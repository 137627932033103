import API from 'services'

export const getCreditCards = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/me/cards`
  )
  return response.data
}
export const postLinkCard = async (body) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/me/link-cards`,
    body
  )
  return response.data
}
export const postUnLinkCard = async (creditCardId) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/me/unlink-cards`,
    {
      id: creditCardId
    }
  )
  return response.data
}
