import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import moment from 'moment'
const WaitingListBeli = ({ data }) => {
  return (
    <CardSectionHeader headerTitle="Informasi Waiting List">
      <div className="sm:flex sm:gap-0 grid grid-cols-1 gap-2 justify-between items-center">
        <p className="text-md-regular text-gray-600">Booking Anda akan dimulai pada</p>
        <p className="text-md-medium text-gray-700 text-right">
          {moment(data?.start_time).format('DD MMMM YYYY, HH:mm')} -{' '}
          {moment(data?.end_time).format('DD MMMM YYYY, HH:mm')}
        </p>
      </div>
    </CardSectionHeader>
  )
}

export default WaitingListBeli
