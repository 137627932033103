import React from 'react'
import { Collapse, Space } from 'antd'
import './style.css'
import FormSelect from 'components/ui/form/form-select'
import InputCurrency from 'components/ui/input-currency'
const { Panel } = Collapse

function FilterExTaxi({ filter, handleFilter, brands }) {
  const informasiMobilInput = [
    {
      id: 1,
      label: 'Merek',
      name: 'merek',
      type: 'select',
      options: brands,
      value: filter?.merek?.value,
      placeholder: 'Pilih Merek'
    }
  ]
  return (
    <Space direction="vertical" size="large">
      {/* Informasi Mobil */}
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={['1']}
        bordered={false}
        className="bg-white rounded-[4px] border border-solid border-gray-200">
        <Panel key={'1'} header="Informasi Mobil" className="text-gray-700">
          <div className="grid grid-cols-1 gap-3">
            {informasiMobilInput.map((data, index) => (
              <FormSelect
                key={index}
                data={{ ...data, onChange: (_, option) => handleFilter(data.name, option) }}
              />
            ))}
          </div>
        </Panel>
      </Collapse>

      {/* Harga */}
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={['1']}
        bordered={false}
        className="bg-white rounded-[4px] border border-solid border-gray-200">
        <Panel key={'1'} header="Harga" className="text-gray-700">
          <div className="grid grid-cols-1 gap-3">
            <div className="grid grid-cols-1 gap-2">
              <p className="text-sm-medium text-gray-700">Minimum</p>
              <InputCurrency
                placeholder="Rp 2000000"
                value={filter.minHarga !== null ? filter.minHarga : ''}
                onChange={(value, name) => handleFilter(name, value)}
                name="minHarga"
                prefix="Rp"
              />
            </div>
            <div className="grid grid-cols-1 gap-2">
              <p className="text-sm-medium text-gray-700">Maximum</p>
              <InputCurrency
                placeholder="Rp 2000000"
                value={filter.maxHarga !== null ? filter.maxHarga : ''}
                onChange={(value, name) => handleFilter(name, value)}
                name="maxHarga"
                prefix="Rp"
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    </Space>
  )
}

export default FilterExTaxi
