import { Col, Row } from 'antd'
import { BookSuccessImage, SampleCar } from 'assets'
import React from 'react'
import { Link } from 'react-router-dom'

const BookingMobilResultPage = () => {
  return (
    <Row className="py-20 gap-10">
      <div className="grid grid-cols-1 gap-2 w-full">
        <div className="flex flex-col justify-center items-center text-lg-medium"></div>
      </div>
      <Col span={14} className="mx-auto flex flex-col gap-3 text-center">
        <div className="bg-white border border-solid border-gray-200 rounded-md p-6 lg:p-16">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col justify-center items-center gap-4 text-center">
              <img src={BookSuccessImage} alt="" />
              <p className="display-sm-semibold text-gray-900">Mobil Berhasil Dibooking!</p>
              <p className="text-xl-regular text-gray-700">
                Mobil berhasil dibooking. Mobil akan diamankan dengan waktu 6 hari, segera lakukan
                konfirmasi beli mobil, jika tidak maka DP akan dikembalikan 100%.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center gap-4 ">
              <p className="text-md-regular text-gray-500">Unit:</p>
              {/* Card */}
              <div className="border border-solid border-gray-200 place-items-center rounded-md grid grid-cols-[110px_1fr] gap-4 p-4">
                <div className="bg-gray-200 p-3">
                  <img src={SampleCar} alt="unit image" />
                </div>
                <p className="text-md-medium text-gray-700">2019 Toyota Kijang Innova 2.4G</p>
              </div>
            </div>
          </div>
        </div>
        <Link to="/" className="hover:text-primary-900 text-md-medium text-primary-600 ">
          Kembali ke Halaman Utama
        </Link>
      </Col>
    </Row>
  )
}

export default BookingMobilResultPage
