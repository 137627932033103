const ShoppingBagIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 5.00008L4 1.66675H14L16.5 5.00008M1.5 5.00008V16.6667C1.5 17.1088 1.67559 17.5327 1.98816 17.8453C2.30072 18.1578 2.72464 18.3334 3.16667 18.3334H14.8333C15.2754 18.3334 15.6993 18.1578 16.0118 17.8453C16.3244 17.5327 16.5 17.1088 16.5 16.6667V5.00008M1.5 5.00008H16.5M12.3333 8.33342C12.3333 9.21747 11.9821 10.0653 11.357 10.6904C10.7319 11.3156 9.88405 11.6667 9 11.6667C8.11594 11.6667 7.2681 11.3156 6.64298 10.6904C6.01786 10.0653 5.66667 9.21747 5.66667 8.33342"
        stroke="#667085"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ShoppingBagIcon
