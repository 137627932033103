import AuthOtp from 'components/auth-otp/auth-otp'
import { getDataUser } from 'features/profile/service'
import useValidation from 'lib/useValidation'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { registerPersonal, sendOtp, requestOtp } from '../service'
import InputPersonal from './input-personal'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage } from 'utils/toastMessage'

const initialState = {
  fullname: '',
  phone_number: '',
  email: '',
  password: ''
}

function RegisterTypePersonal() {
  const { push } = useHistory()
  const [isPersonal, setIsPersonal] = useState(true)
  const [state, setState] = useState({ ...initialState })
  const [error, setError] = useState({ ...initialState })
  const [companyEmail, setCompanyEmail] = useState('')
  const [agree, setAgree] = useState(false)
  const { nameValidation, numberValidation, passValidation, emailValidation } = useValidation()

  const handleChangeState = (e) => {
    const { name, value } = e.target
    let err = ''

    switch (name) {
      case 'name':
        err = nameValidation(value)
        break
      case 'phone':
        err = numberValidation(value, 'Nomor Telepon')
        break
      case 'email':
        err = emailValidation(value)
        break
      case 'password':
        err = passValidation(value)
        break
      default:
        err = ''
    }

    setState((prev) => ({
      ...prev,
      [name]: value
    }))
    setError((prev) => ({
      ...prev,
      [name]: err
    }))
  }

  const handleChangeAgree = (e) => {
    const { checked } = e.target
    setAgree(checked)
  }

  const handleRegisterCustomer = (data) => {
    sessionStorage.setItem('token', data.access_token)
    refetch()
    setIsPersonal(false)
  }

  const { mutate, isLoading } = useMutation(registerPersonal, {
    onSuccess: (data) => {
      handleRegisterCustomer(data)
      // sessionStorage.setItem('token', data.access_token)
      // refetch()
      // setIsPersonal(false)
    },
    onError: (data) => {
      const { response } = data
      // console.log(response?.data?.errors[0]?.message)
      showErrorMessage(response?.data?.errors[0]?.message || 'there was an error')
    }
  })

  const { refetch: getDataRefetch } = useQuery({
    queryKey: 'getProfilePersonal',
    queryFn: () => {
      const token = localStorage.getItem('token')
      return getDataUser({ account_type: 'personal', token: token })
    },
    onSuccess: (data) => {
      localStorage.setItem('account_type', 'customer')
      const dataStringify = JSON.stringify(data)
      localStorage.setItem('user_name', data.fullname)
      localStorage.setItem('user', dataStringify)
      push('/')
    },
    enabled: false
  })

  const submitOtp = useMutation(sendOtp, {
    onSuccess: (data) => {
      // console.log(data)
      const token = sessionStorage.getItem('token')
      localStorage.setItem('token', token)
      sessionStorage.removeItem('token')
      getDataRefetch()
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })

  const { refetch } = useQuery('newOtpRequest', requestOtp, {
    onSuccess: (data) => {
      // console.log(data)
    },
    enabled: false
  })

  const handleSubmit = () => {
    // validate
    let nameError = ''
    let nameInput = ''
    let validError = true
    let validInput = true
    Object.keys(state).forEach((key) => {
      if (state[key].length === 0) {
        validInput = false
        nameInput = String(key)
      }
    })
    Object.keys(error).forEach((key) => {
      if (error[key].length > 0) {
        validError = false
        nameError = String(key)
      }
    })
    // Object.values(state).forEach((v) => {
    //   if (v.length === 0) {
    //     validInput = false
    //   }
    // })
    // Object.values(error).forEach((v) => {
    //   if (v.length > 0) {
    //     validError = false
    //   }
    // })
    if (validInput) {
      if (validError) {
        const dataSend = {
          ...state,
          company_email: companyEmail
        }
        mutate(dataSend)
      } else {
        showErrorMessage(`Mohon perbaiki ${nameToString[nameError]}`)
      }
    } else {
      showErrorMessage(`Mohon isi ${nameToString[nameInput]}`)
    }
  }
  const handleSubmitOtp = (otp) => {
    const dataSend = {
      otp_code: otp
    }
    submitOtp.mutate(dataSend)
  }
  const handleClickResendOtp = () => {
    refetch()
  }

  const handleBack = () => setIsPersonal(true)

  return (
    <>
      {isPersonal ? (
        <InputPersonal
          handleSubmit={handleSubmit}
          state={state}
          error={error}
          companyEmail={companyEmail}
          setCompanyEmail={setCompanyEmail}
          isLoading={isLoading}
          agree={agree}
          handleChangeAgree={handleChangeAgree}
          handleChangeState={handleChangeState}
          handleRegisterCustomer={handleRegisterCustomer}
        />
      ) : (
        <AuthOtp
          title="Registrasi BirdMobil"
          handleBack={handleBack}
          handleSubmit={handleSubmitOtp}
          handleResend={handleClickResendOtp}
        />
      )}
    </>
  )
}

export default RegisterTypePersonal
