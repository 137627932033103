import { Divider, Spin } from 'antd'
import BadgeItem from 'components/ui/badge-item'
import CardSectionHeader from 'components/ui/card-section-header'
import CardInformationJadwal from 'components/ui/card/card-information-jadwal'
import CardInformationUser from 'components/ui/card/card-information-user'
import React from 'react'

const FormReviewServis = ({ schedule, dataUser, selectedCar }) => {
  return (
    <>
      <div className="flex flex-col gap-6">
        <CardSectionHeader>
          <div className="flex flex-col gap-3 items-start">
            <BadgeItem
              type={
                selectedCar?.unit_ownership === 'Mobil Pribadi'
                  ? 'plain'
                  : selectedCar?.unit_ownership === 'Mobil Perusahaan'
                  ? 'purple'
                  : 'primary'
              }>
              {selectedCar?.unit_ownership}
            </BadgeItem>
            <p className="text-lg-medium text-gray-900">{selectedCar?.unit_name}</p>
          </div>
        </CardSectionHeader>
        <CardInformationUser headerTitle={'Data Diri'} data={dataUser} />
        <CardInformationJadwal
          workshop={dataUser.workshop}
          selectedSchedule={schedule.hours}
          data={schedule}
          servis
        />
      </div>
    </>
  )
}

export default FormReviewServis
