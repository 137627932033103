import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getServiceHistories } from '../service'

const useServiceHistoriesById = (unitId) => {
  const [serviceHistories, setServiceHistories] = useState([])
  const [servicePagination, setServicePagination] = useState(1)
  const [filterService, setFilterService] = useState({ limit: 5, offset: 0, unitId: unitId })
  const { refetch } = useQuery({
    queryKey: ['getServiceHistories', servicePagination],
    queryFn: () => getServiceHistories(filterService),
    onSuccess: (data) => {
      setServiceHistories(data)
    },
    refetchOnWindowFocus: false
  })
  const handlePaginationService = (pageNumber) => {
    const totalOffset = filterService.limit * pageNumber - filterService.limit
    if (totalOffset >= serviceHistories.meta.total || totalOffset < 0) {
      return false
    }
    setServicePagination(pageNumber)
    handleFilterService('offset', totalOffset)
  }
  function handleFilterService(name, value) {
    setFilterService({ ...filterService, [name]: value })
    return refetch()
  }

  return {
    filterService,
    servicePagination,
    serviceHistories,
    handlePaginationService,
    handleFilterService
  }
}

export default useServiceHistoriesById
