import { ReportTypeBills, ReportTypeCars, ReportTypeExpense } from 'features/report-type'
import React from 'react'
import { useParams } from 'react-router-dom'

function ReportTypePage() {
	const { type } = useParams();
	const token = localStorage.getItem('token')
  const accountType = localStorage.getItem('account_type')
  if (accountType !== 'company' || token === null) {
    window.location.replace('/')
  }

	const renderComponent = () => {
		switch (type) {
			case 'cars':
				return <ReportTypeCars />
			case 'expense':
				return <ReportTypeExpense />
			case 'bill':
				return <ReportTypeBills />
			default:
				return <ReportTypeCars />
		}
	}
	return (
		<>
			{
				renderComponent()
			}
		</>
	)
}

export default ReportTypePage
