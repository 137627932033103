import { Col, Row } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import { EditIcon } from 'assets'

function CardProfileInformation({ isB2B, data = {}, handleClick }) {
  let newData = [
    {
      id: 1,
      label: isB2B === false ? 'Nama' : 'Nama Perusahaan',
      value: data?.fullname ? data?.fullname : data?.name || '-'
    },
    { id: 2, label: isB2B ? 'Email Perusahaan' : 'Email Pribadi', value: data?.email || '-' },
    { id: 3, label: 'Email Perusahaan', value: data?.company_email || '-' },
    { id: 4, label: 'Nomor Telepon', value: data?.phone_number },
    { id: 5, label: 'NPWP', value: data?.npwp || '-' }
  ]
  if (isB2B) {
    newData = newData.filter((el) => el.id !== 3)
  }
  let currentProfile = newData
  let userAddress = [
    { label: 'Alamat Lengkap', value: data?.address || '-' },
    { label: 'Provinsi', value: data?.province_name || '-' },
    { label: 'Kota', value: data?.city_name || '-' },
    { label: 'Kecamatan', value: data?.sub_district_name || '-' },
    { label: 'Kode Pos', value: data?.postal_code || '-' }
  ]
  return (
    <CardSectionHeader
      headerTitle={
        <div className="flex justify-between">
          <div>Profil {isB2B ? 'Perusahaan' : 'Saya'}</div>
          <CustomButton
            type="plain"
            label={
              <div className="flex justify-center items-center gap-1 ">
                <EditIcon />
                Edit Profil
              </div>
            }
            onClick={handleClick}
          />
        </div>
      }
      hideDivider>
      <div className="mt-5 gap-3 grid grid-cols-1 lg:grid-cols-2">
        <div>
          <p className="text-lg-medium text-gray-700 mb-6">Biodata</p>
          <div className="flex flex-col gap-2 lg:gap-6">
            {currentProfile.map((user, index) => (
              <Row key={`biodata-${index}`}>
                <Col span={8} className="text-xs-regular md:text-md-regular text-gray-500">
                  {user.label}
                </Col>
                <Col span={16} className="text-xs-medium md:text-md-medium text-gray-900">
                  {user.value}
                </Col>
              </Row>
            ))}
          </div>
        </div>
        <div>
          <p className="text-lg-medium text-gray-700 mb-6">
            {isB2B === false ? 'Alamat' : 'Alamat Perusahaan'}
          </p>
          <div className="flex flex-col gap-2 lg:gap-6">
            {userAddress.map((user, index) => (
              <Row key={`alamat-${index}`}>
                <Col span={8} className="text-xs-regular md:text-md-regular text-gray-500">
                  {user.label}
                </Col>
                <Col span={16} className="text-xs-medium md:text-md-medium text-gray-900">
                  {user.value}
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardProfileInformation
