import { Carousel, Col, Row } from 'antd'
import { CarIcon, MagnifyingIcon, ServiceIcon, ShoppingBagActiveIcon } from 'assets'
import React from 'react'
import { ImageHero } from 'assets'
const navIconList = [
  {
    id: 0,
    label: 'Beli Mobil',
    url: '/beli/mobil-bekas',
    color: 'text-primary-500 stroke-primary-500 bg-primary-50',
    icon: <ShoppingBagActiveIcon />
  },
  {
    id: 1,
    label: 'Jual Mobil',
    url: {
      pathname: '/jual'
    },
    color: 'text-success-500 bg-success-50',
    icon: <CarIcon className={'stroke-success-500'} />
  },
  {
    id: 2,
    label: 'Servis Mobil',
    url: '/servis',
    color: 'text-warning-500 bg-warning-50',
    icon: <ServiceIcon className={'stroke-warning-500'} />
  },
  {
    id: 3,
    label: 'Inspeksi Mobil',
    url: '/inspeksi',
    color: 'text-blue-500 stroke-blue-500 bg-blue-50',
    icon: <MagnifyingIcon className={'stroke-blue-500'} />
  }
]
const IconNavFeaturesSection = ({ handleNavigation, banners }) => {
  return (
    <Col span={24} className="bg-white lg:hidden">
      <Col span={24}>
        {/* <img src={ImageHero} className="w-full"></img> */}
        <div className='banner-landing-page overflow-x-hidden max-h-[129px]'>
          <Carousel autoplay autoplaySpeed={5000} infinite={true} dots={false} className='h-full'>
            {
              banners.length > 0 && banners?.map((el, idx) => (
                <div className={`bg-cover bg-no-repeat h-full block`} key={el?.id}>
                  <img src={el?.image} alt={`birdmobil-bann-${idx}`} className='h-full max-h-[129px] bg-no-repeat w-full object-cover' />
                </div>
              ))
            }
          </Carousel>
        </div>
      </Col>
      <Col span={22} lg={{ span: 20 }} className="py-6 mx-auto">
        <div className="flex justify-between items-center text-center">
          {navIconList.map(({ id, label, url, icon, color }) => (
            <div
              onClick={() => {
                handleNavigation(url)
              }}
              className={'flex flex-col gap-2'}
              to={url}
              key={id}>
              {/* Icon */}
              <div
                className={`w-10 h-10 p-3 ${color} rounded-full flex justify-center items-center mx-auto`}>
                {icon}
              </div>
              <p className="text-xs-regular text-gray-500">{label}</p>
            </div>
          ))}
        </div>
      </Col>
    </Col>
  )
}

export default IconNavFeaturesSection
