const MekanikTersertifikasiIcon = ({ className }) => {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_10754_46347)">
        <path d="M72.3044 17.438C72.4888 19.0048 72.1201 22.6915 71.2906 24.0739C70.6455 25.2721 68.9865 26.4703 67.2353 26.8389C66.4058 26.9311 66.1293 26.9311 65.2998 26.8389C63.5487 26.4703 61.9819 25.2721 61.2445 24.0739C60.5072 22.6915 60.1385 19.0048 60.2307 17.438C60.6915 14.3965 63.3644 12.9219 66.3137 12.9219C69.3551 12.9219 72.0279 14.3965 72.3044 17.438Z" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M52.4885 53.1985H42.6267C40.9677 53.1985 39.3087 52.5533 38.0184 51.4474L34.8848 48.498" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.0742 60.4785L27.4198 61.2158C29.6318 61.5845 30.6456 61.9532 32.5811 64.1652" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.1621 54.9492V66.1935" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.1615 54.9501L11.567 51.0791C11.1984 50.7104 10.9219 50.1574 10.9219 49.6045V45.3648C10.9219 44.2588 11.8435 43.3372 12.9495 43.3372H21.0601L28.0647 29.8809C29.4472 28.0376 31.6592 26.9316 33.9633 26.9316H48.4334" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.5254 43.3359H52.8572" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M31.2909 32.0009L27.4199 39.4663H48.4337V30.8027H33.9637C32.9499 30.8027 32.0282 31.2636 31.2909 32.0009Z" fill="#E12B25" />
        <path d="M57.4663 48.4984L57.0977 40.1113" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M75.1622 48.4988L75.4387 41.402L76.4525 39.7431L83.6414 33.4758C85.6691 31.7246 85.4848 31.909 86.0377 29.3283L88.9871 16.7938C89.4479 14.1209 84.6553 13.3836 84.0101 16.0564L80.1391 28.591L75.7152 32.0933C68.4341 29.697 63.7336 29.697 57.0977 32.1855" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M66.3145 44.3497V36.5156" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M66.3145 34.9499V32.8301" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M38.9395 69.7875C39.6769 71.4465 40.5985 72.9211 41.7967 74.3036L39.4004 77.7138C38.4787 79.0041 38.7552 80.8474 40.1377 81.7691L43.9165 84.4419C45.2068 85.3635 47.0501 85.087 47.9718 83.7045L50.3681 80.2944C53.5018 81.4004 57.0041 81.7691 60.5064 81.2161L62.2575 84.9949C62.9027 86.4695 64.6538 87.1147 66.1285 86.3774L70.3681 84.4419C71.8428 83.7967 72.4879 82.0456 71.7506 80.5709L69.9073 76.6999C72.4879 74.488 74.5156 71.6308 75.7137 68.405L79.8612 68.7737C81.428 68.958 82.9027 67.7598 82.9948 66.1009L83.3635 61.4926C83.5478 59.9257 82.3497 58.4511 80.6907 58.3589L76.5432 57.9903C76.2667 56.2391 75.7137 54.5801 74.9764 53.1055" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M50.1836 64.6263C51.1974 66.8383 53.2251 68.4973 55.8057 68.9581C59.9532 69.6032 63.8241 66.8383 64.4693 62.783C64.7458 61.2161 64.4693 59.7415 63.9163 58.4512" stroke="#344154" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10754_46347">
          <rect width="80" height="75.576" fill="white" transform="translate(10 12)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MekanikTersertifikasiIcon