import BirdmobilCertifiedSection from 'components/section/birdmobil-certified-section'
import FAQSection from 'components/section/faq-section'
import ServiceSection from 'components/section/service-section'
import StepsSection from 'components/section/steps-section'
// import TestimonySection from 'components/section/testimony-section'
import {
  // AvailablePackageSection,
  BenefitServiceSection,
  HeroServisSection,
  WhyServiceSection
} from 'features/servis'
import React from 'react'
import { useHistory } from 'react-router-dom'

const ServisPage = () => {
  const { push } = useHistory()
  const token = localStorage.getItem('token')
  const onNavFormServis = () => {
    if (!token) {
      return push('/login')
    }
    push('/servis/servis-mobil')
  }

  const stepData = [
    {
      title: 'Isi Data Unit Mobil Anda',
      description: 'Isi data lengkap mobil Anda untuk mengetahui jenis layanan perawatan'
    },
    {
      title: 'Jadwalkan Servis',
      description: 'Jadwalkan tanggal dan jam untuk Anda bisa melakukan perawatan mobil Anda'
    },
    {
      title: 'Mobil Anda Sudah Kembali Prima',
      description: 'Kini mobil Anda sudah kembali prima dan bisa diajak beraktivitas kembali'
    }
  ]
  return (
    <>
      <HeroServisSection onClick={onNavFormServis} />
      {/* <AvailablePackageSection /> */}
      <WhyServiceSection />
      <ServiceSection headerTitle="Layanan Servis Kami" />
      <StepsSection
        items={stepData}
        onClick={onNavFormServis}
        buttonLabel={'Booking Servis Sekarang'}
        sectionTitle={'3 Langkah Melakukan Servis'}
      />
      {/* <TestimonySection unOrder={true} /> */}
      <BenefitServiceSection unOrder={true} />
      <BirdmobilCertifiedSection unOrder={true} />
      <FAQSection unOrder={true} />
    </>
  )
}

export default ServisPage
