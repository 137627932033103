const ChecklistSquareIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="36"
      height="34"
      viewBox="0 0 36 34"
      fill="none"
      stroke="#E12B25"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.25 15.3333L17.25 20.3333L33.9167 3.66667M32.25 17V28.6667C32.25 29.5507 31.8988 30.3986 31.2737 31.0237C30.6486 31.6488 29.8007 32 28.9167 32H5.58333C4.69928 32 3.85143 31.6488 3.22631 31.0237C2.60119 30.3986 2.25 29.5507 2.25 28.6667V5.33333C2.25 4.44928 2.60119 3.60143 3.22631 2.97631C3.85143 2.35119 4.69928 2 5.58333 2H23.9167"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChecklistSquareIcon
