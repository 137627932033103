import { Col, Input, Row, Select, Spin } from 'antd'
import { CloseIcon, MagnifyingIcon, SlidersIcon } from 'assets'
import CardCar from 'components/ui/card-car'
import CustomPagination from 'components/ui/pagination/custom-pagination'
import { merk } from 'data/merk'
import { FilterMobilBekas, NullComponentMobilBekas } from 'features/beli-mobil'

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useWishlist } from 'hooks'
import { useSearchCatalog } from 'features/beli-mobil/hooks'
import CustomButton from 'components/ui/custom-button'
import { useEffect } from 'react'
import useWindowSize from 'lib/useWindowSize'
import { onRupiah } from 'utils/formatCurrency'
import { ModalLayout } from 'layouts'

// Value Sort
const sortOption = [
  { label: 'Urutkan', value: '', disabled: true },
  { label: 'Harga Tertinggi', value: 'desc' },
  {
    label: 'Harga Terendah',
    value: 'asc'
  }
]
function BeliMobilBekasPage() {
  const { push, location } = useHistory()
  const [sortType, setSortType] = useState(sortOption[0])
  let filterData = location?.state?.data

  const {
    handlePagination,
    handleFilter,
    handleResetFilter,
    handleRemoveFilterByOption,
    cars,
    filter,
    filterTag,
    locations,
    fuels,
    transmissions,
    models,
    colors,
    types,
    brands,
    provinces,
    cities,
    isCatalogFetching
  } = useSearchCatalog(filterData)
  const [filterModal, setFilterModal] = useState(false)
  const { wishlistIds, handleWishlist } = useWishlist()
  const [width] = useWindowSize()
  useEffect(() => {
    handleFilter('merek', location?.state?.data?.merek)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [location?.state?.data?.merek])

  // Sort Data
  const sortedData =
    sortType?.value === ''
      ? cars?.data
      : sortType?.value === 'asc'
      ? cars?.data.sort((a, b) => a?.price - b?.price)
      : cars?.data.sort((a, b) => b?.price - a?.price)

  // Functions
  const handleSortData = (option) => {
    setSortType({
      label: option.label,
      value: option.value
    })
  }
  function handleNavigation(path) {
    return push({
      pathname: path
    })
  }
  function openFilterModal() {
    setFilterModal(true)
  }
  function closeFilterModal() {
    setFilterModal(false)
  }
  useEffect(() => {
    width > 768 && closeFilterModal()
  }, [width])

  return (
    <Row>
      <Col span={22} lg={{ span: 20 }} className="mx-auto my-10">
        <div className="grid lg:grid-cols-[200px_1fr] xl:grid-cols-[286px_1fr] gap-6">
          {/* Filter */}
          <div className="hidden lg:block">
            <FilterMobilBekas
              provinces={provinces}
              cities={cities}
              handleFilter={handleFilter}
              brands={brands}
              locations={locations}
              fuels={fuels}
              transmissions={transmissions}
              models={models}
              colors={colors}
              types={types}
              filter={filter}
            />
          </div>

          {/* Right Content */}
          <div className="grid place-content-start grid-cols-1 gap-6">
            {/* Search Bar & Sort */}
            <div className="grid grid-cols-1 gap-6">
              <div className="grid lg:flex gap-2 items-center">
                <Input
                  size="medium"
                  className="text-md-normal text-gray-500 px-[14px] py-[5px] rounded-lg"
                  prefix={<MagnifyingIcon className={'mr-1'} />}
                  placeholder="Search"
                  name="keywords"
                  onChange={(e) => {
                    handleFilter(e.target.name, e.target.value)
                  }}
                  value={filter.keywords}
                />
                <div className="flex justify-between items-center">
                  <Select
                    className="w-[150px]"
                    size="medium"
                    defaultValue={'Urutkan'}
                    value={sortType?.label}
                    onChange={(value, option) => handleSortData(option)}
                    options={sortOption}
                    placeholder="Urutkan"
                  />
                  <div className="lg:hidden">
                    <CustomButton
                      onClick={openFilterModal}
                      type="plain"
                      label={
                        <div className="flex justify-center items-center">
                          <SlidersIcon className={'h-3'} /> Filter
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
              {Object.entries(filterTag).filter(([, value]) => value).length > 0 ||
              sortType?.value !== '' ? (
                <div className="flex gap-2 items-center overflow-x-scroll whitespace-nowrap pb-2 w-[100%]">
                  {sortType?.value !== '' ? (
                    <div className="flex justify-center items-center gap-2 bg-gray-200 rounded px-2 py-3 text-xs-medium text-gray-500">
                      {sortType?.value === 'asc' ? 'dari Terendah' : 'dari Tertinggi'}
                      <div onClick={() => setSortType({ ...sortOption[0] })}>
                        <CloseIcon className={'cursor-pointer w-2 h-2'} />
                      </div>
                    </div>
                  ) : null}
                  {Object.entries(filterTag).length > 0 ? (
                    <>
                      {Object.entries(filterTag).map(([key, value]) => {
                        if (key !== 'offset' && key !== 'limit' && value) {
                          return (
                            <div
                              key={key}
                              className="flex justify-center items-center gap-2 bg-gray-200 rounded px-2 py-3 text-xs-medium text-gray-500">
                              {value?.label
                                ? value?.label
                                : key === 'minHarga' || key === 'maxHarga'
                                ? `${key === 'minHarga' ? 'dari' : 'sampai'} Rp ${onRupiah(value)}`
                                : key === 'minTempuh' || key === 'maxTempuh'
                                ? `${key === 'minTempuh' ? 'dari' : 'sampai'} ${onRupiah(value)} KM`
                                : value}
                              <div onClick={() => handleRemoveFilterByOption(key)}>
                                <CloseIcon className={'cursor-pointer w-2 h-2'} />
                              </div>
                            </div>
                          )
                        }
                      })}
                    </>
                  ) : null}

                  <span
                    className="hover:text-primary-400 text-primary-700 text-sm-medium cursor-pointer"
                    onClick={() => {
                      handleResetFilter()
                      setSortType({ ...sortOption[0] })
                    }}>
                    Hapus Filter
                  </span>
                </div>
              ) : null}
            </div>
            {/* Grid Car */}
            {isCatalogFetching ? (
              <Spin
                spinning={isCatalogFetching}
                size="large"
                className="h-full w-full flex justify-center items-center"></Spin>
            ) : sortedData.length === 0 ? (
              <NullComponentMobilBekas cabang={filter.location} />
            ) : (
              <>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {sortedData?.slice(0, 12).map((data, index) => (
                    <CardCar
                      handleWishlist={handleWishlist}
                      isBookmark={wishlistIds.includes(data?.id)}
                      data={data}
                      onClick={() => {
                        handleNavigation(`/beli/mobil-bekas/detail/${data?.id}`)
                      }}
                      key={index}
                    />
                  ))}
                </div>

                {/* Promo Section */}
                {/* <div className="grid grid-cols-1 h-44 bg-primary-100 content-center place-items-center">
                  <p className="text-md-medium text-primary-500">Promo Section</p>
                </div> */}

                {/* Another Grid Car */}
                {sortedData?.length > 12 ? (
                  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {sortedData?.slice(12).map((data, index) => (
                      <CardCar
                        handleWishlist={handleWishlist}
                        isBookmark={wishlistIds.includes(data?.id)}
                        data={data}
                        onClick={() => {
                          handleNavigation(`/beli/mobil-bekas/detail/${data?.id}`)
                        }}
                        key={index}
                      />
                    ))}
                  </div>
                ) : null}

                {/* Pagination */}
                <div className="grid grid-cols-1 place-items-end">
                  <CustomPagination
                    onChange={handlePagination}
                    defaultPageSize={filter?.limit}
                    defaultCurrent={1}
                    current={filter?.offset === 0 ? 1 : filter?.offset / filter?.limit + 1}
                    total={cars?.meta?.total}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {/* Available Merk Car */}
        <div className=" mt-20 grid grid-cols-1 gap-12">
          <div className="flex flex-col items-center justify-center gap-2">
            <p className=" text-lg-semibold md:display-xs-semibold lg:display-sm-medium text-gray-900">
              Merk Mobil yang Tersedia
            </p>
          </div>
          {/* Merk */}
          <div className="hidden lg:flex lg:justify-between">
            {merk?.map((merkItem, key) => (
              <span className="flex flex-col items-center gap-3" key={key}>
                <div className="h-8 lg:h-14 w-full object-contain flex items-center justify-center">
                  {merkItem.image}
                </div>
                <span className="text-md-semibold text-gray-500">{merkItem.label}</span>
              </span>
            ))}
          </div>
          <div className="grid grid-cols-1 gap-6 lg:hidden">
            <div className="flex justify-evenly">
              {merk?.slice(0, 4).map((merkItem, key) => (
                <span className="flex flex-col items-center gap-1" key={key}>
                  <div className="h-10 lg:h-14 w-full object-contain flex items-center justify-center">
                    {merkItem.image}
                  </div>
                  <span className="text-xs-regular lg:text-md-semibold text-gray-500">
                    {merkItem.label}
                  </span>
                </span>
              ))}
            </div>
            <div className="flex justify-evenly">
              {merk?.slice(4).map((merkItem, key) => (
                <span className="flex flex-col items-center gap-1" key={key}>
                  <div className="h-10 lg:h-14 w-full object-contain flex items-center justify-center">
                    {merkItem.image}
                  </div>
                  <span className="text-xs-regular lg:text-md-semibold text-gray-500">
                    {merkItem.label}
                  </span>
                </span>
              ))}
            </div>
          </div>
        </div>
        {/* Filter Modal */}
        <ModalLayout
          className={'max-w-md w-full md:hidden'}
          openModal={filterModal}
          onCancel={closeFilterModal}
          headerTitle={'Filter'}>
          <div className="grid grid-cols-1">
            <FilterMobilBekas
              modal={true}
              provinces={provinces}
              cities={cities}
              handleFilter={handleFilter}
              brands={brands}
              locations={locations}
              fuels={fuels}
              transmissions={transmissions}
              models={models}
              colors={colors}
              types={types}
              filter={filter}
            />
          </div>
          <div className="flex justify-end items-center gap-2 pt-5">
            <CustomButton
              type="plain"
              onClick={() => {
                handleResetFilter()
                closeFilterModal()
              }}
              label={'Reset Filter'}
            />
            <CustomButton onClick={closeFilterModal} label={'Simpan Filter'} />
          </div>
        </ModalLayout>
      </Col>
    </Row>
  )
}

export default BeliMobilBekasPage
