import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'

const hapusAkunWording =
  'Dengan fitur "Hapus Akun" di BirdMobil, anda dapat menghapus akun secara permanen. Semua data pribadi dan informasi akun akan dihapus dari platform secara permanen setelah mengonfirmasi tindakan ini. Apabila akun anda memiliki transaksi yang sedang berjalan maka akun tidak bisa dihapus sampai transaksi anda selesai diproses.'

function CardDeleteAccount({ isB2B, handleClick }) {
  return (
    <CardSectionHeader headerTitle="Hapus Akun" hideDivider>
      <div className="flex flex-col space-y-6 mt-5">
        <p className="text-xs-regular md:text-md-medium text-gray-500">{hapusAkunWording}</p>
        <div>
          <CustomButton
            type={isB2B ? 'primary' : 'plain'}
            label="Hapus Permanen Akun"
            className="max-w-fit"
            onClick={handleClick}
          />
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardDeleteAccount
