import { Divider } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import { capitalize } from 'lodash'
import React from 'react'
import moment from 'moment'
const DoneScheduledInspeksi = ({ serviceName, confirmed, data }) => {
  return (
    <CardSectionHeader headerTitle={`Done Scheduled`}>
      <div className="flex flex-col gap-3">
        <p className="text-md-regular text-gray-600">
          Unit Anda telah dikonfirmasi hadir di bengkel.
        </p>
        <Divider className="m-0" />
        <div className="sm:flex sm:gap-0 grid grid-cols-1 gap-2 justify-between items-center">
          <>
            <p className="text-md-regular text-gray-600">
              {capitalize(serviceName)} dimulai pada:{' '}
            </p>
            <p className="text-md-medium text-gray-700 text-right">
              {moment(data?.done_scheduled_at).format('DD MMM YYYY - HH:mm')} WIB
            </p>
          </>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default DoneScheduledInspeksi
