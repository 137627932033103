const LayananCepatIcon = ({ className }) => {
  return (
    <svg
      width="82"
      className={className}
      height="66"
      viewBox="0 0 82 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M66.495 43.2263L64.5507 45.004C63.773 45.7261 62.7731 46.115 61.7176 46.115H56.4958H49.6075C48.552 46.115 47.5521 45.7261 46.7744 45.004L44.8301 43.2263"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.8824 55.503H65.9941V54.1142C65.9941 52.7254 67.0496 51.5033 68.4384 51.3367L73.938 50.559"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.9423 50.559L42.4418 51.3367C43.8306 51.5589 44.8305 52.7254 44.8305 54.1142V55.503H36.8867"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6641 47.226V60.336"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6647 47.2259L30.4982 44.8373C30.2204 44.615 30.1094 44.2817 30.1094 43.8929V41.282C30.1094 40.5598 30.6648 40.0043 31.387 40.0043H36.3866L40.7196 31.7272C41.5529 30.6162 42.8861 29.9496 44.3304 29.9496H55.4962H66.6619C68.1062 29.9496 69.4394 30.6162 70.2727 31.7272L74.6057 40.0043H79.6053C80.3275 40.0043 80.883 40.5598 80.883 41.282V43.8929C80.883 44.2262 80.7163 44.5595 80.4941 44.8373L78.3276 47.2259V60.336"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.1602 60.3359V60.8359C70.1602 63.0579 71.9378 64.8356 74.1598 64.8356C76.3818 64.8356 78.1595 63.0579 78.1595 60.8359V60.3359"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.6634 60.3359V60.8359C40.6634 63.0579 38.8857 64.8356 36.6637 64.8356C34.4417 64.8356 32.6641 63.0579 32.6641 60.8359V60.3359"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.8828 60.3359H56.1621H36.9414"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.2734 55.5031H58.5507"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.5504 33.1716H55.3846H44.2189C43.8856 33.1716 43.5522 33.3382 43.2745 33.5604L40.4414 39.0044H70.2723L67.4392 33.5604C67.217 33.2827 66.8837 33.1716 66.5504 33.1716Z"
        fill="#E12B25"
      />
      <path
        d="M56.8288 26.2277C55.2733 12.2288 43.3854 1.34082 28.9977 1.34082C13.499 1.34082 1 13.8953 1 29.3385C1 44.8373 13.5545 57.3362 28.9977 57.3362"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.9404 26.4499C49.5516 15.5619 40.2191 7.11816 28.9422 7.11816C16.6654 7.11816 6.77734 17.0618 6.77734 29.283C6.77734 41.1154 16.0543 50.7813 27.72 51.4479C28.1089 51.4479 28.5533 51.5034 28.9977 51.5034"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9414 10.2289V13.8398"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4434 15.8396L17.9987 18.3949"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83203 29.3386H13.4428"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4434 42.8374L17.9987 40.2821"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.4401 15.8396L39.8848 18.3949"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6641 29.3386H28.9412V17.8951"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LayananCepatIcon
