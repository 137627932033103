import {
  ChecklistIcon,
  ChecklistSquareIcon,
  HamburgerIcon,
  LayananBeliMobilIcon,
  LayananInspeksiIcon,
  LayananJualMobilIcon,
  ServisUmumIcon,
  WrenchIcon
} from 'assets'

export const layananData = [
  {
    label: 'Beli Mobil',
    description:
      'BirdMobil menyediakan mobil - mobil bekas dengan tanda certified dimana mobil sudah dilakukan pengecekan di lebih dari 180 titik baik interior, eksterior dan mesin. Kami memastikan kondisi mobil dalam keadaan baik, dengan harga yang transparan dalam satu situs www.birdmobil.id. Dimanapun dan kapanpun Anda dapat memilih mobil impian Anda.',
    icon: <LayananBeliMobilIcon />
  },
  {
    label: 'Jual Mobil',
    description:
      'Jual Mobil kesayangan Anda dengan mudah dan proses yang transparan. Kami akan memberikan harga terbaik sesuai dengan kondisi mobil Anda maksimum 4 jam setelah proses Inspeksi di 188 titik.',
    icon: <LayananJualMobilIcon />
  },
  {
    label: 'Servis Mobil',
    description: (
      <>
        Teknisi BirdMobil yang andal dan tersertifikasi akan membantu dalam perawatan dan perbaikan
        Mobil kesayangan Anda. BirdMobil melayani semua jenis Mobil, dengan pilihan suku cadang
        original dan suku cadang yang berkualitas. <br /> <br /> Layanan kami: Servis Berkala,
        Servis Umum, Body Repair dan Body Detailing
      </>
    ),
    icon: <ServisUmumIcon />
  },
  {
    label: 'Inspeksi Mobil',
    description: (
      <>
        Ketahui kondisi Mobil Anda menggunakan layanan Inspeksi BirdMobil dengan Inspektor
        berpengalaman. Pengecekan dilakukan di lebih dari 180 titik meliputi (Interior, Eksterior
        maupun kondisi Mesin) mobil Anda. Hasil pengecekan akan kami kirimkan maksimal 2 jam setelah
        Inspeksi dilakukan.
      </>
    ),
    icon: <LayananInspeksiIcon />
  }
]
export const layananData2 = [
  {
    label: 'Beli Mobil',
    description:
      'BirdMobil menyediakan Mobil Mobil bekas berkualitas dengan tanda certified dimana mobil sudah dilakukan pengecekan di lebih dari 180 titik baik interior, ekterior dan mesin. Kami memastikan kondisi mobil dalam keadaan baik, dengan harga yang transparan dalam satu situs www.birdmobil.id. Dimanapun dan kapanpun Anda dapat memilih mobil impian Anda.',
    icon: <ChecklistSquareIcon />
  },
  {
    label: 'Jual Mobil',
    description:
      'Jual Mobil kesayangan Anda dengan mudah dan proses yang transparan Kami akan memberikan harga terbaik sesuai dengan kondisi mobil Anda maksimum 4 jam setelah proses Inspeksi di lebih dari 180 titik.',
    icon: <ChecklistIcon className="w-[33px] h-[33px]" />
  },
  {
    label: 'Servis Mobil',
    description: (
      <>
        Teknisi BirdMobil yang andal dan tersertifikasi akan membantu dalam perawatan dan perbaikan
        Mobil kesayangan Anda. BirdMobil melayani semua jenis Mobil, dengan pilihan suku cadang
        original atau suku cadang yang berkualitas.
        <br /> <br /> Layanan kami : Servis berkala, Servis Umum, Body Repair dan Body Detailing.
      </>
    ),
    icon: <WrenchIcon />
  },
  {
    label: 'Inspeksi Mobil',
    description:
      'Ketahui kondisi Mobil Anda menggunakan layanan Inspeksi BirdMobil dengan Inspektor berpengalaman. Pengecekan dilakukan di lebih dari 180 titik meliputi ( Interior , Ekterior maupun kondisi Mesin ) mobil Anda. Hasil pengecekan akan kami kirimkan maksimal 2 Jam setelah Inspeksi dilakukan.',
    icon: <HamburgerIcon />
  }
]
