export const nameToString = {
  email: 'Email',
  password: 'Password',
  fullname: 'Nama Lengkap',
  phone_number: 'Nomor Telepon',
  company_email: 'Email Perusahaan',
  company_name: 'Nama Perusahaan',
  npwp: 'NPWP',
  address: 'Alamat',
  province_id: 'Provinsi',
  city_id: 'Kota',
  subdistrict_id: 'Kecamatan',
  postal_code: 'Kode Pos',

  // input pada jual mobil
  input: 'Ekspektasi Harga Jual',
  phone: 'Nomor Telepon',
  mileage: 'Jarak Tempuh',
  police_number: 'Plat Nomor',
  frame_number: 'Nomor Rangka',
  engine_number: 'Nomor Mesin',
  stnk_number: 'STNK',
  bpkb_number: 'BPKB',
  kir_number: 'KIR',
  tax: 'Pajak',
  tax_month: 'Bulan Pajak',
  tax_year: 'Tahun Pajak',
  brand: 'Merek',
  model: 'Model',
  type: 'Tipe',
  year: 'Tahun',
  transmisi: 'Transmisi',
  fuel: 'Bahan Bakar',
  color: 'Warna',
  province: 'Provinsi',
  city: 'Kota',
  subdistrict: 'Kecamatan',
  range_price: 'Harga jarak',
  loc: 'Lokasi',
  nama: 'Nama Lengkap',
  alamat: 'Alamat',

  merek: 'Merek',
  tipe: 'Tipe',
  tahunKeluaran: 'Tahun Keluaran',
  bahanBakar: 'Bahan Bakar',
  warna: 'Warna',
  platNomor: 'Plat Nomor',
  nomorRangka: 'Nomor Rangka',
  nomorMesin: 'Nomor Mesin',
  dokumen_stnk: 'Dokumen STNK',
  dokumen_bpkb: 'Dokumen BPKB',
  dokumen_faktur: 'Dokumen Faktur',
  dokumen_ktp: 'Dokumen KTP',
  dokumen_kwitansi: 'Dokumen Kwitansi',
  dokumen_keur: 'Dokumen Keur',
  dokumen_form: 'Dokumen Form A',
  catatan: 'Catatan',
  thumbnail: 'Foto Thumbnail',

  new: 'Password Baru',
  confirm: 'Konfirmasi Password Baru',

  card_number: 'Nomor Kartu',
  expired_at: 'Tanggal Kadaluarsa',
  cvv: 'CVV',
  cardholder_name: 'Nama Pemegang Kartu'
}