import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'
import moment from 'moment'
const BookingLunasBeli = ({ data }) => {
  return (
    <CardSectionHeader headerTitle={'Booking Lunas'}>
      <div className="flex flex-col gap-3">
        <div className="sm:flex sm:gap-0 grid grid-cols-1 gap-2 justify-between items-center">
          <p className="text-md-regular text-gray-600">Transaksi pembelian selesai pada</p>
          <p className="text-md-semibold text-gray-700 text-right">
            {moment(data?.sold_at).format('DD MMM YYYY - HH:mm')} WIB
          </p>
        </div>
        <div className="sm:flex sm:gap-0 grid grid-cols-1 gap-2 justify-between items-center">
          <p className="text-md-regular text-gray-600">Harga Pembelian</p>
          <p className="text-md-semibold text-gray-700 text-right">
            Rp. {onRupiah(data?.total_price)}
          </p>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default BookingLunasBeli
