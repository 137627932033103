const ServisBerkalaIcon = ({ className }) => {
  return (
    <svg
      width="79"
      height="83"
      className={className}
      viewBox="0 0 79 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.1233 21.1875L53.1743 23.9096C52.0401 24.9682 50.4522 25.5731 48.8643 25.5731H41.0004H30.5656C28.9777 25.5731 27.4654 24.9682 26.2556 23.9096L23.3066 21.1875"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.3137 39.7905H55.291V37.6733C55.291 35.5561 56.8789 33.7414 58.9204 33.4389L67.238 32.3047"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3589 32.3047L19.6765 33.4389C21.7937 33.7414 23.3059 35.5561 23.3059 37.6733V39.7905H11.2832"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.93164 27.2383V47.0492"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.93196 27.2382L1.60495 23.6087C1.22688 23.2306 1 22.7013 1 22.172V18.2401C1 17.1815 1.83179 16.2741 2.966 16.2741H10.5274L17.0303 3.72212C18.3157 1.98299 20.3573 1 22.4745 1H39.3365H56.1985C58.3157 1 60.3573 1.98299 61.6428 3.72212L68.1456 16.2741H75.707C76.7656 16.2741 77.673 17.1059 77.673 18.2401V22.172C77.673 22.7013 77.4461 23.2306 77.068 23.6087L73.7411 27.2382V47.1248"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.6426 47.0488V47.805C61.6426 51.132 64.3647 53.8541 67.6917 53.8541C71.0188 53.8541 73.7409 51.132 73.7409 47.805V47.0488"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.03 47.0488V47.805C17.03 51.132 14.3079 53.8541 10.9808 53.8541C7.6538 53.8541 4.93164 51.132 4.93164 47.805V47.0488"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.314 47.0488H40.5465H11.3594"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5723 39.791H44.0997"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4082 16.3496H61.3401"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.8269 81.001H9.99891C6.44504 81.001 3.49609 78.1276 3.49609 74.4981V66.7099C3.49609 63.156 6.36942 60.207 9.99891 60.207H68.8269C72.3808 60.207 75.3297 63.0804 75.3297 66.7099V74.4981C75.3297 78.052 72.3808 81.001 68.8269 81.001Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.1995 4.93164H39.3375H22.4755C21.6437 4.93164 20.812 5.30971 20.207 5.91463L17.5605 10.9052H61.0388L58.3923 5.91463C57.9386 5.30971 57.1068 4.93164 56.1995 4.93164Z"
        fill="#E12B25"
      />
      <path
        d="M17.1061 67.3151C17.6354 67.5419 18.0135 67.8444 18.2403 68.298C18.5428 68.7517 18.6184 69.2054 18.6184 69.8103C18.6184 70.4152 18.4671 70.8689 18.2403 71.3226C17.9378 71.7763 17.5598 72.0788 17.1061 72.3056C16.5768 72.5325 15.9718 72.6837 15.2913 72.6837H14.0059V74.6497H11.8887V67.0882H15.3669C15.9718 66.937 16.5768 67.0882 17.1061 67.3151ZM16.1987 70.5665C16.4255 70.3396 16.5011 70.1128 16.5011 69.7347C16.5011 69.3566 16.4255 69.1298 16.1987 68.903C15.9718 68.6761 15.6694 68.6005 15.2157 68.6005H14.0059V70.7933H15.2157C15.5938 70.8689 15.9718 70.7933 16.1987 70.5665Z"
        fill="#344154"
      />
      <path
        d="M25.8025 72.9096V74.5732H19.6777V67.0117H25.6512V68.6752H21.7949V69.9607H25.1975V71.5486H21.7949V72.9096H25.8025Z"
        fill="#344154"
      />
      <path
        d="M30.3393 72.5316H29.2051V74.5732H27.0879V67.0117H30.5661C31.2467 67.0117 31.8516 67.1629 32.3809 67.3898C32.9102 67.6166 33.2883 67.9191 33.5151 68.3728C33.8176 68.8265 33.8932 69.2801 33.8932 69.8851C33.8932 70.4144 33.742 70.9437 33.5151 71.3217C33.2883 71.6998 32.9102 72.0779 32.3809 72.3047L34.0445 74.7244H31.776L30.3393 72.5316ZM31.3223 68.9021C31.0954 68.6752 30.793 68.5996 30.3393 68.5996H29.1295V70.7924H30.3393C30.793 70.7924 31.0954 70.7168 31.3223 70.49C31.5491 70.2631 31.6248 70.0363 31.6248 69.6582C31.7004 69.4314 31.5491 69.1289 31.3223 68.9021Z"
        fill="#344154"
      />
      <path d="M35.0273 66.9375H37.1446V74.4989H35.0273V66.9375Z" fill="#344154" />
      <path
        d="M40.3951 74.1948C39.7902 73.8924 39.2609 73.363 38.8828 72.7581C38.5047 72.1532 38.3535 71.4727 38.3535 70.7165C38.3535 69.9604 38.5047 69.2799 38.8828 68.675C39.2609 68.07 39.7145 67.6164 40.3951 67.2383C41 66.9358 41.7561 66.709 42.5879 66.709C43.4197 66.709 44.1002 66.8602 44.7807 67.2383C45.3857 67.5407 45.915 68.07 46.293 68.675C46.6711 69.2799 46.8223 69.9604 46.8223 70.7165C46.8223 71.4727 46.6711 72.1532 46.293 72.7581C45.915 73.363 45.4613 73.8167 44.7807 74.1948C44.1758 74.4973 43.4197 74.7241 42.5879 74.7241C41.6805 74.7241 41 74.4973 40.3951 74.1948ZM43.5709 72.6069C43.8734 72.4557 44.1002 72.1532 44.3271 71.8508C44.4783 71.5483 44.6295 71.1702 44.6295 70.7165C44.6295 70.2629 44.5539 69.8848 44.3271 69.5823C44.1758 69.2799 43.8734 68.9774 43.5709 68.8262C43.2685 68.675 42.8904 68.5237 42.5123 68.5237C42.1342 68.5237 41.7561 68.5993 41.4537 68.8262C41.1512 68.9774 40.9244 69.2799 40.6975 69.5823C40.5463 69.8848 40.3951 70.2629 40.3951 70.7165C40.3951 71.1702 40.4707 71.5483 40.6975 71.8508C40.8488 72.1532 41.1512 72.4557 41.4537 72.6069C41.7561 72.7581 42.1342 72.9094 42.5123 72.9094C42.8904 72.9094 43.2685 72.8337 43.5709 72.6069Z"
        fill="#344154"
      />
      <path
        d="M47.8809 66.9375H51.4348C52.2665 66.9375 53.0226 67.0887 53.6275 67.3912C54.2324 67.6936 54.7617 68.1473 55.1398 68.6766C55.5179 69.2815 55.6692 69.8865 55.6692 70.7182C55.6692 71.4744 55.5179 72.1549 55.1398 72.7598C54.7617 73.3647 54.3081 73.7428 53.6275 74.0453C52.947 74.3477 52.2665 74.4989 51.4348 74.4989H47.8809V66.9375ZM51.3591 72.8354C52.0396 72.8354 52.4934 72.6842 52.9471 72.3061C53.3251 71.9281 53.5519 71.3988 53.5519 70.7938C53.5519 70.1133 53.3251 69.6596 52.9471 69.2816C52.569 68.9035 52.0396 68.7522 51.3591 68.7522H49.9981V72.911H51.3591V72.8354Z"
        fill="#344154"
      />
      <path d="M56.8047 66.9375H58.9218V74.4989H56.8047V66.9375Z" fill="#344154" />
      <path
        d="M62.0963 74.1948C61.4914 73.8924 60.9621 73.363 60.584 72.7581C60.206 72.1532 60.0547 71.4727 60.0547 70.7165C60.0547 69.9604 60.206 69.2799 60.584 68.675C60.9621 68.07 61.4158 67.6164 62.0963 67.2383C62.7012 66.9358 63.4573 66.709 64.2135 66.709C64.894 66.709 65.4989 66.8602 66.1038 67.0871C66.7088 67.3139 67.0869 67.692 67.4649 68.1457L66.1038 69.3555C65.6502 68.7506 65.0453 68.4481 64.2892 68.4481C63.9111 68.4481 63.533 68.5237 63.155 68.7506C62.8525 68.9018 62.55 69.2043 62.3988 69.5067C62.2476 69.8092 62.0963 70.1872 62.0963 70.6409C62.0963 71.0946 62.172 71.4727 62.3988 71.7751C62.55 72.0776 62.8525 72.3801 63.155 72.5313C63.4574 72.6825 63.8355 72.8337 64.2892 72.8337C64.9697 72.8337 65.5745 72.5313 66.1038 71.9264L67.4649 73.1362C67.0869 73.5899 66.6331 73.968 66.1038 74.1948C65.5745 74.4217 64.894 74.5729 64.2135 74.5729C63.4573 74.7241 62.7769 74.4973 62.0963 74.1948Z"
        fill="#344154"
      />
    </svg>
  )
}

export default ServisBerkalaIcon
