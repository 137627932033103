import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import React from 'react'

const CardInspectionNull = ({ handleNavigation, buttonDisabled }) => {
  return (
    <CardSectionHeader headerTitle={<div>Inspeksi</div>}>
      <div className="grid grid-cols-1 gap-5">
        <p className="text-sm-regular md:text-md-regular text-gray-500">
          Mobil Ini belum melakukan inspeksi. Silahkan lakukan inspeksi untuk lihat hasil inspeksi
          mobil
        </p>
        <CustomButton
          disabled={buttonDisabled}
          onClick={handleNavigation}
          label={'Lakukan Inspeksi'}
        />
      </div>
    </CardSectionHeader>
  )
}

export default CardInspectionNull
