import { Col } from 'antd'
import { Garansi6BulanIcon, HargaBersaingIcon, KreditTunaiIcon, MesinTerawatIcon } from 'assets'
import CardLandingGradient from 'components/ui/card/card-landing-gradient'
import SectionTitle from 'components/ui/section-title'
import { layananData } from 'features/about-us/data/layanan-data'
import React from 'react'

const layananKamiData = [
  {
    label: 'Garansi 6 Bulan',
    icon: <Garansi6BulanIcon />
  },
  {
    label: 'Harga Bersaing',
    icon: <HargaBersaingIcon className={'lg:absolute lg:top-[11px] lg:left-[36px]'} />
  },
  {
    label: 'Kredit & Tunai',
    icon: <KreditTunaiIcon />
  },
  {
    label: 'Mesin Terawat',
    icon: <MesinTerawatIcon />
  }
]

function OurServiceSection() {
  return (
    <Col span={24} lg={{ order: 5 }} className="bg-white py-9 lg:py-20">
      <Col span={22} lg={{ span: 20 }} className="mx-auto flex-col flex gap-[72px]">
        {/* Section Title Container */}
        <SectionTitle
          title={'Layanan Kami'}
          description={
            'BirdMobil memberikan pelayanan terpercaya untuk mempermudah Anda mendapatkan mobil terbaik'
          }
        />
        {/* Layanan Container */}
        <div className="mx-auto lg:flex grid grid-cols-1 md:grid-cols-2 gap-7 lg:flex-row justify-between">
          {layananData.map((data, key) => (
            <CardLandingGradient
              key={key}
              gradient={true}
              parentContainerClassName={'max-w-[300px] flex-1'}>
              <article key={key} className="grid grid-cols-1 gap-4">
                <div className="relative flex items-end h-[70px] object-contain">{data.icon}</div>
                <h6 className="text-md-semibold md:text-lg-semibold text-gray-900">{data.label}</h6>
                <p>{data.description}</p>
              </article>
            </CardLandingGradient>
          ))}
        </div>
      </Col>
    </Col>
  )
}

export default OurServiceSection
