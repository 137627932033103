import { Col, DatePicker, Row } from 'antd'
import CustomTable from 'components/ui/custom-table'
// import dayjs from 'dayjs';
import React from 'react'
import { Link } from 'react-router-dom'

function ReportTypeTemplate({
  columns,
  data,
  currentPage,
  totalPerPage,
  lengthAllData,
  defaultPageSize,
  onChangePageSize,
  onChangeNextPage,
  onChangePrevPage,
  handleChangeDate,
  type
}) {
  const { RangePicker } = DatePicker
  const dateFormat = 'DD/MM/YYYY'

  return (
    <Row className="gap-6">
      {/* Header */}
      <Col span={24} className="text-xl-medium text-gray-900">
        <Link className=" stroke-primary-600 text-gray-700 hover:text-primary-500" to={'/report'}>
          Report
        </Link>{' '}
        / <span className="text-primary-500">{`Total ${type}`}</span>
      </Col>
      {/* Content */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* filter setting */}
        <Col span={24} md={8}>
          <RangePicker
            className="rounded-[4px] flex justify-center text-md-normal p-2 gap-3 items-center"
            // defaultValue={[dayjs('2023/01/01', dateFormat), dayjs('2023/01/01', dateFormat)]}
            onChange={(val) => handleChangeDate(val)}
            format={dateFormat}
          />
        </Col>
        {/* table content */}
        <div className="md:bg-white md:border border-solid border-gray-200 md:p-6 rounded-[4px]">
          <p className="display-xs-medium text-gray-900 mb-6">{`Daftar ${type}`}</p>
          <CustomTable
            columns={columns}
            data={data}
            currentPage={currentPage}
            totalPerPage={totalPerPage}
            lengthAllData={lengthAllData}
            defaultPageSize={defaultPageSize}
            onChangePageSize={onChangePageSize}
            onChangeNextPage={onChangeNextPage}
            onChangePrevPage={onChangePrevPage}
          />
        </div>
      </Col>
    </Row>
  )
}

export default ReportTypeTemplate
