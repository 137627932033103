import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

const CustomRoute = ({ path, layout: Layout, page: Page }) => {
    const user = localStorage.getItem('user')
    const pathname = window.location.pathname
    useEffect(() => {
        if(user === null && !(pathname === '/login' || pathname.includes('register'))){
            localStorage.setItem('prevUrl', pathname)
        }
        scrollToTop();
    }, [path]);

    const scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <Route path={path} >
            <Layout>
                <Page />
            </Layout>
        </Route>
    )
}

export default CustomRoute;