import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import moment from 'moment'
import { capitalize } from 'lodash'

function CardDetailService({ data }) {
  return (
    <CardSectionHeader
      headerTitle={data?.serviceType === 'jual' ? 'Test Drive' : capitalize(data?.serviceType)}>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <p className="text-sm-regular text-gray-600">Layanan</p>
          <p className="text-md-medium text-gray-900">
            {data?.serviceType === 'jual'
              ? 'Beli Mobil - Test Drive'
              : capitalize(data?.serviceType)}
          </p>
        </div>
        <div>
          <p className="text-sm-regular text-gray-600">Tempat</p>
          <p className="text-md-medium text-gray-900">
            {data?.place ||
              data?.transaction?.workshop_name ||
              data?.workshop_name ||
              data?.test_drive?.workshop_name}
          </p>
        </div>
        <div>
          <p className="text-sm-regular text-gray-600">Tanggal</p>
          <p className="text-md-medium text-gray-900">
            {moment(data?.service_date).format('DD MMMM YYYY')}
          </p>
        </div>
        <div>
          <p className="text-sm-regular text-gray-600">Jam</p>
          <p className="text-md-medium text-gray-900">
            {data?.service_start_time} - {data?.service_end_time}
          </p>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardDetailService
