import { Modal } from 'antd'
import React, { useEffect } from 'react'

function ModalImage({ openModal, title = null, footer = null, onCancel, imageUrl }) {
  useEffect(() => {
    document.body.style.overflow = openModal ? 'hidden' : 'auto'
    return () => (document.body.style.overflow = 'unset')
  }, [openModal])
  return (
    <Modal
      centered
      title={title}
      open={openModal}
      width={''}
      wrapClassName=""
      footer={footer}
      className="max-w-5xl max-h-xl flex justify-center"
      onCancel={onCancel}>
      <div className="w-full max-w-[500px]">
        <img alt="Preview Image" className="object-cover w-full h-full" src={imageUrl} />
      </div>
    </Modal>
  )
}

export default ModalImage
