import CardFormPesanMobil from 'components/ui/card/card-form-pesan-mobil'
import CardSelectCar from 'components/ui/card/card-select-car'
function FormDataServis({
  dataUser,
  cars,
  dataError,
  keyword,
  handleChangeKeyword,
  handleChange,
  selectedCar,
  handleSelectedCar,
  citiesList,
  subdistrictsList,
  provincesList,
  handleChangeLocation
}) {
  return (
    <div>
      <div className="grid grid-cols-1 gap-6">
        {/* Pilih Mobil */}
        <CardSelectCar
          selectedCar={selectedCar}
          handleSelectedCar={handleSelectedCar}
          cars={cars}
          keyword={keyword}
          handleChangeKeyword={handleChangeKeyword}
        />
        {/* isi Data Diri */}
        <CardFormPesanMobil
          handleChangeLocation={handleChangeLocation}
          provinces={provincesList}
          cities={citiesList}
          subdistricts={subdistrictsList}
          data={dataUser}
          errors={dataError}
          handleChange={handleChange}
        />
      </div>
    </div>
  )
}

export default FormDataServis
