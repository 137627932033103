function InformationContent({ userData }) {
  return (
    <div className="grid grid-cols-1 gap-3 md:gap-6">
      {
        userData.map((el) => (
          <div className="grid grid-cols-1 gap-3 md:gap-6" key={el.id}>
            <div className="text-lg-medium text-gray-700">{el.label}</div>
            {
              el.child.map((chld) => (
                <div className="flex gap-10" key={chld.label}>
                  <div className="w-[100px] md:w-[140px] text-md-regular text-gray-500">{chld.label}</div>
                  <div className="text-md-medium text-gray-900">{chld.value}</div>
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )
}
export default InformationContent