import { Layout } from 'antd'
import Footer from 'components/footer/footer'
import LiveChat from 'components/live-chat'
import Header from 'components/header/header'
import React from 'react'

function RootLayout({ children }) {
  return (
    <Layout>
      <Header />
      {children}
      <Footer />
      {window.location.pathname.includes('/beli/mobil-bekas/detail/') ? null : <LiveChat />}
    </Layout>
  )
}

export default RootLayout
