import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

const ProgressTestDrive = () => {
  return (
    <CardSectionHeader headerTitle={'Progress Test Drive'}>
      <p className="text-md-regular text-gray-600">Test Drive sedang berlangsung</p>
    </CardSectionHeader>
  )
}

export default ProgressTestDrive
