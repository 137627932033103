import { EditIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import { ModalLayout } from 'layouts'
import React, { useState } from 'react'
import CustomButton from '../custom-button'
import FormSelect from '../form/form-select'


function CardInformationPengguna({
  data,
  headerTitle,
  users,
  openModal,
  setOpenModal,
  selectedUser,
  setSelectedUser,
  handleConfirmUser
}) {
  const listUser = {
    name: 'user',
    type: 'select',
    options: users,
    placeholder: 'Pilih Pengguna',
    value: selectedUser?.value,
    onChange: (_, option) => setSelectedUser(option)
  }
  const handleCloseModal = () => setOpenModal(false)
  return (
    <>
      <ModalLayout
        openModal={openModal}
        onCancel={handleCloseModal}
        headerTitle="Ganti Pengguna"
        className="w-1/3">
        <div className='grid grid-cols-1 gap-6'>
          <div className='grid grid-cols-1 gap-1'>
            <div className='text-sm-medium text-gray-700'>{data?.unitName}</div>
            <div className='text-xs-regular text-gray-500'>{data?.police_number}</div>
          </div>
          <div className='grid grid-cols-1'>
            <FormSelect data={listUser} />
          </div>
          <div className="flex justify-end items-center gap-2">
            <CustomButton type="plain" label={'Batal'} onClick={handleCloseModal} />
            <CustomButton label={'Simpan'} onClick={handleConfirmUser} />
          </div>
        </div>
      </ModalLayout>
      {/* content */}
      <CardSectionHeader headerTitle={
        <div className='flex justify-between items-center w-full'>
          <div>{headerTitle}</div>
          <CustomButton
            type="plain"
            onClick={() => setOpenModal(true)}
            label={
              <div className="flex justify-center items-center gap-1 ">
                <EditIcon />
                Edit Pengguna
              </div>
            }
          />
        </div>
      }>
        <div className="grid grid-cols-4 gap-6">
          <div>
            <p className="text-sm-regular text-gray-600">Nama</p>
            <p className="text-md-medium text-gray-900">{data.name}</p>
          </div>
          <div>
            <p className="text-sm-regular text-gray-600">Email</p>
            <p className="text-md-medium text-gray-900">{data.email}</p>
          </div>
          <div>
            <p className="text-sm-regular text-gray-600">Nomor Telepon</p>
            <p className="text-md-medium text-gray-900">{data.phoneNumber}</p>
          </div>
          <div>
            <p className="text-sm-regular text-gray-600">Alamat</p>
            <p className="text-md-medium text-gray-900">{data.address}</p>
          </div>
        </div>
      </CardSectionHeader>
    </>
  )
}

export default CardInformationPengguna
