import React from 'react'
import { Divider, Progress } from 'antd'
import { ChevronRightIcon, EyeIcon, ImagePlaceholder, WarningIcon } from 'assets'
import CustomButton from './custom-button'
import BadgeItem from './badge-item'
import moment from 'moment'
const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
function CardDashboard({ navigateHandler, unit }) {
  const accountType = localStorage.getItem('account_type')
  const detailCar = [
    {
      title: accountType === 'company' ? 'Pengguna' : 'Kepemilikan',
      value:
        accountType === 'company'
          ? unit?.is_assigned === 'Assigned'
            ? unit?.unit_owner || '-'
            : '-'
          : unit?.unit_ownership === 'Mobil Perusahaan'
          ? unit?.company_name || '-'
          : unit?.unit_owner || '-'
    },
    { title: 'Transmisi', value: unit?.transmission },
    { title: 'Tahun', value: unit?.production_year },
    { title: 'Plat Nomor', value: unit?.police_number }
  ]
  return (
    <div className=" p-4 md:p-6 grid grid-cols-1 gap-3 md:gap-6 bg-white rounded-[4px] border border-solid border-gray-200">
      {/* Image & Status */}
      <div className="grid grid-cols-1 md:grid-cols-[300px_1fr] gap-4 md:gap-7">
        {/* Image */}
        <div className="bg-gray-200 md:max-w-[300px] max-h-[168px] overflow-hidden flex items-center relative justify-center rounded-[4px]">
          {unit?.thumbnail ? (
            <img
              src={urlImage.concat(unit?.thumbnail)}
              className="object-cover w-full h-full"
              alt="car"
            />
          ) : (
            <img src={ImagePlaceholder} className="object-contain w-full h-full" alt="car" />
          )}
          {unit?.unit_ownership === 'Mobil Pribadi' ? (
            <BadgeItem type="plain" className="absolute bottom-2 left-2">
              Mobil Pribadi
            </BadgeItem>
          ) : unit?.unit_ownership === 'Mobil Internal' ? (
            <BadgeItem type="primary" className="absolute bottom-2 left-2">
              Mobil Internal
            </BadgeItem>
          ) : (
            <BadgeItem type="purple" className="absolute bottom-2 left-2">
              Mobil Perusahaan
            </BadgeItem>
          )}
        </div>

        {/* Status */}
        <div className="grid grid-cols-1 gap-3 md:gap-6">
          {/* Status & Estimate */}
          <div className="grid grid-cols-1 gap-1 md:gap-3">
            <div className="flex flex-col justify-between items-start gap-2 md:gap-0 md:flex-row md:items-center">
              {unit.unit_status === 'Sedang diservis' ? (
                <BadgeItem type="warning">Sedang Diservis</BadgeItem>
              ) : unit.unit_status === 'Sedang diinspeksi' ? (
                <BadgeItem type="default">Sedang Diinspeksi</BadgeItem>
              ) : unit.unit_status === 'Dijual' ? (
                <BadgeItem type="plain">Dijual</BadgeItem>
              ) : (
                <BadgeItem type="success">Di Garasi</BadgeItem>
              )}
              {unit?.inspection_progress && (
                <p className="text-gray-500 text-xs-regular">
                  Estimasi Selesai:
                  <span className="text-gray-700 text-xs-medium ml-2">
                    {moment(unit?.inspection_progress?.date).format('DD MMM YYYY') +
                      ' - ' +
                      moment(unit?.inspection_progress?.end_time).format('LT')}
                  </span>
                </p>
              )}
              {unit?.service_progress && (
                <p className="text-gray-500 text-xs-regular">
                  Estimasi Selesai:
                  <span className="text-gray-700 text-xs-medium ml-2">
                    <span className="text-gray-700 text-xs-medium ml-2">
                      {moment(unit?.service_progress?.date).format('DD MMM YYYY') +
                        ' - ' +
                        moment(unit?.service_progress?.end_time).format('LT')}
                    </span>
                  </span>
                </p>
              )}
            </div>

            {/* progress percentage */}
            {unit?.inspection_progress && (
              <Progress
                strokeColor="#2e90fa"
                className="m-0 p-0 h-full"
                percent={
                  (unit?.inspection_progress?.progress_finished_total /
                    unit?.inspection_progress?.progress_total) *
                  100
                }
                showInfo={false}
              />
            )}
            {unit?.service_progress && (
              <Progress
                strokeColor="#2e90fa"
                className="m-0 p-0 h-full"
                percent={
                  (unit?.service_progress?.progress_finished_total /
                    unit?.service_progress?.progress_total) *
                  100
                }
                showInfo={false}
              />
            )}
            {/* Merk Car */}
            <p className="text-md-medium md:text-xl-medium text-gray-900 flex items-center">
              {unit?.unit_name}
            </p>
          </div>

          {/* Detail Car */}
          <div className="grid grid-cols-2 gap-3 md:gap-0 md:grid-cols-4 place-content-evenly">
            {detailCar?.map((detail, index) => (
              <div key={index}>
                <p className="text-xs-regular text-gray-500">{detail.title}</p>
                <p className="text-sm-medium text-gray-700 pr-3">{detail.value}</p>
              </div>
            ))}
          </div>
          <Divider className="m-0 p-0" />
        </div>
      </div>

      {/* Recommendation Service */}
      {unit?.recommendationService && (
        <>
          <div className="grid grid-cols-1">
            {/* List Badge */}
            <div className="flex flex-col md:flex-row gap-2 items-start md:items-center">
              <p className="text-xs-regular text-gray-500">Rekomendasi servis: </p>
              {/* Badge Item */}
              <div className="flex gap-2">
                {unit.recommendationService.map((service, key) => (
                  <BadgeItem type="bluelight" key={key}>
                    {service}
                  </BadgeItem>
                ))}
              </div>
            </div>
          </div>
          <Divider className="m-0 p-0" />
        </>
      )}

      {/* REMINDER ALERT */}
      {unit?.reminder && (
        <>
          <div className="grid grid-cols-1">
            {/* Container*/}
            <div className="flex items-center justify-between border border-solid border-warning-300 bg-warning-50 px-1 md:px-4 py-3">
              <div className="flex gap-2 items-center">
                <WarningIcon className={'stroke-warning-700'} />
                <p className="text-sm-medium md:text-md-medium text-warning-700">{unit.reminder}</p>
              </div>
              <ChevronRightIcon className={'stroke-warning-700'} />
            </div>
          </div>
          <Divider className="m-0 p-0" />
        </>
      )}

      {/* Detail Button  */}
      <div className="flex justify-end items-center gap-2">
        <CustomButton
          onClick={() => {
            navigateHandler('/dashboard/unit/detail-unit', unit?.id)
          }}
          label={
            <div className="flex gap-2 items-center">
              <EyeIcon className={'stroke-gray-700'} />
              Detail
            </div>
          }
          type="plain"
        />
        {unit?.inspection_progress ? (
          <CustomButton
            onClick={navigateHandler.bind(
              this,
              'transaction/transaction-detail',
              unit?.inspection_progress?.id,
              'inspeksi'
            )}
            label={
              <div className="flex gap-2 items-center">
                Detail Inspeksi
                <ChevronRightIcon className={'stroke-primary-600'} />
              </div>
            }
            type="secondary"
          />
        ) : null}
        {unit?.service_progress ? (
          <CustomButton
            onClick={navigateHandler.bind(
              this,
              'transaction/transaction-detail',
              unit?.service_progress?.id,
              'servis'
            )}
            label={
              <div className="flex gap-2 items-center">
                Detail Servis
                <ChevronRightIcon className={'stroke-primary-600'} />
              </div>
            }
            type="secondary"
          />
        ) : null}
      </div>
    </div>
  )
}

export default CardDashboard
