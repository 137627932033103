import { Col, Row } from 'antd'
import React from 'react'
import ContentTitle from './ui/content-title'

function PrivacyPolicy() {
  return (
    <Row className={`bg-gray-50 `}>
      <Col span={24}>
        {/* Content Title */}
        <ContentTitle title="Kebijakan Privasi" />
        <Col span={20} className="py-9 lg:py-20 flex flex-col gap-12 mx-auto">
          <p className="text-md-regular text-gray-600">
            <span className="text-md-semibold text-gray-900">
              PERSETUJUAN TERHADAP KEBIJAKAN PRIVASI
            </span>
            <br /> <br /> Kebijakan privasi Kami (“Kebijakan Privasi”) adalah sebagaimana tertera di
            bawah. Kebijakan Privasi ini berlaku bagi seluruh pengguna layanan Kami, termasuk
            pengguna situs web, layanan, atau produk kami (“Situs”), kecuali diatur pada kebijakan
            privasi yang terpisah.
            <br /> <br /> Dengan mencentang “Ya, Saya menerima” pada halaman terakhir, Anda mengakui
            bahwa Anda telah membaca dan memahami Kebijakan Privasi ini dan menyetujui seluruh
            ketentuan yang terdapat dalam setiap poin pada Kebijakan Privasi ini.
            <br /> <br /> Kami, PT. BirdMobil Pusaka Mitra Mobilindo dan afiliasi-afiliasi, (untuk
            selanjutnya disebut sebagai “BirdMobil” atau “Kami”) berhak untuk setiap saat mengubah,
            memperbarui, dan/atau menambahkan sebagian ataupun seluruh ketentuan dalam Kebijakan
            Privasi ini. Dengan terus berkomunikasi dengan Kami, menggunakan Situs dan
            layanan-layanan Kami, ataupun menggunakan fitur-fitur dalam Situs berarti menandakan
            penerimaan Anda atas perubahan, pembaharuan, dan/atau penambahan yang Kami lakukan
            terhadap Kebijakan Privasi ini.
            <br /> <br /> Kami menyusun Kebijakan Privasi ini untuk menjelaskan kepada Anda
            bagaimana Kami, mengumpulkan, menggunakan, dan menyimpan Data Pribadi Anda.
            <br /> <br /> Kami berhak untuk, dari waktu ke waktu, melakukan perubahan, penambahan,
            dan/atau modifikasi atas seluruh atau sebagian dari isi Kebijakan Privasi ini. Oleh
            karena itu, Kami menyarankan Anda untuk senantiasa membaca Kebijakan Privasi ini agar
            Anda dapat memahami setiap ketentuan-ketentuan yang Kami berlakukan dari waktu ke waktu
            pada Kebijakan Privasi ini.
          </p>

          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">DATA PRIBADI</span>
            <br /> <br />
            “Data Pribadi” adalah setiap data, informasi, dan/atau keterangan dalam bentuk apapun
            yang dapat mengidentifikasikan diri Anda, yang dari waktu ke waktu Anda sampaikan kepada
            Kami atau yang Anda cantumkan atau sampaikan dalam, pada, dan/atau melalui Situs yang
            menyangkut informasi mengenai pribadi Anda, yang mencakup, tetapi tidak terbatas pada;
            nama lengkap, tanggal lahir, alamat surat elektronik (e-mail), nomor telepon genggam
            (handphone), IP address, informasi lokasi, data kendaraan, data perangkat Anda, data
            yang menyangkut informasi mengenai kegiatan transaksi Anda pada Situs, data pembayaran
            yang anda daftarkan pada saat Anda melakukan aktivitas transaksi pembayaran melalui
            Situs baik yang kami dapatkan secara langsung maupun tidak langsung, dan data lainnya
            yang tergolong sebagai Data Pribadi.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">PENGUMPULAN DATA PRIBADI</span>
            <br /> <br />
            Kami akan mengumpulkan Data Pribadi Anda pada saat Anda menggunakan Situs atau pada saat
            lainnya sebagaimana Kami mintakan kepada Anda apabila dibutuhkan dari waktu ke waktu.
            Pemberian Data Pribadi Anda bersifat sukarela. Namun, jika Anda tidak memberikan Kami
            Data Pribadi Anda, Kami tidak akan dapat memproses Data Pribadi Anda untuk tujuan yang
            diuraikan di bawah ini, dan dapat menyebabkan Kami tidak dapat memberikan
            layanan-layanan atau produk-produk kepada Anda atau memproses transaksi dari Anda.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">
              INFORMASI YANG DIKUMPULKAN DARI PIHAK KETIGA
            </span>
            <br /> <br />
            Kami juga dapat mengumpulkan Data Pribadi Anda dari pihak ketiga (termasuk agen, vendor,
            pemasok, kontraktor, mitra, dan pihak lainnya yang memberikan layanan kepada Kami,
            melakukan tugas atas nama Kami, atau dengan siapa Kami melakukan kerja sama). Dalam
            kasus tersebut, Kami hanya akan mengumpulkan Data Pribadi Anda untuk atau sehubungan
            dengan tujuan yang melibatkan pihak ketiga tersebut atau tujuan kerja sama Kami dengan
            pihak ketiga tersebut (tergantung pada situasinya).
          </p>
          <div className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">PENGGUNAAN DATA PRIBADI</span>
            <br /> <br />
            Kami akan menggunakan Data Pribadi Anda untuk:
            <ol className="list-[lower-alpha] list-inside gap-2 flex flex-col">
              <li>mengidentifikasi penggunaan Anda atas Situs dan layanan-layanan Kami;</li>
              <li>memproses transaksi yang Anda mintakan;</li>
              <li>
                mencegah, mendeteksi, dan mengatasi terjadinya tindakan kejahatan yang mungkin
                terjadi dalam penggunaan Situs termasuk namun tidak terbatas pada penipuan (fraud),
                penggelapan, pencurian, money laundering;
              </li>
              <li>mengembangkan, menambah dan menyediakan produk untuk memenuhi kebutuhan Anda;</li>
              <li>urusan administrasi akun Anda;</li>
              <li>
                mengembangkan, meningkatkan, dan menyediakan layanan-layanan Kami, dan fitur-fitur
                di dalam Situs;
              </li>
              <li>
                pengiriman informasi yang Kami anggap berguna untuk Anda termasuk informasi tentang
                layanan dari Kami (newsletter atau promosi) setelah Anda memberikan persetujuan
                kepada Kami bahwa Anda tidak keberatan dihubungi mengenai layanan Kami;
              </li>
              <li>
                tujuan administratif internal, seperti; audit, analisis data, rekaman-rekaman dalam
                data base;
              </li>
              <li>
                berkomunikasi dengan Anda sehubungan dengan segala hal mengenai Situs,
                layanan-layanan Kami, dan/atau fitur-fitur daripadanya;
              </li>
              <li>
                menjaga keselamatan, keamanan, dan keberlangsungan Situs, layanan-layanan Kami,
                dan/atau fitur-fitur daripadanya.
              </li>
            </ol>
            <br />
            <br />
            <p>
              Sehubungan dengan pengumpulan dan penggunaan Data Pribadi sebagaimana diuraikan dalam
              poin-poin di atas dengan ini Anda menyatakan telah memberikan persetujuan, izin, dan
              wewenang kepada Kami untuk mengumpulkan dan menggunakan Data Pribadi Anda untuk
              tujuan-tujuan sebagaimana dimaksud dalam poin-poin di atas.
            </p>
          </div>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">
              PEMBERIAN INFORMASI PRIBADI YANG KAMI KUMPULKAN
            </span>
            <br /> <br />
            Kami dapat mengungkapkan atau membagikan Data Pribadi Anda kepada pihak ketiga (termasuk
            agen, vendor, pemasok, kontraktor, mitra, dan pihak lain yang memberikan layanan kepada
            Kami atau Anda, melakukan tugas atas nama Kami, atau pihak dengan siapa Kami mengadakan
            kerja sama komersial), untuk atau sehubungan dengan tujuan di mana pihak ketiga tersebut
            terlibat atau tujuan kerja sama Kami dengan pihak ketiga tersebut (tergantung
            keadaannya), yang dapat mencakup diperbolehkannya pihak ketiga tersebut untuk
            memperkenalkan atau menawarkan produk atau layanan kepada Anda, melakukan autentikasi
            terhadap Anda atau menghubungkan Anda dengan akun Anda atau melakukan kegiatan lain
            termasuk pemasaran, penelitian, analisis dan pengembangan produk.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">PENYIMPANAN DATA PRIBADI</span>
            <br /> <br />
            <ol className="list-[lower-alpha] list-inside gap-2 flex flex-col">
              <li>
                Data Pribadi Anda hanya akan disimpan selama diperlukan untuk memenuhi tujuan dari
                pengumpulannya, atau selama penyimpanan tersebut diperlukan atau diperbolehkan oleh
                peraturan perundang-undangan yang berlaku.
              </li>
              <li>
                Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Data Pribadi Anda
                disimpan oleh pihak lain dengan cara tertentu. Informasi yang Anda sampaikan melalui
                komunikasi antara Anda dengan pihak lain (seperti namun tidak terbatas pada: pegawai
                Kami) yang dilakukan selain melalui penggunaan Situs (seperti melalui panggilan
                telepon, SMS, pesan seluler atau cara komunikasi lainnya) juga dapat disimpan dengan
                beberapa cara. Kami tidak mengizinkan dan mendorong penyimpanan Data Pribadi dengan
                cara demikian dan Kami tidak bertanggung jawab kepada Anda untuk hal tersebut. Kami
                tidak akan bertanggung jawab atas penyimpanan Data Pribadi Anda. Anda setuju untuk
                mengganti rugi, membela, dan membebaskan Kami, pejabat, direktur, karyawan, agen,
                mitra, pemasok, kontraktor, dan afiliasi Kami dari dan terhadap setiap dan segala
                klaim, kerugian, kewajiban, biaya, kerusakan, dan ongkos (termasuk tetapi tidak
                terbatas pada biaya hukum dan pengeluaran biaya ganti rugi penuh) yang dihasilkan
                secara langsung atau tidak langsung dari setiap penyimpanan yang tidak sah atas Data
                Pribadi Anda.
              </li>
            </ol>
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">
              TEMPAT KAMI MENYIMPAN DATA PRIBADI ANDA
            </span>
            <br /> <br />
            <ol className="list-[lower-alpha] list-inside gap-2 flex flex-col">
              <li>
                Data Pribadi dari Anda yang Kami kumpulkan dapat disimpan, ditransfer, atau diolah
                oleh penyedia layanan pihak ketiga. Kami akan menggunakan semua upaya yang wajar
                untuk memastikan bahwa semua penyedia layanan pihak ketiga tersebut memberikan
                tingkat perlindungan yang sebanding dengan komitmen Kami berdasarkan Kebijakan
                Privasi ini.
              </li>
              <li>
                Data Pribadi Anda juga dapat disimpan atau diproses di luar negara Republik
                Indonesia oleh pihak yang bekerja untuk Kami di negara lain, atau oleh penyedia
                layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi Kami. Dalam
                hal tersebut, Kami akan memastikan bahwa Data Pribadi tersebut tetap menjadi subjek
                dari tingkat perlindungan yang sebanding dengan apa yang disyaratkan dalam hukum
                negara Republik Indonesia (dan, dalam hal apapun, sejalan dengan komitmen Kami dalam
                Kebijakan Privasi ini).
              </li>
            </ol>
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">PENCABUTAN PERSETUJUAN</span>
            <br /> <br />
            Anda dapat menarik persetujuan Anda untuk setiap atau segala pengumpulan, penggunaan
            atau pengungkapan Data Pribadi Anda kapan saja dengan memberikan kepada Kami
            pemberitahuan yang wajar secara tertulis menggunakan rincian kontak yang disebutkan di
            bawah ini.
            <br />
            <br />
            Tergantung pada keadaan dan sifat persetujuan yang Anda tarik, Anda harus memahami dan
            mengakui bahwa setelah penarikan persetujuan tersebut, Anda mungkin tidak lagi dapat
            menggunakan situs atau layanan. Penarikan persetujuan Anda dapat mengakibatkan
            penghentian akun Anda atau hubungan kontraktual Anda dengan Kami, dengan semua hak dan
            kewajiban yang muncul sepenuhnya harus dipenuhi. Setelah menerima pemberitahuan untuk
            menarik persetujuan untuk pengumpulan, penggunaan atau pengungkapan Data Pribadi Anda,
            Kami akan menginformasikan Anda tentang konsekuensi yang mungkin terjadi dari penarikan
            tersebut sehingga Anda dapat memutuskan apakah Anda tetap ingin menarik persetujuan.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">PENGUNGKAPAN KEPADA PIHAK KETIGA</span>
            <br /> <br />
            Untuk tujuan pengembangan, peningkatan, perlindungan, ataupun perawatan Situs dan
            layanan-layanan Kami lainnya, jika Kami diharuskan untuk mengungkapkan Data Pribadi
            kepada pihak ketiga. Anda dengan ini menyatakan telah memberikan persetujuan, izin, dan
            wewenang kepada Kami untuk mengungkapkan dan memberikan akses atas Data Pribadi kepada
            pihak ketiga untuk tujuan-tujuan sebagaimana dimaksud dalam paragraf ini.
            <br />
            <br />
            Untuk menghindari keraguan, pihak ketiga sebagaimana dimaksud dalam paragraf ini dapat
            merupakan, termasuk namun tidak terbatas pada:
            <ol className="list-[lower-alpha] list-inside gap-2 flex flex-col">
              <li>perusahaan induk, afiliasi, dan anak perusahaan Kami;</li>
              <li>
                mitra Kami, termasuk pihak-pihak dengan siapa Kami bekerja sama, termasuk namun
                tidak terbatas, untuk memproses transaksi, dan verifikasi pembayaran;
              </li>
              <li>
                perusahaan-perusahaan riset pemasaran, manajemen acara, sponsor, dan periklanan;
              </li>
              <li>
                penyedia layanan, termasuk penyedia layanan teknologi informasi (IT) untuk pekerjaan
                infrastruktur, perangkat lunak, dan pembangunan; dan
              </li>
              <li>
                penasihat profesional, auditor eksternal, penasihat hukum, keuangan, konsultan, dan
                lain-lain.
              </li>
            </ol>
            <br />
            <br />
            Sehubungan dengan pengungkapan Data Pribadi kepada pihak ketiga untuk tujuan sebagaimana
            dimaksud dalam paragraf di atas Kami dengan ini menjamin bahwa Kami akan menjaga
            keamanan dan kerahasiaan dari Data Pribadi milik Anda yang Kami ungkapkan kepada pihak
            ketiga tersebut. Selain itu, Kami hanya akan mengungkapkan Data Pribadi yang relevan
            untuk diungkapkan kepada pihak ketiga tersebut sesuai dengan tujuan pengungkapannya.
            <br />
            <br />
            Disamping itu, untuk tujuan lainnya, dalam hal-hal tertentu Kami dapat saja diwajibkan
            untuk mengungkapkan Data Pribadi Anda kepada institusi, instansi, lembaga, badan,
            ataupun pemerintah yang berwenang berdasarkan suatu peraturan perundang-undangan yang
            berlaku, surat perintah, surat keputusan, ataupun surat putusan, yang mana dalam hal
            demikian maka Anda dengan ini menyatakan bahwa Anda telah memberikan persetujuan, izin,
            dan wewenang kepada Kami untuk mengungkapkan data kepada pihak-pihak tersebut.
            <br />
            <br />
            Data Pribadi Anda juga dapat diungkapkan kepada pihak ketiga apabila terdapat transaksi
            perusahaan, seperti; pembentukan perusahaan patungan, penjualan anak perusahaan atau
            divisi, penggabungan, konsolidasi, pengambilalihan, penjualan aset, ataupun likuidasi.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-semibold text-gray-900">
              PERMINTAAN AKSES DAN KOREKSI DATA PRIBADI
            </span>
            <br /> <br />
            Tunduk pada peraturan perundang-undangan yang berlaku, Anda dapat meminta kepada Kami
            untuk mengakses dan/atau mengoreksi Data Pribadi Anda yang berada dalam kepemilikan dan
            penguasaan Kami, dengan menghubungi Kami di perincian yang disediakan di bawah ini. Kami
            berhak menolak permintaan Anda untuk mengakses dan/atau membuat koreksi terhadap Data
            Pribadi Anda karena alasan yang dibenarkan berdasarkan peraturan perundang-undangan yang
            berlaku.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-semibold text-gray-900">TAUTAN KE SITUS PIHAK KETIGA</span>
            <br /> <br />
            Situs Kami dapat saja berisi tautan ke situs pihak ketiga. Kami tidak bertanggung jawab
            atas pengumpulan, penggunaan, pemeliharaan, pembagian, atau pengungkapan Data Pribadi
            Anda oleh pihak ketiga tersebut. Jika Anda memberikan informasi langsung ke situs
            tersebut, kebijakan privasi dan ketentuan layanan di situs tersebut akan berlaku, dan
            Kami tidak bertanggung jawab atas kegiatan pengolahan informasi atau kebijakan privasi
            dari situs tersebut.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-semibold text-gray-900">
              DATA PRIBADI DARI ANAK DI BAWAH UMUR DAN INDIVIDU LAINNYA
            </span>
            <br /> <br />
            Sebagai orang tua atau wali yang sah, mohon untuk tidak mengizinkan anak di bawah umur
            (di bawah 18 (delapan belas) tahun) yang berada dalam pengawasan Anda untuk memberikan
            data pribadi-nya kepada Kami. Jika data pribadi tersebut diberikan kepada Kami, Anda
            dengan ini menyetujui pemrosesan data pribadi Anak di bawah umur tersebut dan secara
            pribadi menerima dan setuju untuk terikat oleh Kebijakan Privasi ini dan bertanggung
            jawab atas tindakannya.
            <br />
            <br />
            Dalam keadaan tertentu, Anda mungkin memberikan data pribadi yang berkaitan dengan orang
            lain (seperti pasangan, anggota keluarga, atau teman Anda) dan dalam keadaan tersebut,
            Anda berarti mewakili dan menjamin bahwa Anda telah memiliki wewenang dan persetujuan
            dari pemilik data pribadi tersebut untuk memberikan data pribadi mereka kepada Kami
            untuk digunakan sesuai dengan tujuan-tujuan diuraikan dalam Kebijakan Privasi ini.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-semibold text-gray-900">KEAKURATAN DATA PRIBADI</span>
            <br /> <br />
            Kami membutuhkan Data Pribadi Anda, salah satunya adalah untuk dapat melakukan
            pemrosesan transaksi. Oleh karena itu, Data Pribadi yang Anda berikan kepada Kami
            haruslah seakurat mungkin dan tidak menyesatkan. Selain itu, Anda harus memperbaharui
            dan memberitahukan kepada Kami apabila ada perubahan terhadap Data Pribadi Anda. Anda
            dengan ini membebaskan Kami dari setiap tuntutan, gugatan, ganti rugi, dan/atau klaim
            sehubungan dengan kegagalan pemrosesan transaksi pada aplikasi yang disebabkan oleh
            ketidakakuratan Data Pribadi Anda yang Anda berikan kepada Kami.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-semibold text-gray-900">CARA UNTUK MENGHUBUNGI KAMI</span>
            <br /> <br />
            Jika Anda memiliki pertanyaan mengenai Kebijakan Privasi ini atau Anda ingin mendapatkan
            akses dan/atau melakukan koreksi terhadap Data Pribadi Anda, silahkan hubungi:
            customercare@birdmobil.com
          </p>
        </Col>
      </Col>
    </Row>
  )
}

export default PrivacyPolicy
