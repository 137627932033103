const SliderIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33398 16.5V10.6667M3.33398 7.33333V1.5M10.0007 16.5V9M10.0007 5.66667V1.5M16.6673 16.5V12.3333M16.6673 9V1.5M0.833984 10.6667H5.83398M7.50065 5.66667H12.5006M14.1673 12.3333H19.1673"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SliderIcon
