import useValidation from 'lib/useValidation'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  deleteDataUser,
  editDataUser,
  getCities,
  getDataUser,
  getProvinces,
  getSubdistricts
} from '../service'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'

const initialState = {
  nama: '',
  email: '',
  email_company: '',
  phone: '',
  npwp: '',
  alamat: '',
  province: { label: '', value: null },
  city: { label: '', value: null },
  postal_code: '',
  sub_district: { label: '', value: null }
}
const initialDiscountState = {
  discount_type: 0,
  discount: 0,
  due_date: ''
}
const useProfile = (isB2B, token) => {
  const [modalEditProfile, setModalEditProfile] = useState(false)
  const [modalDeleteAccount, setModalDeleteAccount] = useState(false)
  const [fullData, setFullData] = useState({})
  const [state, setState] = useState({ ...initialState })
  const [errorsState, setErrorsState] = useState({ ...initialState })
  const [discountState, setDiscountState] = useState({ ...initialDiscountState })
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [subdistricts, setSubdistricts] = useState([])
  const {
    stringValidation,
    nameValidation,
    numberValidation,
    phonenumberValidation,
    emailValidation,
    addressValidation,
    handleXSSInput
  } = useValidation()

  const setUpData = (data) => {
    setFullData({ ...data })
    setState({
      nama: data?.fullname || data?.name,
      email: data?.email,
      email_company: data?.company_email,
      phone: data?.phone_number,
      npwp: data?.npwp,
      alamat: data?.address,
      province: {
        label: data?.province_name,
        value: data?.province_id === 0 ? null : data?.province_id
      },
      postal_code: data?.postal_code,
      city: {
        label: data?.city_name,
        value: data?.city_id === 0 ? null : data?.city_id
      },
      sub_district: {
        label: data?.sub_district_name,
        value: data?.sub_district_id === 0 ? null : data?.sub_district_id
      }
    })
    if (isB2B !== 'customer') {
      setDiscountState({
        discount_type: data.discount_type,
        discount: data.discount,
        due_date: data.due_date
      })
    }
  }

  // start of fetching
  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: 0
  })
  useQuery({
    queryKey: ['getCities', state?.province?.value],
    queryFn: () => getCities(state?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    staleTime: 0,
    enabled:
      state?.province?.value !== '' &&
      state?.province?.value !== null &&
      state?.province?.value !== undefined
  })
  useQuery({
    queryKey: ['getSubdistricts', state?.city?.value],
    queryFn: () => getSubdistricts(state?.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts(newData)
    },
    staleTime: 0,
    enabled:
      state?.city?.value !== '' && state?.city?.value !== null && state?.city?.value !== undefined
  })

  // user
  const { refetch } = useQuery({
    queryKey: 'getDataUser',
    queryFn: () =>
      getDataUser({
        account_type: isB2B === 'customer' ? 'personal' : 'b2b',
        token: token
      }),
    onSuccess: (data) => {
      setUpData(data)
      const dataStringify = JSON.stringify(data)
      localStorage.setItem('user_name', data?.name || data?.fullname)
      localStorage.setItem('user', dataStringify)
    },
    refetchOnWindowFocus: false
  })
  const deleteAccount = useMutation(
    ('deleteAccount',
    {
      mutationFn: () => {
        const dataSend = {
          account_type: isB2B === 'customer' ? 'personal' : 'b2b',
          token: token
        }
        return deleteDataUser(dataSend)
      },
      onSuccess: () => {
        showSuccessMessage('Akun berhasil dihapus')
        localStorage.clear()
        window.location.reload()
      },
      onError: (data) => {
        const { response } = data
        showErrorMessage(response?.data?.errors[0]?.message || 'there was an error')
      }
    })
  )
  const updateAccount = useMutation(
    ('updateAccount',
    {
      mutationFn: () => {
        const body = {
          fullname: handleXSSInput(state.nama),
          email: state.email,
          company_email: state.email_company,
          phone_number: handleXSSInput(state.phone),
          npwp: state.npwp,
          address: handleXSSInput(state.alamat),
          postal_code: state.postal_code,
          province_id: state.province.value,
          province_name: handleXSSInput(state.province.label),
          city_id: state.city.value,
          city_name: handleXSSInput(state.city.label),
          sub_district_id: state.sub_district.value,
          sub_district_name: handleXSSInput(state.sub_district.label)
        }
        if (isB2B !== 'customer') {
          delete body.company_email
        }
        const dataSend = {
          account_type: isB2B !== 'customer' ? 'b2b' : 'personal',
          body: body,
          token: token
        }
        return editDataUser(dataSend)
      },
      onSuccess: () => {
        closeModalEditAccount()
        showSuccessMessage('Akun berhasil diedit')
        refetch()
      },
      onError: (data) => {
        const { response } = data
        showErrorMessage(response?.data?.errors[0]?.message || 'there was an error')
      }
    })
  )
  // end of fetching

  // function
  const handleChange = (name, value) => {
    let err = ''
    switch (name) {
      case 'nama':
        err = nameValidation(value)
        break
      case 'phone':
        err = phonenumberValidation(value)
        break
      // case 'npwp':
      //   err = numberValidation(value, 'NPWP')
      //   break
      case 'email':
        err = emailValidation(value)
        break
      case 'alamat':
        err = addressValidation(value)
        break
      default:
        err = ''
    }
    setErrorsState((prev) => ({
      ...prev,
      [name]: err
    }))
    setState((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeLocation = (name, value) => {
    let newValue = {}
    if (name === 'province') {
      setCities([])
      setSubdistricts([])
      newValue = {
        ...state,
        province: value,
        city: {},
        sub_district: ''
      }
    } else if (name === 'city') {
      setSubdistricts([])
      newValue = {
        ...state,
        city: value,
        sub_district: ''
      }
    } else {
      newValue = {
        ...state,
        sub_district: value
      }
    }

    setState(newValue)
  }

  const handleSubmitEdit = () => {
    let nameError = ''
    let nameInput = ''
    let validError = true
    let validInput = true
    const newObject = { ...state }
    delete newObject.email_company
    delete newObject.province
    delete newObject.city
    delete newObject.sub_district

    const newError = { ...errorsState }
    delete newError.email_company
    delete newError.province
    delete newError.city
    delete newError.sub_district

    if (isB2B === 'customer') {
      delete newObject.npwp
      delete newError.npwp
    }

    Object.keys(newObject).forEach((key) => {
      if (newObject[key].length === 0) {
        validInput = false
        nameInput = String(key)
      }
    })
    Object.keys(newError).forEach((key) => {
      if (newError[key].length > 0) {
        validError = false
        nameError = String(key)
      }
    })
    if (state.province.value === '' || state?.province?.value === undefined) {
      showErrorMessage('Mohon isi Provinsi')
    } else if (state.city.value === '' || state?.city?.value === undefined) {
      showErrorMessage('Mohon isi Kota')
    } else if (state.sub_district.value === '' || state?.sub_district?.value === undefined) {
      showErrorMessage('Mohon isi Kecamatan')
    } else if (
      !isB2B &&
      state?.email_company !== '' &&
      !state?.email_company?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      showErrorMessage('Email tidak valid')
    } else if (isNaN(state.npwp)) {
      showErrorMessage('NPWP harus angka')
    } else if (isNaN(state.postal_code)) {
      showErrorMessage('Kode Pos harus angka')
    } else {
      if (validInput) {
        if (validError) {
          updateAccount.mutate()
        } else {
          showErrorMessage(`Mohon perbaiki ${nameToString[nameError]}`)
        }
      } else {
        showErrorMessage(`Mohon isi ${nameToString[nameInput]}`)
      }
    }
  }

  function openModalDeleteAccount() {
    setModalDeleteAccount(true)
  }
  function closeModalDeleteAccount() {
    setModalDeleteAccount(false)
  }
  function openModalEditAccount() {
    setModalEditProfile(true)
  }
  function closeModalEditAccount() {
    setModalEditProfile(false)
    handleCancelEdit()
  }

  const handleCancelEdit = () => {
    setState({
      nama: fullData?.fullname || '',
      email: fullData?.email || '',
      email_company: fullData?.company_email || '',
      phone: fullData?.phone_number || '',
      npwp: fullData?.npwp || '',
      postal_code: fullData?.postal_code || '',
      alamat: fullData?.address || '',
      province: {
        label: fullData?.province_name,
        value: fullData?.province_id
      },
      city: {
        label: fullData?.city_name,
        value: fullData?.city_id
      },
      sub_district: {
        label: fullData?.sub_district_name,
        value: fullData?.sub_district_id
      }
    })
    setErrorsState({ ...initialState })
  }
  const handleDeleteAccount = () => {
    deleteAccount.mutate()
  }
  return {
    modalEditProfile,
    modalDeleteAccount,
    state,
    discountState,
    cities,
    fullData,
    errorsState,
    provinces,
    subdistricts,
    handleChange,
    handleChangeLocation,
    handleDeleteAccount,
    handleCancelEdit,
    openModalDeleteAccount,
    openModalEditAccount,
    closeModalDeleteAccount,
    closeModalEditAccount,
    handleSubmitEdit
  }
}

export default useProfile
