import AuthOtp from 'components/auth-otp/auth-otp'
import { getDataUser } from 'features/profile/service'
import useValidation from 'lib/useValidation'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  getCities,
  getProvinces,
  getSubdistricts,
  registerCompany,
  sendOtp,
  requestOtp
} from '../service'
import RegisterTypeCompanyMain from './register-type-company-main'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage } from 'utils/toastMessage'

const initialFirstPageState = {
  npwp: '',
  password: '',
  email: '',
  phone_number: '',
  company_name: ''
}

const initialSecondPageState = {
  postal_code: '',
  subdistrict_id: '',
  city_id: '',
  address: '',
  province_id: ''
}

function RegisterTypeCompany() {
  const { push } = useHistory()
  const {
    nameValidation,
    numberValidation,
    passValidation,
    emailValidation,
    handleXSSInput,
    addressValidation
  } = useValidation()

  const [isNext, setIsNext] = useState(true)
  const [isDetail, setIsDetail] = useState(true)
  const [agree, setAgree] = useState(false)
  const [firstState, setFirstState] = useState({ ...initialFirstPageState })
  const [secondState, setSecondState] = useState({ ...initialSecondPageState })
  const [firstError, setFirstError] = useState({ ...initialFirstPageState })
  const [secondError, setSecondError] = useState({ ...initialSecondPageState })

  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [subdistricts, setSubdistricts] = useState([])

  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: Infinity
  })

  useQuery({
    queryKey: ['getCities', secondState.province_id],
    queryFn: () => getCities(secondState.province_id),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    enabled: secondState.province_id !== ''
  })

  useQuery({
    queryKey: ['getSubdistricts', secondState.city_id],
    queryFn: () => getSubdistricts(secondState.city_id),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts(newData)
    },
    enabled: secondState.city_id !== ''
  })

  const handleChangeFirstState = (e) => {
    const { name, value } = e.target
    let err = ''

    switch (name) {
      case 'company_name':
        err = nameValidation(value)
        break
      case 'phone_number':
        err = numberValidation(value, 'Nomor Telepon')
        break
      case 'npwp':
        err = numberValidation(value, 'NPWP')
        break
      case 'email':
        err = emailValidation(value)
        break
      case 'password':
        err = passValidation(value)
        break
      default:
        err = ''
    }

    setFirstState((prev) => ({
      ...prev,
      [name]: value
    }))
    setFirstError((prev) => ({
      ...prev,
      [name]: err
    }))
  }

  const handleChangeSecondState = (e) => {
    const { name, value } = e.target
    let err = ''

    switch (name) {
      case 'address':
        err = addressValidation(value)
        break
      case 'postal_code':
        err = numberValidation(value, 'Kode POS')
        break
      case 'npwp':
        err = numberValidation(value, 'NPWP')
        break
      default:
        err = ''
    }

    setSecondState((prev) => ({
      ...prev,
      [name]: value
    }))
    setSecondError((prev) => ({
      ...prev,
      [name]: err
    }))
  }

  const handleChangeLocation = (name, value) => {
    let newValue = {}
    if (name === 'province_id') {
      setCities([])
      setSubdistricts([])
      newValue = {
        ...secondState,
        province_id: value,
        city_id: '',
        subdistrict_id: ''
      }
    } else if (name === 'city_id') {
      setSubdistricts([])
      newValue = {
        ...secondState,
        city_id: value,
        subdistrict_id: ''
      }
    } else if (name === 'subdistrict_id') {
      newValue = {
        ...secondState,
        subdistrict_id: value
      }
    }
    setSecondState(newValue)
  }

  const handleRegisterCompany = (data) => {
    sessionStorage.setItem('token', data.access_token)
    refetch()
    setIsNext(false)
  }

  const { refetch: getDataRefetch } = useQuery({
    queryKey: 'getCompanyProfile',
    queryFn: () => {
      const token = localStorage.getItem('token')
      return getDataUser({ account_type: 'b2b', token: token })
    },
    onSuccess: (data) => {
      localStorage.setItem('account_type', 'company')
      const dataStringify = JSON.stringify(data)
      localStorage.setItem('user_name', data?.name)
      localStorage.setItem('user', dataStringify)
      push('/')
    },
    enabled: false
  })

  const submitOtp = useMutation(sendOtp, {
    onSuccess: (data) => {
      // console.log(data)
      const token = sessionStorage.getItem('token')
      localStorage.setItem('token', token)
      sessionStorage.removeItem('token')
      getDataRefetch()
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })

  const { refetch } = useQuery('newOtpRequest', requestOtp, {
    onSuccess: (data) => {
      // console.log(data)
    },
    enabled: false
  })

  const { mutate, isLoading } = useMutation(registerCompany, {
    onSuccess: (data) => {
      // console.log(data)
      handleRegisterCompany(data)
    },
    onError: (data) => {
      const { response } = data
      // console.log(response?.data?.errors[0]?.message)
      showErrorMessage(response?.data?.errors[0]?.message || 'there was an error')
    }
  })

  const handleChangeAgree = (e) => {
    const { checked } = e.target
    setAgree(checked)
  }
  const handleClick = () => {
    // validate
    let nameInput = ''
    let nameError = ''
    let validError = true
    let validInput = true
    Object.keys(firstState).forEach((key) => {
      if (firstState[key].length === 0) {
        validInput = false
        nameInput = String(key)
      }
    })
    Object.keys(firstError).forEach((key) => {
      if (firstError[key].length > 0) {
        validInput = false
        nameError = String(key)
      }
    })
    // Object.values(firstState).forEach((v) => {
    //   if (v.length === 0) {
    //     validInput = false
    //   }
    // })
    // Object.values(firstError).forEach((v) => {
    //   if (v.length > 0) {
    //     validError = false
    //   }
    // })
    if (validInput) {
      if (validError) {
        if (firstState.npwp.length === 16) {
          setIsDetail(false)
        } else {
          showErrorMessage('NPWP harus 16 digit')
        }
      } else {
        showErrorMessage(`Mohon perbaiki ${nameToString[nameError]}`)
      }
    } else {
      showErrorMessage(`Mohon isi ${nameToString[nameInput]}`)
    }
  }
  const handleSubmit = () => {
    const sendData = {
      email: firstState.email,
      password: firstState.password,
      company_name: handleXSSInput(firstState.company_name),
      phone_number: firstState.phone_number,
      npwp: firstState.npwp,
      address: handleXSSInput(secondState.address),
      province_id: parseInt(secondState.province_id),
      city_id: parseInt(secondState.city_id),
      subdistrict_id: parseInt(secondState.subdistrict_id),
      postal_code: secondState.postal_code
    }
    // console.log('aww', sendData)
    let nameInput = ''
    let nameError = ''
    let validError = true
    let validInput = true
    Object.keys(secondState).forEach((key) => {
      if (secondState[key].length === 0) {
        validInput = false
        nameInput = String(key)
      }
    })
    Object.keys(secondError).forEach((key) => {
      console.log('secondError', secondError[key])
      if (secondError[key].length > 0) {
        validInput = false
        nameError = String(key)
      }
    })
    console.log(nameError, nameInput, handleXSSInput(secondState.address))
    // Object.values(secondState).forEach((v) => {
    //   if (v.length === 0) {
    //     validInput = false
    //   }
    // })
    // Object.values(secondError).forEach((v) => {
    //   if (v.length > 0) {
    //     validError = false
    //   }
    // })
    if (validInput) {
      if (validError) {
        mutate(sendData)
      } else {
        showErrorMessage(`Mohon perbaiki ${nameToString[nameError]}`)
      }
    } else {
      showErrorMessage(`Mohon isi atau perbaiki ${nameToString[nameError]}`)
    }
  }

  const handleSubmitOtp = (otp) => {
    const dataSend = {
      otp_code: otp
    }
    submitOtp.mutate(dataSend)
  }
  const handleClickResendOtp = () => {
    refetch()
  }

  const handleBack = () => {
    setIsNext(true)
  }
  return (
    <>
      {isNext ? (
        <RegisterTypeCompanyMain
          firstState={firstState}
          secondState={secondState}
          firstError={firstError}
          secondError={secondError}
          agree={agree}
          provinces={provinces}
          cities={cities}
          subdistricts={subdistricts}
          isNext={isDetail}
          isLoading={isLoading}
          setIsNext={setIsDetail}
          handleChangeFirstState={handleChangeFirstState}
          handleChangeSecondState={handleChangeSecondState}
          handleChangeLocation={handleChangeLocation}
          handleChangeAgree={handleChangeAgree}
          handleNextDetail={handleClick}
          handleSubmit={handleSubmit}
          handleRegisterCompany={handleRegisterCompany}
        />
      ) : (
        <AuthOtp
          title="Registrasi BirdMobil"
          handleBack={handleBack}
          handleSubmit={handleSubmitOtp}
          handleResend={handleClickResendOtp}
        />
      )}
    </>
  )
}

export default RegisterTypeCompany
