import { Col, Dropdown, Input, Row, Typography } from 'antd'
import { EyeIcon, MagnifyingIcon, MoreVerticalIcon, TrashIcon } from 'assets'
import PlusIcon from 'assets/icons/plus'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import CustomTable from 'components/ui/custom-table'
import React, { useState, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import ConfirmationModal from 'components/ui/confirmation-modal'
import ModalAddUser from 'components/ui/modal/modal-add-user'
import { useMutation, useQuery } from 'react-query'
import { addCompanyUser, deleteUser, getUsers, searchUser } from 'features/detail-users/service'
import { debounce } from 'lodash'
import { ModalLayout } from 'layouts'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
const initialFilter = {
  keywords: '',
  limit: 5,
  offset: 0
}

function UsersPage() {
  const { replace } = useHistory()
  const token = localStorage.getItem('token')
  const isB2B = localStorage.getItem('account_type')
  if (token === null || isB2B === 'customer') {
    replace('/')
  }
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [filter, setFilter] = useState({ ...initialFilter })
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)
  const [userKeyword, setUserKeyword] = useState('')

  // list
  const [users, setUsers] = useState(null)

  const selectedUser = useRef({})
  const { push } = useHistory()

  // start of fetching
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      getDataUser.refetch()
    }, 500),
    []
  )

  const getDataUser = useQuery({
    queryKey: ['getUsers', debouncedChangeHandler],
    queryFn: () =>
      getUsers({
        params: {
          ...filter
        },
        token: token
      }),
    onSuccess: (data) => {
      // console.log(data)
      setUsers(data)
    },
    enabled: Boolean(debouncedChangeHandler)
  })

  const addUserCompany = useMutation(addCompanyUser, {
    onSuccess: (data) => {
      // console.log(data)
      setUserKeyword('')
      setIsOpen(false)
      debouncedChangeHandler()
    },
    onError: (data) => {
      const { response } = data
      // console.log(response?.data?.errors[0]?.message)
      showErrorMessage(response?.data?.errors[0]?.message || 'there was an error')
    }
  })

  const deleteDataUser = useMutation('deleteDataUser', {
    mutationFn: () => {
      const dataSend = {
        id: selectedUser.current.id,
        token: token
      }

      return deleteUser(dataSend)
    },
    onSuccess: () => {
      showSuccessMessage('Data user berhasil dihapus')
      setIsOpenConfirmation(false)

      return debouncedChangeHandler()
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message)
      setIsOpenConfirmation(false)
    }
  })

  const findUser = useQuery({
    queryKey: 'findUser',
    queryFn: () =>
      searchUser({
        params: {
          keyword: userKeyword
        },
        token: token
      }),
    onSuccess: (data) => {
      // console.log(data)
      const dataSend = {
        id: data.id,
        token: token
      }
      addUserCompany.mutate(dataSend)
    },
    onError: (data) => {
      // console.log(data)
      setIsOpen(false)
      setIsOpenAdd(true)
    },
    enabled: false
  })
  // end of fetching

  // fucntion
  const handleChangeKeyword = (value) => setUserKeyword(value)
  const handleChangeFilter = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))
    return debouncedChangeHandler()
  }
  const handleChangeFilterIncludeOffset = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
      offset: 0
    }))
    return debouncedChangeHandler()
  }

  const handleSearch = () => {
    findUser.refetch()
  }
  const handleAdd = () => push(`/users/add-user`, { addToCompany: true })
  const handleConfirmDelete = () => {
    deleteDataUser.mutate()
  }
  const handleDelete = (row) => {
    selectedUser.current = row
    setIsOpenConfirmation(true)
  }
  const onChangeNextPage = () => {
    if (currentPage !== Math.ceil(users?.meta?.total / filter.limit)) {
      handleChangeFilter('offset', currentPage * filter.limit)
      setCurrentPage((prev) => prev + 1)
    }
  }
  const onChangePrevPage = () => {
    if (currentPage > 1) {
      handleChangeFilter('offset', filter.offset - filter.limit)
      setCurrentPage((prev) => prev - 1)
    }
  }

  const optionsButton = (row) => [
    { icon: EyeIcon, label: 'Detail User', onClick: () => push('/users/detail', { id: row.id }) },
    { icon: TrashIcon, label: 'Delete', onClick: handleDelete }
  ]
  const columns = [
    {
      name: 'Nama',
      selector: (row) => row.name,
      cell: (row) => (
        <div className="flex flex-col">
          <p
            className="text-sm-medium cursor-pointer"
            onClick={() =>
              push('/users/detail', {
                id: row.id
              })
            }>
            {row?.name}
          </p>
          <p className="text-xs-regular mt-1">{row?.user_number || '-'}</p>
        </div>
      ),
      sortable: true
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      cell: (row) => (
        <Typography.Text
          // className="w-32 2xl:w-full"
          ellipsis={{
            tooltip: row?.email
          }}>
          {row?.email}
        </Typography.Text>
      ),
      sortable: true
    },
    {
      name: 'Nomor Telepon',
      selector: (row) => row.phone_number,
      sortable: true
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <Dropdown dropdownRender={DropdownRender.bind(this, optionsButton(row), row)}>
          <div className="flex items-center gap-2">
            <a
              onClick={(e) => {
                e.preventDefault()
              }}
              className="flex items-center gap-2">
              <MoreVerticalIcon className="text-gray-600" />
            </a>
          </div>
        </Dropdown>
      ),
      right: true,
      width: '100px'
    }
  ]
  return (
    <>
      <Row className="gap-6">
        {/* Header */}
        <Col
          span={24}
          className="hidden md:flex justify-between items-center text-xl-medium text-gray-900">
          Users
        </Col>
        {/* Content */}
        <CardSectionHeader
          hideDivider
          headerTitle={
            <div className="flex items-center justify-between">
              Users
              <CustomButton
                onClick={() => setIsOpen(true)}
                label={
                  <div className="flex items-center gap-3 justify-center stroke-white">
                    <PlusIcon /> Tambah Users
                  </div>
                }
              />
            </div>
          }>
          <Col span={24} className="mt-5">
            <div className="grid grid-cols-1 gap-6">
              {/* Filter & Search */}
              <div className="flex justify-end items-center">
                <div className="w-full">
                  <Input
                    size="medium"
                    className="text-md-normal text-gray-500 md:w-[400px] px-[14px] py-[10px] rounded-lg"
                    prefix={<MagnifyingIcon className={'mr-1'} />}
                    placeholder="Cari Users"
                    name="keywords"
                    onChange={(e) => handleChangeFilterIncludeOffset(e.target.name, e.target.value)}
                    value={filter.keyword}
                  />
                </div>
              </div>
              {/* Table */}
              <CustomTable
                columns={columns}
                data={users?.data || []}
                currentPage={filter?.offset === 0 ? 1 : filter?.offset + 1}
                totalPerPage={
                  currentPage * filter?.limit >= users?.meta.total
                    ? users?.meta.total
                    : currentPage * filter?.limit
                }
                lengthAllData={users?.meta?.total}
                defaultPageSize={filter?.limit}
                onChangePageSize={handleChangeFilterIncludeOffset}
                onChangeNextPage={onChangeNextPage}
                onChangePrevPage={onChangePrevPage}
              />
            </div>
          </Col>
        </CardSectionHeader>
      </Row>

      {/* modal tambah user */}
      <ModalAddUser
        openModal={isOpen}
        onCancel={() => setIsOpen(false)}
        onClick={handleSearch}
        userKeyword={userKeyword}
        handleChangeKeyword={handleChangeKeyword}
      />

      {/* modal konfirmasi add user */}
      <ConfirmationModal
        openModal={isOpenAdd}
        onClick={handleAdd}
        className="w-[400px]"
        confirmLabel="Tambah"
        onCancel={() => setIsOpenAdd(false)}
        headerTitle="Konfirmasi Tambah User"
        contentDescription={`User tidak ditemukan apakah Anda ingin menambah user baru?`}
      />

      {/* modal confirmation delete */}
      <ConfirmationModal
        openModal={isOpenConfirmation}
        onClick={handleConfirmDelete}
        className="w-[400px]"
        confirmLabel="Hapus"
        onCancel={() => setIsOpenConfirmation(false)}
        headerTitle="Konfirmasi Hapus User"
        contentDescription={`Apakah Anda yakin ingin menghapus user ${
          selectedUser?.current?.unitOwner || ''
        } ini? Data yang Anda hapus tidak dapat dipulihkan kembali.`}
      />
    </>
  )
}

const DropdownRender = (optionsButton, row) => {
  return (
    <div className="bg-white rounded-[4px] border shadow-sm w-[200px] border-gray-200">
      <div className="flex flex-col gap-2">
        {optionsButton.map(({ icon: Icon, label, onClick }, key) => {
          return (
            <button
              key={key}
              className="flex items-center bg-white border-0 gap-2 rounded-[4px] text-sm-normal stroke-gray-700 p-2 text-gray-700 hover:text-primary-500 hover:stroke-primary-500 hover:bg-gray-100"
              onClick={() => onClick(row)}>
              <Icon />
              {label}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default UsersPage
