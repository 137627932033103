import { AuthLayout, DashboardLayout, RootLayout } from 'layouts'
import ForgotPassword from 'pages/forgot-password-page'
import LandingPage from 'pages/landing-page'
import SignInPage from 'pages/sign-in-page'
import AboutUsPage from 'pages/about-us-page'
import Register from 'pages/register-page'
import RegisterType from 'pages/register-type-page'
import DashboardPage from 'pages/dashboard-page'
import WishlistPage from 'pages/wishlist-page'
import TransactionPage from 'pages/transaction-page'
import UsersPage from 'pages/users-page'
import ReportPage from 'pages/report-page'
import CreateEditUnitPage from 'pages/create-edit-unit-page'
import DetailUnitPage from 'pages/detail-unit-page'
import DetailServicePage from 'pages/detail-service-page'
import DetailSellingPage from 'pages/detail-selling-page'
import DetailInspectionPage from 'pages/detail-inspection-page'
import RedunantDetailInspectionPage from 'pages/redundant-detail-inspection-page'
// import RedundantTransactionDetail from 'pages/redundant-transaction-detail'
import ReportTypePage from 'pages/report-type-page'
import BeliMobilBekasPage from 'pages/beli-mobil-bekas-page'
import BeliExTaxiPage from 'pages/beli-ex-taxi-page'
import ProfilePage from 'pages/profile-page'
import CreateEditUserPage from 'pages/create-edit-user-page'
import BeliMobilDetailPage from 'pages/beli-mobil-detail-page'
import BeliExTaxiDetailPage from 'pages/beli-ex-taxi-detail-page'
import JualMobilPage from 'pages/jual-mobil-page'
import BookingMobilPage from 'pages/booking-mobil-page'
import ScheduledTestDrivePage from 'pages/scheduled-test-drive-page'
import TestDriveResultPage from 'pages/test-drive-result-page'
import BookingMobilResultPage from 'pages/booking-mobil-result-page'
import FormJualMobilPage from 'pages/form-jual-mobil-page'
import DetailUserPage from 'pages/detail-user-page'
import ServisPage from 'pages/servis-page'
import InspeksiPage from 'pages/inspeksi-page'
import ReminderPage from 'pages/reminder-page'
import FormInspeksiMobilPage from 'pages/form-inspeksi-mobil-page'
import FormServicePage from 'pages/form-service-page'
import FormAddUnitPage from 'pages/form-add-unit-page'
import NotificationPage from 'pages/notification-page'
// import RedundantDetailUnitPage from 'pages/redundant-detail-unit-page'
import PaymentPage from 'pages/payment-page'
import VerifPaymentSuccessPage from 'pages/verif-payment-success-page'

export const routeList = [
  {
    path: '/payment/verification/result',
    component: VerifPaymentSuccessPage,
    layout: RootLayout
  },
  {
    path: '/notification',
    component: NotificationPage,
    layout: RootLayout
  },
  {
    path: '/servis/servis-mobil/result',
    component: TestDriveResultPage,
    layout: RootLayout
  },
  {
    path: '/servis/servis-mobil/payment',
    component: PaymentPage,
    layout: RootLayout
  },
  {
    path: '/servis/servis-mobil',
    component: FormServicePage,
    layout: RootLayout
  },
  {
    path: '/add-unit/form',
    component: FormAddUnitPage,
    layout: RootLayout
  },
  {
    path: '/inspeksi/form/payment',
    component: PaymentPage,
    layout: RootLayout
  },
  {
    path: '/inspeksi/form/result',
    component: BookingMobilResultPage,
    layout: RootLayout
  },
  {
    path: '/inspeksi/form',
    component: FormInspeksiMobilPage,
    layout: RootLayout
  },
  {
    path: '/inspeksi',
    component: InspeksiPage,
    layout: RootLayout
  },
  {
    path: '/servis',
    component: ServisPage,
    layout: RootLayout
  },
  {
    path: '/beli/:type/detail-inspection',
    component: DetailInspectionPage,
    layout: RootLayout
  },
  {
    path: '/jual/form/result',
    component: TestDriveResultPage,
    layout: RootLayout
  },
  {
    path: '/jual/inspeksi/result',
    component: TestDriveResultPage,
    layout: RootLayout
  },
  // {
  //   path: '/jual/form/result',
  //   component: BookingMobilResultPage,
  //   layout: RootLayout
  // },
  {
    path: '/beli/ex-taxi/booking/payment',
    component: PaymentPage,
    layout: RootLayout
  },
  {
    path: '/beli/mobil-bekas/booking/payment',
    component: PaymentPage,
    layout: RootLayout
  },
  {
    path: '/beli/mobil-bekas/booking/result',
    component: BookingMobilResultPage,
    layout: RootLayout
  },
  {
    path: '/beli/:type/test-drive/result',
    component: TestDriveResultPage,
    layout: RootLayout
  },
  {
    path: '/beli/:type/test-drive',
    component: ScheduledTestDrivePage,
    layout: RootLayout
  },
  {
    path: '/beli/ex-taxi/booking/result',
    component: BookingMobilResultPage,
    layout: RootLayout
  },
  {
    path: '/beli/ex-taxi/booking',
    component: BookingMobilPage,
    layout: RootLayout
  },
  {
    path: '/beli/mobil-bekas/booking',
    component: BookingMobilPage,
    layout: RootLayout
  },
  {
    path: '/jual/form',
    component: FormJualMobilPage,
    layout: RootLayout
  },
  {
    path: '/jual',
    component: JualMobilPage,
    layout: RootLayout
  },
  {
    path: '/beli/ex-taxi/detail/:id',
    component: BeliExTaxiDetailPage,
    layout: RootLayout
  },
  {
    path: '/beli/ex-taxi',
    component: BeliExTaxiPage,
    layout: RootLayout
  },
  {
    path: '/beli/mobil-bekas/detail/:id',
    component: BeliMobilDetailPage,
    layout: RootLayout
  },
  {
    path: '/beli/mobil-bekas',
    component: BeliMobilBekasPage,
    layout: RootLayout
  },
  {
    path: '/dashboard/reminder',
    component: ReminderPage,
    layout: DashboardLayout
  },
  {
    path: '/dashboard/detail-unit/inspection-detail',
    component: DetailInspectionPage,
    layout: DashboardLayout
  },
  {
    path: '/dashboard/unit/detail-unit',
    component: DetailUnitPage,
    layout: DashboardLayout
  },
  {
    path: '/dashboard/edit-unit',
    component: CreateEditUnitPage,
    layout: DashboardLayout
  },
  {
    path: '/dashboard/add-unit',
    component: CreateEditUnitPage,
    layout: DashboardLayout
  },
  {
    path: '/users/add-user',
    component: CreateEditUserPage,
    layout: DashboardLayout
  },
  {
    path: '/users/detail/unit',
    component: DetailUnitPage,
    layout: DashboardLayout
  },
  {
    path: '/users/detail',
    component: DetailUserPage,
    layout: DashboardLayout
  },
  {
    path: '/users/edit-user/:id',
    component: CreateEditUserPage,
    layout: DashboardLayout
  },
  {
    path: '/transaction/transaction-detail/:serviceName/:idTransaction',
    component: DetailServicePage,
    layout: DashboardLayout
  },
  {
    path: '/transaction/transaction-detail/selling-detail',
    component: DetailSellingPage,
    layout: DashboardLayout
  },
  {
    path: '/transaction/transaction-detail',
    component: DetailServicePage,
    layout: DashboardLayout
  },
  {
    path: '/transaction/inspection-detail',
    component: DetailInspectionPage,
    layout: DashboardLayout
  },
  // redundant page
  {
    path: '/transaction/selling-detail',
    component: RedunantDetailInspectionPage,
    layout: DashboardLayout
  },
  // {
  //   path: '/transaction/transaction-detail',
  //   component: RedundantTransactionDetail,
  //   layout: DashboardLayout
  // },
  // end of redundant page

  {
    path: '/report/:type',
    component: ReportTypePage,
    layout: DashboardLayout
  },
  {
    path: '/report',
    component: ReportPage,
    layout: DashboardLayout
  },
  {
    path: '/profile',
    component: ProfilePage,
    layout: DashboardLayout
  },
  {
    path: '/users',
    component: UsersPage,
    layout: DashboardLayout
  },
  {
    path: '/wishlist',
    component: WishlistPage,
    layout: DashboardLayout
  },
  {
    path: '/transaction',
    component: TransactionPage,
    layout: DashboardLayout
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    layout: DashboardLayout
  },
  {
    path: '/about-us/:content',
    component: AboutUsPage,
    layout: RootLayout
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    layout: AuthLayout
  },
  {
    path: '/register/:type',
    component: RegisterType,
    layout: AuthLayout
  },
  {
    path: '/register',
    component: Register,
    layout: AuthLayout
  },
  {
    path: '/login',
    component: SignInPage,
    layout: AuthLayout
  },
  {
    path: '/',
    component: LandingPage,
    layout: RootLayout
  },
  {
    component: LandingPage,
    layout: RootLayout
  }
]
