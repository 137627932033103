import PlusIcon from 'assets/icons/plus'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
import CreditCardItem from './credit-card-item'
import Slider from 'react-slick'
import { CarouselMultipleLayout } from 'components/ui/CarouselMultipleLayout'

const CardDebitCreditList = ({
  handleClick,
  creditCards,
  handlePreviewSelectedCard,
  openModalAddCard,
  handleConfirmDeleteCard,
  width
}) => {
  return (
    <CardSectionHeader
      headerTitle={
        <div className="flex justify-between">
          <div>Debit/Credit Card Saya</div>
          <CustomButton
            label={
              <div className="flex justify-center items-center gap-1 ">
                <PlusIcon className={'stroke-white'} />
                Tambah Kartu
              </div>
            }
            onClick={handleClick}
          />
        </div>
      }
      hideDivider>
      <div className="pt-2 ">
        {creditCards?.length > 0 ? (
          <CarouselMultipleLayout
            arrows
            slideToShow={5}
            slidesToScroll={5}
            totalItem={creditCards?.length}
            className={'overflow-hidden'}
            responsive={[
              {
                breakpoint: 1441,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4
                }
              },
              {
                breakpoint: 1285,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}>
            {creditCards?.map((card, index) => (
              <CreditCardItem
                width={width}
                handleConfirmDeleteCard={handleConfirmDeleteCard.bind(this, card?.id)}
                card={card}
                key={index}
                onClick={handlePreviewSelectedCard.bind(this, card)}
              />
            ))}
          </CarouselMultipleLayout>
        ) : (
          // <>
          //   {creditCards?.map((card, index) => (
          //     <CreditCardItem
          //       width={width}
          //       handleConfirmDeleteCard={handleConfirmDeleteCard.bind(this, card?.id)}
          //       card={card}
          //       key={index}
          //       onClick={handlePreviewSelectedCard.bind(this, card)}
          //     />
          //   ))}
          // </>
          <div
            onClick={openModalAddCard}
            className="flex cursor-pointer w-full py-3 h-32 justify-center items-center bg-gray-50 flex-col">
            <p className="text-sm-semibold text-gray-500">Belum ada kartu yang tersedia</p>
            <p className="text-primary-500 text-sm-semibold">Tambah Kartu</p>
          </div>
        )}
      </div>
    </CardSectionHeader>
  )
}

export default CardDebitCreditList
