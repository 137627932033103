import React from 'react'
import PembelianSelesaiBeli from '../card-status-transaction/beli/pembelian-selesai-beli'
import CardDetailCar from 'components/ui/card/card-detail-car'
import CardInformationUser from 'components/ui/card/card-information-user'
import { onRupiah } from 'utils/formatCurrency'
import { CardDetailPayment } from '..'
import BookingLunasBeli from '../card-status-transaction/beli/booking-lunas-beli'
import BookingBeli from '../card-status-transaction/beli/booking-beli'
import KonfirmasiPembayaranCard from '../templates-status-card/konfirmasi-pembayaran-card'
import ServiceInfoCard from '../templates-status-card/service-info-card'
import WaitingListBeli from '../card-status-transaction/beli/waiting-list-beli'

const BeliContentTransaction = ({
  data,
  status,
  navigateHandler,
  serviceName,
  bookingProcessDate,
  validationCheckPaymentMethod
}) => {
  const CardStatusTransactionBeliMap = {
    'menunggu pembayaran': (
      <KonfirmasiPembayaranCard
        data={data}
        validationCheckPaymentMethod={validationCheckPaymentMethod}
        serviceName={serviceName}
      />
    ),
    booking: <BookingBeli data={data?.booking} />,
    'booking lunas': (
      <BookingLunasBeli
        data={{
          total_price: data?.catalog_invoice?.total_price,
          sold_at: data?.catalog?.sold_at
        }}
      />
    ),
    'waiting list': <WaitingListBeli data={bookingProcessDate} />,
    failed: <ServiceInfoCard status={'beli_failed'} />,
    selesai: (
      <PembelianSelesaiBeli
        data={{
          end_time: data?.end_time,
          total_price: data?.catalog_invoice?.total_price
        }}
      />
    )
  }
  return (
    <>
      {CardStatusTransactionBeliMap[status]}
      <CardDetailCar data={data} navigateDetailHandler={navigateHandler} />
      {data?.unit_type !== 'taxi' && (
        <CardInformationUser
          headerTitle={'Informasi Penjual'}
          data={{
            nama: data?.catalog?.owner_fullname,
            email: data?.catalog?.owner_email,
            phone: data?.catalog?.owner_phone_number,
            alamat: data?.catalog?.owner_address
          }}
        />
      )}
      <CardDetailPayment
        data={data?.dataPayment}
        total={onRupiah(data?.catalog_invoice?.total_price)}
      />
    </>
  )
}

export default BeliContentTransaction
