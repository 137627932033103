import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import moment from 'moment'
const CardRentangProsesBookingMobilBekas = ({ data }) => {
  return (
    <div>
      <CardSectionHeader headerTitle="Informasi Tanggal Booking" className="flex flex-col gap-2">
        <div className="flex gap-2 rounded justify-between items-center p-3 bg-success-50 text-md-medium text-gray-700">
          <p>{moment(data?.start_time).format('DD MMMM YYYY')}</p>
          <p>-</p>
          <p>{moment(data?.end_time).format('DD MMMM YYYY')}</p>
        </div>
        <p className="text-xs-medium text-gray-500">
          Informasi ketersediaan tanggal booking dapat berubah sewaktu-waktu jika sudah dipesan oleh
          customer lain
        </p>
      </CardSectionHeader>
    </div>
  )
}

export default CardRentangProsesBookingMobilBekas
