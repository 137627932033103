import { ChecklistIcon, ImagePlaceholder } from 'assets'
import BadgeItem from 'components/ui/badge-item'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { Link } from 'react-router-dom'
import { onRupiah } from 'utils/formatCurrency'
// eslint-disable-next-line no-undef
const urlImage = process.env.REACT_APP_IMAGE_BASE_URL

const CardUnit = ({ dataCatalog, isTaxi, is_create_your_own_car }) => {
  return (
    <CardSectionHeader hideDivider>
      <div className="flex flex-col md:flex-row gap-6">
        {/* Image Preview */}
        {dataCatalog?.thumbnail_filename ? (
          <img
            src={urlImage.concat(dataCatalog?.thumbnail_filename)}
            className="w-full h-full object-cover max-w-[200px] max-h-[140px]"
            alt="Car"
          />
        ) : (
          <img
            src={ImagePlaceholder}
            className="w-full h-full object-cover max-w-[200px] max-h-[140px]"
            alt="No Image"
          />
        )}
        {/* Detail Unit */}
        <div className="flex flex-col items-start gap-1.5">
          {dataCatalog?.is_certified ? (
            <BadgeItem type="primary" className={'flex gap-1 items-center'}>
              <ChecklistIcon className={'stroke-primary-500 w-3 h-3'} /> BirdMobil Certified
            </BadgeItem>
          ) : null}
          <p className="text-lg-medium text-gray-900">
            {dataCatalog?.unit_name || dataCatalog?.name}
          </p>
          <p className="text-md-semibold text-primary-500">
            Rp {onRupiah(dataCatalog?.unit_price || dataCatalog?.price)}
          </p>
          <Link
            to={{
              pathname: `${isTaxi ? `/beli/ex-taxi/detail/${dataCatalog?.id}` : `/beli/mobil-bekas/detail/${dataCatalog?.id}`}`
            }}
            onClick={() => {
              localStorage.setItem('catalogId', dataCatalog?.id)
              localStorage.setItem('is_create_your_own_car', is_create_your_own_car)
            }}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary-600 cursor-pointer text-sm-medium text-primary-600">
            Lihat Detail Produk
          </Link>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardUnit
