import { ChecklistIcon, XCircleIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'

const VerifPaymentSuccessPage = () => {
  const { replace } = useHistory()
  const queryParams = new URLSearchParams(window.location.search)
  const status = queryParams.get('status-payment')
  if (localStorage.getItem('user') == null) {
    return replace('/')
  }

  const renderMap = useCallback(() => {
    return status === 'success' ? (
      <>
        {/* Icon */}
        <div className="flex justify-center items-center relative bg-success-50 p-4 rounded-full w-40 h-40">
          <div className="w-28 h-28 bg-success-100 p-4 rounded-full flex justify-center items-center">
            <ChecklistIcon className={'h-16 w-16'} />
          </div>
        </div>
        {/* Title */}
        <p className="display-xs-semibold text-success-500">Validasi Sukses!</p>
      </>
    ) : (
      <>
        {/* Icon */}
        <div className="flex justify-center items-center relative bg-danger-50 p-4 rounded-full w-40 h-40">
          <div className="w-28 h-28 bg-danger-100 p-4 rounded-full flex justify-center items-center">
            <XCircleIcon className={'h-16 w-16 stroke-danger-600'} />
          </div>
        </div>
        {/* Title */}
        <p className="display-xs-semibold text-danger-500">Validasi Gagal!</p>
      </>
    )
  }, [status])

  return (
    <div className="grid grid-cols-1 place-content-center items-center py-20 gap-2">
      <div className="flex justify-center items-center bg-gray-100 ">
        <CardSectionHeader className="flex gap-10 justify-center items-center max-w-md min-h-[320px]">
          {renderMap()}
        </CardSectionHeader>
      </div>
      <Link
        replace={true}
        to="/"
        className="hover:text-primary-900 text-md-medium text-primary-600 flex justify-center items-center">
        Kembali ke Halaman Utama
      </Link>
    </div>
  )
}

export default VerifPaymentSuccessPage
