import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

const MenungguKonfirmasiInspeksi = () => {
  return (
    <CardSectionHeader headerTitle="Menunggu Konfirmasi">
      <p className="text-md-regular text-gray-600">
        Pembayaran berhasil. Sedang menunggu konfirmasi..
      </p>
    </CardSectionHeader>
  )
}

export default MenungguKonfirmasiInspeksi
