const CloseIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      stroke="#475467"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M11 1L1 11M1 1L11 11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default CloseIcon
