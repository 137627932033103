const ArrowRightIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66602 7.00008H13.3327M13.3327 7.00008L7.49935 1.16675M13.3327 7.00008L7.49935 12.8334"
        stroke="#C11B23"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowRightIcon
