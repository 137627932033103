import { Col, Divider, Input, Row } from 'antd'
import Password from 'antd/lib/input/Password'
import { NavLink, useHistory } from 'react-router-dom'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import CustomButton from 'components/ui/custom-button'
import { birdmobilLogo } from 'features/auth/assets'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { postAuthGoogle } from '../service'
import { BirdMobilLogo } from 'assets'
import { showErrorMessage } from 'utils/toastMessage'

function InputPersonal({
  handleSubmit,
  state,
  companyEmail,
  setCompanyEmail,
  error,
  agree,
  isLoading,
  handleChangeAgree,
  handleChangeState,
  handleRegisterCustomer
}) {
  const { push } = useHistory()
  const google = window.google
  const handleClick = () => {
    handleSubmit()
  }
  const handleCancel = () => {
    push('/register')
  }

  const postGoogle = useMutation('postAuthGoogle', postAuthGoogle, {
    onSuccess: (data) => {
      // console.log(data)
      handleRegisterCustomer(data)
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })

  const responseGoogle = async (response) => {
    console.log(response)
    const dataSend = {
      token: response.credential,
      body: {
        account_type: 'customer'
      }
    }
    postGoogle.mutate(dataSend)
  }

  useEffect(() => {
    google?.accounts?.id?.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: responseGoogle
    })
    google?.accounts?.id?.renderButton(
      document.getElementById('buttonGoogle'),
      { theme: 'outline', size: 'medium', text: 'signup_with' } // customization attributes
    )
  }, [])

  return (
    <Row>
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* Title */}
        <div className="flex flex-col gap-5 items-center space-y-4">
          <img src={BirdMobilLogo} alt="" className="w-full max-w-[200px] object-contain" />
          <p className="display-sm-semibold text-gray-900">Registrasi BirdMobil</p>
        </div>
        {/* Form Input */}
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="fullname" className="text-sm-medium text-gray-700">
              Nama Lengkap <span className="text-danger-500">*</span>
            </label>
            <Input
              type="text"
              className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
              name="fullname"
              placeholder="Nama Lengkap"
              onChange={(e) => handleChangeState(e)}
              value={state?.fullname}
            />
            <small className="text-primary-300">{error.fullname}</small>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="phone_number" className="text-sm-medium text-gray-700">
              Nomor Telepon <span className="text-danger-500">*</span>
            </label>
            <Input
              addonBefore="+62"
              type="text"
              className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
              name="phone_number"
              placeholder="Nomor Telepon"
              onChange={(e) => handleChangeState(e)}
              value={state?.phone_number}
            />
            <small className="text-primary-300">{error.phone_number}</small>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-sm-medium text-gray-700">
              Email <span className="text-danger-500">*</span>
            </label>
            <Input
              type="email"
              className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
              name="email"
              placeholder="email@birdmobil.com"
              onChange={(e) => handleChangeState(e)}
              value={state?.email}
            />
            <small className="text-primary-300">{error.email}</small>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="company_email" className="text-sm-medium text-gray-700">
              Email Perusahaan (opsional)
            </label>
            <Input
              type="email"
              className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
              name="company_email"
              placeholder="email@birdmobil.com"
              onChange={(e) => setCompanyEmail(e.target.value)}
              value={companyEmail}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="text-sm-medium text-gray-700">
              Password <span className="text-danger-500">*</span>
            </label>
            <Password
              placeholder="Password"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              className="flex justify-center text-md-normal items-center rounded-lg bg-white shadow-xs"
              name="password"
              onChange={(e) => handleChangeState(e)}
              value={state?.password}
            />
            <small className="text-primary-300">{error.password}</small>
            <small className="text-gray-400">
              Password tidak boleh kurang dari 8 karakter dengan kombinasi angka.
            </small>
          </div>
          <div className="flex items-start space-x-2">
            <input
              type="checkbox"
              className="accent-primary-500 h-4 w-4"
              checked={agree}
              onChange={handleChangeAgree}
            />
            <p className="text-gray-400 text-sm-regular">
              Apakah Anda menyetujui <span className="text-gray-800">Syarat dan Ketentuan</span>{' '}
              serta <span className="text-gray-800">Kebijakan Privasi</span> kami
            </p>
          </div>
          {/* Button */}
          <div className="grid grid-cols-2 gap-2">
            <CustomButton label="Kembali" type="plain" onClick={handleCancel} />
            <CustomButton
              label="Selanjutnya"
              onClick={handleClick}
              disabled={!agree || isLoading}
            />
          </div>
          <Divider className="m-0" />
          <div id="buttonGoogle" />
          {/* <Button className="flex justify-center text-md-medium gap-3 items-center p-5 rounded-lg bg-white shadow-xs">
                        <GoogleIcon className={'w-6 h-6'} /> Daftar Dengan Google
                    </Button> */}
          {/* <Button className="flex justify-center text-md-medium gap-3 items-center p-5 rounded-lg bg-white shadow-xs">
                        <FacebookIcon className={' fill-[#1877F2] w-6 h-6'} />
                        Daftar Dengan Facebook
                    </Button> */}
          <p className="text-sm-regular text-center text-gray-500">
            Sudah memiliki akun?
            <NavLink
              to={'/login'}
              className="text-primary-600 hover:text-primary-600 text-sm-medium ml-2">
              Login
            </NavLink>
          </p>
        </div>
      </Col>
    </Row>
  )
}

export default InputPersonal
