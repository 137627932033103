import Password from 'antd/lib/input/Password'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import CustomButton from 'components/ui/custom-button'
function FormInput({
  data,
  error,
  handleChange,
  handleSubmit,
  handleBack }) {
  const handleClick = () => {
    handleSubmit()
  }
  const handleCancel = () => {
    handleBack()
  }
  return (
    <>
      <div className="text-gray-700">
        <div className="mb-3">
          <p className="text-sm-medium mb-[6px]">Password Baru</p>
          <Password
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            name="password"
            value={data.password}
            onChange={e => handleChange(e)}
          />
          <small className="text-primary-300">{error.password}</small>
        </div>
        <div>
          <p className="text-sm-medium mb-[6px]">Verifikasi Password baru</p>
          <Password
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            name="confirm_password"
            value={data.confirm_password}
            onChange={e => handleChange(e)}
          />
          <small className="text-primary-300">{error.confirm_password}</small>
        </div>
      </div>
      <div className='grid grid-cols-2 gap-3'>
        <CustomButton label="Kembali" type="plain" onClick={handleCancel} />
        <CustomButton label="Lanjutkan" onClick={handleClick} />
      </div>
    </>
  )
}

export default FormInput
