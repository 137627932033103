import { Col } from 'antd'
import CardCar from 'components/ui/card-car'
import CustomButton from 'components/ui/custom-button'
import SectionTitle from 'components/ui/section-title'
import React from 'react'
import Slider from 'react-slick'

function ProductSection({ handleWishlist, cars, wishlistIds, handleNavigation }) {
  return (
    <Col span={24} lg={{ order: 4 }} className="bg-gray-50 py-9 lg:py-20">
      <Col span={22} lg={{ span: 20 }} className="mx-auto flex-col flex gap-12">
        {/* Section Title */}
        <SectionTitle
          title={'Produk Terbaru dari Kami'}
          description={'Temukan mobil impian berkualitas yang cocok untuk Anda'}
        />
        {/* Card Container */}
        <Slider
          slidesToShow={4}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]}
          infinite
          dots
          autoplay
          arrows={false}
          className="gap-0 mb-3 lg:hidden">
          {cars?.data?.map((car, key) => (
            <CardCar
              handleWishlist={handleWishlist}
              isBookmark={wishlistIds.includes(car?.id)}
              data={car}
              onClick={() => {
                handleNavigation(`/beli/mobil-bekas/detail/${car?.id}`)
              }}
              key={key}
            />
          ))}
        </Slider>
        <div className="hidden lg:grid grid-cols-4 gap-2">
          {cars?.data?.map((car, key) => (
            <div key={key}>
              <CardCar
                handleWishlist={handleWishlist}
                isBookmark={wishlistIds.includes(car?.id)}
                data={car}
                onClick={() => {
                  handleNavigation(`/beli/mobil-bekas/detail/${car?.id}`)
                }}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <CustomButton
            onClick={() => handleNavigation('/beli/mobil-bekas')}
            label="Lihat Produk Lainnya"
          />
        </div>
      </Col>
    </Col>
  )
}

export default ProductSection
