const MesinTerawatIcon = ({ className }) => {
  return (
    <svg
      width="61"
      className={className}
      height="64"
      viewBox="0 0 61 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.681 21.5798L57.5844 21.3296C58.6856 21.2294 59.5366 20.2784 59.4365 19.127L59.1862 15.9233C59.0861 14.8221 58.135 13.9711 56.9837 14.0712L54.0803 14.3215C53.2294 11.9688 51.8277 10.0166 50.0757 8.51484L51.3272 5.91185C51.8277 4.9107 51.3772 3.70932 50.3761 3.20874L47.4727 1.80714C46.4716 1.30657 45.2702 1.75708 44.7696 2.75823L43.5182 5.36123C41.2656 4.96077 38.8128 5.11093 36.5102 5.91185L34.8583 3.50909C34.2075 2.60806 32.9561 2.35777 32.055 3.00852L29.402 4.81058C28.501 5.46132 28.2507 6.71278 28.9014 7.61382L30.5533 10.0166C29.0015 11.8186 27.9503 14.0212 27.4998 16.3739L24.5965 16.6242C23.4952 16.7243 22.6442 17.6754 22.7443 18.8267L22.9946 22.0304C23.0947 23.1316 24.0458 23.9826 25.1972 23.8825L28.1005 23.6322C28.9515 25.9849 30.3531 27.9372 32.1051 29.4389L30.8537 32.0419C30.3531 33.043 30.8036 34.2444 31.8047 34.745L34.7081 36.1466C35.7092 36.6472 36.9106 36.1966 37.4112 35.1955L38.6626 32.5925C40.9653 32.993 43.368 32.8428 45.6707 32.0419L47.3226 34.4446C47.9733 35.3457 49.2248 35.596 50.1258 34.9452L52.7788 33.1431C53.6799 32.4924 53.9302 31.2409 53.2794 30.3399L51.6275 27.9372C53.1793 26.085 54.2305 23.8825 54.681 21.5798Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.3207 18.9787C46.3207 16.0754 43.968 13.7227 41.0646 13.7227C38.1613 13.7227 35.8086 16.0754 35.8086 18.9787C35.8086 21.882 38.1613 24.2347 41.0646 24.2347C43.968 24.2347 46.3207 21.882 46.3207 18.9787Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.1154 21.5317C39.7138 21.5317 38.5625 20.3803 38.5625 18.9787C38.5625 17.5771 39.7138 16.4258 41.1154 16.4258C42.517 16.4258 43.6684 17.5771 43.6684 18.9787C43.6684 20.3803 42.517 21.5317 41.1154 21.5317Z"
        fill="#E12B25"
      />
      <path
        d="M34.6069 51.5146C33.9061 51.5146 33.3555 50.964 33.3555 50.2632C33.3555 49.5624 33.9061 49.0117 34.6069 49.0117C35.3077 49.0117 35.8583 49.5624 35.8583 50.2632C35.8583 50.964 35.3077 51.5146 34.6069 51.5146Z"
        fill="#E12B25"
      />
      <path
        d="M42.4166 44.9577L43.7181 43.4559C44.2187 42.8553 44.1186 42.0043 43.5679 41.5037L41.916 40.1021C41.3153 39.6015 40.4644 39.7016 39.9638 40.2523L38.6623 41.754C37.1606 41.0532 35.5087 40.7529 33.9569 40.903L33.3061 39.0509C33.0559 38.3501 32.2549 37.9496 31.5541 38.1999L29.5018 38.9508C28.801 39.2011 28.4005 40.002 28.6508 40.7028L29.3015 42.5549C28.0001 43.456 26.9488 44.6573 26.198 46.2091L24.2457 45.8587C23.4949 45.7085 22.7941 46.2091 22.6439 46.96L22.2434 49.1124C22.0933 49.8633 22.5938 50.5641 23.3447 50.7143L25.2969 51.0647C25.4471 52.6665 25.9978 54.2183 26.8988 55.5699L25.5973 57.0716C25.0967 57.6723 25.1968 58.5233 25.7475 59.0238L27.3994 60.4255C28.0001 60.926 28.851 60.8259 29.3516 60.2753L30.603 58.7736C32.1048 59.4744 33.7567 59.7747 35.3084 59.6245L35.9592 61.4766C36.2095 62.1775 37.0104 62.5779 37.7112 62.3276L39.7636 61.5768C40.4644 61.3265 40.8648 60.5256 40.6145 59.8248L39.9638 57.9726C41.2653 57.0716 42.3165 55.8702 43.0674 54.3184L45.0196 54.6688C45.7705 54.819 46.4713 54.3184 46.6214 53.5676L47.0219 51.4151C47.1721 50.6642 46.6715 49.9634 45.9206 49.8133L43.9684 49.4628C43.8683 47.861 43.3176 46.3092 42.4166 44.9577Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.1605 47.7114C35.7589 46.3098 33.5063 46.3098 32.1547 47.7114C30.7531 49.113 30.7531 51.3656 32.1547 52.7171C33.5563 54.1187 35.8089 54.1187 37.1605 52.7171C38.5621 51.3656 38.5621 49.113 37.1605 47.7114Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4974 27.386H19.7408C18.7396 27.386 17.7885 27.0356 17.0377 26.3347L15.1855 24.6328"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.67578 31.6406L12.9318 32.3414C14.2333 32.5416 15.2345 33.6429 15.2345 34.9945V36.296H7.67578"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.62109 28.4375V40.9519"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.62202 28.4391L1.5196 26.1865C1.26931 25.9362 1.11914 25.6359 1.11914 25.2855V22.8327C1.11914 22.1819 1.66977 21.6313 2.32052 21.6313H7.07598L11.1807 13.7723C11.9816 12.7211 13.2831 12.0703 14.6347 12.0703H25.2469H25.7975"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2298 40.9531V41.4037C11.2298 43.5061 9.52788 45.208 7.42546 45.208C5.32305 45.208 3.62109 43.5061 3.62109 41.4037V40.9531"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4963 40.9531H7.67578"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2422 36.3477H25.2456"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4316 21.6289H19.8413"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MesinTerawatIcon
