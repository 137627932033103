import { MagnifyingIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

const CardDocumentNull = ({ isOnGarage, handleNavigation, isPersonal, isCompanyAdmin }) => {
  return (
    <CardSectionHeader headerTitle={'Dokumen'}>
      <div className="flex flex-col px-6 h-full text-center gap-5 justify-center items-center">
        <MagnifyingIcon className={'w-10 h-10 stroke-gray-400'} />
        <p className="text-sm-regular md:text-md-regular text-gray-500">
          Silahkan lakukan inspeksi terlebih dahulu untuk melihat detail dokumen
        </p>
        {!isOnGarage && (isCompanyAdmin || isPersonal) ? (
          <p className="text-sm-regular md:text-md-regular text-gray-500">
            Lakukan Inspeksi{' '}
            <span
              className="text-primary-600 hover:text-primary-900 text-sm-medium cursor-pointer"
              onClick={handleNavigation}>
              Disini
            </span>
          </p>
        ) : null}
      </div>
    </CardSectionHeader>
  )
}

export default CardDocumentNull
