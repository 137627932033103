import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { getNotifications, updateNotification } from 'features/dashboard/service'
import { showErrorMessage } from 'utils/toastMessage'

const initialNotifications = {
  data: [],
  hasNotif: false
}
const initialNotificationPage = {
  today: [],
  another: []
}

function useNotification(type) {
  const token = localStorage.getItem('token')
  const { push } = useHistory()
  const [notifications, setNotifications] = useState(
    type === 'page' ? { ...initialNotificationPage } : { ...initialNotifications }
  )

  const { refetch } = useQuery({
    queryKey: 'getNotifications',
    queryFn: () => {
      const args = {
        token: token
      }
      return getNotifications(args)
    },
    onSuccess: (datas) => {
      if (type === 'page') {
        const today = moment().format('DD-MM-YYYY')
        const todayNotif = datas.filter(
          (el) => moment.utc(el.created_at).format('DD-MM-YYYY') === today
        )
        const anotherNotif = datas.filter(
          (el) => moment.utc(el.created_at).format('DD-MM-YYYY') !== today
        )

        setNotifications({
          today: todayNotif,
          another: anotherNotif
        })
      } else {
        const newData = datas.sort((a, b) => (a.id > b.id ? -1 : 1)).filter((_, index) => index < 5)
        const newNotif = datas.find((el) => el.is_read === false)

        setNotifications({
          data: newData,
          hasNotif: newNotif
        })
      }
    },
    staleTime: Infinity
  })
  const readNotification = useMutation({
    mutationFn: (dataSend) => updateNotification(dataSend),
    onSuccess: () => {
      push('/transaction')
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })
  const handleClickNotification = (id) => {
    const dataSend = {
      id: id,
      token: token
    }
    readNotification.mutate(dataSend)
  }

  return { notifications, handleClickNotification, refetch }
}

export default useNotification
