const TransparanIcon = ({ className }) => {
  return (
    <svg
      width="83"
      className={className}
      height="72"
      viewBox="0 0 83 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.8312 22.8711H50.0703V21.6613C50.0703 20.4515 50.9243 19.4551 52.1341 19.3128L56.8312 18.6723"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.373 18.6723L30.0701 19.3128C31.2799 19.4551 32.1339 20.5226 32.1339 21.6613V22.8711H25.373"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7441 15.7545V26.9276"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7452 15.7545L19.8949 13.7618C19.6814 13.5483 19.5391 13.2636 19.5391 12.979V10.7728C19.5391 10.2035 20.0372 9.7053 20.6066 9.7053H24.8766L28.5773 2.65979C29.2889 1.66345 30.4276 1.16528 31.6374 1.16528H41.1026H50.5678C51.7776 1.16528 52.9163 1.73462 53.628 2.65979L57.3287 9.7053H61.5987C62.168 9.7053 62.6662 10.2035 62.6662 10.7728V12.979C62.6662 13.2636 62.5238 13.5483 62.3103 13.7618L60.46 15.7545V26.9277"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.6289 26.9276V27.3546C53.6289 29.2049 55.1234 30.7706 57.0449 30.7706C58.8952 30.7706 60.4609 29.2761 60.4609 27.3546V26.9276"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5762 26.9276V27.3546C28.5762 29.2049 27.0817 30.7706 25.1602 30.7706C23.3098 30.7706 21.7441 29.2761 21.7441 27.3546V26.9276"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.8288 26.9276H41.7414H25.373"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.3984 22.8712H43.7359"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.567 4.51007H41.1018H31.6366C31.5654 4.51007 31.4231 4.51007 31.3519 4.58124L28.7188 9.70525H53.4848L50.8516 4.58124C50.7804 4.51007 50.6381 4.51007 50.567 4.51007Z"
        fill="#E12B25"
      />
      <path
        d="M6.08789 18.53L8.6499 18.0318C10.0732 17.7471 11.4966 18.6723 11.7101 20.0956C12.2794 23.5116 13.7027 31.4823 14.2009 34.6137L16.1224 37.1045"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6387 19.384L12.9908 19.0993C14.4142 18.8146 15.8375 19.7398 16.051 21.1631C16.8338 25.362 17.332 29.2762 17.9725 32.7633L19.894 35.4677"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7227 47.0679C24.0932 43.7942 18.5422 42.8691 14.0587 44.8617C13.6317 45.0752 13.2047 45.2176 12.7777 45.2887C9.71749 45.9292 9.14816 43.2961 8.22299 41.3746C7.44016 39.5954 5.73217 38.2432 3.81066 37.3181C2.31616 36.6064 0.679322 38.0297 1.24866 39.5954C2.24499 42.4421 3.38366 45.2176 4.73582 47.9219C5.51866 49.4164 6.30149 50.9109 7.5825 52.0496C10.0022 54.2558 13.4893 54.6828 16.6207 55.3944C19.8232 56.0349 23.3104 57.3871 24.6625 60.3049V69.9125"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0293 37.2468C26.0858 38.2432 30.9963 38.8125 34.1988 43.9365C35.7645 46.4273 36.9032 49.5587 37.1878 52.6189V70.4817"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.09469 37.318L1.88851 20.5938C1.74618 19.3128 2.60017 18.103 3.88117 17.8895C5.16217 17.676 6.37202 18.4588 6.58552 19.7398L9.21868 36.1082L11.5672 38.8837"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.1165 18.53L73.5545 18.0318C72.1312 17.7471 70.7079 18.6723 70.4944 20.0956C69.925 23.5116 68.5017 31.4823 68.0035 34.6137L66.082 37.1045"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.5659 19.384L69.2137 19.0993C67.7904 18.8146 66.367 19.7398 66.1535 21.1631C65.3707 25.362 64.8725 29.2762 64.232 32.7633L62.3105 35.4677"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.4102 47.0679C58.0397 43.7942 63.5907 42.8691 68.0742 44.8617C68.5012 45.0752 68.9282 45.2176 69.3552 45.2887C72.4154 45.9292 72.9847 43.2961 73.9099 41.3746C74.6927 39.5954 76.4007 38.2432 78.3222 37.3181C79.8167 36.6064 81.4535 38.0297 80.8842 39.5954C79.8879 42.4421 78.7492 45.2176 77.397 47.9219C76.6142 49.4164 75.8313 50.9109 74.5503 52.0496C72.1307 54.2558 68.6435 54.6828 65.5122 55.3944C62.3097 56.0349 58.8225 57.3871 57.4703 60.3049V69.9125"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.1027 37.2468C56.0462 38.2432 51.1357 38.8125 47.862 43.9365C46.2963 46.4273 45.1577 49.5587 44.873 52.6189V70.4817"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.037 37.318L80.2431 20.5938C80.3855 19.3128 79.5315 18.103 78.2505 17.8895C76.9695 17.676 75.7596 18.4588 75.5461 19.7398L72.9129 36.1082L70.5645 38.8837"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TransparanIcon
