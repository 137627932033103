import { Button } from 'antd'
import React from 'react'

const buttonType = {
  primary:
    'bg-primary-500 border-primary-500 text-white hover:bg-primary-600 hover:border-primary-600 hover:text-white focus:border-primary-600 focus:bg-primary-500 focus:border-primary-600 focus:text-white',
  secondary:
    'border-primary-50 bg-primary-50 text-primary-600 hover:bg-primary-100 hover:border-primary-100 hover:text-primary-600 focus:bg-primary-50 focus:border-primary-100 focus:text-primary-600',
  plain:
    'bg-white text-gray-700 border-gray-300 hover:bg-gray-100 hover:text-gray-800 hover:border-gray-300 stroke-gray-700 hover:stroke-gray-800 focus:bg-white focus:text-gray-800 focus:border-gray-300',
  danger:
    'text-danger-700 border-danger-700 bg-white hover:bg-danger-50 hover:text-danger-800 hover:border-danger-300 hover:stroke-danger-800 stroke-danger-700'
}

function CustomButton({ type = 'primary', label, onClick, disabled, className = 'gap-3 p-3 md:p-5 text-sm-medium md:text-md-medium' }) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={`flex flex-col items-center justify-center rounded-lg shadow-xs ${buttonType[type]} ${className}`}>
      {label}
    </Button>
  )
}

export default CustomButton
