import { Col, Collapse, Row } from 'antd'
import { CollapseIcon, ExpandIcon } from 'assets'
import SectionTitle from 'components/ui/section-title'
import React, { useState } from 'react'
import ContentTitle from './ui/content-title'
import { faqData } from 'data/FAQ'

const { Panel } = Collapse

function FAQ() {
  const [filteredFAQ, setFilteredFAQ] = useState([...faqData])
  const onSearch = (text) => {
    if (!text) setFilteredFAQ([...faqData])
    const newFilteredData = faqData.filter((faq) =>
      faq.title.toLowerCase().includes(text.toLowerCase())
    )
    // console.log('newFiltered', newFilteredData)
    setFilteredFAQ([...newFilteredData])
  }
  return (
    <Row className="bg-gray-50">
      {/* Header title */}
      <Col span={24}>
        <ContentTitle title="Pertanyaan yang sering diajukan" onSearch={onSearch} />
      </Col>
      {/* Content */}
      <Col span={20} className="mx-auto flex-col flex gap-12 py-20">
        {/* Section Title */}
        <SectionTitle title={'FAQ'} />
        {/* FAQ collapse container */}
        <div>
          <Collapse
            accordion
            bordered={false}
            className="text-lg-medium text-gray-900 rounded-[4px] bg-gray-50"
            expandIconPosition={'end'}
            expandIcon={(props) => {
              return props.isActive ? <CollapseIcon /> : <ExpandIcon />
            }}>
            {filteredFAQ.map((data, key) => (
              <Panel
                style={{ border: '1px solid #EAECF0' }}
                className="p-2 flex flex-col justify-center mb-4 bg-white"
                header={data.title}
                key={key}>
                <div className="text-sm-medium text-gray-600">{data.description}</div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </Col>
    </Row>
  )
}

export default FAQ
