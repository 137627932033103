import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'

// Component
import { Col, Collapse, Dropdown, Row } from 'antd'
import {
  AvatarIcon,
  BellIcon,
  BirdMobil,
  BirdMobilLogo,
  ChartIcon,
  ClockIcon,
  CloseIcon,
  DashboardIcon,
  HeartIcon,
  LogoutIcon,
  PersonIcon,
  ProfileIcon
} from 'assets'
import { HiMenu, HiOutlineChevronDown, HiOutlineChevronRight } from 'react-icons/hi'
import MenuUnlogged from './components/menu-unlogged'
import MenuLogged from './components/menu-logged'
import NavItem from './components/nav-item'
const { Panel } = Collapse
// constant
import navigationList from 'data/navigation-list'
import { useState } from 'react'
import CustomButton from 'components/ui/custom-button'
const options = [
  {
    title: 'Dashboard',
    accountType: ['customer', 'company'],
    url: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Transaksi',
    accountType: ['customer', 'company'],
    url: '/transaction',
    icon: <ClockIcon />
  },
  { title: 'Wishlist', accountType: ['customer'], url: '/wishlist', icon: <HeartIcon /> },
  {
    title: 'Report',
    accountType: ['company'],
    url: '/report',
    icon: <ChartIcon />
  },
  {
    title: 'Users',
    accountType: ['company'],
    url: '/users',
    icon: <PersonIcon />
  },
  { title: 'Profil', accountType: ['customer', 'company'], url: '/profile', icon: <ProfileIcon /> }
]
function Header() {
  const { push } = useHistory()
  const accountType = localStorage.getItem('account_type')
  const userName = localStorage.getItem('user_name')
  const [toggleNav, setToggleNav] = useState(false)
  const [toggleProfileNav, setToggleProfileNav] = useState(false)

  function openNav() {
    setToggleNav(true)
  }
  function closeNav() {
    setToggleNav(false)
  }
  function openProfileNav() {
    setToggleProfileNav(true)
  }
  function closeProfileNav() {
    setToggleProfileNav(false)
  }
  function handleLogout() {
    localStorage.clear()
    window.location.reload()
  }

  return (
    <Row justify={'center'} className={'bg-white h-12 lg:h-[96px] sticky top-0 z-[999]'}>
      <Col className="hidden lg:flex justify-between" span={23} xl={{ span: 22 }}>
        <div className="xl:p-5 p-2" style={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <NavLink to={'/'} className="flex flex-col items-center ">
            <div className="flex items-center gap-2">
              <img src={BirdMobilLogo} className="w-full max-w-[150px] object-contain" />
            </div>
          </NavLink>

          {/* Nav List */}
          <div className="flex gap-4 xl:gap-10 text-sm-medium xl:text-md-medium items-center ml-12">
            {navigationList.map((nav) => {
              if (nav.children) {
                return (
                  <Dropdown
                    key={nav.key}
                    dropdownRender={() => (
                      <div className="drop-nav-list border border-solid border-gray-200">
                        {nav.children.map((navItem) => (
                          <NavItem
                            className="drop-nav-item"
                            label={navItem.label}
                            to={navItem.url}
                            key={navItem.key}
                          />
                        ))}
                      </div>
                    )}>
                    <NavLink
                      to={nav.url}
                      className={'nav-item stroke-gray-500'}
                      activeClassName={'text-primary-500 stroke-primary-500'}
                      onClick={(e) => e.preventDefault()}>
                      <span
                        className="whitespace-nowrap"
                        style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        {nav.label}
                        <HiOutlineChevronDown />
                      </span>
                    </NavLink>
                  </Dropdown>
                )
              } else
                return (
                  <NavItem className={'nav-item'} label={nav.label} to={nav.url} key={nav.key} />
                )
            })}
          </div>
        </div>
        {/* Menu Container */}
        {accountType ? <MenuLogged /> : <MenuUnlogged />}
      </Col>

      {/* Mobile Responsive */}
      <Col span={22} className="flex lg:hidden justify-between relative">
        {/* Hamburger Navigation */}
        <div onClick={openNav} className="flex justify-center items-center">
          <HiMenu size={16} />
        </div>
        {/* Logo Birdmobil */}
        <NavLink to={'/'} className="flex items-center ml-[20px]">
          <BirdMobil className={'w-6 h-full'} />
        </NavLink>
        {/* Profile Icon */}
        <div className="flex gap-3 justify-between items-center">
          <NavLink
            to={localStorage.getItem('account_type') ? '/notification' : '/login'}
            className="">
            <BellIcon />
          </NavLink>
          <div
            onClick={() =>
              localStorage.getItem('account_type') ? openProfileNav() : push('/login')
            }
            className="rounded-full p-1 flex justify-center items-center bg-primary-50">
            <AvatarIcon className={'w-3 h-full'} />
          </div>
        </div>
      </Col>
      {/* Modal navigation  */}
      <div className="lg:hidden">
        {toggleNav ? (
          <div className=" z-[10000] w-full h-full bg-white absolute top-0 left-0">
            {/* Header Title */}
            <div className=" flex justify-start items-center gap-3 py-2 px-4">
              <div onClick={closeNav}>
                <CloseIcon />
              </div>
              <span className="text-lg-medium text-gray-900">Menu</span>
            </div>
            {/* Nav List */}
            <div className="flex flex-col items-start p-4 gap-6 bg-white">
              {navigationList.map((nav) => {
                if (nav.children) {
                  return (
                    <Collapse
                      ghost
                      accordion
                      bordered={false}
                      expandIconPosition={'end'}
                      key={nav.key}
                      className="w-full collapse-nav"
                      expandIcon={(props) => {
                        return props.isActive ? (
                          <HiOutlineChevronDown size={15} className="stroke-primary-500" />
                        ) : (
                          <HiOutlineChevronRight size={15} className="stroke-gray-500" />
                        )
                      }}>
                      <Panel
                        header={
                          <NavLink
                            to={nav.url}
                            className={
                              'text-md-medium nav-item stroke-gray-500 focus:text-gray-500 '
                            }
                            activeClassName={'text-primary-500 stroke-primary-500'}
                            onClick={(e) => e.preventDefault()}>
                            <span
                              className="text-md-medium nav-item stroke-gray-500"
                              style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                              {nav.label}
                            </span>
                          </NavLink>
                        }>
                        <div className="flex flex-col gap-4">
                          {nav.children.map((navItem) => (
                            <NavItem
                              onClickCapture={closeNav}
                              label={navItem.label}
                              to={navItem.url}
                              key={navItem.key}
                            />
                          ))}
                        </div>
                      </Panel>
                    </Collapse>
                  )
                } else
                  return (
                    <NavItem
                      onClickCapture={closeNav}
                      className={'nav-item'}
                      label={nav.label}
                      to={nav.url}
                      key={nav.key}
                    />
                  )
              })}

              {/* Masuk & Daftar */}
              {!localStorage.getItem('account_type') ? (
                <div className="flex w-full justify-center gap-3 border-t border-gray-300 pt-3">
                  <CustomButton onClick={() => push('/login')} type="plain" label={'Masuk'} />
                  <CustomButton onClick={() => push('/register')} label={'Daftar'} />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <div className="lg:hidden bg-white">
        {toggleProfileNav ? (
          <div className=" z-[10000] w-full h-full bg-white absolute top-0 left-0">
            {/* Header Title */}
            <div className=" flex justify-between items-center gap-3 py-2 px-4 md:pr-9">
              <div className="flex gap-3 items-center justify-center">
                <div
                  onClick={openProfileNav}
                  className="rounded-full p-2 flex justify-center items-center bg-primary-50">
                  <AvatarIcon className={'w-3 h-full'} />
                </div>
                <span className="text-xs-medium whitespace-nowrap md:text-md-medium text-gray-700">
                  {userName}
                </span>
              </div>
              <div onClick={closeProfileNav}>
                <CloseIcon />
              </div>
            </div>
            {/* Nav List */}
            <div className="flex flex-col items-start p-4 gap-6 bg-white">
              {options.map((nav, key) => {
                return (
                  nav.accountType.includes(accountType) && (
                    <NavLink
                      key={key}
                      to={nav.url}
                      activeClassName={'text-primary-500 stroke-primary-500'}
                      className="flex items-center gap-2 rounded-[4px] text-md-medium stroke-primary-600 text-gray-700 hover:text-primary-500 hover:bg-gray-100">
                      {nav.icon}
                      {nav.title}
                    </NavLink>
                  )
                )
              })}
              <div className="grid grid-cols-1 w-full border-t border-gray-300 pt-3">
                <CustomButton
                  onClick={handleLogout}
                  label={
                    <div className="flex text-primary-500 items-center gap-2">
                      <LogoutIcon />
                      Logout
                    </div>
                  }
                  type="danger"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Row>
  )
}

export default Header
