import { Col, Row, Steps } from 'antd'
import { paymentList } from 'features/beli-mobil/data/paymentList'
import { FormCheckoutJual, FormDataMobil, FormJadwalkanInspeksi } from 'features/jual-mobil'
import {
  getAdminPrice,
  getAllBrands,
  getAllColors,
  getAllFuels,
  getAllModels,
  getAllTransmision,
  getAllTypes,
  getAllUnits,
  getCities,
  getDetailUnit,
  getPriceRecommendation,
  getProvinces,
  getRangePrice,
  getServicePrice,
  getSubdistricts,
  getTaxPrice,
  getTimes,
  getWorkshops,
  postSellCar
} from 'features/jual-mobil/service'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import MobileStep from 'components/ui/mobile-step'
import { nameToString } from 'utils/nameToString'
import { imageResizer } from 'utils/imageResizer'
import { useScheduleService } from 'hooks'
import { showErrorMessage } from 'utils/toastMessage'

const items = [
  { key: 0, title: 'Data Mobil' },
  { key: 1, title: 'Jadwalkan Inspeksi' },
  { key: 2, title: 'Review' }
]

const initialUnit = {
  label: 'Pilih Unit',
  value: ''
}

const initialCarData = {
  unit: {},
  type: {},
  model: {},
  brand: {},
  year: '',
  transmisi: {},
  fuel: {},
  color: {}
}
const initialPrice = {
  min: '-',
  max: '-',
  input: ''
}
const initialState = {
  workshop: {},
  price: 200_000
}

const initialInspPrice = {
  tax: 0,
  inspection: 0,
  admin_fee: 0
}

function FormJualMobilPage() {
  const { push, location, replace, goBack } = useHistory()
  const send = location.state
  if (send === undefined || send === null) {
    return replace('/jual')
  }
  const prevData = location.state.data
  const prevDataUnit = location.state.unit
  const token = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))

  const initialDocumentData = {
    phone: user?.phone_number,
    mileage: '',
    police_number: '',
    frame_number: '',
    engine_number: '',
    stnk_number: '',
    bpkb_number: '',
    kir_number: '',
    tax: '',
    tax_month: {},
    tax_year: '',
    province: {
      label: user?.province_name || '',
      value: user?.province_id || ''
    },
    city: {
      label: user?.city_name || '',
      value: user?.city_id || ''
    },
    subdistrict: {
      label: user?.sub_district_name || '',
      value: user?.sub_district_id || ''
    },
    postal_code: user?.postal_code || '',
    address: user?.address || ''
    // docs: [
    //   { label: 'STNK', value: 'stnk', checked: false },
    //   { label: 'BPKB', value: 'bpkb', checked: false },
    //   { label: 'NIK', value: 'nik', checked: false },
    //   { label: 'Buku Servis', value: 'service_book', checked: false },
    //   { label: 'Buku Manual', value: 'manual_book', checked: false },
    //   { label: 'Kunci Serep', value: 'spare_key', checked: false }
    // ]
  }

  const initialHomeState = {
    province: {
      label: user?.province_name || '',
      value: user?.province_id || ''
    },
    city: {
      label: user?.city_name || '',
      value: user?.city_id || ''
    },
    subdistrict: {
      label: user?.sub_district_name || '',
      value: user?.sub_district_id || ''
    },
    range_price: {},
    postal_code: user?.postal_code || '',
    address: user?.address || '',
    loc: {}
  }

  const [currentContent, setCurrentContent] = useState(0)
  const [isShowRoom, setIsShowRoom] = useState(true)
  const [selectedPayment, setSelectedPayment] = useState(null)

  const [carData, setCardData] = useState(
    prevData !== undefined
      ? {
          ...prevData,
          unit: {},
          // year: '',
          // transmisi: {},
          fuel: {},
          color: {}
        }
      : prevDataUnit !== undefined
      ? {
          unit: { ...prevDataUnit },
          type: {},
          model: {},
          brand: {},
          year: '',
          transmisi: {},
          fuel: {},
          color: {}
        }
      : { ...initialCarData }
  )
  const [documentData, setDocumentData] = useState({ ...initialDocumentData })
  const [state, setState] = useState({ ...initialState })
  const [price, setPrice] = useState({ ...initialPrice })
  const [inspectionPrice, setInspectionPrice] = useState({ ...initialInspPrice })
  const [homeState, setHomeState] = useState({ ...initialHomeState })
  const [thumbnail, setThumbnail] = useState([])
  const { times, schedule, handleChangeSchedule, scheduleIsValid, resetSchedule } =
    useScheduleService('inspeksi')

  // state for list
  const [units, setUnits] = useState([])
  const [colors, setColors] = useState([])
  const [transmissions, setTransmissions] = useState([])
  const [fuels, setFuels] = useState([])
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [types, setTypes] = useState([])
  const [workshops, setWorkshops] = useState([])

  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [subdistricts, setSubdistricts] = useState([])
  const [rangePrices, setRangePrices] = useState([])

  const taxPrice =
    (parseInt(inspectionPrice?.inspection) +
      parseInt(inspectionPrice?.admin_fee) +
      parseInt(homeState?.range_price?.price || 0)) *
      (parseInt(inspectionPrice.tax) / 100) || 0
  const totalPrice =
    homeState?.range_price?.price !== undefined
      ? parseInt(inspectionPrice?.inspection) +
        parseInt(inspectionPrice?.admin_fee) +
        taxPrice +
        parseInt(homeState?.range_price?.price)
      : parseInt(inspectionPrice?.inspection) + parseInt(inspectionPrice?.admin_fee) + taxPrice

  // start of fectching
  useQuery({
    queryKey: 'getWorkshop',
    queryFn: () => getWorkshops(),
    onSuccess: (data) => {
      setWorkshops(data)
      if (data.length > 0) {
        setState((prev) => ({
          ...prev,
          workshop: data[0]
        }))
      }
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: 'getColors',
    queryFn: () => getAllColors(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setColors(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getUnits',
    queryFn: () => getAllUnits(),
    onSuccess: (data) => {
      const newData = data?.filter((car) =>
        (car?.company_admin_id !== user?.id && car?.company_id !== 0) ||
        car?.company_admin_id === user?.id
          ? !car?.is_booked && !car?.is_in_catalog && !car?.is_in_service && !car?.is_inspected
          : !car?.is_booked &&
            !car?.is_in_catalog &&
            !car?.is_in_service &&
            !car?.is_inspected &&
            car?.is_service_paid
      )
      const newDataV2 = newData.map((el) => ({
        ...el,
        label: `${el.unit_name} (${el?.police_number})`,
        value: el.id
      }))
      setUnits([{ ...initialUnit }, ...newDataV2])
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: ['getDetailUnit', carData.unit.value],
    queryFn: () => getDetailUnit(carData.unit.value),
    onSuccess: (data) => {
      if (carData.unit.value !== '') {
        setCardData((prev) => ({
          ...prev,
          brand: { label: '', value: data.brand_id },
          model: { label: '', value: data.model_id },
          type: { label: '', value: data.type_id },
          year: data?.production_year,
          transmisi: { label: '', value: data.transmission_id },
          // fuel: { label: '', value: data.fuel_id },
          // color: { label: '', value: data?.color_id }
          fuel: data?.fuel_id === 0 ? {} : { label: '', value: data.fuel_id },
          color: data?.color_id === 0 ? {} : { label: '', value: data?.color_id }
        }))
        setDocumentData((prev) => ({
          ...prev,
          phone: user?.phone_number,
          mileage: data.mileage,
          police_number: data.police_number,
          frame_number: data.frame_number,
          engine_number: data.engine_number === 'null' ? '' : data.engine_number
          // docs: [
          //   { label: 'STNK', value: 'stnk', checked: data.document_stnk_status },
          //   { label: 'BPKB', value: 'bpkb', checked: data.document_bpkb_status },
          //   { label: 'NIK', value: 'nik', checked: data.document_ktp_status },
          //   { label: 'Buku Servis', value: 'service_book', checked: false },
          //   { label: 'Buku Manual', value: 'manual_book', checked: false },
          //   { label: 'Kunci Serep', value: 'spare_key', checked: false }
          // ]
        }))
      } else {
        setCardData({ ...initialCarData })
        setDocumentData({ ...initialDocumentData })
      }
    },
    enabled: carData.unit.value !== undefined,
    staleTime: 0,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: 'getTransmissions',
    queryFn: () => getAllTransmision(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTransmissions(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getFuels',
    queryFn: () => getAllFuels(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setFuels(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getBrands',
    queryFn: () => getAllBrands(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setBrands(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: ['getModels', carData?.brand?.value],
    queryFn: () => getAllModels(carData?.brand?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setModels(newData)
    },
    enabled: carData?.brand?.value !== undefined && carData?.brand?.value !== '',
    staleTime: 0
  })
  useQuery({
    queryKey: ['getTypes', carData?.model?.value],
    queryFn: () => getAllTypes(carData?.model?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTypes(newData)
    },
    enabled: carData?.model?.value !== undefined && carData?.model?.value !== '',
    staleTime: 0
  })

  useQuery({
    queryKey: [
      'getPriceRecommendation',
      carData.brand.value,
      carData.model.value,
      carData.type.value,
      carData.transmisi.value,
      carData.year
    ],
    queryFn: () =>
      getPriceRecommendation(carData.type.value, carData.transmisi.value, carData.year),
    onSuccess: (data) => {
      setPrice((prev) => ({
        ...prev,
        min: data.min_price.toLocaleString('id-ID'),
        max: data.max_price.toLocaleString('id-ID')
      }))
    },
    onError: (data) => {
      setPrice((prev) => ({
        ...prev,
        min: '-',
        max: '-'
      }))
    },
    retry: false,
    staleTime: 0
    // enabled:
    //   carData.type.value !== undefined &&
    //   carData.type.value !== ''
  })

  useQuery({
    queryKey: 'getServicePrice',
    queryFn: () => getServicePrice(),
    onSuccess: (data) => {
      setInspectionPrice((prev) => ({
        ...prev,
        inspection: data.price
      }))
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getAdminPrice',
    queryFn: () => getAdminPrice(),
    onSuccess: (data) => {
      setInspectionPrice((prev) => ({
        ...prev,
        admin_fee: data.price
      }))
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getTaxPrice',
    queryFn: () => getTaxPrice(),
    onSuccess: (data) => {
      setInspectionPrice((prev) => ({
        ...prev,
        tax: data.percentage
      }))
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getRangePrice',
    queryFn: () => getRangePrice(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: `${el.start_distance} km - ${el.end_distance} km`,
        value: el.id,
        price: el.price
      }))
      setRangePrices(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: [
      'getCities',
      homeState?.province?.value,
      documentData?.province?.value,
      currentContent
    ],
    queryFn: () =>
      getCities(currentContent === 0 ? documentData?.province?.value : homeState?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    enabled:
      currentContent === 0
        ? documentData?.province?.value !== undefined
        : homeState?.province?.value !== undefined,
    staleTime: 0
  })
  useQuery({
    queryKey: [
      'getSubdistricts',
      homeState?.city?.value,
      documentData?.city?.value,
      currentContent
    ],
    queryFn: () =>
      getSubdistricts(currentContent === 0 ? documentData?.city?.value : homeState?.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts(newData)
    },
    enabled:
      currentContent === 0
        ? documentData?.province?.value !== undefined
        : homeState?.city?.value !== undefined,
    staleTime: 0
  })

  const postSell = useMutation(postSellCar, {
    onSuccess: () => {
      push('/jual/form/result', {
        data: {
          workshop_name: state?.workshop?.name,
          date: moment(schedule.date).format('DD MMM YYYY'),
          hours: schedule?.hours?.time
        }
      })
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })
  // end of fetching

  // handle change state
  const handleChange = (name, val) => {
    setCardData((prev) => ({
      ...prev,
      [name]: val
    }))
  }

  const handleChangeThumbnail = async (value) => {
    const { file, fileList } = value
    let newFileList
    if (file.status !== undefined) {
      newFileList = thumbnail.filter((item) => item.uid !== file.uid)
    } else {
      const newFile = fileList.pop()
      // const imageConvertedObj = await imageResizer(newFile.originFileObj)
      newFileList = [
        ...fileList,
        {
          id: fileList.length,
          photo: newFile.originFileObj,
          originFileObj: newFile.originFileObj,
          uid: newFile.originFileObj.uid
        }
      ]
    }
    setThumbnail(newFileList)
  }
  const handleChangeDocument = (name, value) => {
    setDocumentData((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  // handleChangeKelengkapan ga dipake
  const handleChangeKelengkapan = (e) => {
    const { checked, value } = e.target
    setDocumentData((prev) => ({
      ...prev,
      docs: prev.docs.map((item) => ({
        ...item,
        checked: item.value === value ? checked : item.checked
      }))
    }))
  }
  const handleChangeCarList = (name, val) => {
    switch (name) {
      case 'brand':
        setCardData((prev) => ({
          ...prev,
          brand: val,
          model: {},
          type: {}
        }))
        break
      case 'model':
        setCardData((prev) => ({
          ...prev,
          model: val,
          type: {}
        }))
        break
      default:
        setCardData((prev) => ({
          ...prev,
          [name]: val
        }))
    }
  }
  const handleChangePrice = (name, value) => {
    setPrice((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleChangeWorkshop = (value) => {
    setState((prev) => ({
      ...prev,
      workshop: value
    }))
  }

  const handleChangeHomeData = (name, value) => {
    setHomeState((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeLocation = (name, value) => {
    let newValue = {}
    let oldValue = currentContent === 0 ? documentData : homeState
    if (name === 'province') {
      setCities([])
      setSubdistricts([])
      newValue = {
        // ...homeState,
        ...oldValue,
        province: value,
        city: {},
        subdistrict: {}
      }
    } else if (name === 'city') {
      setSubdistricts([])
      newValue = {
        // ...homeState,
        ...oldValue,
        city: value,
        subdistrict: {}
      }
    } else {
      newValue = {
        // ...homeState,
        ...oldValue,
        subdistrict: value
      }
    }
    if (currentContent === 0) {
      setDocumentData(newValue)
    } else {
      setHomeState(newValue)
    }
  }

  const handleSelectedPayment = (id) => {
    const splitSelectedPayment = id?.split('-')
    const filteredPaymentMethod = paymentList
      .filter((paymentMethod) => paymentMethod.id.includes(splitSelectedPayment[0]))[0]
      .options.filter((bank) => bank.id.includes(id))[0]
    setSelectedPayment(filteredPaymentMethod)
  }

  const handleCheckout = () => {
    if (!scheduleIsValid()) {
      showErrorMessage('Jam inspeksi tidak valid, silahkan pilih kembali.')
      return setCurrentContent(1)
    }
    const newFormData = new FormData()

    newFormData.append(
      'unit_id',
      carData.unit.value !== '' && carData.unit.value !== undefined ? carData?.unit?.value : 0
    )
    newFormData.append('brand_id', carData.brand.value)
    newFormData.append('model_id', carData.model.value)
    newFormData.append('type_id', carData.type.value)
    newFormData.append('production_year', carData.year)
    newFormData.append('transmission_id', carData.transmisi.value)
    newFormData.append('fuel_id', carData.fuel.value)
    newFormData.append('color_id', carData.color.value)
    newFormData.append('document_stnk', documentData.stnk_number)
    newFormData.append('document_bpkb', documentData.bpkb_number)
    newFormData.append('document_keur', documentData.kir_number)
    newFormData.append('province_id', documentData.province.value)
    newFormData.append('city_id', documentData.city.value)
    newFormData.append('subdistrict_id', documentData.subdistrict.value)
    newFormData.append('postal_code', documentData.postal_code)
    newFormData.append('address', documentData.address)
    newFormData.append(
      'price',
      isShowRoom
        ? parseInt(price.input)
        : parseInt(state.price) + parseInt(homeState?.range_price?.price)
    )
    newFormData.append('owner_phone_number', documentData.phone)
    newFormData.append('mileage', documentData.mileage)
    newFormData.append('tax_status', documentData.tax === '1' ? true : false)
    newFormData.append(
      'tax_active_end',
      `${documentData?.tax_month?.value}/${documentData.tax_year}`
    )
    newFormData.append('police_number', documentData?.police_number)
    newFormData.append('frame_number', documentData?.frame_number)
    newFormData.append('engine_number', documentData?.engine_number)
    // newFormData.append(
    //   'document_stnk_status',
    //   documentData.docs.find((el) => el.value === 'stnk').checked
    // )
    // newFormData.append(
    //   'document_bpkb_status',
    //   documentData.docs.find((el) => el.value === 'bpkb').checked
    // )
    // newFormData.append(
    //   'document_nik_status',
    //   documentData.docs.find((el) => el.value === 'nik').checked
    // )
    // newFormData.append(
    //   'document_service_status',
    //   documentData.docs.find((el) => el.value === 'service_book').checked
    // )
    // newFormData.append(
    //   'document_manual_status',
    //   documentData.docs.find((el) => el.value === 'manual_book').checked
    // )
    // newFormData.append(
    //   'spare_key_status',
    //   documentData.docs.find((el) => el.value === 'spare_key').checked
    // )
    newFormData.append('inspection_is_workshop', isShowRoom)
    newFormData.append('inspection_workshop_id', state?.workshop?.id)
    newFormData.append('inspection_workshop_name', state?.workshop?.name)
    newFormData.append('inspection_date', moment(schedule.date).format('DD/MM/YYYY'))
    newFormData.append('inspection_schedule_id', schedule.hours.id)
    newFormData.append('inspection_province_id', isShowRoom ? 0 : homeState?.province?.value)
    newFormData.append('inspection_province_name', isShowRoom ? '' : homeState?.province?.label)
    newFormData.append('inspection_city_id', isShowRoom ? 0 : homeState?.city?.value)
    newFormData.append('inspection_city_name', isShowRoom ? '' : homeState?.city?.label)
    newFormData.append('inspection_subdistrict_id', isShowRoom ? 0 : homeState?.subdistrict?.value)
    newFormData.append(
      'inspection_subdistrict_name',
      isShowRoom ? '' : homeState?.subdistrict?.label
    )
    newFormData.append('inspection_location_id', 0)
    newFormData.append('inspection_location_name', '')
    newFormData.append('inspection_latitude', isShowRoom ? '' : String(homeState?.loc?.lat))
    newFormData.append('inspection_longitude', isShowRoom ? '' : String(homeState?.loc?.lng))
    newFormData.append('inspection_postal_code', homeState.postal_code)
    newFormData.append('inspection_address', homeState.address)
    newFormData.append(
      'inspection_distance_price_id',
      isShowRoom ? 0 : homeState?.range_price?.value
    )
    newFormData.append('thumbnail', thumbnail[0]?.originFileObj)

    const args = {
      token: token,
      body: newFormData
    }
    postSell.mutate(args)
  }

  const handleNextPage = () => {
    let nameString = ''
    let nameObject = ''
    let validString = true
    let validObject = true
    // let validError = true
    let dataObject = {
      tax_month: documentData.tax_month,
      subdistrict: documentData.subdistrict,
      city: documentData.city,
      province: documentData.province,
      ...carData
    }
    // remove year & unit properties
    delete dataObject.year

    if (!(carData.unit.value !== '' && carData.unit.value !== undefined)) {
      delete dataObject.unit
    }

    let dataString = {
      ...documentData,
      year: carData.year,
      input: price.input
    }
    // remove tax_month, tax, and docs properties
    delete dataString.tax_month
    delete dataString.province,
      delete dataString.city,
      delete dataString.subdistrict,
      delete dataString.mileage
    // delete dataString.docs
    delete dataString.kir_number
    delete dataString.engine_number

    Object.keys(dataObject).forEach((key) => {
      if (dataObject[key].value === undefined) {
        validObject = false
        nameObject = String(key)
        // console.log(String(key))
      }
    })
    Object.keys(dataString).forEach((key) => {
      if (dataString[key].length === 0) {
        validString = false
        nameString = String(key)
      }
    })
    // Object.values(dataObject).forEach((v) => {
    //   if (v.value === undefined) {
    //     validObject = false
    //   }
    // })
    // Object.values(dataString).forEach((v) => {
    //   if (v.length === 0) {
    //     validString = false
    //   }
    // })
    if (validObject) {
      if (validString) {
        if (thumbnail?.length <= 0) {
          showErrorMessage('Mohon isi Foto Unit')
        } else if (documentData.mileage === undefined) {
          showErrorMessage('Jarak tempuh harus angka')
        } else {
          setCurrentContent(1)
        }
        // if (thumbnail.length !== 0) {
        //   setCurrentContent(1)
        // } else {
        //   showErrorMessage('Mohon isi Foto Unit')
        // }
      } else {
        showErrorMessage(`Mohon isi ${nameToString[nameString] || 'semua data string'}`)
      }
    } else {
      showErrorMessage(`Mohon isi ${nameToString[nameObject] || 'semua data'} `)
    }
  }

  const handleNextPreview = () => {
    if (isShowRoom) {
      if (schedule.date === null) {
        showErrorMessage('Mohon isi Tanggal Inspeksi')
      } else if (schedule.hours.label === undefined) {
        showErrorMessage('Mohon isi Jam Inspeksi')
      } else {
        setCurrentContent(2)
      }
    } else {
      let nameObject = ''
      let validObject = true
      let dataObject = { ...homeState }
      // delete objectData
      delete dataObject.loc
      delete dataObject.address
      delete dataObject.postal_code

      Object.keys(dataObject).forEach((key) => {
        if (dataObject[key].value === undefined) {
          validObject = false
          nameObject = String(key)
        }
      })

      if (validObject) {
        if (homeState.postal_code === '') {
          showErrorMessage('Mohon isi Kode Pos')
        } else if (homeState.address === '') {
          showErrorMessage('Mohon isi Alamat')
        } else if (homeState.loc.lat === undefined) {
          showErrorMessage('Mohon isi Lokasi')
        } else if (schedule.date === null) {
          showErrorMessage('Mohon isi Tanggal Inspeksi')
        } else if (schedule.hours.label === undefined) {
          showErrorMessage('Mohon isi Jam Inspeksi')
        } else {
          setCurrentContent(2)
        }
      } else {
        showErrorMessage(`Mohon isi ${nameToString[nameObject]}`)
      }
    }
  }

  useEffect(() => {
    setHomeState({ ...initialHomeState })
    resetSchedule()
  }, [isShowRoom])

  useEffect(() => {
    scrollToTop()
  }, [currentContent])

  const scrollToTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  return (
    <Row className="py-10 gap-6 ">
      {/* Header Title */}
      <div className="text-center w-full display-sm-semibold text-gray-900">Jual Mobil</div>
      {/* Steps */}
      <Col span={21} className="hidden md:grid grid-cols-1 w-full mx-auto">
        <Steps labelPlacement="vertical" current={currentContent} items={items} />
      </Col>
      {/* mobile step */}
      <div className="md:hidden mx-auto ">
        <MobileStep current={currentContent} items={items} />
      </div>
      {/* Content */}
      {currentContent === 2 ? (
        <FormCheckoutJual
          price={price}
          inspectionPrice={inspectionPrice}
          state={state}
          thumbnail={thumbnail}
          schedule={schedule}
          homeState={homeState}
          isShowRoom={isShowRoom}
          carData={carData}
          taxPrice={taxPrice}
          totalPrice={totalPrice}
          isLoading={postSell?.isLoading}
          handleCancel={() => setCurrentContent(1)}
          selectedPayment={selectedPayment}
          handleSelectedPayment={handleSelectedPayment}
          handleClick={handleCheckout}
          handleChangePrice={handleChangePrice}
        />
      ) : currentContent === 1 ? (
        <FormJadwalkanInspeksi
          state={state}
          price={inspectionPrice}
          homeState={homeState}
          taxPrice={taxPrice}
          totalPrice={totalPrice}
          schedule={schedule}
          times={times}
          workshops={workshops}
          provinces={provinces}
          cities={cities}
          subdistricts={subdistricts}
          rangePrice={rangePrices}
          handleCancel={() => setCurrentContent(0)}
          handleClick={handleNextPreview}
          isShowRoom={isShowRoom}
          setIsShowRoom={setIsShowRoom}
          handleChangeHomeData={handleChangeHomeData}
          handleChangeLocation={handleChangeLocation}
          handleChangeWorkshop={handleChangeWorkshop}
          handleChangeSchedule={handleChangeSchedule}
        />
      ) : (
        <FormDataMobil
          carData={carData}
          documentData={documentData}
          price={price}
          units={units}
          brands={brands}
          models={models}
          types={types}
          fuels={fuels}
          colors={colors}
          thumbnail={thumbnail}
          transmissions={transmissions}
          subdistricts={subdistricts}
          provinces={provinces}
          cities={cities}
          handleChangeLocation={handleChangeLocation}
          handleChange={handleChange}
          handleChangeKelengkapan={handleChangeKelengkapan}
          handleChangeDocument={handleChangeDocument}
          handleChangeCarList={handleChangeCarList}
          handleChangePrice={handleChangePrice}
          handleCancel={() => goBack()}
          handleClick={handleNextPage}
          handleChangeThumbnail={handleChangeThumbnail}
        />
      )}
    </Row>
  )
}

export default FormJualMobilPage
