const DaruratIcon = ({ className }) => {
  return (
    <svg
      width="82"
      className={className}
      height="72"
      viewBox="0 0 82 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M58.2415 64.1588C58.2415 67.7668 55.3274 70.681 51.7194 70.681C48.1114 70.681 45.1973 67.7668 45.1973 64.1588C45.1973 60.5509 48.1114 57.6367 51.7194 57.6367C55.3274 57.6367 58.2415 60.5509 58.2415 64.1588Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.2665 64.1591H38.5363C36.1078 64.1591 34.0957 62.1469 34.0957 59.7185V58.6777"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1138 48.2024C14.1138 47.9942 13.975 47.8555 13.7668 47.8555C13.5587 47.8555 13.4199 47.9942 13.4199 48.2024C13.4199 48.4105 13.5587 48.5493 13.7668 48.5493C13.9056 48.5493 14.1138 48.4105 14.1138 48.2024Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.04883 48.2005C9.04883 45.6333 11.1303 43.4824 13.7669 43.4824C16.3342 43.4824 18.4851 45.5639 18.4851 48.2005C18.4851 50.7678 16.4035 52.9187 13.7669 52.9187C11.1303 52.9187 9.04883 50.7678 9.04883 48.2005Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1138 15.9387C14.1138 15.7306 13.975 15.5918 13.7668 15.5918C13.5587 15.5918 13.4199 15.7306 13.4199 15.9387C13.4199 16.1469 13.5587 16.2856 13.7668 16.2856C13.9056 16.2856 14.1138 16.1469 14.1138 15.9387Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.04883 15.9369C9.04883 13.3697 11.1303 11.2188 13.7669 11.2188C16.3342 11.2188 18.4851 13.3003 18.4851 15.9369C18.4851 18.5041 16.4035 20.655 13.7669 20.655C11.1303 20.655 9.04883 18.5041 9.04883 15.9369Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5052 8.58203L21.6777 15.8674"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.375 23.1523V40.6372"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0898 40.6372V23.1523"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4154 48.2012H23.2723L26.464 59.5108V70.6817H1V59.5108L4.26107 48.2012H9.04857"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.1973 29.7441V36.2663"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2773 9.27651L43.8108 1.22794C46.5861 0.325942 49.639 2.19932 49.9166 5.11345C49.9166 5.25222 49.9166 5.39099 49.9166 5.52976"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9164 5.53125C49.5001 12.0534 48.9451 18.5755 48.1124 25.0282L47.8349 27.526C47.6268 28.775 46.5166 29.677 45.2677 29.677C44.0188 29.677 42.9086 28.775 42.7005 27.526L41.174 15.3144L40.8965 12.886"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.7198 67.004C50.1933 67.004 48.875 65.755 48.875 64.1592C48.875 62.6328 50.1239 61.3145 51.7198 61.3145C53.2462 61.3145 54.5645 62.5634 54.5645 64.1592C54.5645 65.755 53.3156 67.004 51.7198 67.004Z"
        fill="#E12B25"
      />
      <path
        d="M81.0008 48.9642H72.5359C67.3321 48.9642 62.2671 47.854 57.5489 45.7725L57.4102 45.7031"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.9994 37.5859C75.3793 37.7247 69.0653 39.1124 62.6126 42.651L57.3394 45.6345C57.3394 45.6345 41.1035 45.6345 34.0957 52.6423V56.1115H37.4261L40.6178 54.2382V52.226"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.0002 64.1602H58.2422"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DaruratIcon
