const DigitalisasiIcon = ({ className }) => {
  return (
    <svg
      width="82"
      className={className}
      height="71"
      viewBox="0 0 82 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.4668 10.6182H76.2982"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.8047 6.0177H63.8905"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0781 48.8867L16.2122 49.7231C17.7457 49.9322 18.9307 51.2567 18.9307 52.8599V54.3934H10.0781"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.196 24.1412H33.5686H18.7911C16.7696 24.1412 14.9572 25.2565 13.9814 26.9991L9.03228 35.9911C8.33522 37.2458 7.08049 38.0126 5.68637 38.0126H2.34051C1.78286 38.0126 1.29492 38.5005 1.29492 39.0582V42.5435C1.29492 42.9617 1.57374 43.3799 1.99198 43.5194L3.3164 44.2861C4.22258 44.8438 4.78021 45.8196 4.78021 46.8652V64.8493C4.78021 67.2193 6.66228 69.1013 9.03228 69.1013H11.4022C13.7722 69.1013 15.6543 67.2193 15.6543 64.8493V62.4793H34.2657"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5842 62.4792H11.123"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5527 54.3934H34.1963"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.2982 59.9003H43.4668"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.7667 69.1013H47.997C43.1873 69.1013 39.3535 65.1978 39.3535 60.4578V10.1303C39.3535 5.32063 43.257 1.48682 47.997 1.48682H71.7667C76.5764 1.48682 80.4101 5.39033 80.4101 10.1303V60.4578C80.4798 65.2675 76.5764 69.1013 71.7667 69.1013Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2576 28.8812L12.5176 37.5944H34.196V27.975H18.7911C18.1637 27.975 17.5364 28.3235 17.2576 28.8812Z"
        fill="#E12B25"
      />
      <path
        d="M75.1829 56.624H44.5821C43.9548 56.624 43.4668 56.136 43.4668 55.5087V47.9805C43.4668 47.2137 44.0941 46.5864 44.8609 46.5864H74.9738C75.7405 46.5864 76.3679 47.2137 76.3679 47.9805V55.5087C76.2982 56.136 75.8102 56.624 75.1829 56.624Z"
        fill="#E12B25"
      />
    </svg>
  )
}

export default DigitalisasiIcon
