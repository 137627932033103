import { Col, Row } from 'antd'
import { InspektorBerpengalamanIcon, LokasiInspeksiFleksibelIcon, ServisUmumIcon } from 'assets'
import CardLandingGradient from 'components/ui/card/card-landing-gradient'
import SectionTitle from 'components/ui/section-title'
import React from 'react'

const benefitData = [
  {
    label: 'Inspektor Berpengalaman',
    description:
      'Team Inspektor BirdMobil telah berpengalaman dalam melakukan cek pada mobil dengan proses yang sudah terstandarisasi dengan durasi inspeksi 60 menit.',
    icon: <InspektorBerpengalamanIcon />
  },
  {
    label: 'Lulus Inspeksi 188 Titik',
    description:
      'Fokus utama kami melakukan inspeksi di 188 titik untuk cek kondisi mobil mulai dari interior, eksterior maupun mesin dan transmisi.',
    icon: <ServisUmumIcon />
  },
  {
    label: 'Lokasi Inspeksi Fleksible',
    description:
      'Anda bisa melakukan inspeksi di inspection point BirdMobil atau di lokasi yang telah disepakati. Team kami akan sigap membantu untuk cek Mobil Anda.',
    icon: <LokasiInspeksiFleksibelIcon />
  }
]

const WhyOurInspectionSection = () => {
  return (
    <Row className="bg-white py-10 md:py-20 ">
      <Col span={20} className="mx-auto gap-12 grid grid-cols-1">
        <SectionTitle title={'Mengapa Inspeksi Kami Terbaik?'} />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {benefitData.map((data, key) => (
            <CardLandingGradient gradient={true} key={key} containerClassName={'flex flex-col gap-4'}>
              <div className="relative flex justify-start h-[70px] object-contain">
                {data.icon}
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-md-semibold md:text-lg-semibold text-gray-900">{data.label}</p>
                <p className="text-sm-regular text-gray-600">{data.description}</p>
              </div>
            </CardLandingGradient>
          ))}
        </div>
      </Col>
    </Row>
  )
}

export default WhyOurInspectionSection
