import { Col, Row } from 'antd'
import { ChevronRightIcon } from 'assets'
import CustomButton from 'components/ui/custom-button'
import React from 'react'

const HeroInspectionSection = ({ onClick }) => {
  return (
    // md:h-[90vh]
    <Row className={`w-full h-[50vh] md:h-full relative bg-white`}>
      {/* Background */}
      {/* md:h-[62vh] */}
      <div className='w-full h-full z-[0] absolute bg-cover bg-no-repeat bg-[url("assets/images/image-hero-inspeksi.webp")]'></div>
      <Col md={20} span={22} className="mx-6 md:mx-auto md:py-24 py-6">
        <div className="grid grid-cols-1 gap-6 h-full max-h-[496px] relative">
          {/* Left Content: Title */}
          <div className="flex mt-5 md:mt-0 flex-col h-full">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2 text-white">
                <p className="md:display-lg-semibold display-md-semibold">
                  Tempat Inspeksi Mobil terpercaya.
                  <br /> Lebih dari 180 titik pemeriksaan
                </p>
              </div>
              <div>
                <CustomButton
                  onClick={onClick}
                  label={
                    <div className="flex gap-4 items-center justify-center">
                      <p>Lakukan Inspeksi</p> <ChevronRightIcon className={'stroke-white'} />
                    </div>
                  }
                />
              </div>
            </div>
            {/* Total Servis */}
            {/* <div className="md:block hidden absolute bottom-0 w-full ">
              <div className="flex gap-8 text-center justify-center relative h-full">
                <div className="flex flex-col gap-2 pr-9 border-r border-gray-300">
                  <p className="display-md-semibold text-primary-500">140.203</p>
                  <p className="text-md-semibold text-gray-700">Bengkel</p>
                </div>
                <div className="flex flex-col gap-2 pr-9 border-r border-gray-300">
                  <p className="display-md-semibold text-primary-500">21.678</p>
                  <p className="text-md-semibold text-gray-700">Pelanggan Puas</p>
                </div>
                <div className="flex flex-col gap-2 pr-9 border-r border-gray-300">
                  <p className="display-md-semibold text-primary-500">11.902</p>
                  <p className="text-md-semibold text-gray-700">Berhasil Inspeksi</p>
                </div>
                <div className="flex flex-col gap-2 pr-9 border-r border-gray-300">
                  <p className="display-md-semibold text-primary-500">11.902</p>
                  <p className="text-md-semibold text-gray-700">Berjalan</p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="display-md-semibold text-primary-500">11.902</p>
                  <p className="text-md-semibold text-gray-700">Berjalan</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default HeroInspectionSection
