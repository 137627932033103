import React from 'react'
import DropDownLocation from './dropdown-location'
import DropdownNotification from './dropdown-notification'
import DropdownUser from './dropdown-user'

function MenuLogged() {
  return (
    <>
      <div className="flex items-center gap-5">
        {/* <DropDownLocation /> */}
        <DropdownNotification />
        <DropdownUser />
      </div>
    </>
  )
}

export default MenuLogged
