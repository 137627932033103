import { Col, Row } from 'antd'
import React from 'react'
import ContentTitle from './ui/content-title'

function TermsConditions() {
  return (
    <Row className={`bg-gray-50 `}>
      <Col span={24}>
        {/* Content Title */}
        <ContentTitle title="Syarat & Ketentuan" />
        <Col span={20} className="py-9 lg:py-20 flex flex-col gap-12 mx-auto">
          <p className="text-md-semibold text-gray-600">
            SYARAT DAN KETENTUAN PENGGUNAAN SITUS BIRDMOBIL.ID
          </p>
          <p className="text-md-medium text-gray-600">
            Selamat Datang di BIRDMOBIL.ID!
            <br />
            <br /> HARAP MEMBACA SYARAT DAN KETENTUAN PENGGUNAAN SITUS BIRDMOBIL.ID INI SEBELUM
            MENGAKSES ATAU MENGGUNAKAN SITUS BIRDMOBIL. DENGAN MENGAKSES DAN MENGGUNAKAN SITUS
            BIRDMOBIL, MAKA SYARAT DAN KETENTUAN PENGGUNAAN SITUS BIRDMOBIL.ID INI BERLAKU SECARA
            OTOMATIS UNTUK ANDA, KARENA ITU ANDA HARUS MEMASTIKAN BAHWA ANDA MEMBACANYA DENGAN
            CERMAT.
          </p>
          <span className="display-xs-semibold">A. KETENTUAN UMUM</span>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">1. Pendahuluan</span>
            <br /> <br />
            Syarat Dan Ketentuan Penggunaan Situs Birdmobil.id ini (“
            <strong>Syarat dan Ketentuan</strong>”) adalah perjanjian yang berlaku dan mengikat
            secara hukum antara pengguna (“
            <strong>Anda</strong>”) dan PT Pusaka Mitra Mobilindo serta afiliasinya (“
            <strong>Kami</strong>”). Syarat dan Ketentuan ini mengatur akses dan penggunaan Anda
            atas Situs, situs (www.birdmobil.id dan situs lainnya yang Kami kelola), konten dan
            produk yang disediakan oleh Kami (“<strong>Situs</strong>
            ”), serta pemesanan, pembayaran atau penggunaan layanan yang tersedia pada Situs Kami (“
            <strong>Layanan</strong>”).
            <br />
            <br /> Kami dapat mengubah ketentuan dalam Syarat dan Ketentuan ini sewaktu-waktu tanpa
            perlu mendapatkan persetujuan terlebih dahulu dari atau memberikan pemberitahuan
            sebelumnya kepada Anda. Perubahan tersebut merupakan bagian yang tidak terpisahkan dari
            Syarat dan Ketentuan ini dan akan berlaku setelah ditampilkan di Situs . Anda
            bertanggung jawab untuk meninjau Syarat dan Ketentuan ini secara teratur.
            <br />
            <br /> Dengan melanjutkan akses atau penggunaan terhadap Situs dan/atau Layanan, Anda
            setuju untuk tunduk dan mematuhi semua ketentuan dalam Syarat dan Ketentuan ini dan
            peraturan perundang-undangan yang berlaku, termasuk semua tambahan, perubahannya dan
            ketentuan penggunaan dari setiap penyedia Layanan, Penyedia Promosi (sebagaimana
            didefinisikan dibawah) atau Penyedia Metode Pembayaran (sebagaimana didefinisikan
            dibawah). Segera hentikan akses atau penggunaan Situs dan/atau Layanan jika Anda tidak
            setuju dengan bagian apapun dari Syarat dan Ketentuan ini.
          </p>

          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">2. Penggunaan Situs dan Layanan</span>
            <br /> <br />
            Akses dan penggunaan Situs dan/atau Layanan tunduk pada Syarat dan Ketentuan ini. Ketika
            memesan Produk atau Layanan, akan menghubungkan Anda dengan penyedia Layanan yang
            tersedia. Dengan demikian, beberapa Layanan tidak dapat digunakan bila Anda tidak
            mengaktifkan fitur penentuan lokasi.
            <br />
            <br />
            Dengan menggunakan Layanan, maka Anda setuju untuk tunduk pada kebijakan operasional
            Layanan Kami termasuk namun tidak terbatas pada batas area operasional, harga, biaya
            tambahan, pembatalan, barang dan hal lainnya yang merupakan bagian yang tidak
            terpisahkan dari Syarat dan Ketentuan ini, sehingga Kami dapat melakukan tindakan yang
            Kami anggap diperlukan apabila Anda tidak memenuhi kebijakan operasional Layanan Kami
            tanpa perlu mendapatkan persetujuan terlebih dahulu dari atau memberikan pemberitahuan
            sebelumnya kepada Anda.
            <br />
            <br />
            Anda mempunyai kebebasan penuh untuk memilih menggunakan Situs atau tidak, menggunakan
            Layanan yang tersedia pada Situs atau tidak, atau berhenti menggunakan Situs atau
            Layanan.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">3. Akun Anda</span>
            <br /> <br /> Sebelum menggunakan Situs , Anda harus menyetujui Syarat dan Ketentuan ini
            dan kebijakan privasi sebagaimana tercantum dalam Situs (“
            <strong>Kebijakan Privasi</strong>”), dan mendaftarkan diri Anda dengan memberikan
            informasi yang dibutuhkan oleh Kami. Saat melakukan pendaftaran, Kami akan meminta Anda
            untuk memberikan nama lengkap, alamat email, dan nomor telepon seluler pribadi Anda yang
            sah. Setelah melakukan verifikasi, sistem Kami akan membuatkan akun (“
            <strong>Akun</strong>”) untuk Anda yang dapat digunakan untuk menggunakan Situs dan
            memesan Layanan atau Produk. Anda dapat mengubah informasi data diri Anda pada fitur
            pengaturan dalam Situs.
            <br />
            <br />
            Akun Anda hanya dapat digunakan oleh Anda dan tidak bisa dialihkan kepada orang lain
            dengan alasan apapun. Kami berhak menolak untuk memfasilitasi layanan jika Kami
            mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah mengalihkan
            atau membiarkan Akun Anda digunakan oleh orang lain.
            <br />
            <br />
            Keamanan dan kerahasiaan Akun Anda, termasuk namun tidak terbatas pada nama terdaftar,
            alamat surat elektronik terdaftar, nomor telepon genggam terdaftar, rincian pembayaran
            dan Metode Pembayaran (sebagaimana didefinisikan dibawah) yang Anda pilih, serta kode
            verifikasi yang dihasilkan dan dikirim oleh sistem Kami atau Penyedia Metode Pembayaran
            (sebagaimana didefinisikan dibawah) sepenuhnya merupakan tanggung jawab pribadi Anda.
            Semua kerugian dan risiko yang ada akibat kelalaian Anda menjaga keamanan dan
            kerahasiaan sebagaimana disebutkan ditanggung oleh Anda sendiri. Dalam hal demikian,
            Kami akan menganggap setiap penggunaan atau pesanan yang dilakukan melalui Akun Anda
            sebagai permintaan yang sah dari Anda.
            <br />
            <br />
            Segera beritahukan Kami jika Anda mengetahui atau menduga bahwa Akun Anda telah
            digunakan tanpa sepengetahuan dan persetujuan Anda. Kami akan melakukan tindakan yang
            Kami anggap perlu dan dapat Kami lakukan terhadap penggunaan tanpa persetujuan tersebut.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">4. Informasi Pribadi</span>
            <br />
            <br />
            Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Anda,
            seperti nama, alamat surat elektronik, dan nomor telepon genggam Anda yang Anda berikan
            ketika Anda membuka Akun tunduk pada Kebijakan Privasi yang merupakan bagian yang tidak
            terpisahkan dari Syarat dan Ketentuan ini.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">5. Promosi</span>
            <br />
            <br />
            Kami atau pihak lain yang bekerja sama dengan Kami dapat memberikan penawaran atau
            promosi dalam bentuk apapun (“<strong>Promosi</strong>”) terkait dengan penggunaan Situs
            dan/atau Layanan . Dalam hal Promosi disediakan oleh pihak lain yang bekerja sama dengan
            Kami (“
            <strong>Penyedia Promosi</strong>”), Kami tidak bertanggung jawab atas bagian apapun
            dari isi Promosi tersebut. Akses atau penggunaan Anda terhadap Promosi merupakan bentuk
            persetujuan Anda untuk tunduk pada syarat dan ketentuan yang ditetapkan oleh Kami atau
            Penyedia Promosi, termasuk terhadap Syarat dan Ketentuan ini, Kebijakan Privasi Kami
            atau Penyedia Promosi.
            <br />
            <br />
            Anda tidak boleh menyalahgunakan Promosi yang Anda terima selama penggunaan Situs atau
            Layanan. Anda setuju untuk menggunakan Promosi tersebut sesuai dengan syarat, ketentuan,
            dan maksud dari pemberian Promosi yang ditetapkan oleh Kami atau Penyedia Promosi. Anda
            tidak akan melanggar syarat dan ketentuan Promosi, menyalahgunakan, menggandakan,
            menguangkan, mengalihkan, menggunakan untuk kepentingan komersial atau mengambil
            keuntungan dengan tidak wajar dari Promosi tersebut dengan bentuk atau cara apapun.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">6. Biaya dan Pembayaran</span>
            <br />
            <br />
            Penggunaan Situs birdmobil.id adalah bebas biaya.
            <br />
            <br />
            Layanan yang tersedia pada situs dikenakan biaya yang dapat Anda temukan pada situs
            sebelum Anda membeli produk atau layanan tersebut. Kami dapat mengubah atau
            memperbaharui biaya dari waktu ke waktu berdasarkan kebijakan operasional, Harga Eceran
            Tertinggi dari pemilik Merk, kebijakan dari Kami dan/atau peraturan perundang-undangan
            yang berlaku. Kami juga dapat membebankan harga dan/atau biaya dengan besaran tertentu
            yang dapat dipungut oleh Kami.
            <br />
            <br />
            Anda dapat melakukan pembayaran terhadap Layanan, atau Promosi yang Anda akses atau
            gunakan melalui metode pembayaran yang disediakan oleh pihak ketiga independen (“
            <strong>Penyedia Metode Pembayaran</strong>”), pada Situs , termasuk uang elektronik,
            kartu debit atau kredit, rekening bank, atau metode pembayaran lain (“
            <strong>Metode Pembayaran</strong>”) yang dapat berubah sewaktu-waktu berdasarkan
            kebijakan Kami sepenuhnya.
            <br />
            <br />
            Untuk dapat melakukan pembayaran melalui Metode Pembayaran, Anda harus terdaftar secara
            resmi pada Penyedia Metode Pembayaran yang Anda pilih dan menggunakan kredensial
            pembayaran Anda sendiri. Dalam hal Anda menggunakan kredensial terdaftar pihak lain,
            Anda bertanggung jawab secara penuh atas semua perizinan yang diperlukan dan semua
            kerugian atau sengketa yang timbul antara Anda dan pihak lain tersebut, baik karena
            kelalaian atau kesalahan Anda, Kami, penyedia Layanan, Penyedia Promosi, Penyedia Metode
            Pembayaran atau pihak ketiga yang bersangkutan.
            <br />
            <br />
            Kami berhak menolak atau menunda untuk meneruskan permintaan pembayaran Anda melalui
            Metode Pembayaran karena alasan tertentu, termasuk namun tidak terbatas pada adanya
            indikasi atau Kami mempunyai alasan yang cukup untuk menduga adanya kecurangan,
            penipuan, pelanggaran Syarat dan Ketentuan ini, pelanggaran atas peraturan
            perundang-undangan yang berlaku termasuk yang terkait dengan alat pembayaran menggunakan
            kartu, uang elektronik, pemrosesan transaksi pembayaran, anti pencucian uang, korupsi
            dan pencegahan pendanaan terorisme, atau tindakan lain yang tidak wajar atau
            mencurigakan, termasuk belum dipenuhinya kewajiban Anda kepada Kami.
            <br />
            <br />
            Ketentuan lebih lanjut tentang biaya yang berlaku terhadap Layanan tertentu, Promosi
            dan/atau Metode Pembayaran dapat ditemukan dalam syarat dan ketentuan tambahan dari
            Layanan yang Anda pesan dan syarat dan ketentuan dari penyedia Layanan, Penyedia Promosi
            dan/atau Penyedia Metode Pembayaran.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">7. Kekayaan Intelektual</span>
            <br />
            <br />
            Situs dan Layanan, termasuk namun tidak terbatas pada, nama, logo, kode program, desain,
            merek dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak cipta,
            merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum
            Republik Indonesia yang terdaftar atas nama Kami. Kami (dan pemberi lisensi Kami)
            memiliki seluruh hak dan kepentingan atas Situs dan Layanan, termasuk seluruh hak
            kekayaan intelektual terkait dengan seluruh fitur yang terdapat didalamnya dan hak
            kekayaan intelektual terkait. Syarat dan Ketentuan ini bukan merupakan perjanjian
            penjualan dan tidak memberikan kepada Anda hak kepemilikan apapun dalam atau terkait
            dengan Layanan dan/atau Situs, atau hak kekayaan intelektual apapun yang dimiliki oleh
            Kami (dan pemberi lisensi Kami).
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">8. Tanggung Jawab Anda</span>
            <br />
            <br />
            Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau
            mengakses Situs , Layanan, Promosi atau Metode Pembayaran. Anda harus memperlakukan
            penyedia Layanan, Penyedia Promosi dan Penyedia Metode Pembayaran dengan hormat dan
            tidak boleh terlibat dalam perilaku atau tindakan yang tidak sah, mengancam atau
            melecehkan ketika menggunakan Layanan, Promosi atau Metode Pembayaran.
            <br />
            <br />
            Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari
            penggunaan Situs, Layanan, Promosi atau Metode Pembayaran melalui Akun Anda, baik oleh
            Anda atau pihak lain yang menggunakan Akun Anda, dengan cara yang bertentangan dengan
            Syarat dan Ketentuan ini, Kebijakan Privasi, Penyedia Promosi, Penyedia Metode
            Pembayaran, atau peraturan perundang-undangan yang berlaku, termasuk namun tidak
            terbatas untuk tujuan anti pencucian uang, anti pendanaan terorisme, aktivitas kriminal,
            penipuan dalam bentuk apapun, pelanggaran hak kekayaan intelektual, dan/atau aktivitas
            lain yang merugikan Kami dan/atau publik dan/atau pihak lain manapun atau yang dapat
            atau dianggap dapat merusak reputasi Kami dan/atau pihak lain manapun.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">9. Batasan Tanggung Jawab Kami</span>
            <br />
            <br />
            Kami menyediakan Situs , Produk dan/atau Layanan sebagaimana adanya dan Kami tidak
            menyatakan atau menjamin bahwa keandalan, ketepatan waktu, kualitas, kesesuaian,
            ketersediaan, akurasi, kelengkapan atau keamanan dari Situs dan/atau Layanan dapat
            memenuhi kebutuhan dan akan sesuai dengan harapan Anda, termasuk namun tidak terbatas
            pada Promosi dan Metode Pembayaran yang sepenuhnya menjadi tanggung jawab Penyedia
            Promosi dan Penyedia Metode Pembayaran. Kami tidak bertanggung jawab atas setiap
            kerugian atau kerusakan yang disebabkan oleh setiap kegagalan atau kesalahan yang
            dilakukan oleh Penyedia Promosi atau Penyedia Metode Pembayaran ataupun kegagalan atau
            kesalahan Anda dalam mematuhi Syarat Dan Ketentuan ini, syarat dan ketentuan dari
            penyedia Layanan, Penyedia Promosi atau Penyedia Metode Pembayaran.
            <br />
            <br />
            Situs dapat mengalami keterbatasan, penundaan, dan masalah-masalah lain yang terdapat
            dalam penggunaan internet dan komunikasi elektronik, termasuk perangkat Anda, Penyedia
            Promosi atau Penyedia Metode Pembayaran rusak, tidak terhubung dengan internet, berada
            di luar jangkauan, dimatikan atau tidak berfungsi. Kami tidak bertanggung jawab atas
            keterlambatan, kegagalan pengiriman, kerusakan atau kerugian yang diakibatkan oleh
            masalah-masalah tersebut.
            <br />
            <br />
            Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda atas Situs. Namun,
            Kami tetap melakukan pengawasan untuk tujuan memastikan kelancaran penggunaan Situs dan
            untuk memastikan kepatuhan terhadap Syarat dan Ketentuan ini, peraturan
            perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga
            administratif atau badan pemerintahan lainnya.
            <br />
            <br />
            Kami tidak mempunyai kewajiban apapun, termasuk untuk mengambil tindakan lebih jauh atau
            tindakan hukum yang dianggap perlu oleh Anda, Penyedia Promosi atau Penyedia Metode
            Pembayaran, terhadap setiap permasalahan atau perselisihan yang timbul antara Anda dan
            Penyedia Promosi atau Penyedia Metode Pembayaran. Tetapi, Kami akan memfasilitasi setiap
            permasalahan atau perselisihan yang timbul antara Anda dan Penyedia Promosi atau
            Penyedia Metode Pembayaran dengan upaya wajar yang diperlukan. Ketika Kami memfasilitasi
            penyelesaian permasalahan atau perselisihan antara Anda dan Penyedia Promosi atau
            Penyedia Metode Pembayaran, Kami tidak bertindak sebagai mediator dan hal tersebut tidak
            menimbulkan kewajiban lebih jauh apapun terhadap Kami.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">
              10. Pembekuan Sementara, Pembekuan Permanen Akun{' '}
            </span>
            <br />
            <br />
            Akun Anda dapat dibekukan untuk sementara waktu atau dapat dibekukan secara permanen
            oleh Kami tanpa perlu mendapatkan persetujuan terlebih dahulu dari atau memberikan
            pemberitahuan sebelumnya kepada Anda, karena hal-hal, termasuk namun tidak terbatas
            pada:
            <br />
            <ol className="list-inside flex flex-col gap-2 list-[lower-alpha]">
              <li>
                Laporan Anda bahwa Akun Anda digunakan atau diduga digunakan atau disalahgunakan
                oleh orang lain;
              </li>
              <li>
                Laporan Anda bahwa telepon genggam atau tablet pribadi Anda hilang, dicuri atau
                diretas;
              </li>
              <li>
                Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Akun Anda telah
                dialihkan atau digunakan oleh orang lain;
              </li>
              <li>
                Kami mengetahui atau dengan alasan yang cukup menduga bahwa telah terjadi hal-hal
                yang menurut pandangan Kami telah atau dapat merugikan Kami, Anda, penyedia Layanan,
                Penyedia Promosi, Penyedia Metode Pembayaran atau pihak lainnya;
              </li>
              <li>
                Kami mengetahui atau dengan alasan yang cukup menduga bahwa Anda telah mendaftar
                atau masuk dalam banyak Akun dalam satu perangkat untuk tujuan melanggar Syarat dan
                Ketentuan ini, Kebijakan Privasi, atau peraturan dan hukum yang berlaku.
              </li>
              <li>
                Kami mendeteksi adanya tindakan yang tidak wajar dari penggunaan Akun Anda atau
                adanya kewajiban berdasarkan Syarat dan Ketentuan ini, dan/atau Kebijakan Privasi
                yang tidak dipenuhi oleh Anda;
              </li>
              <li>
                Anda telah meninggal dunia, ditempatkan di bawah perwalian atau pengampuan atau
                mengalami ketidakmampuan lainnya yang menjadikan Anda tidak cakap hukum berdasarkan
                peraturan perundang-undangan yang berlaku;
              </li>
              <li>
                Penggunaan Situs atau Layanan oleh Anda atau pihak lain (yang menggunakan Akun Anda)
                dengan cara yang bertentangan dengan Syarat dan Ketentuan ini, Kebijakan Privasi
                atau peraturan perundang-undangan yang berlaku; dan/atau
              </li>
              <li>
                Perintah untuk pembekuan Akun, baik sementara atau permanen, yang diterbitkan oleh
                institusi pemerintah atau moneter terkait atau berdasarkan perintah pengadilan yang
                diterbitkan sesuai dengan peraturan perundang-undangan yang berlaku.
              </li>
            </ol>
            <br />
            <br />
            Jika Akun Anda dibekukan dan Anda memiliki bukti yang jelas bahwa Akun Anda seharusnya
            tidak dibekukan, Anda dapat membuat laporan kepada Kami untuk menyampaikan bukti-bukti
            tersebut. Setelah melakukan pemeriksaan lebih lanjut terhadap laporan Anda, Kami akan,
            atas kebijakan Kami sepenuhnya, menentukan untuk mengakhiri atau melanjutkan pembekuan
            terhadap Akun Anda.
          </p>
          <p className="text-md-regular text-gray-600">
            <span className="text-md-medium text-gray-900">
              11. Tindakan Yang Kami Anggap Perlu
            </span>
            <br />
            <br />
            Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah
            melakukan tindakan asusila, pelanggaran, kejahatan atau tindakan lain yang melanggar
            dan/atau bertentangan dengan Syarat dan Ketentuan ini dan/atau peraturan
            perundang-undangan yang berlaku, baik yang dirujuk dalam Syarat dan Ketentuan ini atau
            tidak, maka Kami berhak untuk dan dapat membekukan Akun, baik sementara atau permanen,
            atau menghentikan akses Anda terhadap Situs, termasuk Layanan, Promosi dan/atau Metode
            Pembayaran yang terdapat di dalamnya, melakukan pemeriksaan, menolak transaksi, menuntut
            ganti kerugian, melaporkan kepada pihak berwenang dan/atau mengambil tindakan lain yang
            kami anggap perlu, termasuk tindakan hukum pidana maupun perdata.
            <br />
            <br />
            Kami dapat menindaklanjuti dengan melakukan investigasi dan/atau memfasilitasi pihak
            ketiga yang bersangkutan untuk melaporkan kepada pihak yang berwajib apabila Kami
            menerima laporan adanya pelanggaran yang Anda lakukan atas Syarat dan Ketentuan ini
            ataupun pelanggaran terhadap peraturan perundang-undangan yang berlaku, termasuk namun
            tidak terbatas pada:
            <ol className="list-[lower-alpha] list-inside flex flex-col gap-2">
              <li>
                Pelecehan atau kekerasan verbal, termasuk namun tidak terbatas pada, atas fisik,
                jenis kelamin, suku, agama dan ras, yang Anda lakukan terhadap pihak ketiga yang
                bersangkutan;
              </li>
              <li>Tindakan penipuan;</li>
              <li>Penggunaan Situs pada perangkat rooted atau jail-broken;</li>
              <li>
                Pelecehan atau kekerasan fisik yang Anda lakukan terhadap pihak ketiga yang
                bersangkutan; dan/atau
              </li>
              <li>
                Pelecehan atau kekerasan seksual, baik secara verbal maupun fisik, yang Anda lakukan
                terhadap pihak ketiga yang bersangkutan.
              </li>
            </ol>
          </p>
          <p>
            <span className="text-md-medium text-gray-900">12. Pernyataan Anda</span>
            <br />
            <br />
            Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18 (delapan belas) tahun
            atau sudah menikah dan tidak berada di bawah perwalian atau pengampuan dan Anda secara
            hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Syarat dan Ketentuan
            ini. Jika Anda tidak memenuhi ketentuan tersebut namun tetap mengakses atau menggunakan
            Situs , Layanan, Promosi atau Metode Pembayaran, Anda menyatakan dan menjamin bahwa
            tindakan Anda membuka, mengakses atau melakukan aktivitas lain dalam Situs telah
            disetujui oleh orang tua, wali atau pengampu Anda. Anda secara tegas mengesampingkan
            setiap hak berdasarkan hukum untuk membatalkan atau mencabut setiap dan seluruh
            persetujuan yang Anda berikan berdasarkan Syarat dan Ketentuan ini pada waktu Anda
            dianggap telah dewasa secara hukum.
            <br />
            <br />
            Anda setuju untuk mengakses atau menggunakan Situs , Layanan, Promosi atau Metode
            Pembayaran hanya untuk tujuan sebagaimana ditentukan dalam Syarat dan Ketentuan ini dan
            tidak menyalahgunakan atau menggunakan Situs , Layanan, Promosi atau Metode Pembayaran
            untuk tujuan penipuan, menyebabkan ketidaknyamanan kepada orang lain, melakukan
            pemesanan palsu atau yang tindakan-tindakan lain yang dapat atau dianggap dapat
            menimbulkan kerugian dalam bentuk apapun terhadap orang lain.
            <br />
            <br />
            Anda memahami dan menyetujui bahwa seluruh resiko yang timbul dari penggunaan Situs ,
            Layanan, Promosi dan Metode Pembayaran sepenuhnya menjadi tanggung jawab Anda dan Anda
            dengan ini setuju untuk melepaskan Kami dari segala tuntutan apapun sehubungan dengan
            kerusakan, gangguan, atau bentuk lain dari gangguan sistem yang disebabkan oleh akses
            tidak resmi oleh pihak lain.
            <br />
            <br />
            Anda memahami bahwa Situs dapat menggunakan data dari layanan dan konten dari pihak
            ketiga, dan dengan menggunakan Situs, anda setuju untuk mematuhi ketentuan layanan pihak
            ketiga tersebut.
            <br />
            <br />
            Anda secara tegas membebaskan Kami, termasuk namun tidak terbatas pada, pejabat,
            direktur, komisaris, karyawan dan agen Kami, dari setiap dan semua kewajiban,
            konsekuensi, kerugian baik materiil atau immateriil, tuntutan, biaya-biaya (termasuk
            biaya advokat) atau tanggung jawab hukum lainnya yang timbul atau mungkin timbul akibat
            pelanggaran Anda terhadap Syarat dan Ketentuan ini maupun sehubungan dengan tindakan
            Penyedia Promosi atau Penyedia Metode Pembayaran.
          </p>
          <p>
            <span className="text-md-medium text-gray-900">13. Keadaan Kahar</span>
            <br />
            <br />
            Situs atau Layanan dapat diinterupsi oleh kejadian di luar kewenangan atau kontrol Kami
            (“<strong>Keadaan Kahar</strong>”), termasuk namun tidak terbatas pada bencana alam,
            gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda
            setuju untuk membebaskan Kami dari setiap tuntutan dan tanggung jawab, jika Kami tidak
            dapat memfasilitasi atau memberikan Layanan, termasuk memenuhi instruksi yang Anda
            berikan melalui Situs i, baik sebagian maupun seluruhnya, karena suatu Keadaan Kahar.
          </p>
          <p>
            <span className="text-md-medium text-gray-900">14. Lain-Lain</span>
            <br />
            <br />
            Anda mengerti dan setuju bahwa Syarat dan Ketentuan ini merupakan perjanjian dalam
            bentuk elektronik dan tindakan Anda menekan tombol {"'"}daftar{"'"} saat pembukaan Akun
            atau tombol {"'"}masuk{"'"} saat akan mengakses Akun Anda merupakan persetujuan aktif
            Anda untuk mengikatkan diri dalam perjanjian dengan Kami sehingga keberlakuan Syarat dan
            Ketentuan ini dan Kebijakan Privasi adalah sah dan mengikat secara hukum dan terus
            berlaku sepanjang penggunaan Situs dan Layanan oleh Anda.
            <br />
            <br />
            Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Syarat
            dan Ketentuan atau Kebijakan Privasi yang dibuat dalam bentuk elektronik.
            <br />
            <br />
            Anda tidak dapat mengalihkan hak dan kewajiban Anda berdasarkan Syarat dan Ketentuan ini
            tanpa persetujuan tertulis sebelumnya dari Kami. Namun, Kami dapat mengalihkan hak dan
            kewajiban Kami berdasarkan Syarat dan Ketentuan ini setiap saat kepada pihak lain tanpa
            perlu mendapatkan persetujuan terlebih dahulu dari atau memberikan pemberitahuan
            sebelumnya kepada Anda.
            <br />
            <br />
            Bila Anda tidak mematuhi atau melanggar ketentuan dalam Syarat dan Ketentuan ini, dan
            Kami tidak mengambil tindakan secara langsung, bukan berarti Kami mengesampingkan hak
            Kami untuk mengambil tindakan yang diperlukan di kemudian hari.
            <br />
            <br />
            Pengakhiran perjanjian ini antara Anda dan Kami tidak akan membebaskan atau membatasi
            Anda dari kewajiban dan tanggung jawab yang timbul sebelum pengakhiran tersebut.
            <br />
            <br />
            Anda dan Kami sepakat untuk mengesampingkan ketentuan Pasal 1266 Kitab Undang-undang
            Hukum Perdata Indonesia sepanjang diperlukannya putusan pengadilan untuk mengakhiri
            perjanjian.
            <br />
            <br />
            Jika salah satu dari ketentuan dalam Syarat dan Ketentuan ini tidak dapat diberlakukan,
            hal tersebut tidak akan memengaruhi ketentuan lainnya.
            <br />
            <br />
            Kami membuat Syarat dan Ketentuan ini dalam bahasa Indonesia. Setiap perbedaan akan
            diartikan sesuai dengan bahasa Indonesia.
            <br />
            <br />
            Syarat dan Ketentuan ini diatur berdasarkan hukum Republik Indonesia. Setiap dan seluruh
            perselisihan yang timbul dari penggunaan Situs atau Layanan tunduk pada yurisdiksi
            eksklusif Pengadilan Negeri Jakarta Selatan.
          </p>
          <p>
            <span className="text-md-medium text-gray-900">15. Hubungi Kami</span>
            <br />
            <br />
            Anda dapat menghubungi Kami melalui surat elektonik ke customerservice@birdmobil.id atau
            melalui telepon ke nomor 021-79171000. Semua korespondensi Anda akan dicatat, direkam
            dan disimpan untuk arsip Kami.
          </p>
        </Col>
      </Col>
    </Row>
  )
}

export default TermsConditions
