import { Col, DatePicker, Divider, Row } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import FormSelect from 'components/ui/form/form-select'
import dayjs from 'dayjs'
import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const HeroJualSection = ({
  onClick,
  fuels,
  transmissions,
  colors,
  brands,
  models,
  types,
  state,
  price,
  handleChange,
  handleChangeCarList,
  changeComponent,
  handleClick,
  handleCancel
}) => {
  return (
    <Row className={`w-full h-[calc(100%+6rem)] md:relative bg-white`}>
      {/* Background */}
      <div className='w-full h-[8rem] md:h-[calc(100%-12rem)] z-[0] md:absolute bg-cover bg-no-repeat bg-[url("features/jual-mobil/assets/hero-jual-background.webp")]'></div>
      {/* Content Container mobile */}
      <div className="block md:hidden px-6 pb-6 mx-auto">
        <CardCekHargaComponent
          changeComponent={changeComponent}
          fuels={fuels}
          transmissions={transmissions}
          colors={colors}
          brands={brands}
          models={models}
          types={types}
          state={state}
          price={price}
          handleChange={handleChange}
          handleCancel={handleCancel}
          handleChangeCarList={handleChangeCarList}
          handleClick={handleClick}
          onClick={onClick}
        />
      </div>
      {/* Content Container website*/}
      <Col span={20} className="mx-auto hidden md:grid p-24">
        <div className="grid grid-cols-2 gap-6 h-[525px] relative">
          {/* Left Content: Title */}
          <div className="flex flex-col h-full">
            <div className="flex flex-col gap-6 text-white">
              <p className="display-lg-semibold">
                Platform Jual Beli Mobil dengan Cepat dan Terpercaya
              </p>
            </div>
            {/* Total Penjualan */}
            {/* <div className="flex gap-5 absolute bottom-0">
              <div className="flex flex-col gap-2">
                <p className="display-md-semibold text-primary-500">140.203</p>
                <p className="text-md-semibold text-gray-700">Penjualan</p>
              </div>
              <Divider type="vertical" className="m-0 h-full" />
              <div className="flex flex-col gap-2">
                <p className="display-md-semibold text-primary-500">21.678</p>
                <p className="text-md-semibold text-gray-700">Orang Puas</p>
              </div>
              <Divider type="vertical" className="m-0 h-full" />
              <div className="flex flex-col gap-2">
                <p className="display-md-semibold text-primary-500">11.902</p>
                <p className="text-md-semibold text-gray-700">Berhasil Diservis</p>
              </div>
            </div> */}
          </div>

          {/* Right Content: Form */}
          <CardCekHargaComponent
            changeComponent={changeComponent}
            fuels={fuels}
            transmissions={transmissions}
            colors={colors}
            brands={brands}
            models={models}
            types={types}
            state={state}
            price={price}
            handleChange={handleChange}
            handleCancel={handleCancel}
            handleChangeCarList={handleChangeCarList}
            handleClick={handleClick}
            onClick={onClick}
          />
        </div>
      </Col>
    </Row>
  )
}

const CardCekHargaComponent = ({
  changeComponent,
  fuels,
  transmissions,
  colors,
  brands,
  models,
  types,
  state,
  price,
  handleChange,
  handleCancel,
  handleChangeCarList,
  handleClick,
  onClick
}) => (
  <CardSectionHeader hideDivider className={'md:ml-6'}>
    <div className="grid grid-cols-1 gap-6 h-full">
      {changeComponent ? (
        <FormCekHargaComponent
          handleClick={handleClick}
          fuels={fuels}
          transmissions={transmissions}
          colors={colors}
          brands={brands}
          models={models}
          types={types}
          state={state}
          handleChange={handleChange}
          handleChangeCarList={handleChangeCarList}
        />
      ) : (
        <EstimatePriceComponent state={state} price={price} handleClick={handleCancel} />
      )}
      <CustomButton onClick={onClick} label={'Lanjut Jual Mobil'} />
    </div>
  </CardSectionHeader>
)

const FormCekHargaComponent = ({
  handleClick,
  // fuels,
  transmissions,
  // colors,
  brands,
  models,
  types,
  state,
  handleChange,
  handleChangeCarList
}) => {
  const dateFormat = 'YYYY/MM/DD'
  const informasiMobilInput = [
    {
      id: 1,
      label: 'Merek',
      name: 'merek',
      type: 'select',
      options: brands,
      placeholder: 'Pilih Merek',
      mandatory: true,
      value: state?.brand?.value,
      onChange: (_, option) => {
        handleChangeCarList('brand', option)
      }
    },
    {
      id: 2,
      label: 'Model',
      name: 'model',
      type: 'select',
      options: models,
      placeholder: 'Pilih Model',
      mandatory: true,
      value: state?.model?.value,
      onChange: (_, option) => {
        handleChangeCarList('model', option)
      }
    },
    {
      id: 3,
      label: 'Tipe',
      name: 'tipe',
      type: 'select',
      options: types,
      placeholder: 'Pilih Tipe',
      mandatory: true,
      value: state?.type?.value,
      onChange: (_, option) => {
        handleChangeCarList('type', option)
      }
    },
    {
      id: 4,
      label: 'Transmisi',
      name: 'transmisi',
      type: 'select',
      options: transmissions,
      placeholder: 'Pilih Transmisi',
      value: state?.transmisi?.value,
      mandatory: true,
      onChange: (_, option) => {
        handleChange('transmisi', option)
      }
    }
  ]
  return (
    <>
      <p className="text-xl-medium text-gray-900">Jual Mobil</p>
      <div className="grid grid-cols-2 gap-6">
        {informasiMobilInput.map((data, index) => (
          <FormSelect key={index} data={data} />
        ))}
        <div className="grid grid-cols-1 gap-2">
          <p className="text-sm-medium text-gray-700">
            Tahun Keluaran <span className="text-danger-500">*</span>
          </p>
          <DatePicker
            placeholder={'Tahun'}
            onChange={(_, dateString) => handleChange('year', dateString)}
            picker="year"
            value={state?.year !== '' ? dayjs(`${state?.year}/01/01`, dateFormat) : ''}
          />
        </div>
      </div>
      <CustomButton onClick={handleClick} type="secondary" label={'Cek Harga Jual'} />
      <Divider className="m-0" />
    </>
  )
}

const EstimatePriceComponent = ({ handleClick, price, state }) => (
  <>
    {/* Button Container */}
    <div>
      <div onClick={handleClick} className="flex gap-2 cursor-pointer items-center">
        <AiOutlineArrowLeft className="fill-gray-500" />
        <p className="text-md-medium text-gray-500">Kembali</p>
      </div>
    </div>
    {/* Estimate Price */}
    <div className="flex flex-col justify-center items-center gap-3">
      <p className="text-md-regular text-gray-600">Berikut estimasi harga jual untuk mobil</p>
      <p className="text-xl-medium text-gray-900">{`${state?.brand?.label} ${state.model?.label} ${state?.type?.label}`}</p>
    </div>
    <div className="flex flex-col justify-center items-center py-9 gap-2 display-sm-semibold text-warning-500">
      {price?.min_price !== '-' ? (
        <>
          <p>Rp. {price?.min_price}</p>
          <p className="text-md-regular text-gray-600">sampai</p>
          <p>Rp. {price?.max_price}</p>
        </>
      ) : (
        <p className="text-md-regular">Belum ada rekomendasi harga</p>
      )}
    </div>
    <p className="text-center text-gray-600 text-md-regular">
      Ingin mendapatkan penawaran harga dari Kami?
    </p>
  </>
)
export default HeroJualSection
