const BookmarkIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      stroke="#667085"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8327 16.5L6.99935 12.3333L1.16602 16.5V3.16667C1.16602 2.72464 1.34161 2.30072 1.65417 1.98816C1.96673 1.67559 2.39065 1.5 2.83268 1.5H11.166C11.608 1.5 12.032 1.67559 12.3445 1.98816C12.6571 2.30072 12.8327 2.72464 12.8327 3.16667V16.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BookmarkIcon
