import { CarFlatlineIcon } from 'assets'
import React from 'react'

const NullComponentMobilBekas = ({ cabang }) => {
  return (
    <div className="flex flex-col justify-center text-center items-center h-full gap-4 max-w-[280px] mx-auto">
      <CarFlatlineIcon className={'max-w-[280px] md:max-w-full'} />
      <p className="text-lg-semibold md:text-xl-semibold text-gray-500">
        Tidak ada unit yang dijual!
      </p>
      <p className="text-sm-medium md:text-md-medium text-gray-400">
        Unit yang dijual tidak tersedia pada bengkel {cabang ? `“${cabang?.label}”` : ''}
      </p>
    </div>
  )
}

export default NullComponentMobilBekas
