import CardInformationJadwal from 'components/ui/card/card-information-jadwal'
import CardInformationUser from 'components/ui/card/card-information-user'
import CardUnit from 'components/ui/card/card-unit'
import React from 'react'

const ReviewContent = ({ data, dataCatalog, selectedWorkshop, selectedSchedule }) => {
  return (
    <div className="grid grid-cols-1 gap-6">
      <CardUnit dataCatalog={dataCatalog} />
      {/* review Data diri */}
      <CardInformationUser
        headerTitle={'Data diri'}
        data={{
          nama: data.nama,
          email: data.email,
          phone: data.phone,
          alamat: data.alamat
        }}
      />
      {/* penentu lokasi & */}
      <CardInformationJadwal
        data={{
          date: data.date,
          hours: {
            time: selectedSchedule?.time
          }
        }}
        workshop={selectedWorkshop}
        selectedSchedule={selectedSchedule}
      />
    </div>
  )
}

export default ReviewContent
