const LokasiInspeksiFleksibelIcon = ({ className }) => {
  return (
    <svg
      width="67"
      className={className}
      height="82"
      viewBox="0 0 67 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8252 26.3371C25.9809 26.7022 41.4606 26.7022 48.6163 26.3371M48.3973 23.9275H52.2672M15.3203 23.9275H19.1902M41.9717 32.1055H47.0099M20.5045 32.1055H25.5427M37.8827 78.6177L58.4007 53.7917C63.4389 47.2932 65.9215 41.8168 65.9215 33.2738C65.9215 15.4575 51.537 1 33.7938 1C16.0505 1 1.66602 15.4575 1.66602 33.2738C1.66602 41.8168 4.22156 47.2932 9.25978 53.7917L29.7047 78.6177C32.4064 81.8305 35.1081 81.7575 37.8827 78.6177ZM42.921 38.8961V41.6708C42.921 42.1089 43.286 42.401 43.7241 42.401H50.1496C50.5877 42.401 50.9529 42.0359 50.9529 41.6708V30.7182C50.9529 29.6229 50.7339 28.8197 50.2227 28.1626C49.7846 27.6514 46.7908 23.2704 43.5781 19.3274C43.432 19.1084 43.067 18.7433 42.3368 18.6703C35.4001 18.2322 32.1143 18.2322 25.1777 18.6703C24.5205 18.7433 24.0824 19.1084 23.9364 19.3274C20.7236 23.1974 17.6568 27.6514 17.2917 28.1626C16.7806 28.8197 16.4885 29.6229 16.5615 30.7182V41.7438C16.5615 42.1819 16.9267 42.474 17.3648 42.474H23.7903C24.2284 42.474 24.5935 42.1089 24.5935 41.7438V38.9692C31.2381 39.2612 36.3494 39.2612 42.921 38.8961Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="22.926"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8479 23.9261H24.6665C24.4475 23.9261 24.3014 23.634 24.4474 23.415L25.9078 21.3705C25.9808 21.2975 26.0539 21.2245 26.1269 21.2245C28.4635 21.0054 31.0921 20.8594 33.8667 20.8594C36.6414 20.8594 39.197 21.0054 41.4606 21.2245C41.5336 21.2245 41.6066 21.2975 41.6796 21.3705L43.1399 23.415C43.286 23.634 43.1399 23.9261 42.8479 23.9261Z"
        fill="#E41E25"
      />
    </svg>
  )
}

export default LokasiInspeksiFleksibelIcon
