const HargaPastiIcon = ({ className }) => {
  return (
    <svg width="81" height="82" viewBox="0 0 81 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.1029 63.0527C49.1029 71.4087 42.3454 78.2389 33.9167 78.2389C25.5606 78.2389 18.7305 71.4814 18.7305 63.0527C18.7305 54.6966 25.488 47.8665 33.9167 47.8665C42.2727 47.8665 49.1029 54.624 49.1029 63.0527Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.4488 47.8665V34.2788C65.4488 32.8983 64.3589 31.8083 62.9783 31.8083C61.5977 31.8083 60.5078 32.8983 60.5078 34.2788V51.9355"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.4627 47.8664V44.0154C70.4627 42.6348 69.3728 41.5449 67.9922 41.5449"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.4783 47.8665V45.6867C75.4783 44.3061 74.3884 43.2162 73.0078 43.2162"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.4217 72.4987C76.3491 67.921 76.7124 67.0491 76.7124 67.0491C76.7124 67.0491 76.9303 65.8865 77.439 64.4333C78.8922 59.9283 79.6915 55.2053 79.7641 50.4823V48.3751C79.7641 46.9946 78.6742 45.9047 77.2936 45.9047"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.2561 45.5413C57.6748 44.8874 56.8755 44.524 56.0036 44.524C54.5503 44.524 53.7511 45.396 53.5331 46.1953C53.5331 46.2679 53.4604 46.3406 53.4604 46.4132C53.2424 47.2852 53.3878 48.1571 53.6784 48.9564C54.2597 50.3369 55.2043 53.2434 54.6957 57.0944C54.405 59.2743 54.9136 61.5268 56.2215 63.416C57.0208 64.5785 57.9654 65.8864 58.9827 67.1217C58.9827 67.1217 61.4532 69.5922 61.4532 72.5713"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.2224 45.832H75.4785"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.9342 43.1436H70.3184"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.9924 41.4723H65.4492"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.5469 81V73.8792C58.5469 73.0799 59.2008 72.4987 59.9274 72.4987H77.7294C78.5287 72.4987 79.11 73.1526 79.11 73.8792V81"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.7937 18.1481L45.3232 20.4006C44.306 21.2725 43.0707 21.7811 41.6902 21.7811H35.0053H26.1407C24.8327 21.7811 23.5248 21.2725 22.5076 20.4006L20.0371 18.1481"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.3132 33.9155H47.1406V32.099C47.1406 30.2825 48.4485 28.7566 50.2651 28.5386L57.3132 27.594"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.86328 27.594L16.9114 28.5386C18.728 28.8292 20.0359 30.3551 20.0359 32.099V33.9155H9.86328"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.3418 23.2344V40.1644"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.34242 23.2343L1.50863 20.1826C1.14532 19.8919 1 19.4559 1 18.9473V15.6049C1 14.733 1.72661 13.9337 2.67121 13.9337H9.0654L14.5876 3.32516C15.6776 1.87193 17.4214 1 19.238 1H33.5522H47.8665C49.683 1 51.4269 1.87193 52.5168 3.32516L58.0391 13.9337H64.4333C65.3052 13.9337 66.1045 14.6603 66.1045 15.6049V18.9473C66.1045 19.3833 65.8865 19.8193 65.5958 20.1826L62.762 23.2343V28.5386"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6597 40.1644V40.8184C14.6597 43.6522 12.3345 45.9773 9.50074 45.9773C6.66696 45.9773 4.3418 43.6522 4.3418 40.8184V40.1644"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.3111 40.1644H34.5681H9.86328"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5547 33.9155H37.6201"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9543 66.9764L29.9925 64.1426C29.9198 64.1426 29.7745 64.1426 29.6292 64.1426H27.4493V66.9764H25.6328V57.3851H29.5565C30.3558 57.3851 31.0824 57.5305 31.7364 57.8211C32.3903 58.1117 32.8263 58.4751 33.1896 58.9837C33.5529 59.4923 33.6982 60.0736 33.6982 60.8002C33.6982 61.5268 33.5529 62.1808 33.1896 62.6894C32.8263 63.198 32.3176 63.634 31.6637 63.852L33.8435 66.9764H31.9543ZM31.3004 59.347C30.8644 58.9837 30.2831 58.8384 29.5565 58.8384H27.4493V62.6894H29.5565C30.3558 62.6894 30.9371 62.5441 31.3004 62.1808C31.7364 61.8175 31.8817 61.3815 31.8817 60.7276C31.8817 60.1463 31.7364 59.7103 31.3004 59.347Z"
        fill="white"
      />
      <path
        d="M41.5461 60.0009C42.1274 60.2916 42.5634 60.7276 42.854 61.3088C43.1447 61.8901 43.3626 62.5441 43.3626 63.2707C43.3626 63.9973 43.2173 64.6513 42.854 65.2325C42.5634 65.8138 42.1274 66.2498 41.5461 66.5405C40.9648 66.8311 40.3109 66.9764 39.6569 66.9764C38.6397 66.9764 37.9131 66.6131 37.3318 65.9592V69.5922H35.5879V59.6376H37.1864V60.5822C37.4771 60.2189 37.8404 60.0009 38.2037 59.783C38.567 59.565 39.0756 59.4923 39.5843 59.4923C40.3109 59.565 40.9648 59.7103 41.5461 60.0009ZM40.9648 64.9419C41.4008 64.5059 41.5461 63.9973 41.5461 63.2707C41.5461 62.6167 41.3281 62.0355 40.9648 61.5995C40.5289 61.1635 40.0202 60.9455 39.4389 60.9455C39.003 60.9455 38.6397 61.0182 38.349 61.2362C37.9857 61.4542 37.7677 61.6721 37.5497 62.0355C37.3318 62.3988 37.2591 62.7621 37.2591 63.2707C37.2591 63.7793 37.3318 64.1426 37.5497 64.5059C37.7677 64.8692 37.9857 65.0872 38.349 65.3052C38.7123 65.5232 39.0756 65.5959 39.4389 65.5959C40.0202 65.5959 40.5289 65.3779 40.9648 64.9419Z"
        fill="white"
      />
    </svg>
  )
}

export default HargaPastiIcon
