import { Col, Row } from 'antd'
import { SampleCar } from 'assets'
import CardHistory from 'components/ui/card/card-history'
import CardImage from 'components/ui/card/card-image'
import CardInformation from 'components/ui/card/card-information'
import CardResultInspection from 'components/ui/card/card-result-inspection'
import CardNote from 'components/ui/card/card-note'
import React from 'react'
import CardBuyerInformation from 'components/ui/card/card-buyer-information'
import CardUnitSummary from 'components/ui/card/card-unit-summary'
import CardDetailPayment from 'features/transaction/components/card-detail-payment'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const unit_name = '2019 Toyota Kijang Innova 2.4G'
const status = 'success'
const price = '300.000.000'
const deposit = '55.000.000'
const total = '3.050.000'
const paymentData = [
  { label: 'Pembayaran', value: 'Cicilan', addOn: '', highlight: false },
  { label: 'Leasing Partner', value: 'Doku', addOn: '', highlight: false },
  { label: 'Penawaran Cicilan', value: '12', addOn: ' Bulan', highlight: false },
  { label: 'Cicilan', value: 'Rp. 55.000.000', addOn: ' / Bulan', highlight: true }
]
const informasiPembeli = [
  { label: 'Nama', value: 'Jude Bellingham' },
  { label: 'Email', value: 'birdmobil@ail.com' },
  { label: 'Telepon', value: '083944814722' },
  { label: 'Alamat', value: 'Jln. Bunga Melati No.14, Jakarta' }
]
const informasiUnitData = [
  { label: 'Merek', value: 'Toyota' },
  { label: 'Model', value: 'CRV' },
  { label: 'Tipe', value: 'Innova' },
  { label: 'Transmisi', value: 'Automatic' },
  { label: 'Tahun', value: '2019' },
  { label: 'Plat Nomor', value: 'AB 0000 BB' },
  { label: 'Warna', value: 'Silver' },
  { label: 'Bahan Bakar', value: 'Bensin' },
  { label: 'Nomor Rangka', value: 'MMDJSDHFII009233312' },
  { label: 'Nomor Mesin', value: 'BBMM2231' },
  { label: 'Tag', value: 'SUV, Mobil Keluarga' }
]
const dokumenData = [
  { label: 'STNK', value: true },
  { label: 'BPKB', value: false },
  { label: 'Faktur', value: true },
  { label: 'KTP Pemilik', value: true },
  { label: 'Kwitansi', value: false },
  { label: 'KEUR', value: true },
  { label: 'Form A', value: null }
]

const dataUnit = [
  {
    title: 'image',
    data: [SampleCar, SampleCar, SampleCar, SampleCar, SampleCar]
  },
  {
    title: 'note',
    data: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus ipsa consectetur soluta modi delectus cupiditate eligendi facere aliquam reprehenderit quas!'
  },
  {
    title: 'inspection',
    data: [
      { title: 'Eksterior', value: '40', total: '50' },
      { title: 'Interior', value: '90', total: '90' },
      { title: 'Tes Jalan', value: '10', total: '10' },
      { title: 'Bagian Kolong', value: '32', total: '32' }
    ]
  },
  {
    title: 'history',
    data: [
      {
        label: 'Servis Umum',
        type: 'service',
        date: '10 Feb 2021',
        totalItem: 2,
        price: '500.000',
        status: 'done'
      },
      { label: 'Inspeksi', type: 'inspection', date: '10 Feb 2021', result: 'B', status: 'done' },
      {
        label: 'Servis Umum',
        type: 'service',
        date: '10 Feb 2021',
        totalItem: 2,
        price: '500.000',
        status: 'done'
      }
    ]
  }
]

function DetailSellingPage() {
  const { location } = useHistory()
  const [dataUnit, setDataUnit] = useState(null)
  const id = location.state.id
  return (
    <Row className="gap-6">
      {
        dataUnit !== null && (<>
          {/* Header Breadcrumb */}
          <Col span={24} className="flex gap-1 items-center text-xl-medium text-gray-900 py-2">
            Transaksi / <span className="text-primary-500">Detail Transaksi</span> /{' '}
            <span className="text-primary-500">Detail Jual</span>
          </Col>
          {/* Content */}
          <Col span={24} className="grid grid-cols-1 gap-6">
            <CardUnitSummary unit_name={unit_name} status={status} price={price} />
            <CardBuyerInformation headerTitle={'Informasi Penjual'} data={informasiPembeli} />
            <div className="grid grid-cols-2 gap-6 bg-white border">
              <CardInformation data={informasiUnitData} title="Informasi Unit" />
              <CardInformation data={dokumenData} title="Dokumen" />
            </div>
            {dataUnit.map((section, key) => {
              if (section.title === 'image') return <CardImage key={key} data={section.data} />
              else if (section.title === 'inspection')
                return <CardResultInspection key={key} data={section.data} />
              else if (section.title === 'note') return <CardNote key={key} data={section.data} />
              else if (section.title === 'history') return <CardHistory key={key} data={section.data} />
            })}
            <CardDetailPayment data={paymentData} deposit={deposit} total={total} />
          </Col>
        </>)
      }
    </Row>
  )
}

export default DetailSellingPage
