import { Col, Row } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import FormImage from 'components/ui/form/form-image'
import FormInput from 'components/ui/form/form-input'
import FormRadio from 'components/ui/form/form-radio'
import FormSelect from 'components/ui/form/form-select'
import FormTextArea from 'components/ui/form/form-textarea'
import React from 'react'
import { NavLink } from 'react-router-dom'

const inputs = [
  {
    id: 1,
    headerTitle: 'Informasi Unit',
    inputs: [
      {
        id: 1,
        label: 'Merek',
        name: 'merek',
        type: 'select',
        options: [
          {
            value: 'jack',
            label: 'Jack'
          },
          {
            value: 'lucy',
            label: 'Lucy'
          }
        ],
        placeholder: 'Pilih Merek'
      },
      {
        id: 2,
        label: 'Model',
        name: 'model',
        type: 'select',
        options: [],
        placeholder: 'Pilih Model'
      },
      {
        id: 3,
        label: 'Tipe',
        name: 'tipe',
        type: 'select',
        options: [],
        placeholder: 'Pilih Tipe'
      },
      {
        id: 4,
        label: 'Transmisi',
        name: 'transmisi',
        type: 'select',
        options: [],
        placeholder: 'Pilih Transmisi'
      },
      {
        id: 5,
        label: 'Tahun Keluaran',
        name: 'tahun keluaran',
        type: 'select',
        options: [],
        placeholder: 'Pilih Tahun Keluaran'
      },
      {
        id: 6,
        label: 'Bahan Bakar',
        name: 'bahan bakar',
        type: 'select',
        options: [],
        placeholder: 'Pilih Bahan Bakar'
      },
      {
        id: 99,
        label: 'Plat Nomor',
        name: 'plat_nomor',
        type: 'text',
        placeholder: 'Masukan Plat Nomor'
      },
      {
        id: 7,
        label: 'Nomor Rangka',
        name: 'nomor rangka',
        type: 'text',
        placeholder: 'Masukan Nomor Rangka'
      },
      {
        id: 8,
        label: 'Nomor Mesin',
        name: 'nomor mesin',
        type: 'text',
        placeholder: 'Masukan Nomor Mesin'
      },
      { id: 9, label: 'Kategori', name: 'kategori', type: 'text', placeholder: 'Masukan Kategori' }
    ]
  },
  {
    id: 2,
    headerTitle: 'Foto Unit',
    inputs: [
      {
        id: 1,
        label: 'Foto Thumbnail',
        name: 'foto',
        type: 'file',
        placeholder:
          'Ukuran gambar 16:9, ukuran file maksimal 10mb, dengan format file jpg, jpeg, png.'
      },
      {
        id: 2,
        label: 'Foto Detail Unit',
        name: 'foto',
        type: 'file',
        placeholder: 'Format file jpg, jpeg, png.'
      }
    ]
  },
  {
    id: 3,
    headerTitle: 'Dokumen Unit',
    inputs: [
      {
        id: 1,
        label: 'STNK',
        name: 'STNK',
        type: 'radio',
        options: [
          { id: 1, type: 'radio', name: 'radioSTNK', value: 'ada', label: 'ADA' },
          { id: 2, type: 'radio', name: 'radioSTNK', value: 'tidak ada', label: 'TIDAK ADA' },
          { id: 3, type: 'radio', name: 'radioSTNK', value: 'n/a', label: 'N/A' }
        ]
      },
      {
        id: 2,
        label: 'BPKB',
        name: 'BPKB',
        type: 'radio',
        options: [
          { id: 1, type: 'radio', name: 'radioBPKB', value: 'ada', label: 'ADA' },
          { id: 2, type: 'radio', name: 'radioBPKB', value: 'tidak ada', label: 'TIDAK ADA' },
          { id: 3, type: 'radio', name: 'radioBPKB', value: 'n/a', label: 'N/A' }
        ]
      },
      {
        id: 3,
        label: 'FAKTUR',
        name: 'FAKTUR',
        type: 'radio',
        options: [
          { id: 1, type: 'radio', name: 'radioFAKTUR', value: 'ada', label: 'ADA' },
          { id: 2, type: 'radio', name: 'radioFAKTUR', value: 'tidak ada', label: 'TIDAK ADA' },
          { id: 3, type: 'radio', name: 'radioFAKTUR', value: 'n/a', label: 'N/A' }
        ]
      },
      {
        id: 4,
        label: 'KTP Pemilik',
        name: 'KTP',
        type: 'radio',
        options: [
          { id: 1, type: 'radio', name: 'radioKTP', value: 'ada', label: 'ADA' },
          { id: 2, type: 'radio', name: 'radioKTP', value: 'tidak ada', label: 'TIDAK ADA' },
          { id: 3, type: 'radio', name: 'radioKTP', value: 'n/a', label: 'N/A' }
        ]
      },
      {
        id: 5,
        label: 'Kwitansi',
        name: 'Kwitansi',
        type: 'radio',
        options: [
          { id: 1, type: 'radio', name: 'radioKwitansi', value: 'ada', label: 'ADA' },
          { id: 2, type: 'radio', name: 'radioKwitansi', value: 'tidak ada', label: 'TIDAK ADA' },
          { id: 3, type: 'radio', name: 'radioKwitansi', value: 'n/a', label: 'N/A' }
        ]
      },
      {
        id: 6,
        label: 'KEUR',
        name: 'KEUR',
        type: 'radio',
        options: [
          { id: 1, type: 'radio', name: 'radioKEUR', value: 'ada', label: 'ADA' },
          { id: 2, type: 'radio', name: 'radioKEUR', value: 'tidak ada', label: 'TIDAK ADA' },
          { id: 3, type: 'radio', name: 'radioKEUR', value: 'n/a', label: 'N/A' }
        ]
      },
      {
        id: 7,
        label: 'Form A',
        name: 'FormA',
        type: 'radio',
        options: [
          { id: 1, type: 'radio', name: 'radioFormA', value: 'ada', label: 'ADA' },
          { id: 2, type: 'radio', name: 'radioFormA', value: 'tidak ada', label: 'TIDAK ADA' },
          { id: 3, type: 'radio', name: 'radioFormA', value: 'n/a', label: 'N/A' }
        ]
      }
    ]
  },
  {
    id: 4,
    headerTitle: 'Catatan',
    inputs: [
      {
        id: 1,
        rows: 4,
        maxLength: 6,
        style: { height: 120, resize: 'none' },
        name: 'catatan',
        label: '',
        type: 'textarea',
        placeholder: 'Masukan Catatan'
      }
    ]
  }
]

function FormAddUnitPage() {
  return (
    <Row className="gap-6 my-10">
      {/* Header */}
      <Col span={22} className="flex mx-auto justify-center gap-1 items-center display-sm-semibold text-gray-900 py-2">
        Tambah Mobil
      </Col>
      {/* Content Container */}
      <Col span={12} className="grid grid-cols-1 gap-6 mx-auto">
        {/* Form Information unit */}
        {inputs.map((inputSection) => (
          <CardSectionHeader key={inputSection.id} headerTitle={inputSection.headerTitle}>
            <div className="grid grid-cols-1 gap-5">
              {inputSection.inputs.map((inputItem) => {
                if (inputItem.type === 'text')
                  return <FormInput key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'select')
                  return <FormSelect key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'radio')
                  return <FormRadio key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'file')
                  return <FormImage key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'textarea')
                  return <FormTextArea key={inputItem.id} data={inputItem} />
              })}
            </div>
          </CardSectionHeader>
        ))}

        <Col span={24} className="flex justify-end items-center gap-2">
          <NavLink to={'/dashboard'}>
            <CustomButton type="plain" label={'Batal'} />
          </NavLink>
          <NavLink to={'/dashboard'}>
            <CustomButton label={'Simpan'} />
          </NavLink>
        </Col>
      </Col>
    </Row>
  )
}

export default FormAddUnitPage
