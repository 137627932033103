import { Col, Row } from 'antd'
import { SampleCar } from 'assets'
import CardImage from 'components/ui/card/card-image'
import CardInspectionDetail from 'components/ui/card/card-inspection-detail'
import CardInspectionDetailList from 'components/ui/card/card-inspection-detail-list'
import CardNote from 'components/ui/card/card-note'
import React from 'react'

const dataImage = [SampleCar, SampleCar, SampleCar, SampleCar, SampleCar]
const note =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus ipsa consectetur soluta modi delectus cupiditate eligendi facere aliquam reprehenderit quas!'
const dataInspection = [
  {
    label: 'Kondisi Unit',
    type: 'one',
    value: [
      { label: 'Bebas Tabrak', value: 'success' },
      { label: 'Bebas Banjir', value: 'success' }
    ],
    child: []
  },
  {
    label: 'Interior',
    type: 'two',
    value: [],
    child: [
      {
        title: 'Kelistrikan',
        value: [
          {
            image: SampleCar,
            label: 'Pintu Depan',
            status: 'success',
            details: [
              {
                label: 'Ukuran dan Merek',
                value: 'Continetal'
              },
              {
                label: 'Tanggal Produksi',
                value: '2020'
              }
            ]
          },
          {
            image: '',
            label: 'Pintu Belakang',
            status: 'success',
            details: [
              {
                label: '',
                value: ''
              }
            ]
          }
        ]
      },
      {
        title: 'Chasis & Drive Train Interior',
        value: []
      },
      {
        title: 'Body Interior',
        value: []
      },
      {
        title: 'Kelistrikan Are Seat, Trim & Panel Airbag',
        value: []
      }
    ]
  },
  {
    label: 'Eksterior',
    type: 'two',
    value: [],
    child: [
      {
        title: 'Kelistrikan Area Lampu-Lampu Depan',
        value: []
      },
      {
        title: 'Body Area Eksterior, Panel, & Frame',
        value: []
      }
    ]
  },
  {
    label: 'Kondisi Ban',
    type: 'two',
    value: [],
    child: [
      {
        title: 'Ban Depan',
        value: []
      },
      {
        title: 'Ban Belakang',
        value: []
      },
      {
        title: 'Ban Cadangan',
        value: []
      }
    ]
  },
  {
    label: 'Bagasi & Tools',
    type: 'two',
    value: [],
    child: [
      {
        title: 'Body Area Bagasi & Tools',
        value: []
      },
      {
        title: 'Chasis & Drive Train Area Bagasi & Tools',
        value: []
      }
    ]
  },
  {
    label: 'Scan Diagnostic',
    type: 'two',
    value: [],
    child: [
      {
        title: 'Body Area Bagasi & Tools',
        value: []
      },
      {
        title: 'Chasis & Drive Train Area Bagasi & Tools',
        value: []
      }
    ]
  },
  {
    label: 'Ruang Mesin',
    type: 'two',
    value: [],
    child: [
      {
        title: 'Kelistrikan Area Ruang Mesin',
        value: []
      },
      {
        title: 'Engine Area Ruang Mesin',
        value: []
      }
    ]
  }
]

function RedunantDetailInspectionPage() {
  return (
    <Row className="gap-6">
      {/* Header Breadcrumb */}
      <Col span={24} className="flex gap-1 items-center text-xl-medium text-gray-900 py-2">
        Dashboad / <span className="text-primary-500">Detail Transaksi</span> /{' '}
        <span className="text-primary-500">Detail Inspeksi</span>
      </Col>
      {/* Content */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {dataInspection.map((item, index) =>
          item.type === 'one' ? (
            <CardInspectionDetail title={item.label} data={item.value} key={index} />
          ) : (
            <CardInspectionDetailList title={item.label} data={item.child} key={index} />
          )
        )}
        <CardNote data={note} />
        <CardImage data={dataImage} />
      </Col>
    </Row>
  )
}

export default RedunantDetailInspectionPage
