import { Col, Divider, Row } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import Countdown from 'react-countdown'
import { ClockIcon, CopyIcon } from 'assets'
import { onRupiah } from 'utils/formatCurrency'
import CardSectionHeader from 'components/ui/card-section-header'
import QRCode from 'react-qr-code'

import CustomButton from 'components/ui/custom-button'
import { useMutation } from 'react-query'
import ConfirmationModal from 'components/ui/confirmation-modal'
import { useState } from 'react'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
import { cancelPayment } from 'features/transaction/service'

const PaymentPage = () => {
  const { location, replace } = useHistory()
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const state = location?.state
  console.log('state', state)

  const cancelCurrentPayment = useMutation({
    mutationKey: 'Cancel Payment',
    mutationFn: () => cancelPayment(state?.transactionId),
    onSuccess: () => {
      showSuccessMessage('Pembatalan Pembayaran Anda telah berhasil dibatalkan')
      setOpenCancelModal(false)
      replace('/transaction/transaction-detail', {
        type: state?.serviceType,
        id: state?.serviceType === 'beli' ? state?.transactionId : state?.id
      })
    },
    onError: () => {
      showErrorMessage('Terjadi Kesalahan, Silahkan coba lagi atau hubungi admin BirdMobil')
      setOpenCancelModal(false)
    }
  })

  function onOpenModal() {
    setOpenCancelModal(!openCancelModal)
  }

  const paymentMethodMap = {
    qr_code: <QRrender state={state} />,
    virtual_account: <VArender state={state} />,
    card: <CCRender state={state} />
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <p className="text-primary-500">Pembayaran sudah kedaluwarsa</p>
    } else {
      // Render a countdown
      return (
        <p className="text-warning-500">
          {hours}:{minutes}:{seconds}
        </p>
      )
    }
  }

  return (
    <section className="py-20 flex flex-col justify-center items-center">
      <div className="grid grid-cols-1 gap-2 w-full pb-5">
        {state?.paymentDetails?.payment_method === 'card' && state?.selectedCreditCard ? (
          <div className="flex justify-center items-center text-lg-medium gap-3">
            <ClockIcon className={'stroke-gray-500'} />
            <p className="text-gray-900">Menunggu Validasi Pembayaran</p>
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-center items-center text-lg-medium">
              <p className="text-gray-900">Selesaikan Pembayaran Dalam</p>
              <Countdown
                date={
                  new Date(
                    state?.paymentDetails?.payment_details[
                      state?.paymentDetails?.payment_method
                    ]?.expired_at
                  )
                }
                renderer={renderer}
              />
            </div>
            <div className="flex flex-col justify-center items-center">
              <p className="text-sm-regular text-gray-500">Batas Akhir Pembayaran</p>
              {/* <p className="text-md-medium text-gray-900">Sabtu, 3 Desember 2022</p> */}
              <p className="text-md-medium text-gray-900">
                {moment(
                  new Date(
                    state?.paymentDetails?.payment_details[
                      state?.paymentDetails?.payment_method
                    ]?.expired_at
                  )
                ).format('ddd, DD MMM YYYY')}
              </p>
            </div>
          </>
        )}
      </div>
      <Col span={22} lg={{ span: 14 }} className="mx-auto w-full">
        <CardSectionHeader>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex p-3 justify-between items-center bg-gray-50 rounded-[4px]">
              {/* Title Payment Method */}
              <p className="text-md-medium text-gray-700">{state?.selectedPayment?.title}</p>
              {/* Image Payment Method */}
              <img
                src={state?.selectedPayment?.image}
                className={`${
                  state?.paymentDetails?.payment_method === 'card' ? '' : 'w-14 object-contain'
                }`}
                alt="bankImage"
              />
            </div>
            {paymentMethodMap[state?.paymentDetails?.payment_method]
              ? paymentMethodMap[state?.paymentDetails?.payment_method]
              : null}
            <div className="grid grid-cols-1 gap-4">
              <p className="text-md-medium text-gray-900">Rincian Pembayaran</p>
              {/* List Rincian Pembayaran */}
              {state?.listDetailPayment ? (
                state?.listDetailPayment?.map((item, index) => (
                  <div className=" flex justify-between items-center gap-4" key={index}>
                    <p className="text-md-regular text-gray-500">{item.label}</p>
                    <p
                      className={
                        item.highlight
                          ? 'text-primary-400 text-md-medium whitespace-nowrap'
                          : 'text-md-medium text-gray-700 whitespace-nowrap'
                      }>{`${item.addOn} ${item.value === undefined ? '-' : item.value}`}</p>
                  </div>
                ))
              ) : (
                <>
                  <div className=" flex justify-between items-center gap-4">
                    <p className="text-md-regular text-gray-500">
                      {state?.price?.inspection ? 'Biaya Inspeksi' : 'Booking Mobil'}
                    </p>
                    <p className="text-md-medium text-gray-700">
                      Rp{' '}
                      {state?.price?.inspection
                        ? onRupiah(state?.price?.inspection)
                        : onRupiah(state?.price?.bookingPrice)}
                    </p>
                  </div>
                  {!state?.isShowRoom && state?.range_price !== undefined && (
                    <div className="flex justify-between items-center">
                      <p className="text-md-regular text-gray-500">Biaya Jarak</p>
                      <p className="text-md-medium text-gray-700">
                        Rp. {state?.range_price !== undefined ? onRupiah(state?.range_price) : '-'}
                      </p>
                    </div>
                  )}
                  {/* <div className=" flex justify-between items-center gap-4">
                    <p className="text-md-regular text-gray-500">Biaya Admin</p>
                    <p className="text-md-medium text-gray-700">
                      Rp. {parseInt(state?.price?.admin_fee).toLocaleString('ID-id')}
                    </p>
                  </div> */}
                  {state?.price?.tax ? (
                    <div className=" flex justify-between items-center gap-4">
                      <p className="text-md-regular text-gray-500">Pajak {state?.price?.tax}%</p>
                      <p className="text-md-medium text-gray-700">
                        Rp. {parseInt(state?.taxPrice).toLocaleString('ID-id')}
                      </p>
                    </div>
                  ) : null}
                  {/* Total Tagihan */}
                </>
              )}
              <Divider className="m-0 text-gray-300" />
              <div className="p-4 flex justify-between items-center bg-gray-50 rounded-[4px]">
                <p className="text-md-regular text-gray-600">Total Tagihan</p>
                <p className="text-md-medium text-gray-700">Rp {onRupiah(state?.totalPrice)}</p>
              </div>
            </div>
          </div>
        </CardSectionHeader>
        <div className="flex flex-col justify-center items-center py-5 gap-5">
          <CustomButton type="plain" label={'Batalkan Pembayaran'} onClick={onOpenModal} />
          {openCancelModal && (
            <ConfirmationModal
              headerTitle={'Konfirmasi Batal Pembayaran'}
              onCancel={() => {
                setOpenCancelModal(false)
              }}
              onClick={() => {
                cancelCurrentPayment.mutate()
              }}
              openModal={openCancelModal}
              contentDescription={'Apakah Anda yakin untuk membatalkan pembayaran?'}
              confirmLabel={'Konfirmasi Pembatalan'}
            />
          )}
          <Link to="/" className="hover:text-primary-900 text-md-medium text-primary-600 ">
            Kembali ke Halaman Utama
          </Link>
        </div>
      </Col>
    </section>
  )
}

function VArender({ state }) {
  const copyToClipboard = (number) => {
    navigator.clipboard.writeText(number)
  }
  return (
    <>
      <div className="flex p-3 justify-between bg-gray-50 rounded-[4px]">
        <div className="flex flex-col gap-1 text-gray-700">
          <p className="text-xs-regular ">Nomor Virtual Account</p>
          <p className="text-md-semibold ">
            {state?.paymentDetails?.payment_details?.virtual_account?.va_number}
          </p>
        </div>
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() =>
            copyToClipboard(state?.paymentDetails?.payment_details?.virtual_account?.va_number)
          }>
          <p className="text-md-medium text-primary-600">Salin</p>
          <CopyIcon />
        </div>
      </div>
    </>
  )
}
function QRrender({ state }) {
  return (
    <div className="flex p-3 justify-center rounded-[4px]">
      <div className="flex flex-col gap-1 text-gray-700">
        <QRCode value={state?.paymentDetails?.payment_details?.qr_code?.qr_string} />
      </div>
    </div>
  )
}
function CCRender({ state }) {
  const objCC = state?.selectedCreditCard
  return (
    <div className="grid grid-cols-1 gap-2">
      <div className="grid grid-cols-[140px_1fr] gap-10">
        <span className="text-md-regular text-gray-500">Tipe</span>
        <span className="text-md-medium text-gray-900">{objCC?.network}</span>
      </div>
      <div className="grid grid-cols-[140px_1fr] gap-10">
        <span className="text-md-regular text-gray-500">Nomor Kartu</span>
        <span className="text-md-medium text-gray-900">{objCC?.masked_number}</span>
      </div>
      <div className="grid grid-cols-[140px_1fr] gap-10">
        <span className="text-md-regular text-gray-500">Card Holder</span>
        <span className="text-md-medium text-gray-900">{objCC?.cardholder_name}</span>
      </div>
    </div>
  )
}

export default PaymentPage
