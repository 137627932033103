import { birdmobilLogo } from "features/auth/assets";
import FormInput from "./form-input";
import FormSuccess from "./form-success";

function FormInputPassword({
    data,
    error,
    handleChange,
    isPassword,
    handleSubmitPassword,
    handleBack
}) {
    return (
        <div className='flex flex-col gap-6'>
            <div className='flex flex-col items-center space-y-4'>
                <img src={birdmobilLogo} alt="" />
                <p className="text-gray-900 text-sm-semibold text-3xl text-center">Ganti Password</p>
            </div>
            {
                isPassword ?
                    <FormInput
                        data={data}
                        error={error}
                        handleChange={handleChange}
                        handleSubmit={handleSubmitPassword}
                        handleBack={handleBack}
                    /> : <FormSuccess />
            }
        </div>
    )
}

export default FormInputPassword;