import { Col, Row } from 'antd'
import React from 'react'
import { layananData } from '../data/layanan-data'
import CardLandingGradient from 'components/ui/card/card-landing-gradient'
import SectionTitle from 'components/ui/section-title'

const LayananKamiGeneral = () => {
  return (
    <Row className="bg-white py-20 w-full">
      <Col span={22} lg={{ span: 20 }} className="mx-auto">
        <div className="flex flex-col gap-12">
          {/* Title */}
          <SectionTitle
            title={'Layanan Kami'}
            description={
              'BirdMobil memberikan pelayanan terpercaya untuk mempermudah Anda mendapatkan mobil terbaik'
            }
          />
          {/* List */}
          <div className="lg:flex grid grid-cols-1 gap-7 lg:flex-row justify-between">
            {layananData.map((data, key) => (
              <CardLandingGradient
                key={key}
                gradient={true}
                parentContainerClassName={'max-w-[300px] flex-1'}>
                <article key={key} className="grid grid-cols-1 gap-4">
                  <div className="relative flex items-end h-[70px] object-contain">{data.icon}</div>
                  <h6 className="text-md-semibold md:text-lg-semibold text-gray-900">
                    {data.label}
                  </h6>
                  <p>{data.description}</p>
                </article>
              </CardLandingGradient>
            ))}
          </div>
          {/* Badge Item */}
        </div>
      </Col>
    </Row>
  )
}

export default LayananKamiGeneral
