import { Col, Divider, Dropdown } from 'antd'
import React from 'react'
import { ChevronUpIcon, WhatsappIcon, HeartIcon, XCircleIcon } from 'assets'
import CustomButton from 'components/ui/custom-button'
import { onRupiah } from 'utils/formatCurrency'
function SummaryCar({
  handleWishlist,
  isBookmark,
  handleModal,
  dataUnit,
  isTaxi,
  selectedSpareparts,
  deleteSelectedSpareparts,
  selectedColor
}) {
  const isCustomer = localStorage.getItem('account_type')
  const user = JSON.parse(localStorage.getItem('user'))
  let totalSelectedSpareparts = 0 + dataUnit?.price
  Object.keys(selectedSpareparts).length > 0
    ? Object.keys(selectedSpareparts).map(
        (name) => (totalSelectedSpareparts += selectedSpareparts[name].price)
      )
    : null
  return (
    <Col
      span={24}
      className="w-full md:h-24 flex justify-center items-center py-4 md:py-7 bg-white z-50 fixed bottom-0">
      <Col span={24} className="w-full flex px-2 md:px-0 justify-end md:justify-around">
        {/* Title & detail spec */}
        <div className="hidden lg:flex flex-col gap-1">
          <p className="text-xl-semibold text-gray-900">
            {dataUnit?.name || dataUnit?.unit_name || '-'}
          </p>
          {!isTaxi && (
            <div className="flex items-center gap-2 text-xs-regular text-gray-500">
              <p>{dataUnit?.mileage !== undefined && onRupiah(dataUnit.mileage)} km</p>
              <Divider type="vertical" className="m-0 p-0" />
              <p>{dataUnit?.transmission_name || '-'}</p>
              <Divider type="vertical" className="m-0 p-0" />
              <p>{dataUnit?.branch_name || '-'}</p>
              <Divider type="vertical" className="m-0 p-0" />
            </div>
          )}
        </div>
        {/* Total Price */}
        <div className="flex gap-4 items-center">
          {/* Detail Price Dropdown */}
          <Dropdown
            dropdownRender={() =>
              DropdownRender(
                selectedSpareparts,
                deleteSelectedSpareparts,
                totalSelectedSpareparts,
                dataUnit?.price
              )
            }
            className="hidden md:flex relative"
            trigger="click"
            placement="topRight">
            <a onClick={(e) => e.preventDefault()} className="flex items-center gap-2">
              <div className="flex gap-1 items-center">
                <p className="text-md-regular text-gray-500">Total :</p>
                <p className="text-xl-semibold text-warning-500">
                  Rp{' '}
                  {!isNaN(totalSelectedSpareparts)
                    ? totalSelectedSpareparts?.toLocaleString('ID-id')
                    : '0'}
                </p>
              </div>
              <ChevronUpIcon className={'stroke-gray-400'} />
            </a>
          </Dropdown>
          {/* Button */}
          <div className="flex gap-2">
            <CustomButton
              onClick={handleModal}
              label={'Pesan Sekarang'}
              disabled={
                isTaxi
                  ? !selectedColor
                  : user?.id !== undefined
                  ? dataUnit?.owner_id === user?.id
                  : false
              }
            />
            {/* <CustomButton
              type="secondary"
              label={
                <div className="flex gap-2 items-center">
                  <WhatsappIcon className={'fill-primary-600 w-5 h-5 xl:w-5 xl:h-[18px]'} />{' '}
                  <span className=" hidden md:block">Hubungi</span>
                </div>
              }
            /> */}
            {!isTaxi && isCustomer === 'customer' && (
              <CustomButton
                onClick={() => handleWishlist(isBookmark)}
                type="plain"
                label={
                  <div className="flex justify-center items-center">
                    {
                      <HeartIcon
                        className={`${isBookmark ? 'stroke-rose-500 fill-rose-500' : null}`}
                      />
                    }
                  </div>
                }
              />
            )}
          </div>
        </div>
      </Col>
    </Col>
  )
}
const DropdownRender = (
  selectedSpareparts,
  deleteSelectedSpareparts,
  totalSelectedSpareparts,
  unitPrice
) => {
  return (
    <div className="bg-white p-4 bottom-5 right-0 absolute rounded-[4px] border border-solid shadow-sm w-[250px]">
      <div className="flex flex-col gap-4 text-xs-regular text-gray-500">
        {/* Header Title */}
        <p className="text-md-medium text-gray-900">Rincian Biaya</p>

        {/* Detail Customize */}
        {Object.keys(selectedSpareparts).length > 0 ? (
          <div className="flex flex-col gap-1">
            {Object.keys(selectedSpareparts).map((name, index) => (
              <div key={index}>
                {/* Header Type */}
                <div className="flex justify-between">
                  <p className="text-gray-700 text-sm-medium">{name}</p>
                  <div className="cursor-pointer" onClick={() => deleteSelectedSpareparts(name)}>
                    <XCircleIcon className={'stroke-gray-400'} />
                  </div>
                </div>

                {/* Detail Type */}
                <div className="flex justify-between">
                  <p>{selectedSpareparts[name]?.name}</p>
                  <p>Rp {onRupiah(selectedSpareparts[name]?.price)}</p>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {/* Price Car */}
        <div className="flex justify-between">
          <p>Harga Mobil</p>
          <p>Rp {onRupiah(unitPrice)}</p>
        </div>

        <Divider className="m-0 border-gray-200 border" />
        {/* Total */}
        <div className="flex text-xs-semibold  justify-between">
          <p>Total</p>
          <p>Rp {onRupiah(totalSelectedSpareparts)}</p>
        </div>
      </div>
    </div>
  )
}
export default SummaryCar
