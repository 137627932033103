import React from 'react'
import PartItem from './part-item'

const PartList = ({ selected, handleSelected, spareparts }) => {
  return (
    <ol className="max-h-80 bg-gray-50 p-1 md:p-3 rounded-[4px] flex flex-col gap-3 overflow-hidden overflow-y-scroll">
      {spareparts.map((sparepart, index) => (
        <PartItem
          sparepart={sparepart}
          key={index}
          handleSelected={() => handleSelected(sparepart)}
          selected={sparepart?.id === selected}
        />
      ))}
    </ol>
  )
}

export default PartList
