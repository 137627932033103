/* eslint-disable no-undef */
import API from 'services'

const getCatalogLocation = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/catalogs/locations`
  )
  return response.data
}
const getAllFuels = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/fuels/all`
  )
  return response.data
}
const getAllTransmision = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/transmissions/all`
  )
  return response.data
}
const getAllCategories = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/categories/all`
  )
  return response.data
}
const getAllModels = async (brandId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/models/all?brand_id=${brandId}`
  )
  return response.data
}
const getAllColors = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/colors/all`
  )
  return response.data
}
const getAllUnitClasses = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/classes/all`
  )
  return response.data
}
const getAllBrands = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/brands/all`
  )
  return response.data
}
const getAllTypes = async (modelId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/types/all?model_id=${modelId}`
  )
  return response.data
}
const getCategoriesByTypes = async (typeId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/types/${typeId}`
  )
  return response.data
}
const getCYOC = async (catalogId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/create-your-own-car/spareparts/catalog/${catalogId}`
  )
  return response.data
}
const getRelatedCatalog = async (catalogId, limit) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/catalogs/related-catalogs/${catalogId}?limit=${limit}`
  )
  return response.data
}
const getCatalog = async ({
  province,
  city,
  location,
  merek,
  model,
  transmisi,
  bahanBakar,
  warna,
  minHarga,
  maxHarga,
  minTahun,
  maxTahun,
  minTempuh,
  maxTempuh,
  keywords,
  limit,
  tipe,
  offset
}) => {
  let urlParam = `limit=${limit}&offset=${offset}`
  if (location) {
    urlParam += `&workshop_id=${location?.value}`
  }
  if (province) {
    urlParam += `&province_id=${province?.value}`
  }
  if (city) {
    urlParam += `&city_id=${city?.value}`
  }
  if (merek) {
    urlParam += `&brand_id=${merek?.value}`
  }
  if (model) {
    urlParam += `&model_id=${model?.value}`
  }
  if (transmisi) {
    urlParam += `&transmission_id=${transmisi?.value}`
  }
  if (bahanBakar) {
    urlParam += `&fuel_id=${bahanBakar?.value}`
  }
  if (warna) {
    urlParam += `&color_id=${warna?.value}`
  }
  if (tipe) {
    urlParam += `&type_id=${tipe?.value}`
  }
  if (minHarga) {
    urlParam += `&min_price=${parseInt(minHarga)}`
  }
  if (maxHarga) {
    urlParam += `&max_price=${parseInt(maxHarga)}`
  }
  if (minTahun) {
    urlParam += `&min_production_year=${minTahun}`
  }
  if (maxTahun) {
    urlParam += `&max_production_year=${maxTahun}`
  }
  if (minTempuh) {
    urlParam += `&min_mileage=${parseInt(minTempuh)}`
  }
  if (maxTempuh) {
    urlParam += `&max_mileage=${parseInt(maxTempuh)}`
  }
  if (keywords) {
    urlParam += `&keywords=${keywords}`
  }

  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/catalogs?${urlParam}`
  )
  return response
}
// const getCatalogV2 = async ({
//   province,
//   city,
//   workshop,
//   merek,
//   model,
//   transmisi,
//   bahanBakar,
//   warna,
//   minHarga,
//   maxHarga,
//   minTahun,
//   maxTahun,
//   minTempuh,
//   maxTempuh,
//   keywords,
//   limit,
//   tipe,
//   offset
// }) => {
//   let urlParam = `limit=${limit}&offset=${offset}`
//   if (merek) {
//     urlParam += `&brand_id=${merek?.value}`
//   }
//   if (model) {
//     urlParam += `&model_id=${model?.value}`
//   }
//   if (transmisi) {
//     urlParam += `&transmission_id=${transmisi?.value}`
//   }
//   if (bahanBakar) {
//     urlParam += `&fuel_id=${bahanBakar?.value}`
//   }
//   if (warna) {
//     urlParam += `&color_id=${warna?.value}`
//   }
//   if (tipe) {
//     urlParam += `&type_id=${tipe?.value}`
//   }
//   if (minHarga) {
//     urlParam += `&min_price=${parseInt(minHarga)}`
//   }
//   if (maxHarga) {
//     urlParam += `&max_price=${parseInt(maxHarga)}`
//   }
//   if (minTahun) {
//     urlParam += `&min_production_year=${minTahun}`
//   }
//   if (maxTahun) {
//     urlParam += `&max_production_year=${maxTahun}`
//   }
//   if (minTempuh) {
//     urlParam += `&min_mileage=${parseInt(minTempuh)}`
//   }
//   if (maxTempuh) {
//     urlParam += `&max_mileage=${parseInt(maxTempuh)}`
//   }
//   if (keywords) {
//     urlParam += `&keywords=${keywords}`
//   }
//   if (province) {
//     urlParam += `&province_id=${province}`
//   }
//   if (city) {
//     urlParam += `&city_id=${city}`
//   }
//   if (workshop) {
//     urlParam += `&workshop_id=${workshop}`
//   }

//   const { data: response } = await API.get(
//     `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/catalogs?${urlParam}`
//   )
//   return response
// }

const getProvinces = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/provinces/all`);
  return response.data;
}

const getCities = async (provinceId) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/cities/province/${provinceId}`);
  return response.data;
}

const getSubdistricts = async (cityId) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/subdistricts/city/${cityId}`);
  return response.data;
}

const getDetailCatalog = async (id) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/catalogs/${id}`
  )
  return response.data
}
const postBookingCatalog = async ({ id, ...body }) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/catalogs/booking/${id}`,
    body
  )
  return response.data
}

// Ex-Taxi
const getCYOCTaxi = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/create-your-own-car/spareparts/taxi`
  )
  return response.data
}
const postBookingTaxi = async ({ id, ...body }) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/taxis/booking/${id}`,
    body
  )
  return response.data
}

const getCatalogTaxis = async ({ merek, minHarga, maxHarga, keywords, limit, sort, offset }) => {
  let urlParam = `limit=${limit}`
  if (merek !== null) {
    urlParam += `&brand_id=${merek}`
  }
  if (minHarga !== null) {
    urlParam += `&min_price=${parseInt(minHarga)}`
  }
  if (maxHarga !== null) {
    urlParam += `&max_price=${parseInt(maxHarga)}`
  }
  if (keywords !== null) {
    urlParam += `&keywords=${keywords}`
  }
  if (offset > 0) {
    urlParam += `&offset=${offset}`
  }

  if (sort === 'ASC') {
    urlParam += `&sort= price ASC`
  } else if (sort === 'DESC') {
    urlParam += `&sort= price DESC`
  }

  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/taxis?${urlParam}`
  )
  return response
}

const getDetailCatalogTaxi = async (id) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/taxis/${id}`
  )
  return response.data
}
const getBanners = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/contents/banners`
  )
  return response.data
}

const getWorkshops = async (province_id, city_id) => {
  let urlParam = ''
  if (province_id) {
    urlParam += `&province_id=${province_id}`
  }
  if (city_id) {
    urlParam += `&city_id=${city_id}`
  }

  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/workshops/all?keywords=&sort_by=name&order_by=asc${urlParam}`
  )
  return response.data
}
const getAdminPrice = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/configurations/service-prices/3`
  )
  return response.data
}
const getTaxPrice = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/configurations/taxes/3`
  )
  return response.data
}
const getSchedules = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/test-drives/schedules`
  )
  return response.data
}

const postTestDrive = async (args) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/test-drives${
      args.isTaxi ? '/taxis' : ''
    }`,
    args.body,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    }
  )

  return response.data
}
const getBookingPrice = async (id) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/configurations/service-prices/${id}`
  )
  return response.data
}

export {
  getAdminPrice,
  getTaxPrice,
  getBanners,
  getRelatedCatalog,
  getCYOC,
  getCYOCTaxi,
  postBookingTaxi,
  getCategoriesByTypes,
  getCatalogTaxis,
  getDetailCatalogTaxi,
  postBookingCatalog,
  getDetailCatalog,
  getAllBrands,
  getAllCategories,
  getAllColors,
  getAllFuels,
  getAllModels,
  getAllTransmision,
  getAllTypes,
  getAllUnitClasses,
  getCatalog,
  getCatalogLocation,
  getWorkshops,
  getSchedules,
  postTestDrive,
  getBookingPrice,
  getProvinces,
  getCities,
  getSubdistricts
}
