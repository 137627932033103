import CardSectionHeader from 'components/ui/card-section-header'
import CardPacketService from 'features/servis/components/card/card-packet-service'
import { CarouselMultipleLayout } from 'components/ui/CarouselMultipleLayout'
import React, { useEffect, useState } from 'react'
import CardService from '../card/card-service'
import { MagnifyingIcon } from 'assets'
import { Input, Spin } from 'antd'
import { NullComponentServis } from '../ui'
import CustomButton from 'components/ui/custom-button'
import { useRef } from 'react'

const FormLayananServis = ({
  services,
  handleGeneralService,
  handlePacketService,
  changeKeywordHandler,
  keyword,
  handleSelectedCategory,
  selectedCategory,
  serviceCategories,
  handleTampilLebihBanyak,
  servicesIsFetching,
  selectedGeneralServiceIds,
  selectedPacketIds,
  isAddServisBtnDisabled
}) => {
  const refSearchBar = useRef(null)
  const [isTambahBanyak, setIsTambahBanyak] = useState(false)
  useEffect(() => {
    if (!servicesIsFetching && !isTambahBanyak) {
      refSearchBar.current.focus()
      setIsTambahBanyak(false)
    }
  }, [servicesIsFetching, isTambahBanyak])
  return (
    <div className="flex flex-col gap-6">
      {/* Tab Kategori Layanan */}
      <CarouselMultipleLayout
        slideToShow={3}
        slidesToScroll={3}
        arrowClassIcon="stroke-gray-500"
        arrowClassContainer="bg-transparent"
        totalItem={serviceCategories.length}
        className="bg-white rounded-[4px] overflow-hidden px-6">
        {serviceCategories?.map((data) => (
          <div
            onClick={handleSelectedCategory.bind(this, data)}
            key={data.id}
            className={`cursor-pointer text-center flex items-center justify-center p-3 text-sm-medium xl:text-md-medium ${
              selectedCategory?.name === data.name
                ? 'border-b-2 border-primary-500 text-primary-500'
                : 'text-gray-500'
            }`}>
            {data.name}
          </div>
        ))}
      </CarouselMultipleLayout>

      {/* Content Layanan */}
      <CardSectionHeader>
        <div className="grid grid-cols-1 gap-5 ">
          {/* Search bar */}
          <Input
            ref={refSearchBar}
            disabled={servicesIsFetching && services.length === 0}
            id="searchKeywords"
            name="keywords"
            size="medium"
            value={keyword}
            className="text-md-normal text-gray-500 px-[14px] hover:border-gray-200 focus:border-gray-200"
            prefix={<MagnifyingIcon className={'mr-1'} />}
            placeholder="Search"
            onChange={(e) => {
              setIsTambahBanyak(false)
              changeKeywordHandler(e)
            }}
          />
          {servicesIsFetching && services.length === 0 ? (
            <Spin spinning={servicesIsFetching} size="large"></Spin>
          ) : services.length === 0 ? (
            <NullComponentServis />
          ) : (
            <>
              {services.map((data) => {
                let passed = true
                // CHECKING PACKET IDS
                if (data?.type === 'PACKET') {
                  if (!selectedPacketIds[data.id]) {
                    // CHECKING GENERAL_SERVICE IDS
                    for (let i = 0; i < data.general_services.length; i++) {
                      if (selectedGeneralServiceIds[data.general_services[i].id]) {
                        passed = false
                      }
                    }
                  } else {
                    passed = false
                  }
                  if (passed) {
                    return (
                      <CardPacketService
                        handlePacketService={handlePacketService.bind(
                          this,
                          data.id,
                          selectedCategory.id
                        )}
                        key={`${selectedCategory?.name}-PACKET-${data.id}`}
                        data={data}
                      />
                    )
                  }
                } else if (data?.type === 'GENERAL' && selectedGeneralServiceIds[data.id]) {
                  null
                } else {
                  return (
                    <CardService
                      handleGeneralService={handleGeneralService.bind(this, selectedCategory.id)}
                      key={`${selectedCategory?.name}-GENERAL-${data.id}`}
                      data={data}
                    />
                  )
                }
              })}
              {isAddServisBtnDisabled ? null : (
                <Spin spinning={servicesIsFetching}>
                  <div className="grid grid-cols-1 w-full">
                    <CustomButton
                      label={'Tampilkan Lebih Banyak'}
                      onClick={() => {
                        setIsTambahBanyak(true)
                        handleTampilLebihBanyak()
                      }}
                    />
                  </div>
                </Spin>
              )}
            </>
          )}
        </div>
      </CardSectionHeader>
    </div>
  )
}

export default FormLayananServis
