const ImageIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 19H17.5C18.6046 19 19.5 18.1046 19.5 17V3C19.5 1.89543 18.6046 1 17.5 1H3.5C2.39543 1 1.5 1.89543 1.5 3V17C1.5 18.1046 2.39543 19 3.5 19ZM3.5 19L14.5 8L19.5 13M8.5 6.5C8.5 7.32843 7.82843 8 7 8C6.17157 8 5.5 7.32843 5.5 6.5C5.5 5.67157 6.17157 5 7 5C7.82843 5 8.5 5.67157 8.5 6.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ImageIcon
