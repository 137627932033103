import CardSectionHeader from 'components/ui/card-section-header'
import FormImage from 'components/ui/form/form-image'

function CardFotoUnit({ fileList, handleChangeThumbnail }) {
  const inputProps = {
    id: 1,
    label: '',
    name: 'thumbnail',
    type: 'file',
    // onChange: (file) => {
    //   inputHandler({ name: 'thumbnail', value: file, type: 'file' })
    // },
    onChange: (file) => handleChangeThumbnail(file),
    beforeUpload: () => {
      return false
    },
    mandatory: false,
    limit: 1,
    fileList: fileList,
    placeholder: 'Ukuran gambar 16:9, ukuran file maksimal 10mb, dengan format file jpg, jpeg, png.'
  }
  return (
    <CardSectionHeader headerTitle={<span>Foto Unit <span className='text-danger-500'>*</span></span>}>
      <div className="grid grid-cols-1 gap-6">
        <FormImage imageProps={inputProps} />
      </div>
    </CardSectionHeader>
  )
}
export default CardFotoUnit
