import { ModalLayout } from 'layouts'
import React from 'react'
import CustomButton from '../custom-button'
import { TrashIcon } from 'assets'

const ModalDetailCard = ({ openModal, onCancel, card, handleConfirmDeleteCard, width }) => {
  return (
    <ModalLayout
      headerTitle={'Detail Kartu Debit / Kredit'}
      openModal={openModal}
      wrapClassName=""
      footer={null}
      className="w-full max-w-md"
      width={''}
      onCancel={onCancel}>
      <div className="grid grid-cols-1 gap-2">
        <div className="grid grid-cols-[140px_1fr] gap-10">
          <span className="text-md-regular text-gray-500">Tipe</span>
          <span className="text-md-medium text-gray-900">{card?.network}</span>
        </div>
        <div className="grid grid-cols-[140px_1fr] gap-10">
          <span className="text-md-regular text-gray-500">Nomor Kartu</span>
          <span className="text-md-medium text-gray-900">{card?.masked_number}</span>
        </div>
        <div className="grid grid-cols-[140px_1fr] gap-10">
          <span className="text-md-regular text-gray-500">Card Holder</span>
          <span className="text-md-medium text-gray-900">{card?.cardholder_name}</span>
        </div>
      </div>
      {width !== undefined && width < 1024 ? (
        <div className="flex justify-start items-center pt-2">
          <CustomButton
            onClick={() => {
              onCancel()
              handleConfirmDeleteCard(card)
            }}
            type="danger"
            label={
              <div className="flex justify-center items-center gap-1">
                <TrashIcon />
                Hapus Kartu
              </div>
            }
          />
        </div>
      ) : null}
    </ModalLayout>
  )
}

export default ModalDetailCard
