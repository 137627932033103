import { BellIcon, LockIcon, PhoneIcon } from 'assets'
import CalendarIcon from 'assets/icons/calendar'
import React from 'react'

function ModalPesanSekarang({ isTaxi, handleNavigation }) {
  const options = [
    {
      type: 'booking',
      icon: LockIcon,
      label: 'Booking Mobil',
      caption: 'Biaya Pemesanan Dapat Dikembalikan 100%.',
      child: [
        'Menjamin test drive Anda',
        'Amankan mobil ini hanya untuk Anda',
        'Test drive dengan nyaman dari rumah Anda'
      ],
      path: `/beli/${!isTaxi ? 'mobil-bekas' : 'ex-taxi'}/booking`,
      detail: null
    },
    {
      type: 'test_drive',
      icon: CalendarIcon,
      label: 'Jadwalkan Test Drive',
      caption: 'Test drive experience di center kami:',
      child: [
        'Tersedia untuk Anda yang sudah divaksinasi lengkap',
        'Mobil ini akan tetap tersedia untuk dipesan oleh pelanggan lain'
      ],
      path: `/beli/${!isTaxi ? 'mobil-bekas' : 'ex-taxi'}/test-drive`,
      detail: {
        label:
          'Jika seseorang memesan mobil ini sebelum test drive terjadwal Anda, maka kami akan merekomendasikan mobil lain kepada Anda',
        icon: <BellIcon />
      }
    },
    {
      type: 'customer_service',
      icon: PhoneIcon,
      label: 'Kami Akan Segera Menghubungi Anda',
      caption:
        'Butuh informasi lebih? <br /><br />Pilih opsi ini dan kami akan segera menghubungi Anda',
      child: [],
      path: '/',
      detail: null
    }
  ]
  return (
    <div className="grid grid-cols-1 gap-6 max-w-[688px] w-full xl:w-[688px]">
      {options.map(({ icon: Icon, label, caption, child, detail, path, type }, index) => (
        <div
          className="p-6 bg-gray-50 border-gray-300 border border-solid rounded-lg cursor-pointer"
          key={index}
          onClick={() => handleNavigation(path, type)}>
          <div className="text-gray-700 stroke-gray-700 flex items-center space-x-2.5 text-lg-medium mb-2">
            <Icon className={'w-5 h-5 xl:w-5 xl:h-[18px]'} />
            <p>{label}</p>
          </div>
          <div className="pl-8 text-sm-medium text-gray-500">
            <div dangerouslySetInnerHTML={{ __html: caption }} />
            {child.length > 0 ? (
              <ul className="list-disc pl-5 mt-4">
                {child.map((el, i) => (
                  <li key={`child-${i}`}>{el}</li>
                ))}
              </ul>
            ) : (
              ''
            )}
            {detail !== null ? (
              <div className="flex space-x-2.5 mt-4 text-xs-regular text-gray-700">
                {detail?.icon}
                <p>{detail.label}</p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ModalPesanSekarang
