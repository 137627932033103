export const checkSpecialChar = (value) => {
  let hasSpecialCharacter = false
  if (value.includes('~') ||
    value.includes('!') ||
    value.includes('@') ||
    value.includes('#') ||
    value.includes('$') ||
    value.includes('%') ||
    value.includes('^') ||
    value.includes('&') ||
    value.includes('*') ||
    value.includes('?')) {
    if (value.includes('(') ||
      value.includes(')') ||
      value.includes('-') ||
      value.includes('_') ||
      value.includes('+') ||
      value.includes('=') ||
      value.includes('{') ||
      value.includes('[') ||
      value.includes('}') ||
      value.includes(']') ||
      value.includes('|') ||
      value.includes('\\') ||
      value.includes(':') ||
      value.includes(';') ||
      value.includes('"') ||
      value.includes('\'') ||
      value.includes('<') ||
      value.includes(',') ||
      value.includes('>') ||
      value.includes('.') ||
      value.includes('/')) {
      hasSpecialCharacter = false
    } else {
      hasSpecialCharacter = true
    }
  }
  return hasSpecialCharacter
}