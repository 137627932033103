import { Col } from 'antd'
import CustomButton from 'components/ui/custom-button'
import SectionTitle from 'components/ui/section-title'
import React from 'react'

const StepsSection = ({ items, onClick, buttonLabel, sectionTitle }) => {
  return (
    <Col span={24} className="bg-white py-10 md:py-20">
      <Col span={22} lg={{ span: 20 }} className="mx-auto flex flex-col gap-12">
        <SectionTitle title={sectionTitle} />
        {/* Steps */}

        <ol className="flex md:flex-row flex-col flex-wrap">
          {items.map((data, index) => (
            <li key={index} className="md:stepper__item mb-4">
              {/* Num Step */}
              <div className="flex justify-center items-center mb-4 md:mb-0">
                <div className=" h-12 w-12 rounded-full flex justify-center items-center display-xs-medium text-primary-500 bg-primary-50">
                  {index + 1}
                </div>
              </div>
              {/* Title & Description */}
              <div className="flex text-center flex-col gap-2 max-w-xs mx-auto items-center">
                <p className="text-lg-medium text-gray-900">{data.title}</p>
                <p className="text-sm-regular text-gray-500">{data.description}</p>
                {
                  index === 2 && (
                    <>
                      <p className="text-sm-regular text-gray-500">(*) untuk inspeksi berbayar</p>
                      <p className="text-sm-regular text-gray-500">(**) untuk Jual Mobil di BirdMobil</p>
                    </>
                  )
                }
              </div>
            </li>
          ))}
        </ol>
        {/* Button Container */}
        <div className="flex items-center justify-center">
          <CustomButton onClick={onClick} label={buttonLabel} />
        </div>
      </Col>
    </Col>
  )
}

export default StepsSection
