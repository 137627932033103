import CardSectionHeader from 'components/ui/card-section-header'
import { capitalize } from 'lodash'
import React from 'react'
import QRCode from 'react-qr-code'

function CardBarcode({ idTransaction, serviceName }) {
  return (
    <CardSectionHeader headerTitle={`Informasi ${capitalize(serviceName)}`}>
      <div className="grid grid-cols-1 gap-5">
        {idTransaction && <QRCode className="w-full object-contain" value={idTransaction.toString()} />}
      </div>
    </CardSectionHeader>
  )
}

export default CardBarcode
