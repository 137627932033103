const EyeIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      stroke=""
      strokeWidth="1.67"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.833984 7.9987C0.833984 7.9987 4.16732 1.33203 10.0007 1.33203C15.834 1.33203 19.1673 7.9987 19.1673 7.9987C19.1673 7.9987 15.834 14.6654 10.0007 14.6654C4.16732 14.6654 0.833984 7.9987 0.833984 7.9987Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0007 10.4987C11.3814 10.4987 12.5006 9.37941 12.5006 7.9987C12.5006 6.61799 11.3814 5.4987 10.0007 5.4987C8.61994 5.4987 7.50065 6.61799 7.50065 7.9987C7.50065 9.37941 8.61994 10.4987 10.0007 10.4987Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EyeIcon
