import CardSectionHeader from "components/ui/card-section-header"
import CustomButton from "components/ui/custom-button"
import { EditIcon } from "assets"
function CardChangePassword({ handleClick }) {
    return (
        <CardSectionHeader
            headerTitle={
                <div className="flex justify-between">
                    <div>Password</div>
                    <CustomButton
                        type="plain"
                        label={
                            <div className="flex justify-center items-center gap-1 ">
                                <EditIcon />
                                Ganti Password
                            </div>
                        }
                        onClick={handleClick}
                    />
                </div>
            }
            hideDivider
        />
    )
}
export default CardChangePassword;