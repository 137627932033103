import { BookIcon, CheckIcon } from 'assets'
import BadgeItem from 'components/ui/badge-item'
import CardSectionHeader from 'components/ui/card-section-header'
import GradeItem from 'components/ui/grade-item'
import React from 'react'
import moment from 'moment'
const colors = [
  { id: 1, hex: '#101828' },
  { id: 2, hex: '#AFB5D9' },
  { id: 3, hex: '#36BFFA' },
  { id: 4, hex: '#FEC84B' },
  { id: 5, hex: '#E12B25' }
]

function CardCTA({
  handleNavToCicilan,
  isTaxi,
  dataUnit,
  colors,
  selectedColor,
  handleSelectedColor
}) {
  const cicilan = () => {
    let final = 0
    const price = dataUnit?.price || 0
    const depo = price * 0.1
    const bunga = 10 / 100
    const tenor = 5 * 12

    const sisaBayar = price - depo
    const cicilanPokok = sisaBayar / tenor
    const bungaBulanan = (sisaBayar * bunga) / 12

    final = cicilanPokok + bungaBulanan
    return final
  }
  return (
    <CardSectionHeader hideDivider={true}>
      <div className="grid grid-cols-1 gap-5 ">
        {/* Badge */}
        <div className="flex justify-start gap-2">
          {/* {!isTaxi ? (
            <BadgeItem type="primary" className={'flex gap-1 items-center'}>
              <ChecklistIcon className={'stroke-primary-500 w-3 h-3'} /> BirdMobil Certified
            </BadgeItem>
          ) : (
            ''
          )} */}
          <BadgeItem type="success">Ready Stock</BadgeItem>
        </div>

        {/* Title & Grade Inspection */}
        <div className="flex justify-between items-center gap-2">
          {/* Title */}
          <p className="text-md-medium md:text-xl-medium text-gray-900">
            {dataUnit?.name || dataUnit?.unit_name || '-'}
          </p>
        </div>

        {/* Unit Price & Discounted Price/Color */}
        <div className="flex flex-col gap-2">
          {/* Discounted Price/Color Select */}
          {
            isTaxi ? (
              <div className="text-md-medium text-gray-900 w-full">
                Warna
                <div className="w-full overflow-auto">
                  <div className="flex gap-4 mt-2 mb-5 w-full">
                    {colors?.map((color) => (
                      <div
                        key={color.id}
                        className={`overflow-hidden h-[40px] w-[40px] rounded-full flex items-center justify-center flex-shrink-0 ${selectedColor === color.id ? 'border border-solid border-primary-700' : ''}`}
                      >
                        <div
                          style={{ backgroundColor: color?.hex_code }}
                          className={`rounded-full inline-block h-[38px] w-[38px] flex items-center justify-center flex-shrink-0 cursor-pointer border border-primary-300
                          ${selectedColor === color.id ? 'border-2 border-solid border-primary-50' : 'border'
                            }`}
                          onClick={() => handleSelectedColor(color.id)}
                        >
                          {
                            selectedColor === color.id ? (
                              <div className="h-[20px] w-[20px] flex items-center justify-center rounded-full bg-primary-50">
                                <CheckIcon className={'w-3 h-3 fill-primary-700'} />
                              </div>
                            ) : null
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null
            // <p className="text-xl-medium text-gray-400 line-through">Rp 250.000.000</p>
          }
          {/* Unit Price */}
          <p className="display-xs-semibold md:display-sm-semibold text-primary-500">
            Rp {dataUnit?.price !== undefined ? dataUnit?.price?.toLocaleString('ID-id') : '0'}
          </p>
        </div>

        {/* Estimate Credit */}
        <div className="flex gap-2 text-gray-500 text-sm-medium">
          <BookIcon className={'stroke-gray-500'} />
          <span>Estimasi Pembayaran</span>
        </div>
        <div className="flex justify-between items-center">
          {/* Total Payment per Month */}
          <div className="flex flex-col text-xs-medium md:text-sm-medium">
            <span className="text-gray-500">Perkiraan Cicilan Bulanan:</span>
            <span className="text-gray-900">
              Rp {dataUnit?.price !== undefined ? cicilan().toLocaleString('ID-id') : '0'} / bln
            </span>
          </div>
          <p
            className="text-xs-medium md:text-sm-medium cursor-pointer text-primary-600 whitespace-nowrap"
            onClick={handleNavToCicilan}>
            Simulasi Kredit
          </p>
        </div>
        {dataUnit?.available_booking ? (
          <div className="flex flex-col gap-2">
            <p className="text-gray-700 text-xs-regular md:text-sm-regular">
              Available tanggal booking:
            </p>
            {dataUnit?.available_booking?.start_time ? (
              <div className="flex gap-2 rounded justify-start items-center p-3 bg-success-50 text-sm-medium md:text-md-medium text-gray-700">
                <p>{moment(dataUnit?.available_booking?.start_time).format('DD MMMM YYYY')}</p>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </CardSectionHeader>
  )
}

export default CardCTA
