import {
  CustomerStories,
  FAQ,
  General,
  HelpSupport,
  PrivacyPolicy,
  TermsConditions
} from 'features/about-us'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const contentData = [
  { key: 'general', components: <General /> },
  { key: 'customer-stories', components: <CustomerStories /> },
  { key: 'privacy-policy', components: <PrivacyPolicy /> },
  { key: 'terms-and-conditions', components: <TermsConditions /> },
  { key: 'help-and-support', components: <HelpSupport /> },
  { key: 'faq', components: <FAQ /> }
]

function AboutUsPage() {
  const { content } = useParams()
  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [content])
  return <>{contentData.filter((data) => data.key === content)[0].components}</>
}

export default AboutUsPage
