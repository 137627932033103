const EksteriorIcon = ({ className }) => {
  return (
    <svg
      width="64"
      className={className}
      height="60"
      viewBox="0 0 64 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.606 57.8357C38.3496 56.9627 41.4873 52.4096 40.6143 47.6659C39.7413 42.9223 35.1882 39.7846 30.4445 40.6576C25.7009 41.5306 22.5632 46.0837 23.4362 50.8274C24.3092 55.571 28.8623 58.7087 33.606 57.8357Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3691 48.7253C28.3691 48.7253 31.5017 51.8578 31.6915 52.0477L36.4853 47.2539"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.709 48.9651C48.8726 47.4463 56.2768 43.8865 62.3995 38.8555"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.59961 38.8555C7.72233 43.934 15.1265 47.4937 23.2902 48.9651"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3715 13.5078L40.568 15.169C39.856 15.8335 38.9068 16.2132 37.91 16.2132H33.0688H26.6139C25.6171 16.2132 24.7153 15.8335 23.9559 15.169L22.1523 13.5078"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.3007 24.9945H41.8965V23.713C41.8965 22.3841 42.8457 21.2924 44.1747 21.1026L49.3007 20.3906"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7461 20.3438L19.8721 21.0557C21.1536 21.2455 22.1503 22.3372 22.1503 23.6662V24.9477H14.7461"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7598 17.2109V29.5038"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7605 17.211L8.71955 14.9803C8.48223 14.743 8.33984 14.4582 8.33984 14.1259V11.7053C8.33984 11.0408 8.86194 10.5188 9.52642 10.5188H14.1778L18.2121 2.7823C19.019 1.73811 20.253 1.12109 21.582 1.12109H32.0238H42.4657C43.7946 1.12109 45.0287 1.73811 45.8356 2.7823L49.8699 10.5188H54.5213C55.1857 10.5188 55.7078 11.0408 55.7078 11.7053V14.1259C55.7078 14.4582 55.5654 14.7904 55.3281 14.9803L53.2872 17.211V29.5039"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.7871 29.5039V29.9785C45.7871 32.0669 47.4483 33.7281 49.5367 33.7281C51.625 33.7281 53.2862 32.0669 53.2862 29.9785V29.5039"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2589 29.5039V29.9785C18.2589 32.0669 16.5977 33.7281 14.5093 33.7281C12.421 33.7281 10.7598 32.0669 10.7598 29.9785V29.5039"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.2991 29.5039H32.7345H14.7461"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0332 24.9961H34.9661"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4492 10.5195H45.598"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.417 3.73047H31.9752H21.5333C21.1062 3.73047 20.679 3.92032 20.3467 4.25256L18.6855 7.48004H45.2173L43.5561 4.25256C43.2713 3.92032 42.8916 3.73047 42.417 3.73047Z"
        fill="#E12B25"
      />
    </svg>
  )
}

export default EksteriorIcon
