import { Row } from 'antd'
import BenefitSection from 'components/section/benefit-section'
import FAQSection from 'components/section/faq-section'
import StepsSection from 'components/section/steps-section'
// import TestimonySection from 'components/section/testimony-section'
import { HeroJualSection } from 'features/jual-mobil'
import {
  getAllBrands,
  getAllColors,
  getAllFuels,
  getAllModels,
  getAllTransmision,
  getAllTypes,
  getPriceRecommendation
} from 'features/jual-mobil/service'
import React from 'react'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage } from 'utils/toastMessage'
const stepData = [
  {
    title: 'Isi Data Unit Mobil Anda',
    description: 'Isi data lengkap mobil Anda untuk mengetahui harga pasaran'
  },
  {
    title: 'Jadwalkan Inspeksi',
    description: 'Mekanik kami akan menginspeksi mobil Anda secara menyeluruh'
  },
  {
    title: 'Unit Siap Dijual',
    description: 'Dapatkan penawaran secara langsung untuk mobil Anda'
  },
  {
    title: 'Terjual',
    description:
      'Terima pembayaran dalam waktu 1jam* dan semua pengurusan dokumen akan dilakukan oleh tim BirdMobil'
  }
]
const initialState = {
  year: '',
  type: {},
  model: {},
  brand: {},
  transmisi: {}
  // fuel: {},
  // color: {}
}
const initialprice = {
  min_price: '-',
  max_price: '-'
}
const JualMobilPage = () => {
  const { push } = useHistory()
  const token = localStorage.getItem('token')
  const [state, setState] = useState({ ...initialState })
  const [price, setPrice] = useState({ ...initialprice })
  const [changeComponent, setChangeComponent] = useState(true)
  // list
  const [colors, setColors] = useState([])
  const [transmissions, setTransmissions] = useState([])
  const [fuels, setFuels] = useState([])
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [types, setTypes] = useState([])

  // start of fetching
  useQuery({
    queryKey: 'getColors',
    queryFn: () => getAllColors(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setColors(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getTransmissions',
    queryFn: () => getAllTransmision(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTransmissions(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getFuels',
    queryFn: () => getAllFuels(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setFuels(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getBrands',
    queryFn: () => getAllBrands(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setBrands(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: ['getModels', state.brand.value],
    queryFn: () => getAllModels(state?.brand?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setModels(newData)
    },
    staleTime: 0,
    enabled: state?.brand?.value !== undefined && state?.brand?.value !== '',
    refetchOnWindowFocus: true
  })
  useQuery({
    queryKey: ['getTypes', state?.model?.value],
    queryFn: () => getAllTypes(state?.model?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTypes(newData)
    },
    staleTime: 0,
    enabled: state?.model?.value !== undefined && state?.model?.value !== '',
    refetchOnWindowFocus: true
  })
  const { refetch } = useQuery({
    queryKey: 'getPriceRecommendation',
    queryFn: () => getPriceRecommendation(state?.type?.value, state?.transmisi?.value, state?.year),
    // queryFn: () => getPriceRecommendation(227),
    onSuccess: (data) => {
      setPrice({
        min_price: data.min_price.toLocaleString('id-ID'),
        max_price: data.max_price.toLocaleString('id-ID')
      })
    },
    onError: (data) => {
      // console.log('error', data?.response?.data)
      setPrice({ ...initialprice })
    },
    retry: false,
    enabled: false
  })
  // end of fetching

  const handleChange = (name, val) => {
    setState((prev) => ({
      ...prev,
      [name]: val
    }))
  }
  const handleChangeCarList = (name, val) => {
    switch (name) {
      case 'brand':
        setModels([])
        setTypes([])
        setState((prev) => ({
          ...prev,
          brand: val,
          model: {},
          type: {}
        }))
        break
      case 'model':
        setTypes([])
        setState((prev) => ({
          ...prev,
          model: val,
          type: {}
        }))
        break
      default:
        setState((prev) => ({
          ...prev,
          [name]: val
        }))
    }
  }
  const handleClickJualForm = () => {
    if (token !== null) {
      push('/jual/form', {
        data: {
          ...state
        },
        send: true
      })
    } else {
      push('/login')
    }
  }

  const handleClick = () => {
    let nameObject = ''
    let validObject = true
    const newObj = { ...state }
    delete newObj.year
    Object.keys(newObj).forEach((key) => {
      if (newObj[key].value === undefined) {
        validObject = false
        nameObject = String(key)
      }
    })
    // Object.values(newObj).forEach((v) => {
    //   if (v.value === undefined) {
    //     validObject = false
    //   }
    // })
    if (validObject) {
      if (state.year !== '') {
        refetch()
        setChangeComponent(false)
      } else {
        showErrorMessage('Mohon isi Tahun Keluaran')
      }
    } else {
      showErrorMessage(`Mohon isi ${nameToString[nameObject]}`)
    }
  }

  const handleCancel = () => {
    setPrice({ ...initialprice })
    setChangeComponent(true)
  }

  return (
    <Row>
      <HeroJualSection
        fuels={fuels}
        transmissions={transmissions}
        colors={colors}
        brands={brands}
        models={models}
        types={types}
        state={state}
        price={price}
        onClick={handleClickJualForm}
        handleChange={handleChange}
        handleChangeCarList={handleChangeCarList}
        changeComponent={changeComponent}
        setChangeComponent={setChangeComponent}
        handleClick={handleClick}
        handleCancel={handleCancel}
      />
      <BenefitSection headerTitle={'Keuntungan Jual di BirdMobil'} />
      {/* <TestimonySection /> */}
      <StepsSection
        items={stepData}
        onClick={handleClickJualForm}
        buttonLabel={'Jual Mobil'}
        sectionTitle={'Langkah Mudah Menjual Mobil Anda'}
      />
      <FAQSection />
    </Row>
  )
}

export default JualMobilPage
