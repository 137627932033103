import { Col, Row } from 'antd'
import React, { useState } from 'react'
import './style.css'
import { ImageWeb } from 'assets'
import SectionTitle from 'components/ui/section-title'
const radioData = [
  { label: 'Beli Mobil', id: 'opt1', value: 'beli', checked: true },
  { label: 'Jual Mobil', id: 'opt2', value: 'jual' },
  { label: 'Servis', id: 'opt3', value: 'servis' },
  { label: 'Inspeksi', id: 'opt4', value: 'inspeksi' }
]
const radioDataResponsive = [
  { label: 'Beli', id: 'opt1', value: 'beli', checked: true },
  { label: 'Jual', id: 'opt2', value: 'jual' },
  { label: 'Servis', id: 'opt3', value: 'servis' },
  { label: 'Inspeksi', id: 'opt4', value: 'inspeksi' }
]

const contentData = [
  {
    id: 'beli',
    children: [
      {
        title: 'Cari & Temukan Mobil Anda',
        description: 'Temukan mobil impian Anda secara online yang telah kami pilih'
      },
      {
        title: 'Beli Bebas Khawatir',
        description: 'Beli Mobil Impian Anda secara Tunai atau Kredit'
      },
      {
        title: 'Nikmati mobil Pilihan Anda',
        description: 'Nikmati mobil pilihan Anda yang telah lulus dari kurasi kami'
      }
    ]
  },
  {
    id: 'jual',
    children: [
      {
        title: 'Isi Data Unit Mobil Anda',
        description: 'Isi data lengkap mobil Anda untuk mengetahui harga pasaran'
      },
      {
        title: 'Jadwalkan Inspeksi',
        description: 'Mekanik kami akan menginspeksi mobil Anda secara menyeluruh'
      },
      {
        title: 'Unit Siap Dijual',
        description: 'Dapatkan penawaran secara langsung untuk mobil Anda'
      },
      {
        title: 'Terjual',
        description:
          'Terima pembayaran dalam waktu 1jam* dan semua pengurusan dokumen akan dilakukan oleh tim BirdMobil'
      }
    ]
  },
  {
    id: 'servis',
    children: [
      {
        title: 'Isi Data Unit Mobil Anda',
        description: 'Isi data lengkap mobil Anda untuk mengetahui jenis layanan perawatan'
      },
      {
        title: 'Jadwalkan Servis',
        description: 'Jadwalkan tanggal dan jam untuk Anda bisa melakukan perawatan mobil Anda'
      },
      {
        title: 'Mobil Anda Sudah Kembali Prima',
        description: 'Kini mobil Anda sudah kembali prima dan bisa diajak beraktivitas kembali'
      }
    ]
  },
  {
    id: 'inspeksi',
    children: [
      {
        title: 'Isi Data Unit Mobil Anda',
        description: 'Isi data lengkap mobil Anda'
      },
      {
        title: 'Jadwalkan Inspeksi',
        description: 'Jadwalkan tanggal dan jam untuk Anda bisa melakukan Inspeksi'
      },
      {
        title: 'Hasil Inspeksi Keluar',
        description: 'Kini Anda sudah mengetahui kondisi terkini dari mobil Anda'
      }
    ]
  }
]

function HowItWorksSection() {
  const [tab, setTab] = useState('beli')
  const content = contentData.filter((data) => data.id === tab)[0]['children']
  return (
    <Col span={24} lg={{ order: 7 }} className="bg-gray-50 py-9 lg:py-20">
      <Col span={22} lg={{ span: 20 }} className="mx-auto flex-col flex gap-12">
        {/* Section Title */}
        <SectionTitle
          title={'Layanan Yang Tersedia'}
          description={
            'Kami memberikan kemudahan dalam satu tempat untuk Anda menjual, membeli dan perawatan mobil Anda'
          }
        />
        {/* Radio Button Container */}
        <div className="radio hidden lg:flex">
          {radioData.map((data) => {
            return (
              <React.Fragment key={data.id}>
                {tab === data.value ? (
                  <input
                    className="radio-input"
                    type="radio"
                    defaultChecked
                    name="myRadio"
                    value={data.value}
                    id={data.id}
                    onClick={() => setTab(data.value)}
                  />
                ) : (
                  <input
                    className="radio-input"
                    type="radio"
                    name="myRadio"
                    value={data.value}
                    id={data.id}
                    onClick={() => setTab(data.value)}
                  />
                )}
                <label className="radio-label text-sm-regular md:text-md-regular" htmlFor={data.id}>
                  {data.label}
                </label>
              </React.Fragment>
            )
          })}
        </div>
        <div className="radio lg:hidden">
          {radioDataResponsive.map((data) => {
            return (
              <React.Fragment key={data.id}>
                <input
                  className="radio-input"
                  type="radio"
                  checked={tab === data.value}
                  name="radioResponsive"
                  value={data.value}
                  id={data.id}
                  onChange={() => setTab(data.value)}
                />
                <label className="radio-label" htmlFor={data.id}>
                  {data.label}
                </label>
              </React.Fragment>
            )
          })}
        </div>

        {/* Content Container */}
        <div className="grid-cols-1 md:grid-cols-2 grid gap-6">
          <div className="flex flex-col gap-4 md:gap-0 justify-around">
            {content.map((data, key) => (
              <div key={key} className="">
                {/* Steps container */}
                <div className="flex gap-[10px] items-center">
                  <div className="bg-primary-100 p-4 rounded-[4px] h-16 min-w-[64px] flex items-center justify-center text-primary-700 display-xs-medium">
                    {key + 1}
                  </div>
                  <div className="flex-col flex gap-2">
                    <div className="text-lg-medium text-gray-900">{data.title}</div>
                    <div className="text-sm-regular text-gray-500">{data.description}</div>
                  </div>
                </div>
              </div>
            ))}
            {/* Image Container */}
          </div>
          <img src={ImageWeb} className="object-cover w-full" alt="" />
        </div>
      </Col>
    </Col>
  )
}

export default HowItWorksSection
