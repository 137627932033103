import React, { useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  createDashboardUnitV2,
  editDashboardUnitV2,
  getDetailUnitDashboard,
  uploadPhotoUnit
} from 'features/dashboard/service'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { useGetOptSpecCar } from 'hooks'
import { deletePhotoUnit } from 'features/dashboard/service'
import { nameToString } from 'utils/nameToString'
import { imageResizer } from 'utils/imageResizer'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
import useValidation from 'lib/useValidation'
// eslint-disable-next-line no-undef
const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
const userProfile = JSON.parse(localStorage.getItem('user'))
const useCreateEditUnit = (idUnit, typePage, state) => {
  const { handleXSSInput } = useValidation()
  const { replace } = useHistory()
  const newUrlImages = useRef([])
  const removedImages = useRef([])
  const [isLoading, setIsLoading] = useState(false)
  const [statusInfo, setStatusInfo] = useState({
    category: '',
    upload: false,
    send: false,
    removed: false,
    done: false,
    progress: true,
    currentPhoto: 0,
    totalPhoto: 0
  })
  // Status = upload / mengirimkan
  const [input, setInput] = useState({
    merek: null,
    model: null,
    tipe: null,
    transmisi: null,
    tahunKeluaran: null,
    bahanBakar: null,
    warna: null,
    platNomor: null,
    nomorRangka: null,
    nomorMesin: null,
    thumbnail: [],
    photos: [],
    catatan: null
  })
  const { brands, models, transmissions, types, fuels, colors, categories } = useGetOptSpecCar(
    input?.merek?.value,
    input?.model?.value,
    input?.tipe?.value
  )
  const [errors, setErrors] = useState({
    merek: false,
    model: false,
    tipe: false,
    transmisi: false,
    tahunKeluaran: false,
    bahanBakar: false,
    warna: false,
    platNomor: false,
    nomorRangka: false,
    nomorMesin: false,
    thumbnail: false,
    photos: false,
    catatan: false
  })
  const inputs = [
    {
      id: 1,
      headerTitle: typePage === 'edit-unit' ? 'Edit Unit' : 'Tambah Unit',
      inputs: [
        {
          id: 1,
          label: 'Merek',
          name: 'merek',
          type: 'select',
          options: brands,
          value: input?.merek?.label,
          onChange: (value, option) =>
            inputHandler({ name: 'merek', value: option, type: 'select' }),
          mandatory: true,
          placeholder: 'Pilih Merek'
        },
        {
          id: 2,
          label: 'Model',
          name: 'model',
          type: 'select',
          options: models,
          value: input?.model?.label,
          onChange: (value, option) =>
            inputHandler({ name: 'model', value: option, type: 'select' }),
          mandatory: true,
          placeholder: 'Pilih Model'
        },
        {
          id: 3,
          label: 'Tipe',
          name: 'tipe',
          type: 'select',
          options: types,
          value: input?.tipe?.label,
          onChange: (value, option) =>
            inputHandler({ name: 'tipe', value: option, type: 'select' }),
          mandatory: true,
          placeholder: 'Pilih Tipe'
        },
        {
          id: 4,
          label: 'Transmisi',
          name: 'transmisi',
          type: 'select',
          options: transmissions,
          value: input?.transmisi?.label,
          onChange: (value, option) =>
            inputHandler({ name: 'transmisi', value: option, type: 'select' }),
          mandatory: true,
          placeholder: 'Pilih Transmisi'
        },
        {
          id: 5,
          label: 'Tahun Keluaran',
          name: 'tahunKeluaran',
          type: 'datepicker',
          value: input?.tahunKeluaran ? dayjs(input?.tahunKeluaran) : null,
          onChange: (_, dateString) =>
            inputHandler({ name: 'tahunKeluaran', value: dateString, type: 'select' }),
          picker: 'year'
        },
        {
          id: 6,
          label: 'Bahan Bakar',
          name: 'bahanBakar',
          type: 'select',
          options: fuels,
          value: input?.bahanBakar?.label,
          onChange: (value, option) =>
            inputHandler({ name: 'bahanBakar', value: option, type: 'select' }),
          placeholder: 'Pilih Bahan Bakar'
        },
        {
          id: 7,
          label: 'Warna',
          name: 'warna',
          type: 'select',
          options: colors,
          value: input?.warna?.label,
          onChange: (value, option) =>
            inputHandler({ name: 'warna', value: option, type: 'select' }),
          placeholder: 'Pilih Warna'
        },
        {
          id: 8,
          label: 'Plat Nomor',
          name: 'platNomor',
          type: 'text',
          value: input?.platNomor,
          onChange: (e) =>
            inputHandler({ name: e.target.name, value: e.target.value, tipe: e.target.type }),
          mandatory: true,
          placeholder: 'Masukan Plat Nomor'
        },
        {
          id: 9,
          label: 'Nomor Rangka',
          name: 'nomorRangka',
          type: 'text',
          value: input?.nomorRangka,
          onChange: (e) =>
            inputHandler({ name: e.target.name, value: e.target.value, tipe: e.target.type }),
          mandatory: true,
          placeholder: 'Masukan Nomor Rangka'
        },
        {
          id: 10,
          label: 'Nomor Mesin',
          name: 'nomorMesin',
          type: 'text',
          onChange: (e) =>
            inputHandler({ name: e.target.name, value: e.target.value, tipe: e.target.type }),
          value: input?.nomorMesin,
          mandatory: false,
          placeholder: 'Masukan Nomor Mesin'
        },
        {
          id: 11,
          label: 'Kategori',
          type: 'kategori',
          disabled: true,
          value: categories
        }
      ]
    },
    {
      id: 2,
      headerTitle: 'Foto Unit',
      inputs: [
        {
          id: 'file-upload-thumbnail',
          name: 'file-upload',
          label: 'Foto Thumbnail',
          accept: 'image/*',
          type: 'file',
          onChange: (file) => {
            inputHandler({ name: 'thumbnail', value: file, type: 'file' })
          },
          limit: 1,
          fileList: input?.thumbnail,
          placeholder:
            'Ukuran gambar 16:9, ukuran file maksimal 10mb, dengan format file jpg, jpeg, png.'
        },
        {
          label: 'Foto Detail Unit',
          type: 'file',
          id: 'file-upload-detail-unit',
          name: 'file-upload',
          accept: 'image/*',
          limit: 8,
          defaultFileList: input?.photos,
          fileList: input?.photos,
          onChange: (file) => {
            inputHandler({ name: 'photos', value: file, type: 'file' })
          },
          mandatory: false,
          placeholder: 'Format file jpg, jpeg, png.'
        }
      ]
    },
    // {
    //   id: 3,
    //   headerTitle: 'Dokumen Unit',
    //   inputs: [
    //     {
    //       id: 1,
    //       label: 'STNK',
    //       mandatory: true,
    //       name: 'dokumen_stnk',
    //       type: 'radio',
    //       value: input?.dokumen_stnk,
    //       options: [
    //         {
    //           id: 1,
    //           type: 'radio',
    //           name: 'radioSTNK',
    //           value: true,
    //           checked: input?.dokumen_stnk == 'true',
    //           label: 'ADA'
    //         },
    //         {
    //           id: 2,
    //           type: 'radio',
    //           name: 'radioSTNK',
    //           value: false,
    //           checked: input?.dokumen_stnk == 'false',
    //           label: 'TIDAK ADA'
    //         }
    //       ],
    //       onChange: (e) =>
    //         inputHandler({ name: 'dokumen_stnk', value: e.target.value, tipe: e.target.type })
    //     },
    //     {
    //       id: 2,
    //       label: 'BPKB',
    //       mandatory: true,
    //       name: 'dokumen_bpkb',
    //       type: 'radio',
    //       value: input?.dokumen_bpkb,
    //       options: [
    //         {
    //           id: 1,
    //           type: 'radio',
    //           name: 'radioBPKB',
    //           checked: input?.dokumen_bpkb == 'true',
    //           value: true,
    //           label: 'ADA'
    //         },
    //         {
    //           id: 2,
    //           type: 'radio',
    //           name: 'radioBPKB',
    //           checked: input?.dokumen_bpkb == 'false',
    //           value: false,
    //           label: 'TIDAK ADA'
    //         }
    //       ],
    //       onChange: (e) =>
    //         inputHandler({ name: 'dokumen_bpkb', value: e.target.value, tipe: e.target.type })
    //     },
    //     {
    //       id: 3,
    //       label: 'FAKTUR',
    //       mandatory: true,
    //       name: 'dokumen_faktur',
    //       type: 'radio',
    //       value: input?.dokumen_faktur,
    //       options: [
    //         {
    //           id: 1,
    //           type: 'radio',
    //           name: 'radioFAKTUR',
    //           value: true,
    //           checked: input?.dokumen_faktur == 'true',
    //           label: 'ADA'
    //         },
    //         {
    //           id: 2,
    //           type: 'radio',
    //           name: 'radioFAKTUR',
    //           value: false,
    //           checked: input?.dokumen_faktur == 'false',
    //           label: 'TIDAK ADA'
    //         }
    //       ],
    //       onChange: (e) =>
    //         inputHandler({ name: 'dokumen_faktur', value: e.target.value, tipe: e.target.type })
    //     },
    //     {
    //       id: 4,
    //       label: 'KTP Pemilik',
    //       mandatory: true,
    //       name: 'dokumen_ktp',
    //       type: 'radio',
    //       value: input?.dokumen_ktp,
    //       options: [
    //         {
    //           id: 1,
    //           type: 'radio',
    //           name: 'radioKTP',
    //           checked: input?.dokumen_ktp == 'true',
    //           value: true,
    //           label: 'ADA'
    //         },
    //         {
    //           id: 2,
    //           type: 'radio',
    //           name: 'radioKTP',
    //           checked: input?.dokumen_ktp == 'false',
    //           value: false,
    //           label: 'TIDAK ADA'
    //         }
    //       ],
    //       onChange: (e) =>
    //         inputHandler({ name: 'dokumen_ktp', value: e.target.value, tipe: e.target.type })
    //     },
    //     {
    //       id: 5,
    //       label: 'Kwitansi',
    //       mandatory: true,
    //       name: 'dokumen_kwitansi',
    //       type: 'radio',
    //       value: input?.dokumen_kwitansi,
    //       options: [
    //         {
    //           id: 1,
    //           type: 'radio',
    //           name: 'radioKwitansi',
    //           value: true,
    //           checked: input?.dokumen_kwitansi == 'true',
    //           label: 'ADA'
    //         },
    //         {
    //           id: 2,
    //           type: 'radio',
    //           name: 'radioKwitansi',
    //           value: false,
    //           checked: input?.dokumen_kwitansi == 'false',
    //           label: 'TIDAK ADA'
    //         }
    //       ],
    //       onChange: (e) =>
    //         inputHandler({ name: 'dokumen_kwitansi', value: e.target.value, tipe: e.target.type })
    //     },
    //     {
    //       id: 6,
    //       label: 'KEUR',
    //       mandatory: false,
    //       name: 'dokumen_keur',
    //       type: 'radio',
    //       value: input?.dokumen_keur,
    //       options: [
    //         {
    //           id: 1,
    //           type: 'radio',
    //           name: 'radioKEUR',
    //           value: true,
    //           checked: input?.dokumen_keur == 'true',
    //           label: 'ADA'
    //         },
    //         {
    //           id: 2,
    //           type: 'radio',
    //           name: 'radioKEUR',
    //           value: false,
    //           checked: input?.dokumen_keur == 'false',
    //           label: 'TIDAK ADA'
    //         }
    //       ],
    //       onChange: (e) =>
    //         inputHandler({ name: 'dokumen_keur', value: e.target.value, tipe: e.target.type })
    //     },
    //     {
    //       id: 7,
    //       label: 'Form A',
    //       mandatory: true,
    //       name: 'dokumen_form',
    //       type: 'radio',
    //       value: input?.dokumen_form,
    //       options: [
    //         {
    //           id: 1,
    //           type: 'radio',
    //           name: 'radioFormA',
    //           value: true,
    //           checked: input?.dokumen_form == 'true',
    //           label: 'ADA'
    //         },
    //         {
    //           id: 2,
    //           type: 'radio',
    //           name: 'radioFormA',
    //           value: false,
    //           checked: input?.dokumen_form == 'false',
    //           label: 'TIDAK ADA'
    //         }
    //       ],
    //       onChange: (e) =>
    //         inputHandler({ name: 'dokumen_form', value: e.target.value, tipe: e.target.type })
    //     }
    //   ]
    // },
    {
      id: 4,
      headerTitle: 'Catatan',
      inputs: [
        {
          id: 1,
          rows: 4,
          style: { height: 120, resize: 'none' },
          name: 'catatan',
          value: input?.catatan,
          label: '',
          type: 'textarea',
          mandatory: false,
          placeholder: 'Masukan Catatan',
          onChange: (e) => inputHandler({ name: e.target.name, value: e.target.value })
        }
      ]
    }
  ]
  useQuery({
    queryKey: 'getDetailUnit',
    queryFn: () => getDetailUnitDashboard(idUnit),
    onSuccess: (data) => {
      const photos = data?.photos?.map((photo) => ({
        ...photo,
        be_id: photo.id,
        photo: '',
        url: urlImage.concat(photo?.photo_filename)
      }))
      let thumbnailObj = {}
      if (data?.thumbnail_filename) {
        thumbnailObj = {
          id: 0,
          be_id: 1,
          url: urlImage.concat(data?.thumbnail_filename),
          photo: ''
        }
      }
      setInput({
        merek: { label: data?.brand?.name, value: data?.brand?.id },
        model: { label: data?.model?.name, value: data?.model?.id },
        tipe: { label: data?.type?.name, value: data?.type?.id },
        transmisi: { label: data?.transmission?.name, value: data?.transmission?.id },
        tahunKeluaran: data?.production_year,
        bahanBakar:
          data?.fuel?.id !== 0 ? { label: data?.fuel?.name, value: data?.fuel?.id } : null,
        warna: data?.color?.id !== 0 ? { label: data?.color?.name, value: data?.color?.id } : null,
        platNomor: data?.police_number,
        nomorRangka: data?.frame_number,
        nomorMesin: data?.engine_number,
        thumbnail: data?.thumbnail_filename ? [thumbnailObj] : [],
        photos: photos,
        catatan: data?.description
      })
    },
    enabled: !!idUnit,
    refetchOnWindowFocus: false
  })
  const postCreateUnitV2 = useMutation(createDashboardUnitV2, {
    onSuccess: () => {
      setStatusInfo({
        ...statusInfo,
        done: true,
        progress: false
      })
      setIsLoading(false)
      showSuccessMessage('Unit Anda berhasil ditambahkan')
      replace({
        pathname:
          state?.navBack === 'servis'
            ? '/servis/servis-mobil'
            : state?.navBack === 'inspeksi'
            ? '/inspeksi/form'
            : '/dashboard',
        state: { isModalOpen: state?.navBack ? true : false }
      })
    },
    onError: (data) => {
      setStatusInfo({
        ...statusInfo,
        done: false,
        progress: false,
        fail: true
      })
      setIsLoading(false)
      showErrorMessage(data?.message)
    }
  })
  const putEditUnitV2 = useMutation({
    mutationFn: (formData) => editDashboardUnitV2(idUnit, formData),
    onSuccess: () => {
      setStatusInfo({
        ...statusInfo,
        done: true,
        progress: false
      })
      setIsLoading(false)
      showSuccessMessage('Unit Anda berhasil diubah')
      replace('/dashboard')
    },
    onError: (data) => {
      setStatusInfo({
        ...statusInfo,
        done: false,
        progress: false,
        fail: true
      })
      setIsLoading(false)
      showErrorMessage(data?.message)
    }
  })
  const postUploadPhoto = useMutation({
    mutationFn: (formData) => uploadPhotoUnit(formData),
    onSuccess: (data) => {
      newUrlImages.current.push(data)
    },
    onError: (data) => {
      setIsLoading(false)
      showErrorMessage(data?.message)
    }
  })
  const deletePhoto = useMutation({
    mutationFn: (photoId) => deletePhotoUnit(photoId),
    onSuccess: () => {
      removedImages.current.shift()
    },
    onError: (data) => {
      setIsLoading(false)
      showErrorMessage(data?.message)
    }
  })
  // END API

  // Start Function
  async function inputHandler({ name, value }) {
    // FILE HANDLER
    if (name === 'thumbnail' || name === 'photos') {
      const { file, fileList } = value
      let newFileList
      if (file.status !== undefined) {
        if (name === 'photos' && file.photo_filename !== undefined) removedImages.current.push(file)
        newFileList = fileList
      } else {
        const newFile = fileList.pop()
        // const imageConvertedObj = await imageResizer(newFile.originFileObj)
        newFileList = [
          ...fileList,
          {
            id: fileList.length,
            photo: newFile.originFileObj,
            originFileObj: newFile.originFileObj,
            uid: newFile.originFileObj.uid
          }
        ]
      }
      setInput({ ...input, [name]: newFileList })
    } else if (!value && (name !== 'photos' || name !== 'thumbnail')) {
      setInput({ ...input, [name]: null })
    } else {
      setInput({ ...input, [name]: value })
    }

    if (name === 'merek') {
      setInput({ ...input, [name]: value, ['model']: null, ['tipe']: null })
      setErrors({ ...errors, [name]: false, ['model']: false, ['tipe']: false })
    }
  }
  async function submitForm() {
    // Re-Validate (DONE)
    let valid = { status: true, errorMessage: '' }
    const newErrors = { ...errors }
    const newInput = { ...input }
    delete newInput.photos
    delete newInput.nomorMesin
    delete newInput.catatan
    delete newInput.warna
    delete newInput.thumbnail
    delete newInput.bahanBakar
    delete newErrors.photos
    delete newErrors.nomorMesin
    delete newErrors.catatan
    delete newErrors.warna
    delete newErrors.thumbnail
    delete newErrors.bahanBakar
    for (const [key, value] of Object.entries(newErrors)) {
      if (newInput[key] === null || newInput[key]?.length === 0 || value) {
        valid.status = false
        valid.errorMessage = `Mohon isi ${nameToString[key]}`
        break
      }
    }
    // if (input.photos.length < 4) {
    //   valid.status = false
    //   valid.errorMessage = 'Photo unit minimal 4 gambar'
    // }
    if (!valid.status) return showErrorMessage(valid.errorMessage)
    setIsLoading(true)

    // REMOVED IMAGE LOOP (DONE)
    if (removedImages.current.length > 0) {
      let tempArray = [...removedImages.current]
      for (let i = 0; i < tempArray.length; i++) {
        setStatusInfo({
          ...statusInfo,
          category: 'gambar',
          currentPhoto: i + 1,
          totalPhoto: tempArray.length,
          progress: true,
          done: false,
          fail: false,
          upload: false,
          removed: true,
          send: false
        })
        await deletePhoto.mutateAsync(tempArray[i].be_id)
      }
    }

    // UPLOAD IMAGE LOOP (DONE)
    if (input.photos.length > 0) {
      for (let i = 0; i < input.photos.length; i++) {
        setStatusInfo({
          ...statusInfo,
          category: 'gambar',
          currentPhoto: i + 1,
          totalPhoto: input.photos.length,
          progress: true,
          done: false,
          fail: false,
          upload: true,
          removed: false,
          send: false
        })
        if (input?.photos[i]?.be_id !== undefined) {
          newUrlImages.current.push(input?.photos[i]?.photo_filename)
        } else {
          const formDataImage = new FormData()
          formDataImage.append('photo', input.photos[i].originFileObj)
          await postUploadPhoto.mutateAsync(formDataImage)
        }
      }
    }
    setStatusInfo({
      ...statusInfo,
      category: 'data',
      removed: false,
      upload: false,
      send: true
    })

    // ADD FORMDATA TO POST DASHBOARD UNIT
    const newFormData = new FormData()
    newFormData.append('brand_id', input?.merek?.value)
    newFormData.append('brand_name', handleXSSInput(input?.merek?.label))
    newFormData.append('model_id', input?.model?.value)
    newFormData.append('model_name', handleXSSInput(input?.model?.label))
    newFormData.append('type_id', input?.tipe?.value)
    newFormData.append('type_name', handleXSSInput(input?.tipe?.label))
    newFormData.append('transmission_id', input?.transmisi?.value)
    newFormData.append('fuel_id', input?.bahanBakar?.value ? input?.bahanBakar?.value : 0)
    newFormData.append('color_id', input?.warna?.value ? input?.warna?.value : 0)
    newFormData.append('company_id', userProfile?.company_id)
    newFormData.append('police_number', handleXSSInput(input?.platNomor))
    newFormData.append('production_year', handleXSSInput(input?.tahunKeluaran))
    newFormData.append('frame_number', handleXSSInput(input?.nomorRangka))
    newFormData.append('engine_number', input?.nomorMesin ? handleXSSInput(input?.nomorMesin) : '')
    newFormData.append('description', input?.catatan ? handleXSSInput(input?.catatan) : '')
    newFormData.append('document_stnk_status', false)
    newFormData.append('document_bpkb_status', false)
    newFormData.append('document_faktur_status', false)
    newFormData.append('document_kwitansi_status', false)
    newFormData.append('document_ktp_status', false)
    newFormData.append('document_keur_status', false)
    newFormData.append('document_form_status', false)
    newFormData.append(
      'thumbnail',
      input?.thumbnail[0]?.be_id !== undefined || input?.thumbnail?.length === 0
        ? ''
        : input.thumbnail[0].originFileObj
    )

    for (let i = 0; i < newUrlImages.current.length; i++) {
      newFormData.append(`photos[]`, newUrlImages.current[i])
    }
    if (typePage === 'edit-unit') {
      putEditUnitV2.mutate(newFormData)
    } else {
      postCreateUnitV2.mutate(newFormData)
    }
  }
  return {
    inputs,
    errors,
    isLoading,
    statusInfo,
    submitForm
  }
}

export default useCreateEditUnit
