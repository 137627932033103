import { Button, Col, Input, Row, Select } from 'antd'
import { ArrowRightIcon, MagnifyingIcon } from 'assets'
import React from 'react'

import CustomButton from 'components/ui/custom-button'
import { merk } from 'data/merk'
import { CarouselMultipleLayout } from 'components/ui/CarouselMultipleLayout'

const filterHarga = [
  { title: '< Rp 100 Jt', value: { minValue: 0, maxValue: 100000000 } },
  { title: 'Rp 100 Jt - 200 Jt', value: { minValue: 100000000, maxValue: 200000000 } },
  { title: 'Rp 200 Jt - 300 Jt', value: { minValue: 200000000, maxValue: 300000000 } },
  { title: 'Rp 300 Jt - 400 Jt', value: { minValue: 300000000, maxValue: 400000000 } },
  { title: 'Rp 400 Jt - 500 Jt', value: { minValue: 500000000, maxValue: 500000000 } },
  { title: '> Rp 500 Jt', value: { minValue: 500000000 } }
]

function TabBuyContent({
  handleNavigationCatalog,
  handleClickBrandPrice,
  handleChangeSearch,
  locations,
  filter
}) {
  return (
    <Row className="bg-white h-full w-full border border-solid border-gray-200 shadow-lg">
      <Row className="w-full px-5 mt-4">
        {/* Filter Search by Keyword & Location */}
        <Col span={24}>
          <div className="flex gap-5">
            <Input
              name="keywords"
              size="medium"
              className="text-md-normal text-gray-500 px-[14px]"
              prefix={<MagnifyingIcon className={'mr-1'} />}
              placeholder="Cari mobil menurut merek, model, atau kata kunci"
              onChange={(e) => {
                handleChangeSearch(e.target.name, e.target.value)
              }}
            />
            <Select
              name="location"
              placeholder="Pilih Cabang"
              size="large"
              style={{ borderRadius: '8px' }}
              className="w-full rounded-lg "
              options={locations}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={filter?.location}
              onChange={(value, option) =>
                handleChangeSearch('location', { label: option.label, value: option.value })
              }
            />
            <CustomButton label={'Search'} onClick={handleNavigationCatalog} />
          </div>
          {/* Filter Harga */}
          <Col span={24} className="mt-8">
            <p className="text-sm-medium mb-[10px]">Cari Berdasarkan Harga</p>
            {/* <div className="grid-cols-6 gap-2 grid lg:justify-between">
              {filterHarga.map((obj, key) => (
                <Button
                  className="px-6 bg-gray-200 border-none hover:border-none hover:bg-gray-300"
                  key={key}>
                  <p className="text-md-medium text-gray-500">{obj.title}</p>
                </Button>
              ))}
            </div> */}
            <div className="overflow-hidden">
              <CarouselMultipleLayout
                centerMode
                infinite
                arrows={true}
                slidesToShow={4}
                slidesToScroll={1}
                responsive={[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}>
                {filterHarga.map((obj, key) => (
                  <Button
                    onClick={() => handleClickBrandPrice('harga', obj.value)}
                    className="px-6 bg-gray-200 border-none hover:border-none hover:bg-gray-300 focus:bg-gray-300"
                    key={key}>
                    <p className="text-md-medium text-gray-500">{obj.title}</p>
                  </Button>
                ))}
              </CarouselMultipleLayout>
            </div>
          </Col>
          {/* Filter Merk */}
          <Col span={24} className="mt-8">
            <div className="flex justify-between">
              {merk.map((merkItem, key) => (
                <span
                  onClick={() => handleClickBrandPrice('merek', merkItem)}
                  className="flex flex-col items-center cursor-pointer group gap-2"
                  key={key}>
                  {merkItem.image}
                  <span className="text-md-medium group-hover:text-primary-500 text-gray-600">
                    {merkItem.label}
                  </span>
                </span>
              ))}
            </div>
            <div className="flex justify-center my-6 mt-8 mb-3">
              <div
                className="flex gap-2 cursor-pointer items-center"
                onClick={handleNavigationCatalog}>
                <span className="text-primary-600 text-md-medium">Lihat Semua Produk</span>
                <ArrowRightIcon />
              </div>
            </div>
          </Col>
        </Col>
      </Row>
    </Row>
  )
}

export default TabBuyContent
