import { BriefCaseIcon, ProfileIcon } from 'assets'
import { useHistory } from 'react-router-dom'

const BoxButton = ({ icon: Icon, title, path }) => {
  const { push, replace } = useHistory()
  const isB2B = localStorage.getItem('account_type')
  if (isB2B !== null) {
    replace('/')
  }
  const handleClick = (e) => {
    e.preventDefault()
    push(path)
  }
  return (
    <div
      onClick={handleClick}
      className="cursor-pointer p-5 border border-solid border-gray-200 rounded-md mb-2">
      <div className="w-full flex items-center">
        <div className="rounded-full bg-gray-200 h-12 w-12 flex items-center justify-center">
          <Icon className="h-6 w-6 stroke-gray-400" />
        </div>
        <div className="ml-5">
          <p className="text-gray-900 text-lg-medium">{`Daftar untuk ${title}`}</p>
          <p className="text-gray-500 text-sm-regular">{`Kelola mobil ${title.toLowerCase()} Anda`}</p>
        </div>
      </div>
    </div>
  )
}

function Register() {
  const dataList = [
    {
      icon: BriefCaseIcon,
      title: 'Pribadi',
      path: '/register/personal'
    },
    {
      icon: ProfileIcon,
      title: 'Perusahaan',
      path: '/register/company'
    }
  ]
  return (
    <div className="flex flex-col gap-6">
      <p className="text-gray-900 text-sm-semibold text-3xl text-center">
        Register to BirdMobil as?
      </p>
      <p className="text-gray-500 text-xl-regular text-center mb-3">
        Gabung dengan BirdMobil untuk solusi kebutuhan mobil Anda.
      </p>
      <div>
        {dataList.map((data, indx) => (
          <BoxButton icon={data.icon} title={data.title} path={data.path} key={`button-${indx}`} />
        ))}
      </div>
    </div>
  )
}

export default Register
