import { Pagination } from 'antd'
import React from 'react'
import './style.css'
function CustomPagination({ defaultCurrent, defaultPageSize, current, onChange, total }) {
  return (
    <Pagination
      responsive={true}
      defaultPageSize={defaultPageSize}
      current={current}
      defaultCurrent={defaultCurrent}
      onChange={onChange}
      total={total}
    />
  )
}

export default CustomPagination
