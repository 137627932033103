import API from "services";

const getDetailInspeksi = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit/result/${args.id}`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    });
  return response.data;
}
const getDetailInspeksiCatalog = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/non-login/inspection-unit/result/${args.id}`
  );
  return response.data;
}
const getProvinces = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/provinces/all`);
  return response.data;
}

const getCities = async (provinceId) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/cities/province/${provinceId}`);
  return response.data;
}

const getSubdistricts = async (cityId) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/subdistricts/city/${cityId}`);
  return response.data;
}

const getSchedules = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/cities/province/`);
  return response.data;
}

const getRangePrice = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/admin/inspection-distance-prices/all`);
  return response.data;
}

const getWorkshops = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/workshops/all?keywords=&sort_by=name&order_by=asc`);
  return response.data;
}

const getTimes = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit/schedules`);
  return response.data;
}

const getUnitList = async (keyword) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/customer-site/units/inspection/all?keywords=${keyword}`);
  return response.data;
}

// 2 untuk biaya inspeksi
const getInspectionPrice = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/configurations/service-prices/2`);
  return response.data;
}
// 3 untuk biaya admin
const getAdminPrice = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/configurations/service-prices/3`);
  return response.data;
}
const getTaxPrice = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/configurations/taxes/5`);
  return response.data;
}

const postInspection = async (args) => {
  const { data: response } = await API.post(`${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit`,
    args.body,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    }
  );
  return response.data;
}

export {
  getDetailInspeksi,
  getDetailInspeksiCatalog,
  getProvinces,
  getCities,
  getSubdistricts,
  getSchedules,
  getWorkshops,
  getRangePrice,
  getTimes,
  getUnitList,
  postInspection,
  getInspectionPrice,
  getAdminPrice,
  getTaxPrice
}