const BadgeIconSuccess = (className) => {
    return (
        <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3L4.5 8.5L2 6" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

const BadgeIconWarning = (className) => {
    return (
        <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00135 4.00059V6.00059M6.00135 8.00059H6.00635M5.14635 1.43059L0.911353 8.50059C0.824037 8.6518 0.777837 8.82324 0.777348 8.99785C0.776859 9.17246 0.822099 9.34416 0.908566 9.49585C0.995034 9.64755 1.11972 9.77396 1.27021 9.86252C1.4207 9.95107 1.59175 9.99867 1.76635 10.0006H10.2364C10.411 9.99867 10.582 9.95107 10.7325 9.86252C10.883 9.77396 11.0077 9.64755 11.0941 9.49585C11.1806 9.34416 11.2258 9.17246 11.2254 8.99785C11.2249 8.82324 11.1787 8.6518 11.0914 8.50059L6.85635 1.43059C6.76722 1.28364 6.64171 1.16215 6.49195 1.07783C6.34219 0.993515 6.17322 0.949219 6.00135 0.949219C5.82949 0.949219 5.66052 0.993515 5.51076 1.07783C5.36099 1.16215 5.23549 1.28364 5.14635 1.43059Z" stroke="#F79009" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

const BadgeIconDanger = (className) => {
    return (
        <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 3L3 9M3 3L9 9" stroke="#F04438" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export {
    BadgeIconSuccess,
    BadgeIconWarning,
    BadgeIconDanger
}