import { ModalLayout } from 'layouts'
import React from 'react'
import CustomButton from '../custom-button'
import PlusIcon from 'assets/icons/plus'
import { imageCreditCards } from 'data/image-credit-card'

const staticCardList = [
  {
    id: 'pm-85bb4a12-7159-4b97-9815-063f41c9d05f',
    masked_number: '482XXXXX399',
    type: 'CREDIT',
    network: 'VISA',
    cardholder_name: 'Christianto Saputra'
  },
  {
    id: 'pm-85bb4a12-7159-4b97-9815-063f41c9d05f',
    masked_number: '482XXXXX399',
    type: 'CREDIT',
    network: 'VISA',
    cardholder_name: 'Christianto Saputra'
  },
  {
    id: 'pm-85bb4a12-7159-4b97-9815-063f41c9d05f',
    masked_number: '482XXXXX399',
    type: 'CREDIT',
    network: 'VISA',
    cardholder_name: 'Christianto Saputra'
  }
]

const ModalCreditCards = ({
  openModal,
  onCancel,
  creditCards,
  openModalAddCard,
  handleSelectedCard,
  selectedCreditCard
}) => {
  return (
    <ModalLayout
      headerTitle={'Pilih Kartu Kredit / Debit'}
      openModal={openModal}
      wrapClassName=""
      footer={null}
      className="w-full max-w-md"
      width={''}
      onCancel={onCancel}>
      <div className="grid grid-cols-1 gap-4">
        {creditCards?.length > 0 ? (
          creditCards?.map((card, index) => (
            <div
              className={`p-6 border border-solid rounded-[4px] cursor-pointer flex justify-between items-center border-gray-200 bg-white`}
              onClick={() => {
                handleSelectedCard(card)
              }}
              key={index}>
              <div>
                <p className="text-xs-regular text-gray-500">{card?.cardholder_name}</p>
                <p className="text-lg-medium text-gray-900">
                  {card?.masked_number.match(/.{1,4}/g).join(' ')}
                </p>
              </div>
              <img src={imageCreditCards[card?.network]?.image} alt="Logo Credit Card" />
            </div>
          ))
        ) : (
          <div
            onClick={openModalAddCard}
            className="flex cursor-pointer max-w-md w-full py-3 h-32 justify-center items-center bg-gray-50 flex-col">
            <p className="text-sm-semibold text-gray-500">Belum ada kartu yang tersedia</p>
            <p className="text-primary-500 text-sm-semibold">Tambah Kartu</p>
          </div>
        )}
        {creditCards?.length > 0 ? (
          <div className="grid grid-cols-1">
            <CustomButton
              onClick={openModalAddCard}
              type="secondary"
              label={
                <div className="flex items-center gap-3 justify-center stroke-primary-600">
                  <PlusIcon /> Tambah Kartu
                </div>
              }
            />
          </div>
        ) : null}
      </div>
    </ModalLayout>
  )
}

export default ModalCreditCards
