import { Divider } from 'antd'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
import moment from 'moment'
import CardSectionHeader from 'components/ui/card-section-header'
const KonfirmasiPembayaranCard = ({ data, validationCheckPaymentMethod, serviceName }) => {
  return (
    <CardSectionHeader headerTitle="Konfirmasi Pembayaran">
      <>
        <div className=" grid grid-cols-1 gap-2 sm:gap-0 sm:flex justify-between items-center">
          {data?.expired_at ? (
            <>
              <p className="text-md-regular text-gray-600">Segera lakukan pembayaran sebelum: </p>
              <p className="text-md-medium text-gray-700 text-right">
                {moment(data.expired_at).format('DD MMMM YYYY - HH:mm')} WIB
              </p>
            </>
          ) : (
            <>
              <p className="text-md-regular text-gray-600">{`Segera lakukan pembayaran ${serviceName} Anda`}</p>
            </>
          )}
        </div>
        <Divider />
        <div className="flex justify-end">
          <CustomButton label={'Lakukan Pembayaran'} onClick={validationCheckPaymentMethod} />
        </div>
      </>
    </CardSectionHeader>
  )
}

export default KonfirmasiPembayaranCard
