import { Col } from 'antd'
import {
  BebasBanjirIcon,
  BirdMobilCertifiedIcon,
  // AwardIcon,
  ChecklistIcon,
  // CoinStackIcon,
  DollarIcon,
  HargaPastiIcon,
  LulusInspeksiIcon,
  MagnifyingIcon,
  // RecycleIcon,
  ShieldIcon
} from 'assets'
import React from 'react'

function BirdmobilCertifiedSection({ unOrder }) {
  return (
    <Col
      span={24}
      lg={{ order: unOrder ? null : 9 }}
      className="bg-primary-500 py-9 lg:py-20 h-full md:h-[434px] relative overflow-hidden">
      {/* Circle Left background */}
      <div className="hidden absolute top-[-40vh] left-[-23vw] h-[679px] w-[679px] opacity-30 bg-primary-200 rounded-full md:flex justify-center items-center">
        <div className="h-[461px] w-[461px] opacity-50 bg-primary-300 rounded-full flex justify-center items-center">
          <div className="h-[251px] w-[251px] opacity-100 bg-primary-400 rounded-full"></div>
        </div>
      </div>
      {/* Content */}
      <Col span={24} className="z-10 mx-auto">
        {/* Section Title */}
        <div className="flex justify-center mb-[77px]">
          <div className="flex gap-[13px] items-center">
            <BirdMobilCertifiedIcon />
            <div className="flex flex-col gap-1">
              <p className=" text-white text-lg-medium">BirdMobil</p>
              <p className="text-white display-xs-semibold">Certified</p>
            </div>
          </div>
        </div>
        {/* Benefits */}
        <div className="flex flex-col justify-center items-center md:items-start gap-5 md:gap-0 md:flex-row md:justify-around">
          <div className="flex flex-col items-center gap-[11px] w-[230px] max-w-[250px]">
            <HargaPastiIcon />
            <p className="text-lg-medium text-white text-center">Harga Pasti</p>
          </div>
          <div className="flex flex-col items-center gap-[11px] w-[230px] max-w-[250px]">
            <LulusInspeksiIcon />
            <p className="text-lg-medium text-white text-center">Lulus Inspeksi 188 Titik</p>
          </div>
          <div className="flex flex-col items-center gap-[11px] w-[230px] max-w-[250px]">
            <BebasBanjirIcon />
            <p className="text-lg-medium text-white text-center">
              Bebas Banjir,<br /> Kecelakaan & Kebakaran
            </p>
          </div>
        </div>
      </Col>
      {/* Circle Right Background */}
      <div className="absolute z-0 md:bottom-[-30vh] bottom-0 right-[-100%] sm:right-[-50%] md:right-[-13vw] h-[679px] w-[679px] opacity-30 bg-primary-200 rounded-full flex justify-center items-center">
        <div className="h-[461px] w-[461px] bg-primary-300 rounded-full opacity-50 flex justify-center items-center">
          <div className="h-[251px] w-[251px] bg-primary-400 rounded-full opacity-100"></div>
        </div>
      </div>
    </Col>
  )
}

export default BirdmobilCertifiedSection
