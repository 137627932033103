import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
function CardInformationCar({car, isJual}) {
  return (
    <CardSectionHeader hideDivider>
      <div className="grid grid-cols-1 gap-6">
        {/* content data */}
        <div className="flex md:flex-row flex-col items-center gap-6">
          <div className="rounded-md overflow-hidden flex justify-center items-center">
            <img src={!isJual ? process.env.REACT_APP_IMAGE_BASE_URL.concat(car?.thumbnail) : car?.thumbnail} className="w-full max-w-[200px] object-cover" alt="" />
          </div>
          <p className="text-lg-medium text-gray-900">{car?.unit_name}</p>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardInformationCar
