const KonsultasiIcon = ({ className }) => {
  return (
    <svg
      width="82"
      className={className}
      height="54"
      viewBox="0 0 82 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.44894 44.435C8.07016 44.6366 10.6914 44.771 13.2454 44.9727M10.7586 17.2146C10.0865 18.3572 10.0193 19.2309 10.0193 20.1719C10.0193 21.9866 11.4307 22.9947 13.111 23.0619C23.6631 23.5996 33.4758 23.6668 43.7591 23.3308M6.72594 30.5223L15.5978 31.5977M42.6165 10.8296C33.1398 10.2919 26.7548 10.3591 16.1355 11.0312C14.9257 11.1656 14.2536 11.8377 13.9847 12.1738L9.68322 18.7605H3.29819C2.55887 18.7605 1.81956 19.3654 1.61792 20.0375L1.01303 22.1882C0.945816 22.457 1.14745 22.5915 1.41629 22.7259L5.04567 24.4062L3.56704 26.1536C2.6933 27.2962 2.22282 28.7749 2.22282 30.6568V48.8709C2.22282 49.6102 2.82772 50.2151 3.56704 50.2151H11.6995C12.4389 50.2151 13.0438 49.6102 13.0438 48.8709L13.111 44.9727C17.6141 45.2415 22.3861 45.3759 27.2252 45.4431M80.9939 52.4331C81.1283 42.8891 79.112 35.3615 68.7615 32.0682C61.4355 29.7158 49.5392 29.7158 42.2132 32.0682C31.8628 35.3615 29.8465 42.8891 29.9809 52.4331H80.9939ZM65.8042 7.80509C66.4091 -1.26836 44.7 -1.26836 45.3721 7.80509L46.1115 18.6932C46.7836 28.5732 64.3928 28.5732 65.0649 18.6932L65.8042 7.80509Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="22.926"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4563 17.886L16.1366 14.1222C16.1366 14.055 16.2038 13.9878 16.271 13.9878C18.2201 13.8534 20.102 13.7189 22.1184 13.5845C28.6378 13.1813 34.9556 13.114 41.0046 13.2485C41.139 13.2485 41.2062 13.5173 41.2062 13.6517C41.2734 15.1304 41.3406 16.4746 41.4078 17.9532C41.4078 18.0876 41.3406 18.2221 41.2062 18.2221C39.5259 18.2893 28.1673 18.5581 14.5235 18.2221C14.5235 18.2221 14.3891 18.0204 14.4563 17.886Z"
        fill="#E12B25"
      />
    </svg>
  )
}

export default KonsultasiIcon
