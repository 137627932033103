function CarCustomIcon({ className }) {
	return (
		<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1638_97749)">
				<path d="M21 11.5V19M21 11.5H3M21 11.5L19.9583 9M21 19V21.5H20V19M21 19H20M3 11.5L5.5 5.5H13M3 11.5V19M3 19V21.5H4V19M3 19H4M20 19H4M18 2.5V7.5M20.5 5H15.5M7.5 17C7.10218 17 6.72064 16.842 6.43934 16.5607C6.15804 16.2794 6 15.8978 6 15.5C6 15.1022 6.15804 14.7206 6.43934 14.4393C6.72064 14.158 7.10218 14 7.5 14C7.89782 14 8.27936 14.158 8.56066 14.4393C8.84196 14.7206 9 15.1022 9 15.5C9 15.8978 8.84196 16.2794 8.56066 16.5607C8.27936 16.842 7.89782 17 7.5 17ZM16.5 17C16.1022 17 15.7206 16.842 15.4393 16.5607C15.158 16.2794 15 15.8978 15 15.5C15 15.1022 15.158 14.7206 15.4393 14.4393C15.7206 14.158 16.1022 14 16.5 14C16.8978 14 17.2794 14.158 17.5607 14.4393C17.842 14.7206 18 15.1022 18 15.5C18 15.8978 17.842 16.2794 17.5607 16.5607C17.2794 16.842 16.8978 17 16.5 17Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			</g>
			<defs>
				<clipPath id="clip0_1638_97749">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>

	)
}
export default CarCustomIcon