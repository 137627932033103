import { Col, Divider, Row } from 'antd'
import NotificationItem from 'components/header/components/notification-item'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import moment from 'moment'
import useNotification from 'hooks/useNotification'
import 'moment/locale/id';

const NotificationPage = () => {
  const { notifications, handleClickNotification } = useNotification('page')

  return (
    <Row className="py-20">
      <Col span={22} className="grid grid-cols-1 gap-12 mx-auto">
        <p className="display-sm-semibold text-gray-900">Notifikasi</p>
        {
          notifications?.today?.length > 0 &&
          <CardSectionHeader headerTitle="Hari ini" hideDivider>
            <ol className="grid grid-cols-1 gap-0 mt-5">
              {notifications?.today?.map((notif, key) => (
                <React.Fragment key={key}>
                  <NotificationItem
                    isPage
                    id={notif.id}
                    key={key}
                    title={notif.title}
                    vehicle={notif?.body?.concat(notif?.unit_police_number)}
                    read={notif?.is_read}
                    time={moment(notif?.created_at).fromNow()}
                    handleClickNotification={handleClickNotification}
                  />
                  {key === notifications?.today?.length - 1 ? null : <Divider className="m-0" />}
                </React.Fragment>
              ))}
            </ol>
          </CardSectionHeader>
        }
        {
          notifications?.another?.length > 0 &&
          <CardSectionHeader headerTitle="Lebih Awal" hideDivider>
            <ol className="grid grid-cols-1 gap-0 mt-5">
              {notifications?.another?.map((notif, key) => (
                <React.Fragment key={key}>
                  <NotificationItem
                    isPage
                    id={notif.id}
                    key={key}
                    title={notif.title}
                    vehicle={`${notif?.body} ${notif?.unit_police_number}`}
                    read={notif?.is_read}
                    time={moment(notif?.created_at).fromNow()}
                    handleClickNotification={handleClickNotification}
                  />
                  {key === notifications?.another?.length - 1 ? null : <Divider className="m-0 p-0" />}
                </React.Fragment>
              ))}
            </ol>
          </CardSectionHeader>
        }
      </Col>
    </Row>
  )
}

export default NotificationPage
