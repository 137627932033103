import React from 'react'
import StatusDoneTransaction from './status-done-transaction'
import StatusPendingTransaction from './status-pending-transaction'
import StatusProgressTransaction from './status-progress-transaction'

function CardStatusTransaction({
  data,
  handleLakukanPembayaran,
  handleApprovalService,
  putApprovalService,
  status,
  serviceType,
  isApprovalServiceCompany
}) {
  return (serviceType === 'beli' || serviceType === 'test drive') &&
    (status?.toLowerCase() === 'booked' ||
      status?.toLowerCase() === 'booking lunas' ||
      status?.toLowerCase() === 'menunggu konfirmasi') ? (
    // serviceType beli/test drive + status booked/booking lunas/menunggu konfirmasi
    <StatusPendingTransaction
      putApprovalService={putApprovalService}
      handleApprovalService={handleApprovalService}
      handleLakukanPembayaran={handleLakukanPembayaran}
      data={data}
    />
  ) : (serviceType === 'inspeksi' || serviceType === 'servis') &&
    (status === 'WAITING_APPROVAL' || status === 'WAITING_CONFIRMATION' || status === 'UNPAID') ? (
    // serviceType inspeksi/servis + status
    <StatusPendingTransaction
      isApprovalServiceCompany={isApprovalServiceCompany}
      putApprovalService={putApprovalService}
      handleApprovalService={handleApprovalService}
      handleLakukanPembayaran={handleLakukanPembayaran}
      data={data}
    />
  ) : serviceType !== 'beli' &&
    (status === 'progress' ||
      status === 'SERVICE_IN_PROGRESS' ||
      status === 'IN_PROGRESS' ||
      status === 'SCHEDULED' ||
      status === 'Berlangsung' ||
      status === 'Penjadwalan') ? (
    // serviceType inspeksi/servis
    <StatusProgressTransaction data={data} status={status} />
  ) : status?.toLowerCase() === 'selesai' || status === 'SERVICE_FINISHED' ? (
    // status done
    <StatusDoneTransaction data={data} />
  ) : (
    ''
  )
}

export default CardStatusTransaction
