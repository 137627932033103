import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getTimesInspection, getTimesServis, getTimesTestDrive } from 'services/schedule-times'
import { showErrorMessage } from 'utils/toastMessage'
const initialSchedule = {
  date: null,
  hours: null
}
const serviceAPIMap = {
  servis: getTimesServis,
  inspeksi: getTimesInspection,
  'test-drive': getTimesTestDrive
}
const useScheduleService = (serviceName) => {
  const [schedule, setSchedule] = useState({ ...initialSchedule })
  const [times, setTimes] = useState([])
  useQuery({
    queryKey: ['getSchedules', schedule?.date?.date()],
    queryFn: () => serviceAPIMap[serviceName](),
    onSuccess: (data) => {
      const currentHour = new Date().getHours()
      const currentDate = new Date().getDate()
      let newData = []
      if (schedule?.date?.date() === currentDate) {
        for (let i = 0; i < data.length; i++) {
          const startTime = data[i].time.split('-')[0].split(':')[0]
          if (startTime > currentHour) {
            newData.push({
              ...data[i],
              label: data[i].time,
              value: data[i].id,
              time: data[i].time
            })
          }
        }
      } else {
        newData = data.map((el) => ({
          ...el,
          label: el.time,
          value: el.id,
          time: el.time
        }))
      }
      setTimes(newData)
    },
    staleTime: 0
  })
  const handleChangeSchedule = (name, value) => {
    if (name === 'date') {
      setSchedule((prev) => ({
        ...prev,
        [name]: value,
        hours: null
      }))
    }
    setSchedule((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  function scheduleIsValid() {
    const newSplitHour = schedule.hours.time.split('-')
    const currentDate = new Date().getDate()
    if (new Date().getHours() <= newSplitHour[0] && currentDate === schedule?.date?.date()) {
      return false
    }
    return true
  }
  function resetSchedule() {
    setSchedule({ ...initialSchedule })
  }
  function validationSchedule() {
    let isValid = null
    isValid = Object.entries(schedule).find(([key, value]) => value === null && key)
    if (isValid !== null) {
      showErrorMessage(`Harap mengisi ${isValid === 'date' ? 'Tanggal' : 'Jam'} Jadwal`)
    }
  }
  return {
    times,
    schedule,
    handleChangeSchedule,
    scheduleIsValid,
    resetSchedule,
    validationSchedule
  }
}

export default useScheduleService
