import { Col, Row, Steps } from 'antd'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
const stepsData = [
  {
    key: 0,
    title: 'Booking Inspeksi'
  },
  {
    key: 1,
    title: 'Inspeksi Dilakukan'
  },
  {
    key: 2,
    title: 'Kirim Hasil Inspeksi'
  }
]
function TabInspectionContent({ handleNavigation }) {
  return (
    <Row className="bg-white h-full w-full border border-solid border-gray-200 shadow-lg">
      <Row className="w-full px-5 py-4">
        <Col span={24}>
          <ol className="flex md:flex-row flex-col flex-wrap ">
            {stepsData.map((data, index) => (
              <li key={index} className="md:stepper__item mb-7">
                {/* Num Step */}
                <div className="flex justify-center items-center mb-4 md:mb-0">
                  <div className=" h-12 w-12 rounded-full flex justify-center items-center display-xs-medium text-primary-500 bg-primary-50">
                    {index + 1}
                  </div>
                </div>
                {/* Title & Description */}
                <div className="flex text-center flex-col gap-2 max-w-xs mx-auto items-center">
                  <p className="text-lg-medium text-gray-900">{data.title}</p>
                  {/* <p className="text-sm-regular text-gray-500">{data.description}</p> */}
                </div>
              </li>
            ))}
          </ol>
        </Col>
        <div className="flex justify-center items-center w-full">
          <CustomButton
            label="Lakukan Inspeksi"
            onClick={() =>
              handleNavigation('/inspeksi/form', {
                send: true
              })
            }
          />
        </div>
      </Row>
    </Row>
  )
}

export default TabInspectionContent
