import { Col, Row } from 'antd'
import { ChevronRightIcon } from 'assets'
import CustomButton from 'components/ui/custom-button'
import { HeroJualBackground } from 'features/jual-mobil/assets'
import React from 'react'

const HeroServisSection = ({ onClick }) => {
  return (
    // xl:h-[90vh]
    <Row className={`w-full h-full xl:h-full relative bg-white`}>
      {/* Background */}
      {/* h-[62vh] */}
      <div className='hidden xl:block w-full h-full z-[0] absolute bg-cover bg-no-repeat bg-[url("assets/images/hero-servis-background.webp")]'></div>
      <img
        src={HeroJualBackground}
        className="xl:hidden w-full h-full max-h-72 z-[0] absolute bg-contain bg-no-repeat"></img>
      <Col span={22} lg={{ span: 20 }} className="mx-auto py-9 lg:py-6 xl:py-24">
        <div className="grid grid-cols-1 gap-6 h-full xl:max-h-[496px] relative">
          {/* Left Content: Title */}
          <div className="flex flex-col h-full">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2 text-white">
                <p className="display-xs-semibold lg:display-lg-semibold ">
                  Perawatan mobil terpercaya <br /> dengan mekanik <br /> yang sudah tersertifikasi
                </p>
              </div>
              <div>
                <CustomButton
                  onClick={onClick}
                  label={
                    <div className="flex gap-4 items-center justify-center">
                      <p>Lakukan Servis</p> <ChevronRightIcon className={'stroke-white'} />
                    </div>
                  }
                />
              </div>
            </div>
            {/* Total Servis */}
            {/* <div className="absolute bottom-0 w-full hidden xl:block">
              <div className="flex gap-8 text-center justify-center relative h-full">
                <div className="flex flex-col gap-2 pr-9 border-r border-gray-300">
                  <p className="display-md-semibold text-primary-500">140.203</p>
                  <p className="text-md-semibold text-gray-700">Penjualan</p>
                </div>
                <div className="flex flex-col gap-2 pr-9 border-r border-gray-300">
                  <p className="display-md-semibold text-primary-500">21.678</p>
                  <p className="text-md-semibold text-gray-700">Orang Puas</p>
                </div>
                <div className="flex flex-col gap-2 pr-9 border-r border-gray-300">
                  <p className="display-md-semibold text-primary-500">11.902</p>
                  <p className="text-md-semibold text-gray-700">Berhasil Diservis</p>
                </div>
                <div className="flex flex-col gap-2 pr-9 border-r border-gray-300">
                  <p className="display-md-semibold text-primary-500">11.902</p>
                  <p className="text-md-semibold text-gray-700">Berjalan</p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="display-md-semibold text-primary-500">11.902</p>
                  <p className="text-md-semibold text-gray-700">Berjalan</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default HeroServisSection
