import { Col, DatePicker, Row, Switch } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import FormInput from 'components/ui/form/form-input'
import FormSelect from 'components/ui/form/form-select'
import FormTextArea from 'components/ui/form/form-textarea'
import moment from 'moment'
import {
  addCompanyUser,
  addUser,
  getCities,
  getProvinces,
  getSubdistricts
} from 'features/detail-users/service'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import useValidation from 'lib/useValidation'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
import { nameToString } from 'utils/nameToString'

const initialUserData = {
  alamat: '',
  phone: '',
  email: '',
  nama: '',
  company_email: '',
  npwp: '',
  birth_date: null,
  province: {},
  city: {},
  sub_district: {}
}

function CreateEditUserPage() {
  const { id } = useParams()
  const { push, location } = useHistory()
  const token = localStorage.getItem('token')
  const [userData, setUserData] = useState({ ...initialUserData })
  const [errors, setErrors] = useState({ ...initialUserData })

  // list
  const [cities, setCities] = useState([])
  const [provinces, setProvinces] = useState([])
  const [subdistricts, setSubdistricts] = useState([])

  const { stringValidation, numberValidation, emailValidation } = useValidation()

  // start of fetching
  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: Infinity
  })

  useQuery({
    queryKey: ['getCities', userData?.province?.value],
    queryFn: () => getCities(userData?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    enabled: userData?.province?.value !== '' && userData?.province?.value !== undefined,
    staleTime: 0
  })

  useQuery({
    queryKey: ['getSubdistricts', userData?.city?.value],
    queryFn: () => getSubdistricts(userData?.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts(newData)
    },
    enabled: userData?.city?.value !== '' && userData?.city?.value !== undefined,
    staleTime: 0
  })

  const addUserCompany = useMutation(addCompanyUser, {
    onSuccess: (data) => {
      // console.log(data)
      push('/users')
    },
    onError: (data) => {
      const { response } = data
      // console.log(response?.data?.errors[0]?.message)
      showErrorMessage(response?.data?.errors[0]?.message || 'there was an error')
    }
  })

  const postDatauser = useMutation(addUser, {
    onSuccess: (data) => {
      // console.log(data)
      if (location.state.addToCompany !== undefined) {
        // const dataSend = {
        //   id: data.id,
        //   token: token
        // }
        // addUserCompany.mutate(dataSend)
        showSuccessMessage('User berhasil ditambahkan')
        push('/users')
      } else {
        push('/users')
      }
    },
    onError: (data) => {
      const { response } = data
      console.log(response?.data?.errors[0]?.message)
      showErrorMessage(response?.data?.errors[0]?.message || 'there was an error')
    }
  })
  // end of fetching

  // function
  const handleCancel = () => push('/users')

  const handleSubmit = () => {
    const state = { ...userData }
    const error = { ...errors }
    delete state.birth_date
    delete state.province
    delete state.city
    delete state.sub_district
    delete state.npwp
    delete state.company_email
    delete error.birth_date
    delete error.province
    delete error.city
    delete error.sub_district
    delete error.npwp
    delete error.company_email

    // validate
    let validError = true
    let validInput = true
    let nameError = ''
    let nameInput = ''
    Object.keys(state).forEach((key) => {
      if (state[key].length === 0) {
        validInput = false
        nameInput = String(key)
      }
    })
    Object.keys(error).forEach((key) => {
      if (error[key].length > 0) {
        validError = false
        nameError = String(key)
      }
    })
    if (!validInput) {
      showErrorMessage(`Mohon isi ${nameToString[nameInput]}`)
    } else if (userData.birth_date === null) {
      showErrorMessage(`Mohon isi Tanggal Lahir`)
    } else if (userData.province.value === undefined) {
      showErrorMessage(`Mohon isi ${nameToString['province']}`)
    } else if (userData.city.value === undefined) {
      showErrorMessage(`Mohon isi ${nameToString['city']}`)
    } else if (userData.sub_district.value === undefined) {
      showErrorMessage(`Mohon isi ${nameToString['subdistrict']}`)
    } else {
      if (validError) {
        // console.log('2')
        const dataSend = {
          data: {
            email: userData.email,
            company_email: userData.company_email,
            fullname: userData.nama,
            phone_number: userData.phone,
            npwp: userData.npwp,
            address: userData.alamat,
            birth_date: moment(userData.birth_date).format('DD/MM/YYYY'),
            province_id: userData.province.value,
            province_name: userData.province.label,
            city_id: userData.city.value,
            city_name: userData.city.label,
            sub_district_id: userData.sub_district.value,
            sub_district_name: userData.sub_district.label
          },
          token: token
        }
        postDatauser.mutate(dataSend)
      } else {
        // console.log('3')
        showErrorMessage(`Mohon perbaiki ${nameToString[nameError]}`)
      }
    }
  }
  const handleChange = (name, value) => {
    let err = ''
    switch (name) {
      case 'phone':
        err = numberValidation(value, 'Nomor Telepon')
        break
      case 'email':
        err = emailValidation(value)
        break
      // case 'company_email':
      //   err = emailValidation(value)
      //   break
      case 'nama':
        err = stringValidation(value, 'Nama')
        break
      // case 'npwp':
      //   err = numberValidation(value, 'NPWP')
      //   break
      case 'address':
        err = stringValidation(value, 'Alamat')
        break
      default:
        err = ''
    }

    setUserData((prev) => ({
      ...prev,
      [name]: value
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: err
    }))
  }
  const handleChangeLocation = (name, value) => {
    let newValue = {}
    if (name === 'province') {
      setCities([])
      setSubdistricts([])
      newValue = {
        ...userData,
        province: value,
        city: {},
        sub_district: {}
      }
    } else if (name === 'city') {
      setSubdistricts([])
      newValue = {
        ...userData,
        city: value,
        sub_district: {}
      }
    } else if (name === 'sub_district') {
      newValue = {
        ...userData,
        sub_district: value
      }
    }
    setUserData(newValue)
  }

  const inputss = [
    {
      id: 1,
      mandatory: true,
      label: 'Nama',
      name: 'nama',
      type: 'text',
      placeholder: 'Masukan Nama',
      onChange: (e) => handleChange('nama', e.target.value),
      value: userData.nama
    },
    {
      id: 2,
      mandatory: false,
      label: 'Email Perusahaan',
      name: 'company_email',
      type: 'text',
      placeholder: 'Masukan Email Perusahaan',
      onChange: (e) => handleChange('company_email', e.target.value),
      value: userData.company_email
    },
    {
      id: 3,
      mandatory: true,
      label: 'Email Pribadi',
      name: 'email',
      type: 'text',
      placeholder: 'Masukan Email Pribadi',
      onChange: (e) => handleChange('email', e.target.value),
      value: userData.email
    },
    {
      id: 4,
      mandatory: true,
      label: 'Nomor Telepon',
      name: 'phone',
      addonBefore: '+62',
      type: 'text',
      placeholder: 'Masukan Nomor Telepon',
      onChange: (e) => handleChange('phone', e.target.value),
      value: userData.phone
    },
    {
      id: 5,
      mandatory: true,
      label: 'Tanggal Lahir',
      type: 'datepicker',
      name: 'birth_date',
      onChange: (date) => handleChange('birth_date', date),
      value: userData.birth_date
    },
    {
      id: 9,
      mandatory: false,
      label: 'NPWP',
      name: 'phone',
      type: 'text',
      placeholder: 'Masukan NPWP',
      onChange: (e) => handleChange('npwp', e.target.value),
      value: userData.npwp
    },
    {
      id: 10,
      mandatory: true,
      rows: 4,
      style: { height: 120, resize: 'none' },
      name: 'alamat',
      label: 'Alamat',
      type: 'textarea',
      placeholder: 'Masukan Alamat',
      onChange: (e) => handleChange('alamat', e.target.value),
      value: userData.alamat
    },
    {
      id: 10,
      label: '',
      child: [
        {
          id: 11,
          mandatory: true,
          label: 'Provinsi',
          placeholder: 'Provinsi',
          type: 'select',
          options: provinces,
          onChange: (_, option) => handleChangeLocation('province', option),
          value: userData?.province?.value
        },
        {
          id: 12,
          mandatory: true,
          label: 'Kota',
          placeholder: 'Kota',
          type: 'select',
          options: cities,
          onChange: (_, option) => handleChangeLocation('city', option),
          value: userData?.city?.value
        },
        {
          id: 13,
          mandatory: true,
          label: 'Kecamatan',
          placeholder: 'Kecamatan',
          type: 'select',
          options: subdistricts,
          onChange: (_, option) => handleChangeLocation('sub_district', option),
          value: userData?.sub_district?.value
        }
      ]
    }
  ]

  return (
    <Row className="gap-6">
      {/* Header */}
      <Col span={24} className="flex gap-1 items-center text-xl-medium text-gray-900 py-2">
        User /{' '}
        <span className="text-primary-500">{id !== undefined ? 'Edit User' : 'Tambah User'}</span>
      </Col>
      {/* Content Container */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* Form Information user */}
        <CardSectionHeader>
          <div className="grid grid-cols-1 gap-5">
            {id !== undefined ? (
              <div className="text-sm-regular text-gray-700 max-w-fit gap-6 flex">
                {/* User Aktif */}
                User Aktif
                <Switch defaultChecked />
              </div>
            ) : (
              ''
            )}
            {inputss.map((inputItem) => {
              if (inputItem.child !== undefined) {
                return (
                  <div className="grid grid-cols-3 gap-3" key={inputItem.id}>
                    {inputItem.child.map((input) => (
                      <FormSelect key={input.id} data={input} />
                    ))}
                  </div>
                )
              } else {
                if (inputItem.type === 'text')
                  return (
                    <React.Fragment key={inputItem.id}>
                      <FormInput data={inputItem} />
                      {errors[inputItem.name] && (
                        <small className="text-primary-300">{errors[inputItem.name]}</small>
                      )}
                    </React.Fragment>
                  )
                else if (inputItem.type === 'select')
                  return <FormSelect key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'datepicker')
                  return (
                    <div className="grid grid-cols-1 gap-2" key={inputItem.id}>
                      <p className="text-sm-medium text-gray-700">
                        Tanggal Lahir <span className="text-danger-500">*</span>
                      </p>
                      <DatePicker
                        className="rounded-[4px] flex justify-center text-md-normal p-2 gap-3 items-center"
                        format="DD/MM/YYYY"
                        placeholder="Masukan Tanggal Lahir"
                        onChange={inputItem.onChange}
                      />
                    </div>
                  )
                else if (inputItem.type === 'textarea')
                  return (
                    <React.Fragment key={inputItem.id}>
                      <FormTextArea data={inputItem} />
                      {errors[inputItem.name] && (
                        <small className="text-primary-300">{errors[inputItem.name]}</small>
                      )}
                    </React.Fragment>
                  )
              }
            })}
          </div>
        </CardSectionHeader>

        <Col span={24} className="flex justify-end items-center gap-2">
          <CustomButton type="plain" label={'Batal'} onClick={handleCancel} />
          <CustomButton label={'Simpan'} onClick={handleSubmit} />
        </Col>
      </Col>
    </Row>
  )
}

export default CreateEditUserPage
