const LayananJualMobilIcon = ({ className }) => {
  return (
    <svg
      width="101"
      height="74"
      className={className}
      viewBox="0 0 101 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1979 24.8647H14.0547V43.9952H18.1979V24.8647Z" fill="#E12B25" />
      <path
        d="M53.3379 53.3553H47.7625H42.187C42.0336 53.3553 41.8801 53.4065 41.7778 53.4576L40.0898 55.4014H55.4863L53.7983 53.4576C53.6448 53.4065 53.4914 53.3553 53.3379 53.3553Z"
        fill="#E12B25"
      />
      <path
        d="M87.8656 53.3553H82.2902H76.6636C76.5101 53.3553 76.3567 53.4065 76.2544 53.4576L74.5664 55.4014H89.9628L88.2748 53.4576C88.1725 53.4065 88.0191 53.3553 87.8656 53.3553Z"
        fill="#E12B25"
      />
      <path
        d="M2.90429 0.772339H29.2982C30.3723 0.772339 31.1907 1.6419 31.1907 2.66492V22.9207C31.1907 23.9948 30.3212 24.8133 29.2982 24.8133H2.90429C1.83012 24.8133 1.01172 23.9437 1.01172 22.9207V2.66492C1.01172 1.6419 1.83012 0.772339 2.90429 0.772339Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M14.0547 24.8647V65.9389"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M18.1484 65.9389V24.8647"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path d="M100.5 73.0483H0.5" stroke="black" strokeWidth="1.5" strokeMiterlimit="22.926" />
      <path
        d="M4.28516 73.0484V68.854H27.968V73.0484"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M32.6758 55.964H36.5632"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M59.0156 55.964H62.9543"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M55.3844 67.4731V69.0077C55.3844 69.3146 55.6401 69.5703 55.9982 69.5703H60.3972C60.7041 69.5703 61.011 69.3146 61.011 69.0077V61.1816C61.011 60.3632 60.8064 59.6982 60.4483 59.2378C60.1414 58.8798 57.8396 56.1176 55.3332 53.0997C55.1798 52.9463 54.924 52.6394 54.4125 52.5882C49.0928 52.2813 46.5353 52.2302 41.2156 52.5882C40.7041 52.6394 40.3972 52.9463 40.2949 53.0997C37.7885 56.0665 35.4867 58.8798 35.1798 59.2378C34.7706 59.7494 34.5659 60.3632 34.6171 61.1816V69.0077C34.6171 69.3146 34.8729 69.5703 35.2309 69.5703H39.6299C39.9368 69.5703 40.2437 69.3146 40.2437 69.0077V67.4731C45.2565 67.78 50.3716 67.78 55.3844 67.4731Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M36.9727 61.5396L40.9113 62.0511"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M58.5046 61.5396L54.6172 62.0511"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M36.2539 57.8567C41.7782 58.1124 53.6964 58.1124 59.2207 57.8567"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path d="M67.1484 55.964H71.087" stroke="black" strokeWidth="1.5" strokeMiterlimit="22.926" />
      <path d="M93.543 55.964H97.4816" stroke="black" strokeWidth="1.5" strokeMiterlimit="22.926" />
      <path
        d="M89.9117 67.4731V69.0077C89.9117 69.3146 90.1675 69.5703 90.5255 69.5703H94.9245C95.2314 69.5703 95.5383 69.3146 95.5383 69.0077V61.1816C95.5383 60.3632 95.3337 59.6982 94.9757 59.2378C94.6687 58.8798 92.367 56.1176 89.8606 53.0997C89.7071 52.9463 89.4514 52.6394 88.9399 52.5882C83.6202 52.2813 81.0626 52.2302 75.7429 52.5882C75.2314 52.6394 74.9245 52.9463 74.8222 53.0997C72.3158 56.0665 70.014 58.8798 69.7071 59.2378C69.2979 59.7494 69.0933 60.3632 69.1444 61.1816V69.0077C69.1444 69.3146 69.4002 69.5703 69.7583 69.5703H74.1572C74.4641 69.5703 74.771 69.3146 74.771 69.0077V67.4731C79.7838 67.78 84.8989 67.78 89.9117 67.4731Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M71.5 61.5396L75.3875 62.0511"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M93.032 61.5396L89.1445 62.0511"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M70.7812 57.8567C76.3055 58.1124 88.2237 58.1124 93.748 57.8567"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
      />
      <path
        d="M13.9496 15.5551L12.4662 13.4067H12.3639H10.8293V15.5551H9.03906V7.93359H12.3639C13.0288 7.93359 13.6427 8.0359 14.103 8.29165C14.6145 8.49625 14.9726 8.85431 15.2795 9.26352C15.5352 9.67272 15.6887 10.1842 15.6887 10.7469C15.6887 11.3096 15.5352 11.8211 15.2795 12.2303C15.0237 12.6395 14.6145 12.9464 14.103 13.151L15.791 15.6062H13.9496V15.5551ZM13.4892 9.72388C13.2334 9.51927 12.8242 9.36582 12.2616 9.36582H10.8293V12.0257H12.2616C12.7731 12.0257 13.1823 11.9234 13.4892 11.6676C13.745 11.4119 13.8984 11.1049 13.8984 10.6957C13.8984 10.2865 13.745 9.92848 13.4892 9.72388Z"
        fill="#344154"
      />
      <path
        d="M21.9265 9.97976C22.3868 10.2355 22.7449 10.5936 23.0006 11.0539C23.2564 11.5143 23.3587 12.0258 23.3587 12.6396C23.3587 13.2534 23.2564 13.7649 23.0006 14.2253C22.7449 14.6856 22.3868 15.0437 21.9265 15.2995C21.4661 15.5552 21.0058 15.6575 20.4431 15.6575C19.6758 15.6575 19.1132 15.4018 18.6528 14.9414V17.7035H16.9648V9.67286H18.6017V10.3378C19.0109 9.82631 19.6247 9.57056 20.4431 9.57056C20.9546 9.62171 21.4661 9.72401 21.9265 9.97976ZM21.2104 13.8161C21.4661 13.5092 21.6196 13.1511 21.6196 12.6396C21.6196 12.1281 21.4661 11.77 21.2104 11.4631C20.9546 11.1562 20.5966 11.0028 20.1362 11.0028C19.6758 11.0028 19.3178 11.1562 19.062 11.4631C18.8063 11.77 18.6528 12.1281 18.6528 12.6396C18.6528 13.1511 18.8063 13.5092 19.062 13.8161C19.3178 14.123 19.6758 14.2764 20.1362 14.2764C20.5966 14.2764 20.9035 14.123 21.2104 13.8161Z"
        fill="#344154"
      />
    </svg>
  )
}

export default LayananJualMobilIcon
