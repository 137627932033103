const PersonIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1673 16.5V14.8333C14.1673 13.9493 13.8161 13.1014 13.191 12.4763C12.5659 11.8512 11.718 11.5 10.834 11.5H4.16732C3.28326 11.5 2.43542 11.8512 1.8103 12.4763C1.18517 13.1014 0.833984 13.9493 0.833984 14.8333V16.5M19.1673 16.5V14.8333C19.1668 14.0948 18.9209 13.3773 18.4685 12.7936C18.016 12.2099 17.3824 11.793 16.6673 11.6083M13.334 1.60833C14.051 1.79192 14.6865 2.20892 15.1403 2.79359C15.5942 3.37827 15.8405 4.09736 15.8405 4.8375C15.8405 5.57764 15.5942 6.29673 15.1403 6.88141C14.6865 7.46608 14.051 7.88308 13.334 8.06667M10.834 4.83333C10.834 6.67428 9.3416 8.16667 7.50065 8.16667C5.6597 8.16667 4.16732 6.67428 4.16732 4.83333C4.16732 2.99238 5.6597 1.5 7.50065 1.5C9.3416 1.5 10.834 2.99238 10.834 4.83333Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PersonIcon
