const GaransiServisIcon = ({ className }) => {
  return (
    <svg
      width="84"
      className={className}
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.5586 63.3594C60.9409 65.2197 63.2239 68.8557 63.2239 72.999C63.2239 76.8887 61.1946 80.3555 58.1505 82.3004V79.8482V76.9732C58.1505 75.4512 57.3894 74.0982 56.0365 73.3372C53.6689 71.9843 50.7094 71.9843 48.3417 73.3372C47.0734 74.0982 46.2278 75.4512 46.2278 76.9732V79.7636V82.2158C43.1837 80.271 41.1543 76.8041 41.1543 72.9144C41.1543 68.7711 43.4374 65.2197 46.8197 63.2748V53.4661V20.4039C43.4374 18.5436 41.1543 14.9076 41.1543 10.7643C41.1543 6.87461 43.1837 3.40773 46.2278 1.46289V3.91507V6.79005C46.2278 8.31209 46.9888 9.66502 48.3417 10.426C50.7094 11.779 53.6689 11.779 56.0365 10.426C57.3049 9.66502 58.1505 8.31209 58.1505 6.79005V3.91507V1.54745C61.1946 3.49228 63.2239 6.95916 63.2239 10.8488C63.2239 14.9922 60.9409 18.5436 57.5586 20.4884V23.5325"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.0692 39.8527H31.6833C30.0767 39.8527 28.4701 39.2608 27.2863 38.1615L24.2422 35.3711"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9824 46.7852L20.5228 47.969C22.6367 48.3072 24.2433 50.1675 24.2433 52.2814V54.4799H11.9824"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.38477 41.543V62.006"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.38634 41.5446L2.00402 37.824C1.58123 37.4858 1.41211 36.8939 1.41211 36.3865V32.3277C1.41211 31.313 2.25769 30.4675 3.35694 30.4675H11.1363L17.8164 17.6146C19.1693 15.8389 21.1987 14.8242 23.3972 14.8242H37.2647"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8154 62.0059V62.7669C17.8154 66.2338 15.025 69.0242 11.5581 69.0242C8.0912 69.0242 5.30078 66.2338 5.30078 62.7669V62.0059"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.0704 62.0059H11.9824"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8262 54.4805H40.7305"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1543 30.4668H41.4923"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8592 19.6431L17.1387 26.8306H37.2635V18.5439H23.396C22.4658 18.4593 21.5357 18.8821 20.8592 19.6431Z"
        fill="#E12B25"
      />
      <path
        d="M61.4492 41.7986L66.6918 46.4492L74.2175 37.2324"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.4187 29.7064C73.1173 30.8057 67.3674 25.4785 67.3674 25.4785C67.3674 25.4785 61.6174 30.8057 52.316 29.7064C52.316 29.7064 49.4411 54.3128 67.3674 59.64C85.2937 54.3128 82.4187 29.7064 82.4187 29.7064Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5586 60.3145V63.3585"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GaransiServisIcon
