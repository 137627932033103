import { Row } from 'antd'
import BirdmobilCertifiedSection from 'components/section/birdmobil-certified-section'
import FAQSection from 'components/section/faq-section'
// import TestimonySection from 'components/section/testimony-section'
import { getBanners, getCatalog } from 'features/beli-mobil/service'
import {
  AboutUsSection,
  HowItWorksSection,
  IconNavFeaturesSection,
  OurServiceSection,
  PickMenuSection,
  ProductSection,
  WhyUsSection
} from 'features/landing-page'
import { useWishlist } from 'hooks'

import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

function LandingPage() {
  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL
  const { push } = useHistory()
  const { handleWishlist, wishlistIds } = useWishlist()
  const [cars, setCars] = useState([])
  const [banners, setBanners] = useState([])
  useQuery({
    queryKey: ['getCatalog'],
    queryFn: () => getCatalog({ limit: 4, offset: 0 }),
    onSuccess: (data) => {
      setCars(data)
    }
  })
  useQuery({
    queryKey: 'getBanners',
    queryFn: () => getBanners(),
    onSuccess: (data) => {
      const newData = data.map(banner => ({
        ...banner,
        image: imgUrl.concat(banner?.image_filename)
      }))
      // setBanners([...data])
      setBanners([...newData])
    }
  })
  function handleNavigation(path) {
    return push(path)
  }
  return (
    <Row>
      <IconNavFeaturesSection handleNavigation={handleNavigation} banners={banners} />
      <PickMenuSection banners={banners} />
      <ProductSection
        handleWishlist={handleWishlist}
        cars={cars}
        wishlistIds={wishlistIds}
        handleNavigation={handleNavigation}
      />
      {/* <CalculatedDataSection /> */}
      <AboutUsSection />
      <OurServiceSection />
      {/* <TestimonySection /> */}
      <HowItWorksSection />
      <WhyUsSection handleNavigation={handleNavigation} />
      <BirdmobilCertifiedSection />
      <FAQSection />
    </Row>
  )
}

export default LandingPage
