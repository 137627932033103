const InteriorIcon = ({ className }) => {
  return (
    <svg
      width="61"
      className={className}
      height="63"
      viewBox="0 0 61 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.0756 60.6614C36.606 59.6999 39.4992 55.2478 38.5377 50.7174C37.5763 46.1869 33.1242 43.2937 28.5937 44.2552C24.0633 45.2167 21.1701 49.6687 22.1315 54.1992C23.093 58.7296 27.5451 61.6229 32.0756 60.6614Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8242 51.9636C26.8242 51.9636 29.8322 54.9716 30.0145 55.1539L34.6176 50.5508"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6738 52.192C46.5128 50.7336 53.6226 47.3154 59.5019 42.4844"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.11914 42.4844C6.99839 47.361 14.1538 50.7336 21.9472 52.192"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6951 27.7607H39.3574V26.4846C39.3574 25.1629 40.3145 24.0691 41.5906 23.8868L46.6951 23.2031"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4219 23.2031L17.5263 23.8868C18.8025 24.0691 19.7595 25.1629 19.7595 26.4846V27.7607H12.4219"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.45703 20.0586V32.2273"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.8223 6.79688L47.286 13.4053H51.9347C52.5728 13.4053 53.1197 13.9522 53.1197 14.5903V17.0058C53.1197 17.3248 52.983 17.6439 52.7551 17.8718L50.6586 20.0594V32.2281"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.45652 20.0606L6.40562 17.873C6.17774 17.6451 6.04102 17.3261 6.04102 17.0071V14.5916C6.04102 13.9535 6.58792 13.4066 7.22598 13.4066H11.8747L15.0194 7.39062"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.2305 32.2734V32.7292C43.2305 34.7801 44.9168 36.4664 46.9677 36.4664C49.0186 36.4664 50.7049 34.7801 50.7049 32.7292V32.2734"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8865 32.2734V32.7292C15.8865 34.7801 14.2002 36.4664 12.1493 36.4664C10.0984 36.4664 8.41211 34.7801 8.41211 32.7292V32.2734"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6947 32.2734H30.2875H12.4219"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6406 27.7617H32.4743"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.558 13.4056H16.7057L14.1535 4.29052C13.7889 2.92325 14.7915 1.60156 16.2044 1.60156H29.3302H42.4559C43.8688 1.60156 44.8715 2.96883 44.5068 4.29052L41.9546 13.4056H29.558Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7052 13.4062L14.6543 17.3258V19.5134H22.1287"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.9087 13.4062L43.9596 17.3258V19.5134H22.1289"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5449 19.5145V17.418C24.5449 16.9167 24.9551 16.4609 25.502 16.4609H33.66C34.1614 16.4609 34.6171 16.8711 34.6171 17.418V19.5145"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M42.3648 3.78906H16.25L17.2982 7.48069H41.3166L42.3648 3.78906Z" fill="#E12B25" />
    </svg>
  )
}

export default InteriorIcon
