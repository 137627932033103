import { Col, Divider, Layout, Row } from 'antd'
import {
  AvatarIcon,
  ChartIcon,
  ClockIcon,
  DashboardIcon,
  GearedIcon,
  PersonIcon,
  HeartIcon
} from 'assets'
import Header from 'components/header/header'
import React from 'react'
import { NavLink } from 'react-router-dom'
const navigationDashboard = [
  {
    title: 'Dashboard',
    accountType: ['customer', 'company'],
    url: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Transaksi',
    accountType: ['customer', 'company'],
    url: '/transaction',
    icon: <ClockIcon />
  },
  { title: 'Wishlist', accountType: ['customer'], url: '/wishlist', icon: <HeartIcon /> },
  {
    title: 'Report',
    accountType: ['company'],
    url: '/report',
    icon: <ChartIcon />
  },
  {
    title: 'Users',
    accountType: ['company'],
    url: '/users',
    icon: <PersonIcon />
  }
]

function DashboardLayout({ children }) {
  const accountType = localStorage.getItem('account_type')
  const userName = localStorage.getItem('user_name')
  return (
    <Layout>
      <Header />
      <Row className="relative bg-gray-50  min-h-[calc(100vh-96px)]">
        {/* Sidebar */}
        <Col
          span={5}
          className="h-screen hidden lg:block w-screen fixed top-[96px] left-0 bg-white p-6">
          <div className="grid grid-cols-1 place-content-center">
            {/* Profile */}
            <div className="flex items-center justify-between gap-2">
              {/* Avatar Container */}
              <div className="flex items-center gap-3">
                <div className="rounded-full h-12 w-12 p-3 flex justify-center items-center bg-primary-50">
                  <AvatarIcon />
                </div>
                <span className="text-md-medium text-gray-700">{userName}</span>
              </div>
              <NavLink
                to="/profile"
                className={`stroke-gray-400 hover:stroke-primary-500`}
                activeClassName={'text-primary-500 stroke-primary-500'}>
                <GearedIcon />
              </NavLink>
            </div>
          </div>
          <Divider className="my-3" />
          {/* Nav */}
          <div className="grid grid-cols-1 gap-2 mt-6 ">
            {navigationDashboard.map((nav, key) => {
              return (
                nav.accountType.includes(accountType) && (
                  <NavLink
                    key={key}
                    to={nav.url}
                    className="flex items-center p-3 rounded-[4px] hover:bg-gray-100 gap-2 text-md-medium stroke-gray-500 text-gray-700 hover:text-primary-500 hover:stroke-primary-500"
                    activeClassName={'text-primary-500 stroke-primary-500'}>
                    {nav.title !== 'Transaksi' ? (
                      <>
                        {nav.icon}
                        {nav.title}
                      </>
                    ) : (
                      <div className="flex w-full justify-between items-center">
                        <div className="flex gap-2 items-center">
                          {nav.icon}
                          {nav.title}
                        </div>
                        {/* Badge Jumlah Status Berlangsung */}
                        {/* <div className="rounded-full flex text-xs-regular items-center justify-center bg-primary-50 text-primary-500 px-2">
                          5
                        </div> */}
                      </div>
                    )}
                  </NavLink>
                )
              )
            })}
          </div>
        </Col>
        {/* Content */}
        <Col lg={{ offset: 5, span: 19 }} offset={0} span={24} className="w-screen p-4 lg:p-6">
          <div className="mx-auto">{children}</div>
        </Col>
      </Row>
    </Layout>
  )
}

export default DashboardLayout
