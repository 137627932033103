import { ChecklistIcon } from 'assets'
import CustomButton from 'components/ui/custom-button';
import { useHistory } from 'react-router-dom';
function FormSuccess() {
  const { push } = useHistory();
  const handleClick = () => push('/login')
  return (
    <>
      <div className="text-gray-700 flex flex-col items-center space-y-3">
        <div className='rounded-full w-[56px] h-[56px] bg-success-100 border-8 border-success-50 flex items-center justify-center'>
          <ChecklistIcon className="h-6 w-6" />
        </div>
        <p className='text-lg-medium'>Password Anda berhasil diubah!</p>
      </div>
      <CustomButton label="Login" onClick={handleClick} />
    </>
  )
}

export default FormSuccess
