import React from 'react'

const CardLandingGradient = ({
  children,
  containerClassName,
  parentContainerClassName,
  gradient = false
}) => {
  return (
    <div
      className={`relative p-6 overflow-hidden bg-white border border-solid border-gray-200 rounded-[4px] ${
        parentContainerClassName ? parentContainerClassName : ''
      }`}>
      {gradient ? (
        <div className=" z-[0] absolute -right-28 -top-52 h-[254px] w-[254px] bg-primary-400 rounded-full flex justify-center items-center">
          <div className="h-[184px] w-[184px] opacity-50 bg-primary-500 rounded-full flex justify-center items-center">
            <div className="h-[128px] w-[128px] opacity-50 bg-primary-500 rounded-full"></div>
          </div>
        </div>
      ) : null}
      <div className={`z-[100] relative ${containerClassName ? containerClassName : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default CardLandingGradient
