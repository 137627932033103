/* eslint-disable no-undef */
import API from 'services'

const getAllFuels = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/fuels/all`
  )
  return response.data
}
const getAllTransmision = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/transmissions/all`
  )
  return response.data
}
const getAllCategories = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/categories/all`
  )
  return response.data
}
const getAllModels = async (brandId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/models/all?brand_id=${brandId}`
  )
  return response.data
}
const getAllColors = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/colors/all`
  )
  return response.data
}
const getAllUnitClasses = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/classes/all`
  )
  return response.data
}
const getAllBrands = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/brands/all`
  )
  return response.data
}
const getAllTypes = async (modelId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/types/all?model_id=${modelId}`
  )
  return response.data
}
const getCategoriesByTypes = async (typeId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/types/${typeId}`
  )
  return response.data
}

export {
  getAllBrands,
  getAllCategories,
  getAllColors,
  getAllFuels,
  getAllModels,
  getAllTransmision,
  getAllTypes,
  getAllUnitClasses,
  getCategoriesByTypes
}
