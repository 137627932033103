import { Divider } from 'antd'
import { ChevronRightIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import { paymentList } from 'features/beli-mobil/data/paymentList'
import { ModalLayout } from 'layouts'
import React, { useRef, useState } from 'react'
import CustomButton from '../custom-button'
import FormInput from '../form/form-input'
import { onRupiah } from 'utils/formatCurrency'

const inputVoucher = {
  label: 'Kode Voucher',
  name: 'voucher_code',
  type: 'text',
  placeholder: 'Masukan Kode Voucher'
}

const CardPayment = ({
  data,
  selectedPayment,
  handleSelectedPayment,
  inspeksi,
  disableVoucher,
  isShowRoom,
  price,
  taxPrice,
  totalPrice
}) => {
  // console.log(price, taxPrice)
  const modalType = useRef('metode-pembayaran')
  const [modalOpen, setModalOpen] = useState(false)

  const handlePayment = (id) => {
    handleSelectedPayment(id)
    setModalOpen(false)
  }
  const handleClickPaymentMethod = () => {
    modalType.current = 'metode-pembayaran'
    setModalOpen(true)
  }
  const handleClickVoucher = () => {
    modalType.current = 'apply-voucher'
    setModalOpen(true)
  }

  return (
    <>
      <CardSectionHeader headerTitle="Pembayaran">
        <div className="grid grid-cols-1 gap-5">
          {/* Rincian Pembayaran */}
          <div className="grid grid-cols-1 gap-4">
            <p className="text-md-medium text-gray-900">Rincian Pembayaran</p>
            {/* List Rincian Pembayaran */}
            <div className=" flex justify-between items-center gap-4">
              <p className="text-md-regular text-gray-500">
                {inspeksi ? 'Biaya Inspeksi' : 'Booking Mobil'}
              </p>
              <p className="text-md-medium text-gray-700">
                Rp {price?.inspection ? onRupiah(price?.inspection) : onRupiah(price?.bookingPrice)}
              </p>
            </div>
            {!isShowRoom && data?.range_price?.price !== undefined && (
              <div className="flex justify-between items-center pb-2">
                <p className="text-md-regular text-gray-500">Biaya Jarak</p>
                <p className="text-md-medium text-gray-700">
                  Rp.{' '}
                  {data?.range_price?.price !== undefined
                    ? onRupiah(data?.range_price?.price)
                    : '-'}
                </p>
              </div>
            )}
            {price?.admin_fee !== 0 && (
              <div className=" flex justify-between items-center gap-4">
                <p className="text-md-regular text-gray-500">Biaya Admin</p>
                <p className="text-md-medium text-gray-700">Rp. {onRupiah(price?.admin_fee)}</p>
              </div>
            )}
            <div className=" flex justify-between items-center gap-4">
              <p className="text-md-regular text-gray-500">Pajak {price?.tax}%</p>
              <p className="text-md-medium text-gray-700">Rp. {onRupiah(taxPrice)}</p>
            </div>
            {/* Total Tagihan */}
            <Divider className="m-0 text-gray-300" />
            <div className="p-4 flex justify-between items-center bg-gray-50 rounded-[4px]">
              <p className="text-md-regular text-gray-600">Total Tagihan</p>
              <p className="text-md-medium text-gray-700">Rp {onRupiah(totalPrice)}</p>
            </div>
          </div>
          {/* Metode Pembayaran */}
          <div className="grid grid-cols-1 gap-4">
            <p className="text-md-medium text-gray-900">Metode Pembayaran</p>
            <div
              onClick={handleClickPaymentMethod}
              className="cursor-pointer p-4 flex justify-between items-center bg-gray-50 rounded-[4px]">
              {selectedPayment ? (
                <>
                  <div className="flex gap-4 items-center">
                    <img src={selectedPayment.image} className="w-14 object-contain" alt="" />
                    <p className="text-md-medium text-gray-700">{selectedPayment.title}</p>
                  </div>
                </>
              ) : (
                <p className="text-sm-regular text-gray-500">Pilih Metode Pembayaran</p>
              )}
              <div>{<ChevronRightIcon className={'stroke-gray-700'} />}</div>
            </div>
            {/* voucher */}
            {!disableVoucher && (
              <div
                onClick={handleClickVoucher}
                className="cursor-pointer p-4 flex justify-between items-center bg-gray-100 rounded-[4px]">
                <p className="text-md-regular text-gray-500">
                  %<span className="ml-3">Apply Kode Voucher</span>
                </p>
                <div>{<ChevronRightIcon className={'stroke-gray-700'} />}</div>
              </div>
            )}
          </div>
        </div>
      </CardSectionHeader>
      <ModalLayout
        openModal={modalOpen}
        headerTitle={
          modalType.current === 'metode-pembayaran' ? `Pilih Pembayaran` : `Apply Voucher`
        }
        className="w-full max-w-md"
        onCancel={() => setModalOpen(false)}>
        {modalType.current === 'metode-pembayaran' ? (
          <div className="grid grid-cols-1 gap-8 overflow-y-scroll h-full max-h-96 scroll">
            {paymentList.map(({ id, groupTitle, options }) => (
              <div key={id} className="grid grid-cols-1">
                {groupTitle ? (
                  <p className="py-4 text-xl-medium text-gray-700 border-b border-gray-200">
                    {groupTitle}
                  </p>
                ) : null}
                <div className="grid grid-cols-1">
                  {options.map(({ id, image, title }) => (
                    <div
                      onClick={handlePayment.bind(this, id)}
                      key={id}
                      className=" cursor-pointer hover:bg-gray-50 flex py-4 border-b justify-between px-2 items-center border-gray-200">
                      <div className="flex gap-4 items-center">
                        <img src={image} className="w-14 object-contain" alt="bankImage" />
                        <p className="text-md-medium text-gray-700">{title}</p>
                      </div>
                      <div>
                        <ChevronRightIcon className={'stroke-gray-700'} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-[80vw] md:w-[452px] grid grid-cols-1 gap-5">
            <FormInput data={inputVoucher} />
            <div className="flex justify-end items-center gap-2">
              <CustomButton type="plain" label={'Batal'} onClick={() => setModalOpen(false)} />
              <CustomButton label={'Apply'} onClick={() => setModalOpen(false)} />
            </div>
          </div>
        )}
      </ModalLayout>
    </>
  )
}

export default CardPayment
