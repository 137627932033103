import { BadgeIconSuccess } from 'assets/icons/badge-icons'
import React from 'react'
import BadgeItem from '../badge-item'
import CardSectionHeader from '../card-section-header'

function CardInspectionDetail({ data, title }) {
	return (
		<CardSectionHeader headerTitle={title}>
			<div className="grid grid-cols-2 grid-rows-1 gap-6">
				{
					data.map((item, index) => (
						<div className='flex justify-between items-center' key={index}>
							<p className="text-sm-regular text-gray-600">{item.label}</p>
							<BadgeItem type='success' >
								<div className='flex items-center space-x-2'>
									<BadgeIconSuccess className="h-3 w-3" />
									<p>Lulus</p>
								</div>
							</BadgeItem>
						</div>
					))
				}
			</div>
		</CardSectionHeader>
	)
}

export default CardInspectionDetail
