import { ModalLayout } from 'layouts'
import { useEffect } from 'react'
import { useState } from 'react'
import CustomButton from '../custom-button'
import FormInput from '../form/form-input'

const ModalAddUser = ({ onCancel, openModal, onClick, userKeyword, handleChangeKeyword }) => {
  const inputEmail = {
    id: 1,
    label: 'Email',
    name: 'email',
    type: 'text',
    placeholder: 'Masukan Email',
    onChange: (e) => handleChangeKeyword(e.target.value),
    value: userKeyword
  }
  const inputPhone = {
    id: 2,
    label: 'Nomor Telepon',
    name: 'phone',
    type: 'text',
    placeholder: 'Masukan Nomor Telepon',
    addonBefore: '+62',
    onChange: (e) => handleChangeKeyword(e.target.value),
    value: userKeyword
  }
  // const [value, setValue] = useState('')
  const [isEmail, setIsEmail] = useState(true)
  useEffect(() => {
    handleChangeKeyword('')
  }, [isEmail])
  return (
    <ModalLayout
      headerTitle={'Tambah User'}
      onCancel={onCancel}
      openModal={openModal}
      className="w-[400px]">
      <div className="grid grid-cols-1 gap-5">
        <p className="text-md-medium text-gray-500">
          Tambahkan email user untuk menambahkan user kedalam akun perusahaan.
        </p>
        <div>
          <p className="text-md-medium text-gray-500 mb-2">Tambahkan user berdasarkan</p>
          <div className="grid grid-cols-2 text-center text-md-medium text-gray-900 border-gray-300 rounded-lg border border-solid overflow-hidden">
            <div
              className={`py-2.5 cursor-pointer ${isEmail ? 'bg-gray-300' : 'bg-white'}`}
              onClick={() => setIsEmail(true)}>
              Email
            </div>
            <div
              className={`py-2.5 cursor-pointer ${!isEmail ? 'bg-gray-300' : 'bg-white'}`}
              onClick={() => setIsEmail(false)}>
              Nomor Telepon
            </div>
          </div>
        </div>
        <FormInput data={isEmail ? inputEmail : inputPhone} />
        <div className="flex justify-end items-center gap-2">
          <CustomButton type="plain" label={'Batal'} onClick={onCancel} />
          <CustomButton label={'Konfirmasi'} onClick={onClick} />
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalAddUser
