import { Col, Row } from 'antd'
import {
  BookingServisIcon,
  FileTextIcon,
  ListIcon,
  RadioIcon,
  RekomendasiServisIcon,
  ServisImage,
  TerkoneksiIcon
} from 'assets'
import SectionTitle from 'components/ui/section-title'
import React from 'react'
const benefitData = [
  {
    label: 'Booking Servis',
    description: 'Anda bisa memesan waktu service menyesuaikan dengan kesibukan Anda',
    icon: <BookingServisIcon />
  },
  {
    label: 'Terkoneksi',
    description:
      'Setiap langkah pengerjaan yang dilakukan oleh tim Kami dan terlacak di situs Kami',
    icon: <TerkoneksiIcon />
  },
  {
    label: 'Rekomendasi Servis',
    description: 'Kami memiliki rekomendasi jenis servis yang bisa Anda lakukan selanjutnya',
    icon: <RekomendasiServisIcon />
  }
]
const BenefitServiceSection = () => {
  return (
    <Row className="bg-white py-20 ">
      <Col span={22} lg={{ span: 20 }} className="mx-auto gap-12 grid grid-cols-1">
        <SectionTitle title={'Apa Yang Kami Berikan Jika Servis di BirdMobil?'} />
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
          {/* Left Content */}
          <ol className="flex flex-col gap-8">
            {benefitData.map((data, key) => (
              <li key={key} className="flex gap-4 items-center">
                {/* Icon */}
                <div className="relative flex justify-center items-center h-[70px] object-contain">
                  {data.icon}
                </div>
                {/* Title & Description */}
                <div className="flex flex-col gap-2">
                  <p className="text-md-semibold md:text-lg-semibold text-gray-900">{data.label}</p>
                  <p className="text-sm-regular text-gray-500">{data.description}</p>
                </div>
              </li>
            ))}
          </ol>
          {/* Right Content */}
          <img src={ServisImage} alt="" />
        </div>
      </Col>
    </Row>
  )
}

export default BenefitServiceSection
