import CustomButton from 'components/ui/custom-button'
import { useState, useRef } from 'react'
import Countdown from 'react-countdown'
import OtpInput from './component/otp-input'
import { birdmobilLogo } from 'features/auth/assets'

function AuthOtp({ title, handleSubmit, handleBack, handleResend }) {
    const [otp, setOtp] = useState('')
    const ref = useRef(Date.now())
    const [countdown, setCountdown] = useState({
        key: 1,
        show: true
    })
    const time = parseInt(process.env.REACT_APP_OTP_TIME)
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            setCountdown(prev => ({
                ...prev,
                show: false
            }))
        } else {
            // Render a countdown
            return (
                <span>
                    {minutes}:{seconds}
                </span>
            );
        }
    };

    const onChange = (value) => setOtp(value)

    const handleClickResend = (e) => {
        e.preventDefault()
        ref.current = Date.now()
        setCountdown(prev => ({
            key: prev + 1,
            show: true
        }))
        handleResend()
    }

    const handleCancel = () => {
        handleBack()
    }

    const handleClick = () => {
        handleSubmit(otp)
    }

    return (
        <div className="flex flex-col gap-6 text-center">
            <div className='flex flex-col items-center space-y-4'>
                <img src={birdmobilLogo} alt="" />
                <p className="text-gray-900 text-sm-semibold text-3xl">{title}</p>
            </div>
            <div className="text-gray-700 flex flex-col space-y-6">
                <p className="text-xl-medium mb-2">Masukan Kode Verifikasi</p>
                <OtpInput value={otp} valueLength={6} onChange={onChange} />
                <p className="text-xs-medium text-gray-500 mb-[6px]">
                    Tidak menerima email kode OTP?{' '}
                    {
                        countdown.show ?
                            <span className='text-sm-medium text-gray-300'>
                                Kirim ulang dalam{' '}
                                {/* <Countdown key={countdown.key} date={Date.now() + time} zeroPadTime={3} renderer={renderer} /> */}
                                <Countdown key={countdown.key} date={ref.current + time} zeroPadTime={3} renderer={renderer} />
                            </span> :
                            <a onClick={handleClickResend} className="text-primary-500 hover:text-primary-500">
                                Kirim ulang kode verifikasi
                            </a>
                    }
                </p>
            </div>
            <div className='grid grid-cols-2 gap-2'>
                <CustomButton label="Kembali" type='plain' onClick={handleCancel} />
                <CustomButton label="Lanjutkan" onClick={handleClick} />
            </div>
        </div>
    )
}

export default AuthOtp
