import React, { useState } from 'react'

const usePreviewImage = () => {
  const [openModalImage, setOpenModalImage] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const handleCloseModalImage = () => {
    setOpenModalImage(false)
  }
  const handleOpenModalImage = (imageUrl) => {
    setPreviewImage(imageUrl)
    setOpenModalImage(true)
  }
  return {
    openModalImage,
    previewImage,
    handleOpenModalImage,
    handleCloseModalImage
  }
}

export default usePreviewImage
