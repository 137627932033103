import { Col, Row } from 'antd'
import { CheckIcon } from 'assets'
import React from 'react'

const benefitData = [
  {
    label: 'Mudah menjual mobil Anda'
  },
  {
    label: 'Beli mobil Berkualitas'
  },
  {
    label: 'Terpercaya dalam Inspeksi mobil'
  },
  {
    label: 'Andal dalam Perawatan mobil'
  }
]

function SignInContent() {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <Col span={16}>
        <Row>
          <Col span={24} className="grid grid-cols-1 gap-6 ">
            <div className="grid grid-cols-1 gap-2 text-white text-center">
              <p className="display-xs-semibold ">BirdMobil mempermudahmu</p>
              <p className="text-xl-regular">Solusi Kebutuhan mobil Anda dalam Satu Tempat</p>
            </div>
            <div className="grid grid-cols-1 gap-4 mx-auto">
              {benefitData.map((data, key) => (
                <div
                  key={key}
                  className="flex gap-3 px-2 py-2 bg-white/10 w-full justify-center items-center text-white text-xl-regular">
                  <CheckIcon className={'w-3 h-3 fill-white'} /> {data.label}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default SignInContent
