import API from 'services'
const getAllFuels = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/fuels/all`
  )
  return response.data
}

const getAllTransmision = async () => {
  const { data: response } = await API.get(

    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/transmissions/all`
  )
  return response.data
}
const getAllCategories = async () => {
  const { data: response } = await API.get(

    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/categories/all`
  )
  return response.data
}
const getAllModels = async (id) => {
  const { data: response } = await API.get(

    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/models/all?brand_id=${id}`
  )
  return response.data
}
const getAllColors = async () => {
  const { data: response } = await API.get(

    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/colors/all`
  )
  return response.data
}
const getAllUnits = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/customer-site/units/sell/all`);
  return response.data;
}
const getDetailUnit = async (id) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/${id}`
  )
  return response.data
}
const getAllUnitClasses = async () => {
  const { data: response } = await API.get(

    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/classes/all`
  )
  return response.data
}
const getAllBrands = async () => {
  const { data: response } = await API.get(

    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/brands/all`
  )
  return response.data
}
const getAllTypes = async (id) => {
  const { data: response } = await API.get(

    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/types/all?model_id=${id}`
  )
  return response.data
}
const getWorkshops = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/workshops/all?keywords=&sort_by=name&order_by=asc`);
  return response.data;
}

const getTimes = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit/schedules`);
  return response.data;
}
const getPriceRecommendation = async (type_id, transmission_id, year) => {
  const { data: response } = await API.get(

    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/catalogs/price-recommendation?type_id=${type_id}&transmission_id=${transmission_id}&year=${year}`
  )
  return response.data
}
const postSellCar = async (args) => {
  const { data: response } = await API.post(`${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/v2/catalogs/sell`,
    args.body,
    {
      headers: {
        'Authorization': `Bearer ${args.token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  return response.data;
}

const getProvinces = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/provinces/all`);
  return response.data;
}

const getCities = async (provinceId) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/cities/province/${provinceId}`);
  return response.data;
}

const getSubdistricts = async (cityId) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/subdistricts/city/${cityId}`);
  return response.data;
}

const getRangePrice = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/admin/inspection-distance-prices/all`);
  return response.data;
}

// 2 untuk biaya inspeksi
const getServicePrice = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/configurations/service-prices/2`);
  return response.data;
}
// 3 untuk biaya admin
const getAdminPrice = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/configurations/service-prices/3`);
  return response.data;
}
const getTaxPrice = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/configurations/taxes/1`);
  return response.data;
}


export {
  getAllBrands,
  getAllCategories,
  getAllColors,
  getAllFuels,
  getAllModels,
  getAllUnits,
  getDetailUnit,
  getAllTransmision,
  getAllTypes,
  getAllUnitClasses,
  getWorkshops,
  getTimes,
  getPriceRecommendation,
  postSellCar,
  getProvinces,
  getCities,
  getSubdistricts,
  getRangePrice,
  getServicePrice,
  getAdminPrice,
  getTaxPrice
}