import { Space } from 'antd'
import React from 'react'
import { NavLink } from 'react-router-dom'

function NavItem({ label, className, ...props }) {
  return (
    <NavLink
      className={'text-sm-medium xl:text-md-medium ' + className}
      activeClassName={'text-primary-500'}
      {...props}>
      <Space className=" whitespace-nowrap">{label}</Space>
    </NavLink>
  )
}

export default NavItem
