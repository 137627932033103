const LayananBeliMobilIcon = ({ className }) => {
  return (
    <svg
      width="81"
      className={className}
      height="82"
      viewBox="0 0 81 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.2784 63.0325C49.2784 71.4356 42.5149 78.199 34.1118 78.199C25.7088 78.199 18.9453 71.4356 18.9453 63.0325C18.9453 54.6294 25.7088 47.866 34.1118 47.866C42.5149 47.866 49.2784 54.6977 49.2784 63.0325Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.6727 47.8659V34.2707C65.6727 32.9043 64.5796 31.7429 63.1449 31.7429C61.7103 31.7429 60.6172 32.836 60.6172 34.2707V51.965"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.6606 47.866V43.9718C70.6606 42.6055 69.5675 41.4441 68.1328 41.4441"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.6489 47.8659V45.6797C75.6489 44.3134 74.5558 43.152 73.1211 43.152"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.6028 72.4603C76.5345 67.883 76.8761 66.9949 76.8761 66.9949C76.8761 66.9949 77.081 65.8335 77.5592 64.3988C78.9939 59.8898 79.8137 55.1759 79.9503 50.462V48.3442C79.9503 46.9778 78.8573 45.8164 77.4226 45.8164"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4312 45.5431C57.8847 44.9282 57.0649 44.5183 56.1767 44.5183C54.6738 44.5183 53.9223 45.3381 53.649 46.1579C53.649 46.2263 53.5807 46.2946 53.5807 46.3629C53.3757 47.1827 53.5124 48.0708 53.8539 48.8906C54.4688 50.3253 55.3569 53.1947 54.8787 57.0204C54.6054 59.2066 55.0837 61.4611 56.3817 63.3057C57.2015 64.4671 58.158 65.7651 59.1827 66.9948C59.1827 66.9948 61.6422 69.4543 61.6422 72.4602"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.4247 45.8846H75.6484"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.1195 43.152H70.5234"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.1352 41.5125H65.6758"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.7734 80.9999V73.8949C58.7734 73.1434 59.3883 72.4602 60.2081 72.4602H78.039C78.7905 72.4602 79.4737 73.0751 79.4737 73.8949V80.9999"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9792 18.1478L45.5197 20.4023C44.495 21.2904 43.2652 21.837 41.8989 21.837H35.2038H26.3225C24.9561 21.837 23.6581 21.3588 22.7016 20.4023L20.2422 18.1478"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5426 33.9291H47.3633V32.1528C47.3633 30.3766 48.6613 28.8053 50.4376 28.532L57.4743 27.5756"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0625 27.6439L17.0992 28.6004C18.8755 28.8736 20.1735 30.3766 20.1735 32.2212V33.9975H10.0625"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.59766 23.2716V40.1461"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.59757 23.2716L1.79654 20.1973C1.45495 19.8557 1.25 19.4458 1.25 18.9675V15.62C1.25 14.7319 2.00149 13.9804 2.88962 13.9804H9.31149L14.8452 3.3228C15.9383 1.88813 17.6462 1 19.4908 1H33.8375H48.1842C50.0288 1 51.7368 1.81981 52.8298 3.3228L58.2953 14.0487H64.7171C65.6052 14.0487 66.3567 14.8002 66.3567 15.6883V19.0359C66.3567 19.5141 66.1518 19.924 65.8102 20.2656L63.0092 23.3399V28.6687"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8453 40.1461V40.761C14.8453 43.6303 12.5225 45.8848 9.72148 45.8848C6.85214 45.8848 4.59766 43.562 4.59766 40.761V40.1461"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5433 40.1461H34.7935H10.0625"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7383 33.9291H37.8681"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.114 5.37231H33.7672H19.4205C19.0106 5.37231 18.6007 5.50895 18.3275 5.85054L14.0234 14.117H53.5111L49.207 5.85054C48.9338 5.50895 48.5239 5.37231 48.114 5.37231Z"
        fill="#E12B25"
      />
      <path
        d="M32.1979 66.1067L30.5583 63.7156H30.4899H28.782V66.1067H26.8008V57.567H30.4899C31.2414 57.567 31.9246 57.7037 32.4711 57.9769C33.0177 58.2502 33.4276 58.5918 33.7692 59.07C34.0425 59.5482 34.2474 60.0948 34.2474 60.7096C34.2474 61.3245 34.1108 61.871 33.7692 62.3493C33.4959 62.8275 33.0177 63.1691 32.4711 63.374L34.384 66.1067H32.1979ZM31.7197 59.6165C31.3781 59.3433 30.9682 59.2066 30.3533 59.2066H28.7137V62.1443H30.3533C30.9682 62.1443 31.3781 62.0077 31.7197 61.7344C32.0612 61.4611 32.1979 61.1195 32.1979 60.6413C32.1979 60.2314 31.9929 59.8215 31.7197 59.6165Z"
        fill="#344154"
      />
      <path
        d="M41.147 59.8896C41.6252 60.1629 42.0351 60.5728 42.3084 61.051C42.5817 61.5293 42.7183 62.1441 42.7183 62.8273C42.7183 63.5105 42.5817 64.057 42.3084 64.6036C42.0351 65.0818 41.6252 65.4917 41.147 65.765C40.6688 66.0382 40.1222 66.1749 39.5074 66.1749C38.6876 66.1749 38.0044 65.9016 37.5262 65.3551V68.4294H35.6133V59.5481H37.4579V60.2995C37.9361 59.753 38.6193 59.4797 39.5074 59.4797C40.0539 59.4797 40.6005 59.6164 41.147 59.8896ZM40.3272 64.1937C40.6688 63.8521 40.8054 63.4422 40.8054 62.8956C40.8054 62.3491 40.6688 61.8709 40.3272 61.5976C39.9856 61.256 39.644 61.1194 39.0975 61.1194C38.5509 61.1194 38.2094 61.256 37.8678 61.5976C37.5262 61.9392 37.3895 62.3491 37.3895 62.8956C37.3895 63.4422 37.5262 63.9204 37.8678 64.1937C38.2094 64.5352 38.5509 64.6719 39.0975 64.6719C39.644 64.6719 39.9856 64.4669 40.3272 64.1937Z"
        fill="#344154"
      />
    </svg>
  )
}

export default LayananBeliMobilIcon
