import { Col } from 'antd'
import React from 'react'

const calculatedData = [
  { label: 'Bengkel', total: '140.203' },
  { label: 'Pelanggan Puas', total: '21.678' },
  { label: 'Mobil telah dirawat', total: '11.092' },
  { label: 'Mobil Terjual', total: '240.902' },
  { label: 'Dealer', total: '20.200' }
]

function CalculatedDataSection() {
  return (
    <Col span={24} lg={{ order: 2 }} className="bg-gray-50 py-9 lg:py-20">
      <Col span={20} className="mx-auto grid grid-cols-3 gap-3 lg:gap-0 lg:flex justify-between">
        {calculatedData.map((data, key) => (
          <div className="flex flex-col items-center" key={key}>
            <p className="text-xl-semibold md:display-md-semibold text-primary-500">{data.total}</p>
            <p className="text-xs-regular md:text-md-regular text-gray-700">{data.label}</p>
          </div>
        ))}
      </Col>
    </Col>
  )
}

export default CalculatedDataSection
