import { Col } from 'antd'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
import { NavLink } from 'react-router-dom'

function PartnerSection() {
  return (
    <>
      <Col span={24} className="py-20 bg-white">
        <Col
          span={22}
          className=" bg-[url('features/about-us/assets/images/background-title-aboutus.webp')] py-16 mx-auto gap-6 flex flex-col">
          {/* Title */}
          <div className="flex flex-col items-center justify-center gap-2 text-center">
            <p className="display-xs-semibold lg:display-sm-semibold text-white">
              Siap bermitra dengan Kami?
            </p>
            <p className="text-sm-regular px-3 lg:text-md-regular text-white max-w-[880px] text-center">
              Ingin menjual mobil serta membeli mobil yang anda atau perusahaan inginkan, kami dapat
              membantu Anda
            </p>
          </div>
          {/* Button Container */}
          <div className="flex-col lg:flex-row flex justify-center items-center gap-2">
            <NavLink to="/register/company">
              <CustomButton type="plain" label={'Daftar Untuk Perusahaan'} />
            </NavLink>
            <p className="text-sm-regular text-white">Atau</p>
            <NavLink to="/register/personal">
              <CustomButton type="secondary" label={'Daftar Untuk Personal'} />
            </NavLink>
          </div>
        </Col>
      </Col>
    </>
  )
}

export default PartnerSection
