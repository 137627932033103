const SuratDokumenIcon = ({ className }) => {
  return (
    <svg
      width="52"
      className={className}
      height="64"
      viewBox="0 0 52 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.5771 56.3055H1.67969V1.60156H41.4644V39.996"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68945 24.918H37.2926"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68945 29.9414H37.2926"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68945 34.9688H37.2926"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68945 39.9961H37.2926"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5982 51.2773H5.68945"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3696 18.3412V19.1433C15.3696 19.9454 14.7279 20.6405 13.8723 20.6405H13.0702C12.2681 20.6405 11.5729 19.9989 11.5729 19.1433V12.8868C11.5729 12.5125 11.359 12.1916 11.0382 11.9777L10.6104 11.7104C10.45 11.6569 10.3965 11.55 10.3965 11.3895V10.1596C10.3965 9.94572 10.5569 9.78532 10.7708 9.78532H11.9472C12.4285 9.78532 12.9098 9.51792 13.1237 9.09013L14.8348 5.98864C15.1557 5.40043 15.7974 4.97266 16.4925 4.97266H21.626H26.7595C27.4547 4.97266 28.0964 5.34695 28.4172 5.98864L30.1284 9.09013C30.3423 9.51792 30.8236 9.78532 31.3048 9.78532H32.4813C32.6952 9.78532 32.8556 9.94572 32.8556 10.1596V11.3895C32.8556 11.55 32.7486 11.6569 32.6417 11.7104L32.2139 11.9777C31.8931 12.1382 31.6792 12.5125 31.6792 12.8868V19.1433C31.6792 19.9454 31.0375 20.6405 30.1819 20.6405H29.3798C28.5777 20.6405 27.8825 19.9989 27.8825 19.1433V18.3412H15.3696Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.9825 52.9375C50.9825 47.6971 46.7581 43.4727 41.5176 43.4727C36.2772 43.4727 32.0527 47.6971 32.0527 52.9375C32.0527 58.178 36.2772 62.4025 41.5176 62.4025C46.7046 62.4025 50.9825 58.178 50.9825 52.9375Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.3457 52.8818L41.1958 56.3042L46.7037 49.5664"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.7369 34.5413C49.9668 34.5413 50.9293 33.5252 50.9293 32.3488V12.6704C50.9293 11.8683 50.2876 11.2266 49.4855 11.2266H47.8813C47.0792 11.2266 46.4375 11.8683 46.4375 12.6704V32.3488C46.5444 33.5787 47.507 34.5413 48.7369 34.5413Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.7363 34.543V36.6819"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5449 29.8359H50.9833"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7216 10.4772L26.2244 7.75H16.9199L15.4226 10.4772C15.4226 10.5307 15.3691 10.5307 15.3691 10.5842H27.7751C27.7751 10.5307 27.7216 10.4772 27.7216 10.4772Z"
        fill="#E12B25"
      />
      <path d="M16.6516 45.2344H5.68945V48.3893H16.6516V45.2344Z" fill="#E12B25" />
    </svg>
  )
}

export default SuratDokumenIcon
