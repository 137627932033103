import { Button, Select } from 'antd'
import { AlertMarkBlackIcon, ChevronLeftIcon, ChevronRightIcon } from 'assets'
import React from 'react'
import BadgeItem from '../badge-item'
import CardSectionHeader from '../card-section-header'
import moment from 'moment'
import { onRupiah } from 'utils/formatCurrency'
import { capitalize } from 'lodash'
const CardServiceHistoryPagination = ({
  page,
  totalRows,
  totalPerPage,
  pageSize,
  currentPage,
  handlePerRowsChange,
  lengthAllData,
  data,
  onChangeNextPage,
  onChangePrevPage,
  hidePagination,
  handleNavigation,
  headerTitle
}) => {
  return (
    <div>
      <CardSectionHeader headerTitle={headerTitle}>
        {/* Container */}
        <div className="grid grid-cols-1 gap-5">
          {/* Items */}
          {data?.length > 0 ? (
            data?.map((item, key) => (
              <div
                onClick={() => handleNavigation(item?.id)}
                key={key}
                className="p-6 grid grid-cols-[1fr_1fr_30px] lg:grid-cols-[1fr_1fr_1fr_30px] gap-2 justify-between items-center bg-gray-50 rounded-[4px] cursor-pointer">
                <div
                  className={`flex flex-col ${
                    headerTitle === 'Riwayat Inspeksi' ? 'lg:col-span-2' : ''
                  }`}>
                  <p className={`text-sm-semibold lg:text-lg-medium text-gray-900 mb-1`}>
                    {item?.workshop_name ? item?.workshop_name : item?.service_name}
                  </p>
                  <p className="text-xs-regular lg:text-md-regular text-gray-600">
                    {moment(item?.created_at).format('DD MMM YYYY')}
                  </p>
                </div>
                {item?.service_name ? (
                  <div className="flex-col gap-1 hidden lg:flex ">
                    <p className="text-sm-regular text-gray-900">{item?.item_count} Item</p>
                    <p className="text-md-semibold text-primary-500">
                      Rp {item?.price_total ? onRupiah(item?.price_total) : '-'}
                    </p>
                  </div>
                ) : null}
                <div className="flex flex-col gap-1">
                  <p className="text-sm-regular text-gray-900">Status</p>
                  {item?.status === 'progress' ? (
                    <BadgeItem className={'max-w-fit'} type="default">
                      Sedang Berlangsung
                    </BadgeItem>
                  ) : item?.status === 'Selesai' ||
                    item?.status === 'SERVICE_FINISHED' ||
                    item?.status === 'PICKUP_FINISHED' ? (
                    <BadgeItem className={'max-w-fit'} type="success">
                      {capitalize(item?.status.split('_').join(' '))}
                    </BadgeItem>
                  ) : item?.status === 'UNCONFIRMED' ||
                    item?.status === 'Belum Dikonfirmasi' ||
                    item?.status === 'Inspeksi Terjadwal' ||
                    item?.status === 'Perlu Melakukan Pembayaran' ||
                    item?.status === 'WAITING_APPROVAL' ||
                    item?.status === 'SERVICE_CONFIRMED' ||
                    item?.status === 'PICKUP_CONFIRMED' ? (
                    <BadgeItem className={'max-w-fit'} type="warning">
                      {capitalize(item?.status.split('_').join(' '))}
                    </BadgeItem>
                  ) : item?.status === 'PICKUP_IN_PROGRESS' ||
                    item?.status === 'Sedang Berlangsung' ||
                    item?.status === 'SERVICE_IN_PROGRESS' ? (
                    <BadgeItem className={'max-w-fit'} type="default">
                      {capitalize(item?.status.split('_').join(' '))}
                    </BadgeItem>
                  ) : item?.status === 'Pembayaran Gagal' ||
                    item?.status === 'CANCELED' ||
                    item?.status === 'REJECTED' ? (
                    <BadgeItem className={'max-w-fit'} type="danger">
                      {capitalize(item?.status.split('_').join(' '))}
                    </BadgeItem>
                  ) : null}
                </div>
                <div className="flex items-center justify-end">
                  <ChevronRightIcon className={'stroke-gray-400'} />
                </div>
              </div>
            ))
          ) : (
            <div className="bg-gray-50 p-6 flex justify-center items-center gap-2 rounded">
              <div>
                <AlertMarkBlackIcon className={'stroke-gray-500'} />
              </div>
              <p className="text-sm-medium text-gray-500">
                Belum ada riwayat {`${headerTitle === 'Riwayat Servis' ? 'servis' : 'inspeksi'}`}{' '}
                pada mobil Anda
              </p>
            </div>
          )}
        </div>
        {!hidePagination && data && data.length !== 0 && (
          <div className="flex w-full flex-row items-center justify-end space-x-2 md:space-x-4 mt-4">
            <div className={`mr-2 md:mr-4`}>
              <label className="text-gray-700 mr-2 md:mr-4">Tampilkan</label>
              <Select
                className="custom-table-limit"
                // style={{ width: '70px' }}
                value={pageSize ?? 5}
                onChange={handlePerRowsChange}>
                <Select.Option value={5}>5</Select.Option>
                <Select.Option value={10}>10</Select.Option>
                <Select.Option value={25}>25</Select.Option>
                <Select.Option value={50}>50</Select.Option>
                <Select.Option value={100}>100</Select.Option>
              </Select>
            </div>
            <div className="flex flex-shrink-0 flex-row">
              {isNaN(page) && (
                <span className="font-medium text-gray-700">
                  {`${currentPage ?? '1'} - ${totalPerPage ?? data?.length} dari ${
                    lengthAllData ?? data?.length
                  }`}
                </span>
              )}
              {!isNaN(page) && (
                <span className="font-medium text-gray-700">
                  {`${page + 1} - ${
                    page + pageSize >= totalRows ? totalRows : page + pageSize
                  } dari ${totalRows}`}
                </span>
              )}
            </div>
            <div className="flex flex-row space-x-2">
              <Button
                className="group p-0 flex border border-solid border-gray-300 bg-white justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
                onClick={onChangePrevPage ? () => onChangePrevPage(currentPage - 1) : () => {}}>
                <ChevronLeftIcon className="text-gray-700 group-hover:text-primary-500" />
              </Button>
              <Button
                className="group p-0 flex border border-solid border-gray-300 bg-white justify-center items-center rounded-md w-9 h-9 hover:border-primary-500"
                onClick={onChangeNextPage ? () => onChangeNextPage(currentPage + 1) : () => {}}>
                <ChevronRightIcon className="stroke-gray-700 group-hover:stroke-primary-500" />
              </Button>
            </div>
          </div>
        )}
      </CardSectionHeader>
    </div>
  )
}

export default CardServiceHistoryPagination
