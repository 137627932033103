import API from 'services';
const baseUrl = process.env.REACT_APP_API_BASE_URL
const loginSubmit = async (data) => {
  const { data: response } = await API.post(`${baseUrl}/user-service/auth/login`, data);
  return response.data;
};
const registerPersonal = async (data) => {
  const { data: response } = await API.post(`${baseUrl}/user-service/auth/register`, data);
  return response.data;
};

const registerCompany = async (data) => {
  const { data: response } = await API.post(`${baseUrl}/user-service/auth/register/company`, data);
  return response.data;
};

const sendOtp = async (data) => {
  const token = sessionStorage.getItem('token')
  const { data: response } = await API.post(
    `${baseUrl}/user-service/auth/mfa/otp/validate`,
    data,
    {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    });
  return response.data;
};

const requestOtp = async () => {
  const token = sessionStorage.getItem('token')
  const { data: response } = await API.get(
    `${baseUrl}/user-service/auth/mfa/otp/send`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  return response.data;
};

const getProvinces = async () => {
  const { data: response } = await API.get(`${baseUrl}/master-service/admin/provinces/all`);
  return response.data;
}

const getCities = async (provinceId) => {
  const { data: response } = await API.get(`${baseUrl}/master-service/admin/cities/province/${provinceId}`);
  return response.data;
}

const getSubdistricts = async (cityId) => {
  const { data: response } = await API.get(`${baseUrl}/master-service/admin/subdistricts/city/${cityId}`);
  return response.data;
}

const postAuthGoogle = async (args) => {
  const { data: response } = await API.post(
    `${baseUrl}/user-service/auth/sso/google`,
    args.body,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    }
  );
  return response.data
}

export {
  loginSubmit,
  registerPersonal,
  registerCompany,
  getProvinces,
  getCities,
  getSubdistricts,
  sendOtp,
  requestOtp,
  postAuthGoogle
}