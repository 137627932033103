import React from 'react'
import BadgeItem from '../badge-item'
import CardSectionHeader from '../card-section-header'

const ContentContainer = ({ title, children }) => (
    <div>
        <p className="text-lg-medium text-gray-900 mb-1">{title}</p>
        <p className="text-md-regular text-gray-600">{children}</p>
    </div>
)

function CardUnitSummary({ unit_name, status, price }) {
    return (
        <>
            <CardSectionHeader>
                <div className="grid grid-cols-4">
                    <ContentContainer title="Unit">{unit_name}</ContentContainer>
                    <ContentContainer title="Status">
                        <BadgeItem type={status} className="max-w-fit">Selesai</BadgeItem>
                    </ContentContainer>
                    <ContentContainer title="Harga Jual">{`Rp. ${price}`}</ContentContainer>
                    <div />
                </div>
            </CardSectionHeader>
        </>
    )
}

export default CardUnitSummary
