const WarningIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2739_64315)">
        <path
          d="M9.99935 6.66797V10.0013M9.99935 13.3346H10.0077M6.54935 1.66797H13.4493L18.3327 6.5513V13.4513L13.4493 18.3346H6.54935L1.66602 13.4513V6.5513L6.54935 1.66797Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2739_64315">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WarningIcon
