const BookIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33398 16.25C1.33398 15.6975 1.55348 15.1676 1.94418 14.7769C2.33488 14.3862 2.86478 14.1667 3.41732 14.1667H14.6673M1.33398 16.25C1.33398 16.8026 1.55348 17.3325 1.94418 17.7232C2.33488 18.1139 2.86478 18.3334 3.41732 18.3334H14.6673V1.66669H3.41732C2.86478 1.66669 2.33488 1.88618 1.94418 2.27688C1.55348 2.66758 1.33398 3.19749 1.33398 3.75002V16.25Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BookIcon
