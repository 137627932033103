import { Col, Row } from 'antd'
import {
  GaransiServisIcon,
  HargaBersaingIcon,
  MekanikTersertifikasiIcon,
  SukuCadangIcon,
  TransparanIcon
} from 'assets'
import CardLandingGradient from 'components/ui/card/card-landing-gradient'
import React from 'react'

const ServisBirdMobilData = [
  {
    label: 'Transparan',
    icon: <TransparanIcon />,
    description: 'Biaya, suku cadang, dan lacak pengerjaan servis dapat dilihat melalui website kami'
  },
  {
    label: 'Mekanik Tersertifikasi',
    icon: <MekanikTersertifikasiIcon />,
    description: 'Tim Mekanik yang andal dan berpengalaman'
  },
  {
    label: 'Harga Bersaing',
    icon: <HargaBersaingIcon className={'lg:absolute lg:top-[11px] lg:left-[36px]'} />,
    description: (
      <>
      Biaya Jasa dan Suku Cadang kami bersaing*
      <br /><br />
      *Berdasarkan survey telah dilakukan
      </>
    )
  },
  {
    label: 'Garansi Servis',
    icon: <GaransiServisIcon />,
    description: 'Garansi servis 1,000 Km atau 14 hari, mana yang mencapai lebih dahulu'
  },
  {
    label: 'Suku Cadang Bervariasi',
    icon: <SukuCadangIcon />,
    description: 'Suku cadang yang Kami sediakan bervariasi sesuai dengan kebutuhan Anda.'
  }
]
const WhyServiceSection = () => {
  return (
    <Row className="bg-white py-20 w-full">
      <Col span={22} lg={{ span: 20 }} className="mx-auto">
        <div className="flex flex-col gap-12">
          {/* Title */}
          <p className="display-xs-semibold xl:display-sm-semibold text-gray-900 text-center">
            Mengapa Harus Servis di Birdmobil?
          </p>
          {/* List */}
          <div className="grid grid-cols-1 xl:grid-cols-5 gap-6">
            {ServisBirdMobilData.map((data, key) => (
              <CardLandingGradient key={key} containerClassName={'flex flex-col gap-4'} gradient={true}>
                <div className="relative flex justify-start items-end h-[70px] object-contain">
                  {data.icon}
                </div>
                <div className="flex flex-col">
                  <p className="text-md-semibold md:text-lg-semibold text-gray-900">{data.label}</p>
                  <p className="text-sm-regular text-gray-600">{data.description}</p>
                </div>
              </CardLandingGradient>
            ))}
          </div>
          {/* Badge Item */}
        </div>
      </Col>
    </Row>
  )
}

export default WhyServiceSection
