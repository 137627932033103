const RekomendasiServisIcon = ({ className }) => {
  return (
    <svg
      width="62"
      className={className}
      height="64"
      viewBox="0 0 62 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.8669 14.3885V18.9348C51.8669 19.1347 51.767 19.3345 51.6171 19.4844C51.4672 19.6342 51.2674 19.7342 51.0675 19.7342C50.6679 19.7342 50.3681 19.4344 50.3182 19.0347V18.9348C50.2182 18.2354 49.9185 17.6858 49.4189 17.2362C48.9193 16.8365 48.3198 16.5368 47.6204 16.5368C46.2715 16.5368 45.1224 17.536 44.9226 18.8848V18.9848C44.8726 19.3844 44.5229 19.6842 44.1732 19.6842H43.8735C43.7236 19.6842 43.5237 19.6343 43.4238 19.5343V15.6875C44.3231 15.1879 45.0725 14.5384 45.7219 13.7891H51.3173C51.6171 13.7891 51.8669 14.0388 51.8669 14.3885Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.8678 15.5879H58.8121C59.9612 15.5879 60.9104 16.5371 60.9104 17.6862V49.8597C60.9104 51.0087 59.9612 51.958 58.8121 51.958H46.9219"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.2188 48.8108L57.8141 43.2154V18.6855"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.2236 23.6816L42.9746 24.9306"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.2236 26.5791L42.9746 25.3301"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.2236 38.8184L42.9746 40.0673"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.2236 41.7677L42.9746 40.4688"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.7715 26.8789H55.2645"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.7715 34.3223H55.2645"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5228 52.1081C46.9725 53.1072 47.2223 54.2563 47.2223 55.4054C47.2223 58.3529 45.6735 60.9508 43.3754 62.3996V58.4528C43.3754 57.3038 42.7759 56.3046 41.7767 55.7051C40.8775 55.2055 39.8783 54.9058 38.8791 54.9058C37.8799 54.9058 36.8808 55.1556 35.9815 55.7051C35.0323 56.2546 34.4328 57.3038 34.4328 58.4528V62.3996C32.1347 60.9008 30.5859 58.3529 30.5859 55.4054C30.5859 52.7076 31.8849 50.2596 33.9332 48.7608C34.2329 48.561 34.5327 48.3612 34.8324 48.1613V15.8379C32.2845 14.3891 30.5859 11.6913 30.5859 8.59386C30.5859 5.64628 32.1347 3.04842 34.4328 1.59961V5.54638C34.4328 6.69543 35.0323 7.69461 36.0315 8.29412C36.9307 8.7937 37.9299 9.09345 38.9291 9.09345C39.9283 9.09345 40.9274 8.84366 41.8267 8.29412C42.7759 7.74457 43.3754 6.69542 43.3754 5.59632V3.4481V1.59961C45.6735 3.09838 47.2223 5.64628 47.2223 8.59386C47.2223 10.6422 46.4729 12.4907 45.2739 13.9395C44.6244 14.6888 43.875 15.3383 42.9757 15.8379V25.3301V25.5799V25.7298V40.4676V40.7174V40.8673V48.1113C43.4254 48.3611 43.825 48.6109 44.1748 48.9606C45.1739 49.81 45.9733 50.8591 46.5228 52.1081Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3349 30.0261H24.2408C23.0417 30.0261 21.8427 29.5765 20.9435 28.7272L18.6953 26.6289"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.40234 35.2715L15.847 36.1707C17.4457 36.4205 18.6947 37.8194 18.6947 39.4181V41.0667H9.40234"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.40625 31.375V46.7124"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.40687 31.374L1.85896 28.5763C1.55921 28.2765 1.35938 27.8769 1.35938 27.4772V24.4297C1.35938 23.5804 2.00884 22.9309 2.85814 22.9309H8.70333L13.7492 13.2389C14.7484 11.94 16.2971 11.1406 17.9457 11.1406H28.3871"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7985 46.7129V47.2625C13.7985 49.8603 11.7003 51.9586 9.10239 51.9586C6.50452 51.9586 4.40625 49.8603 4.40625 47.2625V46.7129"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2843 46.7129H9.40234"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3379 41.0664H31.0349"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0488 22.9824H31.5844"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0965 14.7888L13.2988 20.1843H28.4364V13.9395H17.995C17.2456 13.9395 16.5462 14.2392 16.0965 14.7888Z"
        fill="#E12B25"
      />
    </svg>
  )
}

export default RekomendasiServisIcon
