import useValidation from 'lib/useValidation'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { getCreditCards, postLinkCard, postUnLinkCard } from 'services/credit-card'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage, showSuccessMessage, showWarningMessage } from 'utils/toastMessage'
const initialState = { cardholder_name: null, card_number: null, expired_at: null, cvv: null }
const initialError = { cardholder_name: null, card_number: null, expired_at: null, cvv: null }
const useCreditCard = (isListShow = false) => {
  const [creditCards, setCreditCards] = useState(null)
  const [newCreditCard, setNewCreditCard] = useState({ ...initialState })
  const [errors, setErrors] = useState({ ...initialError })
  const [modalCardList, setModalCardList] = useState(false)
  const [modalAddCard, setModalAddCard] = useState(false)
  const [modalPreviewCard, setModalPreviewCard] = useState(false)
  const [modalConfirmDeleteCard, setModalConfirmDeleteCard] = useState(false)
  const [selectedCreditCard, setSelectedCreditCard] = useState(null)
  const { nameValidation, numberValidation } = useValidation()
  const { refetch } = useQuery({
    queryKey: 'getCreditCards',
    queryFn: () => getCreditCards(),
    onSuccess: (data) => {
      setCreditCards(data?.length > 0 ? data : null)
    }
  })
  const linkCard = useMutation({
    mutationFn: (dataSend) => postLinkCard(dataSend),
    onSuccess: (data) => {
      // console.log(data)
      if (data?.auth_url !== '' && data?.auth_url !== undefined) {
        window.open(data?.auth_url)
        showWarningMessage('Silahkan validasi credit card Anda')
      } else {
        showSuccessMessage('Credit card Anda berhasil ditambahkan')
      }
      closeModalAddCard()
      refetch()
      setNewCreditCard({ ...initialState })
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })
  const unLinkCard = useMutation({
    mutationFn: (id) => postUnLinkCard(id),
    onSuccess: () => {
      showSuccessMessage('Credit card Anda berhasil dihapus')
      closeModalConfirmDeleteCard()
      refetch()
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })

  function handleDeleteCard(id) {
    unLinkCard.mutate(id)
  }

  function handleSelectedCreditCard(card) {
    setSelectedCreditCard(card)
  }

  function handleValidation(name, value) {
    let err = ''

    switch (name) {
      case 'cardholder_name':
        err = nameValidation(value)
        break
      case 'cvv':
        err = numberValidation(value)
        break
      case 'card_number':
        err = numberValidation(value)
        break
      default:
        err = ''
    }
    return err
  }

  function submitAddCard() {
    const keys = Object.keys(newCreditCard)
    for (let i = 0; i < keys.length; i++) {
      if (newCreditCard[keys[i]] === null) {
        showErrorMessage(`Mohon isi ${nameToString[keys[i]]}`)
        return false
      }
    }
    const dataSend = {
      card_number: newCreditCard?.card_number?.replace(/\D/g, '')?.split(' ').join(),
      expiry_month: newCreditCard?.expired_at.split('/')[0],
      expiry_year: newCreditCard?.expired_at.split('/')[1],
      cvv: newCreditCard?.cvv,
      cardholder_name: newCreditCard?.cardholder_name
    }
    linkCard.mutate(dataSend)
  }

  function handleChangeCreditCard(name, value) {
    let newValue = value
    if (name === 'card_number' || name === 'cvv') {
      newValue = value.replace(/\D/g, '')
    }
    let err = ''
    err = handleValidation(name, newValue)
    setNewCreditCard((prev) => ({
      ...prev,
      [name]: newValue
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: err
    }))
  }
  function openModalCardList() {
    setModalCardList(true)
  }
  function closeModalCardList() {
    setModalCardList(false)
  }
  function openModalAddCard() {
    if (modalCardList && isListShow) {
      closeModalCardList()
    }
    setModalAddCard(true)
  }
  function closeModalAddCard() {
    setModalAddCard(false)
    if (!modalCardList && isListShow) {
      openModalCardList()
    }
  }
  function openModalPreviewCard() {
    setModalPreviewCard(true)
  }
  function closeModalPreviewCard() {
    setModalPreviewCard(false)
  }
  function handlePreviewSelectedCard(card) {
    handleSelectedCreditCard(card)
    openModalPreviewCard()
  }
  function openModalConfirmDeleteCard() {
    setModalConfirmDeleteCard(true)
  }
  function closeModalConfirmDeleteCard() {
    setModalConfirmDeleteCard(false)
  }
  function handleConfirmDeleteCard(card) {
    handleSelectedCreditCard(card)
    openModalConfirmDeleteCard()
  }

  return {
    modalPreviewCard,
    modalCardList,
    modalAddCard,
    modalConfirmDeleteCard,
    errorsAddCard: errors,
    creditCards,
    selectedCreditCard,
    newCreditCard,
    isAddCardLoading: linkCard?.isLoading,
    isRemoveCardLoading: unLinkCard?.isLoading,
    handleChangeCreditCard,
    handleDeleteCard,
    submitAddCard,
    openModalCardList,
    closeModalCardList,
    openModalAddCard,
    closeModalAddCard,
    handleSelectedCreditCard,
    openModalPreviewCard,
    closeModalPreviewCard,
    handlePreviewSelectedCard,
    openModalConfirmDeleteCard,
    closeModalConfirmDeleteCard,
    handleConfirmDeleteCard
  }
}

export default useCreditCard
