import { Divider, Spin } from 'antd'
import { AlertMarkBlackIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import { capitalize } from 'lodash'
import React from 'react'
import moment from 'moment'
import CustomButton from 'components/ui/custom-button'
const MenungguApprovalCard = ({
  data,
  serviceName,
  accountType,
  isApprovalServiceCompany,
  putApprovalService,
  handleApprovalService
}) => {
  return accountType !== 'company' ? (
    <CardSectionHeader>
      <div className="flex justify-start items-center gap-2">
        <AlertMarkBlackIcon className={'stroke-gray-500'} />
        <p className="text-md-regular text-gray-600">
          {capitalize(serviceName)} sedang menunggu approval dari perusahaan
        </p>
      </div>
    </CardSectionHeader>
  ) : (
    <CardSectionHeader headerTitle={'Approval Layanan'}>
      {isApprovalServiceCompany ? (
        <>
          <div className="flex justify-between items-center">
            <p className="text-md-regular text-gray-600">
              {data?.requestedName} telah mengajukan {capitalize(data?.serviceType)} yang dilakukan
              pada:
            </p>
            <p className="text-md-medium text-gray-700">
              {moment(data?.service_date).format('DD MMM YYYY')} - {data?.service_start_time} WIB
            </p>
          </div>
          <Divider />
          <div className="flex justify-end gap-2">
            <Spin spinning={putApprovalService.isLoading}>
              <CustomButton
                onClick={() => {
                  handleApprovalService(false)
                }}
                type="plain"
                label={'Abaikan'}
              />
            </Spin>
            <Spin spinning={putApprovalService.isLoading}>
              <CustomButton
                onClick={() => {
                  handleApprovalService(true)
                }}
                label={'Setujui'}
              />
            </Spin>
          </div>
        </>
      ) : null}
    </CardSectionHeader>
  )
}

export default MenungguApprovalCard
