import { Col } from 'antd'
import { HargaBersaingIcon, TerpercayaIcon } from 'assets'
import AmanIcon from 'assets/icons/aman'
import PencairanCepatIcon from 'assets/icons/pencairan-cepat'
import ProsesMudahIcon from 'assets/icons/proses-mudah'
import CardLandingGradient from 'components/ui/card/card-landing-gradient'
import React from 'react'
const kelebihanData = [
  {
    label: 'Terpercaya',
    description: 'Inspektor Kami sangat ANDAL dalam menginspeksi mobil Anda secara menyeluruh',
    icon: <TerpercayaIcon />
  },
  {
    label: 'Proses Mudah',
    description:
      'Dapatkan pengalaman yang mudah & nyaman mulai dari proses inspeksi mobil hingga mobil Anda terjual',
    icon: <ProsesMudahIcon />
  },

  {
    label: 'Aman',
    description: 'Kami menjamin keamanan selama proses berlangsung',
    icon: <AmanIcon />
  },
  {
    label: 'Harga Terbaik',
    description:
      'Dapatkan harga penawaran terbaik dari Kami dengan proses yang transparan dan nyaman',
    icon: <HargaBersaingIcon />
  },
  {
    label: 'Pencairan Cepat',
    description:
      'Dapatkan harga penawaran terbaik dari Kami dengan proses yang transparan dan nyaman',
    icon: <PencairanCepatIcon />
  }
]

function BenefitSection({ containerClassName, headerTitle }) {
  return (
    <Col span={24} className={`${containerClassName ? containerClassName : `py-20 bg-gray-50`}`}>
      <Col span={22} lg={{ span: 20 }} className="mx-auto gap-12 flex flex-col">
        <div className="flex items-center justify-center">
          <p className="display-xs-semibold lg:display-sm-semibold text-gray-900">{headerTitle}</p>
        </div>
        <div className="grid grid-cols-1 xl:flex xl:flex-col items-center gap-6">
          <div className="grid grid-cols-1 xl:grid-cols-5 gap-6">
            {kelebihanData.map((data, key) => (
              <CardLandingGradient
                key={key}
                containerClassName={'flex flex-col gap-4'}
                gradient={true}>
                <div className="relative flex justify-start items-end h-[70px] object-contain">
                  {data.icon}
                </div>
                <div className="flex flex-col">
                  <p className="text-md-semibold md:text-lg-semibold text-gray-900">{data.label}</p>
                  <p className="text-sm-regular text-gray-600">{data.description}</p>
                </div>
              </CardLandingGradient>
            ))}
          </div>
        </div>
      </Col>
    </Col>
  )
}

export default BenefitSection
