import { Col, Row } from 'antd'
import CardDashboard from 'components/ui/card-dashboard'
import React from 'react'
import { useHistory } from 'react-router-dom'

const data = {
  id: 1,
  carStatus: 'service',
  status: 'progress',
  carOwned: 'personal',
  percentage: 75,
  estimate: '30 Oktober 2022 - 10:24',
  unit_name: '2019 Toyota Kijang Innova 2.4G',
  unit_owner: 'Mitchel Owen',
  transmission: 'Manual',
  production_year: 2019,
  police_number: 'L 2930 R',
  detailCar: [
    { title: 'Kepemilikan', value: 'Mitchel Owen' },
    { title: 'Transmisi', value: 'Manual' },
    { title: 'Tahun', value: 2019 },
    { title: 'Plat Nomor', value: 'L 2930 R' }
  ],
  recommendationService: ['Ganti Oli', 'Tune Up Mesin'],
  reminder: 'Mobil ini waktunya ganti oli!'
}

const ReminderPage = () => {
  const { push } = useHistory()
  const navigateHandler = (path) => {
    push(path)
  }
  return (
    <Row className="gap-6">
      <Col span={24} className="flex gap-1 items-center text-xl-medium text-gray-900 py-2">
        Dashboard / <span className="text-primary-500">Reminder</span>
      </Col>
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* Card Dashboard */}
        <CardDashboard unit={data} navigateHandler={navigateHandler} />
      </Col>
    </Row>
  )
}

export default ReminderPage
