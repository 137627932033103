import { Col, Divider, Row } from 'antd'
import React from 'react'
import CardStory from '../ui/card-story'
import ContentTitle from '../ui/content-title'
import DetailLocationSection from '../ui/detail-location-section'
import BenefitSection from '../../../../components/section/benefit-section'
import PartnerSection from '../ui/partner-section'

import './style.css'
import YouTube from 'react-youtube'
import ServiceSection from 'components/section/service-section'
import { layananData } from 'features/about-us/data/layanan-data'
import { CarouselMultipleLayout } from 'components/ui/CarouselMultipleLayout'
import Slider from 'react-slick'
import { LayananKamiGeneral } from '..'

function CustomerStories() {
  return (
    <Row className={`bg-white`}>
      <Col span={24}>
        {/* Content Title */}
        <ContentTitle title="Cerita Anda" />
        {/* Carousel Stories */}
        <Col span={24} className="bg-white py-20 lg:block hidden">
          <CarouselMultipleLayout
            arrows={false}
            slideToShow={4}
            totalItem={8}
            className={'overflow-hidden cursor-grab'}>
            {new Array(6).fill(0).map((review, key) => (
              <div key={key}>
                <CardStory />
              </div>
            ))}
          </CarouselMultipleLayout>
        </Col>
        <Col span={24} className="bg-white py-9 lg:hidden block">
          <Slider arrows={false} dots slidesToShow={1} infinite autoplay>
            {new Array(6).fill(0).map((review, key) => (
              <div key={key} className="px-3">
                <CardStory />
              </div>
            ))}
          </Slider>
        </Col>
        {/* Introduction Video */}
        <Col span={24} className="bg-gray-50 py-20">
          <Col
            span={22}
            className="p-6 grid grid-cols-1 lg:grid-cols-[400px_1fr] gap-6 bg-white border border-solid border-gray-200 shadow-lg mx-auto">
            {/* Video */}
            <div className="relative z-0">
              <YouTube
                containerClassName="rounded-[4px]"
                // videoId="jfKfPfyJRdk"
                opts={{ minHeight: '300px', maxHeight: '400px', width: '100%' }}
              />
              <div className="-z-[1] top-[-13px] right-[-13px] absolute w-full h-full bg-gray-100 rounded-[4px]"></div>
              <div className="-z-[1] bottom-[-13px] left-[-13px] absolute w-full h-full bg-primary-50 rounded-[4px]"></div>
            </div>
            {/* Description */}
            <div className="flex flex-col justify-center">
              <p className="text-gray-900 display-sm-semibold">Dengarkan Cerita dari Mitra Kami</p>
              <Divider className="h-[4px] min-w-[0px] max-w-[240px] bg-primary-500 rounded-[4px]" />
              <p className="text-sm-regular text-gray-600">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec urna, tellus risus amet
                venenatis urna. Consectetur malesuada sed elementum sed molestie massa. Sed lectus
                sollicitudin phasellus enim dignissim adipiscing. Eget elit pharetra gravida et
                scelerisque tortor aenean nibh gravida. Quam eu consectetur quis netus ac commodo
                blandit massa. Nunc scelerisque id dolor tincidunt pellentesque porttitor odio.
                Pharetra, eget ante vulputate diam quam vestibulum blandit. Donec dui et vulputate
                porttitor tortor integer nec phasellus nulla. Orci auctor ac morbi sapien egestas
                vestibulum lorem nunc nam.
              </p>
            </div>
          </Col>
        </Col>
        <LayananKamiGeneral />
        <BenefitSection headerTitle={'Kelebihan BirdMobil'} />
        <PartnerSection />
        <DetailLocationSection />
      </Col>
    </Row>
  )
}

export default CustomerStories
