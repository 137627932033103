import {
  EksteriorIcon,
  FileTextIcon,
  GridIcon,
  InteriorIcon,
  MesinTerawatIcon,
  SuratDokumenIcon,
  TruckIcon,
  WindIcon
} from 'assets'

export const inspeksiStepData = [
  {
    title: 'Jadwalkan Inspeksi Mobil',
    description:
      'Isi data unit mobil Anda dengan lengkap, Jadwal dan lokasi Inspeksi yang Anda inginkan.'
  },
  {
    title: 'Inspeksi Dilakukan',
    description:
      'Inspektor akan melakukan Inspeksi sesuai dengan Jadwal dan Lokasi yang disepakati untuk mobil Anda di lebih dari 180 titik.'
  },
  {
    title: 'Kirim Hasil Inspeksi',
    description: `Hasil Inspeksi akan dikirimkan kepada Anda dalam bentuk Laporan hasil Inspeksi dalam kurun 1 Jam (*), atau berupa penawaran harga sesuai dengan kondisi mobil Anda (**)
      `
  }
]
export const InspectionBenefitData = [
  {
    label: 'Eksterior',
    description:
      'Inspeksi / Cek bagian luar mobil meliputi Body Mobil, Bumper, Spoiler, Spion, Kaca dan Pintu Mobil dan lain-lain yang berhubungan dengan estetika dan fungsi mobil Anda.',
    icon: <EksteriorIcon />
  },
  {
    label: 'Interior',
    description:
      'Inspeksi / cek bagian dalam mobil meliputi Dashboard, setir, Panel Pintu, Setir dan lain-lain di dalam mobil.',
    icon: <InteriorIcon />
  },
  {
    label: 'Mesin dan Transmisi',
    description:
      'Inspeksi / Cek bagian Mesin dan transmisi meliputi sistem pendingin, pengapian, pengereman, kopling, kelistrikan dan lain-lain',
    icon: <MesinTerawatIcon />
  },
  {
    label: 'Surat dan Dokumen',
    description: 'Inspeksi / Cek kelengkapan surat-surat Mobil (STNK dan BPKB)',
    icon: <SuratDokumenIcon />
  }
]

export const standardInspectionData = []
