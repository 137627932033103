const ThumbsDownIcon = ({ className }) => {
  return (

    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9430_100411)">
        <path
          d="M8.49953 1.00018H9.83453C10.1175 0.995176 10.3925 1.09425 10.6072 1.27859C10.822 1.46293 10.9616 1.71971 10.9995 2.00018V5.50018C10.9616 5.78065 10.822 6.03743 10.6072 6.22177C10.3925 6.40611 10.1175 6.50519 9.83453 6.50018H8.49953M4.99953 7.50018V9.50018C4.99953 9.89801 5.15756 10.2795 5.43887 10.5608C5.72017 10.8421 6.1017 11.0002 6.49953 11.0002L8.49953 6.50018V1.00018H2.85953C2.61836 0.997454 2.38434 1.08198 2.20057 1.23818C2.01681 1.39438 1.89569 1.61173 1.85953 1.85018L1.16953 6.35018C1.14777 6.4935 1.15744 6.63984 1.19786 6.77906C1.23827 6.91827 1.30848 7.04703 1.4036 7.15642C1.49872 7.26581 1.61649 7.35321 1.74874 7.41257C1.881 7.47193 2.02457 7.50182 2.16953 7.50018H4.99953Z"
          stroke="#F04438"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9430_100411">
          <rect
            width="12"
            height="12"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>

  )
}

export default ThumbsDownIcon
