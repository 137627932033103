import CardSectionHeader from '../card-section-header'

function CardPilihBengkel({ workshops, data, handleChangeActiveWorkshop }) {
  const activeContainer = 'border-primary-300 bg-primary-50'
  const deactiveContainer = 'border-gray-200 bg-white'
  return (
    <CardSectionHeader headerTitle="Pilih Bengkel">
      <div className="grid grid-cols-1 gap-4 p-3 overflow-y-auto max-h-[184px] bg-gray-50">
        {workshops.length > 0 ? (
          workshops?.map((el, index) => (
            <div
              className={`flex gap-2 border border-solid cursor-pointer p-4 rounded ${
                el.id === data?.workshop?.id ? activeContainer : deactiveContainer
              }`}
              key={index}
              onClick={() => handleChangeActiveWorkshop(el)}>
              <div
                className={`w-5 h-5 rounded-full border border-solid flex items-center justify-center ${
                  el.id === data.workshop.id
                    ? 'bg-primary-100 border-primary-500'
                    : 'bg-white border-gray-300 '
                }`}>
                <div
                  className={`w-[10px] h-[10px]  ${
                    el.id === data.workshop.id ? 'bg-primary-500' : 'bg-white'
                  } rounded-full`}></div>
              </div>
              <div>
                <p
                  className={`text-sm-medium ${
                    el.id === data.workshop.id ? 'text-primary-700' : 'text-gray-700'
                  }`}>
                  {el?.name}
                </p>
                <p
                  className={`text-sm-normal ${
                    el.id === data.workshop.id ? 'text-primary-600' : 'text-gray-500'
                  }`}>
                  {el?.location_name || '-'}
                </p>
              </div>
            </div>
          ))
        ) : (
          <span className="flex justify-center items-center text-sm-regular">
            Belum Ada Bengkel yang Menyediakan Pickup Servis
          </span>
        )}
      </div>
    </CardSectionHeader>
  )
}

export default CardPilihBengkel
