import { Col, Input, Row, Select } from 'antd'
import { CloseIcon, MagnifyingIcon, SlidersIcon } from 'assets'
import CardCar from 'components/ui/card-car'
import CustomPagination from 'components/ui/pagination/custom-pagination'
import { FilterExTaxi } from 'features/beli-mobil'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import { useQuery } from 'react-query'
import { getAllBrands, getCatalogTaxis } from 'features/beli-mobil/service'
import CustomButton from 'components/ui/custom-button'
import { ModalFilterExTaxi } from 'features/beli-mobil/components/modal'
import { onRupiah } from 'utils/formatCurrency'

const sortOption = [
  { label: 'Urutkan', value: '', disabled: true },
  { label: 'Harga Tertinggi', value: 'desc' },
  { label: 'Harga Terendah', value: 'asc' }
]

const initialFilter = {
  merek: null,
  minHarga: null,
  maxHarga: null,
  keywords: '',
  limit: 15,
  offset: 0
}
function BeliExTaxiPage() {
  const { push, location } = useHistory()
  const [sortType, setSortType] = useState(sortOption[0])
  const [filterModal, setFilterModal] = useState(false)
  const [filterTag, setFilterTag] = useState({})
  const [taxis, setTaxis] = useState({})
  const [brands, setBrands] = useState([])
  const [filter, setFilter] = useState({ ...initialFilter })
  useEffect(() => {
    handleFilter('merek', location?.state?.data?.merek)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [location?.state?.data?.merek])
  useQuery({
    queryKey: 'getAllBrands',
    queryFn: () => getAllBrands(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setBrands(newData)
    }
  })
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    []
  )
  var { refetch } = useQuery({
    queryKey: ['getCatalog', debouncedChangeHandler],
    queryFn: () => {
      const newFilter = {
        ...filter
      }
      if (filter?.merek?.value !== undefined) {
        newFilter.merek = filter.merek.value
      }
      return getCatalogTaxis(newFilter)
    },
    onSuccess: (data) => {
      setTaxis(data)
    },
    enabled: Boolean(debouncedChangeHandler)
  })
  // Sort Data
  const sortedData =
    sortType.value === ''
      ? taxis?.data
      : sortType.value === 'asc'
      ? taxis?.data?.sort((a, b) => a?.price - b?.price)
      : taxis?.data?.sort((a, b) => b?.price - a?.price)

  const handleSortData = (value) => {
    setSortType(value)
  }
  function handleFilter(name, value) {
    value = value === undefined ? null : value
    let newObj =
      name === 'keywords' ? { ...filter, [name]: value, offset: 0 } : { ...filter, [name]: value }
    setFilter(newObj)
    setFilterTag((prev) => ({
      ...prev,
      [name]: value
    }))
    return debouncedChangeHandler()
  }
  function handleResetFilter() {
    setFilterTag({})
    setFilter({ ...initialFilter })
    return debouncedChangeHandler()
  }
  function handlePagination(pageNumber) {
    const totalOffset = filter.limit * pageNumber - filter.limit
    return handleFilter('offset', totalOffset)
  }
  function handleNavigation(path) {
    return push({ pathname: path, state: { is_create_your_own_car: true } })
  }
  function openFilterModal() {
    setFilterModal(true)
  }
  function closeFilterModal() {
    setFilterModal(false)
  }
  return (
    <>
      <Row>
        <Col span={22} lg={{ span: 20 }} className="mx-auto my-10">
          <div className="grid lg:grid-cols-[200px_1fr] xl:grid-cols-[286px_1fr] gap-6">
            {/* Filter */}
            <div className="hidden lg:block">
              <FilterExTaxi brands={brands} handleFilter={handleFilter} filter={filter} />
            </div>
            {/* Right Content */}
            <div className="grid place-content-start grid-cols-1 gap-6">
              {/* Search Bar & Sort */}
              <div className="grid lg:flex gap-2 items-center">
                <Input
                  size="medium"
                  className="text-md-normal text-gray-500 px-[14px] py-[5px] rounded-lg"
                  prefix={<MagnifyingIcon className={'mr-1'} />}
                  placeholder="Search"
                  name="keywords"
                  onChange={(e) => {
                    handleFilter(e.target.name, e.target.value)
                  }}
                  value={filter.keywords}
                />
                <div className="flex justify-between items-center">
                  <Select
                    className="w-[150px]"
                    size="medium"
                    defaultValue={'Urutkan'}
                    onChange={(_, option) => handleSortData(option)}
                    options={sortOption}
                    value={sortType?.value}
                    placeholder="Urutkan"
                  />
                  <div className="lg:hidden">
                    <CustomButton
                      onClick={openFilterModal}
                      type="plain"
                      label={
                        <div className="flex justify-center items-center">
                          <SlidersIcon className={'h-3'} /> Filter
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
              {/* Filter Tag */}
              {Object.entries(filterTag).filter(([, value]) => value).length > 0 ||
              sortType.value !== '' ? (
                <div className="flex gap-2 items-center overflow-x-scroll whitespace-nowrap pb-2 w-[100%]">
                  {sortType.value !== '' ? (
                    <div className="flex justify-center items-center gap-2 bg-gray-200 rounded px-2 py-3 text-xs-medium text-gray-500">
                      {sortType.value === 'asc' ? 'dari Terendah' : 'dari Tertinggi'}
                      <div onClick={() => setSortType(sortOption[0])}>
                        <CloseIcon className={'cursor-pointer w-2 h-2'} />
                      </div>
                    </div>
                  ) : null}
                  {Object.entries(filterTag).length > 0 ? (
                    <>
                      {Object.entries(filterTag).map(([key, value]) => {
                        if (key !== 'offset' && key !== 'limit' && key !== 'keywords' && value) {
                          return (
                            <div
                              key={key}
                              className="flex justify-center items-center gap-2 bg-gray-200 rounded px-2 py-3 text-xs-medium text-gray-500">
                              {key === 'merek'
                                ? value?.label
                                : `${key === 'minHarga' ? 'dari' : 'sampai'} Rp ${onRupiah(value)}`}
                              <div onClick={() => handleFilter(key, null)}>
                                {/* <div> */}
                                <CloseIcon className={'cursor-pointer w-2 h-2'} />
                              </div>
                            </div>
                          )
                        }
                      })}
                    </>
                  ) : null}

                  <span
                    className="hover:text-primary-400 text-primary-700 text-sm-medium cursor-pointer"
                    onClick={() => {
                      handleResetFilter()
                      setSortType(sortOption[0])
                    }}>
                    Hapus Filter
                  </span>
                </div>
              ) : null}
              {/* Grid Car */}
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {taxis?.data?.map((data, index) => (
                  <CardCar
                    isTaxi
                    data={data}
                    onClick={() => {
                      handleNavigation(`/beli/ex-taxi/detail/${data?.id}`)
                    }}
                    key={index}
                  />
                ))}
              </div>

              {/* Pagination */}
              <div className="grid grid-cols-1 place-items-end">
                <CustomPagination
                  onChange={handlePagination}
                  defaultPageSize={filter?.limit}
                  defaultCurrent={1}
                  current={filter?.offset === 0 ? 1 : filter?.offset / filter?.limit + 1}
                  total={taxis?.meta?.total}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ModalFilterExTaxi
        brands={brands}
        handleFilter={handleFilter}
        filter={filter}
        openModal={filterModal}
        onCancel={closeFilterModal}
        handleResetFilter={handleResetFilter}
      />
    </>
  )
}

export default BeliExTaxiPage
