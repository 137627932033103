import React from 'react'
import PublishedJual from '../card-status-transaction/jual/published-jual'
import BookedUnitJual from '../card-status-transaction/jual/booked-unit-jual'
import TerjualJual from '../card-status-transaction/jual/terjual-jual'
import SelesaiJual from '../card-status-transaction/jual/selesai-jual'
import CardDetailCar from 'components/ui/card/card-detail-car'
import CardInformationShowroom from 'components/ui/card/card-information-showroom'
import InspeksiSelesaiJual from '../card-status-transaction/jual/inspeksi-selesai-jual'
import PenjadwalanBeli from '../card-status-transaction/beli/penjadwalan-beli'
import CardRecommendationPrice from '../card-recommendation-price'

const JualContentTransaction = ({ data, status, navigateHandler }) => {
  const CardStatusTransactionJualMap = {
    'inspeksi terjadwal': (
      <PenjadwalanBeli
        confirmed={data?.inspection?.is_confirm}
        serviceName={'inspeksi'}
        data={{
          date: data?.inspection?.inspection_date,
          start_time: data?.inspection?.start_hour
        }}
      />
    ),
    'inspeksi selesai': (
      <InspeksiSelesaiJual
        data={{ date: data?.inspection?.inspection_date, end_time: data?.inspection?.end_hour }}
      />
    ),
    published: <PublishedJual />,
    booked: <BookedUnitJual jumlah_booked={data?.catalog?.booking_count} />,
    terjual: (
      <TerjualJual
        data={{ total_price: data?.catalog?.price, buyer_name: data?.booking?.buyer_fullname }}
      />
    ),
    selesai: (
      <SelesaiJual
        data={{
          date: data?.catalog?.sold_at,
          buyer_name: data?.booking?.buyer_fullname,
          total_price: data?.catalog?.price
        }}
      />
    )
  }
  return (
    <>
      {CardStatusTransactionJualMap[status]}
      {
        status !== 'terjual' &&
        status !== 'selesai' &&
        <CardRecommendationPrice data={data} />
      }
      <CardDetailCar data={data} navigateDetailHandler={navigateHandler} />
      <CardInformationShowroom
        headerTitle={'Informasi Showroom'}
        data={{
          name: data?.workshop?.name,
          alamat: data?.workshop?.location_name
        }}
      />
    </>
  )
}

export default JualContentTransaction
