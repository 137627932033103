const WrenchIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      stroke="#F79009"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4983 9.50001C21.1929 9.81156 21.0219 10.2304 21.0219 10.6667C21.0219 11.1029 21.1929 11.5218 21.4983 11.8333L24.165 14.5C24.4765 14.8054 24.8954 14.9764 25.3316 14.9764C25.7679 14.9764 26.1867 14.8054 26.4983 14.5L32.7816 8.21667C33.6197 10.0687 33.8734 12.1321 33.5091 14.1319C33.1447 16.1318 32.1795 17.9731 30.7421 19.4105C29.3047 20.8479 27.4634 21.8131 25.4635 22.1774C23.4637 22.5418 21.4003 22.2881 19.5483 21.45L8.03163 32.9667C7.36859 33.6297 6.46931 34.0022 5.53163 34.0022C4.59395 34.0022 3.69467 33.6297 3.03163 32.9667C2.36859 32.3036 1.99609 31.4044 1.99609 30.4667C1.99609 29.529 2.36859 28.6297 3.03163 27.9667L14.5483 16.45C13.7102 14.598 13.4565 12.5346 13.8209 10.5348C14.1852 8.53491 15.1504 6.6936 16.5878 5.25621C18.0252 3.81882 19.8665 2.85362 21.8664 2.48924C23.8662 2.12487 25.9296 2.37861 27.7816 3.21667L21.515 9.48334L21.4983 9.50001Z"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default WrenchIcon
