import React, { useRef } from 'react'
import {
  CardCicilan,
  CardCTA,
  CardCustomBeli,
  CardImageUnit,
  // CardPromoBeliMobil,
  CardSpesifikasiTaxi,
  // CardTestimoniBeliMobil
} from './card'

function ExTaxiDetail({ dataTaxi, cyoc, handleCYOC, resetCYOC, selectedSpareparts, colors, handleSelectedColor, selectedColor }) {
  const cicilanRef = useRef()
  const handleNavToCicilan = () => {
    window.scrollTo({
      top: cicilanRef.current.offsetTop,
      behavior: 'smooth'
    })
  }
  return (
    <div className="grid grid-cols-1 md:grid-cols-[1fr_389px] gap-6">
      {/* Car Detail */}
      <div>
        <div className="flex flex-col gap-6">
          <div>
            <CardImageUnit isTaxi data={dataTaxi} />
          </div>
          <div className="flex flex-col gap-6 md:hidden">
            <CardCTA
              handleNavToCicilan={handleNavToCicilan}
              isTaxi
              dataUnit={dataTaxi}
              colors={colors}
              selectedColor={selectedColor}
              handleSelectedColor={handleSelectedColor}
            />
            {cyoc ? (
              <CardCustomBeli
                cyoc={cyoc}
                handleCYOC={handleCYOC}
                resetCYOC={resetCYOC}
                selectedSpareparts={selectedSpareparts}
              />
            ) : null}
          </div>
          <CardSpesifikasiTaxi data={dataTaxi} />
          {dataTaxi.price !== undefined && <CardCicilan ref={cicilanRef} price={dataTaxi.price} />}
          {/* <div className="hidden md:block">
            <CardPromoBeliMobil />
          </div>
          <div className="hidden md:block">
            <CardTestimoniBeliMobil />
          </div> */}
        </div>
      </div>
      {/* Price & Customize Car */}
      <div>
        <div className="hidden md:flex flex-col gap-6">
          <CardCTA
            handleNavToCicilan={handleNavToCicilan}
            isTaxi
            dataUnit={dataTaxi}
            colors={colors}
            selectedColor={selectedColor}
            handleSelectedColor={handleSelectedColor}
          />
          {cyoc ? (
            <CardCustomBeli
              cyoc={cyoc}
              handleCYOC={handleCYOC}
              resetCYOC={resetCYOC}
              selectedSpareparts={selectedSpareparts}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ExTaxiDetail
