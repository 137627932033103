import { Col, DatePicker, Row } from 'antd'
import CustomButton from 'components/ui/custom-button'
import FormSelect from 'components/ui/form/form-select'
import dayjs from 'dayjs'
import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

function TabSellContent({
  handleChangeCarList,
  handleClickJualForm,
  handleChange,
  price,
  brands,
  transmissions,
  models,
  types,
  state,
  changeComponent,
  handleClick
}) {
  const informasiMobilInput = [
    {
      id: 1,
      label: 'Merek',
      name: 'merek',
      type: 'select',
      options: brands,
      mandatory: true,
      placeholder: 'Pilih Merek',
      value: state?.brand?.value,
      onChange: (_, option) => {
        handleChangeCarList('brand', option)
      }
    },
    {
      id: 2,
      label: 'Model',
      name: 'model',
      type: 'select',
      options: models,
      mandatory: true,
      placeholder: 'Pilih Model',
      value: state?.model?.value,
      onChange: (_, option) => {
        handleChangeCarList('model', option)
      }
    },
    {
      id: 3,
      label: 'Tipe',
      name: 'tipe',
      type: 'select',
      options: types,
      mandatory: true,
      placeholder: 'Pilih Tipe',
      value: state?.type?.value,
      onChange: (_, option) => {
        handleChangeCarList('type', option)
      }
    },
    {
      id: 4,
      label: 'Transmisi',
      name: 'transmisi',
      type: 'select',
      options: transmissions,
      mandatory: true,
      placeholder: 'Pilih Transmisi',
      value: state?.transmisi?.value,
      onChange: (_, option) => {
        handleChange('transmisi', option)
      }
    }
  ]
  return (
    <Row className="bg-white h-full w-full border border-solid border-gray-200 shadow-lg">
      <Row className="w-full px-5 mt-4 py-4">
        {changeComponent ? (
          <>
            <Col span={24} className={'grid grid-cols-3 gap-[20px]'}>
              {informasiMobilInput.map((data, index) => (
                <FormSelect key={index} data={data} />
              ))}
              <div className="grid grid-cols-1 gap-2">
                <p className="text-sm-medium text-gray-700">
                  Tahun Keluaran <span className="text-danger-500">*</span>
                </p>
                <DatePicker
                  placeholder={'Tahun'}
                  onChange={(_, dateString) => handleChange('year', dateString)}
                  picker="year"
                  value={state?.year !== '' ? dayjs(`${state?.year}/01/01`, 'YYYY/MM/DD') : ''}
                />
              </div>
            </Col>
            <Col span={24} className="flex justify-center items-center gap-6 mt-6">
              <CustomButton onClick={handleClick} type="secondary" label="Cek Harga Mobil" />
              <span className="text-md-regular text-gray-600">Atau</span>
              <CustomButton onClick={handleClickJualForm} label="Jual Mobil" />
            </Col>
          </>
        ) : (
          <Col span={24}>
            {/* Button Container */}
            <div>
              <div onClick={handleClick} className="flex gap-2 cursor-pointer items-center">
                <AiOutlineArrowLeft className="fill-gray-500" />
                <p className="text-md-medium text-gray-500">Kembali</p>
              </div>
            </div>
            {/* Estimate Price */}
            <div className="flex flex-col justify-center items-center gap-3">
              <p className="text-md-regular text-gray-600">
                Berikut estimasi harga jual untuk mobil
              </p>
              <p className="text-xl-medium text-gray-900">{`${state?.brand?.label} ${state.model?.label} ${state?.type?.label}`}</p>
            </div>
            <div className="flex flex-col justify-center items-center py-9 gap-2 display-sm-semibold text-warning-500">
              {price?.min_price !== '-' ? (
                <>
                  <p>Rp. {price?.min_price}</p>
                  <p className="text-md-regular text-gray-600">sampai</p>
                  <p>Rp. {price?.max_price}</p>
                </>
              ) : (
                <p className="text-md-regular">Belum ada rekomendasi harga</p>
              )}
            </div>
            <div className="flex flex-col gap-3 justify-center items-center">
              <p className="text-center text-gray-600 text-md-regular">
                Ingin mendapatkan penawaran harga dari Kami?
              </p>
              <CustomButton onClick={handleClickJualForm} label={'Lanjut Jual Mobil'} />
            </div>
          </Col>
        )}
      </Row>
    </Row>
  )
}

export default TabSellContent
