import { Col, Divider } from 'antd'
import React from 'react'

function CardSectionHeader({ headerTitle = '', children, hideDivider, heightMax, className = '' }) {
  return (
    <Col
      span={24}
      className={`flex flex-col bg-white border p-3 border-solid border-gray-200 ${
        heightMax ? 'h-max' : 'h-full'
      } md:p-6 rounded-[4px] ${className}`}>
      {/* Header Form */}
      {headerTitle ? (
        <div
          className={`flex flex-col gap-5 text-xl-medium text-gray-900 ${
            !hideDivider ? 'mb-5' : 'mb-0'
          }`}>
          {headerTitle}
          {!hideDivider && <Divider className="m-0" />}
        </div>
      ) : null}
      {children}
    </Col>
  )
}

export default CardSectionHeader
