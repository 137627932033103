const ServiceIcon = ({ className, stroke = '#667085' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2491 5.24994C11.0965 5.40572 11.0109 5.61515 11.0109 5.83328C11.0109 6.0514 11.0965 6.26083 11.2491 6.41661L12.5825 7.74994C12.7383 7.90263 12.9477 7.98816 13.1658 7.98816C13.3839 7.98816 13.5934 7.90263 13.7491 7.74994L16.8908 4.60828C17.3098 5.53427 17.4367 6.56597 17.2545 7.5659C17.0723 8.56582 16.5897 9.48648 15.871 10.2052C15.1524 10.9239 14.2317 11.4065 13.2318 11.5887C12.2318 11.7708 11.2001 11.644 10.2741 11.2249L4.51581 16.9833C4.18429 17.3148 3.73465 17.501 3.26581 17.501C2.79697 17.501 2.34733 17.3148 2.01581 16.9833C1.68429 16.6518 1.49805 16.2021 1.49805 15.7333C1.49805 15.2644 1.68429 14.8148 2.01581 14.4833L7.77415 8.72494C7.35512 7.79895 7.22824 6.76725 7.41043 5.76732C7.59262 4.7674 8.07522 3.84674 8.79391 3.12804C9.51261 2.40935 10.4333 1.92675 11.4332 1.74456C12.4331 1.56237 13.4648 1.68925 14.3908 2.10828L11.2575 5.24161L11.2491 5.24994Z"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ServiceIcon
