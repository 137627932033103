import useValidation from 'lib/useValidation'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  getAdminPrice,
  getBookingPrice,
  getCities,
  getDetailCatalog,
  getDetailCatalogTaxi,
  getProvinces,
  getSubdistricts,
  getTaxPrice,
  postBookingCatalog,
  postBookingTaxi
} from '../service'
import { getRangeBookingProcess } from '../components/service'
import { showErrorMessage } from 'utils/toastMessage'
import { paymentList } from '../data/paymentList'
import { getPaymentMethod } from 'utils/getPaymentMethod'

const initialError = {
  nama: false,
  phone: false,
  email: false,
  province: false,
  city: false,
  subdistrict: false,
  postal_code: false,
  alamat: false
}
const initialPrice = {
  tax: 0,
  bookingPrice: 0,
  admin_fee: 0
}
const useBookingCatalog = (
  replace,
  selectedPayment,
  idCatalog,
  isTaxi,
  getPaymentObject,
  state,
  colorId,
  selectedCreditCard,
  creditCards
) => {
  const taxPrice = useRef(0)
  const totalPrice = useRef(0)
  const user = JSON.parse(localStorage.getItem('user'))
  const [modalOpen, setModalOpen] = useState(false)
  const [bookingPrice, setBookingPrice] = useState({ ...initialPrice })
  const [errors, setErrors] = useState({ ...initialError })
  const [dataUser, setDataUser] = useState({
    nama: user?.name || user?.fullname || '',
    phone: user?.phone_number || '',
    email: user?.email || '',
    alamat: user?.address || '',
    province: {
      label: user?.province_name || null,
      value: user?.province_id || null
    },
    city: {
      label: user?.city_name || null,
      value: user?.city_id || null
    },
    subdistrict: {
      label: user?.sub_district_name || null,
      value: user?.sub_district_id || null
    },
    postal_code: user?.postal_code || ''
  })
  const [dataCar, setDataCar] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [bookingProcessDate, setBookingProcessDate] = useState(null)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [subdistricts, setSubdistricts] = useState([])
  const { nameValidation, phonenumberValidation, emailValidation, addressValidation } =
    useValidation()
  useEffect(() => {
    taxPrice.current =
      (bookingPrice?.bookingPrice + bookingPrice?.admin_fee) * (bookingPrice.tax / 100) || 0
    totalPrice.current = bookingPrice.bookingPrice + bookingPrice?.admin_fee + taxPrice.current
    // console.log(taxPrice.current, totalPrice.current)
  }, [bookingPrice])
  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: ['getCities', dataUser?.province?.value],
    queryFn: () => getCities(dataUser?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    enabled: dataUser?.province?.value !== undefined,
    staleTime: 0
  })
  useQuery({
    queryKey: ['getSubdistricts', dataUser?.city?.value],
    queryFn: () => getSubdistricts(dataUser?.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts(newData)
    },
    enabled: dataUser?.city?.value !== undefined,
    staleTime: 0
  })
  useQuery({
    queryKey: 'getDetailCatalog',
    queryFn: () => getDetailCatalog(idCatalog),
    onSuccess: (data) => {
      setDataCar(data)
      // console.log('data', data)
    },
    enabled: !!idCatalog && !isTaxi
  })
  useQuery({
    queryKey: 'getBookingPrice',
    queryFn: () => getBookingPrice(1),
    onSuccess: (data) => {
      setBookingPrice((prev) => ({
        ...prev,
        bookingPrice: data.price
      }))
    }
  })
  useQuery({
    queryKey: 'getAdminPrice',
    queryFn: () => getAdminPrice(),
    onSuccess: (data) => {
      setBookingPrice((prev) => ({
        ...prev,
        admin_fee: data.price
      }))
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getRangeBookingProcess',
    queryFn: () => getRangeBookingProcess(idCatalog),
    onSuccess: (data) => {
      setBookingProcessDate(data)
    },
    enabled: !!idCatalog && !isTaxi,
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getTaxPrice',
    queryFn: () => getTaxPrice(),
    onSuccess: (data) => {
      setBookingPrice((prev) => ({
        ...prev,
        tax: data.percentage
      }))
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: ['getDetailCatalogTaxis'],
    queryFn: () => getDetailCatalogTaxi(idCatalog),
    onSuccess: (data) => {
      const newData = {
        ...data
      }
      setDataCar(newData)
    },
    enabled: !!idCatalog && isTaxi
  })
  const postBookingCatalogs = useMutation({
    mutationFn: (dataSend) => postBookingCatalog(dataSend),
    onSuccess: (datas) => {
      setIsLoading(false)
      const { creditCard, paymentType } = getPaymentMethod(
        datas?.payment_method,
        datas?.payment_details,
        creditCards
      )
      if (
        datas?.payment_details?.card?.authorization_url !== '' &&
        datas?.payment_details?.card?.authorization_url !== undefined
      ) {
        window.open(datas?.payment_details?.card?.authorization_url)
      }
      replace('/beli/mobil-bekas/booking/payment', {
        paymentDetails: {
          payment_method: datas?.payment_method,
          payment_status: datas?.payment_status,
          payment_details: datas?.payment_details
        },
        totalPrice: totalPrice.current,
        taxPrice: null,
        price: {
          tax: null,
          bookingPrice: bookingPrice.bookingPrice,
          admin_fee: null
        },
        selectedPayment: paymentType,
        selectedCreditCard: creditCard,
        transactionId: datas?.transaction_id,
        serviceType: 'beli',
        id: datas?.transaction_id
      })
    },
    onError: (data) => {
      setIsLoading(false)
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })
  const postBookingTaxis = useMutation({
    mutationFn: (dataSend) => postBookingTaxi(dataSend),
    onSuccess: (datas) => {
      setIsLoading(false)
      const { creditCard, paymentType } = getPaymentMethod(
        datas?.payment_method,
        datas?.payment_details,
        creditCards
      )
      if (
        datas?.payment_details?.card?.authorization_url !== '' &&
        datas?.payment_details?.card?.authorization_url !== undefined
      ) {
        window.open(datas?.payment_details?.card?.authorization_url)
      }

      replace('/beli/ex-taxi/booking/payment', {
        paymentDetails: {
          payment_method: datas?.payment_method,
          payment_status: datas?.payment_status,
          payment_details: datas?.payment_details
        },
        totalPrice: totalPrice.current,
        taxPrice: null,
        price: {
          tax: null,
          bookingPrice: bookingPrice.bookingPrice,
          admin_fee: null
        },
        selectedPayment: paymentType,
        selectedCreditCard: creditCard,
        transactionId: datas?.transaction_id,
        serviceType: 'beli',
        id: datas?.transaction_id
      })
    },
    onError: (data) => {
      setIsLoading(false)
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })
  const handleValidation = (name, value) => {
    let err = ''

    switch (name) {
      case 'nama':
        err = nameValidation(value)
        break
      case 'phone':
        err = phonenumberValidation(value)
        break
      case 'email':
        err = emailValidation(value)
        break
      case 'alamat':
        err = addressValidation(value)
        break
      default:
        err = ''
    }
    return err
  }
  // Functions

  const handleChange = (e) => {
    const { name, value } = e.target
    let err = ''
    err = handleValidation(name, value)
    setDataUser((prev) => ({
      ...prev,
      [name]: value
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: err
    }))
  }
  const handleChangeLocation = (name, value) => {
    let newValue = {}
    if (name === 'province') {
      setCities([])
      setSubdistricts([])
      newValue = {
        ...dataUser,
        province: value,
        city: {},
        subdistrict: {}
      }
    } else if (name === 'city') {
      setSubdistricts([])
      newValue = {
        ...dataUser,
        city: value,
        subdistrict: {}
      }
    } else {
      newValue = {
        ...dataUser,
        subdistrict: value
      }
    }
    setDataUser(newValue)
  }
  const submitBookingMobil = () => {
    setIsLoading(true)
    const cyocIds =
      Object.keys(state?.cyoc_spareparts).length > 0
        ? Object.entries(state?.cyoc_spareparts).map(([, { id }]) => id)
        : []
    const dataSend = {
      id: idCatalog,
      fullname: dataUser?.nama,
      phone_number: dataUser?.phone,
      email: dataUser?.email,
      address: dataUser?.alamat,
      cyoc_spareparts: cyocIds,
      payment: getPaymentObject(),
      province_id: dataUser?.province?.value,
      city_id: dataUser?.city?.value,
      subdistrict_id: dataUser?.subdistrict?.value,
      postal_code: dataUser?.postal_code
    }
    if (isTaxi) {
      dataSend.color_id = colorId
    }
    isTaxi ? postBookingTaxis.mutate(dataSend) : postBookingCatalogs.mutate(dataSend)
  }
  return {
    isLoading,
    taxPrice,
    totalPrice,
    bookingPrice,
    dataCar,
    dataUser,
    errors,
    modalOpen,
    provinces,
    cities,
    subdistricts,
    handleChange,
    handleChangeLocation,
    setModalOpen,
    submitBookingMobil,
    bookingProcessDate
  }
}

export default useBookingCatalog
