import { Divider } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import { capitalize } from 'lodash'
import React from 'react'
import moment from 'moment'

const PenjadwalanBeli = ({ serviceName, confirmed, data }) => {
  // console.log(data)
  return (
    <CardSectionHeader
      headerTitle={
        confirmed ? `${capitalize(serviceName)} Dijadwalkan` : `Penjadwalan ${serviceName}`
      }>
      <div className="flex flex-col gap-3">
        <p className="text-md-regular text-gray-600">
          {confirmed
            ? `Jadwal ${serviceName} unit Anda telah dikonfirmasi oleh Admin`
            : 'Sedang menunggu konfirmasi admin..'}
        </p>
        {confirmed ? (
          <>
            <Divider className="m-0" />
            <div className=" grid grid-cols-1 gap-2 sm:gap-0 sm:flex justify-between items-center">
              <>
                <p className="text-md-regular text-gray-600">
                  {capitalize(serviceName)} dimulai pada:{' '}
                </p>
                <p className="text-md-medium text-gray-700 text-right">
                  {moment(data?.date).format('DD MMM YYYY')} - {data?.start_time} WIB
                </p>
              </>
            </div>
          </>
        ) : null}
      </div>
    </CardSectionHeader>
  )
}

export default PenjadwalanBeli
