import { DatePicker } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import FormSelect from 'components/ui/form/form-select'
import moment from 'moment'

function CardJadwalTestDrive({ inspeksi, servis, times, schedule, handleChangeSchedule }) {
  const label = servis ? 'Servis' : inspeksi ? 'Inspeksi' : 'Test Drive'
  const data = {
    label: 'Jam',
    name: 'hours',
    type: 'select',
    mandatory: true,
    value: schedule?.hours?.id,
    onChange: (_, option) => {
      handleChangeSchedule('hours', option)
    },
    disabled: schedule.date ? false : true,
    options: times,
    placeholder: 'Pilih Jam'
  }
  return (
    <CardSectionHeader headerTitle={`Jadwal ${label}`} hideDivider>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        {/* jadwal */}
        <div className="grid grid-cols-1 gap-2">
          <p className="text-sm-medium text-gray-700">
            Tanggal <span className="text-danger-500">*</span>
          </p>
          <DatePicker
            disabledDate={(current) => {
              let customDate = null
              customDate = moment().add(0, 'days').format('DD/MM/YYYY')
              const currentHour = new Date().getHours()
              if (currentHour >= times[times.length - 1]?.time?.split('-')[0]?.split(':')[0]) {
                customDate = moment().add(1, 'days').format('DD/MM/YYYY')
              } else {
                customDate = moment().format('DD/MM/YYYY')
              }
              return current && current < moment(customDate, 'DD/MM/YYYY')
            }}
            placeholder="Pilih Tanggal"
            className="rounded-[4px] flex justify-center text-md-normal p-2 gap-3 items-center"
            format="DD/MM/YYYY"
            value={schedule.date}
            onChange={(date) => handleChangeSchedule('date', date)}
          />
        </div>
        <FormSelect data={data} />
      </div>
    </CardSectionHeader>
  )
}

export default CardJadwalTestDrive
