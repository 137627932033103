const DashboardIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33333 1.5H1.5V7.33333H7.33333V1.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 1.5H10.6667V7.33333H16.5V1.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 10.6667H10.6667V16.5H16.5V10.6667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33333 10.6667H1.5V16.5H7.33333V10.6667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DashboardIcon
