import CardSectionHeader from 'components/ui/card-section-header'
import FormInput from 'components/ui/form/form-input'
import FormTextArea from 'components/ui/form/form-textarea'
import React from 'react'
import FormSelect from '../form/form-select'

function CardFormPesanMobil({
  data,
  errors,
  handleChange,
  handleChangeLocation = () => { },
  provinces = [],
  cities = [],
  subdistricts = []
}) {
  const inputs = [
    {
      id: 1,
      label: 'Nama Lengkap',
      name: 'nama',
      type: 'text',
      placeholder: 'Masukan Nama Lengkap',
      mandatory: true,
      value: data?.nama,
      child: null,
      onChange: (e) => {
        handleChange(e)
      }
    },
    {
      id: 2,
      label: 'Nomor Telepon',
      name: 'phone',
      type: 'text',
      placeholder: 'Nomor Telepon',
      mandatory: true,
      addonBefore: '+62',
      value: data?.phone,
      child: null,
      onChange: (e) => {
        handleChange(e)
      }
    },
    {
      id: 3,
      label: 'Email',
      name: 'email',
      type: 'text',
      placeholder: 'Masukan Email',
      mandatory: true,
      value: data?.email,
      child: null,
      onChange: (e) => {
        handleChange(e)
      }
    },
    {
      id: 4,
      child: [
        {
          id: '4.1',
          name: 'province',
          type: 'select',
          placeholder: 'Pilih Provinsi',
          mandatory: true,
          value: data?.province?.value,
          label: 'Provinsi',
          options: provinces,
          onChange: (_, option) => {
            handleChangeLocation('province', option)
          }
        },
        {
          id: '4.2',
          name: 'city',
          type: 'select',
          placeholder: 'Pilih Kota',
          mandatory: true,
          value: data?.city?.value,
          options: cities,
          label: 'Kota',
          onChange: (_, option) => {
            handleChangeLocation('city', option)
          }
        },
      ],
    },
    {
      id: 5,
      child: [
        {
          id: '5.1',
          name: 'subdistrict',
          type: 'select',
          placeholder: 'Pilih Kecamatan',
          mandatory: true,
          value: data?.subdistrict?.value,
          options: subdistricts,
          label: 'Kecamatan',
          onChange: (_, option) => {
            handleChangeLocation('subdistrict', option)
          }
        },
        {
          id: '5.2',
          name: 'postal_code',
          type: 'input',
          placeholder: 'Kode Pos',
          mandatory: true,
          label: 'Kode POS',
          value: data?.postal_code,
          onChange: (e) => {
            handleChange(e)
          }
        },
      ],
    },
    {
      id: 6,
      rows: 3,
      label: 'Alamat',
      // style: { height: 120, resize: 'none' },
      name: 'alamat',
      type: 'textarea',
      placeholder: 'Masukan Alamat',
      mandatory: true,
      value: data?.alamat,
      child: null,
      onChange: (e) => {
        handleChange(e)
      }
    }
  ]
  return (
    <CardSectionHeader headerTitle={`Data Diri`} hideDivider>
      <div className="grid grid-cols-1 gap-6 mt-6">
        {inputs.map((inputItem) => {
          if (inputItem.child === null) {
            if (inputItem?.type === 'text')
              return (
                <React.Fragment key={inputItem?.id}>
                  <FormInput data={inputItem} />
                  {errors[inputItem?.name] ? (
                    <small className="text-primary-300">{errors[inputItem?.name]}</small>
                  ) : null}
                </React.Fragment>
              )
            else if (inputItem?.type === 'textarea')
              return (
                <React.Fragment key={inputItem.id}>
                  <FormTextArea data={inputItem} />
                  {errors[inputItem?.name] ? (
                    <small className="text-primary-300">{errors[inputItem?.name]}</small>
                  ) : null}
                </React.Fragment>
              )
          } else {
            if (window.location.pathname.includes('test-drive')) {
              return null
            } else {
              return (
                <div key={inputItem.id} className='grid grid-cols-2 gap-6'>
                  {
                    inputItem.child.map((item) => {
                      if (item.type === 'select') {
                        return (
                          <FormSelect data={item} key={item.id} />
                        )
                      } else {
                        return (
                          <FormInput data={item} key={item.id} />
                        )
                      }
                    })
                  }
                </div>
              )
            }
          }
        })}
      </div>
    </CardSectionHeader>
  )
}

export default CardFormPesanMobil
