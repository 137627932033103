import { Divider, Spin } from 'antd'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
import CardSectionHeader from 'components/ui/card-section-header'
import { onRupiah } from 'utils/formatCurrency'
const MenungguPembayaranServis = ({
  data,
  validationCheckPaymentMethod,
  isCompanyAdmin,
  isLoading,
  isPersonal
}) => {
  return (
    <CardSectionHeader headerTitle="Konfirmasi Pembayaran">
      <>
        <div className="flex justify-between items-center">
          <>
            <p className="text-md-regular text-gray-600">Segera Lakukan Pembayaran</p>
            <p className="text-md-medium text-gray-700 whitespace-nowrap">
              Rp. {onRupiah(data?.booking_invoice?.price_total)}
            </p>
          </>
        </div>
        <Divider />
        {isCompanyAdmin ? (
          <div className="flex justify-end">
            <Spin spinning={isLoading}>
              <CustomButton label={'Lakukan Pembayaran'} onClick={validationCheckPaymentMethod} />
            </Spin>
          </div>
        ) : null}
        {isPersonal ? (
          <div className="flex justify-end">
            <Spin spinning={isLoading}>
              <CustomButton label={'Lakukan Pembayaran'} onClick={validationCheckPaymentMethod} />
            </Spin>
          </div>
        ) : null}
      </>
    </CardSectionHeader>
  )
}

export default MenungguPembayaranServis
