import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

function CardInformationShowroom({ data, headerTitle }) {
  return (
    <CardSectionHeader headerTitle={headerTitle}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <p className="text-sm-regular text-gray-600">Tempat</p>
          <p className="text-md-medium text-gray-900">{data?.name}</p>
        </div>
        <div>
          <p className="text-sm-regular text-gray-600">Alamat</p>
          <p className="text-md-medium text-gray-900">{data?.alamat}</p>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardInformationShowroom
