import { ImagePlaceholder, SampleCar } from 'assets'
import React, { useState } from 'react'
import CardSectionHeader from '../card-section-header'
import ModalImage from '../modal/modal-image'
import { CarouselMultipleLayout } from '../CarouselMultipleLayout'
const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
function CardImage({ data }) {
  const [openModalImage, setOpenModalImage] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const handleCloseModalImage = () => {
    setOpenModalImage(false)
  }
  const handleOpenModalImage = (imageUrl) => {
    setPreviewImage(imageUrl)
    setOpenModalImage(true)
  }
  return (
    <>
      <ModalImage
        openModal={openModalImage}
        onCancel={handleCloseModalImage}
        imageUrl={previewImage ? urlImage.concat(previewImage) : ''}
      />
      <CardSectionHeader headerTitle={'Foto Mobil'}>
        <CarouselMultipleLayout
          slideToShow={5}
          slidesToScroll={5}
          totalItem={data?.length}
          className="overflow-hidden">
          {data && data?.length > 0 ? (
            data.map((dataItem) => (
              <div
                key={dataItem?.id}
                onClick={() => handleOpenModalImage(dataItem?.photo_filename)}
                className="bg-gray-100 rounded-md overflow-hidden cursor-pointer">
                <img
                  src={urlImage.concat(dataItem?.photo_filename)}
                  className=" w-full h-[100px] object-cover"
                  alt=""
                />
              </div>
            ))
          ) : (
            <div className="bg-gray-100 rounded-md overflow-hidden">
              <img
                src={ImagePlaceholder}
                className="w-full h-full max-h-[140px] object-cover"
                alt=""
              />
            </div>
          )}
        </CarouselMultipleLayout>
      </CardSectionHeader>
    </>
  )
}

export default CardImage
