import { Col, Row } from "antd"
import CardSectionHeader from "components/ui/card-section-header"
import { onRupiah } from "utils/formatCurrency"

function CardRincianBiaya({ isShowRoom, homeState, price, taxPrice, totalPrice }) {
  return (
    <CardSectionHeader headerTitle="">
      <div className="grid grid-cols-1 gap-4 text-md-medium text-gray-700">
        <div>Rincian Biaya</div>
        <div className="flex justify-between items-center pb-2">
          <p className="text-md-regular text-gray-500">Biaya Inspeksi {isShowRoom ? '' : 'di Rumah'}</p>
          <p>Rp. {onRupiah(price?.inspection)}</p>
        </div>
        {
          !isShowRoom && (
            <div className="flex justify-between items-center pb-2">
              <p className="text-md-regular text-gray-500">Biaya Jarak</p>
              <p>Rp. {
                homeState?.range_price?.price !== undefined ?
                  onRupiah(homeState?.range_price?.price) :
                  '-'
              }</p>
            </div>
          )
        }
        {
          price?.admin_fee !== 0 && (
            <div className="flex justify-between items-center pb-2">
              <p className="text-md-regular text-gray-500">Biaya Admin</p>
              <p>Rp. {onRupiah(price?.admin_fee)}</p>
            </div>
          )
        }
        <div className="flex justify-between items-center pb-2">
          <p className="text-md-regular text-gray-500">Pajak {price.tax}%</p>
          <p>Rp. {onRupiah(taxPrice)}</p>
        </div>
        <div className="flex justify-between items-center border-t pt-2">
          <p className="text-md-regular text-gray-500">Total Biaya</p>
          <p>Rp. {onRupiah(totalPrice)}</p>
        </div>
        <Row className="p-4 bg-gray-50 border-gray-200 border">
          <Col span={16} className="text-md-regular text-gray-600">Total biaya yang harus dibayarkan</Col>
          <Col span={8} className="flex items-center justify-end">Rp. {parseInt(totalPrice).toLocaleString('ID-id')}</Col>
        </Row>
      </div>
    </CardSectionHeader>
  )
}

export default CardRincianBiaya