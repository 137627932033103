import { useState, useCallback } from "react";
import ReportTypeTemplate from "./report-type-template";
import { useQuery } from "react-query";
import { getReportList } from "../service";
import moment from 'moment'
import { debounce } from "lodash";
import { onRupiah } from "utils/formatCurrency";
import { Typography } from "antd";

const initialFilter = {
	offset: 0,
	limit: 5,
	start_date: '',
	end_date: ''
}

function ReportTypeExpense() {
	const token = localStorage.getItem('token')
	const [reportData, setReportData] = useState(null)
	const [currentPage, setCurrentPage] = useState(1)
	const [filter, setFilter] = useState({ ...initialFilter })

	const debouncedChangeHandler = useCallback(
		debounce(() => {
			refetch()
		}, 500),
		[]
	)

	const handleChangeSelectedFilter = (name, val) => {
		setFilter(prev => ({
			...prev,
			[name]: val
		}))
		return debouncedChangeHandler()
	}
	const handleChangeFilterIncludeOffset = (name, value) => {
		setFilter(prev => ({
			...prev,
			[name]: value,
			offset: 0
		}))
		return debouncedChangeHandler()
	}
	const handleChangeDate = (val) => {
		if (val !== null) {
			const startDate = moment(val[0]).format('DD/MM/YYYY')
			const endDate = moment(val[1]).format('DD/MM/YYYY')
			setFilter(prev => ({
				...prev,
				start_date: startDate,
				end_date: endDate
			}))
		} else {
			setFilter({ ...initialFilter })
		}
		return debouncedChangeHandler()
	}

	const onChangeNextPage = () => {
		if (currentPage !== Math.ceil(reportData?.meta?.total / filter.limit)) {
			handleChangeSelectedFilter('offset', currentPage * filter.limit)
			setCurrentPage(prev => prev + 1)
		}
	}
	const onChangePrevPage = () => {
		if (currentPage > 1) {
			handleChangeSelectedFilter('offset', filter.offset - filter.limit)
			setCurrentPage(prev => prev - 1)
		}
	}

	const { refetch } = useQuery(['getReportList', debouncedChangeHandler], {
		queryFn: () => {
			const dataSend = {
				token: token,
				params: {
					limit: filter.limit,
					offset: filter.offset,
					start_date: String(filter.start_date),
					end_date: String(filter.end_date),
					payment_status: 'paid'
				}
			}

			return getReportList(dataSend)
		},
		onSuccess: (data) => {
			setReportData(data)
		},
		enabled: Boolean(debouncedChangeHandler)
	})

	const columns = [
		{
			name: 'Tanggal',
			selector: row => row.created_at,
			cell: (row) => moment(row.created_at).format('DD MMM YYYY'),
			sortable: true,
			width: '150px',
		},
		{
			name: 'Nama Unit',
			selector: row => (
				<div className="flex flex-col space-y-1 overflow-hidden">
					<Typography.Text
						className="text-sm-medium"
						ellipsis={{
							tooltip: row?.unit_name
						}}>
						{row?.unit_name}
					</Typography.Text>
					<p className='text-xs-regular mt-1'>{row.unit_police_number}</p>
				</div>
			),
			sortable: true,
			width: '30%'
		},
		{
			name: 'User',
			selector: row => row.user_fullname,
			cell: row => (
				<Typography.Text
					// className="text-sm-medium"
					ellipsis={{
						tooltip: row?.user_fullname
					}}>
					{row?.user_fullname}
				</Typography.Text>
			),
			sortable: true,
		},
		{
			name: 'Layanan',
			selector: row => row.transaction_type,
			sortable: true,
		},
		{
			name: 'Biaya',
			selector: row => <p className='text-warning-500'>{`Rp. ${onRupiah(row.transaction_profit)}`}</p>,
			sortable: true,
		},
	]

	return (
		<ReportTypeTemplate
			columns={columns}
			data={reportData?.data || []}
			currentPage={filter?.offset === 0 ? 1 : filter?.offset + 1}
			totalPerPage={(currentPage * filter?.limit) >= reportData?.meta.total ? reportData?.meta.total : currentPage * filter?.limit}
			lengthAllData={reportData?.meta?.total}
			defaultPageSize={filter?.limit}
			onChangePageSize={handleChangeFilterIncludeOffset}
			onChangeNextPage={onChangeNextPage}
			onChangePrevPage={onChangePrevPage}
			handleChangeDate={handleChangeDate}
			type="Pengeluaran"
		/>
	)
}

export default ReportTypeExpense;