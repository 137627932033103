const BriefCaseIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      stroke="#A21226"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3327 16.5V3.16667C13.3327 2.72464 13.1571 2.30072 12.8445 1.98816C12.532 1.67559 12.108 1.5 11.666 1.5H8.33268C7.89066 1.5 7.46673 1.67559 7.15417 1.98816C6.84161 2.30072 6.66602 2.72464 6.66602 3.16667V16.5M3.33268 4.83333H16.666C17.5865 4.83333 18.3327 5.57953 18.3327 6.5V14.8333C18.3327 15.7538 17.5865 16.5 16.666 16.5H3.33268C2.41221 16.5 1.66602 15.7538 1.66602 14.8333V6.5C1.66602 5.57953 2.41221 4.83333 3.33268 4.83333Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BriefCaseIcon
