import React, { useState } from 'react'
import { CarCustomIcon, CheckIcon, InfoIcon, RefreshIcon } from 'assets'
import { Collapse } from 'antd'
import { ModalPreviewCustom } from '../modal'
import { ModalLayout } from 'layouts'
import { onRupiah } from 'utils/formatCurrency'

const { Panel } = Collapse
// eslint-disable-next-line no-undef
const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
function CardCustomBeli({ cyoc, resetCYOC, handleCYOC, selectedSpareparts }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [detailSparepart, setDetailSparepart] = useState(null)
  const handleModal = (item) => {
    setDetailSparepart(item)
    setModalOpen(true)
  }
  return (
    <div>
      {/* title */}
      <div className="flex justify-between items-center mb-4">
        <div className="text-md-medium md:text-xl-medium text-gray-900 stroke-gray-900 flex items-center gap-2.5 ">
          <CarCustomIcon className={'h-4 w-4'} />
          Custom Mobilmu
        </div>
        <div
          onClick={resetCYOC}
          className="flex gap-2 flex-shrink-0 items-center text-sm-medium text-primary-600 cursor-pointer">
          <RefreshIcon />
          <span>Reset</span>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-2">
        {/* map content */}
        {Object.keys(cyoc).map((name, index) => (
          <Collapse
            expandIconPosition="end"
            defaultActiveKey={[]}
            bordered={false}
            className="bg-white rounded-[4px] border p-3 border-gray-200"
            key={index}>
            <Panel
              // key={option.id}
              header={
                <div className="flex items-center justify-between text-lg-medium text-md-900">
                  {name}
                  {/* selected option */}
                  {selectedSpareparts[name] !== undefined ? (
                    <div className="rounded-full h-[22px] w-[22px] bg-primary-50 text-primary-500 flex items-center justify-center text-[8px]">
                      1
                    </div>
                  ) : null}
                </div>
              }
              className="text-gray-700">
              {/* children */}
              <div className="max-h-[272px] overflow-y-auto bg-gray-50 grid grid-cols-1 gap-2 rounded p-2">
                {/* map child */}
                {cyoc[name].map((item, index) => (
                  <div
                    onClick={() => handleCYOC(name, item)}
                    className={`rounded p-2 border border-solid cursor-pointer ${
                      item?.id === selectedSpareparts[name]?.id
                        ? 'bg-primary-50 border-primary-300'
                        : 'bg-white border-gray-200'
                    }`}
                    key={index}>
                    <div className="flex justify-between">
                      <div className="flex space-x-2 cursor-pointer">
                        {/* image */}
                        <div className="w-[100px] h-[64px] rounded overflow-hidden bg-gray-300 relative">
                          {item?.id === selectedSpareparts[name]?.id ? (
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              <div className="h-[36px] w-[36px] flex items-center justify-center rounded-full bg-primary-50">
                                <CheckIcon className={'w-3 h-3 fill-primary-700'} />
                              </div>
                            </div>
                          ) : null}
                          <img
                            src={urlImage.concat(item.image_url)}
                            alt=""
                            className="h-full w-full object-cover"
                          />
                        </div>
                        {/* name & price */}
                        <div className="flex flex-col justify-between">
                          <p className="text-sm-medium text-gray-700">{item.name}</p>
                          <p className="text-sm-regular text-gray-500">{`+Rp. ${onRupiah(
                            item.price ? item.price : 0
                          )}`}</p>
                        </div>
                      </div>
                      {/* info */}
                      <div
                        className="stroke-gray-400 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleModal(item)
                        }}>
                        <InfoIcon />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Panel>
          </Collapse>
        ))}
      </div>
      <ModalLayout
        openModal={modalOpen}
        headerTitle={`Detail Sparepart`}
        className="w-fit"
        onCancel={() => setModalOpen(false)}>
        <ModalPreviewCustom detailSparepart={detailSparepart} />
      </ModalLayout>
    </div>
  )
}

export default CardCustomBeli
