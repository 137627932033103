import {
  BCA,
  BJB,
  BNC,
  BNI,
  BRI,
  BSI,
  BSS,
  Briva,
  CC,
  CIMB,
  DBS,
  Mandiri,
  Permata,
  QRIS
} from 'assets'

export const paymentList = [
  {
    id: 'qr_code',
    groupTitle: '',
    options: [
      {
        id: 'qr_code',
        title: 'QRIS',
        image: QRIS
      }
    ]
  },
  {
    id: 'card',
    groupTitle: '',
    options: [
      {
        id: 'card',
        title: 'Credit Card',
        image: CC
      }
    ]
  },
  {
    id: 'virtual_account',
    groupTitle: 'Transfer Virtual Account',
    options: [
      // {
      //   id: 'va-briva',
      //   title: 'BRIVA',
      // bank_code: '',
      //   image: Briva
      // },

      // {
      //   id: 'virtual_account-BCA',
      //   title: 'BCA Virtual Account',
      //   bank_code: 'BCA',
      //   image: BCA
      // },
      {
        id: 'virtual_account-PERMATA',
        title: 'Permata Virtual Account',
        bank_code: 'PERMATA',
        image: Permata
      },
      {
        id: 'virtual_account-BRI',
        title: 'BRI Virtual Account',
        bank_code: 'BRI',
        image: BRI
      },
      {
        id: 'virtual_account-MANDIRI',
        title: 'Mandiri Virtual Account',
        bank_code: 'MANDIRI',
        image: Mandiri
      },
      {
        id: 'virtual_account-BNI',
        title: 'BNI Virtual Account',
        bank_code: 'BNI',
        image: BNI
      },
      {
        id: 'virtual_account-SAHABAT_SAMPOERNA',
        title: 'BSS Virtual Account',
        bank_code: 'SAHABAT_SAMPOERNA',
        image: BSS
      },
      {
        id: 'virtual_account-CIMB',
        title: 'CIMB Virtual Account',
        bank_code: 'CIMB',
        image: CIMB
      },
      {
        id: 'virtual_account-BJB',
        title: 'BJB Virtual Account',
        bank_code: 'BJB',
        image: BJB
      },
      {
        id: 'virtual_account-BSI',
        title: 'BSI Virtual Account',
        bank_code: 'BSI',
        image: BSI
      }
    ]
  }

  // {
  //   id: 'bank',
  //   groupTitle: 'Transfer Bank (Verifikasi Manual)',
  //   options: [
  //     {
  //       id: 'bank-mandiri',
  //       title: 'Bank Mandiri',
  //       image: Mandiri
  //     },
  //     {
  //       id: 'bank-bri',
  //       title: 'Bank BRI',
  //       image: BRI
  //     },
  //     {
  //       id: 'bank-bni',
  //       title: 'Bank BNI',
  //       image: BNI
  //     },
  //     {
  //       id: 'bank-bca',
  //       title: 'Bank BCA',
  //       image: BCA
  //     }
  //   ]
  // }
]
