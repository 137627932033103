export const bantuanPanduanNav = {
  title: 'Bantuan dan Panduan',
  nav: [
    {
      title: 'FAQ',
      url: '/about-us/faq'
    },
    {
      title: 'Hubungi Kami',
      url: '/about-us/general#contact'
    },
    {
      title: 'Lokasi Kami',
      url: '/about-us/general#contact'
    },
    {
      title: 'Syarat dan Ketentuan',
      url: '/about-us/terms-and-conditions'
    },
    {
      title: 'Kebijakan Privasi',
      url: '/about-us/privacy-policy'
    }
  ]
}

export const jenisMobilNav = {
  title: 'Jenis Mobil',
  nav: [
    {
      title: 'All New Limo',
      url: ''
    },
    {
      title: 'Almera',
      url: ''
    },
    {
      title: 'Camry',
      url: ''
    },
    {
      title: 'Alphard',
      url: ''
    },
    {
      title: 'C200 CGI',
      url: ''
    },
    {
      title: 'Mobilio',
      url: ''
    }
  ]
}
export const layananNav = {
  title: 'Layanan',
  nav: [
    {
      title: 'Beli Mobil',
      url: '/beli/mobil-bekas'
    },
    {
      title: 'Jual Mobil',
      url: '/jual'
    },
    {
      title: 'Servis',
      url: '/servis'
    },
    {
      title: 'Inspeksi',
      url: '/inspeksi'
    }
  ]
}
