/* eslint-disable no-undef */
import API from 'services'

const getDashboardUnits = async ({ keywords, limit, offset }) => {
  let urlParam = `limit=${limit}&offset=${offset}`
  if (keywords !== null) {
    urlParam += `&keywords=${keywords}`
  }
  const response = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units?${urlParam}`
  )
  return response.data
}

const getInspectionHistories = async ({ unitId, limit, offset }) => {
  let urlParam = `limit=${limit}&offset=${offset}`
  const response = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit/histories/${unitId}?${urlParam}`
  )
  return response.data
}
const getServiceHistories = async ({ unitId, limit, offset }) => {
  let urlParam = `limit=${limit}&offset=${offset}`
  const response = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/internal/bookings/histories/unit/${unitId}?${urlParam}`
  )
  return response.data
}
const getUsersDashboard = async () => {
  const response = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users/all`
  )
  return response.data
}

const getDetailUnitDashboard = async (id) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/${id}`
  )
  return response.data
}
const getNotifications = async (args) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/notifications`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    }
  )
  return response.data
}
const updateNotification = async (args) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/notifications/read/${args.id}`,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      }
    }
  )
  return response.data
}
const updateDashboardUnitOwner = async (id) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/update-owner/${id}`
  )
  return response.data
}
const deleteUnitDashboard = async (id) => {
  const { data: response } = await API.delete(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/${id}`
  )

  return response
}
const createDashboardUnit = async (input) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/customer-site/units`,
    {
      brand_id: input?.merek?.value,
      brand_name: input?.merek?.label,
      model_id: input?.model?.value,
      model_name: input?.model?.label,
      type_id: input?.tipe?.value,
      type_name: input?.tipe?.label,
      transmission_id: input?.transmisi?.value,
      fuel_id: input?.bahanBakar?.value,
      color_id: input?.warna?.value,
      police_number: input?.platNomor,
      // engine_capacity: 1500,
      // mileage: 25000,
      production_year: input?.tahunKeluaran,
      frame_number: input?.nomorRangka,
      engine_number: input?.nomorMesin,
      description: input?.catatan,
      document_stnk_status: input?.dokumen_stnk === 'true' ? true : false,
      document_bpkb_status: input?.dokumen_bpkb === 'true' ? true : false,
      document_faktur_status: input?.dokumen_faktur === 'true' ? true : false,
      document_ktp_status: input?.dokumen_ktp === 'true' ? true : false,
      document_kwitansi_status: input?.dokumen_kwitansi === 'true' ? true : false,
      document_keur_status: input?.dokumen_keur === 'true' ? true : false,
      document_form_status: input?.dokumen_form === 'true' ? true : false,
      thumbnail: input?.thumbnail,
      photos: input?.photos
    }
  )
  return response.data
}
const editDashboardUnit = async (input) => {
  const { data: response } = await API.put(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/${input?.idUnit}`,
    {
      brand_id: input?.merek?.value,
      brand_name: input?.merek?.label,
      model_id: input?.model?.value,
      model_name: input?.model?.label,
      type_id: input?.tipe?.value,
      type_name: input?.tipe?.label,
      transmission_id: input?.transmisi?.value,
      fuel_id: input?.bahanBakar?.value,
      color_id: input?.warna?.value,
      police_number: input?.platNomor,
      // engine_capacity: 1500,
      // mileage: 25000,
      production_year: input?.tahunKeluaran,
      frame_number: input?.nomorRangka,
      engine_number: input?.nomorMesin,
      description: input?.catatan,
      document_stnk_status: input?.dokumen_stnk === 'true' ? true : false,
      document_bpkb_status: input?.dokumen_bpkb === 'true' ? true : false,
      document_faktur_status: input?.dokumen_faktur === 'true' ? true : false,
      document_ktp_status: input?.dokumen_ktp === 'true' ? true : false,
      document_kwitansi_status: input?.dokumen_kwitansi === 'true' ? true : false,
      document_keur_status: input?.dokumen_keur === 'true' ? true : false,
      document_form_status: input?.dokumen_form === 'true' ? true : false,
      thumbnail: input?.thumbnail,
      photos: input?.photos
    }
  )
  console.log(response)
  return response.data
}

const updateUserUnit = async (args) => {
  const { data: response } = await API.put(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/units/update-owner/${args?.idUnit}`,
    args.body,
    {
      headers: {
        Authorization: `Bearer ${args.token}`
      } 
    }
  )
  return response.data
}

const createDashboardUnitV2 = async (body) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/v2/website/dashboard/units/`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data; '
      }
    }
  )
  return response.data
}
const editDashboardUnitV2 = async (unitId, body) => {
  const { data: response } = await API.put(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/v2/website/dashboard/units/${unitId}`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data; '
      }
    }
  )
  return response.data
}
const uploadPhotoUnit = async (body) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/v2/website/dashboard/units/upload-photo`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data; '
      }
    }
  )
  return response.data
}
const deletePhotoUnit = async (unitIdPhoto) => {
  const { data: response } = await API.delete(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/v2/website/dashboard/units/delete-photo/${unitIdPhoto}`
  )
  return response.data
}

export {
  createDashboardUnit,
  getServiceHistories,
  getInspectionHistories,
  deleteUnitDashboard,
  editDashboardUnit,
  getDashboardUnits,
  getDetailUnitDashboard,
  getUsersDashboard,
  getNotifications,
  updateNotification,
  updateDashboardUnitOwner,
  updateUserUnit,
  createDashboardUnitV2,
  editDashboardUnitV2,
  uploadPhotoUnit,
  deletePhotoUnit
}
