import { Input } from 'antd'
import AuthOtp from 'components/auth-otp/auth-otp'
import CustomButton from 'components/ui/custom-button'
import { birdmobilLogo } from 'features/auth/assets';
import { useHistory } from 'react-router-dom';

function InputEmail({ data, error, handleChange, handleSubmit }) {
  const { push } = useHistory();
  const handleCancel = () => {
    push('/login')
  }
  const handleClick = () => {
    handleSubmit()
  }
  return (
    <div className="flex flex-col gap-6">
      <div className='flex flex-col items-center space-y-4'>
        <img src={birdmobilLogo} alt="" />
      <p className="text-gray-900 text-sm-semibold text-3xl">Lupa Password</p>
      </div>
      <div className="text-gray-700">
        <p className="text-xl mb-2">Masukan email Anda</p>
        <div>
          <p className="text-sm-medium mb-[6px]">Email</p>
          <Input placeholder="email@birdmobil.com" type="email" name="email" onChange={e => handleChange(e)} value={data.email} />
          <small className="text-primary-300">{error.email}</small>
        </div>
      </div>
      <div className='grid grid-cols-2 gap-3'>
        <CustomButton label="Kembali" onClick={handleCancel} type="plain" />
        <CustomButton label="Lanjutkan" onClick={handleClick} />
      </div>
    </div>
  )
}

function FormInputEmail({
  data,
  error,
  handleChange,
  isInput,
  handleSubmitOTP,
  handleResend,
  handleSubmitEmail,
  handleBack
}) {
  return (
    <>
      {isInput ? (
        <InputEmail handleSubmit={handleSubmitEmail} data={data} error={error} handleChange={handleChange} />
      ) : (
        <AuthOtp title="Lupa Password" handleSubmit={handleSubmitOTP} handleBack={handleBack} handleResend={handleResend} />
      )}
    </>
  )
}

export default FormInputEmail
