import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import moment from 'moment'
const InspeksiSelesaiJual = ({ data }) => {
  return (
    <CardSectionHeader headerTitle={`Inspeksi Selesai`}>
      <div className="flex flex-col gap-3">
        <div className="sm:flex sm:gap-0 grid grid-cols-1 gap-2 justify-between items-center">
          <>
            <p className="text-md-regular text-gray-600">Inspeksi telah dilakukan pada:</p>
            <p className="text-md-medium text-gray-700 text-right">
              {/* {moment(data?.date).format('DD MMM YYYY')} - {moment(data?.end_time).format('HH:mm')}{' '} */}
              {moment(data?.date).format('DD MMM YYYY')} - {data?.end_time}{' '}
              WIB
            </p>
          </>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default InspeksiSelesaiJual
