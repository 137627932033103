import React, { useCallback } from 'react'
import {
  getAllBrands,
  getAllColors,
  getAllFuels,
  getAllModels,
  getAllTransmision,
  getAllTypes,
  getCatalog,
  getWorkshops
} from 'features/beli-mobil/service'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { debounce } from 'lodash'
import { getCities, getProvinces } from 'features/detail-users/service'
const initialFilter = {
  province: null,
  city: null,
  location: null,
  merek: null,
  model: null,
  transmisi: null,
  bahanBakar: null,
  warna: null,
  tipe: null,
  minHarga: null,
  maxHarga: null,
  minTahun: null,
  maxTahun: null,
  minTempuh: null,
  maxTempuh: null,
  keywords: '',
  limit: 15,
  offset: 0
}
const useSearchCatalog = (filterData) => {
  const [cars, setCars] = useState([])
  const [filter, setFilter] = useState(
    filterData === undefined ? { ...initialFilter } : { ...filterData }
  )
  const [filterTag, setFilterTag] = useState(filterData === undefined ? {} : { ...filterData })
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [locations, setLocations] = useState([])
  const [fuels, setFuels] = useState([])
  const [transmissions, setTransmissions] = useState([])
  const [models, setModels] = useState([])
  const [colors, setColors] = useState([])
  const [brands, setBrands] = useState([])
  const [types, setTypes] = useState([])

  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: 0
  })

  useQuery({
    queryKey: ['getCities', filter?.province?.value],
    queryFn: () => getCities(filter?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    staleTime: 0,
    enabled: !!filter?.province?.value
  })
  useQuery({
    queryKey: ['getWorkshops', filter.province?.value, filter.city?.value],
    queryFn: () => getWorkshops(filter.province?.value, filter.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setLocations(newData)
    },
    staleTime: 0
  })
  useQuery({
    queryKey: 'getAllBrands',
    queryFn: () => getAllBrands(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setBrands(newData)
    }
  })
  useQuery({
    queryKey: ['getAllModels', filter?.merek?.value],
    queryFn: () => getAllModels(filter?.merek?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setModels(newData)
    },
    enabled: !!filter?.merek?.value,
    staleTime: 0
  })
  useQuery({
    queryKey: ['getAllTypes', filter?.model?.value],
    queryFn: () => getAllTypes(filter?.model?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTypes(newData)
    },
    enabled: !!filter?.model?.value,
    staleTime: 0
  })
  useQuery({
    queryKey: 'getAllFuels',
    queryFn: () => getAllFuels(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setFuels(newData)
    }
  })
  useQuery({
    queryKey: 'getAllColors',
    queryFn: () => getAllColors(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setColors(newData)
    }
  })
  useQuery({
    queryKey: 'getAllTransmision',
    queryFn: () => getAllTransmision(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTransmissions(newData)
    }
  })
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    []
  )
  var { refetch, isFetching: isCatalogFetching } = useQuery({
    queryKey: ['getCatalog', debouncedChangeHandler],
    queryFn: () => getCatalog(filter),
    onSuccess: (data) => {
      setCars(data)
    },
    onError: () => {
      setCars(null)
    },
    enabled: Boolean(debouncedChangeHandler),
    staleTime: 0
  })
  function handleFilter(name, value) {
    const newTagObj = { ...filterTag }
    if (name === 'merek') {
      setModels([])
      setTypes([])
      setFilter((prev) => ({
        ...prev,
        merek: value,
        model: null,
        tipe: null
      }))
      const { model, tipe, ...rest } = newTagObj
      rest.merek = value
      setFilterTag(rest)
    } else if (name === 'model') {
      setTypes([])
      setFilter((prev) => ({
        ...prev,
        model: value,
        tipe: null
      }))
      newTagObj.model = value
      newTagObj.tipe = undefined
      const { tipe, ...rest } = newTagObj
      rest.model = value
      setFilterTag(rest)
    } else if (name === 'province') {
      setCities([])
      setFilter((prev) => ({
        ...prev,
        province: value,
        city: null,
        location: null
      }))
      newTagObj.province = value
      newTagObj.city = undefined
      newTagObj.location = undefined

      const { city, location, ...rest } = newTagObj
      setFilterTag(rest)
    } else if (name === 'keywords') {
      setFilter((prev) => ({
        ...prev,
        [name]: value,
        offset: 0
      }))
      newTagObj.keywords = value
      setFilterTag(newTagObj)
    } else {
      setFilter((prev) => ({
        ...prev,
        [name]: value
      }))
      newTagObj[name] = value
      setFilterTag(newTagObj)
    }
    return debouncedChangeHandler()
  }
  function handleRemoveFilterByOption(name) {
    handleFilter(name, null)
  }
  function handlePagination(pageNumber) {
    const totalOffset = filter.limit * pageNumber - filter.limit
    return handleFilter('offset', totalOffset)
  }
  function handleResetFilter() {
    setFilter({ ...initialFilter })
    setCities([])
    setModels([])
    setTypes([])
    setFilterTag({})
    return debouncedChangeHandler()
  }
  return {
    handlePagination,
    handleFilter,
    handleResetFilter,
    handleRemoveFilterByOption,
    cars,
    filter,
    filterTag,
    locations,
    fuels,
    transmissions,
    models,
    colors,
    types,
    brands,
    provinces,
    cities,
    isCatalogFetching,
    setFilter
  }
}

export default useSearchCatalog
