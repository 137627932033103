import React from 'react'
import PenjadwalanCard from '../templates-status-card/penjadwalan-card'
import SelesaiTestDrive from '../card-status-transaction/test-drive/selesai-test-drive'
import ProgressTestDrive from '../card-status-transaction/test-drive/progress-test-drive'
import CardDetailCar from 'components/ui/card/card-detail-car'
import { CardDetailService } from '..'
import moment from 'moment'

const TestDriveContentTransaction = ({ data, status, serviceName, navigateHandler }) => {
  // console.log('ieu data', data)
  const CardStatusTransactionTestDriveMap = {
    penjadwalan: (
      <PenjadwalanCard
        data={{
          date: data?.test_drive?.test_drive_date,
          start_time: data?.test_drive?.test_drive_schedule?.start_hour
        }}
        serviceName={serviceName}
      />
    ),
    berlangsung: <ProgressTestDrive />,
    selesai: (
      <SelesaiTestDrive
        serviceName={serviceName}
        data={{
          date: moment(data?.test_drive?.updated_at).format('DD MMMM YYYY'),
          end_time: moment(data?.test_drive?.updated_at).format('HH:mm')
        }}
      />
    )
  }
  return (
    <>
      {CardStatusTransactionTestDriveMap[status]}
      <CardDetailCar data={data} navigateDetailHandler={navigateHandler} />
      <CardDetailService data={data} />
    </>
  )
}

export default TestDriveContentTransaction
