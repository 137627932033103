/* eslint-disable no-undef */
import API from 'services'

const getTaxPrice = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/configurations/taxes/1`
  )
  return response.data
}
const getAdminFee = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/configurations/service-prices/3`
  )
  return response.data
}

const getVoucherList = async ({ total_price, voucher_code, type }) => {
  let urlParam = `type=${type}`
  if (voucher_code) {
    urlParam += `&voucher_code=${voucher_code}`
  }
  if (total_price !== null) {
    urlParam += `&total_price=${total_price}`
  }
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/vouchers/all?${urlParam}`
  )
  return response.data
}

const getCompanyDiscount = async (companyId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/user-service/internal/company/${companyId}`
  )
  return response.data
}

const getServices = async (typeId, keyword) => {
  let urlParam = `&search=${keyword}`
  if (keyword == '') {
    urlParam = ''
  }
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/services?type_id=${typeId}${urlParam}`
  )
  return response.data
}

const postServiceV2 = async (dataSend, typeId) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/services/${typeId}`,
    dataSend
  )
  return response.data
}

const getServiceCategories = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/services/all`
  )
  return response.data
}

const postBookingService = async (dataSend) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/bookings`,
    dataSend
  )
  return response.data
}

const postSimulateService = async (dataSend) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/servis-service/bookings/simulation-create`,
    dataSend
  )
  return response.data
}

const getUnitList = async (keyword) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/customer-site/units/all?keywords=${keyword}`
  )
  return response.data
}

const getProvinces = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/provinces/all`
  )
  return response.data
}

const getCities = async (provinceId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/cities/province/${provinceId}`
  )
  return response.data
}

const getSubdistricts = async (cityId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/subdistricts/city/${cityId}`
  )
  return response.data
}

const getSchedules = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/admin/cities/province/`
  )
  return response.data
}

const getRangePrice = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/admin/inspection-distance-prices/all`
  )
  return response.data
}

const getWorkshops = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/workshops/all?keywords=&sort_by=name&order_by=asc`
  )
  return response.data
}

const getTimes = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit/schedules`
  )
  return response.data
}

export {
  getCompanyDiscount,
  getAdminFee,
  getVoucherList,
  getTaxPrice,
  getServices,
  getUnitList,
  postBookingService,
  getProvinces,
  getCities,
  getSubdistricts,
  getSchedules,
  getWorkshops,
  getRangePrice,
  getTimes,
  postSimulateService,
  getServiceCategories,
  postServiceV2
}
