import { Col, Spin } from 'antd'
import CardInformationJadwal from 'components/ui/card/card-information-jadwal'
import CardInformationUser from 'components/ui/card/card-information-user'
import CardPayment from 'components/ui/card/card-payment'
import CustomButton from 'components/ui/custom-button'
import CardInformationCar from 'components/ui/card/card-information-car'
import CardInfoTestDrive from 'components/ui/card/card-info-test-drive'

function FormCheckoutInspeksi({
  selectedCar,
  schedule,
  state,
  homeState,
  isShowRoom,
  isLoading,
  price,
  taxPrice,
  totalPrice,
  handleCancel,
  selectedPayment,
  handleSelectedPayment,
  handleConfirmation
}) {
  return (
    <>
      {/* <Col span={20} className="mx-auto grid grid-cols-1 md:grid-cols-[1fr_389px] gap-6"> */}
      <Col span={20} className="mx-auto grid grid-cols-1 gap-6">
        {/* left content */}
        <div>
          <div className="grid grid-cols-1 gap-6">
            {/* data mobil */}
            <CardInformationCar car={selectedCar} />
            {/* informasi user */}
            <CardInformationUser
              headerTitle={'Data diri'}
              data={{
                nama: state?.nama,
                email: state?.email,
                phone: state?.phone,
                alamat: state?.alamat
              }}
            />
            {/* informasi jadwal */}
            <CardInformationJadwal
              workshop={state.workshop}
              selectedSchedule={schedule.hours}
              data={schedule}
              inspeksi
            />
            {/* pembayaran */}
            <CardPayment
              data={homeState}
              selectedPayment={selectedPayment}
              handleSelectedPayment={handleSelectedPayment}
              inspeksi
              disableVoucher
              price={price}
              isShowRoom={isShowRoom}
              taxPrice={taxPrice}
              totalPrice={totalPrice}
            />
            {/* button */}
            <div className="flex gap-3 justify-end items-center">
              <Spin spinning={isLoading}>
                <CustomButton type="secondary" label={'Sebelumnya'} onClick={handleCancel} />
              </Spin>
              <Spin spinning={isLoading}>
                <CustomButton label={'Checkout'} onClick={handleConfirmation} />
              </Spin>
            </div>
          </div>
        </div>
        {/* right content */}
        {/* <div className="hidden md:block">
          <div className="grid grid-cols-1 gap-6">
            <CardInfoTestDrive />
          </div>
        </div> */}
      </Col>
    </>
  )
}

export default FormCheckoutInspeksi
