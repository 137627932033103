import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getRelatedCatalog } from '../service'

const useRelatedCatalog = (idCatalog, limit) => {
  const [relatedCars, setRelatedCars] = useState(null)
  useQuery({
    queryKey: 'getRelatedCatalogs',
    queryFn: () => getRelatedCatalog(idCatalog, limit),
    onSuccess: (data) => {
      setRelatedCars(data)
    },
    enabled: !!idCatalog,
    staleTime: 0
  })
  return { relatedCars }
}

export default useRelatedCatalog
