import React from 'react'
import CardSectionHeader from '../card-section-header'

function CardInformation({ data, title }) {
  return (
    <>
      <CardSectionHeader headerTitle={title}>
        <div className="flex flex-col gap-3">
          {data.map((dataItem, key) => (
            <div key={key} className="grid grid-cols-[minmax(50px,180px)_1fr] gap-2">
              <p className="text-sm-medium md:text-md-medium text-gray-900">{dataItem.label}</p>
              <p className="text-sm-regular md:text-md-regular text-gray-600">
                {dataItem.value == true
                  ? 'Ada'
                  : dataItem.value == false
                  ? 'Tidak Ada'
                  : dataItem.value == null
                  ? 'N/A'
                  : dataItem.value}
              </p>
            </div>
          ))}
        </div>
      </CardSectionHeader>
    </>
  )
}

export default CardInformation
