import { ImagePlaceholder } from 'assets'
import { CarouselMultipleLayout } from 'components/ui/CarouselMultipleLayout'
import React, { useState } from 'react'
import { useEffect } from 'react'

function CardImageUnit({ isTaxi, data }) {
  const [previewImage, setPreviewImage] = useState('')
  const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
  useEffect(() => {
    if (data?.photos !== undefined) {
      setPreviewImage(data?.photos[0]?.photo_filename)
    }
  }, [data])
  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="w-full h-full min-h-[200px] max-h-[200px] md:max-h-full md:h-96 bg-gray-200 flex rounded-md overflow-hidden items-center justify-center relative">
        {/* Image Preview */}
        {previewImage ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              backgroundImage: `url(${urlImage.concat(previewImage)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
        ) : (
          <img src={ImagePlaceholder} className="object-contain py-14 w-full h-full" alt="" />
        )}
        {/* {!isTaxi ? (
          <div className="bg-warning-400 absolute right-0 top-5 px-4 py-3 display-xs-semibold text-white">
            <div className="z-10">Rp 50.000.000 OFF</div>
            <div className="absolute w-[31px] h-full overflow-hidden top-0 left-[-31px]">
              <div className="w-[31px] bg-warning-400 z-[3] h-full skew-x-[45deg] absolute top-0 left-[90%]"></div>
              <div className="w-[31px] bg-warning-400 z-[3] h-full skew-x-[135deg] absolute top-0 left-[90%]"></div>
            </div>
          </div>
        ) : (
          ''
        )} */}
        {/* Badge Discount */}
      </div>

      {/* Carousel */}
      {data?.photos?.length > 0 ? (
        <div>
          <CarouselMultipleLayout
            arrows
            slideToShow={5}
            slidesToScroll={4}
            totalItem={data?.photos?.length}
            className={'overflow-hidden'}>
            {data?.photos?.map((el, index) => (
              <img
                key={index}
                onClick={() => {
                  setPreviewImage(el?.photo_filename)
                }}
                src={urlImage.concat(el?.photo_filename)}
                className="object-cover w-full h-full cursor-pointer max-w-[183px] max-h-[80px] md:max-h-[90px] bg-gray-200 rounded-[4px]"
                alt="car image"
              />
            ))}
          </CarouselMultipleLayout>
        </div>
      ) : null}
    </div>
  )
}

export default CardImageUnit
