import API from 'services'

const getTimesInspection = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit/schedules`
  )
  return response.data
}
const getTimesServis = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/inspeksi-service/website/inspection-unit/schedules`
  )
  return response.data
}
const getTimesTestDrive = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/test-drives/schedules`
  )
  return response.data
}

export { getTimesInspection, getTimesTestDrive, getTimesServis }
