const CalendarIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3333 1.66797V5.0013M5.66667 1.66797V5.0013M1.5 8.33464H16.5M3.16667 3.33464H14.8333C15.7538 3.33464 16.5 4.08083 16.5 5.0013V16.668C16.5 17.5884 15.7538 18.3346 14.8333 18.3346H3.16667C2.24619 18.3346 1.5 17.5884 1.5 16.668V5.0013C1.5 4.08083 2.24619 3.33464 3.16667 3.33464Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CalendarIcon
