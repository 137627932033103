import { Col, Divider, Input, Row } from 'antd'
import Password from 'antd/lib/input/Password'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import AuthOtp from 'components/auth-otp/auth-otp'
import CustomButton from 'components/ui/custom-button'
import { birdmobilLogo } from 'features/auth/assets'
import { getDataUser } from 'features/profile/service'
import { loginSubmit, postAuthGoogle, requestOtp, sendOtp } from 'features/register-type/service'
import useValidation from 'lib/useValidation'
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { NavLink, useHistory } from 'react-router-dom'
import { BirdMobilLogo } from 'assets'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage } from 'utils/toastMessage'

const InputForm = ({ state, error, handleClick, handleChange, handleResponseLogin }) => {
  const google = window.google

  const postGoogle = useMutation('postAuthGoogle', postAuthGoogle, {
    onSuccess: (data) => {
      // console.log(data)
      handleResponseLogin(data)
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })

  const responseGoogle = async (response) => {
    // console.log('ini response')
    // console.log(response)
    const dataSend = {
      token: response.credential,
      body: {}
    }
    postGoogle.mutate(dataSend)
  }

  useEffect(() => {
    google?.accounts?.id?.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: responseGoogle
    })
    google?.accounts?.id?.renderButton(
      document.getElementById('buttonGoogle'),
      { theme: 'outline', size: 'medium', width: '100%', minWidth: '100%', maxWidth: '100%' } // customization attributes
    )
  }, [])
  return (
    <Row>
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* Title */}
        <div className="flex flex-col gap-5 items-center space-y-4">
          <img src={BirdMobilLogo} alt="" className="w-full max-w-[200px] object-contain" />
          <p className="display-sm-semibold text-gray-900">Login to BirdMobil</p>
        </div>
        {/* Form Input */}
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="text-sm-medium text-gray-700">
              Email <span className="text-danger-500">*</span>
            </label>
            <Input
              type="text"
              className="flex justify-center text-md-normal p-2 gap-3 items-center rounded-lg bg-white shadow-xs"
              name="email"
              placeholder="email@birdmobil.com"
              onChange={(e) => handleChange(e)}
              value={state?.email}
            />
            <small className="text-primary-300">{error.email}</small>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="text-sm-medium text-gray-700">
              Password <span className="text-danger-500">*</span>
            </label>
            <Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              className="text-md-normal p-2 rounded-lg bg-white shadow-xs"
              name="password"
              placeholder="Password"
              value={state.password}
              onChange={(e) => handleChange(e)}
            />
            <small className="text-primary-300">{error.password}</small>
          </div>
          {/* Forgot Password */}
          <div>
            <NavLink
              to="/forgot-password"
              className="text-sm-medium text-primary-600 hover:text-primary-600">
              Lupa Password Anda?
            </NavLink>
          </div>
          {/* Button */}
          <CustomButton label={'Masuk'} onClick={handleClick} />
          <Divider className="m-0" />
          <div id="buttonGoogle" />
          {/* <Button className="flex justify-center text-md-medium gap-3 items-center p-5 rounded-lg bg-white shadow-xs">
            <GoogleIcon className={'w-6 h-6'} /> Masuk Dengan Google
          </Button>
          <Button className="flex justify-center text-md-medium gap-3 items-center p-5 rounded-lg bg-white shadow-xs">
            <FacebookIcon className={' fill-[#1877F2] w-6 h-6'} />
            Masuk Dengan Facebook
          </Button> */}
          <p className="text-sm-regular text-center text-gray-500">
            Belum memiliki akun?
            <NavLink
              to={'/register'}
              className="text-primary-600 hover:text-primary-600 text-sm-medium ml-2">
              Registrasi
            </NavLink>
          </p>
        </div>
      </Col>
    </Row>
  )
}

const intiialState = {
  password: '',
  email: ''
}
function SignInPage() {
  const { push, replace } = useHistory()
  const prevUrl = localStorage.getItem('prevUrl')
  if (localStorage.getItem('user') !== null) {
    if (prevUrl === null || prevUrl === '/login' || prevUrl.includes('register')) {
      replace('/')
    } else {
      replace(prevUrl)
    }
  }

  const { passValidation, emailValidation } = useValidation()
  const [state, setState] = useState({ ...intiialState })
  const [errror, setError] = useState({ ...intiialState })
  const [isNext, setIsNext] = useState(true)
  const handleChange = (e) => {
    const { name, value } = e.target
    let err = ''

    switch (name) {
      case 'email':
        err = emailValidation(value)
        break
      case 'password':
        err = passValidation(value)
        break
      default:
        err = ''
    }
    setState((prev) => ({
      ...prev,
      [name]: value
    }))
    setError((prev) => ({
      ...prev,
      [name]: err
    }))
  }

  const { refetch } = useQuery('newOtpRequest', requestOtp, {
    onSuccess: (data) => {
      // console.log(data)
      setIsNext(false)
    },
    enabled: false
  })
  const { refetch: getDataRefetch } = useQuery({
    queryKey: 'getProfilePersonal',
    queryFn: () => {
      const token = localStorage.getItem('token')
      const account_type = localStorage.getItem('account_type')
      return getDataUser({
        account_type: account_type === 'customer' ? 'personal' : 'b2b',
        token: token
      })
    },
    onSuccess: (data) => {
      localStorage.setItem('user_name', data?.fullname ? data?.fullname : data?.name)
      localStorage.setItem('user', JSON.stringify(data))
    },
    enabled: false
  })
  const submitOtp = useMutation(sendOtp, {
    onSuccess: (data) => {
      // console.log(data)
      const token = sessionStorage.getItem('token')
      const account_type = sessionStorage.getItem('account_type')
      localStorage.setItem('token', token)
      localStorage.setItem('account_type', account_type)
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('account_type')
      getDataRefetch()
    },
    onError: (data) => {
      // console.log('omg')
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })

  const handleResponseLogin = (data) => {
    // console.log('hit')
    if (data.account_type === 'admin') return replace('/')

    sessionStorage.setItem('token', data.access_token)
    sessionStorage.setItem('account_type', data.account_type)
    if (data.is_need_mfa) {
      refetch()
    } else {
      localStorage.setItem('token', data.access_token)
      localStorage.setItem('account_type', data.account_type)
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('account_type')
      getDataRefetch()
    }
  }

  const handleLogin = useMutation('submitLogin', loginSubmit, {
    onSuccess: (data) => {
      if (data.account_type !== 'admin') {
        handleResponseLogin(data)
      } else {
        showErrorMessage('Email telah digunakan, silahkan hubungi tim BirdMobil')
      }
    },
    onError: (data) => {
      const { response } = data
      // console.log(response?.data?.errors[0]?.message)
      if (response?.data?.errors[0]?.message?.length > 0) {
        if (response?.data?.errors[0]?.message === 'User Not Found') {
          showErrorMessage('Akun tidak ditemukan')
        } else if (response?.data?.errors[0]?.message === 'Email and Password mismatch') {
          showErrorMessage('Email dan Password tidak sesuai')
        } else {
          showErrorMessage(response?.data?.errors[0]?.message)
        }
      } else {
        showErrorMessage('Login tidak berhasil')
      }
    }
  })

  const handleSubmitOtp = (otp) => {
    const dataSend = {
      otp_code: otp
    }
    submitOtp.mutate(dataSend)
  }
  const handleClickResendOtp = () => {
    refetch()
  }

  const handleSubmit = () => {
    let nameError = ''
    let nameInput = ''
    let validError = true
    let validInput = true
    Object.keys(state).forEach((key) => {
      if (state[key].length === 0) {
        validInput = false
        nameInput = String(key)
      }
    })
    Object.keys(errror).forEach((key) => {
      if (errror[key].length > 0) {
        validError = false
        nameError = String(key)
      }
    })
    // Object.values(state).forEach((v) => {
    //   if (v.length === 0) {
    //     validInput = false
    //   }
    // })
    // Object.values(errror).forEach((v) => {
    //   if (v.length > 0) {
    //     validError = false
    //   }
    // })
    if (validInput) {
      if (validError) {
        handleLogin.mutate(state)
      } else {
        showErrorMessage(`Mohon perbaiki ${nameToString[nameError]}`)
      }
    } else {
      showErrorMessage(`Mohon isi ${nameToString[nameInput]}`)
    }
  }
  const handleBack = () => setIsNext(true)

  return (
    <>
      {isNext ? (
        <InputForm
          state={state}
          error={errror}
          handleClick={handleSubmit}
          handleChange={handleChange}
          handleResponseLogin={handleResponseLogin}
        />
      ) : (
        <AuthOtp
          title="Login to BirdMobil"
          handleBack={handleBack}
          handleSubmit={handleSubmitOtp}
          handleResend={handleClickResendOtp}
        />
      )}
    </>
  )
}

export default SignInPage
