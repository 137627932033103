import { Rate } from 'antd'
import React from 'react'
import { SampleImageStories } from 'assets'
function CardStory() {
  return (
    <div className="w-full lg:w-[340px] bg-white border border-solid rounded-[4px] border-gray-200">
      <div className="p-6 flex flex-col gap-4 ">
        {/* image */}
        <img className="h-[180px] w-full object-cover" src={SampleImageStories}></img>
        {/* title & rate */}
        <div className="flex flex-col gap-2 p-0">
          <p className="display-sm-medium text-gray-900">Jennifer Willson</p>
          <Rate allowHalf disabled defaultValue={5} />
        </div>
        {/* content */}
        <p className="text-sm-regular text-gray-600">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl viverra a, sit lorem.
          Elementum, suspendisse lorem velit purus eget pellentesque egestas scelerisque gravida.
          Enim senectus tempus ullamcorper dolor tincidunt sit vivamus tellus, at. Facilisi
          tristique condimentum suspendisse suscipit a, aliquam ornare vulputate.
        </p>
      </div>
    </div>
  )
}

export default CardStory
