export const faqData = [
  {
    id: 0,
    title: 'Mengapa harus di BirdMobil?',
    description: (
      <>
        <p>
          BirdMobil menawarkan solusi yang menyeluruh dalam perjalan kepemilikan mobil Anda. Dari
          mulai Jual Mobil, Beli Mobil, sampai dengan Inspeksi dalam satu jendela situs
          www.birdmobil.id
        </p>
      </>
    )
  },

  // BELI
  {
    id: 2,
    title: 'Mengapa membeli di BirdMobil?',
    description: (
      <>
        <p>
          BirdMobil menawarkan mobil dengan tanda Tersertifikasi oleh BirdMobil (
          <span className="!italic">Certified</span>) yang merupakan mobil berkualitas baik dan
          telah melalui proses pengecekan di lebih dari 180 titik baik interior, eksterior dan mesin
          Mobil. Kami memastikan Mobil kami dalam kondisi baik, dengan harga pasti, transparan (
          tidak ada biaya tersembunyi) demi kenyamanan Anda dalam membeli Mobil.
        </p>
      </>
    )
  },
  {
    id: 3,
    title: 'Bagaimana Cara membeli Mobil di Situs BirdMobil?',
    description: (
      <>
        <ol className="list-decimal list-inside">
          <li>Masuk ke laman situs BirdMobil www.birdmobil.id</li>
          <li>Pilih Beli Mobil dan Pilih Beli Mobil Bekas</li>
          <li>Pilih lokasi atau bisa ketik pencarian untuk mobil yang hendak dicari</li>
          <li>Pilih Pesan Sekarang</li>
          <li>Pilih Booking (untuk menjamin mobil tersebut hanya untuk Anda)</li>
          <li>Masukkan data diri Anda</li>
          <li>Pilih Metode Pembayaran dan Pilih Checkout</li>
          <li>
            Akan Muncul pemberitahuan untuk menyelesaikan pembayaran Anda dalam waktu 24 Jam. Dan
            lakukan pembayaran.
          </li>
        </ol>
        <p>
          *Note : untuk Anda yang belum login, Maka Perlu melakukan Login terlebih dahulu untuk
          booking Mobil Impian Anda.
        </p>
      </>
    )
  },
  {
    id: 4,
    title: 'Metode Pembayaran Apa saja yang diterima BirdMobil?',
    description: (
      <>
        <p>Kami menerima metode pembayaran dengan Kartu Kredit, QRIS, Transfer Antar Bank.</p>
      </>
    )
  },
  {
    id: 5,
    title: 'Mengapa Pesanan Saya dibatalkan?',
    description: (
      <>
        <p>
          Ada 2 hal pesanan dibatalkan yaitu Masa waktu pembayaran telah habis atau Anda dengan
          tidak sengaja membatalkan secara manual.
        </p>
      </>
    )
  },
  {
    id: 6,
    title:
      'Berapa lama BirdMobil akan menghubungi saya setelah saya berhasil memesan Mobil dari website?',
    description: (
      <>
        <p>BirdMobil team akan menghubungi Anda dalam waktu 24 Jam untuk mengatur :</p>
        <ol className="list-decimal list-inside">
          <li>
            Uji jalan (<span className="!italic">Test Drive</span>)( Anda dapat memilih melakukan
            Test Drive di Showroom BirdMobil ).
          </li>
          <li>
            Mengumpulkan dokumen sebagai persyaratan pembelian secara kredit. Harap mempersiapkan
            dokumen Anda 3 hari setelah team kami menghubungi Anda untuk menghindari pembatalan
            dikarenakan dokumen yang belum lengkap.
          </li>
        </ol>
      </>
    )
  },
  {
    id: 7,
    title: 'Apakah Mobil yang saya beli di BirdMobil mendapatkan Garansi Servis?',
    description: (
      <>
        <p>
          BirdMobil memberikan Garansi Servis 6 bulan untuk Mobil Anda dan Servis dilakukan di
          Bengkel BirdMobil yang tersedia
        </p>
      </>
    )
  },
  // JUAL
  {
    id: 8,
    title: 'Mengapa Menjual Mobil harus di BirdMobil?',
    description: (
      <>
        <p>
          BirdMobil memberikan solusi yang mudah untuk menjual Mobil Anda tanpa harus mengeluarkan
          biaya untuk mengiklan kan Mobil Anda. Cukup masuk ke situs www.birdmobil.id , masuk
          sebagai pengguna dan mulai mengisi data mobil yang diperlukan sesuai dengan petunjuk yang
          ada.
        </p>
      </>
    )
  },
  {
    id: 8,
    title: 'Keuntungan Menjual mobil di Kami',
    description: (
      <>
        <ol className="list-decimal list-inside">
          <li>Harga sesuai dengan kondisi Mobil</li>
          <li>Jual Mobil secara cepat dan mudah</li>
          <li>Proses jual mobil yang transparan</li>
          <li>Tanpa biaya apapun.</li>
        </ol>
      </>
    )
  },
  {
    id: 9,
    title: 'Bagaimana Cara Menjual Mobil di BirdMobil?',
    description: (
      <>
        <p>Berikut ini Langkah - Langkah Menjual Mobil di website BirdMobil :</p>
        <ol className="list-decimal list-inside">
          <li>Masuk situs BirdMobil www.birdmobil.id</li>
          <li>Pilih Jual Mobil</li>
          <li>Masukkan data yang dibutuhkan secara lengkap</li>
          <li>
            Pilih Lanjut Jual Mobil <br />( Jika Anda belum Login, maka Anda akan diarahkan untuk
            login terlebih dahulu)
          </li>
          <li>
            Lanjutkan untuk masukkan data lainnya seperti tahun keluaran, Ekspektasi harga jual dan
            lain-lain sesuai dengan data yang dibutuhkan)
          </li>
          <li>Pilih Lokasi Inspeksi dan Jadwal Inspeksi</li>
          <li>Pilih Checkout dan konfirmasi jadwal Inspeksi.</li>
          <li>Anda akan mendapatkan notifikasi bahwa Inspeksi telah diajukan.</li>
        </ol>
      </>
    )
  },
  // INSPEKSI
  {
    id: 10,
    title: 'Apakah itu Survey / Inspeksi?',
    description: (
      <>
        <p>
          Survey atau inspeksi adalah proses pengecekan kondisi mobil yang akan dijual dimana
          Inspektor BirdMobil akan melakukan pengechekan di lebih dari 180 titik dari mulai
          interior, ekterior dan juga kondisi mesin mobil dan juga melakukan uji jalan (
          <span className="!italic">test drive</span>). Inspeksi dilakukan selama 60 menit.
        </p>
      </>
    )
  },
  {
    id: 11,
    title: 'Dokumen apa saja yang harus disiapkan?',
    description: (
      <>
        <ol className="list-decimal list-inside">
          <li>Bukti Pemilik mobil Bermotor (BPKB)</li>
          <li>STNK dan Bukti bayar pajak tahunan</li>
          <li>KTP/SIM Pemilik mobil ( NPWP dan TDP Perusahaan apabila Perseroan Terbatas (PT))</li>
        </ol>
      </>
    )
  },
  {
    id: 11,
    title: 'Setelah saya setuju untuk menjual Mobil saya, Apa Proses Selanjutnya?',
    description: (
      <>
        <p>
          BirdMobil akan mengurus semua keperluan serta dokumen yang diperlukan dalam waktu 7 hari
          kerja. Anda harus menyerahkan Mobil Anda dalam waktu 7 hari kerja setelah menerima
          penawaran. Lokasi serah terima Mobil akan ditentukan setelah diskusi dengan Inspektor
          kami. Lokasi kami tersedia di beberapa Showroom seperti BirdMobil BSD, BirdMobil Mampang,
          BirdMobil Kramat Jati, BirdMobil Kelapa Gading.Pembayaran Mobil Anda akan dilakukan dengan
          Transfer.
        </p>
      </>
    )
  },
  {
    id: 12,
    title: 'Apakah ada Biaya lainnya?',
    description: (
      <>
        <p>Penjualan Mobil melalui BirdMobil tidak dikenakan biaya Transaksi.</p>
      </>
    )
  },
  // SERVIS
  {
    id: 13,
    title: 'Mengapa Servis di BirdMobil?',
    description: (
      <>
        <p>
          BirdMobil memiliki layanan perawatan dan perbaikan mobil yang terpercaya. Kami didukung
          oleh mekanik yang andal, berpengalaman dan tersertifikasi. Proses Servis yang transparan
          dan terstandarisasi untuk semua jenis dan Tipe Mobil dengan suku cadang yang bervariasi.
          Biaya perawatan dan perbaikan Mobil lebih Efisien dan tidak ada biaya tersembunyi
        </p>
      </>
    )
  },
  {
    id: 14,
    title: 'Apa Saja layanan Servis di BirdMobil?',
    description: (
      <>
        <p>Layanan kami meliputi:</p>
        <ol className="list-inside list-disc">
          <li>Servis Berkala / Servis Rutin</li>
          <li>Servis Umum (Perbaikan Mobil)</li>
          <li>
            <span className="!italic">Body Repair</span> dan{' '}
            <span className="italic">Auto Detailing</span>
          </li>
        </ol>
      </>
    )
  },
  {
    id: 15,
    title: 'Dimana lokasi bengkel BirdMobil?',
    description: (
      <>
        <p>
          Saat ini kami hadir di beberapa lokasi di Jakarta dan Tangerang Selatan. Dan kedepannya
          akan berkembang di seluruh Indonesia.
        </p>
      </>
    )
  },
  {
    id: 15,
    title: 'Bagaimana saya mendaftar Servis di BirdMobil?',
    description: (
      <>
        <ol className="list-inside list-decimal">
          <li>Masuk ke website BirdMobil</li>
          <li>Pilih Servis</li>
          <li>Pilih Lakukan Servis</li>
          <li>Lengkapi data yang dibutuhkan untuk Servis</li>
          <li>Pilih Servis yang di inginkan ( Servis Berkala / Servis Umum / Paket Servis )</li>
          <li>Setelah memilih servis yang diinginkan, Lanjutkan untuk pilih Tambah ke keranjang</li>
          <li>Masukkan jenis Parts yang diinginkan</li>
          <li>Selanjutnya akan muncul di layar biaya parts dan jasa dari servis yang di pilih.</li>
          <li>Pilih Bengkel, Tanggal dan waktu servis yang diinginkan.</li>
          <li>Pilih Checkout untuk melanjutkan.</li>
          <li>Notifikasi akan muncul bahwa Servis telah dijadwalkan.</li>
        </ol>
      </>
    )
  },
  {
    id: 16,
    title: 'Bagaimana Apabila saya hendak mengganti jadwal Servis yang sudah saya pesan?',
    description: (
      <>
        <p>
          Silahkan contact ke BirdMobil Contact Center dengan memilih tombol merah dan Anda akan
          terhubung dengan contact center kami dan silahkan lakukan penjadwalan ulang di contact
          center kami di 0813-9981-4478 dan kami akan dengan senang hati membantu.
        </p>
      </>
    )
  }
]
