const HeartIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3671 2.84166C16.9415 2.41583 16.4361 2.07803 15.8799 1.84757C15.3237 1.6171 14.7275 1.49847 14.1254 1.49847C13.5234 1.49847 12.9272 1.6171 12.371 1.84757C11.8147 2.07803 11.3094 2.41583 10.8838 2.84166L10.0004 3.725L9.11709 2.84166C8.25735 1.98192 7.09129 1.49892 5.87542 1.49892C4.65956 1.49892 3.4935 1.98192 2.63376 2.84166C1.77401 3.70141 1.29102 4.86747 1.29102 6.08333C1.29102 7.29919 1.77401 8.46525 2.63376 9.325L3.51709 10.2083L10.0004 16.6917L16.4838 10.2083L17.3671 9.325C17.7929 8.89937 18.1307 8.39401 18.3612 7.83779C18.5917 7.28158 18.7103 6.6854 18.7103 6.08333C18.7103 5.48126 18.5917 4.88508 18.3612 4.32887C18.1307 3.77265 17.7929 3.26729 17.3671 2.84166V2.84166Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HeartIcon
