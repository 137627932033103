import FAQSection from 'components/section/faq-section'
import StepsSection from 'components/section/steps-section'
// import TestimonySection from 'components/section/testimony-section'
import {
  HeroInspectionSection,
  StandardInspectionSection,
  WhyOurInspectionSection
} from 'features/inspeksi'
import { inspeksiStepData } from 'features/inspeksi/data/inspeksi-constant-data'
import React from 'react'
import { useHistory } from 'react-router-dom'

const InspeksiPage = () => {
  const token = localStorage.getItem('token')
  const { push } = useHistory()
  const handleClickInspeksiForm = () => {
    if (token !== null) {
      push('/inspeksi/form', {
        data: {
          send: true
        }
      })
    } else {
      push('/login')
    }
  }
  return (
    <>
      <HeroInspectionSection onClick={handleClickInspeksiForm} />
      <WhyOurInspectionSection />
      <StepsSection
        items={inspeksiStepData}
        onClick={handleClickInspeksiForm}
        buttonLabel={'Booking Inspeksi Sekarang'}
        sectionTitle={'3 Langkah Melakukan Inspeksi'}
      />
      <StandardInspectionSection />
      {/* <TestimonySection unOrder={true} /> */}
      <FAQSection unOrder={true} />
    </>
  )
}

export default InspeksiPage
