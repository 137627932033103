const BookMultipleIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99935 4.83333C9.99935 3.94928 9.64816 3.10143 9.02304 2.47631C8.39792 1.85119 7.55007 1.5 6.66602 1.5H1.66602V14H7.49935C8.16239 14 8.79828 14.2634 9.26712 14.7322C9.73596 15.2011 9.99935 15.837 9.99935 16.5M9.99935 4.83333V16.5M9.99935 4.83333C9.99935 3.94928 10.3505 3.10143 10.9757 2.47631C11.6008 1.85119 12.4486 1.5 13.3327 1.5H18.3327V14H12.4993C11.8363 14 11.2004 14.2634 10.7316 14.7322C10.2627 15.2011 9.99935 15.837 9.99935 16.5"
        stroke="#A21226"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BookMultipleIcon
