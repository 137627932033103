import { Col, Row } from 'antd'
import React from 'react'
import ContentTitle from './ui/content-title'
import { ImageAboutUs2 } from 'features/landing-page/index'
import DetailLocationSection from './ui/detail-location-section'
import PartnerSection from './ui/partner-section'
import BenefitSection from '../../../components/section/benefit-section'
import { LayananKamiGeneral } from '.'

const totalData = [
  { label: 'Bengkel', total: '140.203' },
  { label: 'Orang Puas', total: '21.678' },
  { label: 'Servis', total: '11.902' }
]
const totalData2 = [
  { label: 'Berlangganan', total: '100.902' },
  { label: 'Dealer', total: '20.200' }
]

function General() {
  return (
    <Row className={`bg-white`}>
      {/* Content Title */}
      <ContentTitle title="Tentang Kami" />
      {/* Siapa Kami */}
      <Col
        span={22}
        lg={{ span: 20 }}
        className="mx-auto py-9 lg:py-20 grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <img className="object-cover w-full h-full" src={ImageAboutUs2}></img>
        </div>
        <div className="flex gap-2 flex-col">
          {/* Title */}
          <p className="text-gray-900 display-sm-semibold">Siapa Kami?</p>
          {/* Description */}
          <p>
            BirdMobil merupakan member dari Bluebird Group yang berpengalaman dalam bidang
            transportasi lebih dari 50 tahun. Kami ingin memberikan Pengalaman dan pengetahuan kami
            Dalam hal perjalanan kepemilikan Mobil.
          </p>
          <p>
            BirdMobil hadir untuk memenuhi Kebutuhan Anda dengan memberikan solusi menyeluruh dan
            satu pintu terhadap kepemilikan mobil dimulai dari beli mobil, Perawatan mobil, jual
            mobil dan inspeksi mobil dengan terpercaya, transparan dan Digitalisasi.
          </p>
        </div>
      </Col>
      {/* Visi Misi */}
      <Col span={24} className="bg-gray-50 py-20">
        <Col span={22} lg={{ span: 20 }} className="grid grid-cols-1 lg:grid-cols-2 gap-6 mx-auto">
          {/* Visi */}
          <div className="flex flex-col gap-4">
            <p className="text-gray-900 display-sm-semibold text-center">Visi</p>
            <p className="text-center text-gray-600 text-md-regular">
              Visi BirdMobil menjadi pemain utama untuk pasar perjalanan kepemilikan mobil di
              Indonesia dengan Terpercaya, Nyaman dan Transparan.
            </p>
          </div>
          {/* Misi */}
          <div className="flex flex-col gap-4">
            <p className="text-gray-900 display-sm-semibold text-center">Misi</p>
            <p className="text-center text-gray-600 text-md-regular">
              BirdMobil bertujuan untuk memberikan pelayanan komprehensif dan solusi satu atap untuk
              perjalanan kepemilikan mobil bekas.
            </p>
          </div>
        </Col>
      </Col>
      {/* Pencapaian */}
      {/* <Col span={24} className="py-20">
        <Col span={22} lg={{ span: 20 }} className="mx-auto gap-12 flex flex-col">
          <div className="flex flex-col items-center justify-center gap-2 text-center">
            <p className="text-gray-900 display-xs-semibold lg:display-sm-semibold ">
              BirdMobil sebagai sebagai perusahaan transportasi terbesar
            </p>
            <p className="text-xl-medium lg:display-xs-medium text-gray-600">telah mencapai</p>
          </div>
          <div className="flex flex-col items-center gap-6">
            <div className="grid grid-cols-3 gap-4 lg:gap-[156px] text-center">
              {totalData.map((data, key) => (
                <div key={key} className="flex flex-col gap-1 items-center ">
                  <p className="text-xl-semibold lg:display-md-semibold text-primary-500">
                    {data.total}
                  </p>
                  <p className="text-xs-regular lg:text-md-regular text-gray-500">{data.label}</p>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 place-content-between gap-4 lg:gap-[156px] text-center">
              {totalData2.map((data, key) => (
                <div key={key} className="flex flex-col gap-1 items-center">
                  <p className="text-xl-semibold lg:display-md-semibold text-primary-500">
                    {data.total}
                  </p>
                  <p className="text-xs-regular lg:text-md-regular text-gray-500">{data.label}</p>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Col> */}
      <LayananKamiGeneral />
      {/* <ServiceSection data={layananData2} headerTitle="Layanan Kami" /> */}
      {/* Jumlah Birdmobil */}
      {/* <Col span={24} className="py-20 bg-white">
        <Col span={22} lg={{ span: 20 }} className="mx-auto gap-[53px] flex flex-col">
          <div className="flex items-center justify-center gap-2">
            <BirdMobil />
            <span className="display-xs-semibold lg:display-sm-semibold text-gray-900">
              BirdMobil
            </span>
          </div>
          <div className=" flex flex-col gap-6">
            <div className="flex justify-center">
              <p className="text-xl-medium lg:display-xs-medium text-gray-900">Tersebar di</p>
            </div>
            <div className="flex items-center justify-between text-center gap-2 lg:gap-60">
              <div className="flex flex-col items-center">
                <p className="text-xl-semibold lg:display-md-semibold text-gray-700">36</p>
                <p className="text-xs-regular lg:text-md-regular">Provinsi</p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-xl-semibold lg:display-md-semibold text-gray-700">512</p>
                <p className="text-xs-regular lg:text-md-regular">Kota</p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-xl-semibold lg:display-md-semibold text-gray-700">9.902</p>
                <p className="text-xs-regular lg:text-md-regular">Dealer</p>
              </div>
            </div>
          </div>
        </Col>
      </Col> */}
      <BenefitSection headerTitle={'Kelebihan BirdMobil'} />
      <PartnerSection />
      <DetailLocationSection />
    </Row>
  )
}

export default General
