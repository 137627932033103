import { Col, Row } from 'antd'
import { CarouselMultipleLayout } from 'components/ui/CarouselMultipleLayout'
import CardCar from 'components/ui/card-car'
import { MobilBekasDetail, SummaryCar } from 'features/beli-mobil'
import { ModalPesanSekarang } from 'features/beli-mobil/components/modal'
import { useDetailCatalog, useRelatedCatalog } from 'features/beli-mobil/hooks'
import { useCYOC, useWishlist } from 'hooks'
import { ModalLayout } from 'layouts'

import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom'

function BeliMobilDetailPage() {
  const { id: idCatalog } = useParams()
  const { push, replace } = useHistory()
  const token = localStorage.getItem('token')
  const [modalOpen, setModalOpen] = useState(false)
  const { car } = useDetailCatalog(idCatalog)
  const { relatedCars } = useRelatedCatalog(idCatalog, 4)
  const { cyoc, selectedSpareparts, handleCYOC, resetCYOC, deleteSelectedSpareparts } = useCYOC(
    idCatalog,
    car?.is_create_your_own_car,
    false
  )
  const { wishlistIds, handleWishlist } = useWishlist()

  function handleNavigation(id, path, type) {
    if (!token && path !== '/') {
      return push('/login')
    }
    if (type === 'customer_service') {
      return window.open(`https://wa.me/${process.env.REACT_APP_CUSTOMER_SERVICE_NUMBER}`)
    }
    replace({
      pathname: path,
      state: {
        id: id,
        cyoc_spareparts: selectedSpareparts,
        is_create_your_own_car: car?.is_create_your_own_car
      }
    })
  }
  const handleModal = () => {
    modalOpen
    setModalOpen(true)
  }

  return (
    <Row className="my-10">
      <Col span={22} lg={{ span: 20 }} className="mx-auto grid grid-cols-1 gap-6">
        {/* Breadcrumb */}
        {/* Mobil Bekas / Ex Taxi Detail */}
        <MobilBekasDetail
          car={car}
          cyoc={cyoc}
          handleCYOC={handleCYOC}
          resetCYOC={resetCYOC}
          selectedSpareparts={selectedSpareparts}
        />

        {/* Produk Terkait */}
        {relatedCars ? (
          <div className="grid grid-cols-1 gap-5">
            <div className="flex justify-between items-center">
              <p className="text-md-medium md:text-xl-medium text-gray-900">Produk Terkait</p>
              <NavLink
                className={
                  'text-sm-medium md:text-md-medium text-primary-600 hover:text-primary-600'
                }
                to={'/beli/mobil-bekas'}>
                Lihat Lainnya
              </NavLink>
            </div>
            {/* Produk Unit */}
            <div className="hidden xl:grid grid-cols-4 gap-6">
              {relatedCars?.map((data, index) => (
                <CardCar
                  onClick={() => {
                    push(`/beli/mobil-bekas/detail/${data?.id}`)
                  }}
                  data={data}
                  handleWishlist={handleWishlist}
                  isBookmark={wishlistIds.includes(data?.id)}
                  key={index}
                />
              ))}
            </div>
            <div className="block xl:hidden">
              <CarouselMultipleLayout
                autoplay={true}
                responsive={[
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
                arrows={false}
                slideToShow={1}
                slidesToScroll={2}
                totalItem={relatedCars?.length}
                className={'overflow-hidden cursor-grab'}>
                {relatedCars?.map((data, index) => (
                  <CardCar
                    onClick={() => {
                      push(`/beli/mobil-bekas/detail/${data?.id}`)
                    }}
                    data={data}
                    handleWishlist={handleWishlist}
                    isBookmark={wishlistIds.includes(data?.id)}
                    key={index}
                  />
                ))}
              </CarouselMultipleLayout>
            </div>
          </div>
        ) : null}
      </Col>
      <SummaryCar
        handleWishlist={handleWishlist.bind(this, car?.id)}
        handleModal={handleModal}
        dataUnit={car}
        selectedSpareparts={selectedSpareparts}
        deleteSelectedSpareparts={deleteSelectedSpareparts}
        isBookmark={wishlistIds.includes(car?.id)}
      />
      <ModalLayout
        openModal={modalOpen}
        headerTitle={`Pilih Opsi`}
        className="w-fit"
        onCancel={() => setModalOpen(false)}>
        <ModalPesanSekarang handleNavigation={handleNavigation.bind(this, car?.id)} />
      </ModalLayout>
    </Row>
  )
}

export default BeliMobilDetailPage
