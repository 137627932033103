import { Space } from 'antd'
import CustomButton from 'components/ui/custom-button'
import React from 'react'
import { useHistory } from 'react-router-dom'

function MenuUnlogged() {
  const history = useHistory()

  const navigateHandler = (path) => {
    history.push(path)
  }

  return (
    <>
      <Space
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBlock: '12px'
        }}>
        <CustomButton onClick={navigateHandler.bind(this, '/login')} type="plain" label={'Masuk'} />
        <CustomButton onClick={navigateHandler.bind(this, '/register')} label={'Daftar'} />
      </Space>
    </>
  )
}

export default MenuUnlogged
