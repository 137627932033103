import React from 'react'
import CurrencyInput from 'react-currency-input-field'

const InputCurrency = ({ placeholder, suffix, prefix, decimalsLimit, maxLength, value, name, onChange, className }) => {
  return (
    <CurrencyInput
      groupSeparator="."
      decimalSeparator=","
      name={name}
      suffix={suffix}
      prefix={prefix}
      placeholder={placeholder}
      // defaultValue={value}
      value={value}
      maxLength={maxLength}
      decimalsLimit={decimalsLimit || 0}
      className={`ant-input w-fit-content ${className}`}
      // onValueChange={(value, name) => console.log(value, name)}
      onValueChange={onChange}
    />
  )
}

export default InputCurrency