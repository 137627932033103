import { Col, Divider, Row } from 'antd'
import { NavLink } from 'react-router-dom'
import InputCompany from './input-company'
import InputCompanyDetail from './input-company-detail'
import { birdmobilLogo } from 'features/auth/assets'
import { useEffect } from 'react'
import { postAuthGoogle } from '../service'
import { useMutation } from 'react-query'
import { BirdMobilLogo } from 'assets'
import { showErrorMessage } from 'utils/toastMessage'

function RegisterTypeCompanyMain({
  firstState,
  secondState,
  firstError,
  secondError,
  agree,
  provinces,
  cities,
  subdistricts,
  isNext,
  setIsNext,
  isLoading,
  handleChangeFirstState,
  handleChangeSecondState,
  handleChangeLocation,
  handleChangeAgree,
  handleNextDetail,
  handleSubmit,
  handleRegisterCompany
}) {
  const google = window.google
  const handlePrev = () => setIsNext(true)
  const handleClick = () => {
    handleSubmit()
  }

  const postGoogle = useMutation('postAuthGoogle', postAuthGoogle, {
    onSuccess: (data) => {
      handleRegisterCompany(data)
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })

  const responseGoogle = async (response) => {
    // console.log(response)
    const dataSend = {
      token: response.credential,
      body: {
        account_type: 'company'
      }
    }
    postGoogle.mutate(dataSend)
  }

  useEffect(() => {
    google?.accounts?.id?.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: responseGoogle
    })
    google?.accounts?.id?.renderButton(
      document.getElementById('buttonGoogle'),
      { theme: 'outline', size: 'medium', text: 'signup_with' } // customization attributes
    )
  }, [])

  return (
    <Row>
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* Title */}
        <div className="flex flex-col gap-5 items-center space-y-4">
          <img src={BirdMobilLogo} alt="" className="w-full max-w-[200px] object-contain" />
          <p className="display-sm-semibold text-gray-900">Registrasi BirdMobil</p>
        </div>
        {/* Form Input */}
        <div className="flex flex-col gap-4">
          {/* children */}
          {isNext ? (
            <InputCompany
              firstState={firstState}
              firstError={firstError}
              handleChangeFirstState={handleChangeFirstState}
              handleSubmit={handleNextDetail}
            />
          ) : (
            <InputCompanyDetail
              secondState={secondState}
              secondError={secondError}
              agree={agree}
              isLoading={isLoading}
              provinces={provinces}
              cities={cities}
              subdistricts={subdistricts}
              handleChangeSecondState={handleChangeSecondState}
              handleChangeLocation={handleChangeLocation}
              handleChangeAgree={handleChangeAgree}
              handleCancel={handlePrev}
              handleSubmit={handleClick}
            />
          )}
          <Divider className="m-0" />
          <div id="buttonGoogle" />
          {/* <Button className="flex justify-center text-md-medium gap-3 items-center p-5 rounded-lg bg-white shadow-xs">
                        <GoogleIcon className={'w-6 h-6'} /> Daftar Dengan Google
                    </Button>
                    <Button className="flex justify-center text-md-medium gap-3 items-center p-5 rounded-lg bg-white shadow-xs">
                        <FacebookIcon className={' fill-[#1877F2] w-6 h-6'} />
                        Daftar Dengan Facebook
                    </Button> */}
          <p className="text-sm-regular text-center text-gray-500">
            Sudah memiliki akun?
            <NavLink
              to={'/login'}
              className="text-primary-600 hover:text-primary-600 text-sm-medium ml-2">
              Login
            </NavLink>
          </p>
        </div>
      </Col>
    </Row>
  )
}

export default RegisterTypeCompanyMain
