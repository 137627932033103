import { Col } from 'antd'
import {
  CheckIcon,
  DigitalisasiIcon,
  LayananCepatIcon,
  TerpercayaIcon,
  TransparanIcon
} from 'assets'
import CustomButton from 'components/ui/custom-button'
import SectionTitle from 'components/ui/section-title'
import React from 'react'

const KenapaHarusBirdMobil = [
  {
    label: 'Terpercaya',
    icon: <TerpercayaIcon />
  },
  {
    label: 'Transparan',
    icon: <TransparanIcon />
  },
  {
    label: 'Digitalisasi',
    icon: <DigitalisasiIcon />
  },
  {
    label: 'Layanan Cepat',
    icon: <LayananCepatIcon />
  }
]

function WhyUsSection({ handleNavigation }) {
  return (
    <Col span={24} lg={{ order: 8 }} className="bg-gray-50 py-9 lg:py-20">
      <Col
        span={22}
        lg={{ span: 20 }}
        className="mx-auto pb-4 md:pb-0 flex-col flex gap-6 md:gap-12">
        {/* Section Title */}
        <SectionTitle
          title={'Kenapa Harus BirdMobil?'}
          description={
            'Kami penyedia layanan Jual, Beli, Perawatan Mobil dan Inspeksi dari Bluebird Group yang ANDAL dalam melayani Anda'
          }
        />
        {/* icon container */}
        <div className="lg:flex grid grid-cols-2 gap-5 lg:gap-0 lg:flex-row justify-between">
          {KenapaHarusBirdMobil.map((data, key) => (
            <div key={key} className="grid grid-cols-1">
              <div className="relative flex justify-center items-end h-[70px] object-contain">
                {data.icon}
              </div>
              <div className="flex flex-col justify-center items-center gap-2 self-end">
                <div className="text-md-semibold md:text-lg-semibold text-gray-900">
                  {data.label}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="justify-center hidden lg:flex">
          <CustomButton label={'Daftar Sekarang'} onClick={() => handleNavigation('/register')} />
        </div>
      </Col>
    </Col>
  )
}

export default WhyUsSection
