import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'
const CardRecommendationPrice = ({ data }) => {
  return (
    <CardSectionHeader hideDivider>
      <div className="grid grid-cols-1 gap-2 sm:flex items-center justify-between sm:gap-3">
        <p className="text-md-medium md:text-xl-medium text-gray-600">Ekspektasi Harga Jual:</p>
        <p className=" text-xl-medium md:display-xs-medium text-right text-gray-900 whitespace-nowrap">
          Rp. {onRupiah(data?.catalog?.price)}
        </p>
      </div>
    </CardSectionHeader>
  )
}

export default CardRecommendationPrice
