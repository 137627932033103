import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

function CardRecommendationInspection({
  data,
}) {
  return (
    <CardSectionHeader headerTitle="Rekomendasi Perbaikan">
      <div className="text-xs-regular text-gray-600 grid grid-cols-1 pb-4">
        <div className='grid grid-cols-2 bg-gray-50 text-gray-700 text-xs-medium p-4'>
          <p>Sparepart perlu perbaikan</p>
          <p>Perbaikan</p>
        </div>
        {
          data?.serviceRecomendation?.serviceRecomendationList?.length > 0 &&
          data?.serviceRecomendation?.serviceRecomendationList?.map((item, index) => (
            <div className='grid grid-cols-2 text-gray-600 text-xs-regular p-4' key={index}>
              <p>{item?.name}</p>
              <p>{item?.repairment}</p>
            </div>
          ))
        }
      </div>
    </CardSectionHeader>
  )
}

export default CardRecommendationInspection
