import { DatePicker } from "antd";
import CardSectionHeader from "components/ui/card-section-header";
import FormInput from "components/ui/form/form-input";
import FormSelect from "components/ui/form/form-select";
import FormTextArea from "components/ui/form/form-textarea";
import InputCurrency from "components/ui/input-currency";
import { months } from "data/constants";
import moment from 'moment'

const inputPajak = {
  id: 1,
  label: 'Pajak',
  name: 'pajak',
  type: 'radio',
  options: [
    { id: 1, type: 'radio', name: 'radioPajak', value: '1', label: 'Hidup' },
    { id: 2, type: 'radio', name: 'radioPajak', value: '2', label: 'Mati' },
  ]
}

function CardInformasiTambahan({
  documentData,
  handleChangeKelengkapan,
  handleChangeDocument,
  handleChangeLocation,
  subdistricts,
  provinces,
  cities }) {
  const inputs = [
    {
      id: 1,
      child: [
        {
          id: 'input1',
          label: 'Nomor Telepon',
          name: 'phone',
          type: 'text',
          placeholder: 'Input Nomor Telepon',
          addonBefore: "+62",
          mandatory: true,
          onChange: (e) => {
            handleChangeDocument('phone', e.target.value)
          },
          value: documentData?.phone
        }
      ]
    },
    {
      id: 2,
      child: [
        {
          id: 'input21',
          label: 'Jarak Tempuh',
          name: 'mileage',
          type: 'text',
          placeholder: 'Input Jarak Tempuh',
          mandatory: true,
          onChange: (e) => {
            handleChangeDocument('mileage', e.target.value)
          },
          value: documentData?.mileage
        },
        {
          id: 'input22',
          label: 'Plat Nomor',
          name: 'police_number',
          type: 'text',
          placeholder: 'Input Plat Nomor',
          mandatory: true,
          onChange: (e) => {
            handleChangeDocument('police_number', e.target.value)
          },
          value: documentData?.police_number
        }
      ]
    },
    {
      id: 3,
      child: [
        {
          id: 'input31',
          label: 'Nomor Rangka',
          name: 'frame_number',
          type: 'text',
          placeholder: 'Input Nomor Rangka',
          mandatory: true,
          onChange: (e) => {
            handleChangeDocument('frame_number', e.target.value)
          },
          value: documentData?.frame_number
        },
        {
          id: 'input32',
          label: 'Nomor Mesin',
          name: 'engine_number',
          type: 'text',
          placeholder: 'Input Nomor Mesin',
          onChange: (e) => {
            handleChangeDocument('engine_number', e.target.value)
          },
          value: documentData?.engine_number
        }
      ]
    },
    {
      id: 4,
      child: [
        {
          id: 'input41',
          label: 'STNK',
          name: 'stnk_number',
          type: 'text',
          placeholder: 'Input STNK',
          mandatory: true,
          onChange: (e) => {
            handleChangeDocument('stnk_number', e.target.value)
          },
          value: documentData?.stnk_number
        },
        {
          id: 'input42',
          label: 'BPKB',
          name: 'bpkb_number',
          type: 'text',
          placeholder: 'Input BPKB',
          mandatory: true,
          onChange: (e) => {
            handleChangeDocument('bpkb_number', e.target.value)
          },
          value: documentData?.bpkb_number
        },
        {
          id: 'input43',
          label: 'KIR',
          name: 'kir_number',
          type: 'text',
          placeholder: 'Input KIR',
          onChange: (e) => {
            handleChangeDocument('kir_number', e.target.value)
          },
          value: documentData?.kir_number
        },
      ]
    },
  ]
  const inputPajakExpired = [
    {
      id: 'pajak1',
      name: 'tax_month',
      picker: 'month',
      type: 'select',
      placeholder: 'Bulan',
      mandatory: false,
      value: documentData?.tax_month?.value,
      options: months,
      onChange: (_, option) => {
        handleChangeDocument('tax_month', option)
      }
    },
    {
      id: 'pajak2',
      name: 'tax_year',
      picker: 'year',
      type: 'picker',
      option: [],
      placeholder: 'Tahun',
      mandatory: false,
      onChange: () => { },
      value: documentData?.tax_year
    },
  ]
  const inputAddress = [
    {
      id: 'address1',
      name: 'province',
      type: 'select',
      placeholder: 'Pilih Provinsi',
      mandatory: true,
      value: documentData?.province?.value,
      label: 'Provinsi',
      options: provinces,
      onChange: (_, option) => {
        handleChangeLocation('province', option)
      }
    },
    {
      id: 'address2',
      name: 'city',
      type: 'select',
      placeholder: 'Pilih Kota',
      mandatory: true,
      value: documentData?.city?.value,
      options: cities,
      label: 'Kota',
      onChange: (_, option) => {
        handleChangeLocation('city', option)
      }
    },
    {
      id: 'address3',
      name: 'subdistrict',
      type: 'select',
      placeholder: 'Pilih Kecamatan',
      mandatory: true,
      value: documentData?.subdistrict?.value,
      options: subdistricts,
      label: 'Kecamatan',
      onChange: (_, option) => {
        handleChangeLocation('subdistrict', option)
      }
    },
    {
      id: 'address4',
      name: 'postal_code',
      type: 'input',
      placeholder: 'Kode Pos',
      mandatory: true,
      label: 'Kode POS',
      value: documentData?.postal_code,
      onChange: (e) => {
        handleChangeDocument('postal_code', e.target.value)
      }
    },
  ]
  const itemAddress = {
    id: 2,
    rows: 3,
    name: 'address',
    type: 'textarea',
    label: 'Alamat',
    placeholder: 'Masukan Alamat',
    mandatory: true,
    value: documentData?.address,
    onChange: (e) => handleChangeDocument('address', e.target.value)
  }
  return (
    <CardSectionHeader headerTitle="Informasi Tambahan">
      <div className="grid grid-cols-1 gap-6">
        {/* first - fourth */}
        {
          inputs.map((input) => (
            <div className={`grid grid-cols-1 md:grid-cols-3 gap-3`} key={input.id}>
              {
                input.child.map((inputItem) => {
                  if (inputItem.name !== 'mileage') {
                    return <FormInput data={inputItem} key={inputItem.id} />
                  } else {
                    return (
                      <div className="grid grid-cols-1 gap-2" key={inputItem.id}>
                        <p className="text-sm-medium text-gray-700">{inputItem.label} {inputItem?.mandatory && <span className="text-danger-500">*</span>}</p>
                        <InputCurrency
                          className="rounded-md"
                          placeholder={inputItem.placeholder}
                          value={inputItem?.value}
                          onChange={(value, name) => handleChangeDocument(name, value)}
                          name="mileage"
                        />
                      </div>
                    )
                  }
                }
                )
              }
            </div>
          ))
        }
        {/* fifth */}
        <div className={`grid grid-cols-1 md:grid-cols-3 gap-3`}>
          {/* radio - pajak */}
          <div className="text-sm-medium text-gray-900 grid grid-cols-1 gap-2 items-center">
            <p className="text-sm-medium text-gray-700">Pajak <span className="text-danger-500">*</span></p>
            <div className="flex gap-10 items-center">
              {inputPajak.options.map((opt) => (
                <div key={opt.id} className="flex gap-2">
                  <input id="pajak" className="accent-primary-500"
                    name={opt.name}
                    value={opt.value}
                    type={opt.type}
                    checked={documentData.tax === opt.value}
                    onChange={(e) => {
                      handleChangeDocument('tax', e.target.value)
                    }}
                  />
                  <label htmlFor={opt.name}>{opt.label}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-2">
            <p className="text-sm-medium text-gray-700">Pajak Hidup Sampai <span className="text-danger-500">*</span></p>
            <div className="grid grid-cols-2 gap-2">
              {
                inputPajakExpired.map((inputItem) => {
                  if (inputItem.type === 'picker') {
                    return (
                      <div className="grid grid-cols-1 gap-2" key={inputItem.id}>
                        <p className="text-sm-medium text-gray-700">{inputItem.label}</p>
                        <DatePicker
                          placeholder={inputItem.placeholder}
                          onChange={(_, dateString) => handleChangeDocument(inputItem.name, dateString)}
                          picker={inputItem.picker}
                          value={inputItem.value !== '' ? moment(inputItem.value) : null}
                        />
                      </div>
                    )
                  } else {
                    return <FormSelect data={inputItem} key={inputItem.id} />
                  }
                })
              }
            </div>
          </div>
        </div>
        {/* fourth */}
        {/* <div className={`grid grid-cols-1 gap-3`}>
          <p className="text-sm-medium text-gray-700">Kelengkapan Dokumen</p>
          <div className="flex flex-col md:flex-row gap-6">
            {
              documentData.docs.map((item, idx) => (
                <div className="flex items-center space-x-2" key={idx}>
                  <input
                    type="checkbox"
                    className="accent-primary-500 h-3 w-3"
                    value={item.value}
                    checked={item.checked}
                    onChange={e => handleChangeKelengkapan(e)}
                  />
                  <p className="text-gray-700 text-md-medium">{item.label}</p>
                </div>
              ))
            }
          </div>
        </div> */}
        <div className={`grid grid-cols-2 gap-3`}>
          {
            inputAddress.map((inputItem) => {
              if (inputItem.type === 'input') {
                return <FormInput key={inputItem.id} data={inputItem} />
              } else {
                return <FormSelect data={inputItem} key={inputItem.id} />
              }
            })
          }
        </div>
        <FormTextArea data={itemAddress} />
      </div>
    </CardSectionHeader >
  )
}
export default CardInformasiTambahan