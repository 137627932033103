import moment from 'moment'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
function CardInformationJadwal({ inspeksi, data, workshop, servis }) {
  return (
    <CardSectionHeader
      headerTitle={`Informasi ${inspeksi ? 'Inspeksi' : servis ? 'Servis' : 'Test Drive'}`}>
      <div className="grid grid-cols-1 gap-6">
        <div className="grid grid-cols-1 gap-6">
          <div>
            <p className="text-sm-regular text-gray-600">Tempat</p>
            <p className="text-md-medium text-gray-900">{workshop?.name}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <p className="text-sm-regular text-gray-600">Tanggal</p>
            <p className="text-md-medium text-gray-900">
              {moment(data?.date).format('DD MMM YYYY')}
            </p>
          </div>
          <div>
            <p className="text-sm-regular text-gray-600">Waktu</p>
            <p className="text-md-medium text-gray-900">{data?.hours?.time}</p>
          </div>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardInformationJadwal
