import { ChevronRightIcon, PinLocationIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

const CardLocationUnit = ({ data }) => {
  return (
    <CardSectionHeader hideDivider>
      <div className="grid grid-cols-1 gap-5">
        <div className=" cursor-pointer flex items-center justify-between p-3 bg-primary-50 rounded-lg">
          <div className="flex items-center gap-3">
            <PinLocationIcon className={'stroke-gray-900'} />
            <div className="flex flex-col gap-1">
              <p className="text-sm-semibold text-gray-800">Tersedia di</p>
              <p className="text-sm-regular text-gray-900">{data?.branch_name}</p>
            </div>
          </div>
          <ChevronRightIcon className={'stroke-gray-400'} />
        </div>

        {/* Detail Address */}
        <p className="text-sm-regular text-gray-500">
          {data?.branch_address || '-'}
        </p>
      </div>
    </CardSectionHeader>
  )
}

export default CardLocationUnit
