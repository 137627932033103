import API from 'services'

const getWorkshops = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/master-service/website/workshops/all?keywords=&sort_by=name&order_by=asc`
  )
  return response.data
}

const getSchedules = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/test-drives/schedules`
  )
  return response.data
}

const postTestDrive = async (data) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/test-drives`,
    data
  )
  return response.data
}

const getRangeBookingProcess = async (catalogId) => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/catalogs/${catalogId}/booking-date`
  )
  return response.data
}

export { getWorkshops, getSchedules, postTestDrive, getRangeBookingProcess }
