const PhoneIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3351 14.1V16.6C18.3361 16.8321 18.2886 17.0618 18.1956 17.2744C18.1026 17.4871 17.9662 17.678 17.7952 17.8349C17.6242 17.9918 17.4223 18.1112 17.2024 18.1856C16.9826 18.2599 16.7496 18.2875 16.5185 18.2666C13.9542 17.988 11.491 17.1118 9.32682 15.7083C7.31334 14.4289 5.60626 12.7218 4.32682 10.7083C2.91846 8.53432 2.04202 6.05914 1.76848 3.48331C1.74766 3.25287 1.77505 3.02061 1.8489 2.80133C1.92275 2.58205 2.04146 2.38055 2.19745 2.20966C2.35345 2.03877 2.54332 1.90224 2.75498 1.80875C2.96663 1.71526 3.19543 1.66686 3.42682 1.66665H5.92682C6.33124 1.66267 6.72331 1.80588 7.02995 2.06959C7.33659 2.3333 7.53688 2.69952 7.59348 3.09998C7.699 3.90003 7.89469 4.68558 8.17682 5.44165C8.28894 5.73992 8.3132 6.06407 8.24674 6.37571C8.18028 6.68735 8.02587 6.9734 7.80182 7.19998L6.74348 8.25831C7.92978 10.3446 9.65719 12.072 11.7435 13.2583L12.8018 12.2C13.0284 11.9759 13.3144 11.8215 13.6261 11.7551C13.9377 11.6886 14.2619 11.7129 14.5601 11.825C15.3162 12.1071 16.1018 12.3028 16.9018 12.4083C17.3066 12.4654 17.6763 12.6693 17.9406 12.9812C18.2049 13.2931 18.3453 13.6913 18.3351 14.1Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PhoneIcon
