import { DatePicker } from "antd"
import CardSectionHeader from "components/ui/card-section-header"
import FormInput from "components/ui/form/form-input"
import FormSelect from "components/ui/form/form-select"
import dayjs from "dayjs"

function CardPilihMobil({
  carData,
  brands,
  models,
  units,
  types,
  fuels,
  colors,
  transmissions,
  handleChange,
  handleChangeCarList,
}) {
  const dateFormat = 'YYYY/MM/DD';
  const inputs = [
    {
      id: 1,
      label: 'Pilih Mobil dari Dashboard (opsional)',
      name: 'unit_name',
      type: 'select',
      value: carData?.unit?.value,
      options: units,
      placeholder: 'Pilih Mobil',
      mandatory: false,
      onChange: (_, option) => {
        handleChangeCarList('unit', option)
      }
    },
    {
      id: 2,
      label: 'Merek',
      name: 'unit_merk',
      type: 'select',
      options: brands,
      placeholder: 'Pilih Merek',
      mandatory: true,
      value: carData?.brand?.value,
      onChange: (_, option) => {
        handleChangeCarList('brand', option)
      },
      disabled: carData.unit.value !== '' && carData.unit.value !== undefined
    },
    {
      id: 3,
      label: 'Model',
      name: 'unit_model',
      type: 'select',
      options: models,
      value: carData?.model?.value,
      placeholder: 'Pilih Model',
      mandatory: true,
      onChange: (_, option) => {
        handleChangeCarList('model', option)
      },
      disabled: carData.unit.value !== '' && carData.unit.value !== undefined
    },
    {
      id: 4,
      label: 'Tipe',
      name: 'unit_type',
      type: 'select',
      options: types,
      value: carData?.type?.value,
      placeholder: 'Pilih Tipe',
      mandatory: true,
      onChange: (_, option) => {
        handleChangeCarList('type', option)
      },
      disabled: carData.unit.value !== '' && carData.unit.value !== undefined
    },
    {
      id: 5,
      label: 'Tahun Keluaran',
      name: 'year',
      type: 'picker',
      value: carData?.year,
      placeholder: 'Pilih Tahun Keluaran',
      mandatory: true,
    },
    {
      id: 6,
      label: 'Transmisi',
      name: 'unit_transmisi',
      type: 'select',
      options: transmissions,
      value: carData?.transmisi?.value,
      placeholder: 'Pilih Transmisi',
      mandatory: true,
      onChange: (_, option) => {
        handleChangeCarList('transmisi', option)
      },
      disabled: carData.unit.value !== '' && carData.unit.value !== undefined
    },
    {
      id: 12,
      label: 'Bahan Bakar',
      name: 'fuel',
      type: 'select',
      options: fuels,
      value: carData?.fuel?.value,
      placeholder: 'Pilih Bahan Bakar',
      mandatory: true,
      onChange: (_, option) => {
        handleChangeCarList('fuel', option)
      },
      // disabled: carData.unit.value !== '' && carData.unit.value !== undefined
    },
    {
      id: 11,
      label: 'Warna',
      name: 'color',
      type: 'select',
      options: colors,
      value: carData?.color?.value,
      placeholder: 'Pilih Warna',
      mandatory: true,
      onChange: (_, option) => {
        handleChangeCarList('color', option)
      },
      // disabled: carData.unit.value !== '' && carData.unit.value !== undefined
    },
  ]


  return (
    <CardSectionHeader headerTitle="Pilih Mobil">
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
        {inputs.map((inputItem) => {
          if (inputItem.type === 'text')
            return <FormInput key={inputItem.id} data={inputItem} />
          else if (inputItem.type === 'select')
            return <FormSelect key={inputItem.id} data={inputItem} />
          else if (inputItem.type === 'picker')
            return (
              <div className="grid grid-cols-1 gap-2" key={inputItem.id}>
                <p className="text-sm-medium text-gray-700">{inputItem.label} <span className="text-danger-500">*</span></p>
                <DatePicker
                  placeholder={inputItem.placeholder}
                  onChange={(_, dateString) => handleChange('year', dateString)}
                  picker="year"
                  value={carData?.year !== '' ? dayjs(`${carData?.year}/01/01`, dateFormat) : ''}
                  disabled={carData.unit.value !== '' && carData.unit.value !== undefined}
                />
              </div>
            )
        })}

      </div>
    </CardSectionHeader>
  )
}
export default CardPilihMobil