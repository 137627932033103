import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from 'assets'
import React, { useRef, useState } from 'react'
import BadgeItem from '../badge-item'
import CardSectionHeader from '../card-section-header'

function CardHistory({ data }) {
  const [isExpand, setIsExpand] = useState(false)

  const countData = useRef(3)
  const handleToggle = () => {
    if (!isExpand) countData.current = data.length
    else countData.current = 3
    setIsExpand((prevValue) => !prevValue)
  }

  const splitData = data.slice(0, countData.current)

  return (
    <CardSectionHeader headerTitle={'Riwayat'}>
      {/* Container */}
      <div className="grid grid-cols-1 gap-5">
        {/* Items */}
        {splitData.map((item, key) => (
          <div
            key={key}
            className="p-6 grid grid-cols-[1fr_1fr_1fr_30px] justify-between bg-gray-50 rounded-[4px] cursor-pointer">
            <div className="flex flex-col gap-1">
              <p className="text-lg-medium text-gray-900">{item.label}</p>
              <p className="text-md-regular text-gray-600">{item.date}</p>
            </div>
            {item?.type === 'service' ? (
              <div className="flex flex-col gap-1">
                <p className="text-sm-regular text-gray-900">{item.totalItem} Item</p>
                <p className="text-md-semibold text-primary-500">Rp {item.price}</p>
              </div>
            ) : item?.type === 'inspection' ? (
              <div className="flex flex-col gap-1">
                <p className="text-sm-regular text-gray-900">Hasil</p>
                <p className="text-md-semibold text-primary-500">{item?.result}</p>
              </div>
            ) : null}
            <div className="flex flex-col gap-1">
              <p className="text-sm-regular text-gray-900">Status</p>
              {item?.status === 'progress' ? (
                <BadgeItem className={'max-w-fit'} type="default">
                  Sedang Berlangsung
                </BadgeItem>
              ) : item?.status === 'done' ? (
                <BadgeItem className={'max-w-fit'} type="success">
                  Selesai
                </BadgeItem>
              ) : null}
            </div>
            <div className="flex items-center justify-end">
              <ChevronRightIcon className={'stroke-gray-400'} />
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 items-center mt-5">
        {isExpand ? (
          <div className="flex gap-2 items-center justify-center">
            <p onClick={handleToggle} className="text-gray-600 text-md-medium cursor-pointer">
              Lebih Sedikit
            </p>
            <ChevronUpIcon className="stroke-gray-600" />
          </div>
        ) : (
          <div className="flex gap-2 items-center justify-center">
            <p onClick={handleToggle} className="text-primary-600 text-md-medium cursor-pointer">
              Lihat Semua
            </p>
            <ChevronDownIcon className="stroke-primary-600" />
          </div>
        )}
      </div>
    </CardSectionHeader>
  )
}

export default CardHistory
