import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
const SelesaiTestDrive = ({ data }) => {
  return (
    <CardSectionHeader headerTitle={'Test Drive selesai'}>
      <div className="flex flex-col gap-3">
        <div className="sm:flex sm:gap-0 grid grid-cols-1 gap-2 justify-between items-center">
          <p className="text-md-regular text-gray-600">Test Drive selesai pada:</p>
          <p className="text-md-medium text-gray-700 text-right">
            {data?.date} - {data?.end_time}
          </p>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default SelesaiTestDrive
