import { Upload } from 'antd'
import { ImageIcon } from 'assets'
import React from 'react'
import { useState } from 'react'
import ModalImage from '../modal/modal-image'

import './style.css'
import { showErrorMessage } from 'utils/toastMessage'

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
function FormImage({ imageProps }) {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  // const [fileList, setFileList] = useState([
  // {
  //   uid: '-3',
  //   name: 'image.png',
  //   status: 'done',
  //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  // },
  // {
  //   uid: '-4',
  //   name: 'image.png',
  //   status: 'done',
  //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
  // }
  // ])
  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
  }
  const uploadButton = (
    <div className="flex flex-col justify-center items-center gap-1">
      <ImageIcon className={'stroke-gray-400'} />
      <div className="text-xs-medium text-gray-400">Tambah Gambar</div>
    </div>
  )
  return (
    <>
      <p className="text-lg-medium text-gray-900">
        {imageProps?.label} {imageProps?.mandatory && <span className="text-danger-500">*</span>}
      </p>
      <Upload
        accept="image/jpg, image/jpeg, image/png"
        beforeUpload={(file) => {
          const newArrayFormat = ['image/jpg', 'image/jpeg', 'image/png']
          let valid = 0
          newArrayFormat.forEach((formatType) => (file.type === formatType ? (valid += 1) : null))
          if (valid === 0) {
            showErrorMessage(`${file.name} format file harus salah satu dari format jpg,jpeg,png`)
            return Upload.LIST_IGNORE
          } else if (Math.round(file.size / 1048576) > 10) {
            showErrorMessage(
              `${file.name} ukuran file terlalu besar, Silahkan pilih gambar dibawah ukuran 10mb`
            )
            return Upload.LIST_IGNORE
          }
          return false
        }}
        listType="picture-card"
        onPreview={handlePreview}
        {...imageProps}>
        {imageProps?.fileList?.length >= imageProps?.limit ? null : uploadButton}
      </Upload>
      <p className="text-sm-medium text-gray-500 m-0 p-0">{imageProps?.placeholder}</p>
      <ModalImage imageUrl={previewImage} onCancel={handleCancel} openModal={previewOpen} />
    </>
  )
}

export default FormImage
