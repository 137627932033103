import React from 'react'
import KonfirmasiPembayaranCard from '../templates-status-card/konfirmasi-pembayaran-card'
import ProgressServiceCard from '../templates-status-card/progress-service-card'
import MenungguKonfirmasiInspeksi from '../card-status-transaction/inspeksi/menunggu-konfirmasi-inspeksi'
import CardDetailCar from 'components/ui/card/card-detail-car'
import { CardDetailPayment, CardDetailService, CardRecommendationInspection } from '..'
import CardResultInspection from 'components/ui/card/card-result-inspection'
import { onRupiah } from 'utils/formatCurrency'
import ScheduledInspeksi from '../card-status-transaction/inspeksi/scheduled-inspeksi'
import DoneScheduledInspeksi from '../card-status-transaction/inspeksi/done-scheduled-inspeksi'
import ServiceInfoCard from '../templates-status-card/service-info-card'
const InspeksiContentTransaction = ({
  data,
  userId,
  status,
  validationCheckPaymentMethod,
  serviceName,
  navigateHandler
}) => {
  const CardStatusTransactionMap = {
    waiting_payment: (
      <KonfirmasiPembayaranCard
        serviceName={serviceName}
        validationCheckPaymentMethod={validationCheckPaymentMethod}
        data={{ expired_at: data?.payment_expired_at }}
      />
    ),
    unpaid: (
      <KonfirmasiPembayaranCard
        serviceName={serviceName}
        validationCheckPaymentMethod={validationCheckPaymentMethod}
        data={{ expired_at: data?.payment_expired_at }}
      />
    ),
    waiting_confirmation: <MenungguKonfirmasiInspeksi />,
    scheduled: (
      <ScheduledInspeksi data={{ scheduled_at: data?.scheduled_at }} serviceName={serviceName} />
    ),
    done_scheduled: (
      <DoneScheduledInspeksi
        data={{ done_scheduled_at: data?.done_scheduled_at }}
        serviceName={serviceName}
      />
    ),
    ongoing: (
      <ProgressServiceCard
        data={{
          serviceStatusList: data?.booking_service_statuses,
          progress_finished_total: data?.progress_finished_total,
          progress_total: data?.progress_total,
          service_date: data?.service_date,
          service_end_time: data?.service_end_time
        }}
        serviceName={serviceName}
      />
    ),
    failed: <ServiceInfoCard status={'inspeksi_failed'} />,
    cancel: <ServiceInfoCard status={'inspeksi_cancel'} />,
    done: null
  }
  return (
    <>
      {CardStatusTransactionMap[status]}
      <CardDetailCar data={data} navigateDetailHandler={navigateHandler} />
      <CardDetailService data={data} />
      {data?.inspection_result?.inspectionCategoryList?.length > 0 && (
        <CardResultInspection data={data} id={data.id} />
      )}
      {/* KHUSUS RECOMMENDATION SERVICE TYPE INSPEKSI ADMIN PERUSAHAAN */}
      {data?.serviceRecomendation?.serviceRecomendationList?.length > 0 &&
        data?.company_id !== 0 &&
        data?.company_admin_id == userId && <CardRecommendationInspection data={data} />}
      {/* KHUSUS RECOMMENDATION SERVICE TYPE INSPEKSI CUSTOMER */}
      {data?.serviceRecomendation?.serviceRecomendationList?.length > 0 &&
        data?.company_id == 0 &&
        data?.user_id == userId && <CardRecommendationInspection data={data} />}
      <CardDetailPayment
        data={data?.dataPayment}
        total={onRupiah(data?.catalog_invoice?.total_price)}
      />
    </>
  )
}

export default InspeksiContentTransaction
