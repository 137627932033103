import { ChevronRightIcon, ImagePlaceholder } from 'assets'
import React from 'react'
import CardSectionHeader from '../card-section-header'
import { onRupiah } from 'utils/formatCurrency'

function CardDetailCar({ navigateDetailHandler, data }) {
  const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
  const catalog = data?.catalog
  return (
    <CardSectionHeader>
      <div className="grid grid-cols-1 md:grid-cols-[200px_1fr] gap-6">
        <div className="bg-gray-200 md:max-w-[200px] md:max-h-[140px] flex items-center justify-center rounded-[4px]">
          <img
            src={
              catalog?.thumbnail_filename
                ? urlImage.concat(catalog?.thumbnail_filename)
                : ImagePlaceholder
            }
            alt="car"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-lg-medium text-gray-900">
            {catalog?.unit_name || catalog?.name || data?.unit_name}
          </span>
          <div
            onClick={
              data?.serviceType === 'beli' || data?.serviceType === 'test drive'
                ? navigateDetailHandler.bind(
                    this,
                    data?.unit_type !== 'taxi'
                      ? `/beli/mobil-bekas/detail/${data?.catalog?.id}`
                      : `/beli/ex-taxi/detail/${data?.catalog?.id}`,
                    null
                  )
                : navigateDetailHandler.bind(
                    this,
                    '/dashboard/unit/detail-unit',
                    data?.catalog?.unit_id
                  )
            }
            className="flex w-fit hover:cursor-pointer text-sm-medium text-primary-600 hover:text-primary-700 justify-start gap-2 items-center ">
            <p>Detail Unit</p>
            <ChevronRightIcon className={'stroke-primary-600 hover:stroke-primary-700'} />
          </div>
          {data?.unit_type !== 'taxi' ? (
            <div className="grid grid-cols-2 gap-3">
              <div>
                <p className="text-sm-regular text-gray-600">Tahun</p>
                <p className="text-md-medium text-gray-900">{catalog?.production_year}</p>
              </div>
              <div>
                <p className="text-sm-regular text-gray-600">Terakhir diservis</p>
                <p className="text-md-medium text-gray-900">{data?.latestServiceDate}</p>
              </div>
              <div>
                <p className="text-sm-regular text-gray-600">Plat Nomor</p>
                <p className="text-md-medium text-gray-900">{catalog?.police_number}</p>
              </div>
              {data?.serviceType !== 'servis' ? (
                <div>
                  <p className="text-sm-regular text-gray-600">Transmisi</p>
                  <p className="text-md-medium text-gray-900">{catalog?.transmission_name}</p>
                </div>
              ) : null}
              {data.serviceType === 'servis' ? (
                <div>
                  <p className="text-sm-regular text-gray-600">Km Servis Terakhir</p>
                  <p className="text-md-medium text-gray-900">
                    {data?.booking_invoice?.transaction_detail?.status?.toLowerCase() === 'done'
                      ? data?.mileage
                        ? `${onRupiah(data?.mileage)} KM`
                        : '-'
                      : '-'}
                  </p>
                </div>
              ) : null}
              {data.serviceType === 'beli' && (
                <div>
                  <p className="text-sm-regular text-gray-600">Kepemilikan</p>
                  <p className="text-md-medium text-gray-900">
                    {catalog?.owner_type === 'internal'
                      ? catalog?.owner_fullname
                      : catalog?.owner_type}
                  </p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardDetailCar
