import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function SampleNextArrow(props) {
  const { style, onClick, arrowClassContainer, arrowClassIcon } = props
  return (
    <>
      <div
        className={`${arrowClassContainer} next reviews absolute top-[calc(50%-28px)] rounded-full right-[-30px] cursor-pointer  w-14 h-14`}
        style={{ ...style }}
        onClick={onClick}>
        <div className="flex h-full">
          <svg
            className={`h-full ${arrowClassIcon}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="white"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 18L15 12L9 6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  )
}

function SamplePrevArrow(props) {
  const { style, onClick, arrowClassIcon, arrowClassContainer } = props
  return (
    <>
      <div
        className={`${arrowClassContainer} previous reviews z-50 absolute top-[calc(50%-28px)] rounded-full left-[-30px] cursor-pointer w-14 h-14`}
        style={{ ...style }}
        onClick={onClick}>
        <div className="flex h-full justify-end">
          <svg
            className={`h-full ${arrowClassIcon}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 18L9 12L15 6"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  )
}

export const CarouselMultipleLayout = ({
  center,
  totalItem,
  children,
  slideToShow,
  slidesToScroll,
  className,
  arrowClassIcon = '',
  arrowClassContainer = 'bg-gray-900/50',
  arrows = true,
  responsive,
  infinite,
  autoplay
}) => {
  const settings = {
    autoplay: autoplay,
    dots: false,
    infinite: infinite ? infinite : (totalItem ? totalItem : 0) > (slideToShow ? slideToShow : 3),
    // infinite: true,
    arrows: arrows,
    centerMode: center || false,
    slidesToShow: slideToShow || 3,
    slidesToScroll: slidesToScroll || 1,
    nextArrow: (
      <SampleNextArrow arrowClassContainer={arrowClassContainer} arrowClassIcon={arrowClassIcon} />
    ),
    prevArrow: (
      <SamplePrevArrow arrowClassContainer={arrowClassContainer} arrowClassIcon={arrowClassIcon} />
    ),
    responsive: responsive
      ? responsive
      : [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
  }

  return (
    <>
      <Slider {...settings} className={className}>
        {children}
      </Slider>
    </>
  )
}
