const RadioIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      stroke="#667085"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5329 3.46673C13.9977 3.9311 14.3665 4.48254 14.6182 5.08953C14.8698 5.69652 14.9993 6.34715 14.9993 7.00423C14.9993 7.66131 14.8698 8.31194 14.6182 8.91893C14.3665 9.52592 13.9977 10.0774 13.5329 10.5417M6.46619 10.5334C6.00131 10.069 5.63251 9.51759 5.38089 8.9106C5.12927 8.30361 4.99976 7.65298 4.99976 6.9959C4.99976 6.33882 5.12927 5.68819 5.38089 5.0812C5.63251 4.47421 6.00131 3.92276 6.46619 3.4584M15.8912 1.1084C17.4534 2.67113 18.3311 4.79036 18.3311 7.00007C18.3311 9.20977 17.4534 11.329 15.8912 12.8917M4.10786 12.8917C2.5456 11.329 1.66797 9.20977 1.66797 7.00007C1.66797 4.79036 2.5456 2.67113 4.10786 1.1084M11.6662 7.00007C11.6662 7.92054 10.92 8.66673 9.99952 8.66673C9.07905 8.66673 8.33286 7.92054 8.33286 7.00007C8.33286 6.07959 9.07905 5.3334 9.99952 5.3334C10.92 5.3334 11.6662 6.07959 11.6662 7.00007Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RadioIcon
