import React from 'react'

const badgeType = {
  plain: 'bg-gray-100 text-bluegray-700',
  purple: 'text-purple-700 bg-purple-50',
  primary: 'text-primary-700 bg-primary-50',
  default: 'text-blue-700 bg-blue-50',
  warning: 'bg-warning-50 text-danger-700',
  danger: 'bg-danger-50 text-danger-700',
  bluelight: 'text-bluelight-700 bg-bluelight-50',
  success: 'text-success-700 bg-success-50',
  base: 'text-purple-700 bg-purple-50',
  rose: 'text-rose-700 bg-rose-50'
}

function BadgeItem({ className, type = 'primary', children }) {
  return (
    <div
      className={`text-xs-medium xl:text-sm-medium rounded-full text-center px-2 py-0.5 ${className} ${badgeType[type]}`}>
      {children}
    </div>
  )
}

export default BadgeItem
