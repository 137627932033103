import { BadgeIconDanger, BadgeIconSuccess, BadgeIconWarning } from 'assets/icons/badge-icons'
import BadgeItem from 'components/ui/badge-item'

export const RenderBadgeResultInspection = ({ name }) => {
  if (name === 'OK') {
    return (
      <BadgeItem type="success">
        <div className="flex items-center space-x-2">
          <BadgeIconSuccess className="h-3 w-3" />
          <p>Lulus</p>
        </div>
      </BadgeItem>
    )
  } else if (name === 'Repair' || name === 'Perlu Perbaikan') {
    return (
      <BadgeItem type="warning">
        <div className="flex items-center space-x-2">
          <BadgeIconWarning className="h-3 w-3" />
          <p>Diperbaiki</p>
        </div>
      </BadgeItem>
    )
  } else if (name === 'Replace' || name === 'Jelek') {
    return (
      <BadgeItem type="primary">
        <div className="flex items-center space-x-2">
          <BadgeIconDanger className="h-3 w-3" />
          <p>Tidak Lulus</p>
        </div>
      </BadgeItem>
    )
  } else {
    return (
      <BadgeItem type="plain">
        <div className="flex items-center space-x-2">
          <p>{name || 'Tidak Ada'}</p>
        </div>
      </BadgeItem>
    )
  }
}