import { Col, Collapse } from 'antd'
import { CollapseIcon, ExpandIcon } from 'assets'
import CustomButton from 'components/ui/custom-button'
import SectionTitle from 'components/ui/section-title'
import { faqData } from 'data/FAQ'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const { Panel } = Collapse

function FAQSection({ unOrder }) {
  const { push } = useHistory()
  return (
    <Col span={24} lg={{ order: unOrder ? null : 10 }} className="bg-gray-50 py-9 lg:py-20">
      <Col span={22} lg={{ span: 20 }} className="mx-auto flex-col flex gap-12 ">
        {/* Section Title */}
        <SectionTitle title={'FAQ'} />
        {/* FAQ collapse container */}
        <div>
          <Collapse
            accordion
            bordered={false}
            className="text-lg-medium text-gray-900 rounded-[4px] bg-gray-50"
            expandIconPosition={'end'}
            expandIcon={(props) => {
              return props.isActive ? <CollapseIcon /> : <ExpandIcon />
            }}>
            {faqData.slice(0, 5).map((data, key) => (
              <Panel
                style={{ border: '1px solid #EAECF0' }}
                className="p-2 flex flex-col justify-center mb-4 bg-white"
                header={data.title}
                key={key}>
                <div className="text-sm-medium text-gray-600">{data.description}</div>
              </Panel>
            ))}
          </Collapse>
        </div>
        {/* Button */}
        <div className="flex justify-center">
          <CustomButton onClick={() => push('/about-us/faq')} label={'FAQ Lainnya'} />
        </div>
      </Col>
    </Col>
  )
}

export default FAQSection
