import React from 'react'
import { Carousel, Col, Row, Tabs } from 'antd'

import {
  BirdMobilWhiteLogo,
  CarActiveIcon,
  CarIcon,
  MagnifyActiveIcon,
  MagnifyingIcon,
  ServiceActiveIcon,
  ServiceIcon,
  ShoppingBagActiveIcon,
  ShoppingBagIcon
} from 'assets'
import TabBuyContent from './tab-buy-content'
import TabSellContent from './tab-sell-content'
import TabServiceContent from './tab-service-content'
import TabInspectionContent from './tab-inspection-content'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useRecPriceUnit } from 'hooks'
import { useQuery } from 'react-query'
import { getWorkshops } from 'features/beli-mobil/service'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage } from 'utils/toastMessage'
const initialFilter = {
  province: null,
  city: null,
  location: null,
  merek: null,
  model: null,
  transmisi: null,
  bahanBakar: null,
  warna: null,
  tipe: null,
  minHarga: null,
  maxHarga: null,
  minTahun: null,
  maxTahun: null,
  minTempuh: null,
  maxTempuh: null,
  keywords: null,
  limit: 15,
  offset: 0
}
function PickMenuSection({ banners }) {
  const { push } = useHistory()
  const token = localStorage.getItem('token')
  const [changeComponent, setChangeComponent] = useState(true)
  const {
    handleChangeCarList,
    refetch,
    handleChange,
    price,
    brands,
    transmissions,
    models,
    types,
    state
  } = useRecPriceUnit()

  // BELI MENU
  const [filter, setFilter] = useState({ ...initialFilter })
  const [locations, setLocations] = useState(null)
  useQuery({
    queryKey: 'getWorkshops',
    queryFn: () => getWorkshops(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setLocations(newData)
    }
  })

  function handleClickBrandPrice(name, value) {
    let brandObj = {}
    if (name === 'merek') {
      brandObj = brands.find((data) => data.label.toLowerCase() === value.label.toLowerCase())
    }
    let newObj =
      name === 'harga'
        ? {
          ...filter,
          minHarga: value.minValue,
          maxHarga: value?.maxValue ? value?.maxValue : null
        }
        : { ...filter, [name]: brandObj }
    push('/beli/mobil-bekas', {
      data: {
        ...newObj
      }
    })
  }
  function handleChangeSearch(name, value) {
    value = value === undefined ? null : value
    let newObj = { ...filter, [name]: value }
    setFilter(newObj)
  }
  function handleNavigationCatalog() {
    push('/beli/mobil-bekas', {
      data: {
        ...filter
      }
    })
  }
  // JUAL MENU
  function handleClickJualForm() {
    if (token !== null) {
      push('/jual/form', {
        data: {
          ...state
        },
        send: true
      })
    } else {
      push('/login')
    }
  }
  const handleClickCheckHarga = () => {
    let isValid = true
    let nameValid = ''
    const keyState = Object.keys(state)
    for (let i = 0; i < keyState.length; i++) {
      if (Object.keys(state[keyState[i]]).length === 0 && [keyState[i]][0] !== 'year') {
        isValid = false
        nameValid = String(keyState[i])
      }
    }
    if (!isValid) return showErrorMessage(`Mohon isi ${nameToString[nameValid]}`)
    if (state.year === '') return showErrorMessage(`Mohon isi Tahun Keluaran`)
    refetch()
    setChangeComponent((prev) => !prev)
  }

  function handleNavigation(path, data) {
    if (data) {
      push(path, { data: data })
    } else {
      push(path)
    }
  }

  const items = [
    {
      label: 'Beli',
      icon: [<CarIcon key="0" />, <CarActiveIcon key="1" />],
      key: '1',
      children: (
        <TabBuyContent
          filter={filter}
          locations={locations}
          handleNavigationCatalog={handleNavigationCatalog}
          handleClickBrandPrice={handleClickBrandPrice}
          handleChangeSearch={handleChangeSearch}
        />
      )
    },
    {
      label: 'Jual',
      icon: [<ShoppingBagIcon key="0" />, <ShoppingBagActiveIcon key="1" />],
      key: '2',
      children: (
        <TabSellContent
          changeComponent={changeComponent}
          handleClick={handleClickCheckHarga}
          transmissions={transmissions}
          brands={brands}
          models={models}
          types={types}
          state={state}
          price={price}
          handleClickJualForm={handleClickJualForm}
          handleChange={handleChange}
          handleChangeCarList={handleChangeCarList}
        />
      )
    },
    {
      label: 'Servis',
      icon: [<ServiceIcon key="0" />, <ServiceActiveIcon key="1" />],
      key: '3',
      children: <TabServiceContent handleNavigation={handleNavigation} />
    },
    {
      label: 'Inspeksi',
      icon: [<MagnifyingIcon key="0" />, <MagnifyActiveIcon key="1" />],
      key: '4',
      children: <TabInspectionContent handleNavigation={handleNavigation} />
    }
  ]

  return (
    <Col span={24} lg={{ order: 1 }} className={`hidden w-full h-full lg:block relative bg-white`}>
      <div className='w-full h-[62vh] z-[0] absolute'>
        {/* <div className='bg-cover bg-no-repeat h-full bg-[url("assets/images/image-hero.webp")]' /> */}
        <div className='banner-landing-page overflow-x-hidden max-h-[480px]'>
          <Carousel autoplay autoplaySpeed={5000} infinite={true} dots={false} className='h-full'>
            {
              banners.length > 0 && banners?.map((el, idx) => (
                <div className={`bg-cover bg-no-repeat h-full block`} key={el?.id}>
                  <img src={el?.image} alt={`birdmobil-bann-${idx}`} className='h-full max-h-[480px] bg-no-repeat w-full object-cover' />
                </div>
              ))
            }
          </Carousel>
        </div>
      </div>
      <Row justify="center" className="mt-14 block">
        <Col span={20} className="items-center mx-auto">
          <Col span={16} xl={{ span: 10 }} className="flex flex-col gap-5 xl:gap-10">
            <div>
              <p className="display-lg-semibold text-white">
                Temukan Mobil Impian Berkualitas di BirdMobil
              </p>
            </div>
            <p className="display-xs-medium text-white">
              Pilih, Bayar & Nikmati Pengalaman Baru dari Kami
            </p>
          </Col>
        </Col>
      </Row>
      <Row justify="center" className=" mt-6 xl:mt-[48px]">
        <Col span={20} className="z-[99]">
          <Tabs renderTabBar={RenderTabBar} defaultActiveKey="1" items={items} />
        </Col>
      </Row>
    </Col>
  )
}

const RenderTabBar = ({ panes, activeKey, onTabClick }) => {
  return (
    <div className="flex min-w-min z-[1]">
      <div className="flex gap-6 px-5 py-3 bg-white">
        {panes.map(({ props: tab }) => {
          return (
            <span
              onClick={onTabClick.bind(this, tab.tabKey)}
              className={`text-md-medium flex gap-2 hover:text-primary-600 items-center relative cursor-pointer before:absolute before:bottom-[-6px] before:w-full before:h-1 before:rounded-sm before:bg-primary-500  ${activeKey === tab.tabKey
                ? 'text-primary-600 before:content-[""]'
                : 'text-gray-500 before:content-none'
                }`}
              key={tab.tabKey}>
              {activeKey === tab.tabKey ? tab.icon[1] : tab.icon[0]}
              {tab.tab}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default PickMenuSection
