import { NullServisIcon } from 'assets'
import React from 'react'

const NullComponentServis = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-2 max-w-sm mx-auto text-center">
      <NullServisIcon />
      <p className="text-lg-semibold md:text-xl-semibold text-gray-500">
        Sparepart untuk unit anda belum tersedia!
      </p>
      <p className="text-sm-medium md:text-md-medium text-gray-400">
        Silakan hubungi tim BirdMobil untuk keterangan lebih lanjut.
      </p>
    </div>
  )
}

export default NullComponentServis
