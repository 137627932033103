import { Col, Row } from 'antd'
import { EditIcon, TrashIcon } from 'assets'
import CardImage from 'components/ui/card/card-image'
import CardInformation from 'components/ui/card/card-information'
import CardResultInspection from 'components/ui/card/card-result-inspection'
import CardNote from 'components/ui/card/card-note'
import CustomButton from 'components/ui/custom-button'
import React, { useState } from 'react'
import { ModalLayout } from 'layouts'
import BadgeItem from 'components/ui/badge-item'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import CardServiceHistoryPagination from 'components/ui/card/card-service-history-pagination'
import { useMutation, useQuery } from 'react-query'
import {
  getDetailUnitDashboard,
  deleteUnitDashboard,
  getUsersDashboard,
  updateUserUnit
} from 'features/dashboard/service'
import { useInspectionHistoriesById, useServiceHistoriesById } from 'features/dashboard/hooks'
import { CardDocumentNull, CardInspectionNull } from 'features/dashboard'
import CardInformationPengguna from 'components/ui/card/card-information-pengguna'
import { showSuccessMessage } from 'utils/toastMessage'

function DetailUnitPage() {
  const { state, pathname } = useLocation()
  const userData = JSON.parse(localStorage.getItem('user'))
  const accountType = localStorage.getItem('account_type')
  const isUsers = pathname?.includes('users')
  const token = localStorage.getItem('token')
  const isB2B = localStorage.getItem('account_type') === 'company'
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const { push, replace } = useHistory()
  const idUnit = state?.id === undefined ? push('/dashboard') : state?.id
  const [openModal, setOpenModal] = useState(false)
  const [openModalUpdate, setOpenModalUpdate] = useState(false)
  const [unit, setUnit] = useState([])
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const {
    filterService,
    servicePagination,
    serviceHistories,
    handlePaginationService,
    handleFilterService
  } = useServiceHistoriesById(idUnit)
  const {
    filterInspection,
    inspectionPagination,
    inspectionHistories,
    handlePaginationInspection,
    handleFilterInspection
  } = useInspectionHistoriesById(idUnit)

  const informasiUnitData = [
    { label: 'Merek', value: unit?.brand?.name },
    { label: 'Model', value: unit?.model?.name },
    { label: 'Tipe', value: unit?.type?.name },
    { label: 'Transmisi', value: unit?.transmission?.name },
    { label: 'Tahun', value: unit?.production_year },
    { label: 'Plat Nomor', value: unit?.police_number },
    { label: 'Warna', value: unit?.color?.name },
    { label: 'Bahan Bakar', value: unit?.fuel?.name },
    { label: 'Nomor Rangka', value: unit?.frame_number },
    { label: 'Nomor Mesin', value: unit?.engine_number },
    { label: 'Tag', value: unit?.type?.categories?.map((cat) => cat.name).join(',') }
  ]
  const dokumenData = [
    { label: 'STNK', value: unit?.document_stnk_status },
    { label: 'BPKB', value: unit?.document_bpkb_status },
    { label: 'Faktur', value: unit?.document_faktur_status },
    { label: 'KTP Pemilik', value: unit?.document_ktp_status },
    { label: 'Kwitansi', value: unit?.document_kwitansi_status },
    { label: 'KEUR', value: unit?.document_keur_status },
    { label: 'Form A', value: unit?.document_form_status }
  ]

  const { refetch } = useQuery({
    queryKey: 'getDetailUnitDashboard',
    queryFn: () => getDetailUnitDashboard(idUnit),
    onSuccess: (data) => {
      const newPhotosArray = [...data.photos]
      if (data?.thumbnail_filename) {
        const newObjThumbnail = {
          id: 0,
          unit_id: data?.id,
          photo_filename: data?.thumbnail_filename
        }
        newPhotosArray.unshift(newObjThumbnail)
      }
      data.photos = newPhotosArray
      setUnit(data)
      setSelectedUser({
        value: data?.unitOwner?.user_id,
        label: data?.unitOwner?.name
      })
    },
    enabled: !!idUnit,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: 'getUserDashboard',
    queryFn: () => getUsersDashboard(),
    onSuccess: (data) => {
      const newData = data.data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setUsers(newData)
    },
    enabled: isB2B,
    refetchOnWindowFocus: false
  })

  const delUnitDashboard = useMutation({
    mutationFn: () => deleteUnitDashboard(idUnit),
    onSuccess: (datas) => {
      showSuccessMessage(datas?.message)
      replace('/dashboard')
    }
  })
  const updUserUnit = useMutation({
    mutationFn: () => {
      const dataSend = {
        token: token,
        idUnit: state?.id,
        body: {
          user_id: selectedUser?.value
        }
      }
      return updateUserUnit(dataSend)
    },
    onSuccess: () => {
      // showSuccessMessage(datas?.message)
      setOpenModalUpdate(false)
      refetch()
    }
  })

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  function handleConfirmModal() {
    delUnitDashboard.mutate()
  }
  function handleConfirmUser() {
    updUserUnit.mutate()
  }
  function handleNavigation(path, type) {
    if (type === 'inspeksi') {
      push({
        pathname: path,
        state: {
          unit: {
            id: unit?.id,
            unit_name: unit?.unit_name,
            police_number: unit?.police_number,
            thumbnail: unit?.thumbnail_filename,
            unit_ownership: unit?.ownership_status_string
          },
          owner: {
            nama: currentUser?.name || currentUser?.fullname,
            phone: currentUser?.phone_number,
            alamat: currentUser?.address,
            email: currentUser?.email
          }
        }
      })
    } else if (type === 'jual') {
      push({
        pathname: path,
        state: {
          unit: {
            value: unit?.id,
            label: unit?.unit_name,
            unit_name: unit?.unit_name
          }
        }
      })
    } else if (type === 'servis') {
      push({
        pathname: path,
        state: {
          unit: {
            id: unit?.id,
            unit_name: unit?.unit_name,
            police_number: unit?.police_number,
            thumbnail: unit?.thumbnail_filename,
            unit_ownership: unit?.ownership_status_string
          },
          owner: {
            nama: currentUser?.name || currentUser?.fullname,
            phone: currentUser?.phone_number,
            alamat: currentUser?.address,
            email: currentUser?.email
          }
        }
      })
    } else {
      push({ pathname: path, state: { unitOwner: unit?.unitOwner, idUnit: unit?.id } })
    }
  }
  function handleNavigationRiwayat(serviceType, transactionId) {
    push(`/transaction/transaction-detail`, { type: serviceType, id: transactionId })
  }

  return (
    <Row className="gap-6">
      <ModalLayout
        openModal={openModal}
        onCancel={handleCloseModal}
        headerTitle="Konfirmasi Hapus Unit">
        <div className="grid grid-cols-1 gap-4">
          <p className="text-sm-medium text-gray-500">Anda yakin ingin menghapus unit:</p>
          <div className="p-4 flex-col flex rounded-[4px] gap-2 border-gray-200 border">
            {unit?.ownership_status_string === 'Mobil Pribadi' ? (
              <BadgeItem type="plain" className={'max-w-fit'}>
                Mobil Pribadi
              </BadgeItem>
            ) : (
              <BadgeItem type="purple" className={'max-w-fit'}>
                Mobil Perusahaan
              </BadgeItem>
            )}
            <p className="text-md-medium text-gray-900">{unit?.unit_name}</p>
          </div>
          <p className="text-sm-medium text-gray-500 mb-3">
            {'Unit yang Anda hapus tidak dapat dipulihkan kembali.'}
          </p>
        </div>
        <div className="flex justify-end items-center gap-2">
          <CustomButton type="plain" label={'Batal'} onClick={handleCloseModal} />
          <CustomButton label={'Konfirmasi'} onClick={() => handleConfirmModal()} />
        </div>
      </ModalLayout>
      {/* Header Breadcrumb */}

      <Col span={24} className="flex gap-1 items-center text-xl-medium text-gray-900 py-2">
        <NavLink
          className=" stroke-primary-600 text-gray-700 hover:text-primary-500"
          to={'/dashboard'}
          replace>
          Dashboard
        </NavLink>{' '}
        / <span className="text-primary-500">Detail Unit</span>
      </Col>

      {/* Button Container */}
      <Col span={24} className="grid grid-cols-1">
        <div className="flex gap-2 flex-wrap justify-end items-center">
          <CustomButton
            onClick={() => handleNavigation('/servis/servis-mobil', 'servis')}
            label={'Servis'}
            disabled={
              (unit?.company_admin_id !== userData?.id && unit?.company_id !== 0) ||
              unit?.company_admin_id === userData?.id
                ? unit?.is_booked ||
                  unit?.is_in_catalog ||
                  unit?.is_in_service ||
                  unit?.is_inspected
                : unit?.is_booked ||
                  unit?.is_in_catalog ||
                  unit?.is_in_service ||
                  unit?.is_inspected ||
                  !unit?.is_service_paid
            }
          />
          {(isB2B || (!isB2B && unit?.ownership_status_string === 'Mobil Pribadi')) && (
            <>
              <CustomButton
                onClick={() => handleNavigation('/inspeksi/form', 'inspeksi')}
                label={'Inspeksi'}
                disabled={
                  unit?.company_admin_id === userData?.id
                    ? unit?.is_booked ||
                      unit?.is_in_catalog ||
                      unit?.is_in_service ||
                      unit?.is_inspected
                    : unit?.is_booked ||
                      unit?.is_in_catalog ||
                      unit?.is_in_service ||
                      unit?.is_inspected ||
                      !unit?.is_service_paid
                }
              />
              <CustomButton
                onClick={() => handleNavigation('/jual/form', 'jual')}
                label={'Jual'}
                disabled={
                  unit?.company_admin_id === userData?.id
                    ? unit?.is_booked ||
                      unit?.is_in_catalog ||
                      unit?.is_in_service ||
                      unit?.is_inspected
                    : unit?.is_booked ||
                      unit?.is_in_catalog ||
                      unit?.is_in_service ||
                      unit?.is_inspected ||
                      !unit?.is_service_paid
                }
              />
              <CustomButton
                type="plain"
                onClick={() => handleNavigation('/dashboard/edit-unit')}
                label={
                  <div className="flex justify-center items-center gap-1">
                    <EditIcon />
                    <span className="hidden lg:block">Edit</span>
                  </div>
                }
                disabled={
                  unit?.company_admin_id === userData?.id
                    ? unit?.is_booked ||
                      unit?.is_in_catalog ||
                      unit?.is_in_service ||
                      unit?.is_inspected
                    : unit?.is_booked ||
                      unit?.is_in_catalog ||
                      unit?.is_in_service ||
                      unit?.is_inspected ||
                      !unit?.is_service_paid
                }
              />
              <CustomButton
                onClick={handleOpenModal}
                type="danger"
                label={
                  <div className="flex justify-center items-center gap-1">
                    <TrashIcon />
                    <span className="hidden lg:block">Hapus</span>
                  </div>
                }
                disabled={
                  unit?.company_admin_id === userData?.id
                    ? unit?.is_booked ||
                      unit?.is_in_catalog ||
                      unit?.is_in_service ||
                      unit?.is_inspected
                    : unit?.is_booked ||
                      unit?.is_in_catalog ||
                      unit?.is_in_service ||
                      unit?.is_inspected ||
                      !unit?.is_service_paid
                }
              />
            </>
          )}
        </div>
      </Col>
      {/* Reminder */}
      {/* <Col span={24}>
        <div className="grid grid-cols-1">
          <div className="flex items-center justify-between border border-solid border-warning-300 bg-warning-50 px-4 py-3">
            <div className="flex gap-2 items-center">
              <WarningIcon className={'stroke-warning-700'} />
              <p className="text-md-medium text-warning-700">Mobil ini waktunya ganti oli!</p>
            </div>
            <ChevronRightIcon className={'stroke-warning-700'} />
          </div>
        </div>
        <Divider className="m-0" />
      </Col> */}
      {/* Content */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {isB2B && isUsers && (
          <CardInformationPengguna
            headerTitle={`Informasi User`}
            data={{
              name: unit?.unitOwner?.name || '-',
              email: unit?.unitOwner?.email || '-',
              phoneNumber: unit?.unitOwner?.phone_number || '-',
              address: unit?.unitOwner?.address || '-',
              unitName: unit?.unit_name || '-',
              police_number: unit?.police_number || '-'
            }}
            users={users}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            handleConfirmUser={handleConfirmUser}
            openModal={openModalUpdate}
            setOpenModal={setOpenModalUpdate}
          />
        )}
        <div className={`grid grid-cols-1 lg:grid-cols-2 gap-6`}>
          <CardInformation data={informasiUnitData} title="Informasi Unit" />

          {inspectionHistories?.data?.length === 0 ? (
            <CardDocumentNull
              isOnGarage={
                (unit?.company_admin_id !== userData?.id && unit?.company_id !== 0) ||
                unit?.company_admin_id === userData?.id
                  ? unit?.is_booked ||
                    unit?.is_in_catalog ||
                    unit?.is_in_service ||
                    unit?.is_inspected
                  : unit?.is_booked ||
                    unit?.is_in_catalog ||
                    unit?.is_in_service ||
                    unit?.is_inspected ||
                    !unit?.is_service_paid
              }
              isPersonal={
                unit?.company_id == 0 && unit?.user_id == userData?.id && accountType == 'customer'
              }
              isCompanyAdmin={unit?.company_id == userData?.company_id && accountType == 'company'}
              handleNavigation={() => handleNavigation('/inspeksi/form', 'inspeksi')}
            />
          ) : (
            <CardInformation data={dokumenData} title="Dokumen" />
          )}
        </div>
        <CardImage data={unit.photos} />
        {unit?.inspectionResult ? (
          <CardResultInspection
            idUnit={idUnit}
            data={{ inspection_result: unit?.inspectionResult }}
            id={unit?.inspectionResult?.id}
          />
        ) : null}
        {inspectionHistories?.data?.length === 0 &&
          ((!isB2B && unit?.ownership_status_string === 'Mobil Pribadi') ||
            (isB2B && unit?.ownership_status_string !== 'Mobil Pribadi')) && (
            <CardInspectionNull
              buttonDisabled={
                (unit?.company_admin_id !== userData?.id && unit?.company_id !== 0) ||
                unit?.company_admin_id === userData?.id
                  ? unit?.is_booked ||
                    unit?.is_in_catalog ||
                    unit?.is_in_service ||
                    unit?.is_inspected
                  : unit?.is_booked ||
                    unit?.is_in_catalog ||
                    unit?.is_in_service ||
                    unit?.is_inspected ||
                    !unit?.is_service_paid
              }
              handleNavigation={() => handleNavigation('/inspeksi/form', 'inspeksi')}
            />
          )}
        <CardNote data={unit?.description} />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <CardServiceHistoryPagination
              pageSize={filterService?.limit}
              data={serviceHistories?.data}
              currentPage={servicePagination}
              handlePerRowsChange={handleFilterService.bind(this, 'limit')}
              lengthAllData={serviceHistories?.meta?.total}
              totalPerPage={filterService?.limit}
              onChangeNextPage={handlePaginationService}
              onChangePrevPage={handlePaginationService}
              headerTitle={'Riwayat Servis'}
              handleNavigation={handleNavigationRiwayat.bind(this, 'servis')}
            />
          </div>
          <div>
            <CardServiceHistoryPagination
              pageSize={filterInspection?.limit}
              data={inspectionHistories?.data}
              handlePerRowsChange={handleFilterInspection.bind(this, 'limit')}
              currentPage={inspectionPagination}
              lengthAllData={inspectionHistories?.meta?.total}
              totalPerPage={filterInspection?.limit}
              onChangeNextPage={handlePaginationInspection}
              onChangePrevPage={handlePaginationInspection}
              headerTitle={'Riwayat Inspeksi'}
              handleNavigation={handleNavigationRiwayat.bind(this, 'inspeksi')}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default DetailUnitPage
