import { Divider, Spin } from 'antd'
import CustomButton from 'components/ui/custom-button'
import { imageCreditCards } from 'data/image-credit-card'
import { ModalLayout } from 'layouts'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'

const ModalServisConfirmationPayment = ({
  confirmationCheckoutModal,
  handleConfirmationPayment,
  closeConfirmationCheckoutModal,
  dataCar,
  selectedPayment,
  selectedCreditCard,
  checkoutIsLoading,
  serviceType
}) => {
  return (
    <ModalLayout
      openModal={confirmationCheckoutModal}
      headerTitle={`Konfirmasi Pembayaran`}
      className="w-full max-w-sm"
      onCancel={closeConfirmationCheckoutModal}>
      <div className="grid grid-cols-1 gap-4">
        <p className="text-sm-medium sm:text-md-medium text-gray-500">
          Pembayaran {serviceType} mobil untuk unit:
        </p>
        <div className="border border-solid border-gray-200 rounded-[4px] p-4">
          <p className="text-sm-medium sm:text-md-medium text-gray-900">
            {dataCar?.unit_name ? dataCar?.unit_name : dataCar?.name}
          </p>
        </div>
        <Divider className="m-0" />
        {/* Metode Pembayaran */}
        <div className="grid grid-cols-1 gap-4">
          <p className="text-sm-medium sm:text-md-medium text-gray-900">Metode Pembayaran</p>
          {selectedPayment?.id === 'card' ? (
            <div className="p-4 border border-solid rounded-[4px] cursor-pointer flex justify-between items-center border-gray-200 bg-gray-50">
              <div>
                <p className="text-xs-regular text-gray-500">
                  {selectedCreditCard?.cardholder_name}
                </p>
                <p className="text-xs-medium sm:text-lg-medium text-gray-900">
                  {selectedCreditCard?.masked_number.match(/.{1,4}/g).join(' ')}
                </p>
              </div>
              <img
                src={imageCreditCards[selectedCreditCard?.network]?.image}
                alt="Logo Credit Card"
              />
            </div>
          ) : (
            <div className=" p-4 flex justify-start gap-4 items-center bg-gray-50 rounded-[4px]">
              <img src={selectedPayment?.image} alt="bankLogo" className="w-14 object-contain" />
              <p className="text-xs-medium md:text-md-medium text-gray-700">
                {selectedPayment?.title}
              </p>
            </div>
          )}
        </div>
        <Divider className="m-0" />
        {/* Rincian Pembayaran */}
        <div className="grid grid-cols-1 gap-4">
          <p className="text-md-medium text-gray-900">Rincian Pembayaran</p>
          {serviceType == 'servis' && (
            <div className=" flex justify-between items-center gap-4">
              <p className="text-xs-regular md:text-md-regular text-gray-500">Item Total</p>
              <p className="text-xs-medium md:text-md-medium text-gray-700">
                Rp. {onRupiah(dataCar?.item_total)}
              </p>
            </div>
          )}
          {dataCar?.invoice_checkout?.length > 0
            ? dataCar?.invoice_checkout?.map((item, index) => (
                <div
                  className="flex justify-between text-xs-regular md:text-md-regular text-gray-600"
                  key={index}>
                  <p>{item.label}</p>
                  <p className={item.highlight ? 'text-primary-400' : ''}>{`${item.addOn} ${
                    item.value === undefined ? '-' : item.value
                  }`}</p>
                </div>
              ))
            : null}
          {/* Total Tagihan */}
          <Divider className="m-0 text-gray-300" />
          <div className="p-4 flex justify-between items-center bg-gray-50 rounded-[4px]">
            <p className="text-xs-regular md:text-md-regular text-gray-600">Total Tagihan</p>
            <p className="text-xs-medium md:text-md-medium text-gray-700">
              Rp {onRupiah(dataCar?.price_total)}
            </p>
          </div>
        </div>
        {/* Button Container */}
        <div className="flex justify-end items-center gap-2">
          <Spin spinning={checkoutIsLoading}>
            <CustomButton onClick={closeConfirmationCheckoutModal} type="plain" label={'Batal'} />
          </Spin>
          <Spin spinning={checkoutIsLoading}>
            <CustomButton onClick={handleConfirmationPayment} label={'Konfirmasi'} />
          </Spin>
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalServisConfirmationPayment
