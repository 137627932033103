/* eslint-disable no-undef */
import API from 'services'

const getWishlist = async ({ sortBy, limit, offset }) => {
  console.log(sortBy)
  let urlParam = `limit=${limit}&offset=${offset}&sort=${sortBy}`

  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/wishlists?${urlParam}`
  )
  return response
}
const getWishlistIds = async () => {
  const { data: response } = await API.get(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/wishlists/catalogs/ids`
  )
  return response.data
}
const postWishlistId = async (id) => {
  const { data: response } = await API.post(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/wishlists`,
    {
      catalog_id: id
    }
  )
  return response
}
const deleteWishlistId = async (id) => {
  const { data: response } = await API.delete(
    `${process.env.REACT_APP_API_BASE_URL}/jual-beli-service/website/wishlists/${id}`
  )
  return response
}
export { getWishlist, getWishlistIds, deleteWishlistId, postWishlistId }
