import { Collapse } from 'antd'
import { BadgeIconDanger, BadgeIconSuccess, BadgeIconWarning } from 'assets/icons/badge-icons'
import React from 'react'
import BadgeItem from '../badge-item'
import CardSectionHeader from '../card-section-header'
import { RenderBadgeResultInspection } from '../render-badge-result-inspection'

function CardInspectionDetailList({ data, title, handleOpenModalImage }) {
  const urlImage = process.env.REACT_APP_IMAGE_BASE_URL

  const RenderBadgeResult = ({ name }) => {
    if (name === 'OK') {
      return (
        <BadgeItem type="success">
          <div className="flex items-center space-x-2">
            <BadgeIconSuccess className="h-3 w-3" />
            <p>Lulus</p>
          </div>
        </BadgeItem>
      )
    } else if (name === 'Repair') {
      return (
        <BadgeItem type="warning">
          <div className="flex items-center space-x-2">
            <BadgeIconWarning className="h-3 w-3" />
            <p>Diperbaiki</p>
          </div>
        </BadgeItem>
      )
    } else if (name === 'Replace') {
      return (
        <BadgeItem type="primary">
          <div className="flex items-center space-x-2">
            <BadgeIconDanger className="h-3 w-3" />
            <p>Tidak Lulus</p>
          </div>
        </BadgeItem>
      )
    } else {
      return (
        <BadgeItem type="plain">
          <div className="flex items-center space-x-2">
            <BadgeIconDanger className="h-3 w-3" />
            <p>{name}x</p>
          </div>
        </BadgeItem>
      )
    }
  }

  return (
    <CardSectionHeader headerTitle={title}>
      <div className="grid grid-cols-1 gap-6">
        <Collapse bordered={false} expandIconPosition="end">
          {data?.map((item, index) => (
            <Collapse.Panel
              header={`${String.fromCharCode(index + 65)}. ${item?.name}`}
              key={index}
              className="text-lg-medium text-gray-700 bg-white">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                {item?.inspectionPartResults?.map((el, i) => (
                  <div className="md:pr-5" key={`child-${i}`}>
                    <div className="flex justify-between items-center gap-5">
                      <p className="text-sm-regular text-gray-600">{el?.name}</p>
                      <div className="flex-shrink-0">
                        <RenderBadgeResultInspection name={el?.inspectionDescriptionResult?.name} />
                      </div>
                    </div>
                    {/* <div>
                      {el?.details?.map((detail, indx) => (
                        <p
                          key={indx}
                          className="text-sm-regular text-gray-400">{`${detail?.label}: ${detail?.value}`}</p>
                      ))}
                    </div>
                     */}
                    {el?.inspectionDescriptionResult !== null &&
                      el?.inspectionDescriptionResult?.photo_filename !== '' && (
                        <div
                          className="overflow-hidden max-w-fit rounded-xl border border-solid mt-4"
                          onClick={() =>
                            handleOpenModalImage(
                              urlImage.concat(el?.inspectionDescriptionResult?.photo_filename)
                            )
                          }>
                          <img
                            src={urlImage.concat(el?.inspectionDescriptionResult?.photo_filename)}
                            alt="detail-value "
                            className="h-[64px] w-[84px] object-contain"
                          />
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </CardSectionHeader>
  )
}

export default CardInspectionDetailList
