import { Modal } from 'antd'
import { useEffect } from 'react'

function ModalLayout({ headerTitle, children, onCancel, openModal, className, ...params }) {
  useEffect(() => {
    document.body.style.overflow = openModal ? 'hidden' : 'unset'
    return () => (document.body.style.overflow = 'unset')
  }, [openModal])
  return (
    <Modal
      title={<p className="text-xl-medium text-gray-900">{headerTitle}</p>}
      open={openModal}
      wrapClassName="w-full"
      footer={null}
      width=""
      {...params}
      className={`overflow-y-hidden ${className || `max-w-md`}`}
      zIndex={999}
      onCancel={onCancel}
      maskClosable={false}
      centered>
      {children}
    </Modal>
  )
}

export default ModalLayout
