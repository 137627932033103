const HamburgerIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="34"
      height="24"
      viewBox="0 0 34 24"
      stroke="#2E90FA"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0833 2H31.75M10.0833 12H31.75M10.0833 22H31.75M1.75 2H1.76667M1.75 12H1.76667M1.75 22H1.76667"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HamburgerIcon
