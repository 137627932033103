import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { getAdminFee, getServiceCategories, postServiceV2 } from '../service'
import { useDebounce } from 'hooks'
import { showErrorMessage, showSuccessMessage, showWarningMessage } from 'utils/toastMessage'
import { debounce } from 'lodash'

const initialFilterPagination = {
  type_id: null,
  limit: 5,
  search: '',
  selected_general_service_ids: [],
  without_general_service_ids: [],
  without_packet_ids: []
}
const useServicePackets = (selectedTypeId, resetVoucherState) => {
  const [servicePackets, setServicePackets] = useState([])
  const [servicesGenerals, setServiceGenerals] = useState([])
  const [selectedGeneralServiceIds, setSelectedGeneralServiceIds] = useState({})
  const [selectedPacketIds, setSelectedPacketIds] = useState({})
  const [adminFee, setAdminFee] = useState(null)
  const [services, setServices] = useState([])
  const [serviceCategories, setServiceCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [filterServicePagination, setFilterServicePagination] = useState({
    ...initialFilterPagination
  })
  const [isAddServisBtnDisabled, setIsAddServisBtnDisabled] = useState(false)

  useQuery({
    queryKey: 'getServiceCategories',
    queryFn: () => getServiceCategories(),
    onSuccess: (data) => {
      setServiceCategories(data)
      setSelectedCategory(data?.[0])
    },
    refetchOnWindowFocus: false
  })

  const postServicePagination = useMutation({
    mutationFn: (dataSend) => postServiceV2(dataSend, selectedCategory?.id),
    onSuccess: (data) => {
      if (
        data?.general_services?.length === 0 &&
        data?.packets?.length === 0 &&
        (filterServicePagination?.without_general_service_ids?.length !== 0 ||
          filterServicePagination?.without_packet_ids?.length !== 0)
      ) {
        setIsAddServisBtnDisabled(true)
        return showSuccessMessage('Semua Data Servis Sudah Ditampilkan')
      }

      const mergePacketGeneral = []
      const excludePacketsIds = data?.packets?.map((packet) => {
        let lowestPriceByRecSparepart = 0
        packet?.general_services?.map((general_service) => {
          if (general_service?.spareparts?.length > 0) {
            const newSparepartArray = sortSparepartRecommendation(general_service?.spareparts)
            general_service.spareparts = newSparepartArray

            // Check if General Service Has Recommendation Sparepart Or Not
            lowestPriceByRecSparepart += getLowestPriceRecSparepart(newSparepartArray)
          }
        })
        packet.price_total = lowestPriceByRecSparepart
        mergePacketGeneral.push({ type: 'PACKET', ...packet })
        return packet.id
      })
      const excludeGeneralIds = data?.general_services?.map((general_service) => {
        let lowestPriceByRecSparepart = 0
        if (general_service?.spareparts?.length > 0) {
          const newSparepartArray = sortSparepartRecommendation(general_service?.spareparts)
          general_service.spareparts = newSparepartArray
          // Check if General Service Has Recommendation Sparepart Or Not
          lowestPriceByRecSparepart += getLowestPriceRecSparepart(newSparepartArray)
        }
        general_service.price_total = lowestPriceByRecSparepart
        mergePacketGeneral.push({ type: 'GENERAL', ...general_service })
        return general_service.id
      })

      setServices((prev) => [...prev, ...mergePacketGeneral])
      setFilterServicePagination((prev) => ({
        ...prev,
        without_general_service_ids: [...prev.without_general_service_ids, ...excludeGeneralIds],
        without_packet_ids: [...prev.without_packet_ids, ...excludePacketsIds]
      }))
    },
    onError: (e) => {
      showErrorMessage(e)
    },
    refetchOnWindowFocus: false
  })

  useQuery({
    queryKey: 'getAdminFee',
    queryFn: () => getAdminFee(),
    onSuccess: (data) => setAdminFee(data),
    refetchOnWindowFocus: false
  })
  useDebounce(
    () => {
      if (!selectedTypeId || !selectedCategory?.id) {
        return
      }
      setServices([])
      setIsAddServisBtnDisabled(false)
      setFilterServicePagination({
        type_id: selectedTypeId,
        limit: 5,
        search: keyword,
        selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
          parseInt(id)
        ),
        without_general_service_ids: [],
        without_packet_ids: []
      })
      postServicePagination.mutate({
        type_id: selectedTypeId,
        limit: 5,
        search: keyword,
        selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
          parseInt(id)
        ),
        without_general_service_ids: [],
        without_packet_ids: []
      })
    },
    [selectedCategory?.id, selectedTypeId],
    300
  )

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, selectedTypeId) => {
      setServices([])
      setIsAddServisBtnDisabled(false)
      postServicePagination.mutate({
        type_id: selectedTypeId,
        limit: 5,
        search: inputValue,
        selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
          parseInt(id)
        ),
        without_general_service_ids: [],
        without_packet_ids: []
      })
    }, 1000),
    []
  )

  function sortSparepartRecommendation(spareparts) {
    const newSpareparts = spareparts.sort(
      (a, b) => Number(b.is_recommended) - Number(a.is_recommended)
    )
    return newSpareparts
  }

  function getLowestPriceRecSparepart(newSparepartArray) {
    if (newSparepartArray[0].is_recommended) {
      return newSparepartArray[0].price_total
    } else {
      let lowestPrice = Math.min(...newSparepartArray.map((sparepart) => sparepart.price_total))
      return lowestPrice
    }
  }

  function handleSelectedCategory(id) {
    if (id === selectedCategory) {
      return
    }
    setServices([])
    setSelectedCategory(id)
    resetKeyword()
    setFilterServicePagination({
      ...filterServicePagination,
      search: '',
      without_general_service_ids: [],
      without_packet_ids: []
    })
  }

  function handleTampilLebihBanyak() {
    // console.log('postServicePagination buttonTampilLebihBanyak')
    postServicePagination.mutate(filterServicePagination)
  }

  function resetKeyword() {
    setKeyword('')
    setFilterServicePagination({
      ...filterServicePagination,
      search: '',
      selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
        parseInt(id)
      ),
      without_general_service_ids: [],
      without_packet_ids: []
    })
  }

  function changeKeywordHandler(e) {
    setKeyword(e.target.value)
    debouncedChangeHandler(e.target.value, selectedTypeId)
    setFilterServicePagination({
      ...filterServicePagination,
      search: e.target.value,
      selected_general_service_ids: Object.keys(selectedGeneralServiceIds)?.map((id) =>
        parseInt(id)
      ),
      without_general_service_ids: [],
      without_packet_ids: []
    })
  }

  function deleteAllServices() {
    setServicePackets([])
    setServiceGenerals([])
    setSelectedGeneralServiceIds({})
    setSelectedPacketIds({})
    setServices([])
    setFilterServicePagination((prev) => ({
      ...initialFilterPagination,
      type_id: prev.type_id
    }))
  }
  function handleGeneralService(serviceId, generalService) {
    const newObj = {
      service_id: serviceId,
      total_price:
        generalService.sparepart.price * generalService.sparepart.quantity +
        generalService.totalBiayaLayanan,
      ...generalService
    }
    if (!generalService?.is_free) {
      setSelectedGeneralServiceIds({
        ...selectedGeneralServiceIds,
        [generalService.general_service.id]: true
      })
    }
    const newFilterServicePagination = {
      ...filterServicePagination,
      type_id: selectedTypeId,
      selected_general_service_ids: [
        ...new Set([
          ...filterServicePagination.selected_general_service_ids,
          generalService.general_service.id
        ])
      ]
    }
    setFilterServicePagination(() => ({
      ...newFilterServicePagination
    }))
    setServiceGenerals([...servicesGenerals, newObj])
  }
  function handlePacketService(packetId, serviceId, generalServices, packetName, totalDuration) {
    const newObj = {
      service_id: serviceId,
      packet_id: packetId,
      packet_name: packetName,
      general_services: generalServices,
      totalDuration: totalDuration
    }
    const serviceIds = {}
    generalServices.map((service) =>
      service.general_service.is_free === true
        ? false
        : (serviceIds[service.general_service.id] = true)
    )
    const generalServiceIds = Object.keys(serviceIds).map((id) => parseInt(id))
    const newFilterServicePagination = {
      ...filterServicePagination,
      type_id: selectedTypeId,
      selected_general_service_ids: [
        ...new Set([...filterServicePagination.selected_general_service_ids, ...generalServiceIds])
      ]
    }
    // postServicePagination.mutate(newFilterServicePagination)
    setFilterServicePagination(() => ({
      ...newFilterServicePagination
    }))
    setSelectedGeneralServiceIds({ ...selectedGeneralServiceIds, ...serviceIds })
    setSelectedPacketIds({ ...selectedPacketIds, [packetId]: true })
    setServicePackets([...servicePackets, newObj])
  }
  function removeCartItem(typeService, serviceId) {
    let selectedGeneralIds = { ...selectedGeneralServiceIds }
    if (typeService === 'packetService') {
      let generalServiceIds = []
      // DELETE ID FROM ARRAY & OBJECT IDS
      servicePackets
        .find((packet) => packet.packet_id === serviceId)
        .general_services.map((service) => generalServiceIds.push(service.general_service.id))
      generalServiceIds.map((id) => delete selectedGeneralIds[id])
      const newServicePackets = servicePackets.filter((packet) => packet.packet_id !== serviceId)
      const newSelectedPacketIds = { ...selectedPacketIds }
      delete newSelectedPacketIds[serviceId]
      setSelectedPacketIds(newSelectedPacketIds)
      setServicePackets(newServicePackets)
    } else if (typeService === 'generalService') {
      let newServiceGenerals = servicesGenerals.filter(
        (service) => service.general_service.id !== serviceId
      )
      delete selectedGeneralIds[serviceId]
      setServiceGenerals(newServiceGenerals)
      setSelectedGeneralServiceIds({ ...selectedGeneralIds })
    }

    if (Object.keys(selectedGeneralIds).length === 0) {
      resetVoucherState()
    }

    const newFilterServicePagination = {
      ...filterServicePagination,
      type_id: selectedTypeId,
      selected_general_service_ids: Object.keys(selectedGeneralIds)?.map((id) => parseInt(id)),
      without_general_service_ids: [],
      without_packet_ids: []
    }
    setServices([])
    postServicePagination.mutate(newFilterServicePagination)
    setFilterServicePagination(() => ({
      ...newFilterServicePagination
    }))
    setSelectedGeneralServiceIds({ ...selectedGeneralIds })
  }
  function handleValidationPacketsService() {
    if (servicePackets.length === 0 && servicesGenerals.length == 0) {
      return { status: false, errorMessage: 'Mohon pilih salah satu servis terlebih dahulu' }
    }
    return { status: true, errorMessage: '' }
  }
  return {
    removeCartItem,
    handlePacketService,
    handleGeneralService,
    deleteAllServices,
    adminFee,
    services,
    servicePackets,
    servicesGenerals,
    selectedGeneralServiceIds,
    selectedPacketIds,
    handleValidationPacketsService,
    changeKeywordHandler,
    resetKeyword,
    keyword,
    serviceCategories,
    handleSelectedCategory,
    selectedCategory,
    handleTampilLebihBanyak,
    servicesIsFetching: postServicePagination.isLoading,
    isAddServisBtnDisabled
  }
}

export default useServicePackets
