import API from 'services';
const baseUrl = process.env.REACT_APP_API_BASE_URL
const sendOtpForget = async (data) => {
  const { data: response } = await API.post(`${baseUrl}/user-service/auth/otp/validate`, data);
  return response.data;
};

const sendNewPassword = async (data) => {
  const { data: response } = await API.post(`${baseUrl}/user-service/auth/submit-new-password`, data);
  return response.data;
};

const requestOtpForget = async (param) => {
  const { data: response } = await API.get(
    `${baseUrl}/user-service/auth/otp/send`,
    {
      params: param
    });
  return response.data;
};

export {
  requestOtpForget,
  sendNewPassword,
  sendOtpForget,
}