import { ChevronRightIcon } from 'assets'
import { paymentList } from 'features/beli-mobil/data/paymentList'
import { ModalLayout } from 'layouts'
import React from 'react'

const ModalSelectPaymentMethod = ({ closeModal, modalOpen, handlePayment }) => {
  return (
    <ModalLayout
      openModal={modalOpen}
      headerTitle={`Pilih Pembayaran`}
      className="w-full max-w-md"
      onCancel={() => closeModal()}>
      <div className="grid grid-cols-1 gap-8 overflow-y-scroll h-full max-h-96 scroll">
        {paymentList.map(({ id, groupTitle, options }) => (
          <div key={id} className="grid grid-cols-1">
            {groupTitle ? (
              <p className="py-4 text-xl-medium text-gray-700 border-b border-gray-200">
                {groupTitle}
              </p>
            ) : null}
            <div className="grid grid-cols-1">
              {options.map(({ id, image, title }) => (
                <div
                  onClick={handlePayment.bind(this, id)}
                  key={id}
                  className=" cursor-pointer hover:bg-gray-50 flex py-4 border-b justify-between px-2 items-center border-gray-200">
                  <div className="flex gap-4 items-center">
                    <img src={image} className="w-14 object-contain" alt="bankImage" />
                    <p className="text-md-medium text-gray-700">{title}</p>
                  </div>
                  <div>
                    <ChevronRightIcon className={'stroke-gray-700'} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </ModalLayout>
  )
}

export default ModalSelectPaymentMethod
