import App from './App'
import './index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import GA4React, { useGA4React } from "ga-4-react";

Sentry.init({
  dsn: "https://4b776cc6e8fa44c9bc0d6ffda7b4b7c9@sentry-relay.echoteam.tech/49",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const root = ReactDOM.createRoot(document.getElementById('root'))

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  try {
    setTimeout(_ => {
      const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ga4react.initialize().catch(err => console.error(err));
    }, 40000);
  } catch (err) {
    console.error(err);
  }
  // console.log('env ada')
}

root.render(<App />)
