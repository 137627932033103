import { Col, Divider, Row, Spin, Steps } from 'antd'
import CardInfoTestDrive from 'components/ui/card/card-info-test-drive'
import CustomButton from 'components/ui/custom-button'
import MobileStep from 'components/ui/mobile-step'
import ModalAddCreditCard from 'components/ui/modal/modal-add-credit-card'
import ModalCreditCards from 'components/ui/modal/modal-credit-cards'
import { imageCreditCards } from 'data/image-credit-card'
import { CheckoutContent, FormBookingContent } from 'features/beli-mobil'
import { CardRentangProsesBookingMobilBekas } from 'features/beli-mobil/components/card'
import { useBookingCatalog } from 'features/beli-mobil/hooks'
import { useCreditCard, usePayment } from 'hooks'
import { ModalLayout } from 'layouts'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { onRupiah } from 'utils/formatCurrency'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage } from 'utils/toastMessage'
const items = [
  {
    key: 0,
    title: 'Data Booking'
  },
  { key: 1, title: 'Checkout' }
]
const BookingMobilPage = () => {
  const { state } = useLocation()
  const { replace } = useHistory()
  // getIdCatalog
  const idCatalog =
    state?.id === undefined
      ? state?.isTaxi === undefined
        ? replace('/beli/mobil-bekas')
        : replace('/beli/ex-taxi')
      : state?.id
  const isTaxi = state?.isTaxi ? true : false
  const colorId = state?.color_id ? state?.color_id : undefined

  const [currentContent, setCurrentContent] = useState(0)
  const {
    creditCards,
    newCreditCard,
    selectedCreditCard,
    modalCardList,
    modalAddCard,
    isAddCardLoading,
    errorsAddCard,
    handleChangeCreditCard,
    submitAddCard,
    openModalCardList,
    closeModalCardList,
    openModalAddCard,
    closeModalAddCard,
    handleSelectedCreditCard
  } = useCreditCard(true)
  const { selectedPayment, handleSelectedPayment, getPaymentObject, handleCreditCardToken } =
    usePayment()
  const {
    isLoading,
    taxPrice,
    totalPrice,
    bookingPrice,
    dataCar,
    dataUser,
    errors,
    modalOpen,
    provinces,
    cities,
    subdistricts,
    handleChange,
    handleChangeLocation,
    setModalOpen,
    submitBookingMobil,
    bookingProcessDate
  } = useBookingCatalog(
    replace,
    selectedPayment,
    idCatalog,
    isTaxi,
    getPaymentObject,
    state,
    colorId,
    selectedCreditCard,
    creditCards
  )

  function handleSelectedCard(card) {
    handleCreditCardToken(card.id)
    handleSelectedCreditCard(card)
    closeModalCardList()
    setModalOpen(true)
  }

  const handleNext = () => {
    // Re-validation value
    let valid = { status: true, errorMessage: '' }
    if (currentContent === 0) {
      for (const [key, value] of Object.entries(errors)) {
        if (
          dataUser[key] == '' ||
          (typeof dataUser[key] === 'object' && dataUser[key].value == null)
        ) {
          valid.status = false
          valid.errorMessage = `Mohon isi ${nameToString[key]}`
          break
        }
      }
    }
    valid.status
      ? setCurrentContent((prevValue) => (prevValue === items.length ? prevValue : prevValue + 1))
      : showErrorMessage(valid.errorMessage)
  }
  const handlePrev = () => {
    setCurrentContent((prevValue) => (prevValue === 0 ? prevValue : prevValue - 1))
  }
  const handleCheckout = () => {
    return selectedPayment !== null
      ? selectedPayment?.id === 'card'
        ? openModalCardList()
        : setModalOpen(true)
      : showErrorMessage('Silahkan pilih metode pembayaran terlebih dahulu')
  }
  const handleBackNavigation = () => {
    isTaxi
      ? replace({
          pathname: `/beli/ex-taxi/detail/${idCatalog}`
        })
      : replace({
          pathname: `/beli/mobil-bekas/detail/${idCatalog}`
        })
  }

  return (
    <Row className="py-10 gap-6 ">
      {/* Header Title */}
      <div className="text-center w-full display-sm-semibold text-gray-900">Booking Mobil</div>
      {/* Steps */}
      <div className="md:grid hidden md:grid-cols-1 w-1/4 mx-auto ">
        <Steps labelPlacement="vertical" current={currentContent} items={items} />
      </div>
      <div className="md:hidden mx-auto ">
        <MobileStep current={currentContent} items={items} />
      </div>
      <Col span={20} className="mx-auto ">
        <div
          className={`grid grid-cols-1 gap-6 ${
            bookingProcessDate ? 'lg:grid-cols-[1fr_389px]' : ''
          }`}>
          {/* Left Content */}
          <div className="flex flex-col gap-6">
            {currentContent === 0 ? (
              <FormBookingContent
                is_create_your_own_car={state?.is_create_your_own_car}
                handleChange={handleChange}
                handleChangeLocation={handleChangeLocation}
                provinces={provinces}
                cities={cities}
                subdistricts={subdistricts}
                dataCar={dataCar}
                dataUser={dataUser}
                errors={errors}
                isTaxi={isTaxi}
                bookingPrice={bookingPrice?.bookingPrice}
              />
            ) : (
              <CheckoutContent
                dataCar={dataCar}
                dataUser={dataUser}
                selectedPayment={selectedPayment}
                handleSelectedPayment={handleSelectedPayment}
                price={bookingPrice}
                taxPrice={taxPrice.current}
                totalPrice={totalPrice.current}
                isTaxi={isTaxi}
                is_create_your_own_car={state?.is_create_your_own_car}
              />
            )}

            {/* Temporary Button */}
            <div className="flex gap-3 justify-end items-center">
              {currentContent > 0 ? (
                <CustomButton onClick={handlePrev} type="secondary" label={'Sebelumnya'} />
              ) : (
                <CustomButton onClick={handleBackNavigation} type="plain" label={'Batal'} />
              )}
              {currentContent < items.length - 1 ? (
                <CustomButton onClick={handleNext} label={'Selanjutnya'} />
              ) : (
                <CustomButton onClick={handleCheckout} label={'Checkout'} />
              )}
            </div>
          </div>
          {/* Right Content */}
          {/* Information Card */}
          {bookingProcessDate ? (
            <div className="flex flex-col gap-2">
              {bookingProcessDate !== null ? (
                <CardRentangProsesBookingMobilBekas data={bookingProcessDate} />
              ) : null}
              {/* <CardInfoTestDrive /> */}
            </div>
          ) : null}
        </div>
      </Col>
      <ModalCreditCards
        selectedCreditCard={selectedCreditCard}
        openModal={modalCardList}
        onCancel={closeModalCardList}
        creditCards={creditCards}
        openModalAddCard={openModalAddCard}
        handleSelectedCard={handleSelectedCard}
      />
      <ModalAddCreditCard
        errors={errorsAddCard}
        creditCard={newCreditCard}
        openModal={modalAddCard}
        submitAddCard={submitAddCard}
        isAddCardLoading={isAddCardLoading}
        onCancel={closeModalAddCard}
        handleChange={handleChangeCreditCard}
      />
      {/* Konfirmasi Booking Mobil */}
      <ModalLayout
        openModal={modalOpen}
        headerTitle={`Konfirmasi Booking Mobil`}
        className="w-full max-w-sm"
        onCancel={() => setModalOpen(false)}>
        <div className="grid grid-cols-1 gap-4">
          <p className="text-md-medium text-gray-500">Booking mobil untuk unit:</p>
          <div className="border border-solid border-gray-200 rounded-[4px] p-4">
            <p className="text-md-medium text-gray-900">
              {dataCar?.unit_name ? dataCar?.unit_name : dataCar?.name}
            </p>
          </div>
          <Divider className="m-0" />
          {/* Metode Pembayaran */}
          <div className="grid grid-cols-1 gap-4">
            <p className="text-md-medium text-gray-900">Metode Pembayaran</p>
            {selectedPayment?.id === 'card' ? (
              <div className="p-4 border border-solid rounded-[4px] cursor-pointer flex justify-between items-center border-gray-200 bg-gray-50">
                <div>
                  <p className="text-xs-regular text-gray-500">
                    {selectedCreditCard?.cardholder_name}
                  </p>
                  <p className="text-lg-medium text-gray-900">
                    {selectedCreditCard?.masked_number.match(/.{1,4}/g).join(' ')}
                  </p>
                </div>
                <img
                  src={imageCreditCards[selectedCreditCard?.network]?.image}
                  alt="Logo Credit Card"
                />
              </div>
            ) : (
              <div className=" p-4 flex justify-start gap-4 items-center bg-gray-50 rounded-[4px]">
                <img src={selectedPayment?.image} alt="bankLogo" className="w-14 object-contain" />
                <p className="text-md-medium text-gray-700">{selectedPayment?.title}</p>
              </div>
            )}
          </div>
          <Divider className="m-0" />
          {/* Rincian Pembayaran */}
          <div className="grid grid-cols-1 gap-4">
            <p className="text-md-medium text-gray-900">Rincian Pembayaran</p>
            {/* List Rincian Pembayaran */}
            <div className=" flex justify-between items-center gap-4">
              <p className="text-md-regular text-gray-500">Booking Mobil</p>
              <p className="text-md-medium text-gray-700">
                Rp{' '}
                {bookingPrice.bookingPrice !== undefined
                  ? onRupiah(bookingPrice.bookingPrice)
                  : '-'}
              </p>
            </div>
            {bookingPrice?.admin_fee !== 0 && (
              <div className=" flex justify-between items-center gap-4">
                <p className="text-md-regular text-gray-500">Biaya Admin</p>
                <p className="text-md-medium text-gray-700">
                  Rp {onRupiah(bookingPrice.admin_fee)}
                </p>
              </div>
            )}
            <div className=" flex justify-between items-center gap-4">
              <p className="text-md-regular text-gray-500">Pajak {bookingPrice.tax}%</p>
              <p className="text-md-medium text-gray-700">Rp {onRupiah(taxPrice.current)}</p>
            </div>
            {/* Total Tagihan */}
            <Divider className="m-0 text-gray-300" />
            <div className="p-4 border-gray-200 border border-solid flex justify-between items-center bg-gray-50 rounded-[4px]">
              <p className="text-sm-regular text-gray-600">Total Tagihan</p>
              <p className="text-sm-medium text-gray-700">
                Rp {totalPrice.current !== undefined ? onRupiah(totalPrice.current) : '-'}
              </p>
            </div>
          </div>
          {/* Button Container */}
          <div className="flex justify-end items-center gap-2">
            <Spin spinning={isLoading}>
              <CustomButton onClick={() => setModalOpen(false)} type="plain" label={'Batal'} />
            </Spin>
            <Spin spinning={isLoading}>
              <CustomButton onClick={() => submitBookingMobil()} label={'Konfirmasi'} />
            </Spin>
          </div>
        </div>
      </ModalLayout>
    </Row>
  )
}

export default BookingMobilPage
