import { Col, Input, Row, Select } from "antd"
import { MagnifyingIcon } from "assets"
import PlusIcon from "assets/icons/plus"
import BadgeItem from "components/ui/badge-item"
import CustomButton from "components/ui/custom-button"
import CustomTable from "components/ui/custom-table"
import { transactionData } from "data/transaction-data"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import AssignUnitModal from "../modals/assign-unit-modal"
import moment from 'moment'

const gradeOptions = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
]
const statusOptions = [
  { value: 'Di garasi', label: 'Di Garasi' },
  { value: 'Dijual', label: 'Dijual' },
  { value: 'Sedang diservis', label: 'Sedang Diservis' },
  { value: 'Sedang diinspeksi', label: 'Sedang Diinspeksi' },
]
function DetailUnitContent({
  units,
  userUnits,
  openModal,
  setOpenModal,
  currentPage,
  totalPerPage,
  lengthAllData,
  defaultPageSize,
  handleChangeFilterIncludeOffset,
  onChangeNextPage,
  onChangePrevPage,
  selectedUnit,
  handleSelectedUnit,
  handleAssignUnit,
}) {
  const { push } = useHistory()
  const columns = [
    {
      name: 'Nama Unit',
      selector: (row) => row.unitName,
      cell: (row) => (
        <div className="flex flex-col">
          <p className="text-sm-medium">{row.unit_name}</p>
          <p className="text-xs-regular mt-1">{row.police_number}</p>
        </div>
      ),
      sortable: true,
      width: '40%'
    },
    // {
    //   name: 'Grade',
    //   selector: (row) => row.grade,
    //   cell: (row) => row?.grade || '-',
    //   sortable: true,
    //   // width: '10%',
    //   center: true,
    // },
    {
      name: 'Status',
      wrap: true,
      center: true,
      selector: (row) => row.status,
      cell: (row) => (
        <div>
          {row.status === 'Sedang diservis' || row.status === 'Dijual' ? (
            <BadgeItem type="warning" className={'text-center'}>
              {row.status}
            </BadgeItem>
          ) : row.status === 'Sedang diinspeksi' ? (
            <BadgeItem type="bluelight">{row.status}</BadgeItem>
          ) : (
            <BadgeItem type="success">{row.status}</BadgeItem>
          )}
        </div>
      ),
      width: '20%',
      sortable: true
    },
    {
      name: 'Riwayat Servis',
      selector: (row) => row.latest_service_date,
      cell: (row) => row.latest_service_date === '-' ? '-' : moment(row.latest_service_date).format('DD MMM YYYY'),
      sortable: true,
      width: '15%',
      center: true
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <div onClick={() => push({pathname: '/users/detail/unit', state: { id: row?.id }})} className="cursor-pointer text-sm-medium text-primary-600">Lihat Detail</div>
      ),
      sortable: true,
      right: true
    },
  ]
  return (
    <>
      <Row className="grid grid-cols-1 gap-6">
        {/* Headers */}
        <Col span={24} className="flex justify-between items-center text-xl-medium text-gray-900">
          <span className="md:inline hidden">Unit</span>
          <CustomButton
            onClick={() => setOpenModal(true)}
            label={
              <div className="flex items-center gap-3 justify-center stroke-white">
                <PlusIcon /> Assign Unit
              </div>
            }
          />
        </Col>
        <Col span={24} className="grid grid-cols-1 gap-6">
          {/* Filter & Search */}
          <div className="hidden md:flex justify-between items-center">
            <div className="flex gap-2">
              {/* <Select placeholder="Grade" options={gradeOptions} onChange={val => handleChangeFilterIncludeOffset('grade', val)} /> */}
              <Select placeholder="Status" options={statusOptions} onChange={val => handleChangeFilterIncludeOffset('status', val)} />
            </div>
            <div>
              <Input
                size="medium"
                className="text-md-normal text-gray-500 w-[400px] px-[14px] py-[10px] rounded-lg"
                prefix={<MagnifyingIcon className={'mr-1'} />}
                placeholder="Cari Nama Unit"
                onChange={e => handleChangeFilterIncludeOffset('keywords', e.target.value)}
              />
            </div>
          </div>
          {/* Table */}
          <CustomTable
            columns={columns}
            data={userUnits}
            currentPage={currentPage}
            totalPerPage={totalPerPage}
            lengthAllData={lengthAllData}
            defaultPageSize={defaultPageSize}
            onChangeNextPage={onChangeNextPage}
            onChangePrevPage={onChangePrevPage}
            onChangePageSize={handleChangeFilterIncludeOffset}
          />
        </Col>
      </Row>
      <AssignUnitModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        units={units}
        selectedUnit={selectedUnit}
        handleSelectedUnit={handleSelectedUnit}
        handleAssignUnit={handleAssignUnit}
      />
    </>
  )
}

export default DetailUnitContent