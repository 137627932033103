const BellNotifIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4417 17.5001C11.2952 17.7526 11.0849 17.9623 10.8319 18.108C10.5788 18.2538 10.292 18.3305 10 18.3305C9.70802 18.3305 9.42116 18.2538 9.16814 18.108C8.91513 17.9623 8.70484 17.7526 8.55833 17.5001M15 6.66675C15 5.34067 14.4732 4.0689 13.5355 3.13121C12.5979 2.19353 11.3261 1.66675 10 1.66675C8.67392 1.66675 7.40215 2.19353 6.46447 3.13121C5.52678 4.0689 5 5.34067 5 6.66675C5 12.5001 2.5 14.1667 2.5 14.1667H17.5C17.5 14.1667 15 12.5001 15 6.66675Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="14" cy="6" r="5" fill="#12B76A" />
    </svg>
  )
}

export default BellNotifIcon
