const CopyIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="#C11B23"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16797 12.5013H3.33464C2.89261 12.5013 2.46868 12.3257 2.15612 12.0131C1.84356 11.7006 1.66797 11.2767 1.66797 10.8346V3.33464C1.66797 2.89261 1.84356 2.46868 2.15612 2.15612C2.46868 1.84356 2.89261 1.66797 3.33464 1.66797H10.8346C11.2767 1.66797 11.7006 1.84356 12.0131 2.15612C12.3257 2.46868 12.5013 2.89261 12.5013 3.33464V4.16797M9.16797 7.5013H16.668C17.5884 7.5013 18.3346 8.24749 18.3346 9.16797V16.668C18.3346 17.5884 17.5884 18.3346 16.668 18.3346H9.16797C8.24749 18.3346 7.5013 17.5884 7.5013 16.668V9.16797C7.5013 8.24749 8.24749 7.5013 9.16797 7.5013Z"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CopyIcon
