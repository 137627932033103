import { useState } from 'react'
import { Col, Divider, Spin } from 'antd'
import CardInformationJadwal from 'components/ui/card/card-information-jadwal'
import CardInformationUser from 'components/ui/card/card-information-user'
import CardPayment from 'components/ui/card/card-payment'
import CustomButton from 'components/ui/custom-button'
import { ModalLayout } from 'layouts'
import CardInformationCar from 'components/ui/card/card-information-car'
import CardPreviewHarga from 'components/ui/card/card-preview-harga'
import CardKeteranganTambahan from 'components/ui/card/card-keterangan-tambahan'
import moment from 'moment'
import InputCurrency from 'components/ui/input-currency'
import { onRupiah } from 'utils/formatCurrency'

function FormCheckoutJual({
  price,
  taxPrice,
  totalPrice,
  inspectionPrice,
  homeState,
  isShowRoom,
  isLoading,
  state,
  thumbnail,
  schedule,
  carData,
  handleCancel,
  handleClick,
  selectedPayment,
  handleSelectedPayment,
  handleChangePrice
}) {
  const user = JSON.parse(localStorage.getItem('user'))
  const [priceTemp, setPriceTemp] = useState(price?.input)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalPrice, setModalPrice] = useState(false)
  const previewPrice = parseInt(price?.input || 0).toLocaleString('ID-id')

  const handleConfirmPrice = () => {
    handleChangePrice('input', priceTemp)
    setModalPrice(false)
  }

  const handleSubmit = () => {
    setModalOpen(true)
    // if (selectedPayment !== null) {
    // } else {
    //   alert('Mohon pilih metode pembayaran')
    // }
  }
  return (
    <>
      <Col span={22} md={20} className="mx-auto grid grid-cols-1 md:grid-cols-[1fr_389px] gap-6">
        {/* left content */}
        <div>
          <div className="grid grid-cols-1 gap-6">
            {/* data mobil */}
            <CardInformationCar
              car={{
                thumbnail: thumbnail?.[0]?.thumbUrl,
                unit_name:
                  carData?.unit?.value !== undefined
                    ? carData?.unit?.unit_name
                    : `${carData?.year} ${carData?.brand?.label} ${carData?.model?.label} ${carData?.type?.label}`
              }}
              isJual
            />
            <div className="block md:hidden">
              <CardPreviewHarga price={previewPrice} setModalPrice={setModalPrice} />
            </div>
            {/* informasi user */}
            <CardInformationUser
              headerTitle={'Data diri'}
              data={{
                nama: user?.fullname || user?.name || '-',
                email: user?.email || '-',
                phone: user?.phone_number || '-',
                alamat: user?.address || '-'
              }}
            />
            {/* informasi jadwal */}
            <CardInformationJadwal inspeksi workshop={state.workshop} data={schedule} />
            {/* pembayaran */}
            {/* <CardPayment
              selectedPayment={selectedPayment}
              handleSelectedPayment={handleSelectedPayment}
              inspeksi
              data={homeState}
              price={inspectionPrice}
              disableVoucher
              // bookingPrice={state.price}
              taxPrice={taxPrice}
              totalPrice={totalPrice}
              homeState={homeState}
              isShowRoom={isShowRoom}
            /> */}
            {/* button */}
            <div className="flex gap-3 justify-end items-center">
              <CustomButton type="secondary" label={'Sebelumnya'} onClick={handleCancel} />
              <CustomButton label={'Konfirmasi'} onClick={handleSubmit} />
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="grid grid-cols-1 gap-6">
            <CardPreviewHarga price={previewPrice} setModalPrice={setModalPrice} />
            {/* <CardKeteranganTambahan /> */}
          </div>
        </div>
      </Col>
      {/* modal checkout */}
      <ModalLayout
        openModal={modalPrice}
        headerTitle={`Konfirmasi Jual Mobil`}
        className="w-fit"
        onCancel={() => setModalPrice(false)}>
        <div className="grid grid-cols-1 gap-4 w-[80vw] md:w-[400px]">
          {/* content */}
          <div className="p-6 bg-gray-200 flex space-x-3 text-md-semibold text-gray-700 flex items-center">
            <p>Rp</p>
            <InputCurrency
              placeholder="999.999.999"
              value={priceTemp}
              onChange={(value) => setPriceTemp(value)}
              name="input"
            />
          </div>
          {/* Button Container */}
          <div className="flex justify-end items-center gap-2">
            <CustomButton onClick={() => setModalPrice(false)} type="plain" label={'Batal'} />
            <CustomButton onClick={handleConfirmPrice} label={'Konfirmasi'} />
          </div>
        </div>
      </ModalLayout>
      <ModalLayout
        openModal={modalOpen}
        headerTitle={`Konfirmasi Jual Mobil`}
        className="w-full max-w-md"
        onCancel={() => setModalOpen(false)}>
        <div className="grid grid-cols-1 gap-4 w-full md:w-[400px]">
          <div className="border border-solid border-gray-200 rounded-[4px] p-4">
            <p className="text-md-medium text-gray-900">
              {carData?.unit?.value !== undefined
                ? carData?.unit?.unit_name
                : `${carData?.year} ${carData?.brand?.label} ${carData?.model?.label} ${carData?.type?.label}`}
            </p>
          </div>
          <Divider className="m-0" />
          <div className="flex justify-between text-md-medium text-gray-700">
            <p>Harga Jual</p>
            <p>Rp. {previewPrice}</p>
          </div>
          <Divider className="m-0" />
          {/* Informasi Inspeksi & Metode Pembayaran */}
          <div className="grid grid-cols-1 gap-2 text-md-medium text-gray-700">
            <p>Infomasi Inspeksi:</p>
            <div>
              <p className="text-xs-regular text-gray-700">Tempat</p>
              <p className="text-sm-regular">{state?.workshop?.name}</p>
            </div>
            <div>
              <p className="text-xs-regular text-gray-700">Tanggal</p>
              <p className="text-sm-regular">{moment(schedule?.date).format('DD MMM YYYY')}</p>
            </div>
            <div>
              <p className="text-xs-regular text-gray-700">Waktu</p>
              <p className="text-sm-regular">{schedule?.hours?.time}</p>
            </div>
          </div>
          {/* <div className="grid grid-cols-1 gap-4">
            <p className="text-md-medium text-gray-900">Metode Pembayaran</p>
            <div className=" p-4 flex justify-start gap-4 items-center bg-gray-50 rounded-[4px]">
              <img src={selectedPayment?.image} className="w-14 object-contain" alt="bankLogo" />
              <p className="text-md-medium text-gray-700">{selectedPayment?.title}</p>
            </div>
          </div> */}
          <Divider className="m-0" />
          {/* Rincian Pembayaran */}
          {/* <div className="grid grid-cols-1 gap-4">
            <p className="text-md-medium text-gray-900">Rincian Pembayaran</p>
            <div className="flex justify-between items-center pb-1">
              <p className="text-md-regular text-gray-500">
                Biaya Inspeksi {isShowRoom ? '' : 'di Rumah'}
              </p>
              <p>Rp. {onRupiah(inspectionPrice?.inspection)}</p>
            </div>
            {!isShowRoom && (
              <div className="flex justify-between items-center pb-1">
                <p className="text-md-regular text-gray-500">Biaya Jarak</p>
                <p>
                  Rp.{' '}
                  {homeState?.range_price?.price !== undefined
                    ? onRupiah(homeState?.range_price?.price)
                    : '-'}
                </p>
              </div>
            )}
            <div className="flex justify-between items-center pb-1">
              <p className="text-md-regular text-gray-500">Biaya Admin</p>
              <p>Rp. {onRupiah(inspectionPrice.admin_fee)}</p>
            </div>
            <div className="flex justify-between items-center pb-1">
              <p className="text-md-regular text-gray-500">Pajak {inspectionPrice.tax}%</p>
              <p>Rp. {onRupiah(taxPrice)}</p>
            </div>
            <div className="flex justify-between items-center border-t pt-1">
              <p className="text-md-regular text-gray-500">Total Biaya</p>
              <p>Rp. {onRupiah(totalPrice)}</p>
            </div>
          </div> */}
          {/* Button Container */}
          <div className="flex justify-end items-center gap-2">
            <Spin spinning={isLoading}>
              <CustomButton onClick={() => setModalOpen(false)} type="plain" label={'Batal'} />
            </Spin>
            <Spin spinning={isLoading}>
              <CustomButton onClick={handleClick} label={'Konfirmasi'} />
            </Spin>
          </div>
        </div>
      </ModalLayout>
    </>
  )
}

export default FormCheckoutJual
