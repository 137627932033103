import { Divider } from 'antd'
import { ChecklistIcon } from 'assets'
import React from 'react'
import { useHistory } from 'react-router-dom'
import CardSectionHeader from '../card-section-header'
import CustomButton from '../custom-button'
import { RenderBadgeResultInspection } from '../render-badge-result-inspection'

function CardResultInspection({ idUnit, data, id, centered, summaryData, type = '' }) {
  const { push } = useHistory()

  const handleNavDetailInspection = () => {
    if (type === 'beli') push('/beli/mobil-bekas/detail-inspection', { id: id })
    else if (window.location.pathname.includes('dashboard')) {
      push('/dashboard/detail-unit/inspection-detail', { id: id, idUnit: idUnit })
    } else {
      push('/transaction/inspection-detail', { id: id })
    }
  }
  return (
    <CardSectionHeader
      headerTitle={<div className={`${centered ? 'text-center' : null}`}>Hasil Inspeksi</div>}>
      {/* {summaryData.length > 0 ?  : null} */}
      {summaryData && (
        <>
          <div className="grid grid-cols-1 py-4 bg-gray-50 gap-9 rounded-[4px]">
            <p className="text-xs-regular md:text-sm-regular text-gray-700 text-center">
              Dengan keahlian mekanik yang Kami miliki, 107 point di mobil ini telah Kami periksa.
            </p>
            <div className="flex justify-around text-xs-medium text-gray-700">
              <div className="flex flex-col gap-2 justify-center items-center ">
                <ChecklistIcon className={'stroke-success-500 w-9 h-9'} />
                <span>Bebas Banjir</span>
              </div>
              <div className="flex flex-col gap-2 justify-center items-center ">
                <ChecklistIcon className={'stroke-success-500 w-9 h-9'} />
                <span>Bebas Tabrak</span>
              </div>
            </div>
          </div>
          <Divider className="my-4" />
        </>
      )}

      <div className="grid grid-cols-1 gap-3">
        {data?.inspection_result?.inspectionCategoryList.map((data, key) => (
          <div key={key} className="grid grid-cols-2 gap-2 items-center">
            <p className="text-sm-semibold md:text-lg-semibold content-start text-gray-900">
              {data.name}
            </p>
            <div className="max-w-fit justify-self-end">
              <RenderBadgeResultInspection name={data.grade} />
            </div>
            {/* <p
              className={`text-lg-medium ${data.ok_total === data.part_total && data.ok_total > 0 && data.part_total > 0
                  ? 'text-success-500'
                  : 'text-gray-500'
                }`}>
              {data.ok_total}/{data.part_total}
            </p> */}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 mt-5">
        <CustomButton
          onClick={handleNavDetailInspection}
          type="plain"
          label={'Lihat Hasil Inspeksi'}
        />
      </div>
    </CardSectionHeader>
  )
}

export default CardResultInspection
