import CardFormPesanMobil from 'components/ui/card/card-form-pesan-mobil'
import CardUnit from 'components/ui/card/card-unit'
import React from 'react'

const FormBookingContent = ({
  dataCar,
  errors,
  dataUser,
  handleChange,
  handleChangeLocation,
  provinces,
  cities,
  subdistricts,
  isTaxi,
  is_create_your_own_car
}) => {
  return (
    <div className="grid grid-cols-1 gap-6">
      <CardUnit
        dataCatalog={dataCar}
        isTaxi={isTaxi}
        is_create_your_own_car={is_create_your_own_car}
      />
      <CardFormPesanMobil
        data={dataUser}
        errors={errors}
        handleChange={handleChange}
        handleChangeLocation={handleChangeLocation}
        provinces={provinces}
        cities={cities}
        subdistricts={subdistricts}
      />
    </div>
  )
}

export default FormBookingContent
