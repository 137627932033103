import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

const BookedUnitJual = ({ jumlah_booked }) => {
  return (
    <CardSectionHeader headerTitle={'Informasi Penjualan Unit'}>
      <p className="text-md-regular text-gray-600">{`Unit Anda telah dibooking oleh ${jumlah_booked} orang.`}</p>
    </CardSectionHeader>
  )
}

export default BookedUnitJual
