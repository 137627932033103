import { FormInputEmail, FormInputPassword } from 'features/forgot-password'
import { requestOtpForget, sendNewPassword, sendOtpForget } from 'features/forgot-password/service'
import useValidation from 'lib/useValidation'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { showErrorMessage } from 'utils/toastMessage'

const initialState = {
  email: '',
  password: '',
  confirm_password: ''
}

function ForgotPassword() {
  const { replace } = useHistory()
  const isB2B = localStorage.getItem('account_type')
  if (isB2B !== null) {
    replace('/')
  }
  const [state, setState] = useState(true)
  const [data, setData] = useState({ ...initialState })
  const [error, setError] = useState({ ...initialState })
  const [isEmail, setIsEmail] = useState(true)
  const [isPassword, setIsPassword] = useState(true)
  const { emailValidation, passValidation, confirmPassValidation } = useValidation()

  const { refetch } = useQuery(
    'newForgetPasswordOtpRequest',
    () =>
      requestOtpForget({
        email: String(data.email),
        otp_type: 21
      }),
    {
      onSuccess: (data) => {
        // console.log(data)
        setIsEmail(false)
      },
      enabled: false
    }
  )

  const resendOtp = useQuery(
    'resendForgetPasswordOtpRequest',
    () =>
      requestOtpForget({
        email: String(data.email),
        otp_type: 21
      }),
    {
      onSuccess: (data) => {
        // console.log(data)
      },
      enabled: false,
      retry: 1
    }
  )

  const submitOtp = useMutation(sendOtpForget, {
    onSuccess: (data) => {
      sessionStorage.setItem('otp_token', data.otp_token)
      setState(false)
      // localStorage.setItem('token', token)
      // sessionStorage.removeItem('token')
      // push('/')
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })

  const sendPassword = useMutation(sendNewPassword, {
    onSuccess: (data) => {
      // console.log(data)
      sessionStorage.removeItem('otp_token')
      setIsPassword(false)
      // localStorage.setItem('token', token)
      // sessionStorage.removeItem('token')
      // push('/')
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    let err = ''

    switch (name) {
      case 'email':
        err = emailValidation(value)
        break
      case 'password':
        err = passValidation(value)
        break
      case 'confirm_password':
        err = confirmPassValidation(data.password, value)
        break
      default:
        err
    }

    setData((prev) => ({
      ...prev,
      [name]: value
    }))
    setError((prev) => ({
      ...prev,
      [name]: err
    }))
  }

  const handleSubmitOTP = (otp) => {
    const dataSend = {
      otp_type: 21,
      otp_code: otp,
      email: data.email
    }
    submitOtp.mutate(dataSend)
  }

  const handleClickResendOtp = () => {
    resendOtp.refetch()
  }

  const handleSubmitEmail = () => {
    // validate
    const dataValid = data.email.length !== 0
    // console.log(data.email)
    // console.log(dataValid)
    const errorValid = error.email.length !== 0
    // console.log(error.email)
    // console.log(errorValid)
    if (data.email.length !== 0) {
      if (error.email.length === 0) {
        refetch()
      } else {
        showErrorMessage('Mohon perbaiki data')
      }
    } else {
      showErrorMessage('Mohon isi semua data')
    }
  }
  const handleCancelEmail = () => {
    setIsEmail(true)
  }

  const handleSubmitPassword = () => {
    // validate
    let validError = true
    let validInput = true
    Object.values(data).forEach((v) => {
      if (v.length === 0) {
        validInput = false
      }
    })
    Object.values(error).forEach((v) => {
      if (v.length > 0) {
        validError = false
      }
    })
    if (validInput) {
      if (validError) {
        const otp_token = sessionStorage.getItem('otp_token')
        const dataSend = {
          email: data.email,
          new_password: data.password,
          otp_token: otp_token
        }
        sendPassword.mutate(dataSend)
        // setIsPassword(false);
      } else {
        showErrorMessage('Mohon perbaiki data')
      }
    } else {
      showErrorMessage('Mohon isi semua data')
    }
  }
  const handleCancelPassword = () => {
    setState(true)
    setIsEmail(true)
    setIsPassword(true)
  }

  return (
    <>
      {state ? (
        <FormInputEmail
          data={data}
          error={error}
          handleChange={handleChange}
          isInput={isEmail}
          handleSubmitOTP={handleSubmitOTP}
          handleSubmitEmail={handleSubmitEmail}
          handleBack={handleCancelEmail}
          handleResend={handleClickResendOtp}
        />
      ) : (
        <FormInputPassword
          data={data}
          error={error}
          handleChange={handleChange}
          isPassword={isPassword}
          handleSubmitPassword={handleSubmitPassword}
          handleBack={handleCancelPassword}
        />
      )}
    </>
  )
}

export default ForgotPassword
