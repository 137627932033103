import { Col, DatePicker, Row, Typography } from 'antd'
import CustomTable from 'components/ui/custom-table'
import React, { useState, useCallback } from 'react'
import { RightOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { CoinsIcon, CoinStackIcon, TruckIcon } from 'assets'
import { useQuery } from 'react-query'
import {
  getReportList,
  getTotalBills,
  getTotalCars,
  getTotalExpenses
} from 'features/report-type/service'
import { debounce } from 'lodash'
import moment from 'moment'
import { onRupiah } from 'utils/formatCurrency'
// import dayjs from 'dayjs'

const initialFilter = {
  offset: 0,
  limit: 5,
  start_date: '',
  end_date: ''
}

const initialTotals = {
  expense: 0,
  total_unit: 0,
  total_assign: 0,
  bill: 0
}

const ReportTypeContainer = ({ type, icon: Icon }) => {
  const { push } = useHistory()
  const { color, label, value, path } = type
  const handleClick = () => push(`/report/${path}`)
  const bg = `bg-${color}-50`
  const strk = `text-${color}-500`
  return (
    <div className="flex justify-between itens-center bg-white border border-solid border-gray-200 p-6 rounded-[4px]">
      <div className="grid-cols-1">
        <div className="flex items-center mb-2 space-x-3">
          <div className={`${bg} w-[48px] h-[48px] rounded-[4px] flex items-center justify-center`}>
            {/* disini icon */}
            <Icon className={`${strk} h-6 w-6`} />
          </div>
          <p className="text-sm-medium text-gray-500">{label}</p>
        </div>
        <p className="text-xl-semibold text-gray-700">{value}</p>
      </div>
      <button onClick={handleClick} className="border-0 bg-white cursor-pointer">
        <RightOutlined className="text-gray-500 h-4 w-4" />
      </button>
    </div>
  )
}

function ReportPage() {
  const token = localStorage.getItem('token')
  const accountType = localStorage.getItem('account_type')
  if (accountType !== 'company' || token === null) {
    window.location.replace('/')
  }

  const { RangePicker } = DatePicker
  const dateFormat = 'DD/MM/YYYY'

  const [reportData, setReportData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [reportTotals, setReportTotals] = useState({ ...initialTotals })
  const [filter, setFilter] = useState({ ...initialFilter })

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    []
  )

  const { refetch } = useQuery(['getReportList', debouncedChangeHandler], {
    queryFn: () => {
      const dataSend = {
        token: token,
        params: {
          limit: filter.limit,
          offset: filter.offset,
          start_date: String(filter.start_date),
          end_date: String(filter.end_date)
        }
      }

      return getReportList(dataSend)
    },
    onSuccess: (data) => {
      setReportData(data)
    },
    enabled: Boolean(debouncedChangeHandler)
  })
  useQuery(['getTotalExpenses'], getTotalExpenses, {
    onSuccess: (data) => {
      setReportTotals((prev) => ({
        ...prev,
        expense: data
      }))
    }
  })
  useQuery(['getTotalBills'], getTotalBills, {
    onSuccess: (data) => {
      setReportTotals((prev) => ({
        ...prev,
        bill: data
      }))
    }
  })
  useQuery(['getTotalCars'], getTotalCars, {
    onSuccess: (data) => {
      setReportTotals((prev) => ({
        ...prev,
        total_unit: data.total_company_unit,
        total_assign: data.total_assigned_company_unit
      }))
    }
  })

  const handleChangeSelectedFilter = (name, val) => {
    setFilter((prev) => ({
      ...prev,
      [name]: val
    }))
    return debouncedChangeHandler()
  }

  const handleChangeFilterIncludeOffset = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
      offset: 0
    }))
    return debouncedChangeHandler()
  }
  const handleChangeDate = (val) => {
    if(val !== null) {
      const startDate = moment(val[0]).format('DD/MM/YYYY')
      const endDate = moment(val[1]).format('DD/MM/YYYY')
      setFilter((prev) => ({
        ...prev,
        start_date: startDate,
        end_date: endDate
      }))
    }else{
      setFilter({...initialFilter})
    }
    return debouncedChangeHandler()
  }

  const onChangeNextPage = () => {
    if (currentPage !== Math.ceil(reportData?.meta?.total / filter.limit)) {
      handleChangeSelectedFilter('offset', currentPage * filter.limit)
      setCurrentPage((prev) => prev + 1)
    }
  }
  const onChangePrevPage = () => {
    if (currentPage > 1) {
      handleChangeSelectedFilter('offset', filter.offset - filter.limit)
      setCurrentPage((prev) => prev - 1)
    }
  }

  const columns = [
    {
      name: 'Tanggal',
      selector: (row) => row.created_at,
      cell: (row) => moment(row.created_at).format('DD MMM YYYY'),
      sortable: true,
      width: '150px'
    },
    {
      name: 'Nama Unit',
      selector: (row) => (
        <>
          <p className="text-sm-medium">{row.unit_name}</p>
          <p className="text-xs-regular mt-1">{row.unit_police_number}</p>
        </>
      ),
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          {/* <p className="text-sm-medium">{row.unit_name}</p> */}
          <Typography.Text
            className="text-sm-medium block"
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>
          <p className="text-xs-regular">{row.unit_police_number}</p>
        </div>
      ),
      sortable: true,
      width: '30%'
    },
    {
      name: 'User',
      selector: (row) => row.user_fullname,
      cell: (row) => (
        <Typography.Text
          ellipsis={{
            tooltip: row?.user_fullname
          }}>
          {row?.user_fullname}
        </Typography.Text>
      ),
      sortable: true
    },
    {
      name: 'Layanan',
      selector: (row) => row.transaction_type,
      sortable: true
    },
    {
      name: 'Biaya',
      selector: (row) => (
        <p className="text-warning-500">{`Rp. ${onRupiah(row.transaction_profit)}`}</p>
      ),
      sortable: true
    }
  ]

  const reportTypes = [
    {
      label: 'Mobil Digunakan / Total Mobil',
      icon: TruckIcon,
      color: 'primary',
      value: `${reportTotals.total_assign} / ${reportTotals.total_unit} `,
      path: 'cars'
    },
    {
      label: 'Total Pengeluaran',
      icon: CoinStackIcon,
      color: 'warning',
      value: `Rp. ${onRupiah(reportTotals.expense)}`,
      path: 'expense'
    },
    {
      label: 'Total Tagihan',
      icon: CoinsIcon,
      color: 'danger',
      value: `Rp. ${onRupiah(reportTotals.bill)}`,
      path: 'bill'
    }
  ]

  return (
    <Row className="gap-6">
      {/* Header */}
      <Col span={24} className="text-xl-medium text-gray-900">
        Report
      </Col>
      {/* Content */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* filter setting */}
        <Col md={8} span={24}>
          <RangePicker
            className="rounded-[4px] flex justify-center text-md-normal p-2 gap-3 items-center"
            format={dateFormat}
            onChange={(val) => handleChangeDate(val)}
          />
        </Col>
        {/* report type */}
        <Col span={24} className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {reportTypes.map((type, index) => (
            <ReportTypeContainer type={type} icon={type.icon} key={`report-type-${index}`} />
          ))}
        </Col>
        {/* table content */}
        <div className="md:bg-white md:border border-solid border-gray-200 md:p-6 rounded-[4px]">
          <CustomTable
            columns={columns}
            data={reportData?.data || []}
            currentPage={filter?.offset === 0 ? 1 : filter?.offset + 1}
            totalPerPage={
              currentPage * filter?.limit >= reportData?.meta.total
                ? reportData?.meta.total
                : currentPage * filter?.limit
            }
            lengthAllData={reportData?.meta?.total}
            defaultPageSize={filter?.limit}
            onChangePageSize={handleChangeFilterIncludeOffset}
            onChangeNextPage={onChangeNextPage}
            onChangePrevPage={onChangePrevPage}
          />
        </div>
      </Col>
    </Row>
  )
}

export default ReportPage
