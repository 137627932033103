const ThumbsUpIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="31"
      height="30"
      viewBox="0 0 31 30"
      stroke="#12B76A"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.9987 28.3327H4.9987C4.29145 28.3327 3.61318 28.0517 3.11308 27.5516C2.61298 27.0515 2.33203 26.3733 2.33203 25.666V16.3327C2.33203 15.6254 2.61298 14.9472 3.11308 14.4471C3.61318 13.947 4.29145 13.666 4.9987 13.666H8.9987M18.332 10.9993V5.66602C18.332 4.60515 17.9106 3.58773 17.1605 2.83759C16.4103 2.08744 15.3929 1.66602 14.332 1.66602L8.9987 13.666V28.3327H24.0387C24.6818 28.34 25.3059 28.1146 25.7959 27.698C26.2859 27.2815 26.6089 26.7019 26.7054 26.066L28.5454 14.066C28.6034 13.6838 28.5776 13.2936 28.4698 12.9224C28.362 12.5511 28.1748 12.2077 27.9212 11.916C27.6675 11.6243 27.3535 11.3913 27.0008 11.233C26.6481 11.0747 26.2652 10.995 25.8787 10.9993H18.332Z"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ThumbsUpIcon
