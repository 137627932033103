import { DatePicker } from 'antd'
import CustomButton from 'components/ui/custom-button'
import FormInput from 'components/ui/form/form-input'
import FormSelect from 'components/ui/form/form-select'
import InputCurrency from 'components/ui/input-currency'
import { ModalLayout } from 'layouts'
import React from 'react'

const ModalFilterExTaxi = ({
  openModal,
  onCancel,
  filter,
  handleFilter,
  brands,
  handleResetFilter
}) => {
  return (
    <ModalLayout
      className={'max-w-md w-full md:hidden'}
      openModal={openModal}
      onCancel={onCancel}
      headerTitle={'Filter'}>
      <div className="grid grid-cols-1 gap-3">
        <FormSelect
          data={{
            label: 'Lokasi',
            name: 'merek',
            onChange: (value) => handleFilter('merek', value),
            options: brands,
            value: filter?.location,
            defaultValue: 'Brand',
            placeholder: 'Pilih Brand'
          }}
        />
        <div className="grid grid-cols-1 gap-3">
          <p className="text-gray-700 text-lg-medium">Harga</p>
          <div className="grid grid-cols-1 gap-2">
            <p className="text-sm-medium text-gray-700">Minimum</p>
            <InputCurrency
              placeholder="Rp 2000000"
              value={filter?.minHarga}
              onChange={(value, name) => handleFilter(name, value)}
              name="minHarga"
              prefix="Rp"
            />
          </div>
          <div className="grid grid-cols-1 gap-2">
            <p className="text-sm-medium text-gray-700">Maximum</p>
            <InputCurrency
              placeholder="Rp 2000000"
              value={filter?.maxHarga}
              onChange={(value, name) => handleFilter(name, value)}
              name="maxHarga"
              prefix="Rp"
            />
          </div>
        </div>
        <div className="flex justify-end items-center gap-2">
          <CustomButton
            type="plain"
            onClick={() => {
              handleResetFilter()
              onCancel()
            }}
            label={'Reset Filter'}
          />
          <CustomButton onClick={onCancel} label={'Simpan Filter'} />
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalFilterExTaxi
