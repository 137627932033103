import React, { useState } from 'react'
import CardSectionHeader from 'components/ui/card-section-header'
import { Col, Row, Slider } from 'antd'
import InputCurrency from 'components/ui/input-currency'
import { useEffect } from 'react'

const sliderProps = {
  min: 1,
  max: 5,
  step: 1,
  defaultValue: 1,
  marks: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5'
  }
}

// eslint-disable-next-line react/display-name
const CardCicilan = React.forwardRef((props, ref) => {
  const initialCicilan = {
    hargaMobil: String(props?.price) || '0',
    uangMuka: '20000000',
    persentase: '10,00',
    bunga: '10,00',
    tenor: 5
  }
  const [state, setState] = useState({ ...initialCicilan })

  const cicilan = () => {
    let final = 0
    const price = parseInt(state.hargaMobil)
    const depo = parseInt(state.uangMuka)
    const bunga = parseFloat(state.bunga) / 100
    const tenor = parseInt(state.tenor) * 12

    const sisaBayar = price - depo
    const cicilanPokok = sisaBayar / tenor
    const bungaBulanan = (sisaBayar * bunga) / 12

    final = cicilanPokok + bungaBulanan

    return final
  }

  const handleChange = (value, name) => {
    if (name === 'hargaMobil') {
      const price = parseInt(value)
      const oldPer = state?.persentase?.replace(',', '.')
      const percent = parseFloat(oldPer) / 100
      const newVal = price * percent
      setState((prev) => ({
        ...prev,
        [name]: value,
        uangMuka: newVal
      }))
    } else if (name === 'persentase') {
      const price = parseInt(state.hargaMobil)
      const oldPer = value.replace(',', '.')
      const percent = parseFloat(oldPer) / 100
      const newVal = price * percent
      setState((prev) => ({
        ...prev,
        [name]: value,
        uangMuka: newVal
      }))
    }
    else if (name === 'uangMuka') {
      const newPercent = ((parseInt(value) / parseInt(state.hargaMobil)) * 100).toFixed(2)
      setState((prev) => ({
        ...prev,
        uangMuka: value,
        persentase: newPercent
      }))
    }
    else {
      setState((prev) => ({
        ...prev,
        [name]: value
      }))
    }
  }

  const handleChangeSlider = (value) => {
    setState((prev) => ({
      ...prev,
      tenor: value
    }))
  }

  return (
    <div ref={ref}>
      <CardSectionHeader
        hideDivider
        headerTitle={<div className="text-center">Kalkulator Cicilan</div>}>
        <div className="grid grid-cols-1 gap-6 mt-6">
          {/* select */}
          <Col span={24} className="grid grid-cols-1 xl:grid-cols-3 gap-4">
            <Col span={24} className="grid grid-cols-1 gap-1.5">
              <p className="text-sm-medium text-gray-900 mb-2">Harga Mobil (Rp)</p>
              <InputCurrency
                placeholder="Masukan Harga Mobil"
                value={state.hargaMobil}
                onChange={handleChange}
                name="hargaMobil"
              />
            </Col>
            <Col span={24} className="grid grid-cols-1 gap-1.5">
              <p className="text-sm-medium text-gray-900 mb-2">Uang Muka</p>
              <Row className="gap-2">
                <Col span={15}>
                  <InputCurrency
                    placeholder="Masukan Harga Mobil"
                    value={state.uangMuka}
                    onChange={handleChange}
                    decimalsLimit={0}
                    name="uangMuka"
                  />
                </Col>
                <Col span={8}>
                  <InputCurrency
                    suffix="%"
                    maxLength={6}
                    decimalsLimit={2}
                    value={state.persentase}
                    onChange={handleChange}
                    name="persentase"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24} className="grid grid-cols-1 gap-1.5">
              <p className="text-sm-medium text-gray-900 mb-2">Suku Bunga (%)</p>
              <InputCurrency
                placeholder="Masukan Suku Bunga"
                suffix="%"
                maxLength={6}
                decimalsLimit={2}
                value={state.bunga}
                onChange={handleChange}
                name="bunga"
              />
            </Col>
          </Col>
          {/* result */}
          <Col span={24} className="grid grid-cols-1 gap-4 xl:grid-cols-2 xl:gap-16">
            <Row className="gap-2">
              <Col span={24}>
                <p className="text-sm-medium text-gray-900 mb-14">Tenor Pinjaman (Tahun)</p>
                <Slider
                  {...sliderProps}
                  onChange={(value) => handleChangeSlider(value)}
                  value={state.tenor}
                />
              </Col>
            </Row>
            <div className="flex h-full items-center">
              <div>
                <p className="text-sm-medium text-gray-500">Estimasi Cicilan</p>
                <p className="text-xl-semibold text-primary-500">
                  Rp. {cicilan().toLocaleString('id-ID')} / bulan
                </p>
              </div>
            </div>
          </Col>
        </div>
      </CardSectionHeader>
    </div>
  )
})
export default CardCicilan
