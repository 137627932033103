import { Col } from 'antd'
import Password from 'antd/lib/input/Password'
import CustomButton from 'components/ui/custom-button'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import OtpInput from 'components/auth-otp/component/otp-input'
import Countdown from 'react-countdown'
import { useState, useRef } from 'react'

function ModalChangePassword({
  passwords,
  errors,
  isOTPSuccess,
  handleSubmitPassword,
  handleResend,
  handleChangePassword,
  onCancel,
  handleSubmitOTP
}) {
  const [otp, setOtp] = useState('')
  const ref = useRef(Date.now())
  const [countdown, setCountdown] = useState({
    key: 1,
    show: true
  })
  // eslint-disable-next-line no-undef
  const time = parseInt(process.env.REACT_APP_OTP_TIME)
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      setCountdown((prev) => ({
        ...prev,
        show: false
      }))
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      )
    }
  }
  const onChange = (value) => setOtp(value)
  const handleClickResend = (e) => {
    e.preventDefault()
    ref.current = Date.now()
    setCountdown((prev) => ({
      key: prev + 1,
      show: true
    }))
    handleResend()
  }
  const handleSubmit = () => {
    handleSubmitOTP(otp)
  }
  return (
    <Col span={24} className="grid gap-3">
      {isOTPSuccess ? (
        <>
          <div className="text-gray-700 flex flex-col text-center space-y-6">
            <p className="text-xl-medium mb-2">Masukan Kode Verifikasi</p>
            <OtpInput value={otp} valueLength={6} onChange={onChange} />
            <p className="text-xs-medium text-gray-500 mb-[6px]">
              Tidak menerima OTP?{' '}
              {countdown.show ? (
                <span className="text-sm-medium text-gray-300">
                  Kirim ulang dalam{' '}
                  {/* <Countdown key={countdown.key} date={Date.now() + time} zeroPadTime={3} renderer={renderer} /> */}
                  <Countdown
                    key={countdown.key}
                    date={ref.current + time}
                    zeroPadTime={3}
                    renderer={renderer}
                  />
                </span>
              ) : (
                <a onClick={handleClickResend} className="text-primary-500 hover:text-primary-500">
                  Kirim ulang kode verifikasi
                </a>
              )}
            </p>
          </div>
          <div className="flex justify-end items-center gap-2">
            <CustomButton
              type="plain"
              label={'Batal'}
              onClick={() => {
                setOtp('')
                onCancel()
              }}
            />
            <CustomButton label={'Verifikasi'} onClick={handleSubmit} />
          </div>
        </>
      ) : (
        <>
          <Col>
            <p className="text-sm-medium mb-[6px]">Password Baru <span className='text-danger-500'>*</span></p>
            <Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              value={passwords.new}
              onChange={(e) => handleChangePassword('new', e.target.value)}
            />
            {errors.new ? <small className="text-primary-300">{errors.new}</small> : null}
          </Col>
          <Col>
            <p className="text-sm-medium mb-[6px]">Konfirmasi Password baru <span className='text-danger-500'>*</span></p>
            <Password
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              value={passwords.confirm}
              onChange={(e) => handleChangePassword('confirm', e.target.value)}
            />
            {errors.confirm ? <small className="text-primary-300">{errors.confirm}</small> : null}
          </Col>
          <div className="flex justify-end items-center gap-2">
            <CustomButton type="plain" label={'Batal'} onClick={onCancel} />
            <CustomButton label={'Ganti Password'} onClick={handleSubmitPassword} />
          </div>
        </>
      )}
    </Col>
  )
}

export default ModalChangePassword
