import {
  AlertMarkIcon,
  BookIcon,
  BookMultipleIcon,
  CarActiveIcon,
  PersonIcon,
  QuestionMarkIcon,
  SafetyIcon
} from 'assets'

export default [
  {
    key: 0,
    label: 'Beli Mobil',
    children: [
      {
        key: 0,
        label: (
          <span className={'text-md-medium gap-2 flex items-center'}>
            <CarActiveIcon /> Beli Mobil Bekas
          </span>
        ),
        url: '/beli/mobil-bekas'
      },
      {
        key: 1,
        label: (
          <span className={'text-md-medium gap-2 flex items-center'}>
            <CarActiveIcon />
            ex-Taxi
          </span>
        ),
        url: '/beli/ex-taxi'
      }
    ],
    url: '/beli'
  },
  {
    key: 1,
    label: 'Jual Mobil',
    url: '/jual'
  },
  { key: 2, label: 'Servis', url: '/servis' },
  {
    key: 3,
    label: 'Inspeksi',
    url: '/inspeksi'
  },
  {
    key: 4,
    label: 'Tentang Kami',
    children: [
      {
        key: 0,
        label: (
          <span className={'text-md-medium gap-2 flex items-center'}>
            <BookIcon className={'stroke-primary-700'} /> General
          </span>
        ),
        url: '/about-us/general'
      },
      // {
      //   key: 1,
      //   label: (
      //     <span className={'text-md-medium gap-2 flex items-center'}>
      //       <PersonIcon className={'stroke-primary-700'} />
      //       Customer Stories
      //     </span>
      //   ),
      //   url: '/about-us/customer-stories'
      // },
      {
        key: 2,
        label: (
          <span className={'text-md-medium gap-2 flex items-center'}>
            <SafetyIcon /> Kebijakan Privasi
          </span>
        ),
        url: '/about-us/privacy-policy'
      },
      {
        key: 3,
        label: (
          <span className={'text-md-medium gap-2 flex items-center'}>
            <BookMultipleIcon /> Syarat & Ketentuan
          </span>
        ),
        url: '/about-us/terms-and-conditions'
      },
      {
        key: 4,
        label: (
          <span className={'text-md-medium gap-2 flex items-center'}>
            <AlertMarkIcon /> Help & Support
          </span>
        ),
        url: '/about-us/help-and-support'
      },
      {
        key: 5,
        label: (
          <span className={'text-md-medium gap-2 flex items-center'}>
            <QuestionMarkIcon /> FAQ
          </span>
        ),
        url: '/about-us/faq'
      }
    ],
    url: '/about-us'
  }
]
