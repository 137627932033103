const CarActiveIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 6.58325L14.4167 1.58325H3.58333L1.5 6.58325M16.5 6.58325V12.8333M16.5 6.58325H1.5M16.5 12.8333V14.9166H15.6667V12.8333M16.5 12.8333H15.6667M1.5 6.58325V12.8333M1.5 12.8333V14.9166H2.33333V12.8333M1.5 12.8333H2.33333M15.6667 12.8333H2.33333M5.25 11.1666C4.91848 11.1666 4.60054 11.0349 4.36612 10.8005C4.1317 10.566 4 10.2481 4 9.91658C4 9.58506 4.1317 9.26712 4.36612 9.0327C4.60054 8.79828 4.91848 8.66658 5.25 8.66658C5.58152 8.66658 5.89946 8.79828 6.13388 9.0327C6.3683 9.26712 6.5 9.58506 6.5 9.91658C6.5 10.2481 6.3683 10.566 6.13388 10.8005C5.89946 11.0349 5.58152 11.1666 5.25 11.1666ZM12.75 11.1666C12.4185 11.1666 12.1005 11.0349 11.8661 10.8005C11.6317 10.566 11.5 10.2481 11.5 9.91658C11.5 9.58506 11.6317 9.26712 11.8661 9.0327C12.1005 8.79828 12.4185 8.66658 12.75 8.66658C13.0815 8.66658 13.3995 8.79828 13.6339 9.0327C13.8683 9.26712 14 9.58506 14 9.91658C14 10.2481 13.8683 10.566 13.6339 10.8005C13.3995 11.0349 13.0815 11.1666 12.75 11.1666Z"
        stroke="#C11B23"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CarActiveIcon
