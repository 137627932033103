const SafetyIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      stroke="#A21226"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00065 18.3334C8.00065 18.3334 14.6673 15 14.6673 10V4.16669L8.00065 1.66669L1.33398 4.16669V10C1.33398 15 8.00065 18.3334 8.00065 18.3334Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SafetyIcon
