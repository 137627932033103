import React from 'react'
import CardSectionHeader from 'components/ui/card-section-header';
import { Tabs } from 'antd';

function CardSpesifikasiTaxi({ data }) {
    const checkValue = (val, type) => {
        let result = '-'
        if (type === 'boolean') {
            result = val ? 'Available' : 'Not Available'
        } else {
            result = val || '-'
        }
        return result
    }
    const data1 = [
        { label: 'Engine', value: data?.performance_engine || '-' },
        { label: 'Transmission', value: data?.performance_transmission || '-' },
        { label: 'Drive Mode', value: data?.performance_drive_mode ? 'Available' : 'Not Available' },
        { label: 'Drivetrain', value: data?.performance_drive_train || '-' },
        { label: 'Shift-By-Wire (SBW)', value: data?.performance_sbw ? 'Available' : 'Not Available' },
        { label: 'Paddle Shifter', value: data?.performance_paddle_shifter ? 'Available' : 'Not Available' },
        { label: 'Motor Driven Power Steering (MDS)', value: data?.performance_mdps ? 'Available' : 'Not Available' },
    ]
    const data2 = [
        { label: 'Air Bag', value: data?.safety_airbag || '-' },
        { label: '(TMPS)', value: data?.safety_tpms ? 'Available' : 'Not Available' },
        { label: '(ABS)', value: data?.safety_abs ? 'Available' : 'Not Available' },
        { label: '(ESC)', value: data?.safety_esc ? 'Available' : 'Not Available' },
        { label: '(HAC)', value: data?.safety_hac ? 'Available' : 'Not Available' },
        { label: '(LKA)', value: data?.safety_lka ? 'Available' : 'Not Available' },
        { label: '(LFA)', value: data?.safety_lfa ? 'Available' : 'Not Available' },
        { label: '(BCW)', value: data?.safety_bcw ? 'Available' : 'Not Available' },
        { label: '(BSM)', value: data?.safety_bsm ? 'Available' : 'Not Available' },
        { label: '(SEA)', value: data?.safety_sea ? 'Available' : 'Not Available' },
        { label: '(ROA)', value: data?.safety_roa ? 'Available' : 'Not Available' },
        { label: '(FCA)', value: data?.safety_fca ? 'Available' : 'Not Available' },
        { label: '(DAW)', value: data?.safety_daw ? 'Available' : 'Not Available' },
        { label: 'Safety Cruise Control', value: data?.safety_cruise_control ? 'Available' : 'Not Available' },
    ]
    const data3 = [
        { label: 'Media', value: data?.interior_media || '-' },
        { label: 'Cluster', value: data?.interior_cluster || '-' },
        { label: 'Speaker', value: data?.interior_speaker || '-' },
        { label: 'Bluetooth', value: data?.interior_bluetooth ? 'Available' : 'Not Available' },
        { label: 'Steer Control', value: data?.interior_steer_control ? 'Available' : 'Not Available' },
        { label: 'Seat Material', value: data?.interior_seat_material || '-' },
        { label: 'Seat Ventilation', value: data?.interior_seat_ventilation ? 'Available' : 'Not Available' },
        { label: 'Seat Adjust', value: data?.interior_seat_adjust || '-' },
        { label: 'Seat Capacity', value: data?.interior_seat_capacity || '-' },
        { label: '(IMS)', value: data?.interior_ims ? 'Available' : 'Not Available' },
        { label: 'Ambient Light', value: data?.interior_ambient_light ? 'Available' : 'Not Available' },
        { label: 'Cup Holder', value: data?.interior_cup_holder ? 'Available' : 'Not Available' },
        { label: 'Luggage Net', value: data?.interior_luggage_net ? 'Available' : 'Not Available' },
        { label: 'Cooling Box Gloves', value: data?.interior_cooling_glove_box ? 'Available' : 'Not Available' },
    ]
    const data4 = [
        { label: 'Head Lamp', value: data?.exterior_head_lamp || '-' },
        { label: '(DRL)', value: data?.exterior_drl ? 'Available' : 'Not Available' },
        { label: 'Positioning Lamp', value: data?.exterior_positioning_lamp ? 'Available' : 'Not Available' },
        { label: 'Audio Light Control', value: data?.exterior_auto_light_control ? 'Available' : 'Not Available' },
        { label: 'Grille', value: data?.exterior_grille || '-' },
        { label: 'Wiper', value: data?.exterior_wiper || '-' },
        { label: 'Wheel', value: data?.exterior_wheel || '-' },
        { label: 'Side Mirror Color', value: data?.exterior_side_mirror_color || '-' },
        { label: 'Side Mirror Folding', value: data?.exterior_side_mirror_folding ? 'Available' : 'Not Available' },
        { label: 'Side Mirror Heated', value: data?.exterior_side_mirror_heated ? 'Available' : 'Not Available' },
        { label: 'Side Mirror Repeater', value: data?.exterior_side_mirror_repeater ? 'Available' : 'Not Available' },
        { label: 'Puddle Lamp', value: data?.exterior_puddle_lamp ? 'Available' : 'Not Available' },
        { label: 'Rear Lamp', value: data?.exterior_rear_lamp || '-' },
        { label: 'Side Spoiler', value: data?.exterior_side_spoiler ? 'Available' : 'Not Available' },
        { label: '(HMSL)', value: data?.exterior_hmsl ? 'Available' : 'Not Available' },
        { label: 'Muffler', value: data?.exterior_muffler || '-' },
        { label: 'Trunk', value: data?.exterior_trunk ? 'Available' : 'Not Available' },
        { label: 'Sunroof', value: data?.exterior_sunroof ? 'Available' : 'Not Available' },
        { label: 'Two Tone', value: data?.exterior_two_tone ? 'Available' : 'Not Available' },
        { label: 'Fog Lamp', value: data?.exterior_fog_lamp ? 'Available' : 'Not Available' },
        { label: 'Parking Sensor', value: data?.exterior_parking_sensor ? 'Available' : 'Not Available' },
        { label: 'Mud Guard', value: data?.exterior_mud_guard ? 'Available' : 'Not Available' },
    ]
    const data5 = [
        { label: 'Key', value: data?.convenience_key || '-' },
        { label: '(EPB)', value: data?.convenience_epb ? 'Available' : 'Not Available' },
        { label: '(AC)', value: data?.convenience_ac || '-' },
        { label: '(USB)', value: data?.convenience_usb ? 'Available' : 'Not Available' },
        { label: 'Wireless Charger', value: data?.convenience_wireless_charger ? 'Available' : 'Not Available' },
        { label: 'Map Lamp', value: data?.convenience_map_lamp ? 'Available' : 'Not Available' },
        { label: 'Rain Sensor', value: data?.convenience_rain_sensor ? 'Available' : 'Not Available' },
        { label: 'Head Up Display', value: data?.convenience_head_up_display ? 'Available' : 'Not Available' },
        { label: 'Auto Defogger', value: data?.convenience_auto_defogger ? 'Available' : 'Not Available' },
    ]
    const items = [
        {
            label: 'Performance',
            key: '1',
            children: <ChildrenContainer data={data1} />
        },
        {
            label: 'Safety',
            key: '2',
            children: <ChildrenContainer data={data2} />
        },
        {
            label: 'Interior',
            key: '3',
            children: <ChildrenContainer data={data3} />
        },
        {
            label: 'Exterior',
            key: '4',
            children: <ChildrenContainer data={data4} />
        },
        {
            label: 'Convenience',
            key: '5',
            children: <ChildrenContainer data={data5} />
        }
    ]
    return (
        <CardSectionHeader hideDivider headerTitle={<div className='text-center'>Spesifikasi Mobil</div>}>
            <div className='grid grid-cols-1'>
                <Tabs renderTabBar={RenderTabBar} defaultActiveKey="1" items={items} />
            </div>
        </CardSectionHeader>
    )
}

const ChildrenContainer = ({ data }) => {
    return (
        <div className='grid grid-cols-1 gap-4'>
            {
                data.map((el, index) => (
                    <div className='grid grid-cols-2 gap-8 text-md-medium' key={index}>
                        <p className='text-gray-500'>{el.label}</p>
                        <p className='text-gray-700'>{el.value}</p>
                    </div>
                ))
            }
        </div>
    )
}

const RenderTabBar = ({ panes, activeKey, onTabClick }) => {
    return (
        <div className="flex md:min-w-min z-[1] md:justify-center mt-4 mb-7 w-full overflow-y-auto">
            <div className="flex md:gap-6 px-5 py-3 bg-white">
                {panes.map(({ props: tab }) => {
                    return (
                        <span
                            onClick={onTabClick.bind(this, tab.tabKey)}
                            className={`text-xs-medium md:text-md-regular flex md:gap-2 p-2 md:p-4 hover:text-primary-600 items-center relative cursor-pointer ${activeKey === tab.tabKey
                                ? 'text-primary-500 border-b-[1px] border-primary-500'
                                : 'text-gray-400 '
                                }`}
                            key={tab.tabKey}>
                            {tab.tab}
                        </span>
                    )
                })}
            </div>
        </div>
    )
}

export default CardSpesifikasiTaxi;