const LulusInspeksiIcon = ({ className }) => {
  return (
    <svg width="79" height="82" viewBox="0 0 79 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.7915 56.5198C58.2116 56.5198 59.5641 56.3169 60.8489 55.9788L62.472 58.819C63.0806 59.901 64.5007 60.2392 65.5827 59.6305L68.6934 57.8047C69.7754 57.1961 70.1136 55.7759 69.5049 54.6939L67.8819 51.8537C69.7078 50.0278 71.0603 47.661 71.8042 44.956H75.0501C76.335 44.956 77.3494 43.9416 77.2818 42.6567V39.0726C77.2818 37.7878 76.2674 36.7734 74.9825 36.841H71.7365C70.9927 34.2713 69.6402 31.9044 67.7467 30.0109L69.3697 27.1707C69.9783 26.0887 69.5726 24.6686 68.4906 24.06L65.3798 22.3017C64.2978 21.6931 62.8777 22.0988 62.2691 23.1808L60.6461 26.0211C59.2936 25.683 57.9411 25.5477 56.6562 25.5477"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.791 46.8496C58.6845 46.8496 60.5104 45.9705 61.66 44.2798C63.4859 41.5749 62.742 37.9231 60.1046 36.0973C59.0903 35.421 57.9406 35.0829 56.791 35.0829"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.9027 52.53V19.732C42.589 17.8385 40.3574 14.3221 40.3574 10.197C40.3574 6.34235 42.3862 2.89349 45.4293 1V3.43449V6.2071C45.4293 7.69484 46.2408 9.04734 47.5257 9.79121C49.8925 11.1437 52.8004 11.1437 55.1672 9.79121C56.4521 9.04734 57.2636 7.69484 57.2636 6.2071V3.43449V1C60.3067 2.96112 62.3354 6.34235 62.3354 10.197C62.3354 14.2544 60.1038 17.8385 56.7902 19.732V52.53V62.268C60.1038 64.1615 62.3354 67.6779 62.3354 71.803C62.3354 75.6577 60.3067 79.1065 57.2636 81V78.5655V75.7929C57.2636 74.3052 56.4521 72.9527 55.1672 72.2088C52.8004 70.8563 49.8925 70.8563 47.5257 72.2088C46.2408 72.9527 45.4293 74.3052 45.4293 75.7929V78.5655V81C42.3862 79.0389 40.3574 75.6577 40.3574 71.803C40.3574 67.7456 42.589 64.1615 45.9027 62.268V52.53Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.3583 39.0051H31.0261C29.4031 39.0051 27.8477 38.3965 26.6981 37.3145L23.7227 34.6095"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5488 45.9028L20.0019 47.0524C22.1659 47.3906 23.7213 49.2164 23.7213 51.3804V53.5444H11.5488"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99023 40.6957V60.9155"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.98987 40.6956L1.60864 37.0439C1.20289 36.7058 1 36.1648 1 35.6238V31.6339C1 30.5519 1.87914 29.6728 2.96113 29.6728H10.6027L17.2299 16.9594C18.5148 15.2011 20.6112 14.1868 22.7752 14.1868H36.503"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2979 60.9155V61.6594C17.2979 65.0406 14.5253 67.8132 11.1441 67.8132C7.76283 67.8132 4.99023 65.0406 4.99023 61.6594V60.9155"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.3569 60.9155H11.5488"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.082 53.4768H39.951"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6367 29.6729H40.6967"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2213 19.3263L18.043 25.4125H35.0844V18.3795H23.3177C22.5738 18.3795 21.7623 18.7853 21.2213 19.3263Z"
        fill="white"
      />
    </svg>
  )
}

export default LulusInspeksiIcon
