import React from 'react'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { getCYOC, getCYOCTaxi } from '../features/beli-mobil/service'

function useCYOC(idCatalog, isCreateYourOwnCar, isTaxi) {
  const [cyoc, setCyoc] = useState(null)
  const [selectedSpareparts, setSelectedSpareparts] = useState({})
  useQuery({
    queryKey: 'getCYOC',
    queryFn: () => getCYOC(idCatalog),
    onSuccess: (data) => {
      setCyoc(data)
    },
    enabled: !isTaxi && idCatalog !== null && !!isCreateYourOwnCar,
    staleTime: 0
  })
  useQuery({
    queryKey: 'getCYOCTaxi',
    queryFn: () => getCYOCTaxi(),
    onSuccess: (data) => {
      setCyoc(data)
    },
    enabled: idCatalog !== null && !!isCreateYourOwnCar && isTaxi,
    staleTime: 0
  })
  function handleCYOC(name, selectedSparepart) {
    const newObj = { ...selectedSpareparts }
    // Remove Object when user click again same selectedSparepart
    if (newObj[name] !== undefined && newObj[name].id === selectedSparepart?.id) {
      delete newObj[name]
    } else {
      newObj[name] = selectedSparepart
    }
    setSelectedSpareparts(newObj)
  }
  function deleteSelectedSpareparts(name) {
    const newObj = { ...selectedSpareparts }
    delete newObj[name]
    setSelectedSpareparts(newObj)
  }
  function resetCYOC() {
    setSelectedSpareparts({})
  }
  return { cyoc, selectedSpareparts, handleCYOC, resetCYOC, deleteSelectedSpareparts }
}

export default useCYOC
