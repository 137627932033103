import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getInspectionHistories } from '../service'

const useInspectionHistoriesById = (unitId) => {
  const [inspectionHistories, setInspectionHistories] = useState([])
  const [inspectionPagination, setInspectionPagination] = useState(1)
  const [filterInspection, setFilterInspection] = useState({ limit: 5, offset: 0, unitId: unitId })
  const { refetch } = useQuery({
    queryKey: ['getInspectionHistories', inspectionPagination],
    queryFn: () => getInspectionHistories(filterInspection),
    onSuccess: (data) => {
      setInspectionHistories(data)
    },
    refetchOnWindowFocus: false
  })
  const handlePaginationInspection = (pageNumber) => {
    const totalOffset = filterInspection?.limit * pageNumber - filterInspection?.limit
    if (totalOffset >= inspectionHistories?.meta?.total || totalOffset < 0) {
      return false
    }
    setInspectionPagination(pageNumber)
    handleFilterInspection('offset', totalOffset)
  }
  function handleFilterInspection(name, value) {
    setFilterInspection({ ...filterInspection, [name]: value })
    return refetch()
  }

  return {
    filterInspection,
    inspectionPagination,
    inspectionHistories,
    handlePaginationInspection,
    handleFilterInspection
  }
}

export default useInspectionHistoriesById
