const SideBarIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      stroke="#667085"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 1.5V16.5M3.16667 1.5H14.8333C15.7538 1.5 16.5 2.24619 16.5 3.16667V14.8333C16.5 15.7538 15.7538 16.5 14.8333 16.5H3.16667C2.24619 16.5 1.5 15.7538 1.5 14.8333V3.16667C1.5 2.24619 2.24619 1.5 3.16667 1.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SideBarIcon
