const PencairanCepatIcon = ({ className }) => {
  return (
    <svg
      width="65"
      className={className}
      height="66"
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.8155 5.60501C32.8155 8.14246 30.7479 10.21 28.2105 10.21C25.673 10.21 23.6055 8.14246 23.6055 5.60501C23.6055 3.06757 25.673 1 28.2105 1C30.7479 1 32.8155 3.06757 32.8155 5.60501Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4492 1C37.9867 1 40.0542 3.06757 40.0542 5.60501C40.0542 8.14246 37.9867 10.21 35.4492 10.21"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.5791 26V30.6989C56.0299 34.5521 59.6012 38.9691 61.8567 43.3862C66.7436 52.6901 61.2928 65.0014 52.3647 65.0014H32.817H13.2693C4.34123 65.0014 -1.10958 52.6901 3.77735 43.3862C6.12684 38.9691 9.60408 34.4581 15.0549 30.6989V26C15.0549 25.1542 15.8067 24.4023 16.6525 24.4023H48.9815C49.8273 24.4963 50.5791 25.1542 50.5791 26Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.62891 32.8593V27.2205C7.62891 21.4878 12.2339 16.8828 17.9666 16.8828H47.6642C53.3969 16.8828 58.0019 21.4878 58.0019 27.2205V32.8593"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0547 30.793H29.1516"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.1319 44.8881C57.4402 41.6928 55.2787 38.8734 52.6472 36.3359C53.4931 38.9674 54.057 41.6928 54.2449 44.5122C54.6208 50.7148 52.5533 57.0114 48.6061 61.7104C48.5122 61.8044 48.4182 61.8984 48.3242 61.9923H52.2713C55.6546 61.9923 57.7222 59.4549 58.662 57.9512C61.1054 54.0981 61.2934 49.0232 59.1319 44.8881Z"
        fill="#E12B25"
      />
      <path
        d="M28.775 53.1571L26.0496 49.304C25.9556 49.304 25.7677 49.304 25.5797 49.304H22.5723V53.1571H20.1289V40H25.5797C26.7075 40 27.7412 40.188 28.5871 40.5639C29.4329 40.9398 30.0907 41.5037 30.5606 42.1615C31.0305 42.8194 31.2185 43.6652 31.2185 44.699C31.2185 45.7327 30.9365 46.5786 30.4666 47.2364C29.9967 47.8943 29.2449 48.4582 28.3991 48.8341L31.4064 53.1571H28.775ZM27.9292 42.7254C27.3653 42.2555 26.5195 42.0676 25.4857 42.0676H22.6663V47.3304H25.4857C26.5195 47.3304 27.3653 47.1424 27.9292 46.6725C28.4931 46.2026 28.775 45.5448 28.775 44.699C28.775 43.8532 28.4931 43.1013 27.9292 42.7254Z"
        fill="#344154"
      />
      <path
        d="M41.9321 43.5704C42.6839 44.0403 43.3417 44.6041 43.7177 45.356C44.1876 46.1078 44.3755 47.0476 44.3755 48.0814C44.3755 49.1152 44.1876 49.961 43.7177 50.8068C43.2478 51.5586 42.6839 52.2165 41.9321 52.5924C41.1802 53.0623 40.2404 53.2502 39.3006 53.2502C37.9849 53.2502 36.8572 52.7804 36.1053 51.9345V56.8215H33.7559V43.1005H36.0114V44.4162C36.3873 43.9463 36.8572 43.5704 37.4211 43.3824C37.9849 43.1005 38.6428 43.0065 39.3006 43.0065C40.2404 42.9125 41.1802 43.1945 41.9321 43.5704ZM41.1802 50.3369C41.7441 49.773 42.026 49.0212 42.026 48.0814C42.026 47.1416 41.7441 46.3898 41.1802 45.8259C40.6164 45.262 39.8645 44.9801 39.0187 44.9801C38.4548 44.9801 37.9849 45.074 37.515 45.356C37.0451 45.6379 36.6692 46.0138 36.4813 46.4837C36.1993 46.9536 36.1053 47.5175 36.1053 48.1754C36.1053 48.8332 36.1993 49.3971 36.4813 49.867C36.7632 50.3369 37.1391 50.7128 37.515 50.9947C37.9849 51.2767 38.4548 51.3707 39.0187 51.3707C39.8645 51.2767 40.6164 50.9947 41.1802 50.3369Z"
        fill="#344154"
      />
    </svg>
  )
}

export default PencairanCepatIcon
