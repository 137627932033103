import React from 'react'
import { useState } from 'react'
import {
  getAllBrands,
  getAllModels,
  getAllTransmision,
  getAllTypes,
  getPriceRecommendation
} from 'features/jual-mobil/service'
import { useQuery } from 'react-query'
const initialState = {
  type: {},
  model: {},
  brand: {},
  year: '',
  transmisi: {}
}
const initialprice = {
  min_price: '-',
  max_price: '-'
}
const useRecPriceUnit = () => {
  const [state, setState] = useState({ ...initialState })
  const [price, setPrice] = useState({ ...initialprice })

  // list
  const [transmissions, setTransmissions] = useState([])
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [types, setTypes] = useState([])

  useQuery({
    queryKey: 'getTransmissions',
    queryFn: () => getAllTransmision(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTransmissions(newData)
    },
    staleTime: 0
  })
  useQuery({
    queryKey: 'getBrands',
    queryFn: () => getAllBrands(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setBrands(newData)
    },
    staleTime: 0
  })
  useQuery({
    queryKey: ['getModels', state.brand.value],
    queryFn: () => getAllModels(state?.brand?.value),
    onSuccess: (data) => {
      setTypes([])
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setModels(newData)
    },
    enabled: state?.brand?.value !== undefined,
    staleTime: 0
  })
  useQuery({
    queryKey: ['getTypes', state?.model?.value],
    queryFn: () => getAllTypes(state?.model?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTypes(newData)
    },
    enabled: state?.model?.value !== undefined,
    staleTime: 0
  })
  const { refetch } = useQuery({
    queryKey: 'getPriceRecommendation',
    queryFn: () => getPriceRecommendation(state?.type?.value, state?.transmisi?.value, state?.year),
    // queryFn: () => getPriceRecommendation(16, 2),
    onSuccess: (data) => {
      setPrice({
        min_price: data.min_price.toLocaleString('id-ID'),
        max_price: data.max_price.toLocaleString('id-ID')
      })
    },
    onError: (data) => {
      // console.log('error', data?.response?.data)
      setPrice({ ...initialprice })
    },
    retry: false,
    enabled: false
  })
  // end of fetching

  const handleChange = (name, val) => {
    setState((prev) => ({
      ...prev,
      [name]: val
    }))
  }
  const handleChangeCarList = (name, val) => {
    switch (name) {
      case 'brand':
        setModels([])
        setTypes([])
        setState((prev) => ({
          ...prev,
          brand: val,
          model: {},
          type: {}
        }))
        break
      case 'model':
        setState((prev) => ({
          ...prev,
          model: val,
          type: {}
        }))
        break
      default:
        setState((prev) => ({
          ...prev,
          [name]: val
        }))
    }
  }

  return {
    handleChangeCarList,
    refetch,
    handleChange,
    price,
    brands,
    transmissions,
    models,
    types,
    state
  }
}

export default useRecPriceUnit
