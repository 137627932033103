const BirdMobilCertifiedIcon = ({ className }) => {
  return (
    <svg
      width="96"
      className={className}
      height="68"
      viewBox="0 0 96 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.2403 20.2511L51.4486 22.8195C50.3319 23.8245 48.8801 24.3829 47.3726 24.3829H39.8349H29.8404C28.3328 24.3829 26.8811 23.8245 25.7644 22.8195L22.9727 20.2511"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5273 30.8597L19.5118 31.9764C21.5218 32.2556 23.0294 33.9865 23.0294 35.9966V38.0066H11.5273"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33008 26.0021V44.986"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.32884 26.0021L2.20208 22.5403C1.81123 22.2053 1.58789 21.7028 1.58789 21.2003V17.4593C1.58789 16.4543 2.42542 15.6168 3.43045 15.6168H10.6332L16.8867 3.61224C18.1151 1.99302 20.0693 1.04382 22.0794 1.04382H38.2157H54.3521C56.418 1.04382 58.3163 1.99302 59.5447 3.61224L65.7982 15.5609H73.001C74.006 15.5609 74.8435 16.3985 74.8435 17.4035V19.1902"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9438 44.9861V45.7119C16.9438 48.8945 14.3754 51.5188 11.1369 51.5188C7.95433 51.5188 5.33008 48.9504 5.33008 45.7119V44.9861"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6577 44.9861H39.3332H11.5273"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.6387 38.0066H42.7956"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2227 15.6168H59.2106"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.9999 44.7628C94.9999 56.7115 85.2846 66.4268 73.3359 66.4268C61.3872 66.4268 51.6719 56.7115 51.6719 44.7628C51.6719 42.2502 52.1186 39.8493 52.8444 37.6159C55.8037 29.1848 63.8439 23.0988 73.2801 23.0988C85.3404 23.0988 94.9999 32.8141 94.9999 44.7628Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.6098 52.1329H81.8789"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.7813 47.6102H82.2129"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.6198 43.1436H82.5488"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.0221 56.2646H66.58C67.0825 56.2646 67.5292 55.818 67.5292 55.3154V38.5649C67.5292 38.0624 67.0825 37.6157 66.58 37.6157H52.9004"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5293 54.8689C67.5293 54.8689 74.9554 56.209 83.554 56.209C84.7265 56.209 85.899 55.539 85.899 54.1431C85.899 52.9705 84.9498 52.0772 83.6656 52.0772H84.6148C85.7874 52.0772 86.8482 51.1838 86.9599 49.9554C87.0716 48.6712 86.0107 47.5545 84.7265 47.5545H85.6757C86.8482 47.5545 87.9091 46.6612 88.0208 45.4328C88.1324 44.1486 87.0716 43.0319 85.7874 43.0319H86.7366C87.9091 43.0319 88.97 42.1385 89.0816 40.9102C89.1933 39.626 88.1324 38.5093 86.8482 38.5093H78.6405C77.9705 38.5093 77.4121 37.8951 77.5796 37.225C78.1938 34.3775 77.6355 31.9765 76.7421 30.0223C75.9046 28.2914 73.3361 28.8498 73.2803 30.7482C73.1686 37.2809 67.641 38.9559 67.641 38.9559"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.5117 40.631H57.1973L57.4764 41.0777C59.4865 44.5394 59.8215 48.7271 58.4256 52.468H64.5675V40.631H64.5117Z"
        fill="white"
      />
    </svg>
  )
}

export default BirdMobilCertifiedIcon
