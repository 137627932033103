import API from "services";

const getProvinces = async () => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/provinces/all`);
  return response.data;
}

const getCities = async (provinceId) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/cities/province/${provinceId}`);
  return response.data;
}

const getSubdistricts = async (cityId) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/admin/subdistricts/city/${cityId}`);
  return response.data;
}
const getUsers = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    params: args.params
  });
  return response;
}
const getUnitsList = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/users/unit/all`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    // params: args.params
  });
  return response.data;
}
const searchUser = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users/search`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    params: args.params
  });
  return response.data;
}
const getUserUnits = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/users/paginate-unit/${args.id}`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    params: args.params
  });
  return response;
}
const putAssignUnit = async (args) => {
  const { data: response } = await API.put(`${process.env.REACT_APP_API_BASE_URL}/master-service/website/dashboard/users/assign-unit-user/${args.id}`, args.body, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
  });
  return response.data;
}

const getDetailUser = async (args) => {
  const { data: response } = await API.get(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users/${args.id}`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
    params: args.params
  });
  return response.data;
}

const deleteUser = async (args) => {
  const { data: response } = await API.delete(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users/${args.id}`, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
  });
  return response;
}

const addUser = async (args) => {
  const { data: response } = await API.post(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users`, args.data, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
  });
  return response.data;
}
const addCompanyUser = async (args) => {
  const { data: response } = await API.put(`${process.env.REACT_APP_API_BASE_URL}/user-service/website/dashboard/users/add/${args.id}`, {}, {
    headers: {
      Authorization: `Bearer ${args.token}`
    },
  });
  return response;
}

export {
  getProvinces,
  getCities,
  getSubdistricts,
  getUsers,
  searchUser,
  getUserUnits,
  getUnitsList,
  putAssignUnit,
  getDetailUser,
  deleteUser,
  addUser,
  addCompanyUser
}