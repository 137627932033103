import React from 'react'

function SectionTitle({
  title,
  description = '',
  className = '',
  titleClassName = '',
  descClassName = ''
}) {
  return (
    <div className={`flex flex-col items-center justify-center gap-2 ${className}`}>
      <p className={`display-xs-semibold text-center md:display-sm-semibold ${titleClassName}`}>
        {title}
      </p>
      {description && (
        <p
          className={`text-sm-regular md:text-xl-regular text-gray-600 max-w-[692px] text-center ${descClassName}`}>
          {description}
        </p>
      )}
    </div>
  )
}

export default SectionTitle
