import { paymentList } from 'features/beli-mobil/data/paymentList'
import React, { useState } from 'react'

const usePayment = () => {
  const [modalSelectPayment, setModalSelectPayment] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState(null)
  const [creditCardToken, setCreditCardToken] = useState(null)
  const handleSelectedPayment = (id) => {
    const splitSelectedPayment = id?.split('-')
    const filteredPaymentMethod = paymentList
      .filter((paymentMethod) => paymentMethod.id.includes(splitSelectedPayment[0]))[0]
      .options.filter((bank) => bank.id.includes(id))[0]
    setSelectedPayment(filteredPaymentMethod)
  }
  const handleCreditCardToken = (token) => {
    setCreditCardToken(token)
  }
  function openModalPaymentMethod() {
    return setModalSelectPayment(true)
  }
  function closeModalPaymentMethod() {
    return setModalSelectPayment(false)
  }
  const getPaymentObject = () => {
    if (selectedPayment?.id === 'qr_code') {
      return {
        payment_method: 'qr_code'
      }
    } else if (selectedPayment?.id.split('-')[0] === 'virtual_account') {
      return {
        payment_method: 'virtual_account',
        virtual_account: {
          bank_code: selectedPayment?.id?.split('-')[1],
          type: 'closed'
        }
      }
    } else if (selectedPayment?.id === 'card') {
      return {
        payment_method: 'card',
        card: {
          linked_card_id: creditCardToken
        }
      }
    }
  }
  return {
    modalSelectPayment,
    openModalPaymentMethod,
    closeModalPaymentMethod,
    selectedPayment,
    handleSelectedPayment,
    getPaymentObject,
    handleCreditCardToken
  }
}

export default usePayment
