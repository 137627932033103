// import { useState } from 'react'

import { WhatsappIcon } from 'assets'

export default function LiveChat() {
  //   const [isActive, setActive] = useState(false)
  //   const [isMinimize, setMinimize] = useState(false)

  //   const onClickMinimize = () => {
  //     setMinimize((prev) => !prev)
  //   }

  //   const onClickActive = () => {
  //     setActive(true)
  //     setMinimize(false)
  //   }

  return (
    <div className={'fixed z-[998] right-6 bottom-16'}>
      {/* {
				isActive ?
					<LiveChatWindows
						minimize={isMinimize}
						onClickMinimize={onClickMinimize}
						onClickInactive={() => setActive(false)}
					/>
					:
					<button
						type="button"
						onClick={onClickActive}
						style={{
							boxShadow:
								"box-shadow: 0px 4px 8px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
						}}
						className="bg rounded-full bg-[#C21D2D] p-4"
					>
						<ChatProcessing className="text-white w-6 h6" />
					</button>
			} */}
      <a
        href={`https://wa.me/${process.env.REACT_APP_CUSTOMER_SERVICE_NUMBER}`}
        target="_blank"
        rel="noreferrer">
        <div
          style={{
            boxShadow:
              'box-shadow: 0px 4px 8px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)'
          }}
          className="bg rounded-full bg-[#C21D2D] p-4 cursor-pointer">
          <WhatsappIcon className={'fill-white'} />
        </div>
      </a>
    </div>
  )
}
