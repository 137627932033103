import React from 'react'
import moment from 'moment'

function ItemHistoryTransaction({ checked = false, data }) {
  return (
    <div className="flex items-start space-x-3">
      <div
        className={`${
          checked ? 'bg-primary-100 border-primary-500' : 'bg-white border-gray-300'
        } w-5 h-5 rounded-full border border-solid  flex items-center justify-center`}>
        <div
          className={`${
            checked ? 'bg-primary-500' : 'bg-white'
          } w-[10px] h-[10px]  rounded-full`}></div>
      </div>
      <div>
        <p className="text-md-reguler text-gray-500">
          {checked ? moment(data?.completion_time)?.format('DD MMMM YYYY - HH:mm') + ' WIB' : '-'}
        </p>
        <p className="text-md-medium text-gray-700">{data?.name}</p>
      </div>
    </div>
  )
}

export default ItemHistoryTransaction
