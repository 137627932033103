import React from 'react'
import CardSectionHeader from '../card-section-header'

function CardNote({ data }) {
  return (
    <CardSectionHeader headerTitle={'Catatan'}>
      <p>{data}</p>
    </CardSectionHeader>
  )
}
export default CardNote
