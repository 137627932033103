const ProsesMudahIcon = ({ className }) => {
  return (
    <svg
      width="64"
      className={className}
      height="66"
      viewBox="0 0 64 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.8301 20.2923H59.7001C61.4533 20.2923 62.9143 18.8313 62.9143 17.0781"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.1484 51.8516L58.0445 55.358C59.3107 56.4294 59.9925 57.9878 59.9925 59.6436V65.0007"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.7832 20.2917C7.7832 22.9215 9.92602 25.0643 12.4584 25.0643C15.0883 25.0643 17.1337 22.9215 17.1337 20.2917C17.1337 17.6619 14.9909 15.6165 12.4584 15.6165C9.82862 15.5191 7.7832 17.6619 7.7832 20.2917Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3828 20.2917C45.3828 22.9215 47.5256 25.0643 50.058 25.0643C52.6879 25.0643 54.7333 22.9215 54.7333 20.2917C54.7333 17.6619 52.5905 15.6165 50.058 15.6165C47.4282 15.5191 45.3828 17.6619 45.3828 20.2917Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.53906 7.04611L10.6105 8.50712C10.9027 8.99412 11.4871 9.18892 12.0715 9.18892H13.7273H35.058C38.8567 9.18892 42.5579 8.40972 45.9669 6.85131L46.0643 6.75391"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4336 9.18779V3.34375"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.78493 20.2931H3.69411C2.6227 20.2931 1.74609 19.4165 1.74609 18.3451V9.48163C1.74609 8.21542 2.8175 7.14401 4.08371 7.14401H9.63555L9.92775 6.94921C14.5056 3.73499 19.8626 1.59218 25.4145 1.10517C30.2845 0.715571 36.2259 1.29997 42.2648 4.709L46.0634 6.85181C46.0634 6.85181 57.8489 6.85181 62.9137 11.9166V14.4491H60.4787L58.1411 13.0855V11.6244"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2324 20.293H45.3812"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3295 35.8789L23.1738 45.0346"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.3301 25.7461V35.8758L41.3883 44.934"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4406 49.682C19.5523 46.9982 18.2778 43.9214 15.5941 42.8097C12.9103 41.6981 9.83352 42.9725 8.72185 45.6562C7.61017 48.34 8.8846 51.4168 11.5684 52.5285C14.2521 53.6402 17.3289 52.3657 18.4406 49.682Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2196 51.6562L6.32352 55.2601C5.15472 56.3315 4.57031 57.7925 4.57031 59.3509V64.8053"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6484 51.8516L20.5445 55.358C21.8107 56.4294 22.4925 57.9878 22.4925 59.6436V65.0007"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.9211 49.6507C57.0327 46.967 55.7583 43.8902 53.0746 42.7785C50.3908 41.6668 47.314 42.9412 46.2023 45.625C45.0906 48.3088 46.3651 51.3856 49.0488 52.4972C51.7326 53.6089 54.8094 52.3345 55.9211 49.6507Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.7195 51.6562L43.8235 55.2601C42.6547 56.3315 42.0703 57.7925 42.0703 59.3509V64.8053"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3106 20.292C14.3106 21.266 13.5314 22.1426 12.46 22.1426C11.486 22.1426 10.6094 21.3634 10.6094 20.292C10.6094 19.318 11.3886 18.4414 12.46 18.4414C13.5314 18.4414 14.3106 19.2206 14.3106 20.292Z"
        fill="#E12B25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.9063 20.292C51.9063 21.266 51.1271 22.1426 50.0557 22.1426C49.0817 22.1426 48.2051 21.3634 48.2051 20.292C48.2051 19.318 48.9843 18.4414 50.0557 18.4414C51.1271 18.4414 51.9063 19.2206 51.9063 20.292Z"
        fill="#E12B25"
      />
    </svg>
  )
}

export default ProsesMudahIcon
