import { Divider } from 'antd'
import { HeartIcon, ImagePlaceholder } from 'assets'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'
const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
function CardCar({ isTaxi, onClick, data, handleWishlist, isBookmark }) {
  const isCustomer = localStorage.getItem('account_type')
  const cicilan = (unitPrice) => {
    let final = 0
    const price = unitPrice || 0
    const depo = price * 0.1
    const bunga = 10 / 100
    const tenor = 5 * 12

    const sisaBayar = price - depo
    const cicilanPokok = sisaBayar / tenor
    const bungaBulanan = (sisaBayar * bunga) / 12

    final = cicilanPokok + bungaBulanan
    return final
  }
  return (
    <div
      className=" grid grid-cols-[100px_1fr] md:flex md:flex-col border-gray-200 border border-solid shadow-sm h-full rounded-md overflow-hidden w-full cursor-pointer "
      onClick={onClick}>
      {/* Card Cover */}
      <div className="bg-gray-100 h-full md:max-h-[120px] flex relative justify-center items-center">
        {/* Cover container*/}
        <img
          src={
            data?.thumbnail_filename ? urlImage.concat(data?.thumbnail_filename) : ImagePlaceholder
          }
          className="object-contain md:object-cover w-full h-full "
          alt="car"
        />
        {/* Bookmark Icon */}
        {!isTaxi && isCustomer === 'customer' && (
          <div
            onClick={(e) => {
              e.stopPropagation()
              handleWishlist(data?.id, isBookmark)
            }}
            className="absolute h-[30px] w-[30px] xl:h-[40px] xl:w-[40px] right-4 top-4 flex bg-white items-center justify-center rounded-full border-gray-200 border border-solid cursor-pointer group">
            {
              <HeartIcon
                className={
                  isBookmark
                    ? 'xl:w-5 xl:h-[18px] w-4 h-5 stroke-rose-500 fill-rose-500 xl:group-hover:stroke-gray-500 xl:group-hover:fill-white'
                    : 'xl:w-5 xl:h-[18px] w-4 h-5 stroke-gray-500 xl:group-hover:stroke-rose-500 xl:group-hover:fill-rose-500'
                }
              />
            }
          </div>
        )}
        {/* Badge Container */}
        {!isTaxi && (
          <div className="flex flex-col absolute justify-center items-start gap-1 bottom-2 left-2">
            {/* Badge Content */}
            {data?.is_create_your_own_car ? (
              <div className="rounded-2xl bg-blue-50 text-xs-medium text-blue-700 px-2 py-[2px]">
                Create Your Own Car
              </div>
            ) : null}
            {/* <div className="min-w-min">
              <div className="min-w-fit rounded-2xl bg-[#FEECE2] text-xs-medium text-primary-700 px-2 py-[2px]">
                BirdMobil Certified
              </div>
            </div> */}
          </div>
        )}
      </div>
      {/* Card Content */}
      <div className="h-full bg-white flex flex-col p-3 gap-3">
        {/* Spec Container */}
        <div className="flex flex-col gap-1">
          <div className="text-sm-medium text-gray-900">
            {data?.name ? data?.name : data?.unit_name}
          </div>
          {!isTaxi && (
            <div className="flex flex-row flex-wrap gap-1">
              <div className="text-xs-regular text-gray-500">
                {data?.mileage !== undefined && onRupiah(data?.mileage)} km
                <Divider type="vertical" className="h-full m-0 ml-1" />
              </div>
              <div className="text-xs-regular text-gray-500">
                {data?.transmission_name}
                <Divider type="vertical" className="h-full m-0 ml-1" />
              </div>
              <div className="text-xs-regular text-gray-500">
                {data?.branch_name}
                {/* <Divider type="vertical" className="h-full m-0 ml-1 hidden md:block" /> */}
              </div>
            </div>
          )}
        </div>
        {/* Price & Credit Container */}
        <div className="flex flex-col gap-1">
          <div className="md:text-md-semibold text-gray-900">Rp {onRupiah(data?.price)}</div>
          <div className="text-xs-regular text-gray-500">
            Rp {onRupiah(cicilan(data?.price))}/bln
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardCar
