import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

const PublishedJual = () => {
  return (
    <CardSectionHeader headerTitle={'Informasi Penjualan Unit'}>
      <p className="text-md-regular text-gray-600">Unit Anda sedang berada di katalog</p>
    </CardSectionHeader>
  )
}

export default PublishedJual
