import CardSectionHeader from '../card-section-header'
import { Input } from 'antd'
import { MagnifyingIcon } from 'assets'
import FormInput from '../form/form-input'
import FormSelect from '../form/form-select'
import FormTextArea from '../form/form-textarea'
import { useState } from 'react'
import MyPlacesAutocomplete from '../my-place-autocomplete'
import { useMemo } from 'react'

function CardLokasiInspeksi({
  isShowRoom,
  setIsShowRoom,
  isServis,
  provinces,
  state,
  homeState,
  cities,
  workshops,
  rangePrice,
  subdistricts,
  handleChangeLocation,
  handleChangeData,
  handleChangeHomeData,
  handleChangeActiveWorkshop,
  isPickup
}) {
  const handleClickHeader = (value) => setIsShowRoom(value)

  const headersInspeksi = [
    { label: 'Inspeksi di Showroom', value: true },
    { label: 'Inspeksi di Rumah', value: false }
  ]

  const headersServis = [
    { label: 'Datang Ke Bengkel', value: true },
    { label: 'Pickup', value: false }
  ]

  const headers = isServis ? headersServis : headersInspeksi
  return (
    <CardSectionHeader
      headerTitle={
        isServis ? (
          <span>
            Layanan Servis <span className="text-danger-500">*</span>
          </span>
        ) : (
          <span>
            Lokasi Inspeksi <span className="text-danger-500">*</span>
          </span>
        )
      }
      hideDivider>
      <div className="grid grid-cols-1 gap-6 mt-6">
        {/* pilih jenis */}

        <div className="grid grid-cols-2 gap-2 text-center text-xs-regular md:text-md-medium">
          {headers.map((headerItem) => (
            <div
              className={`p-3 cursor-pointer rounded ${
                isShowRoom === headerItem.value
                  ? 'bg-primary-100 text-primary-700'
                  : 'bg-gray-100 text-gray-500'
              }`}
              onClick={() => handleClickHeader(headerItem.value)}
              key={headerItem.label}>
              {headerItem.label}
            </div>
          ))}
        </div>

        {/* content */}
        {isShowRoom ? (
          <ContentInspeksiShowroom
            workshops={workshops}
            data={state}
            handleChangeActiveWorkshop={handleChangeActiveWorkshop}
          />
        ) : (
          <ContentInspeksiRumah
            isPickup={isPickup}
            provinces={provinces}
            state={state}
            homeState={homeState}
            cities={cities}
            handleChangeHomeData={handleChangeHomeData}
            subdistricts={subdistricts}
            handleChangeLocation={handleChangeLocation}
            handleChangeData={handleChangeData}
            rangePrice={rangePrice}
          />
        )}
      </div>
    </CardSectionHeader>
  )
}

const ContentInspeksiShowroom = ({ workshops, data, handleChangeActiveWorkshop }) => {
  const activeContainer = 'border-primary-300 bg-primary-50'
  const deactiveContainer = 'border-gray-200 bg-white'
  const [keyword, setKeyword] = useState('')

  const selectedWorkshop = useMemo(() => {
    return workshops?.find((el) => el.id === parseInt(data?.workshop?.id))
  }, [data?.workshop?.id])
  const filteredWorkshop = useMemo(() => {
    return workshops?.filter((el) => el.name.toLowerCase().includes(keyword.toLowerCase()))
  }, [keyword])

  return (
    <>
      {/* maps */}
      <div className="min-h-[328px] w-full">
        <iframe
          src={
            selectedWorkshop?.pin_google_map === '' || selectedWorkshop?.pin_google_map === null
              ? null
              : `https://maps.google.com/maps?q=${
                  selectedWorkshop?.pin_google_map?.split(',')?.[0]
                },${selectedWorkshop?.pin_google_map?.split(',')?.[1]}&hl=es;z=14&output=embed`
          }
          className="h-full w-full border-0"
        />
      </div>
      {/* Cari Bengkel */}
      <Input
        size="medium"
        className="text-md-normal text-gray-500 px-[14px] py-[5px] rounded-lg"
        prefix={<MagnifyingIcon className={'mr-1'} />}
        placeholder="Cari Bengkel"
        onChange={(e) => setKeyword(e.target.value)}
      />
      {/* list */}
      <div className="grid grid-cols-1 gap-4 p-3 overflow-y-auto max-h-[272px] bg-gray-50">
        {filteredWorkshop?.map((el, index) => (
          <div
            className={`flex gap-2 border border-solid border-gray-200 cursor-pointer p-4 rounded ${
              el.id === data.workshop.id ? activeContainer : deactiveContainer
            }`}
            key={index}
            onClick={() => handleChangeActiveWorkshop(el)}>
            <div
              className={`w-5 h-5 flex-shrink-0 rounded-full border border-solid flex items-center justify-center ${
                el.id === data.workshop.id
                  ? 'bg-primary-100 border-primary-500'
                  : 'bg-white border-gray-300 '
              }`}>
              <div
                className={`w-[10px] h-[10px]  ${
                  el.id === data.workshop.id ? 'bg-primary-500' : 'bg-white'
                } rounded-full`}></div>
            </div>
            <div>
              <p
                className={`text-sm-medium ${
                  el.id === data.workshop.id ? 'text-primary-700' : 'text-gray-700'
                }`}>
                {el?.name}
              </p>
              <p
                className={`text-sm-normal ${
                  el.id === data.workshop.id ? 'text-primary-600' : 'text-gray-500'
                }`}>
                {el?.location_name || '-'}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const ContentInspeksiRumah = ({
  homeState,
  provinces,
  cities,
  subdistricts,
  rangePrice,
  handleChangeLocation,
  handleChangeHomeData,
  isPickup
}) => {
  const inputs = [
    {
      id: 3,
      perRow: 2,
      child: [
        {
          id: 4,
          label: 'Provinsi',
          placeholder: 'Provinsi',
          mandatory: true,
          name: 'province',
          type: 'select',
          value: homeState?.province?.value,
          options: provinces,
          onChange: (_, option) => handleChangeLocation('province', option)
        },
        {
          id: 5,
          label: 'Kota',
          placeholder: 'Kota',
          mandatory: true,
          name: 'city',
          type: 'select',
          value: homeState?.city?.value,
          options: cities,
          onChange: (_, option) => handleChangeLocation('city', option)
        }
      ]
    },
    {
      id: 6,
      perRow: 2,
      child: [
        {
          id: 7,
          label: 'Kecamatan',
          placeholder: 'Kecamatan',
          mandatory: true,
          name: 'subdistrict',
          type: 'select',
          options: subdistricts,
          value: homeState?.subdistrict?.value,
          onChange: (_, option) => handleChangeLocation('subdistrict', option)
        },
        {
          id: 8,
          label: 'Kode POS',
          name: 'postal_code',
          type: 'text',
          placeholder: 'Kode POS',
          mandatory: true,
          pattern: '[^0-9*]',
          minLength: '5',
          maxLength: '5',
          value: homeState?.postal_code,
          onChange: (e) => handleChangeHomeData('postal_code', e.target.value)
        }
      ]
    },
    {
      id: 1,
      perRow: 1,
      child: [
        {
          id: 2,
          rows: 3,
          name: 'address',
          type: 'textarea',
          label: 'Alamat Rumah',
          placeholder: 'Masukan Alamat Rumah',
          mandatory: true,
          value: homeState?.address,
          onChange: (e) => handleChangeHomeData('address', e.target.value)
        }
      ]
    }
  ]
  const priceList = {
    id: 5,
    placeholder: 'Harga Jarak ',
    mandatory: false,
    name: 'range_price',
    type: 'select',
    value: homeState?.range_price?.value,
    options: rangePrice || [],
    onChange: (_, option) => handleChangeHomeData('range_price', option)
  }

  return (
    <>
      {/* input */}
      <div className="grid grid-cols-1 gap-6">
        {inputs.map((inputItem) => (
          <div className={`grid grid-cols-${inputItem.perRow} gap-6`} key={inputItem.id}>
            {inputItem.child.map((item) => {
              if (item.type === 'text') return <FormInput key={item.id} data={item} />
              else if (item.type === 'select') return <FormSelect key={item.id} data={item} />
              else if (item.type === 'textarea') return <FormTextArea key={item.id} data={item} />
            })}
          </div>
        ))}
      </div>

      {/* Cari Alamat */}
      {/* maps */}
      <MyPlacesAutocomplete handleChangeHomeData={handleChangeHomeData} />
      {/* old maps */}
      <div className="min-h-[328px] w-full relative border-0">
        <iframe
          className="h-full w-full border-0"
          src={`https://maps.google.com/maps?${
            homeState?.loc?.lat !== undefined && homeState?.loc?.lng !== undefined
              ? `q=${homeState?.loc?.lat},${homeState?.loc?.lng}`
              : ''
          }&hl=es;z=14&output=embed`}
        />
      </div>

      {/* list price */}
      <div className="p-3 bg-gray-100 grid grid-cols-1 gap-2 text-gray-700 text-xs-regular">
        <p className="text-xs-medium">
          Harga {isPickup ? 'pickup' : 'inspeksi'} di rumah perjarak:{' '}
          <span className="text-danger-500">*</span>
        </p>
        <FormSelect data={priceList} />
      </div>
    </>
  )
}

export default CardLokasiInspeksi
