import { Col, Row } from 'antd'
import { MagnifyingIcon, NullDashboard } from 'assets'
import PlusIcon from 'assets/icons/plus'
import CardDashboard from 'components/ui/card-dashboard'
import CustomButton from 'components/ui/custom-button'
import CustomPagination from 'components/ui/pagination/custom-pagination'
import { useDashboardUnits } from 'features/dashboard/hooks'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Spin, Input } from 'antd'
const NullComponent = () => {
  return (
    <Col span={24} className="flex flex-col justify-center text-center items-center gap-2">
      <img className="mb-14" src={NullDashboard} alt="" />
      <p className="text-xl-semibold text-gray-500">Tidak Ada Mobil Dalam Dashboard!</p>
      <p className="text-md-medium text-gray-400 ">Tambahkan mobil anda dan kelola di dashboard</p>
    </Col>
  )
}

function DashboardPage() {
  const { push } = useHistory()
  const { handlePagination, currentPage, dashboardUnits, filter, handleFilter, isFilterFetching } =
    useDashboardUnits()

  const navigateHandler = (path, id, serviceType) => {
    // console.log(serviceType)
    if (serviceType !== null || serviceType !== undefined) {
      push({ pathname: path, state: { id: id, type: serviceType } })
    } else if (id !== undefined || id !== null) {
      push({ pathname: path, state: { id: id } })
    } else {
      push(path)
    }
  }
  return (
    <Row className="gap-6">
      {/* Header */}
      <Col span={24} className="flex justify-between items-center text-xl-medium text-gray-900">
        Dashboard
        <CustomButton
          onClick={() => {
            navigateHandler('/dashboard/add-unit')
          }}
          label={
            <div className="flex items-center gap-3 justify-center stroke-white">
              <PlusIcon /> Tambah Unit
            </div>
          }
        />
      </Col>
      {/* Reminder */}
      {/* <Col
        onClick={() => {
          navigateHandler('/dashboard/reminder')
        }}
        span={24}
        className="py-3 px-3 md:px-6 bg-blue-50 border border-solid border-blue-300 flex justify-between cursor-pointer">
        <div className="text-blue-700 text-sm-medium md:text-md-medium stroke-blue-700 flex flex-col gap-2">
          <p className="flex gap-2 items-center">
            <CalendarIcon className="w-[15px] h-[16.67px] md:w-[18px] md:h-5" /> REMINDER
          </p>
          <p className="text-xs-regular md:text-sm-regular text-blue-500">
            Servis Birdmobil Bandung - 24 Oktober 2022 10:24 WIB
          </p>
        </div>
        <div className="flex gap-2 whitespace-nowrap text-sm-medium md:text-md-medium stroke-blue-700 text-blue-700 items-center justify-center">
          <span>Lihat Detail</span> <HiChevronRight />
        </div>
      </Col> */}
      {/* Search Bar */}
      <div className="w-full flex">
        <Input
          disabled={isFilterFetching && dashboardUnits.length === 0}
          id="searchKeywords"
          name="keywords"
          size="medium"
          value={filter.keywords}
          className="text-md-normal text-gray-500 px-[14px] hover:border-gray-200 focus:border-gray-200"
          prefix={<MagnifyingIcon className={'mr-1'} />}
          placeholder="Cari Unit"
          onChange={(e) => {
            handleFilter('keywords', e.target.value)
          }}
        />
      </div>
      {/* Content Dashboard */}
      {isFilterFetching ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spin spinning={isFilterFetching} size="large"></Spin>
        </div>
      ) : dashboardUnits?.data?.length !== 0 ? (
        <>
          <Col span={24} className="grid grid-cols-1 gap-6">
            {/* Card Dashboard */}
            {dashboardUnits?.data?.map((unit) => (
              <CardDashboard key={unit.id} unit={unit} navigateHandler={navigateHandler} />
            ))}
          </Col>
          <Col span={24} className="flex justify-end">
            <CustomPagination
              onChange={handlePagination}
              defaultPageSize={filter?.limit}
              defaultCurrent={currentPage}
              current={currentPage}
              total={dashboardUnits?.meta?.total}
            />
          </Col>
        </>
      ) : (
        <NullComponent />
      )}
    </Row>
  )
}

export default DashboardPage
