const BookingServisIcon = ({ className }) => {
  return (
    <svg
      width="61"
      className={className}
      height="64"
      viewBox="0 0 61 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.0544 16.8594L40.8314 18.9021C39.9303 19.7432 38.7888 20.1637 37.5872 20.1637H31.5793H23.6488C22.4473 20.1637 21.3058 19.7432 20.4046 18.9021L18.1816 16.8594"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5251 30.979H42.4531V29.3569C42.4531 27.7347 43.6547 26.3529 45.2168 26.1727L51.5251 25.2715"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.04883 25.2715L15.3571 26.1727C16.9793 26.413 18.1208 27.7948 18.1208 29.3569V30.979H9.04883"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.12305 21.4258V36.5056"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.12309 21.4257L1.59977 18.7221C1.29938 18.4217 1.11914 18.0613 1.11914 17.6407V14.6368C1.11914 13.7957 1.78001 13.1949 2.56104 13.1949H8.32863L13.3152 3.6423C14.2765 2.32056 15.8385 1.59961 17.4606 1.59961H30.2575H43.1144C44.7365 1.59961 46.2986 2.38064 47.2599 3.6423L52.2464 13.1949H58.014C58.795 13.1949 59.4559 13.8557 59.4559 14.6368V17.6407C59.4559 18.0613 59.2757 18.4217 58.9753 18.7221L56.452 21.4257V36.5055"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.2598 36.5059V37.0466C47.2598 39.5699 49.3025 41.6727 51.8859 41.6727C54.4092 41.6727 56.5119 39.63 56.5119 37.0466V36.5059"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3147 36.5059V37.0466C13.3147 39.5699 11.272 41.6727 8.68859 41.6727C6.16527 41.6727 4.0625 39.63 4.0625 37.0466V36.5059"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.5247 36.5059H31.218H9.04883"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6523 30.9785H33.9219"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5547 13.1348H47.0187"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.4255 62.4004H8.14727C5.32355 62.4004 2.98047 60.1174 2.98047 57.2336V51.7664C2.98047 48.9427 5.26347 46.5996 8.14727 46.5996H52.4255C55.2492 46.5996 57.5923 48.8826 57.5923 51.7664V57.2937C57.5923 60.1174 55.3093 62.4004 52.4255 62.4004Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.115 4.48438H30.3181H17.4612C16.8004 4.48438 16.1996 4.78477 15.7189 5.2654L13.7363 9.05038H46.8399L44.8573 5.2654C44.3766 4.72469 43.7759 4.48438 43.115 4.48438Z"
        fill="#E12B25"
      />
      <path
        d="M10.9115 57.8342C10.4309 57.7141 10.0704 57.5338 9.83008 57.2935L10.2506 56.3323C10.4909 56.5125 10.8514 56.6927 11.2119 56.873C11.5724 56.9931 11.9929 57.0532 12.3534 57.0532C12.834 57.0532 13.1945 56.9931 13.4348 56.8129C13.6751 56.6327 13.7953 56.4524 13.7953 56.2121C13.7953 56.0319 13.7352 55.8516 13.6151 55.7315C13.4949 55.6113 13.3147 55.4912 13.1344 55.4311C12.9542 55.371 12.6538 55.3109 12.2933 55.1908C11.8127 55.0706 11.3921 54.9504 11.0917 54.8303C10.7913 54.7101 10.551 54.5299 10.3107 54.2896C10.0704 54.0493 10.0103 53.6888 10.0103 53.2682C10.0103 52.9078 10.1305 52.6074 10.3107 52.307C10.4909 52.0066 10.7913 51.7663 11.2119 51.586C11.6325 51.4058 12.053 51.3457 12.6538 51.3457C13.0743 51.3457 13.4348 51.4058 13.7953 51.4659C14.1558 51.5259 14.5162 51.7062 14.7566 51.8864L14.3961 52.8477C14.0957 52.6674 13.7953 52.5473 13.4949 52.4872C13.1945 52.4271 12.8941 52.367 12.5937 52.367C12.1131 52.367 11.7526 52.4271 11.5123 52.6074C11.272 52.7876 11.1518 52.9678 11.1518 53.2682C11.1518 53.4485 11.2119 53.6287 11.3321 53.7489C11.4522 53.869 11.6325 53.9291 11.8127 54.0493C11.9929 54.1093 12.2933 54.1694 12.6538 54.2896C13.1344 54.4097 13.555 54.5299 13.8554 54.6501C14.1558 54.7702 14.4562 54.9504 14.6364 55.1908C14.8767 55.4311 14.9368 55.7916 14.9368 56.2121C14.9368 56.5726 14.8166 56.873 14.6364 57.1734C14.4562 57.4738 14.1558 57.7141 13.7352 57.8943C13.3147 58.0746 12.834 58.1346 12.2933 58.1346C11.8728 58.0746 11.3921 58.0145 10.9115 57.8342Z"
        fill="#344154"
      />
      <path
        d="M21.0051 56.9945V58.0159H16.1387V51.5273H20.8849V52.5487H17.3403V54.2309H20.4644V55.1922H17.3403V56.9945H21.0051Z"
        fill="#344154"
      />
      <path
        d="M26.5923 57.9558L25.2705 56.0934C25.2104 56.0934 25.1504 56.0934 25.0302 56.0934H23.5883V57.9558H22.3867V51.5273H25.0302C25.5709 51.5273 26.0515 51.6475 26.4721 51.8277C26.8926 52.008 27.193 52.2483 27.4334 52.6088C27.6737 52.9692 27.7938 53.3898 27.7938 53.8103C27.7938 54.291 27.6737 54.7115 27.4334 55.072C27.193 55.4325 26.8326 55.6728 26.412 55.853L27.914 57.9558H26.5923ZM26.1116 52.8491C25.8112 52.6088 25.4508 52.4886 24.91 52.4886H23.5282V55.072H24.91C25.4508 55.072 25.8112 54.9518 26.1116 54.7115C26.412 54.4712 26.5322 54.1708 26.5322 53.7503C26.5322 53.3898 26.412 53.0894 26.1116 52.8491Z"
        fill="#344154"
      />
      <path
        d="M35.0033 51.5273L32.1796 58.0159H30.978L28.1543 51.5273H29.476L31.6389 56.5139L33.8017 51.5273H35.0033Z"
        fill="#344154"
      />
      <path d="M35.7246 51.5273H36.9262V58.0159H35.7246V51.5273Z" fill="#344154" />
      <path
        d="M39.8697 57.653C39.329 57.3526 38.9084 56.9921 38.6081 56.4514C38.3077 55.9107 38.1875 55.37 38.1875 54.7692C38.1875 54.1083 38.3677 53.5676 38.6681 53.087C38.9685 52.6063 39.3891 52.1858 39.9298 51.8854C40.4705 51.585 41.0713 51.4648 41.6721 51.4648C42.2128 51.4648 42.6934 51.585 43.114 51.7652C43.5345 51.9455 43.895 52.2459 44.1954 52.5463L43.4144 53.2071C42.9337 52.7265 42.393 52.4261 41.6721 52.4261C41.2515 52.4261 40.831 52.5463 40.4705 52.7265C40.11 52.9067 39.8697 53.2071 39.6294 53.5075C39.4492 53.868 39.329 54.2285 39.329 54.7091C39.329 55.1297 39.4492 55.5502 39.6294 55.9107C39.8096 56.2712 40.11 56.5115 40.4705 56.6917C40.831 56.872 41.1915 56.9921 41.6721 56.9921C42.333 56.9921 42.9337 56.7518 43.4144 56.2111L44.1954 56.932C43.895 57.2925 43.5345 57.5328 43.0539 57.7131C42.6333 57.8933 42.1527 58.0135 41.612 58.0135C41.0112 58.0735 40.4104 57.9534 39.8697 57.653Z"
        fill="#344154"
      />
      <path
        d="M50.2023 56.9945V58.0159H45.3359V51.5273H50.0822V52.5487H46.5375V54.2309H49.6616V55.1922H46.5375V56.9945H50.2023Z"
        fill="#344154"
      />
    </svg>
  )
}

export default BookingServisIcon
