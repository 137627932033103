import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

const contentServiceMap = {
  inspeksi_failed: {
    title: 'Inspeksi Gagal',
    description: 'Silahkan lakukan penjadwalan inspeksi kembali pada menu inspeksi'
  },
  inspeksi_cancel: {
    title: 'Inspeksi Dibatalkan',
    description: 'Inspeksi pada unit Anda telah dibatalkan.'
  },
  servis_cancel: {
    title: 'Servis Dibatalkan',
    description: 'Servis pada unit Anda telah dibatalkan.'
  },
  service_rejected: {
    title: 'Servis Ditolak',
    description:
      'Servis pada unit Anda telah ditolak oleh Admin. Silahkan lakukan penjadwalan servis kembali pada menu Servis'
  },
  pickup_ongoing: {
    title: 'Progress Pick up',
    description: 'Pick up sedang berlangsung'
  },
  beli_failed: {
    title: 'Beli Mobil Gagal',
    description:
      'Transaksi beli mobil Anda gagal. Silahkan lakukan transaksi Anda kembali pada menu Beli Mobil'
  }
}

const ServiceInfoCard = ({ status }) => {
  return (
    <CardSectionHeader headerTitle={contentServiceMap[status]?.title}>
      <p className="text-gray-600 text-md-regular">{contentServiceMap[status]?.description}</p>
    </CardSectionHeader>
  )
}

export default ServiceInfoCard
