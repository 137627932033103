import React from 'react'
import CardSectionHeader from '../card-section-header'

function CardBuyerInformation({ data }) {
	return (
		<>
			<CardSectionHeader headerTitle={`Informasi Pembeli`}>
				<div className="grid grid-cols-4">
					{
						data.map((item, index) => (
							<div key={index}>
								<p className="text-lg-medium text-gray-900 mb-1">{item.label}</p>
								<p className="text-md-regular text-gray-600">{item.value}</p>
							</div>
						))
					}
				</div>
			</CardSectionHeader>
		</>
	)
}

export default CardBuyerInformation
