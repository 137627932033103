import PlusIcon from 'assets/icons/plus'
import { ModalLayout } from 'layouts'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import BadgeItem from '../badge-item'
import CardSectionHeader from '../card-section-header'
import CustomButton from '../custom-button'
import { Input } from 'antd'
import { MagnifyingIcon } from 'assets'

function CardSelectCar({ selectedCar, handleSelectedCar, keyword, handleChangeKeyword, cars }) {
  const isInspeksi = window.location.pathname.includes('inspeksi')
  const { push } = useHistory()
  const { state } = useLocation()
  const [openModal, setOpenModal] = useState(state?.isModalOpen ? true : false)
  const handleSelect = (id) => {
    handleSelectedCar(id)
    setOpenModal(false)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
    handleChangeKeyword('')
  }

  return (
    <>
      <CardSectionHeader headerTitle={'Pilih Mobil'}>
        <div className="grid grid-cols-1 md:grid-cols-[316px_1fr] gap-6">
          {selectedCar.id === '' ? (
            <div
              className="flex items-center justify-center text-gray-500 text-sm-medium border border-solid border-gray-200 rounded-md py-16 bg-gray-50 cursor-pointer"
              onClick={() => setOpenModal(true)}>
              Pilih Mobil
            </div>
          ) : (
            <>
              <div>
                <BadgeItem
                  type={selectedCar.unit_ownership === 'Mobil Perusahaan' ? 'base' : 'plain'}
                  className="max-w-fit">
                  {selectedCar?.unit_ownership}
                </BadgeItem>
                <p className="text-lg-medium text-gray-900 mt-4">{selectedCar?.unit_name}</p>
              </div>
              <div className="flex justify-end">
                <CustomButton type="plain" label="Edit Mobil" onClick={() => setOpenModal(true)} />
              </div>
            </>
          )}
        </div>
      </CardSectionHeader>
      <ModalLayout
        openModal={openModal}
        headerTitle={`Pilih Mobil`}
        onCancel={() => handleCloseModal()}
        className="w-[700px]">
        <Input
          size="medium"
          className="text-md-normal text-gray-500 w-full mb-6 px-[14px] py-[10px] rounded-lg"
          prefix={<MagnifyingIcon className={'mr-1'} />}
          value={keyword}
          onChange={(e) => handleChangeKeyword(e.target.value)}
          placeholder="Cari Mobil"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {cars.map((car, indx) => (
            <div
              className="p-6 border border-solid border-gray-200 rounded-[4px] cursor-pointer"
              onClick={() => handleSelect(car.id)}
              key={indx}>
              <BadgeItem
                type={
                  car?.unit_ownership === 'Mobil Pribadi'
                    ? 'plain'
                    : car?.unit_ownership === 'Mobil Perusahaan'
                      ? 'purple'
                      : 'primary'
                }
                className="max-w-fit">
                {car?.unit_ownership}
              </BadgeItem>
              <p className="text-lg-medium text-gray-900 mt-4">{car?.unit_name}</p>
              <p className="text-xs-reguler text-gray-900 mt-2">{car?.police_number}</p>
            </div>
          ))}
          <div className="flex min-h-[144px] justify-center items-center">
            <CustomButton
              onClick={() =>
                push({
                  pathname: '/dashboard/add-unit',
                  state: {
                    navBack: window.location.pathname.includes('inspeksi')
                      ? 'inspeksi'
                      : window.location.pathname.includes('servis')
                        ? 'servis'
                        : false
                  }
                })
              }
              type="secondary"
              label={
                <div className="flex items-center gap-3 justify-center stroke-primary-500">
                  <PlusIcon /> Tambah Mobil
                </div>
              }
            />
          </div>
        </div>
      </ModalLayout>
    </>
  )
}
export default CardSelectCar
