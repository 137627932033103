import { Divider } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'

function CardDetailPayment({ data, total }) {
  return (
    <CardSectionHeader headerTitle="Rincian Pembayaran">
      <div className="grid grid-cols-1 gap-3">
        {/* {console.log('data', data)} */}
        {data?.map((item, index) => (
          <div className="flex justify-between text-md-regular w-full text-gray-600" key={index}>
            <p>{item.label}</p>
            <p
              className={`
                w-[125px] ml-5 text-right flex-shrink-0
                ${item.highlight ? 'text-primary-400 whitespace-nowrap' : 'whitespace-nowrap'}
              `}>{`${item.addOn} ${item.value === undefined ? '-' : item.value}`}</p>
          </div>
        ))}
      </div>
      <Divider className="m-0 my-5" />
      <div className="flex justify-between text-md-regular">
        <p className="text-gray-900 ">Total biaya</p>
        <p className="text-warning-500 text-md-semibold ml-5 text-left flex-shrink-0">
          Rp. {total}
        </p>
      </div>
    </CardSectionHeader>
  )
}

export default CardDetailPayment
