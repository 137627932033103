import { SampleOil, ThumbsUpIcon } from 'assets'
import BadgeItem from 'components/ui/badge-item'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'

const PartItem = ({ selected, handleSelected, sparepart }) => {
  return (
    <li
      onClick={handleSelected}
      className={`flex gap-2 rounded-[4px] relative border border-solid cursor-pointer p-4 ${
        selected ? 'border-primary-300 bg-primary-50' : ' bg-white border-gray-200'
      }`}>
      {/* <img src={SampleOil} className="w-8 object-contain md:w-14" alt="oli" /> */}
      <div className="flex flex-col gap-2">
        <p className="text-sm-medium md:text-md-medium text-gray-900">{sparepart?.name}</p>
        <p className="text-lg-medium md:display-xs-medium text-gray-700">
          Rp {onRupiah(sparepart?.price_total)}{' '}
        </p>
      </div>
      {sparepart?.is_recommended ? (
        <BadgeItem className="absolute right-3">
          <ThumbsUpIcon className={'lg:hidden block w-3 h-3 md:w-5 md:h-5 stroke-warning-700'} />
          <p className="hidden lg:block">Recommendation</p>
        </BadgeItem>
      ) : null}
    </li>
  )
}

export default PartItem
