import { Col } from 'antd'
import CardFormPesanMobil from 'components/ui/card/card-form-pesan-mobil'
// import CardInfoTestDrive from 'components/ui/card/card-info-test-drive'
import CardSelectCar from 'components/ui/card/card-select-car'
import CustomButton from 'components/ui/custom-button'
function FormDataInspeksi({
  units,
  state,
  errors,
  keyword,
  handleChange,
  handleCancel,
  handleClick,
  selectedCar,
  handleChangeKeyword,
  handleSelectedCar,
  provinces,
  subdistricts,
  cities,
  handleChangeLocation
}) {
  return (
    // <Col span={22} md={20} className="mx-auto grid grid-cols-1 md:grid-cols-[1fr_389px] gap-6">
    <Col span={22} md={20} className="mx-auto grid grid-cols-1 gap-6">
      {/* left content */}
      <div>
        <div className="grid grid-cols-1 gap-6">
          {/* Pilih Mobil */}
          <CardSelectCar
            selectedCar={selectedCar}
            handleSelectedCar={handleSelectedCar}
            cars={units}
            keyword={keyword}
            handleChangeKeyword={handleChangeKeyword}
          />
          {/* isi Data Diri */}
          <CardFormPesanMobil
            handleChangeLocation={handleChangeLocation}
            provinces={provinces}
            cities={cities}
            subdistricts={subdistricts}
            data={state}
            errors={errors}
            handleChange={handleChange}
          />
          {/* Button */}
          <div className="flex gap-3 justify-end items-center">
            <CustomButton type="secondary" label={'Batal'} onClick={handleCancel} />
            <CustomButton label={'Selanjutnya'} onClick={handleClick} />
          </div>
        </div>
      </div>
      {/* right content */}
      {/* <div className='hidden md:block'>
        <div className="grid grid-cols-1 gap-6">
          <CardInfoTestDrive />
        </div>
      </div> */}
    </Col>
  )
}

export default FormDataInspeksi
