import { AlertMarkBlackIcon, AlertMarkIcon } from 'assets'
import React from 'react'

function NotificationItem({ id, read, title, vehicle, time, handleClickNotification, isPage }) {
  return (
    <div className="grid grid-cols-[30px_1fr] cursor-pointer hover:bg-primary-50 px-6 py-3" onClick={() => handleClickNotification(id)}>
      {read ? <AlertMarkBlackIcon className={'mt-1'} /> : <AlertMarkIcon className={'mt-1'} />}
      <div className="flex flex-col">
        <p className={`text-md-medium ${read ? `text-gray-700` : `text-primary-500`}`}>
          {title.length > 36 ? title.substring(0, 36) + '...' : title}
        </p>
        <div className="grid grid-cols-[70%_30%]">
          <p className="text-sm-regular text-gray-500">{vehicle}</p>
          <p className={`${isPage ? 'text-right' : ''} text-sm-regular text-gray-500`}>{time}</p>
        </div>
      </div>
    </div>
  )
}

export default NotificationItem
