import { Col, Divider, Row, Spin, Steps } from 'antd'
import {
  FormCheckoutInspeksi,
  FormDataInspeksi,
  FormJadwalkanInspeksiContent
} from 'features/inspeksi'
import {
  getAdminPrice,
  getCities,
  getInspectionPrice,
  getProvinces,
  getRangePrice,
  getSubdistricts,
  getTaxPrice,
  getUnitList,
  getWorkshops,
  postInspection
} from 'features/inspeksi/service'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import useValidation from 'lib/useValidation'
import MobileStep from 'components/ui/mobile-step'
import { useCreditCard, usePayment, useScheduleService } from 'hooks'
import ModalCreditCards from 'components/ui/modal/modal-credit-cards'
import ModalAddCreditCard from 'components/ui/modal/modal-add-credit-card'
import CustomButton from 'components/ui/custom-button'
import { onRupiah } from 'utils/formatCurrency'
import { ModalLayout } from 'layouts'
import { imageCreditCards } from 'data/image-credit-card'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage } from 'utils/toastMessage'
import { useCallback } from 'react'
import { debounce } from 'lodash'
import { getPaymentMethod } from 'utils/getPaymentMethod'

const items = [
  { key: 0, title: 'Data Inspeksi' },
  { key: 1, title: 'Jadwal Inspeksi' },
  { key: 2, title: 'Checkout' }
]
const initialError = {
  nama: false,
  phone: false,
  email: false,
  province: false,
  city: false,
  subdistrict: false,
  postal_code: false,
  alamat: false
}

const initialPrice = {
  tax: 0,
  inspection: 0,
  admin_fee: 0
}

const initialHomeState = {
  subdistrict: {},
  city: {},
  province: {},
  range_price: {},
  postal_code: '',
  address: '',
  loc: {}
}

const initialDataCar = {
  id: '',
  unit_name: '',
  unit_ownership: ''
}

function FormInspeksiMobilPage() {
  const { push, replace, location, goBack } = useHistory()
  const send = location.state
  if (send === undefined) {
    return replace('/inspeksi')
  }
  const token = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))
  const [state, setState] = useState(
    location.state.owner === undefined
      ? {
          nama: user?.name || user?.fullname || '',
          phone: user?.phone_number || '',
          email: user?.email || '',
          alamat: user?.address || '',
          province: {
            label: user?.province_name || null,
            value: user?.province_id || null
          },
          city: {
            label: user?.city_name || null,
            value: user?.city_id || null
          },
          subdistrict: {
            label: user?.sub_district_name || null,
            value: user?.sub_district_id || null
          },
          postal_code: user?.postal_code || '',
          workshop: {}
        }
      : {
          ...location?.state?.owner,
          province: {
            label: user?.province_name || null,
            value: user?.province_id || null
          },
          city: {
            label: user?.city_name || null,
            value: user?.city_id || null
          },
          subdistrict: {
            label: user?.sub_district_name || null,
            value: user?.sub_district_id || null
          },
          postal_code: user?.postal_code || '',
          workshop: {}
        }
  )
  const [price, setPrice] = useState({ ...initialPrice })
  const [errors, setErrors] = useState({ ...initialError })
  const [homeState, setHomeState] = useState({
    province: {
      label: user?.province_name || null,
      value: user?.province_id || null
    },
    city: {
      label: user?.city_name || null,
      value: user?.city_id || null
    },
    subdistrict: {
      label: user?.sub_district_name || null,
      value: user?.sub_district_id || null
    },
    postal_code: user?.postal_code || '',
    address: user?.address || '',
    range_price: {},
    loc: {}
  })
  const [currentContent, setCurrentContent] = useState(0)
  const [isShowRoom, setIsShowRoom] = useState(true)
  const [selectedCar, setSelectedCar] = useState(
    location.state.unit === undefined ? { ...initialDataCar } : { ...location?.state?.unit }
  )
  // console.log('selectedCar', selectedCar)
  // console.log('price', price)
  const { times, schedule, handleChangeSchedule, scheduleIsValid, resetSchedule } =
    useScheduleService('inspeksi')
  const [modalOpen, setModalOpen] = useState(false)
  const [keyword, setKeyword] = useState('')

  // state for list
  const [units, setUnits] = useState([])
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [subdistricts, setSubdistricts] = useState([])
  const [workshops, setWorkshops] = useState([])
  const [rangePrice, setRangePRice] = useState([])
  const taxPrice =
    ((price?.inspection || 0) +
      (price?.bookingPrice || 0) +
      price?.admin_fee +
      (homeState?.range_price?.price || 0)) *
      (price.tax / 100) || 0
  const totalPrice =
    homeState?.range_price?.price !== undefined
      ? price?.inspection + price?.admin_fee + taxPrice + homeState?.range_price?.price
      : (price.inspection || 0) + (price.bookingPrice || 0) + price?.admin_fee + taxPrice

  const { nameValidation, phonenumberValidation, emailValidation, addressValidation } =
    useValidation()
  const {
    creditCards,
    newCreditCard,
    selectedCreditCard,
    modalCardList,
    modalAddCard,
    isAddCardLoading,
    errorsAddCard,
    handleChangeCreditCard,
    submitAddCard,
    openModalCardList,
    closeModalCardList,
    openModalAddCard,
    closeModalAddCard,
    handleSelectedCreditCard
  } = useCreditCard(true)
  const { selectedPayment, handleSelectedPayment, getPaymentObject, handleCreditCardToken } =
    usePayment()

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    []
  )
  const handleChangeKeyword = (value) => {
    setKeyword(value)

    return debouncedChangeHandler()
  }

  // start of fectching
  const { refetch } = useQuery({
    queryKey: 'getUnits',
    queryFn: () => getUnitList(keyword),
    onSuccess: (data) => {
      const newData = data?.filter((car) =>
        (car?.company_admin_id !== user?.id && car?.company_id !== 0) ||
        car?.company_admin_id === user?.id
          ? !car?.is_booked && !car?.is_in_catalog && !car?.is_in_service && !car?.is_inspected
          : !car?.is_booked &&
            !car?.is_in_catalog &&
            !car?.is_in_service &&
            !car?.is_inspected &&
            car?.is_service_paid
      )
      setUnits(newData)
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: Boolean(debouncedChangeHandler)
  })
  useQuery({
    queryKey: 'getWorkshops',
    queryFn: () => getWorkshops(),
    onSuccess: (data) => {
      setWorkshops(data)
      if (data.length > 0) {
        setState((prev) => ({
          ...prev,
          workshop: data[0]
        }))
      }
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: 'getRangePrice',
    queryFn: () => getRangePrice(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: `${el.start_distance} km - ${el.end_distance} km`,
        value: el.id,
        price: el.price
      }))
      setRangePRice(newData)
    },
    staleTime: Infinity
  })

  // Address Personal Data
  useQuery({
    queryKey: ['getCities', state?.province?.value],
    queryFn: () => getCities(state?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    staleTime: 0,
    enabled: state?.province?.value !== undefined && currentContent === 0,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: ['getSubdistricts', state?.city?.value],
    queryFn: () => getSubdistricts(state?.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts(newData)
    },
    staleTime: 0,
    enabled: state?.city?.value !== undefined && currentContent === 0,
    refetchOnWindowFocus: false
  })

  useQuery({
    queryKey: 'getProvinces',
    queryFn: () => getProvinces(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setProvinces(newData)
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: ['getCities', homeState?.province?.value],
    queryFn: () => getCities(homeState?.province?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setCities(newData)
    },
    staleTime: 0,
    enabled: homeState?.province?.value !== undefined && currentContent === 1,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: ['getSubdistricts', homeState?.city?.value],
    queryFn: () => getSubdistricts(homeState?.city?.value),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setSubdistricts(newData)
    },
    staleTime: 0,
    enabled: homeState?.city?.value !== undefined && currentContent === 1,
    refetchOnWindowFocus: false
  })
  useQuery({
    queryKey: 'getInspectionPrice',
    queryFn: () => getInspectionPrice(),
    onSuccess: (data) => {
      setPrice((prev) => ({
        ...prev,
        inspection: data.price
      }))
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getAdminPrice',
    queryFn: () => getAdminPrice(),
    onSuccess: (data) => {
      setPrice((prev) => ({
        ...prev,
        admin_fee: data.price
      }))
    },
    staleTime: Infinity
  })
  useQuery({
    queryKey: 'getTaxPrice',
    queryFn: () => getTaxPrice(),
    onSuccess: (data) => {
      setPrice((prev) => ({
        ...prev,
        tax: data.percentage
      }))
    },
    staleTime: Infinity
  })

  const postData = useMutation(postInspection, {
    onSuccess: (datas) => {
      // console.log(datas)
      const { creditCard, paymentType } = getPaymentMethod(
        datas?.transactionDetail?.payment_method,
        datas?.transactionDetail?.payment_details,
        creditCards
      )
      if (
        datas?.transactionDetail?.payment_details?.card?.authorization_url !== '' &&
        datas?.transactionDetail?.payment_details?.card?.authorization_url !== undefined
      ) {
        window.open(datas?.transactionDetail?.payment_details?.card?.authorization_url)
      }
      replace('/inspeksi/form/payment', {
        paymentDetails: {
          payment_method: datas?.transactionDetail?.payment_method,
          payment_status: datas?.transactionDetail?.payment_status,
          payment_details: datas?.transactionDetail?.payment_details
        },
        totalPrice: totalPrice,
        price: {
          tax: price.tax.toFixed(),
          inspection: price.inspection,
          admin_fee: price.admin_fee
        },
        taxPrice: taxPrice,
        range_price: homeState?.range_price?.price
          ? parseInt(homeState?.range_price?.price)
          : undefined,
        selectedPayment: paymentType,
        selectedCreditCard: creditCard,
        transactionId: datas?.transaction_id,
        serviceType: 'inspeksi',
        id: datas?.id
      })
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })

  // end of fetching

  function handleSelectedCard(card) {
    handleCreditCardToken(card.id)
    handleSelectedCreditCard(card)
    closeModalCardList()
    setModalOpen(true)
  }
  const handleConfirmation = () => {
    return selectedPayment !== null
      ? selectedPayment?.id === 'card'
        ? openModalCardList()
        : setModalOpen(true)
      : showErrorMessage('Silahkan pilih metode pembayaran terlebih dahulu')
  }

  const handleSelectedCar = (id) => {
    const found = units.find((el) => el.id === id)
    setSelectedCar(found)
  }

  const handleCheckout = () => {
    if (!scheduleIsValid()) {
      showErrorMessage(
        `Silahkan memilih ulang jam inspeksi ${isShowRoom ? 'di Showroom' : 'di Rumah'} Anda`
      )
      setCurrentContent(1)
    }
    // push('/inspeksi/form/result')
    const dataSend = {
      unit_id: selectedCar.id,
      user_id: 5,
      unit_name: selectedCar.unit_name,
      unit_police_number: selectedCar?.police_number || '-',
      workshop_id: state.workshop.id,
      workshop_name: state.workshop.name,
      schedule_id: schedule.hours.id,
      is_workshop: isShowRoom,
      inspection_date: moment(schedule.date).format('DD/MM/YYYY'),
      owner_name: state.nama,
      owner_email: state.email,
      owner_phone_number: state.phone,
      owner_address: state.alamat,
      owner_province_id: state?.province?.value,
      owner_city_id: state?.city?.value,
      owner_sub_district_id: state?.subdistrict?.value,
      owner_postal_code: state?.postal_code,
      payment: getPaymentObject()
    }
    if (isShowRoom) {
      // dataSend.inspection_date = moment(schedule.date).format('DD/MM/YYYY')
      dataSend.owner_name = state.nama
      dataSend.owner_email = state.email
      dataSend.owner_phone_number = state.phone
      dataSend.owner_address = state.alamat
      // dataSend.price = parseInt(state.price)
    } else {
      dataSend.lattitude = String(homeState?.loc?.lat)
      dataSend.longitude = String(homeState?.loc?.lng)
      dataSend.postal_code = homeState.postal_code
      dataSend.owner_address = homeState.address
      dataSend.distance_price_id = homeState?.range_price?.value
      dataSend.province_id = homeState?.province?.value
      dataSend.province_name = homeState?.province?.label
      dataSend.city_id = homeState?.city?.value
      dataSend.city_name = homeState?.city?.label
      dataSend.sub_district_id = homeState?.subdistrict?.value
      dataSend.sub_district_name = homeState?.subdistrict?.label
      // dataSend.price = parseInt(state.price) + parseInt(homeState?.range_price?.price)
    }
    const args = {
      token: token,
      body: dataSend
    }
    postData.mutate(args)
  }

  const handleNextPage = () => {
    if (selectedCar.id === '') {
      return showErrorMessage('Mohon pilih mobil terlebih dahulu')
    }

    for (const [key] of Object.entries(errors)) {
      if (state[key] == '' || (typeof state[key] === 'object' && state[key].value == null)) {
        return showErrorMessage(`Mohon isi ${nameToString[key]}`)
      }
    }
    setCurrentContent(1)
  }

  const handleNextPageCheckout = () => {
    // validate
    if (isShowRoom) {
      if (schedule.date === null) {
        showErrorMessage('Mohon isi Tanggal Inspeksi')
      } else if (schedule?.hours?.label === undefined) {
        showErrorMessage('Mohon isi Jam Inspeksi')
      } else {
        setCurrentContent(2)
      }
    } else {
      let dataObject = { ...homeState }
      delete dataObject.loc

      for (const [key] of Object.entries(dataObject)) {
        if (
          homeState[key] == '' ||
          (typeof homeState[key] === 'object' && homeState[key].value == null)
        ) {
          return showErrorMessage(`Mohon isi ${nameToString[key]}`)
        }
      }
      if (schedule?.date === null) {
        showErrorMessage('Mohon isi Tanggal Inspeksi')
      } else if (schedule?.hours?.label === undefined) {
        showErrorMessage('Mohon isi Jam Inspeksi')
      } else setCurrentContent(2)
    }
  }

  useEffect(() => {
    scrollToTop()
  }, [currentContent])

  const scrollToTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    let err = ''

    switch (name) {
      case 'nama':
        err = nameValidation(value)
        break
      case 'phone':
        err = phonenumberValidation(value)
        break
      case 'email':
        err = emailValidation(value)
        break
      case 'alamat':
        err = addressValidation(value)
        break
      default:
        err = ''
    }

    setErrors((prev) => ({
      ...prev,
      [name]: err
    }))
    setState((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeHomeData = (name, value) => {
    setHomeState((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeLocation = (type, name, value) => {
    let newValue = {}
    if (name === 'province') {
      setCities([])
      setSubdistricts([])
      newValue = {
        subdistrict: {},
        city: {},
        province: value
      }
    } else if (name === 'city') {
      setSubdistricts([])
      newValue = {
        city: value,
        subdistrict: {}
      }
    } else {
      newValue = {
        subdistrict: value
      }
    }
    if (type === 'pickup') {
      newValue = { ...homeState, ...newValue }
      setHomeState(newValue)
    } else if (type === 'personal') {
      newValue = { ...state, ...newValue }
      setState(newValue)
    }
  }

  const handleChangeActiveWorkshop = (value) => {
    setState((prev) => ({
      ...prev,
      workshop: value
    }))
  }

  useEffect(() => {
    resetSchedule()
  }, [isShowRoom])

  return (
    <Row className="py-10 gap-6 ">
      {/* Header Title */}
      <div className="text-center w-full display-sm-semibold text-gray-900">Inspeksi Mobil</div>
      {/* Steps */}
      <Col span={21} className="hidden md:grid grid-cols-1 w-full mx-auto">
        <Steps labelPlacement="vertical" current={currentContent} items={items} />
      </Col>
      <div className="md:hidden mx-auto ">
        <MobileStep current={currentContent} items={items} />
      </div>
      {/* Content */}
      {currentContent === 2 ? (
        <FormCheckoutInspeksi
          isLoading={postData?.isLoading}
          selectedCar={selectedCar}
          schedule={schedule}
          state={state}
          price={price}
          taxPrice={taxPrice}
          totalPrice={totalPrice}
          homeState={homeState}
          handleCancel={() => setCurrentContent(1)}
          selectedPayment={selectedPayment}
          handleSelectedPayment={handleSelectedPayment}
          isShowRoom={isShowRoom}
          handleConfirmation={handleConfirmation}
        />
      ) : currentContent === 1 ? (
        <FormJadwalkanInspeksiContent
          price={price}
          taxPrice={taxPrice}
          totalPrice={totalPrice}
          handleCancel={() => setCurrentContent(0)}
          handleClick={handleNextPageCheckout}
          isShowRoom={isShowRoom}
          setIsShowRoom={setIsShowRoom}
          state={state}
          homeState={homeState}
          schedule={schedule}
          times={times}
          provinces={provinces}
          cities={cities}
          subdistricts={subdistricts}
          workshops={workshops}
          rangePrice={rangePrice}
          handleChangeActiveWorkshop={handleChangeActiveWorkshop}
          handleChangeData={handleChange}
          handleChangeHomeData={handleChangeHomeData}
          handleChangeLocation={handleChangeLocation.bind(this, 'pickup')}
          handleChangeSchedule={handleChangeSchedule}
        />
      ) : (
        <FormDataInspeksi
          handleChangeLocation={handleChangeLocation.bind(this, 'personal')}
          provinces={provinces}
          cities={cities}
          subdistricts={subdistricts}
          state={state}
          units={units}
          errors={errors}
          keyword={keyword}
          handleChangeKeyword={handleChangeKeyword}
          handleChange={handleChange}
          handleCancel={() => goBack()}
          handleClick={handleNextPage}
          selectedCar={selectedCar}
          handleSelectedCar={handleSelectedCar}
        />
      )}
      <ModalCreditCards
        selectedCreditCard={selectedCreditCard}
        openModal={modalCardList}
        onCancel={closeModalCardList}
        creditCards={creditCards}
        openModalAddCard={openModalAddCard}
        handleSelectedCard={handleSelectedCard}
      />
      <ModalAddCreditCard
        errors={errorsAddCard}
        creditCard={newCreditCard}
        openModal={modalAddCard}
        submitAddCard={submitAddCard}
        isAddCardLoading={isAddCardLoading}
        onCancel={closeModalAddCard}
        handleChange={handleChangeCreditCard}
      />
      {/* modal checkout */}
      <ModalLayout
        openModal={modalOpen}
        headerTitle={`Konfirmasi Inspeksi Mobil`}
        className="w-full max-w-md"
        onCancel={() => (postData?.isLoading ? {} : setModalOpen(false))}>
        <div className="grid grid-cols-1 gap-4 w-full md:w-[400px]">
          <div className="border border-solid border-gray-200 rounded-[4px] p-4">
            <p className="text-md-medium text-gray-900">{selectedCar?.unit_name}</p>
          </div>
          {/* Informasi Inspeksi & Metode Pembayaran */}
          <div className="grid grid-cols-1 gap-2 text-md-medium text-gray-700">
            <p>Infomasi Inspeksi:</p>
            <div>
              <p className="text-xs-regular text-gray-700">Tempat</p>
              <p className="text-sm-regular">{state?.workshop?.name}</p>
            </div>
            <div>
              <p className="text-xs-regular text-gray-700">Tanggal</p>
              <p className="text-sm-regular">{moment(schedule.date).format('DD MMM YYYY')}</p>
            </div>
            <div>
              <p className="text-xs-regular text-gray-700">Waktu</p>
              <p className="text-sm-regular">{schedule?.hours?.time}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <p className="text-md-medium text-gray-900">Metode Pembayaran</p>
            {selectedPayment?.id === 'card' ? (
              <div className="p-4 border border-solid rounded-[4px] cursor-pointer flex justify-between items-center border-gray-200 bg-gray-50">
                <div>
                  <p className="text-xs-regular text-gray-500">
                    {selectedCreditCard?.cardholder_name}
                  </p>
                  <p className="text-lg-medium text-gray-900">
                    {selectedCreditCard?.masked_number.match(/.{1,4}/g).join(' ')}
                  </p>
                </div>
                <img
                  src={imageCreditCards[selectedCreditCard?.network]?.image}
                  alt="Logo Credit Card"
                />
              </div>
            ) : (
              <div className=" p-4 flex justify-start gap-4 items-center bg-gray-50 rounded-[4px]">
                <img src={selectedPayment?.image} alt="bankLogo" className="w-14 object-contain" />
                <p className="text-md-medium text-gray-700">{selectedPayment?.title}</p>
              </div>
            )}
          </div>
          <Divider className="m-0" />
          {/* Rincian Pembayaran */}
          <div className="flex justify-between items-center pb-1">
            <p className="text-md-regular text-gray-500">
              Biaya Inspeksi {isShowRoom ? '' : 'di Rumah'}
            </p>
            <p>Rp. {onRupiah(price?.inspection)}</p>
          </div>
          {!isShowRoom && (
            <div className="flex justify-between items-center pb-1">
              <p className="text-md-regular text-gray-500">Biaya Jarak</p>
              <p>
                Rp.{' '}
                {homeState?.range_price?.price !== undefined
                  ? onRupiah(homeState?.range_price?.price)
                  : '-'}
              </p>
            </div>
          )}
          {price.admin_fee !== 0 && (
            <div className="flex justify-between items-center pb-1">
              <p className="text-md-regular text-gray-500">Biaya Admin</p>
              <p>Rp. {onRupiah(price.admin_fee)}</p>
            </div>
          )}
          <div className="flex justify-between items-center pb-1">
            <p className="text-md-regular text-gray-500">Pajak {price.tax}%</p>
            <p>Rp. {onRupiah(taxPrice)}</p>
          </div>
          <div className="flex justify-between items-center border-t pt-1">
            <p className="text-md-regular text-gray-500">Total Biaya</p>
            <p>Rp. {onRupiah(totalPrice)}</p>
          </div>
          {/* Button Container */}
          <div className="flex justify-end items-center gap-2">
            <Spin spinning={postData?.isLoading}>
              <CustomButton onClick={() => setModalOpen(false)} type="plain" label={'Batal'} />
            </Spin>
            <Spin spinning={postData?.isLoading}>
              <CustomButton onClick={handleCheckout} label={'Konfirmasi'} />
            </Spin>
          </div>
        </div>
      </ModalLayout>
    </Row>
  )
}

export default FormInspeksiMobilPage
