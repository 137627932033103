import { Divider } from 'antd'
import CardSectionHeader from 'components/ui/card-section-header'
import { capitalize } from 'lodash'
import React from 'react'
import moment from 'moment'

const PenjadwalanCard = ({ serviceName, confirmed, data }) => {
  return (
    <CardSectionHeader
      headerTitle={
        confirmed ? `${capitalize(serviceName)} Dijadwalkan` : `Penjadwalan ${serviceName}`
      }>
      <div className="flex flex-col gap-3">
        <p className="text-md-regular text-gray-600">
          {confirmed
            ? `Jadwal ${serviceName} unit Anda telah dikonfirmasi oleh Admin`
            : 'Sedang menunggu konfirmasi admin..'}
        </p>
        <Divider className="m-0" />
        <div className="flex justify-between items-center">
          <>
            <p className="text-md-regular text-gray-600">
              {capitalize(serviceName)} dimulai pada:{' '}
            </p>
            <p className="text-md-medium text-gray-700">
              {moment(data?.date).format('DD MMM YYYY')} -{' '}
              {data?.start_time} WIB
            </p>
          </>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default PenjadwalanCard
