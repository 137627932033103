const ServisUmumIcon = ({ className }) => {
  return (
    <svg
      width="79"
      className={className}
      height="82"
      viewBox="0 0 79 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.8712 56.6543C58.2357 56.6543 59.6719 56.4389 60.9646 56.0798L62.6163 58.8805C63.2626 59.9577 64.627 60.3168 65.7042 59.6705L68.7922 57.8751C69.8694 57.2288 70.2285 55.8644 69.5822 54.7872L67.9305 51.9865C69.7258 50.1193 71.162 47.8213 71.8084 45.0924H75.04C76.3326 45.0924 77.338 44.087 77.2662 42.7943V39.2037C77.2662 37.911 76.2608 36.9057 74.9682 36.9775H71.7366C71.0184 34.3922 69.654 32.0224 67.715 30.1552L69.2949 27.2827C69.9412 26.2055 69.5104 24.7692 68.4332 24.1947L65.2734 22.3994C64.1962 21.7531 62.7599 22.1839 62.1854 23.2611L60.6055 26.0619C59.2411 25.7028 57.9484 25.5592 56.584 25.5592"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.8711 46.961C58.7382 46.961 60.6054 46.0274 61.7544 44.3757C63.5497 41.6468 62.8316 38.0561 60.1745 36.189C59.1691 35.5427 58.0201 35.1836 56.8711 35.1836"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.9549 52.6338V19.8151C42.6515 17.9479 40.3535 14.3573 40.3535 10.2639C40.3535 6.386 42.3643 3.01077 45.4523 1.07181V3.51347V6.31418C45.4523 7.82226 46.2422 9.18671 47.5348 9.90485C49.9047 11.2693 52.7772 11.2693 55.1471 9.90485C56.4397 9.18671 57.2296 7.82226 57.2296 6.31418V3.44165V1C60.2458 2.93896 62.3284 6.31418 62.3284 10.1921C62.3284 14.2855 60.1022 17.8761 56.7269 19.7433V52.5619V62.2567C60.0303 64.1239 62.3284 67.7145 62.3284 71.8079C62.3284 75.6858 60.3176 79.061 57.2296 81V78.5584V75.7576C57.2296 74.2496 56.4397 72.8851 55.1471 72.167C52.7772 70.8025 49.9047 70.8025 47.5348 72.167C46.2422 72.8851 45.4523 74.2496 45.4523 75.7576V78.5584V81C42.4361 79.061 40.3535 75.6858 40.3535 71.8079C40.3535 67.7145 42.5797 64.1239 45.9549 62.2567V52.6338Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.4259 39.0623H31.0902C29.5103 39.0623 27.9304 38.4877 26.7095 37.4105L23.6934 34.6816"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5566 45.9551L20.0306 47.1041C22.185 47.3913 23.7649 49.2585 23.7649 51.4129V53.5673H11.5566"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.02148 40.7852V60.9647"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.02153 40.7843L1.64634 37.1218C1.21546 36.7627 1 36.26 1 35.6855V31.664C1 30.5868 1.86177 29.725 2.93897 29.725H10.623L17.3016 17.0141C18.5943 15.2905 20.6769 14.2852 22.8313 14.2852H36.5476"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3734 60.9648V61.683C17.3734 65.0582 14.6445 67.8589 11.1975 67.8589C7.82223 67.8589 5.02148 65.13 5.02148 61.683V60.9648"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.4256 60.9648H11.5566"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.1836 53.5664H39.9951"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6602 29.7246H40.784"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3905 19.026L16.6562 26.1355H36.6203V17.877H22.904C21.8986 17.877 20.9651 18.3078 20.3905 19.026Z"
        fill="#E12B25"
      />
    </svg>
  )
}

export default ServisUmumIcon
