import React from 'react'
import { ImageAboutUs1, ImageAboutUs2, ImageAboutUs3 } from 'features/landing-page/assets/images'
import { Col } from 'antd'

const aboutData = [
  {
    title: 'Apa itu BirdMobil?',
    description:
      'Salah satu layanan ANDAL dari Bluebird Group yang memberikan pengalaman baru untuk menjual, membeli dan merawat mobil dalam satu tempat',
    image: <img className="object-cover w-full h-full" src={ImageAboutUs2}></img>
  },
  {
    title: 'Terpercaya',
    description:
      'Lebih dari 50 tahun Bluebird Group berdiri untuk memberikan layanan terpercaya bagi pelanggan, begitupula untuk BirdMobil yang memiliki komitmen yang sama untuk pelanggan',
    image: <img className="object-cover w-full h-full" src={ImageAboutUs1}></img>
  },
  {
    title: 'Transparansi',
    description:
      'Dalam memberikan layanan terbaik, BirdMobil selalu mengutamakan Transparansi saat penjualan, inspeksi dan perawatan mobil Anda',
    image: <img className="object-cover w-full h-full" src={ImageAboutUs3}></img>
  }
]

const aboutDataReverse = [
  {
    title: 'Apa itu BirdMobil?',
    description:
      'Salah satu layanan ANDAL dari Bluebird Group yang memberikan pengalaman baru untuk menjual, membeli dan merawat mobil dalam satu tempat'
  },
  {
    image: <img className="object-cover w-full h-full" src={ImageAboutUs1}></img>
  },
  {
    title: 'Terpercaya',
    description:
      'Lebih dari 50 tahun Bluebird Group berdiri untuk memberikan layanan terpercaya bagi pelanggan, begitupula untuk BirdMobil yang memiliki komitmen yang sama untuk pelanggan'
  },
  {
    image: <img className="object-cover w-full h-full" src={ImageAboutUs2}></img>
  },
  {
    title: 'Transparansi',
    description:
      'Dalam memberikan layanan terbaik, BirdMobil selalu mengutamakan Transparansi saat penjualan, inspeksi dan perawatan mobil Anda'
  },
  {
    image: <img className="object-cover w-full h-full" src={ImageAboutUs3}></img>
  }
]

function AboutUsSection() {
  return (
    <Col span={24} lg={{ order: 3 }} className="bg-white py-9 lg:py-20">
      <Col span={22} lg={{ span: 20 }} className="mx-auto hidden lg:flex grid-cols-3 gap-6">
        {aboutData.map((data, key) => (
          <div key={key} className="flex flex-col gap-2">
            <div className="max-h-[200px] w-full">{key % 2 == 1 && data?.image}</div>
            <p className="text-gray-900 display-xs-semibold md:display-sm-semibold">{data.title}</p>
            <p className="text-sm-regular md:text-md-regular">{data.description}</p>
            <div className="max-h-[200px] w-full">{key % 2 == 0 && data?.image}</div>
          </div>
        ))}
      </Col>
      <Col
        span={22}
        lg={{ span: 20 }}
        className="mx-auto grid-cols-1 md:grid-cols-2 grid lg:hidden gap-6">
        {aboutDataReverse.map((data, key) =>
          data?.image ? (
            <div key={key} className="w-full">
              {data.image}
            </div>
          ) : (
            <div key={key} className="h-full">
              <p className="text-gray-900 display-sm-semibold">{data.title}</p>
              <p className="text-md-regular">{data.description}</p>
            </div>
          )
        )}
      </Col>
    </Col>
  )
}

export default AboutUsSection
