import CardJadwalTestDrive from 'components/ui/card/card-jadwal-test-drive'
import CardLokasiInspeksi from 'components/ui/card/card-lokasi-inspeksi'
import CardPilihBengkel from 'components/ui/card/card-pilih-bengkel'
import { useEffect } from 'react'
function FormJadwalServis({
  isShowRoom,
  setIsShowRoom,
  state,
  homeState,
  schedule,
  provinces,
  cities,
  times,
  subdistricts,
  workshops,
  rangePrice,
  handleChangeLocation,
  handleChangeData,
  handleChangeHomeData,
  handleChangeSchedule,
  handleChangeActiveWorkshop,
  isPickup
}) {
  return (
    <div>
      <div className="grid grid-cols-1 gap-6">
        {/* lokasi */}
        <CardLokasiInspeksi
          isServis
          isPickup={isPickup}
          isShowRoom={isShowRoom}
          setIsShowRoom={(value) => {
            if (value) {
              setIsShowRoom(value)
              handleChangeActiveWorkshop(workshops?.length > 0 ? workshops[0] : { id: null })
            } else if (value === false) {
              let filteredWorkshop = workshops.filter((workshop) => workshop.is_pick_up_service)
              handleChangeActiveWorkshop(
                filteredWorkshop.length > 0 ? filteredWorkshop[0] : { id: null }
              )
              setIsShowRoom(value)
            }
          }}
          state={state}
          homeState={homeState}
          provinces={provinces}
          cities={cities}
          subdistricts={subdistricts}
          handleChangeLocation={handleChangeLocation}
          handleChangeData={handleChangeData}
          handleChangeHomeData={handleChangeHomeData}
          workshops={workshops}
          rangePrice={rangePrice}
          handleChangeActiveWorkshop={handleChangeActiveWorkshop}
        />
        {/* jadwal */}
        <CardJadwalTestDrive
          schedule={schedule}
          handleChangeSchedule={handleChangeSchedule}
          times={times}
          servis
        />
        {/* pilih bengkel */}
        {!isShowRoom && (
          <CardPilihBengkel
            workshops={workshops.filter((workshop) => workshop.is_pick_up_service)}
            data={state}
            handleChangeActiveWorkshop={handleChangeActiveWorkshop}
          />
        )}
      </div>
    </div>
  )
}

export default FormJadwalServis
