import { paymentList } from 'features/beli-mobil/data/paymentList'

export function getPaymentMethod(paymentMethod, paymentDetails, creditCards) {
  let paymentType = null
  let creditCard = null
  let paymentMethodList = paymentList.find((payment_method_list) => {
    return payment_method_list.id === paymentMethod
  })?.options

  if (paymentMethod === 'virtual_account') {
    paymentType = paymentMethodList.filter((paymentType) => {
      let provider = paymentType.id.split('-')[1]
      return provider === paymentDetails[paymentMethod]?.bank_code
    })[0]
  } else if (paymentMethod === 'qr_code') {
    paymentType = paymentMethodList[0]
  } else if (paymentMethod === 'card') {
    paymentType = paymentMethodList[0]
    creditCard = creditCards.find((card) => card?.id === paymentDetails?.card?.linked_card_id)
  }
  return { creditCard, paymentType }
}
