import { Modal, Col } from 'antd'
import CustomButton from './custom-button';

function ConfirmationModal({ contentDescription, onCancel, onClick, openModal, headerTitle, confirmLabel, className }) {
    return (
        <Modal
            title={<p className="text-xl-medium text-gray-900">{headerTitle}</p>}
            open={openModal}
            wrapClassName=""
            footer={null}
            width=""
            className={className || `max-w`}
            zIndex={999}
            onCancel={onCancel}
            maskClosable={false}
            centered
        >
            <Col span={24} className='gap-3'>
                <p className="text-sm-medium text-gray-500 mb-5">{contentDescription}</p>
                <div className="flex justify-end items-center gap-2">
                    <CustomButton type="plain" label={'Batal'} onClick={onCancel} />
                    <CustomButton label={confirmLabel} onClick={onClick} />
                </div>
            </Col>
        </Modal>
    )
}

export default ConfirmationModal;