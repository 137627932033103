import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getDetailCatalog } from '../service'
import { random } from 'lodash'

const useDetailCatalog = (idCatalog) => {
  const [car, setCar] = useState(null)

  useQuery({
    queryKey: ['getDetailCatalog', idCatalog],
    queryFn: () => getDetailCatalog(idCatalog),
    onSuccess: (data) => {
      const newObjThumbnail = {
        id: random(),
        photo_filename: data?.thumbnail_filename ? data?.thumbnail_filename : undefined
      }
      const newPhotoArray = [newObjThumbnail, ...data.photos]

      const newObjData = { ...data }
      newObjData.photos = newPhotoArray
      // console.log(newObjData)
      setCar(newObjData)
    },
    enabled: !!idCatalog,
    staleTime: 0
  })
  return { car }
}

export default useDetailCatalog
