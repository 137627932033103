import CustomButton from "components/ui/custom-button";
import ReportTypeTemplate from "./report-type-template";
import { EyeIcon } from "assets";
import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import { getReportUnits } from "../service";
import moment from 'moment'
import { useQuery } from "react-query";
import { Typography } from "antd";

const initialFilter = {
	offset: 0,
	limit: 5,
	start_date: '',
	end_date: ''
}

function ReportTypeCars() {
	const token = localStorage.getItem('token')
	const [reportData, setReportData] = useState(null)
	const [currentPage, setCurrentPage] = useState(1)
	const [filter, setFilter] = useState({ ...initialFilter })
	const { push } = useHistory();

	const debouncedChangeHandler = useCallback(
		debounce(() => {
			refetch()
		}, 500),
		[]
	)

	const handleChangeSelectedFilter = (name, val) => {
		setFilter(prev => ({
			...prev,
			[name]: val
		}))
		return debouncedChangeHandler()
	}
	const handleChangeFilterIncludeOffset = (name, value) => {
		setFilter(prev => ({
			...prev,
			[name]: value,
			offset: 0
		}))
		return debouncedChangeHandler()
	}
	const handleChangeDate = (val) => {
		if(val !== null){
			const startDate = moment(val[0]).format('DD/MM/YYYY')
			const endDate = moment(val[1]).format('DD/MM/YYYY')
			setFilter(prev => ({
				...prev,
				start_date: startDate,
				end_date: endDate
			}))
		}else{
			setFilter({...initialFilter})
		}
		return debouncedChangeHandler()
	}

	const onChangeNextPage = () => {
		if (currentPage !== Math.ceil(reportData?.meta?.total / filter.limit)) {
			handleChangeSelectedFilter('offset', currentPage * filter.limit)
			setCurrentPage(prev => prev + 1)
		}
	}
	const onChangePrevPage = () => {
		if (currentPage > 1) {
			handleChangeSelectedFilter('offset', filter.offset - filter.limit)
			setCurrentPage(prev => prev - 1)
		}
	}

	const navigateHandler = (path, id) => {
		if (id !== undefined || id !== null) {
			push({ pathname: path, state: { id: id } })
		} else {
			push(path)
		}
	}

	const { refetch } = useQuery(['getReportUnits', debouncedChangeHandler], {
		queryFn: () => {
			const dataSend = {
				token: token,
				params: {
					limit: filter.limit,
					offset: filter.offset,
					start_date: String(filter.start_date),
					end_date: String(filter.end_date),
				}
			}

			return getReportUnits(dataSend)
		},
		onSuccess: (data) => {
			setReportData(data)
		},
		enabled: Boolean(debouncedChangeHandler)
	})

	const columns = [
		{
			name: 'Nama Unit',
			selector: row => <>
				<p className='text-sm-medium'>{row.unit_name}</p>
				<p className='text-xs-regular mt-1'>{row.unit_police_number}</p>
			</>,
			cell: (row) => (
				<div className="flex flex-col space-y-1 overflow-hidden">
					<Typography.Text
						className="text-sm-medium"
						ellipsis={{
							tooltip: row?.unit_name
						}}>
						{row?.unit_name}
					</Typography.Text>
					<p className='text-xs-regular mt-1'>{row.unit_police_number}</p>
				</div>
			),
			sortable: true,
			width: '40%'
		},
		{
			name: 'User',
			selector: row => row.owner_name,
			cell: (row) => (
        <Typography.Text
          // className="w-32 2xl:w-full"
          ellipsis={{
            tooltip: row?.owner_name
          }}>
          {row?.owner_name || '-'}
        </Typography.Text>
      ),
			sortable: true,
		},
		{
			name: 'Riwayat Servis',
			selector: row => row.latest_service_date,
			// cell: (row) => row?.latest_service_date !== '-' ? moment(row.latest_service_date).format('DD MMM YYYY') : '-',
			sortable: true,
		},
		{
			name: 'Aksi',
			cell: (row) => (
				<CustomButton
					onClick={navigateHandler.bind(this, '/dashboard/unit/detail-unit', row.id)}
					label={
						<div className="flex gap-2 text-sm-medium items-center">
							<EyeIcon className={'stroke-gray-700'} />
							Detail
						</div>
					}
					type="plain"
				/>
			),
			// right: true,
		},
	]

	return (
		<ReportTypeTemplate
			columns={columns}
			data={reportData?.data || []}
			currentPage={filter?.offset === 0 ? 1 : filter?.offset + 1}
			totalPerPage={(currentPage * filter?.limit) >= reportData?.meta.total ? reportData?.meta.total : currentPage * filter?.limit}
			lengthAllData={reportData?.meta?.total}
			defaultPageSize={filter?.limit}
			onChangePageSize={handleChangeFilterIncludeOffset}
			onChangeNextPage={onChangeNextPage}
			onChangePrevPage={onChangePrevPage}
			handleChangeDate={handleChangeDate}
			type="Mobil"
		/>
	)
}

export default ReportTypeCars;