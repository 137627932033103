const RefreshIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      stroke="#C11B23"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.833984 2.33331V7.33332M0.833984 7.33332H5.83398M0.833984 7.33332L4.70065 3.69998C5.59627 2.80391 6.70429 2.14932 7.92132 1.79729C9.13835 1.44527 10.4247 1.40727 11.6604 1.68686C12.8961 1.96645 14.0408 2.55451 14.9878 3.39616C15.9347 4.23782 16.653 5.30564 17.0756 6.49998M19.1673 15.6666V10.6666M19.1673 10.6666H14.1673M19.1673 10.6666L15.3007 14.3C14.405 15.1961 13.297 15.8506 12.08 16.2027C10.8629 16.5547 9.57657 16.5927 8.34089 16.3131C7.10521 16.0335 5.96048 15.4455 5.01354 14.6038C4.06659 13.7621 3.34829 12.6943 2.92565 11.5"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RefreshIcon
