const DownloadIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5 18V23.3333C25.5 24.0406 25.219 24.7189 24.719 25.219C24.2189 25.719 23.5406 26 22.8333 26H4.16667C3.45942 26 2.78115 25.719 2.28105 25.219C1.78095 24.7189 1.5 24.0406 1.5 23.3333V18M6.83333 11.3333L13.5 18M13.5 18L20.1667 11.3333M13.5 18V2"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownloadIcon
