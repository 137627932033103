import React from 'react'
import { Col } from 'antd'
import { MailIcon, PhoneIcon, PinLocationIcon } from 'assets'
function DetailLocationSection() {
  return (
    <>
      <Col span={24} className="py-20 bg-gray-50" id="contact">
        <Col span={22} lg={{ span: 20 }} className="mx-auto gap-12 flex flex-col">
          {/* Title */}
          <div className="flex flex-col items-center justify-center gap-2">
            <p className="text-md-regular text-primary-500">Hubungi Kami</p>
            <p className="text-center display-sm-semibold text-gray-900">
              BirdMobil <br /> (PT. Pusaka Mitra Mobilindo)
            </p>
          </div>
          {/* Content */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Detail Location Container */}
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-[10px]">
                  <PinLocationIcon className={'stroke-gray-500'} />
                  <span className="text-gray-500 text-lg-medium">Lokasi</span>
                </p>
                <p className="text-md-medium text-gray-900">
                  Jl. Mampang Prapatan Raya No. 60, RT.9/ RW.3, Tegal Parang, Kec. Mampang Prapatan,
                  Kota Jakarta Selatan, Daerah khusus ibukota Jakarta, 12790.
                </p>
              </div>
              <div className="flex flex-col gap-1 text-md-medium text-gray-900">
                <p>Birdmobil BSD :</p>
                <p>
                  Jl. Cilenggang Raya No 30, Cilenggang, Tangerang, Kota Tangerang Selatan, Banten
                  15310
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-[10px]">
                  <PhoneIcon className={'stroke-gray-500'} />
                  <span className="text-gray-500 text-lg-medium">Contact Center :</span>
                </p>
                <p className="text-md-medium text-gray-900">0813-9981-4479</p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-[10px]">
                  <MailIcon className={'stroke-gray-500'} />
                  <span className="text-gray-500 text-lg-medium">Email</span>
                </p>
                <p className="text-md-medium text-gray-900">account.executive@birdmobil.id</p>
                <p className="text-md-medium text-gray-900">marketing.pmm@bluebirdgroup.com</p>
              </div>
            </div>
            {/* map */}
            <div className="min-h-[328px] w-full">
              <iframe
                src={`https://maps.google.com/maps?q=${-6.2460578},${106.8259862}&hl=es;z=14&output=embed`}
                className="h-full w-full border-0"
              />
            </div>
          </div>
        </Col>
      </Col>
    </>
  )
}

export default DetailLocationSection
