const TerpercayaIcon = ({ className }) => {
  return (
    <svg
      width="100"
      height="52"
      className={className}
      viewBox="0 0 100 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.49418 22.2627L8.178 16.8955L8.02015 16.7376L4.17891 18.0531C4.17891 18.0531 8.54634 26.2091 8.54634 26.4196C8.54634 26.6301 6.96775 29.2611 5.86274 28.2613C4.75773 27.2615 0.60078 18.3162 0.285062 17.6848C0.0219639 17.0533 0.442921 16.4219 0.653399 15.7904C0.863878 15.2116 6.70466 12.0545 8.9673 11.2125C9.44088 11.0547 9.86183 11.0021 10.3354 11.1073C12.2823 11.4756 17.9126 12.7385 20.2279 13.2647C21.0172 13.5804 21.6486 14.2119 21.9643 15.0012L25.0163 21.6838L28.8049 28.2087C29.1206 28.7349 29.2259 29.2611 28.7523 29.7346C28.1208 30.4187 26.6475 30.787 25.9634 29.8925L21.7539 23.8413L19.2807 19.8948L18.5441 19.7895L15.0186 27.051C15.0186 27.051 14.5976 27.893 14.5976 28.4192C14.6502 31.0501 15.1238 40.2586 13.5452 41.4162C13.0716 41.7845 11.493 43.0474 9.86183 44.2577M4.86297 26.7879C4.70511 27.5246 4.49463 28.9454 4.54725 31.3659L4.75773 37.8907L5.81012 49.9932C5.91536 50.4668 6.23108 50.7299 6.70466 50.7825H9.17778C9.91445 50.6773 10.0723 50.2563 10.0723 49.6249L9.65135 37.575L9.80921 30.4187M5.12607 42.1529L1.12698 46.4677C0.916497 46.7308 0.863878 46.9413 0.916497 47.257C1.07436 47.941 1.49531 49.1513 2.38985 49.8353C2.54771 49.9932 2.70556 49.9406 2.86342 49.8353L5.59964 47.4675M20.3331 4.42461C20.1227 2.42507 18.3862 1.42529 16.4393 1.42529C14.4924 1.42529 12.8085 2.42507 12.6507 4.42461C12.5981 5.477 12.8612 7.89751 13.3873 8.84466C13.8609 9.63395 15.0712 10.4232 16.1762 10.6863C16.7024 10.7916 16.8602 10.7916 17.3864 10.6863C18.4914 10.4232 19.3334 9.63395 19.7543 8.84466C20.2279 7.95013 20.4384 5.477 20.3331 4.42461Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5966 45.3626C24.1224 45.5205 26.5429 45.731 28.9634 45.8362M25.8588 20.4209L29.7527 14.738C30.0158 14.4223 30.6472 13.7909 31.8048 13.633C41.8552 13.0016 49.2219 13.0016 59.2723 13.633C60.4299 13.7909 61.0087 14.4223 61.3244 14.738L66.4812 22.2626H66.8495L67.5862 21.2628C67.6914 21.105 67.8493 21.0524 68.1124 20.9997H71.48C72.1641 20.9997 72.9008 21.5259 73.0586 22.21L73.3743 23.9991C73.427 24.2622 73.2691 24.3674 73.006 24.4726L69.5331 25.9986L71.2169 27.9455C72.0588 28.9979 72.4798 30.4187 72.4798 32.2077V49.467C72.4798 50.151 71.901 50.7298 71.1643 50.7298H63.4292C62.7452 50.7298 62.1664 50.151 62.1664 49.467L62.1137 45.7836C50.5374 46.415 40.5397 46.415 28.9634 45.7836L28.9108 49.467C28.9108 50.151 28.3319 50.7298 27.5953 50.7298H19.8602C19.1761 50.7298 18.5447 50.151 18.5447 49.467V32.2603C18.4921 30.4713 18.913 29.0505 19.8076 27.9982L21.2809 26.3143L20.9652 25.8408L17.5449 24.3674M28.9108 24.9988C40.5923 25.5777 50.4848 25.5777 62.1664 24.9988C65.1657 24.841 67.06 24.4726 66.5338 22.3152M69.4279 45.3626C66.9021 45.5205 64.4816 45.731 62.0611 45.8362M69.5331 26.0512L68.1124 24.3674M23.4909 31.6815C25.9641 32.2077 28.3846 32.6287 30.8577 32.8392C31.2786 32.8918 31.647 33.3127 31.5417 33.6811L31.226 34.523C31.0682 34.8913 30.8051 35.1544 30.4367 35.1544C28.0688 35.1018 25.6483 34.9439 23.2278 34.6282C22.8069 34.5756 22.4912 34.2073 22.5438 33.7863L22.7016 32.3656C22.7016 31.892 23.07 31.5763 23.4909 31.6815ZM67.5336 31.6815C65.0604 32.2077 62.6399 32.6287 60.1668 32.8392C59.7459 32.8918 59.3775 33.3127 59.4828 33.6811L59.7985 34.523C59.9563 34.8913 60.2194 35.1544 60.5878 35.1544C62.9557 35.1018 65.3762 34.9439 67.7967 34.6282C68.2176 34.5756 68.4807 34.2073 68.4807 33.7863L68.3229 32.3656C68.3229 31.892 67.9545 31.5763 67.5336 31.6815Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.0024 24.7884L77.8989 25.8934C77.1097 25.8934 76.1099 23.1046 77.0044 22.8415L83.6345 21.2103L85.8445 14.3698C86.2129 13.2647 88.1598 12.5281 89.2122 12.3702L93.0534 11.4757C94.4215 11.2126 96.8946 11.6335 97.7892 12.7912C99.7887 15.2643 99.5782 20.3158 99.9992 23.5256C99.8413 26.6827 99.7361 29.1559 99.5782 32.313C99.5782 32.6288 99.3678 32.8919 99.1047 33.0497C98.8942 33.2076 97.5261 33.3128 97.2104 33.3128C96.842 33.3128 96.3684 32.9971 96.3684 32.4183L96.3158 30.0504L96.3684 23.6308L95.4213 18.4215M96.7894 33.4181L97.3156 49.9932C97.3156 50.4668 97.0525 50.7299 96.5789 50.7825H94.2637C94.0006 50.7299 93.7901 50.6247 93.7375 50.3616L91.4748 32.3657H91.317L90.7908 50.4142C90.7908 50.6247 90.6329 50.7825 90.4224 50.7825H87.5283C87.2652 50.7825 87.1074 50.6773 87.1074 50.3616L86.6338 32.6814C86.3707 26.1039 86.4233 28.577 86.3707 21.8944M86.4759 4.63512C86.4759 4.16155 86.5812 3.74059 86.6864 3.37225C87.6862 1.10961 93.2639 0.372933 93.8953 3.74059C94.0532 4.68774 94.0532 7.00301 93.6322 7.95016C93.1586 9.00255 92.001 9.9497 90.8434 10.1602C89.2648 10.5285 87.8441 10.0549 87.16 8.42373C86.7917 7.42396 86.4759 5.47704 86.4759 4.63512Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.3757 20.684L30.0144 20.5788C29.5408 20.5788 29.2251 20.0526 29.4356 19.6316L31.3825 16.1587C31.4878 15.9482 31.6982 15.843 31.9613 15.843C35.9604 15.3694 40.4331 15.0537 45.3267 15.0537C50.3782 15.0537 55.0087 15.3694 59.1131 15.843C59.1657 15.843 59.2709 15.843 59.4288 15.9482C59.534 16.0009 59.5866 16.0535 59.6392 16.1587L61.9019 19.6842C62.165 20.1052 61.9019 20.684 61.3757 20.684Z"
        fill="#E12B25"
      />
    </svg>
  )
}

export default TerpercayaIcon
