import { Col } from 'antd'
import { SignInContent, SignUpContent } from 'features/auth'
import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
function AuthLayout({ children }) {
  const { type } = useParams()
  const { push } = useHistory()
  return (
    <div className="grid md:grid-cols-2 sm:grid-cols-1 h-screen w-screen overflow-x-hidden">
      <div className="h-full flex justify-center">
        <Col span={21} md={16} className='flex h-full flex-col'>
          <div className='text-sm-medium text-primary-600 flex items-center gap-2 cursor-pointer my-6 md:my-12' onClick={() => push('/')}>
            <AiOutlineArrowLeft className="fill-primary-600" />Kembali ke beranda
          </div>
          <div className='w-full h-full flex items-center justify-center'>
            <div className='grow mb-6'>
              {children}
            </div>
          </div>
        </Col>
      </div>
      <div
        span={12}
        className="hidden md:block bg-no-repeat bg-cover bg-[url('assets/images/background-layout.webp')] h-full">
        {type !== undefined ? <SignUpContent /> : <SignInContent />}
      </div>
    </div>
  )
}

export default AuthLayout
