const SlidersIcon = ({ className }) => {
  return (
    <svg
      width="20"
      className={className}
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      stroke="#344054"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33334 16.5V10.6667M3.33334 7.33333V1.5M10 16.5V9M10 5.66667V1.5M16.6667 16.5V12.3333M16.6667 9V1.5M0.833336 10.6667H5.83334M7.5 5.66667H12.5M14.1667 12.3333H19.1667"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SlidersIcon
