import { Col, DatePicker, Row, Spin } from 'antd'
import BadgeItem from 'components/ui/badge-item'
import CardSectionHeader from 'components/ui/card-section-header'
import CustomButton from 'components/ui/custom-button'
import FormImage from 'components/ui/form/form-image'
import FormInput from 'components/ui/form/form-input'
import FormRadio from 'components/ui/form/form-radio'
import FormSelect from 'components/ui/form/form-select'
import FormTextArea from 'components/ui/form/form-textarea'
import { useCreateEditUnit } from 'features/dashboard/hooks'
import { ModalLayout } from 'layouts'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const initialName = {
  merek: 'Merek',
  model: 'Model',
  tipe: 'Tipe',
  transmisi: 'Transmisi',
  tahunKeluaran: 'Tahun Keluaran',
  bahanBakar: 'Bahan Bakar',
  warna: 'Warna',
  platNomor: 'Plat Nomor',
  nomorRangka: 'Nomor Rangka',
  nomorMesin: 'Nomor Mesin',
  thumbnail: 'Photo Thumbnail',
  photos: 'Photo Detail Unit',
  dokumen_stnk: 'STNK',
  dokumen_bpkb: 'BPKB',
  dokumen_ktp: 'KTP Pemilik',
  dokumen_kwitansi: 'Kwitansi',
  dokumen_keur: 'KEUR',
  dokumen_form: 'Form A',
  catatan: 'Catatan'
}

function CreateEditUnitPage() {
  const pathName = window.location.pathname.split('/')
  const typePage = pathName[pathName.length - 1]
  const { state } = useLocation()
  const idUnit = state?.idUnit ? state?.idUnit : undefined
  const { inputs, isLoading, statusInfo, submitForm } = useCreateEditUnit(idUnit, typePage, state)
  return (
    <Row className="gap-6">
      {/* Header */}
      <Col span={24} className="flex gap-1 items-center text-xl-medium text-gray-900 py-2">
        <NavLink to={'/dashboard'} className={'hover:text-primary-500 text-gray-900 no-underline'}>
          Dashboard
        </NavLink>{' '}
        /{' '}
        <span className="text-primary-500">
          {typePage === 'edit-unit' ? 'Edit' : 'Tambah'} Unit
        </span>
      </Col>
      {/* Content Container */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* Form Information unit */}
        {inputs.map((inputSection) => (
          <CardSectionHeader key={inputSection.id} headerTitle={inputSection.headerTitle}>
            <div className="grid grid-cols-1 gap-5">
              {inputSection.inputs.map((inputItem) => {
                if (inputItem.type === 'text')
                  return <FormInput key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'select')
                  return <FormSelect key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'radio')
                  return <FormRadio key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'file')
                  return <FormImage key={inputItem.id} imageProps={inputItem} />
                else if (inputItem.type === 'textarea')
                  return <FormTextArea key={inputItem.id} data={inputItem} />
                else if (inputItem.type === 'kategori')
                  return (
                    <div key={inputItem.id} className="flex flex-col gap-2 ">
                      <p className="text-sm-medium text-gray-700">{inputItem.label}</p>
                      <div className="border border-solid border-gray-200 cursor-not-allowed bg-gray-50 min-h-[30px] flex gap-3 p-2">
                        {inputItem?.value?.map((val, index) => (
                          <BadgeItem key={index} type="bluelight">
                            {val}
                          </BadgeItem>
                        ))}
                      </div>
                    </div>
                  )
                else if (inputItem.type === 'datepicker')
                  return (
                    <div key={inputItem.id} className="flex flex-col gap-2 ">
                      <p className="text-sm-medium text-gray-700">
                        {inputItem.label} <span className="text-danger-500">*</span>
                      </p>
                      <DatePicker {...inputItem} />
                    </div>
                  )
              })}
            </div>
          </CardSectionHeader>
        ))}

        <Col span={24} className="flex justify-end items-center gap-2">
          <Spin spinning={isLoading}>
            <NavLink to={'/dashboard'}>
              <CustomButton type="plain" label={'Batal'} />
            </NavLink>
          </Spin>
          <Spin spinning={isLoading}>
            <CustomButton onClick={submitForm} label={'Simpan'} />
          </Spin>
        </Col>
      </Col>

      <ModalLayout
        openModal={isLoading}
        closable={false}
        className={'w-full max-w-sm'}
        headerTitle={(typePage === 'edit-unit' ? 'Mengubah' : 'Menambahkan') + ' Data Unit'}>
        <div className="flex gap-2 items-center justify-center">
          <Spin spinning={isLoading}></Spin>
          <span className="text-md-medium text-gray-700 pb-1">
            {`${statusInfo.progress ? 'proses ' : ''} ${statusInfo.send ? 'mengirimkan' : ''} ${
              statusInfo.upload ? 'uploading' : ''
            } ${statusInfo.removed ? 'menghapus' : ''} ${statusInfo.category} ${
              statusInfo.category === 'gambar'
                ? `${statusInfo.currentPhoto}/${statusInfo.totalPhoto}`
                : ''
            }`}
            {statusInfo.done}
            {statusInfo.progress}
          </span>
        </div>
      </ModalLayout>
    </Row>
  )
}

export default CreateEditUnitPage
