import { checkSpecialChar } from '../utils/checkSpecialCharacter'

const useValidation = () => {
  const nameValidation = (name) => {
    if (!name) return 'nama tidak boleh kosong'
    const stringRegexValidation = /^[^\\^/<>_#']+$/
    if (stringRegexValidation.test(name)) return false
    return 'nama hanya boleh menggunakan huruf abjad'
  }

  const addressValidation = (str) => {
    if (!str) return 'Alamat tidak boleh kosong'
    const stringRegexValidation = /^[^/\\<>_#@$^!%&*']+$/
    if (stringRegexValidation.test(str)) return false
    return 'Alamat tidak boleh menggunakan huruf spesial (<>_#@$^!%&*/\\)'
  }

  const handleXSSInput = (str) => {
    return str.replace(/[&<>"'/]/g, function (char) {
      switch (char) {
        case '&':
          return '&amp;'
        case '<':
          return '&lt;'
        case '>':
          return '&gt;'
        case '"':
          return '&quot;'
        case '\\':
          return '&#39;'
        case '/':
          return '&#x2F;'
        default:
          return char
      }
    })
  }

  const phonenumberValidation = (number) => {
    const indonesianNumberRegex = /^((^\+628|628|^08|^8)(\d{2}))-?(\d{3,4})-?(\d{3,5}$)/g

    if (!number.match(indonesianNumberRegex)) return 'nomor telepon tidak valid'

    return false
  }

  const nikValidation = (nikStr) => {
    const nik = parseInt(nikStr)

    if (isNaN(nik)) return 'NIK harus angka'
    if (nikStr.length !== 16) return 'NIK harus 16 digit'

    if (nikStr.length === 16) return false
  }

  const emailValidation = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!email.match(emailRegex)) return 'email tidak valid'

    return ''
  }

  const passwordValidation = (password) => {
    // var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
    var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
    if (password.length < 8 || !strongRegex.test(password) || !checkSpecialChar(password))
      return 'Minimal 8 karakter, dapat berupa koombinasi huruf, angka, dan simbol'

    return false
  }

  const confirmPasswordValidation = (password, confirm_password) => {
    if (password !== confirm_password) return 'Password tidak sama'

    return false
  }

  const emailOrPhoneNumberValidation = (value) => {
    const validator = /^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/

    if (!value.match(validator)) return 'Email atau Nomor Telepon tidak valid'

    return false
  }

  const dateValidation = (value) => {
    const dateformat = /^\d{4}-\d{2}-\d{2}$/

    if (!value.match(dateformat)) return 'Tanggal lahir tidak valid'

    return false
  }

  // tambahan raafi
  const numberValidation = (number, title) => {
    if (isNaN(number)) return `${title} harus angka`
    return ''
  }
  const passValidation = (password) => {
    // const strongRegex = /^[a-zA-Z0-9]+$/
    var newRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,12}$/g
    // if ((!strongRegex.test(password) && (password.length < 8) && password.length > 13))
    //   return 'Minimal 8 karakter, maksimal 13 karakter, dapat berupa koombinasi huruf dan angka'

    if (!newRegex.test(password)) {
      return 'Minimal 8 karakter, maksimal 13 karakter, dapat berupa koombinasi huruf dan angka'
    }
    return ''
  }

  const confirmPassValidation = (password, confirm_password) => {
    if (password !== confirm_password) return 'Password tidak sama'

    return ''
  }

  const stringValidation = (string, title) => {
    if (!string) return `${title} tidak boleh kosong`

    return ''
  }

  return {
    nameValidation,
    phonenumberValidation,
    emailValidation,
    passwordValidation,
    nikValidation,
    confirmPasswordValidation,
    emailOrPhoneNumberValidation,
    dateValidation,
    numberValidation,
    passValidation,
    confirmPassValidation,
    addressValidation,
    stringValidation,
    handleXSSInput
  }
}

export default useValidation
