import { Divider } from 'antd'
import {
  BookIcon,
  BookmarkIcon,
  CalendarIcon,
  CoinStackIcon,
  CreditCardIcon,
  GearedIcon,
  OutlineCircleIcon,
  RadioIcon,
  SideBarIcon,
  SliderIcon
} from 'assets'
import BadgeItem from 'components/ui/badge-item'
import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'

function CardSpecificationUnit({ data }) {
  const specificationData = [
    {
      title: 'Jarak Tempuh',
      icon: RadioIcon,
      value: `${onRupiah(data?.mileage)} km`
    },
    {
      title: 'Keluaran',
      icon: CalendarIcon,
      value: data?.production_year
    },
    {
      title: 'Warna',
      icon: OutlineCircleIcon,
      value: data?.color_name
    },
    {
      title: 'Bahan Bakar',
      icon: GearedIcon,
      value: data?.fuel_name
    },
    {
      title: 'Transmisi',
      icon: SliderIcon,
      value: data?.transmission_name
    },
    {
      title: 'Pajak',
      icon: CoinStackIcon,
      value: data?.tax_status ? 'Hidup' : 'Mati'
    },
    {
      title: 'STNK',
      icon: BookmarkIcon,
      value: data?.document_stnk_status ? 'Ada' : 'Tidak Ada'
    },
    {
      title: 'BPKB',
      icon: SideBarIcon,
      value: data?.document_bpkb_status ? 'Ada' : 'Tidak Ada'
    }
    // {
    //   title: 'NIK',
    //   icon: CreditCardIcon,
    //   value: data?.document_nik_status ? 'Ada' : 'Tidak Ada'
    // },
    // {
    //   title: 'Buku Servis',
    //   icon: BookIcon,
    //   value: data?.document_service_status ? 'Ada' : 'Tidak Ada'
    // }
  ]
  return (
    <CardSectionHeader headerTitle={<div className="text-center">Spesifikasi Mobil</div>}>
      <div className="grid grid-cols-1 gap-6">
        {/* Detail Specification */}
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 xl:gap-8">
          {new Array(2).fill(0).map((_, index) => (
            <div key={index} className="grid grid-cols-1 gap-4 text-sm-medium md:text-md-medium ">
              {specificationData
                .slice(index * 4, (index + 1) * 4)
                .map(({ title, icon: Icon, value }, index) => (
                  <div key={index} className="flex justify-between">
                    <div className="flex gap-2 items-center text-gray-500 stroke-gray-500">
                      <Icon className={'w-4 h-4'} /> <p>{title}</p>
                    </div>
                    <p className="text-gray-700 whitespace-nowrap">{value}</p>
                  </div>
                ))}
            </div>
          ))}
        </div>
        <Divider className="m-0" />
        {/* Tags */}
        <div className="flex flex-col gap-2">
          <span className="text-sm-regular text-gray-500">Tags:</span>
          <div className="flex justify-start items-center gap-2">
            {data?.tags?.map((data) => (
              <BadgeItem type="plain" key={data?.id}>
                {data?.name}
              </BadgeItem>
            ))}
          </div>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardSpecificationUnit
