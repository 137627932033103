import { Col, Input, Row, Select, Typography } from 'antd'
import { ChevronDownIcon, ChevronUpIcon, MagnifyingIcon } from 'assets'
import BadgeItem from 'components/ui/badge-item'
import CustomTable from 'components/ui/custom-table'
import {
  getDataTransaction,
  getTransactionStatuses,
  getTransactionTypes
} from 'features/transaction/service'
import { debounce } from 'lodash'
import React from 'react'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { onRupiah } from 'utils/formatCurrency'
import moment from 'moment'
import CustomPagination from 'components/ui/pagination/custom-pagination'

const initialFilter = {
  offset: 0,
  limit: 5,
  type: '',
  status: ''
}

function TransactionPage() {
  const { push } = useHistory()
  const token = localStorage.getItem('token')
  const accountType = localStorage.getItem('account_type')
  if (token === null) {
    window.location.replace('/')
  }
  const [keyword, setKeyword] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [filter, setFilter] = useState({ ...initialFilter })

  const [dataTransaction, setDataTransaction] = useState(null)
  const [transactionTypes, setTransactionTypes] = useState([])
  const [transactionStatuses, setTransactionStatuses] = useState([
    { label: 'Pilih Status', value: '' }
  ])

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    []
  )

  // start of fetching
  const { refetch } = useQuery(['getDataTransaction', debouncedChangeHandler], {
    queryFn: () => {
      const dataSend = {
        token: token,
        account_type: accountType === 'customer' ? 'personal' : 'b2b',
        params: {
          type: filter.type,
          status: filter.status,
          keywords: keyword,
          limit: filter.limit,
          offset: filter.offset
        }
      }

      return getDataTransaction(dataSend)
    },
    onSuccess: (data) => {
      setDataTransaction(data)
    },
    staleTime: 0,
    enabled: Boolean(debouncedChangeHandler)
  })
  useQuery('getTransactionTypes', {
    queryFn: () => {
      const dataSend = {
        token: token,
        account_type: accountType === 'customer' ? 'personal' : 'b2b'
      }

      return getTransactionTypes(dataSend)
    },
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el,
        value: el
      }))
      setTransactionTypes([{ label: 'Pilih Type', value: '' }, ...newData])
    }
  })
  useQuery(['getTransactionStatuses', filter?.type], {
    queryFn: () => {
      const dataSend = {
        token: token,
        account_type: accountType === 'customer' ? 'personal' : 'b2b',
        params: {
          type: filter?.type
        }
      }

      return getTransactionStatuses(dataSend)
    },
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el,
        value: el
      }))
      setTransactionStatuses([{ label: 'Pilih Status', value: '' }, ...newData])
    },
    enabled: filter?.type !== '' && filter?.type !== undefined,
    staleTime: 0
  })

  // end of fetching

  // function
  const handleResetFilter = () => {
    setFilter({ ...initialFilter })
    return debouncedChangeHandler()
  }
  const handleChangeSelectedFilter = (name, val) => {
    if (name === 'type') {
      setTransactionStatuses([{ label: 'Pilih Status', value: '' }])
      setFilter((prev) => ({
        ...prev,
        [name]: val,
        status: ''
      }))
    } else {
      setFilter((prev) => ({
        ...prev,
        [name]: val
      }))
    }

    return debouncedChangeHandler()
  }
  const handleChangeKeyword = (val) => {
    setKeyword(val)
    return debouncedChangeHandler()
  }
  const handleChangeFilterIncludeOffset = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
      offset: 0
    }))
    return debouncedChangeHandler()
  }
  const onChangeNextPage = () => {
    if (currentPage !== Math.ceil(dataTransaction?.meta?.total / filter.limit)) {
      handleChangeSelectedFilter('offset', currentPage * filter.limit)
      setCurrentPage((prev) => prev + 1)
    }
  }
  const onChangePrevPage = () => {
    if (currentPage > 1) {
      handleChangeSelectedFilter('offset', filter.offset - filter.limit)
      setCurrentPage((prev) => prev - 1)
    }
  }

  function handlePagination(pageNumber) {
    if (pageNumber - currentPage === 1) {
      // next page
      if (pageNumber !== Math.ceil(dataTransaction?.meta?.total / filter.limit)) {
        handleChangeSelectedFilter('offset', (pageNumber - 1) * filter.limit)
        setCurrentPage((prev) => prev + 1)
      }
    } else if (currentPage - pageNumber === 1) {
      // prev page
      if (pageNumber >= 1) {
        handleChangeSelectedFilter('offset', filter.offset - filter.limit)
        setCurrentPage((prev) => prev - 1)
      }
    } else {
      // current page
      handleChangeSelectedFilter('offset', (pageNumber - 1) * filter.limit)
      setCurrentPage(pageNumber)
    }
  }

  const navigateHandler = (serviceType, transactionId, id) => {
    push(`/transaction/transaction-detail`, {
      type: serviceType,
      id: serviceType === 'beli' ? id : transactionId
    })
  }
  const columns = [
    {
      name: 'Tanggal',
      selector: (row) => row.created_at,
      cell: (row) => moment(row.created_at).format('DD MMM YYYY'),
      sortable: true,
      width: '13%'
    },
    {
      name: 'Nama Unit',
      selector: (row) => row.unitName,
      cell: (row) => (
        <div className="flex flex-col space-y-1 overflow-hidden">
          <Typography.Text
            className="text-sm-medium block"
            ellipsis={{
              tooltip: row?.unit_name
            }}>
            {row?.unit_name}
          </Typography.Text>
          <p className="text-xs-regular mt-1">{row.unit_police_number}</p>
        </div>
      ),
      sortable: true,
      width: '20%'
    },
    {
      name: 'Status',
      wrap: true,
      center: true,
      selector: (row) => row.status,
      cell: (row) => <TransactionBadge status={row.status} />,
      sortable: true
    },
    {
      name: 'Kepemilikan',
      selector: (row) => row.owner_fullname,
      sortable: true,
      cell: (row) => (
        <Typography.Text
          className="text-sm-medium block"
          ellipsis={{
            tooltip: row?.owner_fullname
          }}>
          {row?.owner_fullname}
        </Typography.Text>
      ),
      omit: accountType !== 'customer' //muncul saat account type personal
    },
    {
      name: 'Nama User',
      selector: (row) => row.user_fullname,
      cell: (row) => (
        <Typography.Text
          className="text-sm-medium block"
          ellipsis={{
            tooltip: row?.user_fullname
          }}>
          {row?.user_fullname}
        </Typography.Text>
      ),
      width: '13%',
      // sortable: true,
      omit: accountType === 'customer' //muncul saat account type B2B
    },
    {
      name: 'Layanan',
      selector: (row) => row.transaction_type,
      width: '10%'
    },
    {
      name: 'Biaya',
      cell: (row) => (
        <p className="text-warning-500">{`Rp. ${onRupiah(row.transaction_profit)}`}</p>
      ),
      sortable: true,
      width: '15%'
    },
    {
      name: 'Aksi',
      cell: (row) => (
        <p
          onClick={navigateHandler.bind(
            this,
            row.transaction_type.toLowerCase(),
            row.transaction_id,
            row.id
          )}
          className="text-primary-500 cursor-pointer text-sm-medium">
          Lihat Detail
        </p>
      ),
      width: '11%',
      right: true
    }
  ]

  return (
    <Row className="gap-6">
      {/* Header */}
      <Col span={24} className="flex justify-between items-center text-xl-medium text-gray-900">
        Transaksi
      </Col>
      {/* Content */}
      <Col span={24}>
        <div className="md:bg-white md:border border-solid border-gray-200 md:p-6 rounded-[4px] grid grid-cols-1 gap-6">
          {/* Filter & Search */}
          <div className="flex w-full flex-col md:flex-row md:justify-between md:items-center gap-3">
            <div className="flex gap-2 justify-between md:justify-start">
              <Select
                placeholder="Pilih Tipe"
                className="w-full"
                options={transactionTypes}
                onChange={(val) => handleChangeSelectedFilter('type', val)}
                value={filter.type}
                showSearch={true}
                allowClear
              />
              <Select
                placeholder="Pilih Status"
                className="w-full"
                options={transactionStatuses}
                onChange={(val) => handleChangeSelectedFilter('status', val)}
                value={filter.status}
                showSearch={true}
                allowClear
              />
              {/* {
                (filter.status !== '' || filter.type !== '') && (
                  <CustomButton
                    label={'Hapus Filter'}
                    className='gap-1 p-1 md:p-3'
                    onClick={handleResetFilter}
                  />
                )
              } */}
              {/* <Select placeholder="Bulan" className='w-full' options={options} /> */}
            </div>
            <div className="order-first md:order-last">
              <Input
                size="medium"
                className="text-md-normal text-gray-500 w-full md:w-[400px] px-[14px] py-[10px] rounded-lg"
                prefix={<MagnifyingIcon className={'mr-1'} />}
                value={keyword}
                onChange={(e) => handleChangeKeyword(e.target.value)}
                placeholder="Cari Nama Unit"
              />
            </div>
          </div>
          {/* Table */}
          <div className="hidden md:block">
            <CustomTable
              columns={columns}
              data={dataTransaction?.data || []}
              currentPage={filter?.offset === 0 ? 1 : filter?.offset + 1}
              totalPerPage={
                currentPage * filter?.limit >= dataTransaction?.meta.total
                  ? dataTransaction?.meta.total
                  : currentPage * filter?.limit
              }
              lengthAllData={dataTransaction?.meta?.total}
              defaultPageSize={filter?.limit}
              onChangePageSize={handleChangeFilterIncludeOffset}
              onChangeNextPage={onChangeNextPage}
              onChangePrevPage={onChangePrevPage}
            />
          </div>
          <div className="block md:hidden">
            <div className="grid grid-cols-1 gap-4 min-h-[200px]">
              {dataTransaction?.data?.map((row, index) => (
                <MobileListComponent row={row} key={index} navigateHandler={navigateHandler} />
              ))}
            </div>
            <div className="grid grid-cols-1 mt-5 place-items-center">
              <CustomPagination
                onChange={handlePagination}
                defaultPageSize={filter?.limit}
                defaultCurrent={2}
                current={filter?.offset === 0 ? 1 : currentPage}
                total={dataTransaction?.meta?.total}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

const MobileListComponent = ({ row, navigateHandler }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="border border-solid border-gray-200 rounded">
      <div
        className={`p-2 flex items-center justify-between ${isOpen ? 'bg-gray-200' : ''}`}
        onClick={() => setIsOpen((prev) => !prev)}>
        <div className="flex flex-col">
          <div className="text-xs-regular text-gray-700">
            {row.transaction_type}
            <span className="text-gray-500">{` - ${moment(row.created_at).format(
              'DD MMM YYYY'
            )}`}</span>
          </div>
          <div className="text-sm-medium text-gray-700">{row.unit_name}</div>
          <div className="text-xs-regular text-gray-700">{row?.unit_police_number}</div>
        </div>
        <div className="flex flex-shrink-0 items-center space-x-2">
          <TransactionBadge status={row.status} />
          {isOpen ? (
            <ChevronUpIcon className="stroke-gray-400 w-[10px] inline-block" />
          ) : (
            <ChevronDownIcon className="stroke-gray-400 w-[10px] inline-block" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="p-2">
          <div className="my-4 grid grid-cols-2 text-gray-700">
            <div className="text-xs-medium">Kepemilikan</div>
            <div className="text-xs-regular">{row?.owner_fullname || '-'}</div>
            <div className="text-xs-medium">Layanan</div>
            <div className="text-xs-regular">{row?.transaction_type || '-'}</div>
            <div className="text-xs-medium">Biaya</div>
            <div className="text-xs-regular">
              {`Rp. ${onRupiah(row.transaction_profit)}` || '-'}
            </div>
          </div>
          <p
            onClick={navigateHandler.bind(
              this,
              row.transaction_type.toLowerCase(),
              row.transaction_id,
              row.id
            )}
            className="text-primary-500 cursor-pointer text-sm-medium text-right">
            Lihat Detail
          </p>
        </div>
      )}
    </div>
  )
}

const TransactionBadge = ({ status }) => {
  return (
    <div>
      {status === 'Menunggu Pembayaran' ||
      status === 'Menunggu Konfirmasi' ||
      status === 'Menunggu Approval' ? (
        <BadgeItem type="warning" className={'text-center break-normal'}>
          {status}
        </BadgeItem>
      ) : status === 'Berlangsung' ? (
        <BadgeItem type="bluelight" className={'text-center break-normal'}>
          Mobil Di Bengkel
        </BadgeItem>
      ) : status === 'Booked' ? (
        <BadgeItem type="rose" className={'text-center break-normal'}>
          {status}
        </BadgeItem>
      ) : status === 'Selesai' ? (
        <BadgeItem type="success" className={'text-center break-normal'}>
          {status}
        </BadgeItem>
      ) : status === 'Booking' || status === 'Booking Lunas' ? (
        <BadgeItem type="purple" className={'text-center break-normal'}>
          {status}
        </BadgeItem>
      ) : status === 'Dibatalkan' ? (
        <BadgeItem type="danger" className={'text-center break-normal'}>
          {status}
        </BadgeItem>
      ) : (
        <BadgeItem type="plain" className={'text-center break-normal'}>
          {status}
        </BadgeItem>
      )}
    </div>
  )
}

export default TransactionPage
