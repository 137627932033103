import { Col, Menu, Row } from 'antd'
import React from 'react'
import ContentTitle from '../ui/content-title'
import DetailLocationSection from '../ui/detail-location-section'
import PartnerSection from '../ui/partner-section'
import './style.css'
function HelpSupport() {
  // const [filteredFAQ, setFilteredFAQ] = useState([...faqData])
  // const onSearch = (text) => {
  //   if (!text) setFilteredFAQ([...faqData])
  //   const newFilteredData = faqData.filter((faq) => faq.title.toLowerCase().includes(text))
  //   console.log('newFiltered', newFilteredData)
  //   setFilteredFAQ([...newFilteredData])
  // }
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type
    }
  }
  const items = [
    getItem('Hubungi Kami', 'sub1', null, [
      getItem('Hubungi Kami', 'hub1', null)
      // getItem('Baru di BirdMobil', 'mitra2', null),
      // getItem('Baru di BirdMobil', 'mitra3', null),
      // getItem('Baru di BirdMobil', 'mitra4', null)
    ])
    // getItem('Pembayaran', 'sub2', null, [
    //   getItem('Baru Pembayaran', 'p1', null),
    //   getItem('Baru Pembayaran', 'p2', null),
    //   getItem('Baru Pembayaran', 'p3', null),
    //   getItem('Baru Pembayaran', 'p4', null)
    // ]),
    // getItem('Akun', 'sub3', null, [
    //   getItem('Baru Akun', 'a1', null),
    //   getItem('Baru Akun', 'a2', null),
    //   getItem('Baru Akun', 'a3', null),
    //   getItem('Baru Akun', 'a4', null)
    // ])
  ]
  return (
    <Row className={`bg-gray-50`}>
      <Col span={24}>
        <ContentTitle title={'Halo, Butuh Bantuan?'} />
        <Col span={20} className="bg-gray-50 py-20 mx-auto flex flex-col lg:flex-row gap-11">
          {/* Menu */}
          <Col span={24} lg={{ span: 4 }}>
            <Menu
              mode="inline"
              items={items}
              defaultSelectedKeys={['hub1']}
              defaultOpenKeys={['sub1']}
            />
          </Col>
          {/* Content */}
          <Col span={24} lg={{ span: 18 }} className="flex flex-col gap-4">
            <h3 className="display-sm-semibold text-gray-900">Hubungi Kami</h3>
            <p className="text-sm-medium md:text-md-medium text-gray-700">
              BirdMobil ( PT. Pusaka Mitra Mobilindo )
            </p>
            <div className="grid grid-cols-[100px_10px_1fr] md:grid-cols-[150px_10px_1fr]">
              <span className="text-sm-medium md:text-md-medium text-gray-700">Lokasi</span>{' '}
              <span>:</span>
              <span className="text-sm-regular md:text-md-regular text-gray-600">
                Jl. Mampang Prapatan Raya No. 60, RT.9/ RW.3, Tegal Parang, Kec. Mampang Prapatan,
                Kota Jakarta Selatan, Daerah khusus ibukota Jakarta, 12790.
              </span>
            </div>
            <p className="text-sm-medium md:text-md-medium text-gray-700">
              BirdMobil BSD ( PT. Pusaka Mitra Mobilindo )
            </p>
            <div className="grid grid-cols-1 gap-4">
              <div className="grid grid-cols-[100px_10px_1fr] md:grid-cols-[150px_10px_1fr]">
                <span className="text-sm-medium md:text-md-medium text-gray-700">Lokasi</span>{' '}
                <span>:</span>
                <span className="text-sm-regular md:text-md-regular text-gray-600">
                  Jl. Cilenggang Raya No 30, Cilenggang, Tangerang, Kota Tangerang Selatan, Banten,
                  15310
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div className="grid grid-cols-[100px_10px_1fr] md:grid-cols-[150px_10px_1fr]">
                <span className="text-sm-medium md:text-md-medium text-gray-700">
                  Contact Center
                </span>{' '}
                <span>:</span>
                <span className="text-sm-regular md:text-md-regular text-gray-600">
                  0813-9981-4479
                </span>
              </div>
              <div className="grid grid-cols-[100px_10px_1fr] md:grid-cols-[150px_10px_1fr]">
                <span className="text-sm-medium md:text-md-medium text-gray-700">Email</span>{' '}
                <span>:</span>
                <div className="grid grid-cols-1 gap-2 text-sm-regular md:text-md-regular text-gray-600">
                  <p>account.executive@birdmobil.id</p>
                  <p>marketing.pmm@bluebirdgroup.com</p>
                </div>
              </div>
            </div>
          </Col>
        </Col>
        <PartnerSection />
        <DetailLocationSection />
      </Col>
    </Row>
  )
}

export default HelpSupport
