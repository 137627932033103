import CardInformationUser from 'components/ui/card/card-information-user'
import CardPayment from 'components/ui/card/card-payment'
import CardUnit from 'components/ui/card/card-unit'
import React from 'react'

const CheckoutContent = ({
  dataCar,
  dataUser,
  selectedPayment,
  handleSelectedPayment,
  price,
  taxPrice,
  totalPrice,
  isTaxi,
  is_create_your_own_car
}) => {
  return (
    <div className="grid grid-cols-1 gap-6">
      <CardUnit
        dataCatalog={dataCar}
        isTaxi={isTaxi}
        is_create_your_own_car={is_create_your_own_car}
      />
      {/* review Data diri */}
      <CardInformationUser headerTitle={'Data diri'} data={dataUser} />
      <CardPayment
        data={dataCar}
        selectedPayment={selectedPayment}
        handleSelectedPayment={handleSelectedPayment}
        disableVoucher
        price={price}
        taxPrice={taxPrice}
        totalPrice={totalPrice}
      />
    </div>
  )
}

export default CheckoutContent
