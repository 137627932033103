const TerkoneksiIcon = ({ className }) => {
  return (
    <svg
      width="64"
      className={className}
      height="55"
      viewBox="0 0 64 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.3711 4.5625H49.9077"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91211 41.7687H15.7023V40.3808C15.7023 38.9929 14.6726 37.8289 13.3294 37.6498L7.91211 36.8887V41.7687Z"
        fill="#E12B25"
      />
      <path
        d="M26.8954 18.5762H26.4029H15.0758C13.5535 18.5762 12.1209 19.4268 11.3598 20.77L7.59896 27.7095C7.06171 28.6497 6.07674 29.2317 5.00223 29.2317H2.40549C1.95778 29.2317 1.59961 29.5899 1.59961 30.0376V32.7239C1.59961 33.0373 1.82347 33.3507 2.13686 33.485L3.12183 34.067C3.83817 34.47 4.28589 35.2311 4.28589 36.037V49.8713C4.28589 51.6622 5.76334 53.1396 7.55419 53.1396H9.38981C11.1807 53.1396 12.6581 51.6622 12.6581 49.8713V47.9909H26.8954"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6585 47.9902H9.25586"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2695 41.7676H26.8957"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.2648 46.0215H34.0586"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0586 8.18945H59.2648"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.8482 53.0949H36.4325C33.3433 53.0949 30.8809 50.5877 30.8809 47.5432V6.71179C30.8809 3.62257 33.3881 1.16016 36.4325 1.16016H56.8482C59.9374 1.16016 62.3998 3.66735 62.3998 6.71179V47.5432C62.4446 50.5877 59.9374 53.0949 56.8482 53.0949Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.7148 49.5586H48.6099"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9564 22.1579L10.2852 28.8736H26.9401V21.4863H15.0757C14.5832 21.4863 14.1803 21.755 13.9564 22.1579Z"
        fill="#E12B25"
      />
      <path
        d="M42.2527 24.666L38.8948 28.2477L37.2383 26.6359"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.416 18.3087L41.4454 14.2793"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4454 18.3087L37.416 14.2793"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.416 39.0372L41.4454 35.0078"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4454 39.0372L37.416 35.0078"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3125 18.3086H55.953"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3125 28.5156H55.953"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3125 39.3496H55.953"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TerkoneksiIcon
