import React from 'react'
// eslint-disable-next-line no-undef
const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
function ModalPreviewCustom({ detailSparepart }) {
  return (
    <div className="grid grid-cols-1 gap-5 w-[400px]">
      <img
        src={urlImage.concat(detailSparepart.image_url)}
        className="w-full bg-gray-50 rounded object-cover"
        alt="imageSparepart"
      />
      <div className="text-lg-medium text-gray-700">{detailSparepart.name}</div>
      <div className="text-md-regular text-gray-500">{detailSparepart.description}</div>
      <div className="text-md-regular text-gray-500">{`+Rp. ${detailSparepart.price}`}</div>
    </div>
  )
}

export default ModalPreviewCustom
