import { Progress } from 'antd'
import React, { useState } from 'react'
import moment from 'moment'
import ItemHistoryTransaction from '../card-history-transaction/item-history-transaction'
import { ChevronDownIcon, ChevronUpIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import { capitalize } from 'lodash'
const ProgressServiceCard = ({ data, serviceName }) => {
  const [isExpand, setIsExpand] = useState(false)
  const handleExpand = () => {
    setIsExpand((prevValue) => !prevValue)
  }
  return (
    <CardSectionHeader headerTitle={`Progress ${capitalize(serviceName)}`}>
      <div className="grid grid-cols-1 gap-5">
        {/* Status Progress bar */}
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center">
            <p className="text-md-medium text-gray-900">{data?.serviceStatusList?.[0]?.name}</p>
            <p className="text-md-semibold text-blue-500">{`${(
              (data?.progress_finished_total / data?.progress_total) *
              100
            ).toFixed(2)}%`}</p>
          </div>
          <div>
            <Progress
              strokeColor="#2e90fa"
              className="m-0 p-0 h-[20px]"
              percent={(data?.progress_finished_total / data?.progress_total) * 100}
              showInfo={false}
            />
            <div className="flex justify-between items-center text-xs-medium text-gray-600">
              <p>Estimasi Selesai</p>
              <p>
                {moment(data?.service_date).format('DD MMMM YYYY')} - {data?.service_end_time} WIB
              </p>
            </div>
          </div>
        </div>
        {/* List Progress */}
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isExpand ? 'h-full' : 'h-0'
          }`}>
          <div
            className={`grid grid-cols-1 gap-5 transition-all duration-300  ease-in-out ${
              isExpand ? 'opacity-100 delay-200' : 'opacity-0'
            }`}>
            {data?.serviceStatusList?.map((el, index) => {
              return <ItemHistoryTransaction key={index} data={el} checked={true} />
            })}
          </div>
        </div>

        {/* Button */}
        <div className="flex justify-center items-center ">
          <p
            onClick={handleExpand}
            className="cursor-pointer text-md-medium text-primary-600 stroke-primary-600 flex items-center gap-3">
            {isExpand ? (
              <>
                Sembunyikan <ChevronUpIcon />
              </>
            ) : (
              <>
                Detail Progress <ChevronDownIcon />
              </>
            )}
          </p>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default ProgressServiceCard
