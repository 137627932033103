import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { replaceIndonesianNumber } from 'utils/replaceIndonesianNumber'

function CardInformationUser({ data, headerTitle }) {
  return (
    <CardSectionHeader headerTitle={headerTitle}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <p className="text-sm-regular text-gray-600">Nama</p>
          <p className="text-md-medium text-gray-900">{data?.nama}</p>
        </div>
        <div>
          <p className="text-sm-regular text-gray-600">Email</p>
          <p className="text-md-medium text-gray-900">{data?.email}</p>
        </div>
        <div>
          <p className="text-sm-regular text-gray-600">Nomor Telepon</p>
          <p className="text-md-medium text-gray-900">{replaceIndonesianNumber(data?.phone)}</p>
        </div>
        <div>
          <p className="text-sm-regular text-gray-600">Alamat</p>
          <p className="text-md-medium text-gray-900">{data?.alamat || '-'}</p>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardInformationUser
