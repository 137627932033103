import { Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import CustomButton from "components/ui/custom-button";

function InputCompanyDetail({
    secondState,
    secondError,
    agree,
    isLoading,
    provinces,
    cities,
    subdistricts,
    handleChangeSecondState,
    handleChangeLocation,
    handleChangeAgree,
    handleSubmit,
    handleCancel
}) {
    const handleClick = () => {
        handleSubmit();
    }
    const handleClickCancel = () => {
        handleCancel();
    }

    return (
        <>
            <div className="flex flex-col gap-1">
                <label htmlFor="name" className="text-sm-medium text-gray-700">
                    Alamat Perusahaan<span className='text-danger-500'>*</span>
                </label>
                <TextArea
                    rows={2}
                    className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
                    name="address"
                    placeholder="Alamat Perusahaan"
                    value={secondState?.address}
                    onChange={(e) => handleChangeSecondState(e)}
                />
                <small className="text-primary-300">{secondError.address}</small>
            </div>
            <div className="grid-cols-2 grid gap-3">
                <div className="flex flex-col gap-1">
                    <label className="text-sm-medium text-gray-700">
                        Provinsi <span className='text-danger-500'>*</span>
                    </label>
                    <Select
                        className="w-full border-gray-300"
                        options={provinces}
                        onChange={(val) => handleChangeLocation('province_id', val)}
                        placeholder="Pilih Provinsi"
                        value={secondState?.province_id}
                        showSearch={true}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="city" className="text-sm-medium text-gray-700">
                        Kota <span className='text-danger-500'>*</span>
                    </label>
                    <Select
                        className="w-full border-gray-300"
                        options={cities}
                        onChange={(val) => handleChangeLocation('city_id', val)}
                        placeholder="Pilih Kota"
                        value={secondState?.city_id}
                        showSearch={true}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                </div>
            </div>
            <div className="grid-cols-2 grid gap-3">
                <div className="flex flex-col gap-1">
                    <label htmlFor="district" className="text-sm-medium text-gray-700">
                        Kecamatan <span className='text-danger-500'>*</span>
                    </label>
                    <Select
                        className="w-full border-gray-300 rounded-lg"
                        options={subdistricts}
                        onChange={(val) => handleChangeLocation('subdistrict_id', val)}
                        placeholder="Pilih Kecamatan"
                        value={secondState?.subdistrict_id}
                        showSearch={true}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="postal_code" className="text-sm-medium text-gray-700">
                        Kode Pos <span className='text-danger-500'>*</span>
                    </label>
                    <Input
                        type="text"
                        className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
                        name="postal_code"
                        placeholder="Kode Pos"
                        value={secondState?.postal_code}
                        onChange={(e) => handleChangeSecondState(e)}
                    />
                    <small className="text-primary-300">{secondError.postal_code}</small>
                </div>
            </div>

            <div className="flex items-start space-x-2">
                <input type="checkbox" className="accent-primary-500 h-4 w-4" checked={agree} onChange={handleChangeAgree} />
                <p className="text-gray-400 text-sm-regular">
                    Apakah Anda menyetujui <span className="text-gray-800">Syarat dan Ketentuan</span> serta <span className="text-gray-800">Kebijakan Privasi</span> kami
                </p>
            </div>
            <div className="grid-cols-2 grid gap-3">
                <CustomButton type="plain" label="Kembali" onClick={handleClickCancel} />
                <CustomButton label="Selanjutnya" onClick={handleClick} disabled={!agree || isLoading} />
            </div>
        </>
    )
}

export default InputCompanyDetail;