import { Col, Divider, Row, Spin, Steps } from 'antd'
import CustomButton from 'components/ui/custom-button'
import { FormTestDriveContent, ReviewContent } from 'features/beli-mobil'
import { ModalLayout } from 'layouts'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getDetailCatalog,
  getDetailCatalogTaxi,
  getSchedules,
  getWorkshops,
  postTestDrive
} from 'features/beli-mobil/service'
import moment from 'moment'
import useValidation from 'lib/useValidation'
import MobileStep from 'components/ui/mobile-step'
import { nameToString } from 'utils/nameToString'
import { useScheduleService } from 'hooks'
import { showErrorMessage } from 'utils/toastMessage'
const items = [
  {
    key: 0,
    title: 'Data Test Drive'
  },
  { key: 1, title: 'Review' }
]

const initialData = {
  nama: '',
  phone: '',
  email: '',
  alamat: '',
  // date: '',
  // hours: {},
  workshop_id: '',
  workshop: {}
  // time: {}
}

const initialUnitData = {
  unit_name: '',
  unit_tag: false,
  id: null,
  unit_price: 0,
  unit_image_url: null
}

// eslint-disable-next-line no-undef
const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
const ScheduledTestDrivePage = () => {
  const token = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))
  const { state } = useLocation()
  const { push, replace } = useHistory()
  const idCatalog =
    state?.id === undefined
      ? state?.isTaxi === undefined
        ? replace('/beli/mobil-bekas')
        : replace('/beli/ex-taxi')
      : state?.id
  const isTaxi = state?.isTaxi ? true : false
  const [dataUnit, setDataUnit] = useState({ ...initialUnitData })
  const [data, setData] = useState({
    nama: user?.name || user?.fullname || '',
    phone: user?.phone_number || '',
    email: user?.email || '',
    alamat: user?.address || '',
    workshop_id: '',
    workshop: {},
    time: {}
  })
  const [errors, setErrors] = useState({ ...initialData })
  const [currentContent, setCurrentContent] = useState(0)
  const [workshops, setWorkshops] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const { times, schedule, handleChangeSchedule, scheduleIsValid } =
    useScheduleService('test-drive')

  const selectedWorkshop = workshops.find((el) => el.id === parseInt(data.workshop_id))
  const selectedSchedule = times.find((el) => el.id === parseInt(schedule?.hours?.id))

  useQuery({
    queryKey: 'getDetailCatalog',
    queryFn: () => getDetailCatalog(idCatalog),
    onSuccess: (data) => {
      setDataUnit(data)
    },
    enabled: !!idCatalog && !isTaxi
  })
  useQuery({
    queryKey: ['getDetailCatalogTaxis'],
    queryFn: () => getDetailCatalogTaxi(idCatalog),
    onSuccess: (data) => {
      const newData = {
        ...data,
        unit_name: data.name
      }
      setDataUnit(newData)
    },
    enabled: !!idCatalog && isTaxi
  })

  const { nameValidation, numberValidation, emailValidation, addressValidation } = useValidation()

  const handleNext = () => {
    // validate
    let nameError = ''
    let nameInput = ''
    let validError = true
    let validInput = true
    const newObject = { ...data }
    delete newObject.workshop
    delete newObject.workshop_id
    const newError = { ...errors }
    delete newError.workshop
    delete newError.workshop_id
    Object.keys(newObject).forEach((key) => {
      if (newObject[key].length === 0) {
        validInput = false
        nameInput = String(key)
      }
    })
    Object.keys(newError).forEach((key) => {
      if (newError[key].length > 0) {
        validInput = false
        nameInput = String(key)
      }
    })

    if (schedule?.date === null) {
      showErrorMessage('Mohon isi Tanggal Test Drive')
    } else if (schedule?.hours?.label === undefined) {
      showErrorMessage('Mohon isi Jam Test Drive')
    } else {
      if (validInput) {
        if (validError) {
          setCurrentContent((prevValue) => (prevValue === items.length ? prevValue : prevValue + 1))
        } else {
          showErrorMessage(`Mohon perbaiki ${nameToString[nameError]}`)
        }
      } else {
        showErrorMessage(`Mohon isi ${nameToString[nameInput] || 'semua data string'}`)
      }
    }
  }
  const handlePrev = () => {
    setCurrentContent((prevValue) => (prevValue === 0 ? prevValue : prevValue - 1))
  }
  const handleCheckout = () => {
    if (!scheduleIsValid()) {
      showErrorMessage(`Silahkan memilih ulang jam test drive Anda`)
    } else setModalOpen(true)
  }

  const handleBackNavigation = () => {
    isTaxi
      ? replace({
          pathname: `/beli/ex-taxi/detail/${idCatalog}`
        })
      : replace({
          pathname: `/beli/mobil-bekas/detail/${idCatalog}`
        })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    let err = ''

    switch (name) {
      case 'nama':
        err = nameValidation(value)
        break
      case 'phone':
        err = numberValidation(value, 'Nomor Telepon')
        break
      case 'email':
        err = emailValidation(value)
        break
      case 'alamat':
        err = addressValidation(value)
        break
      default:
        err = ''
    }

    setData((prev) => ({
      ...prev,
      [name]: value
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: err
    }))
  }

  const handleChangeWorkshop = (id) => {
    setData((prev) => ({
      ...prev,
      workshop_id: parseInt(id)
    }))
  }

  useQuery({
    queryKey: 'getWorkshop',
    queryFn: () => getWorkshops(),
    onSuccess: (data) => {
      setWorkshops(data)
      if (data.length > 0) {
        setData((prev) => ({
          ...prev,
          workshop_id: parseInt(data[0]?.id)
        }))
      }
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
  const postTestDrives = useMutation(postTestDrive, {
    onSuccess: (_) => {
      push(`/beli/${!isTaxi ? 'mobil-bekas' : 'ex-taxi'}/test-drive/result`, {
        data: {
          workshop_name: selectedWorkshop?.name,
          date: moment(schedule.date).format('DD MMM YYYY'),
          hours: schedule?.hours?.time
        }
      })
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })

  const handleConfirm = () => {
    const body = {
      tester_fullname: data.nama,
      tester_phone_number: data.phone,
      tester_email: data.email,
      tester_address: data.alamat,
      workshop_id: data.workshop_id,
      workshop_name: selectedWorkshop.name,
      test_drive_date: moment(schedule.date).format('DD/MM/YYYY'),
      test_drive_schedule_id: schedule?.hours?.value
    }
    if (isTaxi) {
      body.taxi_id = dataUnit?.id
    } else {
      body.catalog_id = dataUnit?.id
    }
    const dataSend = {
      body: body,
      token: token,
      isTaxi: isTaxi
    }
    postTestDrives.mutate(dataSend)
  }

  return (
    <Row className="py-10 gap-6 ">
      {/* Header Title */}
      <div className="text-center w-full display-sm-semibold text-gray-900">Test Drive</div>
      {/* Steps */}
      <div className="md:grid hidden grid-cols-1 w-1/4 mx-auto ">
        <Steps labelPlacement="vertical" current={currentContent} items={items} />
      </div>
      {/* mobile step */}
      <div className="md:hidden mx-auto ">
        <MobileStep current={currentContent} items={items} />
      </div>

      <Col md={20} span={22} className="mx-auto ">
        <div className="grid grid-cols-1 gap-6">
          {/* Left Content */}
          <div className="flex flex-col gap-6">
            {currentContent === 0 ? (
              <FormTestDriveContent
                dataCatalog={dataUnit}
                errors={errors}
                data={data}
                isTaxi={isTaxi}
                workshops={workshops}
                times={times}
                schedule={schedule}
                handleChange={handleChange}
                handleChangeWorkshop={handleChangeWorkshop}
                handleChangeSchedule={handleChangeSchedule}
              />
            ) : (
              <ReviewContent
                data={data}
                dataCatalog={dataUnit}
                workshops={workshops}
                selectedWorkshop={selectedWorkshop}
                selectedSchedule={selectedSchedule}
              />
            )}

            {/* Temporary Button */}
            <div className="flex  gap-3 justify-end items-center">
              {currentContent > 0 ? (
                <CustomButton onClick={handlePrev} type="secondary" label={'Sebelumnya'} />
              ) : (
                <CustomButton type="plain" label={'Batal'} onClick={handleBackNavigation} />
              )}
              {currentContent < items.length - 1 ? (
                <CustomButton onClick={handleNext} label={'Selanjutnya'} />
              ) : (
                <CustomButton onClick={handleCheckout} label={'Selanjutnya'} />
              )}
            </div>
          </div>
          {/* Right Content */}
          {/* <div className='hidden md:block'>
            <CardInfoTestDrive />
          </div> */}
        </div>
      </Col>
      <ModalLayout
        openModal={modalOpen}
        headerTitle={`Konfirmasi Test Drive`}
        className="w-full max-w-sm"
        onCancel={() => setModalOpen(false)}>
        <div className="grid grid-cols-1 gap-4">
          <div className="border border-solid border-gray-200 rounded-[4px] p-4">
            <p className="text-md-medium text-gray-900">{dataUnit?.unit_name}</p>
          </div>
          {/* Rincian Pembayaran */}
          <div className="grid grid-cols-1 gap-2">
            <p className="text-md-medium text-gray-500">Informasi Test Drive</p>
            {/* Tempat Test Drive*/}
            <div className=" flex flex-col">
              <p className="text-xs-regular text-gray-500">Tempat</p>
              <p className="text-sm-medium text-gray-700">{selectedWorkshop?.name}</p>
            </div>
            {/* Tanggal Test Drive */}
            <div className=" flex flex-col">
              <p className="text-xs-regular text-gray-500">Tanggal</p>
              <p className="text-sm-medium text-gray-700">
                {moment(data.date).format('DD MMM YYYY')}
              </p>
            </div>
            {/* jam Test Drive */}
            <div className=" flex flex-col">
              <p className="text-xs-regular text-gray-500">Jam</p>
              <p className="text-sm-medium text-gray-700">{selectedSchedule?.time}</p>
            </div>
            <Divider className="m-0 text-gray-300" />
          </div>
          {/* Button Container */}
          <div className="flex justify-end items-center gap-2">
            <Spin spinning={postTestDrives.isLoading}>
              <CustomButton onClick={() => setModalOpen(false)} type="plain" label={'Batal'} />
            </Spin>
            <Spin spinning={postTestDrives.isLoading}>
              <CustomButton label={'Konfirmasi'} onClick={handleConfirm} />
            </Spin>
          </div>
        </div>
      </ModalLayout>
    </Row>
  )
}

export default ScheduledTestDrivePage
