import { Col } from 'antd'
import {
  DaruratIcon,
  KonsultasiIcon,
  ServisBerkalaIcon,
  ServisUmumIcon,
  SukuCadangIcon
} from 'assets'
import CardLandingGradient from 'components/ui/card/card-landing-gradient'
import React from 'react'

function ServiceSection({ containerClassName, headerTitle = '' }) {
  const ServisBirdMobilData = [
    {
      label: 'Servis Berkala',
      icon: <ServisBerkalaIcon />
    },
    {
      label: 'Servis Umum',
      icon: <ServisUmumIcon />
    },
    {
      label: 'Darurat',
      icon: <DaruratIcon />
    },
    {
      label: 'Konsultasi',
      icon: <KonsultasiIcon />
    },
    {
      label: 'Suku Cadang',
      icon: <SukuCadangIcon />
    }
  ]
  return (
    <Col span={24} className={`${containerClassName ? containerClassName : 'py-20 bg-gray-50'} `}>
      <Col span={22} lg={{ span: 20 }} className="mx-auto gap-12 flex flex-col">
        {/* Section Title */}
        <div className="flex items-center justify-center text-center">
          <p className="display-xs-semibold xl:display-sm-semibold text-gray-900">{headerTitle}</p>
        </div>
        {/* Layanan Data */}
        <div className="lg:flex mx-auto lg:mx-0 grid grid-cols-1 gap-7 lg:gap-5 xl:gap-0 lg:flex-row justify-between">
          {ServisBirdMobilData.map((data, key) => (
            <CardLandingGradient key={key} parentContainerClassName={'max-w-[12rem] w-full'}>
              <div className="grid grid-cols-1 gap-5">
                <div className="relative flex justify-center items-end h-[70px] object-contain">
                  {data.icon}
                </div>
                <div className="flex flex-col justify-center items-center gap-2 self-end">
                  <div className="text-md-semibold md:text-lg-semibold text-gray-900">
                    {data.label}
                  </div>
                </div>
              </div>
            </CardLandingGradient>
          ))}
        </div>
      </Col>
    </Col>
  )
}

export default ServiceSection
