import React, { useState } from 'react'
import { useQuery } from 'react-query'
import {
  getAllBrands,
  getAllColors,
  getAllFuels,
  getAllModels,
  getAllTransmision,
  getAllTypes,
  getCategoriesByTypes
} from 'services/opt-spec-car'

const useGetOptSpecCar = (brandId, modelId, typeId) => {
  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [transmissions, setTransmissions] = useState([])
  const [types, setTypes] = useState([])
  const [fuels, setFuels] = useState([])
  const [colors, setColors] = useState([])
  const [categories, setCategories] = useState(null)
  useQuery({
    queryKey: 'getAllBrands',
    queryFn: () => getAllBrands(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setBrands(newData)
    }
  })
  useQuery({
    queryKey: ['getAllModels', brandId],
    queryFn: () => getAllModels(brandId),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setModels(newData)
    },
    enabled: brandId !== undefined,
    staleTime: 0
  })
  useQuery({
    queryKey: ['getAllTypes', modelId],
    queryFn: () => getAllTypes(modelId),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTypes(newData)
    },
    enabled: modelId !== undefined,
    staleTime: 0
  })
  useQuery({
    queryKey: ['getCategoriesbyType', typeId],
    queryFn: () => getCategoriesByTypes(typeId),
    onSuccess: (data) => {
      const categoriesArray = data?.categories?.map((cat) => cat?.name)
      setCategories(categoriesArray)
    },
    enabled: typeId !== undefined,
    staleTime: 0
  })
  useQuery({
    queryKey: 'getAllFuels',
    queryFn: () => getAllFuels(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setFuels(newData)
    }
  })
  useQuery({
    queryKey: 'getAllColors',
    queryFn: () => getAllColors(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setColors(newData)
    }
  })
  useQuery({
    queryKey: 'getAllTransmision',
    queryFn: () => getAllTransmision(),
    onSuccess: (data) => {
      const newData = data.map((el) => ({
        label: el.name,
        value: el.id
      }))
      setTransmissions(newData)
    }
  })
  return {
    brands,
    models,
    transmissions,
    types,
    fuels,
    colors,
    categories
  }
}

export default useGetOptSpecCar
