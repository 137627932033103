import { Input } from 'antd'
import { MagnifyingIcon } from 'assets'
import CardSectionHeader from 'components/ui/card-section-header'
import { useState } from 'react'
// import GoogleMapReact from 'google-map-react'

// const data = {
//   label: 'Birdmobil Surabaya',
//   caption: 'Jln. Raya Darmo Kali No. 2-6, Keputran, Kec. Tegalsari, Kota Surabaya, Jawa Timur 60211'
// }
const activeContainer = 'border-primary-300 bg-primary-50'
const deactiveContainer = 'border-gray-200 bg-white'

function CardLocationTestDrive({ workshops, data, handleChangeWorkshop }) {
  const [keyword, setKeyword] = useState('')
  const selectedWorkshop = workshops?.find((el) => el.id === parseInt(data.workshop_id))
  const filteredWorkshop = workshops.filter((el) => el.name.includes(keyword))
  return (
    <CardSectionHeader headerTitle={`Lokasi Test Drive`} hideDivider>
      <div className="grid grid-cols-1 gap-6 mt-6">
        {/* map location */}
        <div className="min-h-[328px] w-full">
          <iframe
            src={
              selectedWorkshop?.pin_google_map === '' || selectedWorkshop?.pin_google_map === null
                ? null
                : `https://maps.google.com/maps?q=${
                    selectedWorkshop?.pin_google_map?.split(',')?.[0]
                  },${selectedWorkshop?.pin_google_map?.split(',')?.[1]}&hl=es;z=14&output=embed`
            }
            className="h-full w-full border-0"
          />
          {/* <GoogleMapReact
            bootstrapURLKeys={{ key: '' }}
            defaultCenter={{
              lat: 10.99835602,
              lng: 77.01502627
            }}
            defaultZoom={11}></GoogleMapReact> */}
        </div>
        {/* search input */}
        <Input
          size="medium"
          className="text-md-normal text-gray-500 px-[14px] py-[5px] rounded-lg"
          prefix={<MagnifyingIcon className={'mr-1'} />}
          placeholder="Cari Bengkel"
          onChange={(e) => setKeyword(e.target.value)}
        />
        {/* list bengkel */}
        <div className="grid grid-cols-1 gap-4 p-3 overflow-y-auto max-h-[272px] bg-gray-50">
          {filteredWorkshop.map((el, index) => (
            <div
              className={`flex gap-2 border border-solid cursor-pointer p-4 rounded ${
                el.id === data.workshop_id ? activeContainer : deactiveContainer
              }`}
              key={index}
              onClick={() => handleChangeWorkshop(el.id)}>
              <div
                className={`w-5 h-5 flex-shrink-0 rounded-full border border-solid flex items-center justify-center ${
                  el.id === data.workshop_id
                    ? 'bg-primary-100 border-primary-500'
                    : 'bg-white border-gray-300 '
                }`}>
                <div
                  className={`w-[10px] h-[10px] ${
                    el.id === data.workshop_id ? 'bg-primary-500' : 'bg-white'
                  } rounded-full`}></div>
              </div>
              <div>
                <p
                  className={`text-sm-medium ${
                    el.id === data.workshop_id ? 'text-primary-700' : 'text-gray-700'
                  }`}>
                  {el?.name}
                </p>
                <p
                  className={`text-sm-normal ${
                    el.id === data.workshop_id ? 'text-primary-600' : 'text-gray-500'
                  }`}>
                  {el?.location_name || '-'}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default CardLocationTestDrive
