import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { getDashboardUnits } from '../service'
import { debounce } from 'lodash'

const useDashboardUnits = () => {
  const [dashboardUnits, setDashboardUnits] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const [filter, setFilter] = useState({
    limit: 3,
    offset: 0,
    keywords: null
  })
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      refetch()
    }, 200),
    []
  )
  const { refetch, isFetching: isFilterFetching } = useQuery({
    queryKey: ['getDashboardUnits', debouncedChangeHandler],
    queryFn: () => getDashboardUnits(filter),
    onSuccess: (data) => {
      setDashboardUnits(data)
    },
    enabled: Boolean(debouncedChangeHandler),
    refetchOnWindowFocus: false
  })

  // Functions
  function handlePagination(pageNumber) {
    setCurrentPage(pageNumber)
    const totalOffset = filter.limit * pageNumber - filter.limit
    handleFilter('offset', totalOffset)
  }
  function handleFilter(name, value) {
    setFilter({ ...filter, [name]: value })
    return debouncedChangeHandler()
  }
  return { handlePagination, currentPage, dashboardUnits, filter, handleFilter, isFilterFetching }
}

export default useDashboardUnits
