import { Col } from 'antd'
import CardInfoTestDrive from 'components/ui/card/card-info-test-drive'
import CardJadwalTestDrive from 'components/ui/card/card-jadwal-test-drive'
import CardLokasiInspeksi from 'components/ui/card/card-lokasi-inspeksi'
import CardPilihBengkel from 'components/ui/card/card-pilih-bengkel'
import CustomButton from 'components/ui/custom-button'
import CardRincianBiaya from 'features/beli-mobil/components/card/card-rincian-biaya'
function FormJadwalkanInspeksiContent({
  handleCancel,
  handleClick,
  isShowRoom,
  setIsShowRoom,
  state,
  price,
  taxPrice,
  totalPrice,
  homeState,
  schedule,
  provinces,
  cities,
  times,
  subdistricts,
  workshops,
  rangePrice,
  handleChangeLocation,
  handleChangeData,
  handleChangeHomeData,
  handleChangeSchedule,
  handleChangeActiveWorkshop
}) {
  return (
    <Col span={22} md={20} className="mx-auto grid grid-cols-1 md:grid-cols-[1fr_389px] gap-6">
      {/* left content */}
      <div>
        <div className="grid grid-cols-1 gap-6">
          {/* lokasi */}
          <CardLokasiInspeksi
            isShowRoom={isShowRoom}
            setIsShowRoom={setIsShowRoom}
            state={state}
            homeState={homeState}
            provinces={provinces}
            cities={cities}
            subdistricts={subdistricts}
            handleChangeLocation={handleChangeLocation}
            handleChangeData={handleChangeData}
            handleChangeHomeData={handleChangeHomeData}
            workshops={workshops}
            rangePrice={rangePrice}
            handleChangeActiveWorkshop={handleChangeActiveWorkshop}
          />
          {/* jadwal */}
          <CardJadwalTestDrive schedule={schedule} handleChangeSchedule={handleChangeSchedule} times={times} inspeksi />
          {/* pilih bengkel */}
          {
            !isShowRoom && <CardPilihBengkel workshops={workshops} data={state} handleChangeActiveWorkshop={handleChangeActiveWorkshop} />
          }
          <div className="flex gap-3 justify-end items-center">
            <CustomButton type="secondary" label={'Sebelumnya'} onClick={handleCancel} />
            <CustomButton label={'Selanjutnya'} onClick={handleClick} />
          </div>
        </div>
      </div>
      {/* right content */}
      <div className='hidden md:block'>
        <div className="grid grid-cols-1 gap-6">
          <CardRincianBiaya
            isShowRoom={isShowRoom}
            homeState={homeState}
            price={price}
            taxPrice={taxPrice}
            totalPrice={totalPrice}
          />
          {/* <CardInfoTestDrive /> */}
        </div>
      </div>
    </Col>
  )
}

export default FormJadwalkanInspeksiContent
