const NullServisIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="211"
      height="157"
      viewBox="0 0 211 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M53.8203 113.502V52.8579L105.327 21.9551V83.9596L53.8203 113.502Z" fill="#F2F4F7" />
      <path
        d="M53.8242 113.763C53.7766 113.761 53.73 113.749 53.6881 113.726C53.6499 113.702 53.6183 113.669 53.5963 113.63C53.5744 113.591 53.5627 113.546 53.5625 113.501V52.8574C53.5632 52.8124 53.5751 52.7683 53.597 52.729C53.6189 52.6898 53.6502 52.6565 53.6881 52.6323L105.189 21.7557C105.23 21.7327 105.276 21.7207 105.323 21.7207C105.37 21.7207 105.415 21.7327 105.456 21.7557C105.497 21.7777 105.53 21.8102 105.553 21.8499C105.576 21.8896 105.588 21.9348 105.587 21.9807V83.9538C105.588 84.0004 105.576 84.0463 105.553 84.0867C105.53 84.1272 105.497 84.1608 105.456 84.1841L53.9498 113.726C53.9116 113.749 53.8684 113.761 53.8242 113.763ZM54.0858 53.0091V113.041L105.064 83.8021V22.4203L54.0858 53.0091Z"
        fill="#98A2B3"
      />
      <path d="M105.324 83.9596L158.191 113.502V52.277L105.324 21.9551V83.9596Z" fill="white" />
      <path
        d="M158.194 113.763C158.148 113.761 158.103 113.749 158.063 113.726L105.206 84.1842C105.165 84.1616 105.131 84.1283 105.107 84.0878C105.083 84.0472 105.07 84.001 105.07 83.9539V21.9808C105.071 21.9351 105.083 21.8903 105.106 21.8509C105.129 21.8114 105.162 21.7786 105.201 21.7558C105.241 21.7328 105.286 21.7207 105.332 21.7207C105.378 21.7207 105.423 21.7328 105.463 21.7558L158.319 52.0777C158.359 52.1001 158.393 52.1328 158.416 52.1724C158.439 52.2119 158.451 52.257 158.45 52.3027V113.533C158.449 113.578 158.436 113.623 158.414 113.662C158.391 113.701 158.358 113.734 158.319 113.758C158.279 113.769 158.236 113.771 158.194 113.763ZM105.588 83.8022L157.922 113.051V52.4283L105.588 22.4099V83.8022Z"
        fill="#98A2B3"
      />
      <path
        d="M53.8203 113.501L105.327 144.205L158.194 113.501L105.327 83.959L53.8203 113.501Z"
        fill="white"
      />
      <path
        d="M105.326 144.467C105.279 144.469 105.234 144.456 105.195 144.43L53.6883 113.721C53.6486 113.699 53.6159 113.666 53.5937 113.627C53.5715 113.587 53.5608 113.542 53.5627 113.496C53.5619 113.45 53.5736 113.405 53.5967 113.365C53.6198 113.326 53.6533 113.293 53.6936 113.271L105.195 83.7343C105.235 83.7113 105.28 83.6992 105.326 83.6992C105.372 83.6992 105.417 83.7113 105.456 83.7343L158.313 113.276C158.354 113.298 158.388 113.331 158.412 113.37C158.436 113.41 158.449 113.455 158.449 113.501C158.449 113.548 158.437 113.593 158.414 113.634C158.391 113.674 158.358 113.708 158.318 113.732L105.456 144.441C105.416 144.461 105.371 144.47 105.326 144.467ZM54.3425 113.501L105.331 143.918L157.664 113.517L105.331 84.2576L54.3425 113.501Z"
        fill="#98A2B3"
      />
      <path d="M106.008 113.501V82.6919L131.761 67.7246V98.7269L106.008 113.501Z" fill="#EAECF0" />
      <path
        d="M106.023 113.764C105.978 113.761 105.933 113.749 105.893 113.727C105.854 113.704 105.821 113.671 105.798 113.631C105.776 113.592 105.763 113.548 105.762 113.502V82.6883C105.762 82.6426 105.774 82.5978 105.797 82.5583C105.82 82.5189 105.853 82.4861 105.893 82.4633L131.63 67.5116C131.67 67.4887 131.715 67.4766 131.761 67.4766C131.807 67.4766 131.852 67.4887 131.892 67.5116C131.931 67.5352 131.963 67.5682 131.986 67.6075C132.009 67.6468 132.021 67.6912 132.023 67.7366V98.7389C132.022 98.7846 132.01 98.8294 131.987 98.8689C131.964 98.9083 131.931 98.9411 131.892 98.964L106.139 113.738C106.102 113.754 106.063 113.763 106.023 113.764ZM106.285 82.8453V113.042L131.515 98.5715V68.1815L106.285 82.8453Z"
        fill="#98A2B3"
      />
      <path d="M106.008 113.501L79.5742 98.7269V67.7246L106.008 82.6919V113.501Z" fill="#EAECF0" />
      <path
        d="M106.023 113.763C105.979 113.761 105.936 113.748 105.898 113.726L79.4642 98.9575C79.4233 98.9344 79.3891 98.901 79.3652 98.8606C79.3413 98.8202 79.3285 98.7742 79.3281 98.7272V67.7249C79.3294 67.6795 79.342 67.6351 79.3649 67.5958C79.3877 67.5565 79.4201 67.5235 79.459 67.4999C79.4987 67.4769 79.5439 67.4648 79.5898 67.4648C79.6357 67.4648 79.6808 67.4769 79.7206 67.4999L106.154 82.4672C106.195 82.4888 106.229 82.5212 106.252 82.561C106.275 82.6008 106.286 82.6463 106.285 82.6923V113.501C106.285 113.547 106.273 113.591 106.25 113.631C106.227 113.67 106.194 113.703 106.154 113.726C106.114 113.749 106.069 113.762 106.023 113.763ZM79.8567 98.5859L105.746 113.04V82.844L79.8358 68.1907L79.8567 98.5859Z"
        fill="#98A2B3"
      />
      <path
        d="M79.5742 67.7256L105.327 52.6641L131.761 67.7256L106.008 82.6929L79.5742 67.7256Z"
        fill="#EAECF0"
      />
      <path
        d="M106.023 82.9549C105.98 82.9551 105.936 82.9443 105.898 82.9235L79.4642 67.9562C79.424 67.9336 79.3904 67.901 79.3665 67.8616C79.3427 67.8222 79.3294 67.7772 79.3281 67.7312C79.3285 67.6848 79.3407 67.6393 79.3636 67.599C79.3865 67.5587 79.4193 67.5249 79.459 67.5009L105.212 52.4394C105.252 52.4164 105.297 52.4043 105.343 52.4043C105.389 52.4043 105.434 52.4164 105.474 52.4394L131.892 67.5114C131.932 67.5342 131.965 67.5673 131.989 67.6073C132.012 67.6473 132.024 67.6927 132.024 67.739C132.024 67.7853 132.012 67.8308 131.989 67.8707C131.965 67.9107 131.932 67.9438 131.892 67.9667L106.138 82.9288C106.103 82.9476 106.063 82.9566 106.023 82.9549ZM80.1184 67.7259L106.023 82.3793L131.253 67.7259L105.348 52.9627L80.1184 67.7259Z"
        fill="#98A2B3"
      />
      <path
        d="M106.007 113.502L70.6036 123.503L53.8203 113.502L79.5735 98.7285L106.007 113.502Z"
        fill="#98A2B3"
      />
      <path
        d="M70.6023 123.763C70.5544 123.764 70.5071 123.752 70.4662 123.727L53.6881 113.721C53.6496 113.697 53.6177 113.664 53.5957 113.625C53.5737 113.585 53.5623 113.541 53.5625 113.495C53.5617 113.45 53.5735 113.404 53.5965 113.365C53.6196 113.325 53.6531 113.292 53.6934 113.27L79.4466 98.502C79.4854 98.4789 79.5297 98.4668 79.5748 98.4668C79.6199 98.4668 79.6642 98.4789 79.703 98.502L106.131 113.27C106.177 113.295 106.214 113.332 106.238 113.378C106.262 113.424 106.273 113.475 106.267 113.527C106.261 113.578 106.239 113.626 106.206 113.665C106.172 113.704 106.128 113.732 106.079 113.747L70.6703 123.753L70.6023 123.763ZM54.3371 113.501L70.6389 123.224L105.331 113.417L79.5774 99.0253L54.3371 113.501Z"
        fill="#98A2B3"
      />
      <path
        d="M151.163 80.2062C151.113 80.2039 151.065 80.1914 151.021 80.1696L139.628 73.8896C139.582 73.8656 139.544 73.8295 139.517 73.7852C139.491 73.7409 139.476 73.6901 139.477 73.6384V54.3745C139.476 54.3233 139.49 54.2731 139.516 54.229C139.542 54.1848 139.579 54.1483 139.623 54.1232C139.668 54.0975 139.718 54.084 139.77 54.084C139.821 54.084 139.872 54.0975 139.916 54.1232L151.309 60.7068C151.353 60.7323 151.39 60.7689 151.416 60.8129C151.441 60.857 151.455 60.907 151.456 60.958V79.9131C151.455 79.9641 151.441 80.0141 151.416 80.0582C151.39 80.1022 151.353 80.1388 151.309 80.1643C151.265 80.1914 151.214 80.2059 151.163 80.2062ZM140.037 73.4552L150.854 79.4212V61.1045L140.037 54.8769V73.4552Z"
        fill="#D0D5DD"
      />
    </svg>
  )
}

export default NullServisIcon
