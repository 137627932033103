const SukuCadangIcon = ({ className }) => {
  return (
    <svg
      width="70"
      className={className}
      height="82"
      viewBox="0 0 70 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M49.3648 52.3125L46.7809 54.6976C45.7871 55.6251 44.3959 56.1552 43.0046 56.1552H36.1143H26.9052C25.5138 56.1552 24.1888 55.6251 23.1288 54.6976L20.5449 52.3125"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.2353 68.6117H48.7012V66.7567C48.7012 64.9016 50.0925 63.3115 51.8813 63.0465L59.2353 62.0527"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0762 62.0527L17.4302 63.0465C19.2853 63.3115 20.6103 64.9016 20.6103 66.7567V68.6117H10.0762"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.44531 57.6133V75.0377"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.44518 57.6127L1.53005 54.4326C1.19879 54.1013 1 53.6375 1 53.1738V49.7286C1 48.8011 1.72876 48.0061 2.72255 48.0061H9.34781L15.1118 37.0081C16.2381 35.4843 18.0269 34.623 19.882 34.623H34.7226H49.5632C51.4183 34.623 53.207 35.4843 54.3333 37.0081L60.0974 48.0061H66.7226C67.6502 48.0061 68.4452 48.7348 68.4452 49.7286V53.1738C68.4452 53.6375 68.2464 54.1013 67.9151 54.4326L65 57.6127V75.1034"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.2676 75.0371V75.6996C54.2676 78.6147 56.6527 80.9998 59.5678 80.9998C62.4829 80.9998 64.868 78.6147 64.868 75.6996V75.0371"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0457 75.0371V75.6996C15.0457 78.6147 12.6606 80.9998 9.74552 80.9998C6.83041 80.9998 4.44531 78.6147 4.44531 75.6996V75.0371"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.2356 75.0371H35.7159H10.0762"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4824 68.6113H38.8965"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6472 3.55072L12.5934 13.6211C12.3946 14.7474 13.3222 15.7412 14.4485 15.6087C22.0013 14.6149 34.9205 13.0911 51.9474 15.2774L54.8626 15.6749C55.9889 15.8075 56.9164 14.8137 56.7176 13.6874L54.6638 3.61698C54.5313 2.95445 53.935 2.42443 53.2725 2.29193C40.8833 0.569358 28.4941 0.569358 16.0386 2.29193C15.376 2.35818 14.7797 2.8882 14.6472 3.55072Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.4779 11.5659C46.5814 10.8371 40.6186 10.4396 34.7884 10.4396C27.3681 10.4396 21.0742 11.0359 16.834 11.5659L18.0266 5.66942C23.5918 4.94064 29.157 4.60938 34.7222 4.60938C40.2211 4.60938 45.8526 4.94064 51.4178 5.66942L52.6104 11.5659H52.4779Z"
        fill="#E12B25"
      />
      <path
        d="M34.6562 17.332V27.0049"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2051 28.4609L34.6565 30.9123L37.1078 28.4609"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SukuCadangIcon
