import { deleteWishlistId, getWishlistIds, postWishlistId } from 'features/wishlist/service'
import React from 'react'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'

function useWishlist() {
  const [wishlistIds, setWishlistIds] = useState([])
  const wishlistIdsFn = useQuery({
    queryKey: 'getWishlistIds',
    queryFn: () => getWishlistIds(),
    onSuccess: (data) => {
      // console.log(data)
      setWishlistIds(data)
    },
    enabled: localStorage.getItem('token') !== null,
    staleTime: 0
  })
  const sendWishlistId = useMutation({
    mutationFn: (id) => {
      return postWishlistId(id)
    },
    onSuccess: (datas) => {
      wishlistIdsFn.refetch()
      // showSuccessMessage(datas?.message)
      showSuccessMessage('Berhasil Menambahkan Unit ke dalam Wishlist')
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })
  const deleteWishlist = useMutation({
    mutationFn: (id) => {
      return deleteWishlistId(id)
    },
    onSuccess: (datas) => {
      wishlistIdsFn.refetch()
      // showSuccessMessage(datas?.message)
      showSuccessMessage('Berhasil Menghapus Unit dari Wishlist')
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })

  function handleWishlist(catalogId, isBookmark) {
    if (!localStorage.getItem('token')) return showErrorMessage('Anda harus login terlebih dahulu')
    if (isBookmark) {
      deleteWishlist.mutate(catalogId)
    } else {
      sendWishlistId.mutate(catalogId)
    }
  }
  return {
    wishlistIds,
    handleWishlist
  }
}

export default useWishlist
