const AvatarIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      stroke="#C11B23"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.3346 22.5V20.1667C20.3346 18.929 19.843 17.742 18.9678 16.8668C18.0926 15.9917 16.9056 15.5 15.668 15.5H6.33464C5.09696 15.5 3.90997 15.9917 3.0348 16.8668C2.15963 17.742 1.66797 18.929 1.66797 20.1667V22.5M15.668 6.16667C15.668 8.744 13.5786 10.8333 11.0013 10.8333C8.42397 10.8333 6.33464 8.744 6.33464 6.16667C6.33464 3.58934 8.42397 1.5 11.0013 1.5C13.5786 1.5 15.668 3.58934 15.668 6.16667Z"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AvatarIcon
