import { Col, Row } from 'antd'
import { ExTaxiDetail, SummaryCar } from 'features/beli-mobil'
import { ModalPesanSekarang } from 'features/beli-mobil/components/modal'
import { getDetailCatalogTaxi } from 'features/beli-mobil/service'
import { getAllColors } from 'features/jual-mobil/service'
import { useCYOC, useWishlist } from 'hooks'
import { ModalLayout } from 'layouts'
import { random } from 'lodash'

import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

const isCreateYourOwnCar = true
function BeliExTaxiDetailPage() {
  const { push } = useHistory()
  const token = localStorage.getItem('token')
  const { id: idCatalog } = useParams()
  // get isCreateYourOwnCar
  const [isBookmark, setIsBookmark] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [dataTaxi, setDataTaxi] = useState({})
  const [colors, setColors] = useState([])
  const [selectedColor, setSelectedColor] = useState('')
  const { cyoc, selectedSpareparts, handleCYOC, resetCYOC, deleteSelectedSpareparts } = useCYOC(
    idCatalog,
    isCreateYourOwnCar,
    true
  )
  const { wishlistIds, handleWishlist } = useWishlist()

  // start of fetching
  useQuery({
    queryKey: 'getAllColors',
    queryFn: () => getAllColors(),
    onSuccess: (data) => {
      setColors(data)
    }
  })
  useQuery({
    queryKey: ['getDetailCatalogTaxis'],
    queryFn: () => getDetailCatalogTaxi(idCatalog),
    onSuccess: (data) => {
      const newObjThumbnail = {
        id: random(),
        photo_filename: data?.thumbnail_filename ? data?.thumbnail_filename : undefined
      }
      const newData = {
        ...data
      }
      newData.photos.push(newObjThumbnail)
      setDataTaxi(newData)
    }
  })
  // end of fetching

  // function
  const handleModal = () => {
    setModalOpen(true)
  }
  const handleBookmark = () => {
    setIsBookmark((prevValue) => !prevValue)
  }
  const handleSelectedColor = (id) => {
    if (token) {
      setSelectedColor(id)
    } else {
      push('/login')
    }
  }
  function handleNavigation(path) {
    return push({
      pathname: path,
      state: {
        id: dataTaxi?.id,
        isTaxi: true,
        color_id: selectedColor,
        cyoc_spareparts: selectedSpareparts,
        is_create_your_own_car: true
      }
    })
  }
  return (
    <>
      <Row className="my-10">
        <Col span={20} className="mx-auto grid grid-cols-1 gap-6">
          {/* Breadcrumb */}
          {/* Mobil Bekas / Ex Taxi Detail */}
          <ExTaxiDetail
            dataTaxi={dataTaxi}
            cyoc={cyoc}
            colors={colors}
            handleCYOC={handleCYOC}
            resetCYOC={resetCYOC}
            selectedSpareparts={selectedSpareparts}
            selectedColor={selectedColor}
            handleSelectedColor={handleSelectedColor}
          />
        </Col>
        <SummaryCar
          isTaxi
          dataUnit={dataTaxi}
          handleModal={handleModal}
          handleWishlist={handleWishlist.bind(this, dataTaxi?.id)}
          isBookmark={wishlistIds.includes(dataTaxi?.id)}
          handleBookmark={handleBookmark}
          selectedSpareparts={selectedSpareparts}
          selectedColor={selectedColor}
          deleteSelectedSpareparts={deleteSelectedSpareparts}
        />
      </Row>
      <ModalLayout
        openModal={modalOpen}
        headerTitle={`Pilih Opsi`}
        className="w-fit"
        onCancel={() => setModalOpen(false)}>
        <ModalPesanSekarang
          isTaxi={true}
          handleNavigation={handleNavigation}
          selectedColor={selectedColor}
        />
      </ModalLayout>
    </>
  )
}

export default BeliExTaxiDetailPage
