import React from 'react'
import CardSectionHeader from '../card-section-header'
import { ImagePlaceholder } from 'assets'
import { onRupiah } from 'utils/formatCurrency'
import moment from 'moment'

function CardKelengkapanDokumen({ data, handleOpenModalImage, urlType }) {
  const urlImage = process.env.REACT_APP_IMAGE_BASE_URL
  const booleanConverter = (value) => (value ? 'ADA' : 'TIDAK ADA')
  const documentData = [
    {
      id: 1,
      child: [
        {
          label: 'Odometer',
          type: 'text',
          value: onRupiah(data?.odometer)
        },
        {
          label: 'No. Rangka',
          type: 'text',
          value: data?.frame_number
        },
        {
          label: 'Foto No. Rangka',
          type: 'image',
          value: data?.frame_number_photo_filename,
          hidden: true
        },
        {
          label: 'No. Mesin',
          type: 'text',
          value: data?.engine_number
        },
        {
          label: 'Foto No. Mesin',
          type: 'image',
          value: data?.engine_number_photo_filename,
          hidden: true
        }
      ]
    },
    {
      id: 2,
      child: [
        {
          label: 'STNK',
          type: 'text',
          value: booleanConverter(data?.document_stnk_status)
        },
        {
          label: 'Foto STNK',
          type: 'image',
          value: data?.stnk_photo,
          hidden: true
        },
        {
          label: 'BPKB',
          type: 'text',
          value: booleanConverter(data?.document_bpkb_status)
        },
        {
          label: 'Foto BPKB',
          type: 'image',
          value: data?.bpkb_photo,
          hidden: true
        },
        {
          label: 'Faktur',
          type: 'text',
          value: booleanConverter(data?.document_faktur_status)
        }
      ]
    },
    {
      id: 3,
      child: [
        {
          label: 'KTP Pemilik',
          type: 'text',
          value: booleanConverter(data?.document_ktp_status)
        },
        {
          label: 'Kwitansi',
          type: 'text',
          value: booleanConverter(data?.document_kwitansi_status)
        },
        {
          label: 'KEUR',
          type: 'text',
          value: booleanConverter(data?.document_keur_status)
        },
        {
          label: 'Form A',
          type: 'text',
          value: booleanConverter(data?.document_form_status)
        },
        {
          label: 'Isi Cilinder',
          type: 'text',
          value: onRupiah(data?.engine_capacity || 0)
        },
        {
          label: 'Pajak Berlaku',
          type: 'text',
          value: moment(data?.tax_expiry).format('DD-MM-YYYY')
        },
        {
          label: 'Tangan Pertama',
          type: 'text',
          value: data?.unit_ownership || '-',
          hidden: true
        },
      ]
    },
    {
      id: 4,
      child: [
        {
          label: 'KM Service Terakhir',
          type: 'text',
          value: `${onRupiah(data?.latest_service || 0)} KM` || '-'
        },
        {
          label: 'Tanggal Service Terakhir',
          type: 'text',
          value: moment(data?.latest_service_date).format('DD-MM-YYYY')
        },
        {
          label: 'Warna Eksterior',
          type: 'text',
          value: data?.exterior_color_name || '-'
        },
        {
          label: 'Warna Interior',
          type: 'text',
          value: data?.interior_color_name || '-'
        },
        {
          label: 'Bahan Interior',
          type: 'text',
          value: data?.interior_material || '-'
        },
        {
          label: 'Remark',
          type: 'text',
          value: data?.remark
        }
      ]
    }
  ]
  return (
    <CardSectionHeader headerTitle={'Kelengkapan Dokumen'}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
        {documentData.map((el) => (
          <div key={el.id} className={el.id === 1 || el.id === 3 ? 'md:border-x-2 border-gray-100 md:px-2' : ''}>
            {el.child.map((item, index) => (
              <div className={`${urlType === 'beli' && item.hidden ? 'hidden' : 'grid'} grid-cols-2 mb-2`} key={index}>
                <p className="text-md-medium text-gray-900">{item.label}</p>
                {item.type === 'text' ? (
                  <p className="text-md-regular text-gray-700 break-all" >{item.value}</p>
                ) : (
                  <div
                    className="overflow-hidden max-w-fit rounded-xl border"
                    onClick={() =>
                      item?.value ? handleOpenModalImage(urlImage.concat(item?.value)) : {}
                    }>
                    <img
                      src={item.value ? urlImage.concat(item?.value) : ImagePlaceholder}
                      alt="detail-value "
                      className="h-[64px] w-[84px] object-contain"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </CardSectionHeader>
  )
}
export default CardKelengkapanDokumen
