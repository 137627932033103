import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
function CardPreviewHarga({ price, setModalPrice }) {
  return (
    <CardSectionHeader hideDivider>
      <div className="grid grid-cols-1 gap-6">
        {/* content data */}
        <div className="flex items-center justify-between gap-6">
          <p className="text-xl-medium text-gray-900">Harga yang ditetapkan</p>
          <p className="text-md-medium text-primary-600 cursor-pointer" onClick={setModalPrice}>Ubah harga</p>
        </div>
        <p className="display-xs-semibold text-warning-500">Rp {price}</p>
      </div>
    </CardSectionHeader>
  )
}

export default CardPreviewHarga
