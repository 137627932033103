import { useMemo } from 'react'

export default function MobileStep({ current, items }) {
  const selectedItem = useMemo(() => {
    return items.find((_, idx) => idx === current)
  }, [current])

  return (
    <div className="flex items-center gap-2 text-primary-500">
      <div className="text-xs-medium text text-primary-500 w-[34px] inline-block h-[34px] border border-solid border-primary-500 rounded-full">
        <div className="flex h-full w-full items-center justify-center">{current + 1}</div>
      </div>
      <div className="text-md-regular text-primary-700">{selectedItem.title}</div>
    </div>
  )
}
