import CardFormPesanMobil from 'components/ui/card/card-form-pesan-mobil'
import CardJadwalTestDrive from 'components/ui/card/card-jadwal-test-drive'
import CardUnit from 'components/ui/card/card-unit'
import React from 'react'
import { CardLocationTestDrive } from '../card'

const FormTestDriveContent = ({
  dataCatalog,
  isTaxi,
  data,
  schedule,
  errors,
  workshops,
  times,
  handleChange,
  handleChangeWorkshop,
  handleChangeSchedule
}) => {
  return (
    <div className="grid grid-cols-1 gap-6">
      <CardUnit dataCatalog={dataCatalog} isTaxi={isTaxi} />
      {/* Form Data diri */}
      <CardFormPesanMobil data={data} handleChange={handleChange} errors={errors} />
      {/* Form pilih lokasi */}
      <CardLocationTestDrive
        workshops={workshops}
        data={data}
        handleChangeWorkshop={handleChangeWorkshop}
      />
      {/* Form Pilih jadwal */}
      <CardJadwalTestDrive
        schedule={schedule}
        handleChangeSchedule={handleChangeSchedule}
        times={times}
      />
    </div>
  )
}

export default FormTestDriveContent
