import React from 'react'
import FormInput from 'components/ui/form/form-input'
import dayjs from 'dayjs'
import { DatePicker, Spin } from 'antd'
import { ModalLayout } from 'layouts'
import CustomButton from '../custom-button'
const ModalAddCreditCard = ({
  openModal,
  onCancel,
  handleChange,
  creditCard,
  submitAddCard,
  isAddCardLoading,
  errors
}) => {
  return (
    <ModalLayout
      headerTitle={'Tambah Kartu Debit / Kredit'}
      openModal={openModal}
      wrapClassName=""
      footer={null}
      className="max-w-md w-full"
      width={''}
      onCancel={onCancel}>
      <div className="grid grid-cols-1 gap-3">
        {/* Placeholder Name */}
        <FormInput
          data={{
            label: 'Nama Pemegang Kartu',
            mandatory: true,
            placeholder: 'Nama Pemegang Kartu',
            name: 'cardholder_name',
            onChange: (e) => handleChange('cardholder_name', e.target.value),
            value: creditCard?.cardholder_name
          }}
        />
        {errors.cardholder_name ? (
          <small className="text-primary-300">{errors.cardholder_name}</small>
        ) : null}
        {/* Card Number */}
        <FormInput
          data={{
            maxLength: 19,
            label: 'Nomor Kartu',
            mandatory: true,
            placeholder: 'Nomor Kartu',
            name: 'card_number',
            onChange: (e) => handleChange('card_number', e.target.value),
            value: creditCard?.card_number
          }}
        />
        {errors.card_number ? (
          <small className="text-primary-300">{errors.card_number}</small>
        ) : null}
        {/* Expired Credit Card */}
        <div className="flex flex-col gap-2 ">
          <p className="text-sm-medium text-gray-700">Tanggal Kadaluarsa <span className='text-danger-500'>*</span></p>
          <DatePicker
            className="rounded-md"
            name="expired_at"
            defaultValue={
              creditCard?.card_number !== null ? dayjs(creditCard?.expired_at, 'MM/YYYY') : null
            }
            onChange={(_, dateString) => handleChange('expired_at', dateString)}
            format={'MM/YYYY'}
            picker="month"
            placeholder="MM/YYYY"
          />
        </div>
        {errors.expired_at ? <small className="text-primary-300">{errors.expired_at}</small> : null}
        {/* CVV */}
        <FormInput
          data={{
            type: 'password',
            maxLength: 3,
            label: 'CVV',
            mandatory: true,
            placeholder: '***',
            name: 'cvv',
            value: creditCard?.cvv ? creditCard?.cvv : null,
            onChange: (e) => handleChange('cvv', e.target.value)
          }}
        />
        {errors.cvv ? <small className="text-primary-300">{errors.cvv}</small> : null}
        <div className="flex gap-3 justify-end items-center">
          <Spin spinning={isAddCardLoading}>
            <CustomButton onClick={onCancel} type="plain" label={'Batal'} />
          </Spin>
          <Spin spinning={isAddCardLoading}>
            <CustomButton onClick={submitAddCard} label={'Simpan'} />
          </Spin>
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalAddCreditCard
