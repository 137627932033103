import Resizer from 'react-image-file-resizer'

export const imageResizer = (path) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      path,
      2160,
      3840,
      'WEBP',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })
