import { Input } from "antd";
import Password from "antd/lib/input/Password";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import CustomButton from "components/ui/custom-button";
import { useHistory } from "react-router-dom";
function InputCompany({
    firstState,
    firstError,
    handleChangeFirstState,
    handleSubmit
}) {
    const { push } = useHistory();
    const handleClick = () => {
        handleSubmit()
    }
    const handleCancel = () => {
        push('/register')
    }
    return (
        <>
            <div className="flex flex-col gap-1">
                <label htmlFor="name" className="text-sm-medium text-gray-700">
                    Nama Perusahaan <span className='text-danger-500'>*</span>
                </label>
                <Input
                    type="text"
                    className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
                    name="company_name"
                    placeholder="Nama Perusahaan"
                    value={firstState?.company_name}
                    onChange={(e) => handleChangeFirstState(e)}
                />
                <small className="text-primary-300">{firstError.company_name}</small>
            </div>
            <div className="flex flex-col gap-1">
                <label htmlFor="phone" className="text-sm-medium text-gray-700">
                    Nomor Telepon <span className='text-danger-500'>*</span>
                </label>
                <Input
                    addonBefore="+62"
                    type="text"
                    className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
                    name="phone_number"
                    placeholder="Nomor Telepon"
                    value={firstState?.phone_number}
                    onChange={(e) => handleChangeFirstState(e)}
                />
                <small className="text-primary-300">{firstError.phone_number}</small>
            </div>
            <div className="flex flex-col gap-1">
                <label htmlFor="email" className="text-sm-medium text-gray-700">
                    Email <span className='text-danger-500'>*</span>
                </label>
                <Input
                    type="email"
                    className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
                    name="email"
                    placeholder="email@birdmobil.com"
                    value={firstState?.email}
                    onChange={(e) => handleChangeFirstState(e)}
                />
                <small className="text-primary-300">{firstError.email}</small>
            </div>
            <div className="flex flex-col gap-1">
                <label htmlFor="password" className="text-sm-medium text-gray-700">
                    Password <span className='text-danger-500'>*</span>
                </label>
                <Password
                    placeholder="Password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    className="flex justify-center text-md-normal items-center rounded-lg bg-white shadow-xs"
                    name="password"
                    value={firstState?.password}
                    onChange={(e) => handleChangeFirstState(e)}
                />
                <small className="text-gray-400">Password tidak boleh kurang dari 8 karakter dengan kombinasi angka.</small>
                <small className="text-primary-300">{firstError.password}</small>
            </div>
            <div className="flex flex-col gap-1">
                <label htmlFor="company_email" className="text-sm-medium text-gray-700">
                    NPWP <span className='text-danger-500'>*</span>
                </label>
                <Input
                    type="text"
                    className="flex justify-center text-md-normal gap-3 items-center rounded-lg bg-white shadow-xs"
                    name="npwp"
                    placeholder="NPWP"
                    // maxLength={16}
                    value={firstState?.npwp}
                    onChange={(e) => handleChangeFirstState(e)}
                />
                <small className="text-primary-300">{firstError.npwp}</small>
            </div>
            <div className="grid grid-cols-2 gap-2">
                <CustomButton label="Kembali" type="plain" onClick={handleCancel} />
                <CustomButton label="Selanjutnya" onClick={handleClick} />
            </div>
        </>
    )
}

export default InputCompany;