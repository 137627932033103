import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { postSimulateService } from '../service'

const useCartService = () => {
  const [cartList, setCartList] = useState({})
  const [cartIsLoading, setCartIsLoading] = useState(false)
  const simulateCreateService = useMutation({
    mutationFn: (dataSend) => postSimulateService(dataSend),
    onSuccess: (data) => {
      setCartList(data)
      setCartIsLoading(false)
    }
  })
  function handleSimulateCartService(
    range_price,
    selectedVoucherIds,
    servicePackets,
    servicesGenerals,
    unitId
  ) {
    const newServicePackets = []
    servicePackets.length > 0
      ? servicePackets.map((packet) => {
          const newGeneralServices = []
          packet.general_services.map((service) => {
            const newGeneralObj = {
              general_service_id: service.general_service.id,
              sparepart_id: service.sparepart.id
            }
            newGeneralServices.push(newGeneralObj)
          })

          const newObj = {
            service_id: packet.service_id,
            packet_id: packet.packet_id,
            general_services: newGeneralServices
          }
          newServicePackets.push(newObj)
        })
      : null

    // HANDLE OBJECT SERVICE_GENERALS FOR BODY BOOKING API
    const newServiceGenerals = []
    servicesGenerals.length > 0
      ? servicesGenerals.map((service) => {
          const newGeneralService = {
            service_id: service.service_id,
            general_service_id: service.general_service.id,
            sparepart_id: service.sparepart.id
          }
          newServiceGenerals.push(newGeneralService)
        })
      : null

    const newVoucherIdsArr =
      Object.keys(selectedVoucherIds).length > 0
        ? Object.keys(selectedVoucherIds).map((id) => parseInt(id))
        : []

    const dataSend = {
      unit_id: unitId,
      service_packets: newServicePackets,
      service_generals: newServiceGenerals,
      voucher_ids: newVoucherIdsArr
    }
    if (range_price?.value) {
      dataSend.booking_type = 'PICKUP'
      dataSend.distance_id = range_price.value
    } else if (range_price?.value === undefined) {
      dataSend.booking_type = 'SERVICE'
    }
    setCartIsLoading(true)
    simulateCreateService.mutate(dataSend)
  }
  function deleteCartList() {
    setCartList({})
  }
  return { cartList, handleSimulateCartService, deleteCartList, cartIsLoading }
}

export default useCartService
