import { Col, Row } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { companyContent, personalContent } from '../assets'

function SignUpContent() {
  const { type } = useParams()
  return (
    <div className="flex items-center justify-end h-full w-full">
      <Col span={20}>
        <Row>
          <Col span={24} className="grid grid-cols-1 gap-6 ">
            <div className="grid grid-cols-1 gap-2 text-white w-4/5">
              <p className="display-xs-semibold ">
                BirdMobil {type === 'company' ? 'Perusahaan' : 'Personal'}
              </p>
              <p className="text-xl-regular">Perawatan Mobil Anda dengan Mudah & Nyaman</p>
            </div>
            <div className="sign-up-content border-gray-900 border-y-8 border-l-8 overflow-hidden">
              <img
                src={type === 'company' ? companyContent : personalContent}
                alt="content signup"
              />
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default SignUpContent
