import { RegisterTypeCompany, RegisterTypePersonal } from 'features/register-type'
import { useHistory, useParams } from 'react-router-dom'

function RegisterType() {
  const { type } = useParams()
  const { replace } = useHistory()
  const isB2B = localStorage.getItem('account_type')
  if (isB2B !== null) {
    replace('/')
  }

  return <>{type === 'company' ? <RegisterTypeCompany /> : <RegisterTypePersonal />}</>
}

export default RegisterType
