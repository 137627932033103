import { Fragment } from 'react'
import { Col, Row } from 'antd'
import CustomButton from 'components/ui/custom-button'
import FormInput from 'components/ui/form/form-input'
import FormSelect from 'components/ui/form/form-select'
import FormTextArea from 'components/ui/form/form-textarea'

function ModalEditProfile({
  isB2B,
  state,
  errors,
  cities,
  provinces,
  subdistricts,
  handleChange,
  handleChangeLocation,
  onClick,
  onCancel
}) {
  const iniatialInput = [
    {
      id: 1,
      label: 'Nama',
      name: 'nama',
      type: 'text',
      placeholder: 'Masukan Nama',
      mandatory: true,
      value: state?.nama,
      onChange: (e) => handleChange('nama', e.target.value)
    },
    {
      id: 2,
      label: isB2B ? 'Email Perusahaan' : 'Email',
      name: 'email',
      type: 'text',
      placeholder: 'Masukan Email',
      mandatory: true,
      value: state?.email,
      disabled: !isB2B,
      onChange: (e) => handleChange('email', e.target.value)
    },
    {
      id: 3,
      label: 'Email Perusahaan',
      name: 'email_company',
      type: 'text',
      placeholder: 'Masukan Email Perusahaan',
      mandatory: false,
      value: state?.email_company,
      disabled: isB2B,
      onChange: (e) => handleChange('email_company', e.target.value)
    },
    {
      id: 4,
      label: 'Nomor Telepon',
      name: 'phone',
      type: 'text',
      placeholder: 'Nomor Telepon',
      mandatory: true,
      addonBefore: '+62',
      value: state?.phone,
      onChange: (e) => handleChange('phone', e.target.value)
    },
    {
      id: 5,
      label: 'NPWP',
      name: 'npwp',
      type: 'text',
      // maxLength: 16,
      placeholder: isB2B ? 'NPWP Perusahaan' : 'NPWP',
      mandatory: isB2B,
      value: state?.npwp,
      onChange: (e) => handleChange('npwp', e.target.value)
    },
    {
      id: 6,
      rows: 2,
      label: 'Alamat',
      // style: { height: 120, resize: 'none' },
      name: 'alamat',
      type: 'textarea',
      placeholder: 'Masukan Alamat',
      mandatory: true,
      value: state?.alamat,
      onChange: (e) => handleChange('alamat', e.target.value)
    }
  ]
  let inputs = []
  if (isB2B) {
    inputs = iniatialInput.filter((input) => input.id !== 3)
  } else {
    inputs = [...iniatialInput]
  }
  const inputSelect = [
    {
      id: 7,
      label: 'Provinsi',
      name: 'province',
      type: 'select',
      value: state?.province?.value,
      options: provinces,
      placeholder: 'Pilih Provinsi',
      mandatory: true,
      onChange: (_, option) => handleChangeLocation('province', option)
    },
    {
      id: 8,
      label: 'Kota',
      name: 'city',
      type: 'select',
      value: state?.city?.value,
      options: cities,
      placeholder: 'Pilih Kota',
      mandatory: true,
      onChange: (_, option) => handleChangeLocation('city', option)
    },
    {
      id: 9,
      label: 'Kecamatan',
      name: 'sub_district',
      type: 'select',
      value: state?.sub_district?.value,
      options: subdistricts,
      placeholder: 'Pilih Kecamatan',
      mandatory: true,
      onChange: (_, option) => handleChangeLocation('sub_district', option)
    },
    {
      id: 10,
      label: 'Kode Pos',
      name: 'postal_code',
      type: 'text',
      // maxLength: 16,
      placeholder: 'Kode Pos',
      mandatory: true,
      value: state?.postal_code,
      onChange: (e) => handleChange('postal_code', e.target.value)
    }
  ]
  return (
    <Row className="grid grid-cols-1 gap-3">
      <div className="grid grid-cols-1 gap-5">
        {inputs.map((inputItem) => {
          if (inputItem.type === 'text')
            return (
              <Fragment key={inputItem.id}>
                <FormInput data={inputItem} />
                {errors[inputItem?.name] ? (
                  <small className="text-primary-300">{errors[inputItem?.name]}</small>
                ) : null}
              </Fragment>
            )
          else if (inputItem.type === 'textarea')
            return (
              <Fragment key={inputItem.id}>
                <FormTextArea data={inputItem} />
                {errors[inputItem?.name] ? (
                  <small className="text-primary-300">{errors[inputItem?.name]}</small>
                ) : null}
              </Fragment>
            )
        })}
      </div>
      <div className="grid grid-cols-2 gap-5">
        {inputSelect.map((inputItem) => {
          if (inputItem.type === 'text')
            return (
              <Fragment key={inputItem.id}>
                <FormInput data={inputItem} />
                {errors[inputItem?.name] ? (
                  <small className="text-primary-300">{errors[inputItem?.name]}</small>
                ) : null}
              </Fragment>
            )
          else return <FormSelect key={inputItem.id} data={inputItem} />
        })}
      </div>
      <Col span={24}>
        <div className="flex justify-end items-center gap-2">
          <CustomButton type="plain" label={'Batal'} onClick={onCancel} />
          <CustomButton label={'Simpan'} onClick={onClick} />
        </div>
      </Col>
    </Row>
  )
}

export default ModalEditProfile
