const AmanIcon = ({ className }) => {
  return (
    <svg
      width="66"
      className={className}
      height="66"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2207 21.3644L18.4693 26.9187L27.495 15.9258"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.4463 6.09145C26.3377 7.36432 19.3947 1 19.3947 1C19.3947 1 12.4519 7.36432 1.34323 6.09145C1.34323 6.09145 -2.12824 35.5987 19.3947 41.9631C40.9177 35.5987 37.4463 6.09145 37.4463 6.09145Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3964 11.8789C24.6036 11.8789 29.5794 12.5732 34.0922 13.8461C34.208 12.1103 34.208 10.4903 34.208 9.21748C27.2651 9.21748 22.0579 6.55604 19.2808 4.82031C16.5036 6.67175 11.2964 9.21748 4.35352 9.21748C4.35352 10.4903 4.35356 12.1103 4.46927 13.8461C9.09787 12.5732 14.0736 11.8789 19.3964 11.8789Z"
        fill="#E12B25"
      />
      <path
        d="M63.945 48.6758C57.3493 50.1801 51.1007 52.6101 45.315 56.0815C41.4964 58.3958 37.9092 61.0573 33.9749 62.9087C29.9249 64.7602 24.7177 65.6859 20.4363 64.413L3.77332 59.6687C2.61617 59.3215 2.03761 58.1644 2.26904 57.0073C3.31047 53.3044 7.24478 51.2215 10.9477 52.2629L19.1635 54.5772"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.2735 43.8165L38.0249 49.0236C36.0578 50.6436 33.8592 52.0322 31.6606 53.3051L27.842 55.2722C25.8749 56.3137 23.4449 55.5037 22.4034 53.5365C21.362 51.5694 22.172 49.1394 24.1392 48.0979L27.4948 46.3622C30.6191 44.7422 33.3963 42.5436 35.5949 39.7665C37.0992 37.915 38.7192 35.9479 40.5707 34.3279C45.6621 29.815 54.8036 27.1535 64.0608 31.0878"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AmanIcon
