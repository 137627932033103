import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import { onRupiah } from 'utils/formatCurrency'

const TerjualJual = ({ data }) => {
  return (
    <CardSectionHeader headerTitle="Unit Anda telah terjual">
      <div className="flex flex-col gap-3">
        <div className="sm:flex sm:gap-0 grid grid-cols-1 gap-2 justify-between items-center">
          <p className="text-md-regular text-gray-600">Harga jual final</p>
          <p className="text-md-semibold text-gray-700 text-right whitespace-nowrap">
            Rp. {onRupiah(data?.total_price)}
          </p>
        </div>
        <div className="sm:flex sm:gap-0 grid grid-cols-1 gap-2 justify-between items-center">
          <p className="text-md-regular text-gray-600">Terjual oleh</p>
          <p className="text-md-semibold text-gray-700 text-right">{data?.buyer_name}</p>
        </div>
      </div>
    </CardSectionHeader>
  )
}

export default TerjualJual
