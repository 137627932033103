import CardSectionHeader from "components/ui/card-section-header"
import InputCurrency from "components/ui/input-currency"

function CardExpectationPrice({
  carData,
  price,
  handleChangePrice,
}) {
  const data = {
    unitName: `${carData?.brand?.label || ''} ${carData?.model?.label || ''} ${carData?.type?.label || ''}`,
    price: '',
    recommendPrice: price.min !== '-' ? `Rp. ${price.min} - ${price.max}` : 'Belum ada rekomendasi harga',
  }
  return (
    <CardSectionHeader headerTitle={<span>Ekspektasi Harga Jual <span className="text-danger-500">*</span></span>}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div className="p-6 bg-gray-200 flex space-x-3 text-md-semibold text-gray-700 flex items-center">
          <p>Rp</p>
          <InputCurrency
            placeholder="999.999.999"
            value={price?.input}
            onChange={(value, name) => handleChangePrice(name, value)}
            name="input"
          />
        </div>
        <div className="flex flex-col gap-2 p-3">
          <p className="text-sm-medium text-gray-900">Harga rekomendasi dari Kami untuk {data.unitName}</p>
          <p className="text-lg-medium text-primary-500">{data.recommendPrice}</p>
        </div>
      </div>
    </CardSectionHeader>
  )
}
export default CardExpectationPrice