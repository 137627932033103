import { Divider, Dropdown } from 'antd'
import {
  AvatarIcon,
  DashboardIcon,
  LogoutIcon,
  ProfileIcon,
  HeartIcon,
  ChartIcon,
  PersonIcon
} from 'assets'
import React from 'react'
import { HiOutlineChevronDown } from 'react-icons/hi'
import { NavLink } from 'react-router-dom'

const options = [
  {
    title: 'Dashboard',
    accountType: ['customer', 'company'],
    url: '/dashboard',
    icon: <DashboardIcon />
  },
  { title: 'Wishlist', accountType: ['customer'], url: '/wishlist', icon: <HeartIcon /> },
  {
    title: 'Report',
    accountType: ['company'],
    url: '/report',
    icon: <ChartIcon />
  },
  {
    title: 'Users',
    accountType: ['company'],
    url: '/users',
    icon: <PersonIcon />
  },
  { title: 'Profil', accountType: ['customer', 'company'], url: '/profile', icon: <ProfileIcon /> },
]

function DropdownUser() {
  const accountType = localStorage.getItem('account_type')
  const userName = localStorage.getItem('user_name')
  return (
    <div>
      {/* Avatar */}
      <Dropdown dropdownRender={DropdownRender.bind(this, accountType)}>
        <div className="flex items-center gap-2">
          {/* Avatar Container */}
          <div className="rounded-full p-3 flex justify-center items-center bg-primary-50">
            <AvatarIcon />
          </div>
          <a
            onClick={(e) => {
              e.preventDefault()
            }}
            className="flex items-center gap-2">
            <span className="text-xs-medium md:text-sm-medium xl:text-md-medium text-gray-700 whitespace-nowrap">
              {userName}
            </span>
            <HiOutlineChevronDown className="text-gray-500 h-[20px] w-[20px]" />
          </a>
        </div>
      </Dropdown>
    </div>
  )
}

const DropdownRender = (accountType) => {
  const handleLogout = () => {
    localStorage.clear()
    window.location.replace('/')
  }
  return (
    <div className="bg-white p-4 rounded-[4px] border border-solid border-gray-200 shadow-sm w-[250px]">
      <div className="flex flex-col gap-2">
        {options.map((nav, key) => {
          return (
            nav.accountType.includes(accountType) && (
              <NavLink
                key={key}
                to={nav.url}
                className="flex items-center gap-2 rounded-[4px] text-md-medium stroke-primary-600 p-3 text-gray-700 hover:text-primary-500 hover:bg-gray-100">
                {nav.icon}
                {nav.title}
              </NavLink>
            )
          )
        })}
        <Divider className="m-0" />
        <div
          onClick={handleLogout}
          className="flex items-center cursor-pointer gap-2 rounded-[4px] text-md-medium stroke-primary-600 p-3 text-gray-700 hover:text-primary-500 hover:bg-gray-100">
          <LogoutIcon />
          Logout
        </div>
      </div>
    </div>
  )
}
export default DropdownUser
