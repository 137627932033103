const TrashIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      stroke=""
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 5.0013H3.16667M3.16667 5.0013H16.5M3.16667 5.0013V16.668C3.16667 17.11 3.34226 17.5339 3.65482 17.8465C3.96738 18.159 4.39131 18.3346 4.83333 18.3346H13.1667C13.6087 18.3346 14.0326 18.159 14.3452 17.8465C14.6577 17.5339 14.8333 17.11 14.8333 16.668V5.0013H3.16667ZM5.66667 5.0013V3.33464C5.66667 2.89261 5.84226 2.46868 6.15482 2.15612C6.46738 1.84356 6.89131 1.66797 7.33333 1.66797H10.6667C11.1087 1.66797 11.5326 1.84356 11.8452 2.15612C12.1577 2.46868 12.3333 2.89261 12.3333 3.33464V5.0013"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TrashIcon
