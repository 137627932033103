import { Divider } from "antd"
import { onRupiah } from "utils/formatCurrency"

const DefaultInvoiceContent = ({ invoiceData }) => {
  return (
    <div>
      <div className='grid grid-cols-2 gap-2 text-xs-semibold text-gray-900'>
        <p>Item</p>
        <div className='flex'>
          <p className='w-[40%]'>Price</p>
          <p className='w-[20%]'>Qty</p>
          <p className='w-[40%]'>Total</p>
        </div>
      </div>
      <Divider className="m-0 my-5" />
      {
        invoiceData?.invoice_items?.map((item, index) => (
          <div className='grid grid-cols-2 gap-2 text-xs-regular text-gray-700 mb-2' key={index}>
            <p>{item?.name}</p>
            <div className='flex gap-1'>
              <p className='w-[40%]'>{item?.price !== null ? `Rp. ${onRupiah(item?.price)}` : '-'}</p>
              <p className='w-[20%]'>{item?.qty !== null ? item?.qty : '-'}</p>
              <p className='text-right w-[40%]'>{item?.total !== null ? `Rp. ${onRupiah(item?.total)}` : '-'}</p>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default DefaultInvoiceContent