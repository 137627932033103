import {
  ImageDaihatsu,
  ImageHonda,
  ImageHyundai,
  ImageMitsubishi,
  ImageNissan,
  ImageSuzuki,
  ImageToyota
} from 'assets'
export const merk = [
  {
    label: 'Honda',
    value: 'honda',
    image: <img src={ImageHonda} />
  },
  {
    label: 'Toyota',
    value: 'toyota',
    image: <img src={ImageToyota} />
  },
  {
    label: 'Hyundai',
    value: 'hyundai',
    image: <img src={ImageHyundai} />
  },
  {
    label: 'Mitsubishi',
    value: 'mitsubishi',
    image: <img src={ImageMitsubishi} />
  },
  {
    label: 'Daihatsu',
    value: 'daihatsu',
    image: <img src={ImageDaihatsu} />
  },
  {
    label: 'Nissan',
    value: 'nissan',
    image: <img src={ImageNissan} />
  },
  {
    label: 'Suzuki',
    value: 'suzuki',
    image: <img src={ImageSuzuki} />
  }
]
