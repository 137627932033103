import { Col, Row } from 'antd'
import { GearedIcon, SampleCar, ThumbsDownIcon, ThumbsUpIcon } from 'assets'
import BadgeItem from 'components/ui/badge-item'
import CardImage from 'components/ui/card/card-image'
// import CardInspectionDetail from 'components/ui/card/card-inspection-detail'
import CardInspectionDetailList from 'components/ui/card/card-inspection-detail-list'
import CardKelengkapanDokumen from 'components/ui/card/card-kelengkapan-dokumen'
import CardNote from 'components/ui/card/card-note'
import ModalImage from 'components/ui/modal/modal-image'
import { getDetailInspeksi, getDetailInspeksiCatalog } from 'features/inspeksi/service'
import { CardRecommendationInspection } from 'features/transaction'
import { usePreviewImage } from 'hooks'
import React from 'react'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { NavLink, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const RenderBadge = ({ status }) => {
  if (status === 'OK') {
    return (
      <BadgeItem type={'success'}>
        <div className="flex items-center space-x-2 p-1 text-sm-medium">
          <ThumbsUpIcon className={'h-4 w-4'} />
          <p>OK</p>
        </div>
      </BadgeItem>
    )
  } else if (status === 'Perlu Perbaikan') {
    return (
      <BadgeItem type={'warning'}>
        <div className="flex items-center space-x-2 p-1 text-sm-medium">
          <GearedIcon className={'h-4 w-4 stroke-warning-500'} />
          <p>Perlu Perbaikan</p>
        </div>
      </BadgeItem>
    )
  } else if (status === 'Replace') {
    return (
      <BadgeItem>
        <div className="flex items-center space-x-2 p-1 text-sm-medium">
          <ThumbsDownIcon className={'h-4 w-4'} />
          <p>Jelek</p>
        </div>
      </BadgeItem>
    )
  } else {
    return (
      <BadgeItem type={`plain`}>
        <div className="flex items-center space-x-2 p-1 text-sm-medium">
          <p>{status || 'Belum Ada'}</p>
        </div>
      </BadgeItem>
    )
  }
}

function DetailInspectionPage() {
  const location = useLocation()
  const { goBack } = useHistory()
  const id = location?.state?.id
  const urlType = location.pathname.split('/')[1]
  const token = localStorage.getItem('token')
  const isTransaction = location.pathname?.includes('transaction')
  const [detailInspection, setDetailInspection] = useState(null)
  const { openModalImage, previewImage, handleOpenModalImage, handleCloseModalImage } =
    usePreviewImage()

  useQuery('getDetailInspeksi', {
    queryFn: () => {
      const dataSend = {
        id: id,
        token: token
      }
      return getDetailInspeksi(dataSend)
    },
    onSuccess: (data) => {
      setDetailInspection(data)
    },
    enabled: urlType !== 'beli'
  })
  useQuery('getDetailInspeksiCatalog', {
    queryFn: () => {
      const dataSend = {
        id: id
      }
      return getDetailInspeksiCatalog(dataSend)
    },
    onSuccess: (data) => {
      setDetailInspection(data)
    },
    enabled: urlType === 'beli'
  })

  return (
    <Row className={`${urlType === 'beli' ? 'py-10' : 'gap-6'}`}>
      <ModalImage
        openModal={openModalImage}
        onCancel={handleCloseModalImage}
        imageUrl={previewImage ? previewImage : ''}
      />
      <Col span={urlType === 'beli' ? 22 : 24} className="mx-auto">
        {/* Header Breadcrumb */}
        <Col
          span={24}
          className="flex gap-1 items-center text-sm-medium md:text-xl-medium text-gray-900 py-2">
          {urlType === 'beli' ? (
            <div className="text-sm-medium text-primary-500 flex gap-2">
              <NavLink className={'hover:text-primary-500'} to={'/'}>
                Home
              </NavLink>{' '}
              /
              <NavLink className={'hover:text-primary-500'} to={'/beli/mobil-bekas'}>
                Beli
              </NavLink>{' '}
              /
              <span className="hidden md:inline cursor-pointer" onClick={() => goBack()}>
                {detailInspection?.unit_name} /{' '}
              </span>
              <span className="text-gray-900">Detail Inspeksi</span>
            </div>
          ) : (
            <>
              {isTransaction ? (
                <NavLink className={' hover:text-primary-900 text-primary-600'} to={'/transaction'}>
                  Transaksi
                </NavLink>
              ) : (
                <NavLink className={' hover:text-primary-900 text-primary-600'} to={'/dashboard'}>
                  Dashboard
                </NavLink>
              )}{' '}
              /{' '}
              <span
                className={`text-primary-500 hover:text-primary-900 ${
                  !isTransaction ? 'cursor-pointer' : ''
                }`}
                onClick={isTransaction ? null : () => goBack()}>
                {isTransaction ? 'Detail Transaksi' : 'Detail Unit'}
              </span>
              / <span className="text-primary-500">Detail Inspeksi</span>
            </>
          )}
        </Col>
        {/* Content */}
        <Col span={24} className="grid grid-cols-1 gap-6">
          <CardKelengkapanDokumen
            handleOpenModalImage={handleOpenModalImage}
            data={detailInspection?.inspectionResult}
            urlType={urlType}
          />
          {urlType !== 'beli' &&
            detailInspection?.serviceRecomendation?.serviceRecomendationList?.length > 0 && (
              <CardRecommendationInspection data={detailInspection} />
            )}
          {detailInspection?.inspectionResult?.inspectionCategoryResults?.map((item, index) => (
            // item.type === 'one' ? (
            //   <CardInspectionDetail
            //     title={item.label}
            //     data={item.value}
            //     key={index}
            //   />
            // ) : (
            //   )
            <CardInspectionDetailList
              title={
                <div className="flex w-100 justify-between items-center gap-5">
                  <p>{item.name}</p>
                  <div className="flex-shrink-0">
                    <RenderBadge status={item?.grade} />
                  </div>
                </div>
              }
              handleOpenModalImage={handleOpenModalImage}
              data={item?.inspectionSubCategoryResults}
              // data={[]}
              key={index}
            />
          ))}
          <CardNote data={detailInspection?.inspectionResult?.note || '-'} />
          <CardImage data={detailInspection?.inspectionResult?.inspectionResultPhotos || []} />
        </Col>
      </Col>
    </Row>
  )
}

export default DetailInspectionPage
