const BebasBanjirIcon = ({ className }) => {
  return (
    <svg
      width="82"
      className={className}
      height="72"
      viewBox="0 0 82 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.4453 58.4014H64.7722V56.5909C64.7722 54.7803 63.431 53.238 61.6205 52.9697L54.4453 51.9639V58.4014Z"
        fill="white"
      />
      <path
        d="M52.7031 41.6371V38.5524C52.7031 36.7419 54.1784 35.2666 55.989 35.2666H59.6772"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.1182 56.0545H6.56583C3.48117 56.0545 1 53.5733 1 50.4887V6.5658C1 3.48114 3.48117 1 6.56583 1H46.1299C49.2146 1 51.6957 3.48114 51.6957 6.5658V31.9136"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.0008 47.0017H72.7526C71.4785 47.0017 70.2715 46.5322 69.3998 45.6605L67.0527 43.5146"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.3399 64.705V65.3755C59.3399 68.259 56.9929 70.606 54.1094 70.606C51.226 70.606 48.8789 68.259 48.8789 65.3755V64.705"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.0002 32.1149H70.204C67.7899 32.1149 65.5099 33.1207 63.8335 34.8642C60.6817 38.1501 59.2735 41.6371 59.2735 41.6371H53.1042C50.7572 41.6371 48.8125 43.5818 48.8125 45.9288V64.6379"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.0002 64.705H54.4453"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.2383 58.4015H80.9994"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 38.9548C2.60939 37.8818 4.55411 37.2113 6.49879 37.0771C10.3211 36.8089 12.3328 38.8877 14.881 41.3018C18.6363 44.8559 22.1903 36.7419 25.6773 36.3395C29.4325 35.8701 30.6396 40.6312 33.1207 42.3747C37.0771 45.1241 39.6253 38.8206 43.1794 38.4183C44.6546 38.2842 46.197 39.223 47.1358 40.2959"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7266 20.1115L26.0124 22.995L30.7065 17.228"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8042 10.9916C30.0372 11.6622 26.4161 8.30933 26.4161 8.30933C26.4161 8.30933 22.795 11.6622 17.028 10.9916C17.028 10.9916 15.2175 26.3479 26.4161 29.7008C37.6148 26.3479 35.8042 10.9916 35.8042 10.9916Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4141 14.5457C28.4929 14.5457 30.5046 14.8139 32.3152 15.2834C32.3823 14.6128 32.3822 14.0093 32.3822 13.5398C29.6329 13.5398 27.554 12.534 26.4811 11.8634C25.4082 12.534 23.3294 13.5398 20.5801 13.5398C20.5801 14.0093 20.5801 14.6128 20.6471 15.2834C22.3906 14.8139 24.3353 14.5457 26.4141 14.5457Z"
        fill="white"
      />
    </svg>
  )
}

export default BebasBanjirIcon
