import { Col } from 'antd'
import CustomButton from 'components/ui/custom-button'
import { CardExpectationPrice, CardFotoUnit, CardInformasiTambahan, CardPilihMobil } from './card'

function FormDataMobil({
  carData,
  documentData,
  price,
  brands,
  units,
  models,
  types,
  fuels,
  colors,
  subdistricts,
  provinces,
  cities,
  transmissions,
  thumbnail,
  handleChange,
  handleChangeKelengkapan,
  handleChangeDocument,
  handleChangeCarList,
  handleChangePrice,
  handleCancel,
  handleClick,
  handleChangeThumbnail,
  handleChangeLocation
}) {
  return (
    <Col span={22} md={20} className="mx-auto grid grid-cols-1 gap-6">
      {/* Pilih Mobil */}
      <CardPilihMobil
        units={units}
        carData={carData}
        brands={brands}
        models={models}
        types={types}
        fuels={fuels}
        colors={colors}
        transmissions={transmissions}
        handleChange={handleChange}
        handleChangeCarList={handleChangeCarList}
      />
      {/* Ekspektasi Harga Jual */}
      <CardExpectationPrice price={price} carData={carData} handleChangePrice={handleChangePrice} />
      {/* Informasi Tambahan */}
      <CardInformasiTambahan
        documentData={documentData}
        handleChangeKelengkapan={handleChangeKelengkapan}
        handleChangeDocument={handleChangeDocument}
        handleChangeLocation={handleChangeLocation}
        subdistricts={subdistricts}
        provinces={provinces}
        cities={cities} />
      {/* Foto Unit */}
      <CardFotoUnit fileList={thumbnail} handleChangeThumbnail={handleChangeThumbnail} />
      {/* Button */}
      <div className="flex gap-3 justify-end items-center">
        <CustomButton type="secondary" label={'Batal'} onClick={handleCancel} />
        <CustomButton label={'Selanjutnya'} onClick={handleClick} />
      </div>
    </Col>
  )
}

export default FormDataMobil
