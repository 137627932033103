import { EyeIcon, TrashIcon } from 'assets'
import { imageCreditCards } from 'data/image-credit-card'
import React from 'react'

const CreditCardItem = ({ card, onClick, handleConfirmDeleteCard, width }) => {
  return (
    <div
      style={{ backgroundColor: imageCreditCards[card?.network].color }}
      className={`w-full h-full flex flex-col text-white rounded p-4 gap-4 relative group cursor-pointer lg:cursor-default`}
      onClick={(e) => {
        if (width < 1024) {
          e.stopPropagation()
          onClick()
        }
      }}>
      {/* Name & Image */}
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <span className="text-xs-regular">Name</span>
          <span className="text-sm-medium">{card?.cardholder_name}</span>
        </div>
        <div className="bg-white py-2 px-4">
          <img
            src={imageCreditCards[card?.network].image}
            className="w-full object-contain"
            alt=""
          />
        </div>
      </div>
      {/* Number Card*/}
      <div className="text-md-medium xl:text-lg-medium flex justify-between">
        {card?.masked_number.match(/.{4}/g).map((splittedNumber, index) => (
          <span key={index}>{splittedNumber}</span>
        ))}
      </div>

      {/* Hover Component */}
      <div className="w-full h-full rounded bg-black/50 absolute top-0 z-10 left-0 justify-center items-center gap-2 hidden lg:group-hover:flex">
        <div
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
          className="cursor-pointer w-[32px] h-[32px] stroke-white hover:bg-white hover:stroke-gray-700 rounded-full p-2 border border-solid border-white flex justify-center items-center opacity-100">
          <EyeIcon />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation()
            handleConfirmDeleteCard()
          }}
          className="cursor-pointer stroke-white hover:bg-white hover:stroke-gray-700 w-[32px] h-[32px] rounded-full p-2 border border-solid border-white flex justify-center items-center">
          <TrashIcon />
        </div>
      </div>
    </div>
  )
}

export default CreditCardItem
