import { Col, Divider, Dropdown } from 'antd'
import { BellIcon, BellNotifIcon, NullNotification } from 'assets'
import React from 'react'
import { useHistory } from 'react-router-dom'
import NotificationItem from './notification-item'
import moment from 'moment'
import useNotification from 'hooks/useNotification'

function DropdownNotification() {
  const token = localStorage.getItem('token')
  const { push } = useHistory()

  const { notifications, handleClickNotification, refetch } = useNotification('dropdown')

  const handleNavigation = () => {
    push('/notification')
  }
  const handleOpenDropdown = () => refetch()

  return (
    <>
      <Dropdown
        dropdownRender={NotificationRender.bind(
          this,
          handleNavigation,
          handleClickNotification,
          notifications.data
        )}
        onOpenChange={handleOpenDropdown}>
        <a className="flex items-center gap-2" onClick={(e) => e.preventDefault()}>
          {notifications.hasNotif ? <BellNotifIcon /> : <BellIcon />}
        </a>
      </Dropdown>
    </>
  )
}

const NullComponent = () => {
  return (
    <Col span={24} className="flex flex-col justify-center text-center items-center gap-2">
      <img className="mb-14" src={NullNotification} alt="" />
      <p className="text-xl-semibold text-gray-500">No Notifications Yet!</p>
      <p className="text-md-medium text-gray-400 ">
        You have no notifications right now, {`they'll`} <br /> show up here
      </p>
    </Col>
  )
}
const NotificationRender = (handleNavigation, handleClickNotification, notifications) => {
  return (
    <div className="bg-white rounded-[4px] border border-solid border-gray-200 shadow-sm w-[368px]">
      <div className="flex px-6 pt-6 justify-between">
        <p className="text-lg-medium text-gray-900">Notifikasi</p>
        <p onClick={handleNavigation} className=" cursor-pointer text-sm-medium text-primary-600">
          Lihat Semua
        </p>
      </div>
      <Divider className="mb-0 p-0" />
      {/* Notification Item */}
      <div className="flex flex-col">
        {notifications.length > 0 ? (
          notifications.map((notif, key) => (
            <NotificationItem
              id={notif.id}
              key={key}
              title={notif.title}
              vehicle={`${notif?.body} ${notif?.unit_police_number}`}
              read={notif?.is_read}
              time={moment(notif?.created_at).fromNow()}
              handleClickNotification={handleClickNotification}
            />
          ))
        ) : (
          <NullComponent />
        )}
      </div>
    </div>
  )
}

export default DropdownNotification
