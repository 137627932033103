import CardResultInspection from 'components/ui/card/card-result-inspection'
import React, { useRef } from 'react'
import {
  CardCicilan,
  CardCTA,
  CardCustomBeli,
  // CardFeatures,
  CardImageUnit,
  CardLocationUnit,
  CardSpecificationUnit
} from './card'

function MobilBekasDetail({ cyoc, handleCYOC, resetCYOC, selectedSpareparts, car }) {
  const cicilanRef = useRef()
  const handleNavToCicilan = () => {
    window.scrollTo({
      top: cicilanRef.current.offsetTop,
      behavior: 'smooth'
    })
  }

  return (
    <div className="grid grid-cols-1 xl:grid-cols-[1fr_389px] gap-6">
      {/* Car Detail */}
      <div>
        <div className="flex flex-col gap-6">
          <div>
            <CardImageUnit data={car} />
          </div>
          <div className="xl:hidden block">
            <CardCTA handleNavToCicilan={handleNavToCicilan} dataUnit={car} />
          </div>
          <CardSpecificationUnit data={car} />
          {/* <CardFeatures /> */}
          {car?.inspection_result?.inspectionCategoryList.length > 0 ? (
            <CardResultInspection type="beli" centered data={car} id={car?.inspection_result?.id} />
          ) : null}
          <div className="xl:hidden block">
            {cyoc ? (
              <CardCustomBeli
                cyoc={cyoc}
                handleCYOC={handleCYOC}
                resetCYOC={resetCYOC}
                selectedSpareparts={selectedSpareparts}
              />
            ) : null}
          </div>
          {car?.price !== undefined ? <CardCicilan ref={cicilanRef} price={car?.price} /> : null}
        </div>
      </div>
      {/* Price & Customize Car */}
      <div className="hidden xl:block">
        <div className="flex flex-col gap-6">
          <CardCTA handleNavToCicilan={handleNavToCicilan} dataUnit={car} />
          <CardLocationUnit data={car} />
          {cyoc ? (
            <CardCustomBeli
              cyoc={cyoc}
              handleCYOC={handleCYOC}
              resetCYOC={resetCYOC}
              selectedSpareparts={selectedSpareparts}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default MobilBekasDetail
