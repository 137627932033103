const LayananInspeksiIcon = ({ className }) => {
  return (
    <svg
      width="83"
      className={className}
      height="67"
      viewBox="0 0 83 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M53.457 6.18246V13.3902C53.457 14.0455 53.9931 14.6411 54.7079 14.6411H55.1844C55.7801 14.6411 56.3162 14.1646 56.3758 13.5689V13.3902C56.6737 11.2458 58.4607 9.63741 60.6648 9.63741C62.8092 9.63741 64.6558 11.2458 64.9536 13.3902V13.5689C65.0132 14.1646 65.5493 14.6411 66.145 14.6411C66.8002 14.6411 67.3959 14.105 67.3959 13.3902V6.18246C67.3959 5.64635 66.9789 5.22937 66.4428 5.22937H54.3506C53.874 5.22937 53.457 5.64635 53.457 6.18246Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.4546 8.0885H42.8514C41.0048 8.0885 39.5156 9.57771 39.5156 11.4243V62.4146C39.5156 64.2612 41.0048 65.7504 42.8514 65.7504H78.4136C80.2602 65.7504 81.7494 64.2612 81.7494 62.4146V11.4243C81.7494 9.57771 80.2602 8.0885 78.4136 8.0885H67.3935"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.4609 13.0327V60.8063H67.9903L76.866 51.9307V13.0327"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.9119 33.3455L52.9804 37.5152L51.0742 35.609"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.25 25.9591L55.9559 21.3127"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.9559 25.9591L51.25 21.3127"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.25 50.0246L55.9559 45.3187"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.9559 50.0246L51.25 45.3187"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.293 25.959H72.7553"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.293 37.7535H72.7553"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4048 35.0134H27.4226C26.0526 35.0134 24.7421 34.4773 23.7294 33.5838L21.168 31.2606"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7461 40.9106L18.0134 41.9233C19.8601 42.1616 21.1705 43.7699 21.1705 45.6165V47.4631H10.7461"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14453 36.443V53.7177"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14535 36.443L2.28613 33.3455C1.92872 33.0476 1.75 32.5711 1.75 32.0945V28.6992C1.75 27.7461 2.52441 27.0312 3.41794 27.0312H9.97043L15.6294 16.1303C16.7612 14.6411 18.4887 13.7476 20.3948 13.7476H35.0486H35.8229"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6881 53.7179V54.3731C15.6881 57.292 13.3054 59.6747 10.3866 59.6747C7.46772 59.6747 5.14453 57.292 5.14453 54.3731V53.7179"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4073 53.7179H10.7461"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9375 47.4037H35.0477"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9883 27.0312H35.7053"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.4922 1H64.2956"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4277 17.9769L16.1641 22.3254H35.8215V17.0834H20.3935C19.6786 17.0834 18.9638 17.4408 18.4277 17.9769Z"
        fill="#E12B25"
      />
    </svg>
  )
}

export default LayananInspeksiIcon
