const HargaBersaingIcon = ({ className }) => {
  return (
    <svg
      width="81"
      className={className}
      height="59"
      viewBox="0 0 81 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.5525 26.9642H46.75V25.1818C46.75 23.3994 48.0571 21.8546 49.8395 21.5575L56.9099 20.6069"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.52539 20.6069L13.5957 21.5575C15.3781 21.7952 16.6853 23.3399 16.6853 25.1818V26.9642H6.52539"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5521 12.2888L42.2349 14.3683C41.3437 15.2001 40.1554 15.6754 38.9077 15.6754H32.7881H24.6483C23.4006 15.6754 22.2123 15.2001 21.3211 14.3683L19.0039 12.2888"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3381 33.1431V33.7967C11.3381 36.6486 9.02094 38.9657 6.16904 38.9657C3.31715 38.9657 1 36.6486 1 33.7967V33.1431"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.4931 22.5674V14.6653C62.4931 12.2887 60.5918 10.3875 58.2153 10.3875H52.155C52.155 10.3875 50.729 6.82261 47.4613 3.4954C45.8571 1.89122 43.6587 1 41.401 1H32.7859H22.1507C19.7741 1 17.5164 2.01005 15.9122 3.67365C12.8226 6.94144 11.3967 10.3875 11.3967 10.3875H5.33644C2.95986 10.3875 1.05859 12.2887 1.05859 14.6653V33.1432"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.8084 33.1431H32.7866H6.52539"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.7515 10.3875V7.35737C58.7515 5.57493 57.3255 4.14899 55.5431 4.14899H51.8594"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.74219 10.3875V7.35737C4.74219 5.57493 6.16813 4.14899 7.95056 4.14899H11.6343"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0352 26.9642H35.462"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.5876 44.9469C78.5763 37.7316 75.15 29.4595 67.9346 26.4708C60.7193 23.482 52.4473 26.9084 49.4585 34.1237C46.4697 41.339 49.8961 49.6111 57.1114 52.5999C64.3267 55.5886 72.5988 52.1623 75.5876 44.9469Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4013 4.03015H32.7862H22.151C20.6657 4.03015 19.1803 4.68371 18.1108 5.75317C16.8631 7.06029 15.9719 8.3674 15.3184 9.43686H48.2934C47.6398 8.30799 46.6298 6.94146 45.3226 5.57493C44.3126 4.62429 42.8867 4.03015 41.4013 4.03015Z"
        fill="#E12B25"
      />
      <path
        d="M60.6522 42.7089L59.2857 40.7482H59.2263H57.8003V42.7089H56.1367V35.6385H59.2263C59.8798 35.6385 60.4146 35.7574 60.8305 35.9356C61.3058 36.1733 61.6623 36.4704 61.8999 36.8268C62.1376 37.1833 62.2564 37.6586 62.2564 38.1934C62.2564 38.7281 62.1376 39.144 61.8999 39.5599C61.6623 39.9164 61.3058 40.2135 60.8305 40.4511L62.4346 42.7089H60.6522ZM60.2363 37.3022C59.9987 37.0645 59.5828 37.0051 59.1074 37.0051H57.8003V39.4411H59.1074C59.5828 39.4411 59.9987 39.3222 60.2363 39.144C60.474 38.9658 60.5928 38.6093 60.5928 38.2528C60.6522 37.7775 60.5334 37.4804 60.2363 37.3022Z"
        fill="#344154"
      />
      <path
        d="M68.0777 37.5398C68.4936 37.7775 68.7907 38.0746 69.0283 38.4905C69.266 38.9064 69.3848 39.3817 69.3848 39.9758C69.3848 40.5106 69.266 41.0453 69.0283 41.4612C68.7907 41.8771 68.4936 42.2336 68.0777 42.4118C67.6618 42.6495 67.1865 42.7683 66.7112 42.7683C65.9982 42.7683 65.4635 42.5306 65.0476 42.1147V44.6696H63.4434V37.2428H64.9287V37.8963C65.3446 37.421 65.8794 37.1833 66.6518 37.1833C67.1865 37.1833 67.6618 37.3022 68.0777 37.5398ZM67.4241 41.0453C67.6618 40.7482 67.84 40.3917 67.84 39.9758C67.84 39.5005 67.7212 39.144 67.4241 38.9064C67.1865 38.6093 66.83 38.4905 66.4141 38.4905C65.9982 38.4905 65.7011 38.6093 65.404 38.9064C65.1664 39.2034 64.9882 39.5599 64.9882 39.9758C64.9882 40.4511 65.107 40.8076 65.404 41.0453C65.6417 41.3424 65.9982 41.4612 66.4141 41.4612C66.83 41.4612 67.1271 41.3424 67.4241 41.0453Z"
        fill="#344154"
      />
    </svg>
  )
}

export default HargaBersaingIcon
