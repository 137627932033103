const MailIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      stroke="#667085"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3346 2.99998C18.3346 2.08331 17.5846 1.33331 16.668 1.33331H3.33464C2.41797 1.33331 1.66797 2.08331 1.66797 2.99998M18.3346 2.99998V13C18.3346 13.9166 17.5846 14.6666 16.668 14.6666H3.33464C2.41797 14.6666 1.66797 13.9166 1.66797 13V2.99998M18.3346 2.99998L10.0013 8.83331L1.66797 2.99998"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MailIcon
