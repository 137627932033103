import React from 'react'
import { Collapse, DatePicker, Divider, Select, Space } from 'antd'
import './style.css'
import FormSelect from 'components/ui/form/form-select'
import FormInput from 'components/ui/form/form-input'
import InputCurrency from 'components/ui/input-currency'
const { Panel } = Collapse

function FilterMobilBekas({
  filter,
  handleFilter,
  locations,
  fuels,
  transmissions,
  models,
  colors,
  types,
  brands,
  provinces,
  cities,
  modal
}) {
  const informasiMobilInput = [
    {
      id: 1,
      label: 'Merek',
      name: 'merek',
      type: 'select',
      options: brands,
      placeholder: 'Pilih Merek',
      value: filter?.merek?.label,
      showSearch: true,
      filterOption: (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    },
    {
      id: 2,
      label: 'Model',
      name: 'model',
      type: 'select',
      options: models,
      placeholder: 'Pilih Model',
      value: filter.model?.label,
      showSearch: true,
      filterOption: (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    },
    {
      id: 3,
      label: 'Tipe',
      name: 'tipe',
      type: 'select',
      options: types,
      placeholder: 'Pilih Tipe',
      value: filter.tipe?.label,
      showSearch: true,
      filterOption: (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    },
    {
      id: 4,
      label: 'Transmisi',
      name: 'transmisi',
      type: 'select',
      options: transmissions,
      placeholder: 'Pilih Transmisi',
      value: filter.transmisi?.label,
      showSearch: true,
      filterOption: (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    },
    {
      id: 6,
      label: 'Bahan Bakar',
      name: 'bahanBakar',
      type: 'select',
      options: fuels,
      placeholder: 'Pilih Bahan Bakar',
      value: filter.bahanBakar?.label,
      showSearch: true,
      filterOption: (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    },
    {
      id: 7,
      label: 'Warna',
      name: 'warna',
      type: 'select',
      options: colors,
      placeholder: 'Pilih Warna',
      value: filter.warna?.label,
      showSearch: true,
      filterOption: (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }
  ]
  return (
    <Space direction="vertical" size="large">
      {/* Lokasi */}
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={['10']}
        className="bg-white rounded-[4px] border border-solid border-gray-200"
        bordered={modal ? true : false}>
        <Panel key={'10'} header="Lokasi Cabang" className="text-gray-700">
          <div className="grid grid-cols-1 gap-3">
            <div className="grid grid-cols-1 gap-2">
              <p className="text-sm-medium text-gray-700">Provinsi</p>
              <Select
                onChange={(value, option) =>
                  handleFilter('province', { label: option.label, value: option.value })
                }
                options={provinces}
                value={filter?.province}
                defaultValue={'Pilih Provinsi'}
                placeholder="Pilih Provinsi"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </div>
            <div className="grid grid-cols-1 gap-2">
              <p className="text-sm-medium text-gray-700">Kota</p>
              <Select
                onChange={(value, option) =>
                  handleFilter('city', { label: option.label, value: option.value })
                }
                options={cities}
                value={filter?.city}
                defaultValue={'Pilih Kota'}
                placeholder="Pilih Kota"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </div>
            <div className="grid grid-cols-1 gap-2">
              <p className="text-sm-medium text-gray-700">Cabang</p>
              <Select
                onChange={(value, option) =>
                  handleFilter('location', { label: option.label, value: option.value })
                }
                options={locations}
                value={filter?.location}
                defaultValue={'Pilih Cabang'}
                placeholder="Pilih Cabang"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </div>
          </div>
        </Panel>
      </Collapse>

      {/* Informasi Mobil */}
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={['2']}
        bordered={modal ? true : false}
        className="bg-white rounded-[4px] border border-solid border-gray-200">
        <Panel key={'2'} header="Informasi Mobil" className="text-gray-700">
          <div className="grid grid-cols-1 gap-3">
            {informasiMobilInput.map((data, index) => (
              <FormSelect
                key={index}
                data={{
                  ...data,
                  onChange: (value, option) =>
                    handleFilter(data.name, { label: option.label, value: option.value })
                }}
              />
            ))}
          </div>
        </Panel>
      </Collapse>
      {/* Harga */}
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={['3']}
        bordered={modal ? true : false}
        className="bg-white rounded-[4px] border border-solid border-gray-200">
        <Panel key={'3'} header="Harga" className="text-gray-700">
          <div className="grid grid-cols-1 gap-3">
            <div className="grid grid-cols-1 gap-2">
              <p className="text-sm-medium text-gray-700">Minimum</p>
              <InputCurrency
                placeholder="Rp 2000000"
                value={filter.minHarga !== null ? filter.minHarga : ''}
                onChange={(value, name) => handleFilter(name, value)}
                name="minHarga"
                prefix="Rp"
              />
            </div>
            <div className="grid grid-cols-1 gap-2">
              <p className="text-sm-medium text-gray-700">Maximum</p>
              <InputCurrency
                placeholder="Rp 2000000"
                value={filter.maxHarga !== null ? filter.maxHarga : ''}
                onChange={(value, name) => handleFilter(name, value)}
                name="maxHarga"
                prefix="Rp"
              />
            </div>
          </div>
        </Panel>
      </Collapse>
      {/* Tahun Keluaran */}
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={['4']}
        bordered={modal ? true : false}
        className="bg-white rounded-[4px] border border-solid border-gray-200">
        <Panel key={'4'} header="Tahun Keluaran" className="text-gray-700">
          <div className="grid grid-cols-2 gap-3">
            <div className="flex flex-col gap-2">
              <p className="text-sm-medium text-gray-700">Dari</p>
              <DatePicker
                onChange={(_, dateString) => handleFilter('minTahun', dateString)}
                picker="year"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-sm-medium text-gray-700">Hingga</p>
              <DatePicker
                onChange={(_, dateString) => handleFilter('maxTahun', dateString)}
                picker="year"
              />
            </div>
          </div>
        </Panel>
      </Collapse>
      {/* Jarak Tempuh */}
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={['5']}
        bordered={modal ? true : false}
        className="bg-white rounded-[4px] border border-solid border-gray-200">
        <Panel key={'5'} header="Jarak Tempuh" className="text-gray-700">
          <div className="grid grid-cols-2 gap-3">
            <FormInput
              data={{
                label: 'Dari',
                type: 'number',
                pattern: '[0-9]*',
                placeholder: '0',
                value: filter?.minTempuh,
                name: 'minTempuh',
                onChange: (e) => handleFilter(e.target.name, e.target.value),
                suffix: 'KM'
              }}
            />
            <FormInput
              data={{
                pattern: '[0-9]*',
                label: 'Hingga',
                type: 'number',
                name: 'maxTempuh',
                placeholder: '1000',
                value: filter?.maxTempuh,
                onChange: (e) => handleFilter(e.target.name, e.target.value),
                suffix: 'KM'
              }}
            />
          </div>
        </Panel>
      </Collapse>
    </Space>
  )
}

export default FilterMobilBekas
