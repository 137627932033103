import { Col, Row, Select } from 'antd'
import { NullWishlist } from 'assets'
import CardCar from 'components/ui/card-car'
import CustomPagination from 'components/ui/pagination/custom-pagination'
import { deleteWishlistId, getWishlist, getWishlistIds } from 'features/wishlist/service'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
const NullComponent = () => {
  return (
    <Col span={24} className="flex flex-col justify-center text-center items-center gap-2">
      <img className="mb-14" src={NullWishlist} alt="" />
      <p className="text-xl-semibold text-gray-500">Tidak Ada Unit Di Wishlist</p>
      <p className="text-md-medium text-gray-400">Cek Katalog dan Tambah Unit ke Wishlist</p>
    </Col>
  )
}
function WishlistPage() {
  const { push } = useHistory()
  const [wishlist, setWishlist] = useState([])
  const [wishlistIds, setWishlistIds] = useState([])
  const [selectedSort, setSelectedSort] = useState({
    sortBy: 'created_at DESC',
    limit: 8,
    offset: 0
  })
  useQuery({
    queryKey: 'getWishlistIds',
    queryFn: () => getWishlistIds(),
    onSuccess: (data) => {
      setWishlistIds(data)
    }
  })

  const { refetch } = useQuery({
    queryKey: ['getWishlist', selectedSort],
    queryFn: () => getWishlist(selectedSort),
    onSuccess: (res) => {
      setWishlist(res)
    },
    staleTime: 0
  })

  const deleteWishlist = useMutation({
    mutationFn: (id) => {
      return deleteWishlistId(id)
    },
    onSuccess: (datas) => {
      showSuccessMessage('Berhasil Menghapus Unit dari Wishlist')
      refetch()
    },
    onError: (data) => {
      // console.log('omg', data.response.data);
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })
  const handleSort = (name, value) => {
    setSelectedSort({ ...selectedSort, [name]: value })
  }
  function handlePagination(pageNumber) {
    const totalOffset = selectedSort.limit * pageNumber - selectedSort.limit
    return handleSort('offset', totalOffset)
  }
  function handleNavigation(path) {
    return push({ pathname: path })
  }
  function handleWishlist(catalogId, isBookmark) {
    if (isBookmark) {
      deleteWishlist.mutate(catalogId)
    }
  }
  return (
    <Row className="gap-6">
      {/* Header */}
      <Col
        span={24}
        className="flex justify-between items-center text-xl-medium text-gray-900 py-2">
        Wishlist
      </Col>
      {/* Filter */}
      <Col span={24}>
        <div className="flex items-center gap-2 text-sm-medium text-gray-700 justify-end">
          <p>Urutkan:</p>
          <Select
            className="rounded-[8px]"
            defaultValue={'Terbaru Disimpan'}
            onChange={(value, option) => handleSort(option.name, option.value)}
            options={[
              {
                value: 'created_at DESC',
                label: 'Terbaru Disimpan',
                name: 'sortBy',
                key: 'terbaru'
              },
              {
                value: 'created_at ASC',
                label: 'Terlama Disimpan',
                name: 'sortBy',
                key: 'terlama'
              },
              {
                value: 'price DESC',
                label: 'Harga Tertinggi',
                name: 'sortBy',
                key: 'tertinggi'
              },
              {
                value: 'price ASC',
                label: 'Harga Terendah',
                name: 'sortBy',
                key: 'terendah'
              }
            ]}
          />
        </div>
      </Col>
      {/* Content Dashboard */}
      {wishlist?.data?.length !== 0 ? (
        <>
          <Col span={24} className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {wishlist?.data?.map((dataWish, index) => (
              <CardCar
                handleWishlist={handleWishlist}
                data={dataWish?.catalog}
                isBookmark={wishlistIds.includes(dataWish?.catalog?.id)}
                onClick={() => {
                  handleNavigation(`/beli/mobil-bekas/detail/${dataWish?.catalog?.id}`)
                }}
                key={index}
              />
            ))}
          </Col>
          {/* Pagination */}
          <Col span={24} className="flex justify-end">
            <CustomPagination
              onChange={handlePagination}
              defaultPageSize={selectedSort?.limit}
              defaultCurrent={1}
              current={
                selectedSort?.offset === 0 ? 1 : selectedSort?.offset / selectedSort?.limit + 1
              }
              total={wishlist?.meta?.total ?? 0}
            />
          </Col>
        </>
      ) : (
        <NullComponent />
      )}
    </Row>
  )
}

export default WishlistPage
