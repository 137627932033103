import { Space } from 'antd'
import React from 'react'
import { HashLink } from 'react-router-hash-link'

function FooterItem({ label, className, ...props }) {
  return (
    <HashLink smooth className={'text-sm-medium ' + className} {...props}>
      <Space>{label}</Space>
    </HashLink>
  )
}

export default FooterItem
