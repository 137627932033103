import CardSectionHeader from 'components/ui/card-section-header'
import React from 'react'
import moment from 'moment'

function CardHistoryTransaction({ data }) {
  return (
    <CardSectionHeader headerTitle="Riwayat Transaksi">
      <div className="grid grid-col-1 gap-5">
        {data?.booking_steps?.length > 0 &&
          data?.booking_steps.map((item, index) => (
            <div className="grid grid-cols-[20px,1fr] items-start space-x-3" key={index}>
              <div
                className={`w-5 h-5 rounded-full border border-solid bg-primary-100 border-primary-500 flex items-center justify-center`}>
                <div className={`w-[10px] h-[10px] bg-primary-500 rounded-full`}></div>
              </div>
              <div>
                <p className="text-md-reguler text-gray-500">
                  {moment(item.created_at).format('DD MMMM YYYY - HH:mm')} WIB
                </p>
                <p className="text-md-medium text-gray-700">{item.description}</p>
              </div>
            </div>
          ))}
      </div>
    </CardSectionHeader>
  )
}

export default CardHistoryTransaction
