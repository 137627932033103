import { Col, Divider } from 'antd'
import { MagnifyingIcon } from 'assets'
import React from 'react'
function ContentTitle({ className, title, onSearch = '' }) {
  return (
    <Col span={24}>
      <div
        className={`flex flex-col gap-6 h-full max-h-[240px] py-9 md:h-[240px] lg:h-[360px] items-center justify-center bg-no-repeat lg:bg-cover bg-[url("features/about-us/assets/images/background-title-aboutus.webp")]  ${className}`}>
        <p className="display-xs-semibold lg:display-md-semibold text-white text-center">{title}</p>
        <Divider className="w-[126px] min-w-min h-[4px] bg-white rounded-full m-0" />
        {onSearch ? (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              // if (!e.target[0].value) return
              // console.log(e.target[0].value)
              onSearch(e.target[0].value)
            }}
            className="flex max-w-[420px] bg-white p-2 gap-4 rounded-[4px]">
            <input
              type="text"
              name="search"
              placeholder="Cari"
              className="w-[220px] sm:w-[320px] md:w-[420px] p-2 focus:outline-none border-0"
            />
            <button className="flex w-[40px] h-[40px] items-center justify-center border-0 bg-primary-500 p-2 rounded-[4px]">
              <MagnifyingIcon className={'stroke-white w-[15px] h-[15px]'} />
            </button>
          </form>
        ) : null}
      </div>
    </Col>
  )
}

export default ContentTitle
