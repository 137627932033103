function InspektorBerpengalamanIcon({ className }) {
  return (
    <svg
      width="82"
      className={className}
      height="64"
      viewBox="0 0 82 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.591 62.5438V18.0774C38.591 11.878 34.2515 6.52401 28.1648 5.22778C24.0507 4.38241 19.8238 4.38241 15.7097 5.22778C9.67941 6.58037 5.33984 11.878 5.33984 18.0774V62.5438"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9491 6.75C13.0618 7.14451 13.1182 7.53901 13.1182 7.93352C13.1182 10.5823 10.9766 12.7803 8.27141 12.7803C7.8769 12.7803 7.4824 12.7239 7.08789 12.6112"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.0382 6.63672C30.9255 7.03122 30.8691 7.48209 30.8691 7.93295C30.8691 10.4691 32.9544 12.5543 35.4905 12.5543C35.9414 12.5543 36.4486 12.4979 36.8431 12.3289"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1359 4.94698V5.4542C26.1359 7.14494 25.2342 8.55389 24.1634 8.55389H19.7675C18.6966 8.55389 17.7949 7.14494 17.7949 5.4542V4.89062"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5898 23.9961L41.4641 25.7432C42.3658 26.3068 42.9294 27.3212 42.9294 28.392V31.9426"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33956 23.9961L2.46531 25.7432C1.56358 26.3068 1 27.3212 1 28.392V31.9426"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.0683 34.1936V33.3482V23.8801C33.0683 20.3295 30.5886 17.2298 27.0944 16.4972C25.4036 16.159 23.7129 15.9336 21.9658 15.9336C20.2751 15.9336 18.528 16.1027 16.8372 16.4972C13.3994 17.2298 10.8633 20.3295 10.8633 23.8801V34.1936L13.1176 32.3338C18.2462 28.1069 25.6291 28.1069 30.7576 32.3338L33.0683 34.1936Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4729 19.2048C25.0076 18.8666 23.4296 18.7539 21.9643 18.7539C20.4426 18.7539 18.921 18.923 17.4557 19.2048C15.2577 19.6556 13.6797 21.6282 13.6797 23.8825V24.3333C16.2722 23.3753 19.0901 22.868 21.9643 22.868C24.8386 22.868 27.6565 23.3753 30.2489 24.3333V23.8825C30.2489 21.6282 28.6709 19.712 26.4729 19.2048Z"
        fill="#E12B25"
      />
      <path
        d="M30.0813 56.457H15.0338C12.7231 56.457 10.8633 54.5972 10.8633 52.2865V38.5352"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.4159 62.8247H58.5417C57.5272 62.8247 57.2454 62.5993 57.1327 61.5848L55.6674 41.3523L55.3293 21.6834M64.0647 62.8247H66.939C67.9534 62.8247 68.1789 62.5993 68.3479 61.5848L69.8133 41.3523L70.095 22.7542L70.8841 21.4016L76.6889 16.3857C78.267 15.0331 78.1542 15.1458 78.6051 13.0606L80.9721 3.02889C81.3666 0.887284 77.4779 0.323705 76.9707 2.40895L73.8147 12.3843L70.2641 15.2022C64.4029 13.286 52.2859 14.1314 52.0605 17.7383L51.2715 29.3481L52.0605 38.7598L52.9059 39.9997L54.0894 40.7887M62.7122 40.1124V61.4157M62.7122 32.8986V18.8091M62.7122 17.5692V15.8221M67.5026 4.60691C67.6153 5.84678 67.3335 8.83376 66.7136 9.96092C66.15 10.919 64.9101 11.8771 63.5012 12.2152C62.8249 12.328 62.6558 12.328 61.9795 12.2152C60.5705 11.8771 59.3307 10.9754 58.7671 9.96092C58.1472 8.83376 57.8654 5.84678 57.9781 4.60691C58.2035 2.18352 60.3451 1 62.7685 1C65.1355 1 67.2771 2.23988 67.5026 4.60691Z"
        stroke="#344154"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default InspektorBerpengalamanIcon
