import { Col, Row } from 'antd'
import { DetailUnitContent, InformationContent } from 'features/detail-users'
import {
  getDetailUser,
  getUnitsList,
  getUserUnits,
  putAssignUnit
} from 'features/detail-users/service'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { debounce } from 'lodash'
import { useEffect } from 'react'
import { showErrorMessage } from 'utils/toastMessage'

const headers = [
  { label: 'Informasi', value: true },
  { label: 'Detail Unit', value: false }
]

const initialFilter = {
  offset: 0,
  limit: 5,
  keywords: '',
  status: '',
  grade: ''
}

function DetailUserPage() {
  const token = localStorage.getItem('token')
  const { location } = useHistory()
  const [filter, setFilter] = useState({ ...initialFilter })
  const [openModal, setOpenModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isInformation, setInformation] = useState(true)
  const [selectedUnit, setSelectedUnit] = useState('')
  const [userData, setUserData] = useState([])
  const [userUnitsData, setUserUnitsData] = useState(null)
  const [units, setUnits] = useState([])

  // start of fetching
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      getDataUserUnits.refetch()
    }, 500),
    []
  )

  useQuery({
    queryKey: ['getUsers'],
    queryFn: () =>
      getDetailUser({
        id: location.state.id,
        token: token
      }),
    onSuccess: (data) => {
      setUserData([
        {
          id: 1,
          label: 'Biodata',
          child: [
            { label: 'Nama', value: data?.fullname || '-' },
            { label: 'Email Perusahaan', value: data?.company_email || '-' },
            { label: 'Email Pribadi', value: data?.email || '-' },
            { label: 'Nomor Telepon', value: data?.phone_number || '-' },
            // { label: 'Tanggal Lahir', value: moment(data?.birth_date).format('DD MMM YYYY') || '-' },
            { label: 'NPWP', value: data?.npwp || '-' }
          ]
        },
        {
          id: 2,
          label: 'Alamat',
          child: [
            { label: 'Alamat', value: data?.address || '-' },
            { label: 'Kota', value: data?.city_name || '-' },
            { label: 'Kecamatan', value: data?.sub_district_name || '-' },
            { label: 'Provinsi', value: data?.province_name || '-' }
          ]
        }
      ])
    },
    enabled: isInformation === true
  })
  const getDataUserUnits = useQuery({
    queryKey: ['getUserUnit', debouncedChangeHandler],
    queryFn: () =>
      getUserUnits({
        id: location.state.id,
        params: { ...filter },
        token: token
      }),
    onSuccess: (data) => {
      setUserUnitsData(data)
    },
    enabled: isInformation === false || Boolean(debouncedChangeHandler)
  })
  useQuery('getUnitsList', {
    queryFn: () =>
      getUnitsList({
        token: token
      }),
    onSuccess: (data) => {
      const newData =
        data?.length > 0
          ? data.filter(
              (unit) =>
                !unit?.is_booked &&
                !unit?.is_in_catalog &&
                !unit?.is_in_service &&
                !unit?.is_inspected
            )
          : []

      setUnits(newData)
    },
    staleTime: Infinity
  })
  const putAssignUnits = useMutation('putAssignUnit', putAssignUnit, {
    onSuccess: () => {
      setOpenModal(false)
      setSelectedUnit('')
      getDataUserUnits.refetch()
    },
    onError: (data) => {
      const err = data?.response?.data?.errors[0]?.message
      let messageError = 'Tidak bisa assign Unit'
      if (err === 'Cannot Assign Unit, Unit is Being Inspected') {
        messageError = 'Tidak Dapat Assign Unit, Unit Sedang Diinspeksi'
      } else if (err == 'Cannot Assign Unit, Unit is Being Serviced') {
        messageError = 'Tidak Dapat Assign Unit, Unit Sedang Diservis'
      } else if (err == 'Cannot Assign Unit, Unit is Already in Catalog') {
        messageError = 'Tidak Dapat Assign Unit, Unit Sedang Dijual'
      }
      showErrorMessage(messageError)
    }
  })
  // end of fetching

  // function
  const handleAssignUnit = () => {
    const dataSend = {
      id: location.state.id,
      token: token,
      body: {
        unit_id: selectedUnit
      }
    }
    putAssignUnits.mutate(dataSend)
  }

  const handleSelectedUnit = (val) => setSelectedUnit(val)

  const handleChangeFilter = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))
    return debouncedChangeHandler()
  }
  const handleChangeFilterIncludeOffset = (name, value) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
      offset: 0
    }))
    return debouncedChangeHandler()
  }
  const onChangeNextPage = () => {
    if (currentPage !== Math.ceil(userUnitsData?.meta?.total / filter.limit)) {
      handleChangeFilter('offset', currentPage * filter.limit)
      setCurrentPage((prev) => prev + 1)
    }
  }
  const onChangePrevPage = () => {
    if (currentPage > 1) {
      handleChangeFilter('offset', filter.offset - filter.limit)
      setCurrentPage((prev) => prev - 1)
    }
  }

  useEffect(() => {
    if (isInformation) {
      setFilter({ ...initialFilter })
    }
  }, [isInformation])

  return (
    <Row className="gap-6">
      {/* Header Breadcrumb */}
      <Col span={24} className="flex gap-1 items-center text-xl-medium text-gray-900 py-2">
        <Link className=" stroke-primary-600 text-gray-700 hover:text-primary-500" to={'/users'}>
          Users
        </Link>{' '}
        / <span className="text-primary-500">Detail User</span>
      </Col>
      {/* content */}
      <Col span={24} className="grid grid-cols-1 gap-6">
        {/* Headers */}
        <div>
          <div className="border border-solid flex w-fit border-gray-300 text-gray-900 text-md-medium rounded-xl overflow-hidden">
            {headers.map((header, index) => (
              <div
                className={`py-4 px-6 cursor-pointer ${
                  index === 0 ? 'border-r border-gray-300' : ''
                } ${isInformation === header.value ? 'bg-gray-100' : 'bg-white'}`}
                key={header.label}
                onClick={() => setInformation(header.value)}>
                {header.label}
              </div>
            ))}
          </div>
        </div>
        {/* content */}
        <Col span={24}>
          <div className="md:bg-white md:border border-solid border-gray-200 md:p-6 rounded-[4px] grid grid-cols-1 gap-6">
            {isInformation ? (
              <InformationContent userData={userData} />
            ) : (
              <DetailUnitContent
                userUnits={userUnitsData?.data || []}
                currentPage={filter?.offset === 0 ? 1 : filter?.offset + 1}
                totalPerPage={
                  currentPage * filter?.limit >= userUnitsData?.meta.total
                    ? userUnitsData?.meta.total
                    : currentPage * filter?.limit
                }
                lengthAllData={userUnitsData?.meta?.total}
                defaultPageSize={filter?.limit}
                handleChangeFilterIncludeOffset={handleChangeFilterIncludeOffset}
                onChangeNextPage={onChangeNextPage}
                onChangePrevPage={onChangePrevPage}
                units={units}
                selectedUnit={selectedUnit}
                handleSelectedUnit={handleSelectedUnit}
                handleAssignUnit={handleAssignUnit}
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            )}
          </div>
        </Col>
      </Col>
    </Row>
  )
}

export default DetailUserPage
