import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { getOTPChangePassword, sendChangePassword, sendOTPChangePassword } from '../service'
import useValidation from 'lib/useValidation'
import { nameToString } from 'utils/nameToString'
import { showErrorMessage, showSuccessMessage } from 'utils/toastMessage'
const initialPasswords = {
  confirm: '',
  new: ''
}
const useChangePassword = (fullData) => {
  const [modalChangePassword, setModalChangePassword] = useState(false)
  const [modalConfirmChangePassword, setModalConfirmChangePassword] = useState(false)
  const [isOTPSuccess, setIsOTPSuccess] = useState(false)
  const [passwords, setPasswords] = useState({ ...initialPasswords })
  const [errorPasswords, setErrorPasswords] = useState({ ...initialPasswords })
  const { passValidation, confirmPassValidation } = useValidation()
  // otp & change password
  const sendNewPassword = useMutation('sendNewPassword', {
    mutationFn: (otpToken) =>
      sendChangePassword({
        body: {
          email: String(fullData.email),
          new_password: passwords.new,
          otp_token: otpToken
        }
      }),
    onSuccess: () => {
      resetStateChangePassword()
      showSuccessMessage('Password telah berhasil diubah')
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    }
  })
  const otpRequest = useQuery('otpRequest', {
    queryFn: () =>
      getOTPChangePassword({
        params: {
          email: String(fullData.email),
          otp_type: 21
        }
      }),
    onSuccess: () => {
      OTPSuccess()
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Error')
    },
    enabled: false
  })
  const otpSendPost = useMutation('otpSend', {
    mutationFn: (otp) =>
      sendOTPChangePassword({
        body: {
          otp_code: otp,
          email: fullData.email,
          otp_type: 21
        }
      }),
    onSuccess: (data) => {
      sendNewPassword.mutate(data.data.otp_token)
    },
    onError: (data) => {
      showErrorMessage(data?.response?.data?.errors[0]?.message || 'Errror')
    }
  })
  const handleChangePassword = (name, value) => {
    let err = ''
    switch (name) {
      case 'new':
        err = passValidation(value)
        break
      case 'confirm':
        err = confirmPassValidation(passwords.new, value)
        break
      default:
        err = ''
    }
    setPasswords((prev) => ({
      ...prev,
      [name]: value
    }))
    setErrorPasswords((prev) => ({
      ...prev,
      [name]: err
    }))
  }

  const handleResend = () => otpRequest.refetch()
  const handleSubmitOTP = (otp) => {
    otpSendPost.mutate(otp)
  }
  const handleSubmitPassword = () => {
    let nameError = ''
    let nameInput = ''
    let validError = true
    let validInput = true
    Object.keys(passwords).forEach((key) => {
      if (passwords[key].length === 0) {
        validInput = false
        nameInput = String(key)
      }
    })
    Object.keys(errorPasswords).forEach((key) => {
      if (errorPasswords[key].length > 0) {
        validError = false
        nameError = String(key)
      }
    })
    if (validInput) {
      if (validError) {
        otpRequest.refetch()
      } else {
        showErrorMessage(`Mohon perbaiki ${nameToString[nameError]}`)
      }
    } else {
      showErrorMessage(`Mohon isi ${nameToString[nameInput]}`)
    }
  }
  function openModalChangePassword() {
    setModalChangePassword(true)
  }
  function closeModalChangePassword() {
    setModalChangePassword(false)
    setPasswords({ ...initialPasswords })
    setErrorPasswords({ ...initialPasswords })
  }
  function openModalConfirmChangePassword() {
    setModalConfirmChangePassword(true)
  }
  function closeModalConfirmChangePassword() {
    setModalConfirmChangePassword(false)
  }
  function OTPSuccess() {
    setIsOTPSuccess(true)
  }
  function OTPFail() {
    setIsOTPSuccess(false)
  }
  function resetStateChangePassword() {
    closeModalChangePassword()
    closeModalConfirmChangePassword()
    OTPFail()
    setPasswords({ ...initialPasswords })
    setErrorPasswords({ ...initialPasswords })
  }
  return {
    modalChangePassword,
    openModalChangePassword,
    closeModalChangePassword,
    modalConfirmChangePassword,
    openModalConfirmChangePassword,
    closeModalConfirmChangePassword,
    passwords,
    errorPasswords,
    isOTPSuccess,
    OTPFail,
    handleChangePassword,
    handleResend,
    handleSubmitOTP,
    handleSubmitPassword,
    resetStateChangePassword
  }
}

export default useChangePassword
