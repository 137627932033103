import { Select } from 'antd'
import CustomButton from 'components/ui/custom-button'
import { ModalLayout } from 'layouts'
import React from 'react'

function AssignUnitModal({
  units,
  openModal,
  setOpenModal,
  selectedUnit,
  handleSelectedUnit,
  handleAssignUnit
}) {
  const handleCancel = () => setOpenModal(false)
  return (
    <ModalLayout
      openModal={openModal}
      headerTitle={'Assign Unit'}
      onCancel={() => setOpenModal(false)}
      className="w-[400px]">
      <div className="grid grid-cols-1 gap-5">
        {/* label */}
        <p className="text-md-medium text-gray-500">Pilih Unit untuk diassign ke user.</p>
        {/* select unit */}
        <div className="grid grid-cols-1 gap-2">
          <p className="text-sm-medium text-gray-700">Unit</p>
          <Select
            value={selectedUnit}
            style={{
              width: '100%'
            }}
            optionLabelProp="label"
            placeholder="Pilih Unit"
            onChange={(value) => handleSelectedUnit(value)}>
            {units.map((unitItem) => (
              <Select.Option key={unitItem.id} value={unitItem.id} label={unitItem.unit_name}>
                <div className="bg-gray-100 border w-full rounded p-4">
                  <p className="text-xs-regular text-gray-500">{unitItem.police_number}</p>
                  <p className="text-md-regular text-gray-900 whitespace-break-spaces">{unitItem.unit_name}</p>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
        {/* button */}
        <div className="flex justify-end items-center gap-2">
          <CustomButton type="plain" label={'Batal'} onClick={handleCancel} />
          <CustomButton label={'Konfirmasi'} onClick={handleAssignUnit} />
        </div>
      </div>
    </ModalLayout>
  )
}

export default AssignUnitModal
