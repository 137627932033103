const CoinStackIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="35"
      height="38"
      viewBox="0 0 35 38"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2919 6.03712C20.2937 3.9908 16.1211 2.33337 10.9799 2.33337C5.83863 2.33337 1.66974 3.99265 1.66602 6.03712M1.66602 6.03712C1.66602 8.08343 5.8349 9.74086 10.9799 9.74086C16.1249 9.74086 20.2937 8.08343 20.2937 6.03712L20.2937 20.4075M1.66602 6.03712V28.2594C1.66788 30.3058 5.83676 31.9632 10.9799 31.9632C13.4741 31.9632 15.7262 31.5687 17.3971 30.9335M1.66788 13.4446C1.66788 15.4909 5.83677 17.1483 10.9817 17.1483C16.1267 17.1483 20.2956 15.4909 20.2956 13.4446M17.5089 23.4911C15.8287 24.1485 13.5244 24.5559 10.9799 24.5559C5.83676 24.5559 1.66788 22.8985 1.66788 20.8522M30.878 21.4411C34.1509 24.6948 34.1509 29.9727 30.878 33.2264C27.6051 36.4801 22.2962 36.4801 19.0233 33.2264C15.7504 29.9727 15.7504 24.6948 19.0233 21.4411C22.2962 18.1874 27.6051 18.1874 30.878 21.4411Z"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  )
}

export default CoinStackIcon
