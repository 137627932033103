import { Col, Row } from 'antd'
import { InspectionBenefitData } from 'features/inspeksi/data/inspeksi-constant-data'
import React from 'react'

const StandardInspectionSection = () => {
  return (
    <Row className="bg-white py-10 md:py-20 ">
      <Col span={20} className="mx-auto gap-12 grid grid-cols-1">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Left Content */}
          <div className="order-last md:order-first">
            <ol className="flex flex-col gap-6">
              {InspectionBenefitData.map((data, key) => (
                <li key={key} className="flex gap-4 items-center">
                  {/* Icon */}
                  <div className="relative flex justify-center items-center h-[70px] object-contain">
                    {data.icon}
                  </div>
                  {/* Title & Description */}
                  <div className="flex flex-col gap-2">
                    <p className="text-md-semibold md:text-lg-semibold text-gray-900">
                      {data.label}
                    </p>
                    <p className="text-sm-regular text-gray-500">{data.description}</p>
                  </div>
                </li>
              ))}
            </ol>
          </div>
          {/* Right Content */}
          {/* <div className="grid grid-cols-1">
            <div className="flex flex-col gap-2 text-center md:text-left">
              <p className="display-xs-semibold md:display-sm-semibold text-gray-900">
                Inspeksi Standar Nasional
              </p>
              <p className="text-md-regular md:text-xl-regular text-gray-600">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat in vitae, vulputate et
                non nibh feugiat. Quam ultricies auctor gravida tristique vitae ipsum pretium ipsum
                adipiscing. Risus, libero, varius id mauris dignissim.
              </p>
            </div>
            <ol className="flex flex-col gap-2">
              {new Array(5).fill(0).map((_, index) => (
                <li key={index} className="flex gap-1 items-center text-md-medium text-gray-500">
                  <CheckIcon className={'h-3 w-3'} />
                  <span>Lorem ipsum</span>
                </li>
              ))}
            </ol>
          </div> */}
        </div>
      </Col>
    </Row>
  )
}

export default StandardInspectionSection
